import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class MVStevedorCostingAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      stevedor_on_mv: "",
      quantity_in_mt: "",
      billing_currency: "0",
      stevedore_price_pmt: "",
      currency_exchange_rate: "",
      local_currency: "",
      vat_applicability: "",
      stevedore_base_price: "",
      stevedore_vat_percent: 10,
      stevedore_vat_value: "",
      stevedore_total: "",
      stevedore_pph23_percent: 2,
      stevedore_pph23_value: "",
      stevedore_payable_to_vendor: "",
      stevedore_cost_to_company: "",

      stevedore_price_per_addl_day: "",
      stevedore_addl_days: "",
      stevedore_addl_base_price: "",
      stevedore_addl_vat_percent: 10,
      stevedore_addl_vat_value: "",
      stevedore_addl_pph23_percent: 2,
      stevedore_addl_pph23_value: "",
      stevedore_addl_total: "",
      stevedore_addl_payable_to_vendor: "",
      stevedore_addl_cost_to_company: "",
      pnbp_per_mt: "",
      pnbp: "",
      jasa_pbm_calculation: "",
      jasa_pbm_pmt: "",
      jasa_pbm_value: "",
      status: "",
      wait_period_people: "",
      wait_period_hours: "",
      wait_period_price_per_hour: "",
      wait_period_total_price: "",
      others: [],
      stevedore_costing: [
        {
          stevedore_vendor_id: "",
          stevedore_vendor_name: "",
          invoice_no: "",
          invoice_date: null,
          ppn: "",
          quantity: "",
          billing_currency: "0",
          stevedore_price_pmt: "",
          currency_exchange_rate: "",
          local_currency: "",
          vat_applicability: "",
          stevedore_base_price: "",
          stevedore_vat_percent: 10,
          stevedore_vat_value: "",
          stevedore_total: "",
          stevedore_pph23_percent: 2,
          stevedore_pph23_value: "",
          stevedore_payable_to_vendor: "",
          stevedore_cost_to_company: "",

          stevedore_price_per_addl_day: "",
          stevedore_addl_days: 0,
          stevedore_addl_base_price: "",
          stevedore_addl_vat_percent: 10,
          stevedore_addl_vat_value: "",
          stevedore_addl_pph23_percent: 2,
          stevedore_addl_pph23_value: "",
          stevedore_addl_total: "",
          stevedore_addl_payable_to_vendor: "",
          stevedore_addl_cost_to_company: "",
          pnbp_per_mt: "",
          pnbp: "",
          pnbp_company_to_billing: "",
          jasa_pbm_calculation: "",
          jasa_pbm_pmt: "",
          jasa_pbm_value: "",
          jasa_pbm_company_to_billing: "",
          status: "",
          wait_period_people: "",
          wait_period_hours: "",
          wait_period_price_per_hour: "",
          wait_period_total_price: "",
          others: [],
        },
      ],
      isLoading: true,
      features: [],
      vendors: [],
      vendorData: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Stevedore_Costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("226") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      var status = "Active";
      var vendors = [];
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                vendors = res.vendors;
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
      try {
        var businessNoID = this.props.businessNoID;

        // const getStevedorData = await api.get_mv_purchase_financials_stevedore(loginUserID, idToken, businessNoID);
        // if (getStevedorData.status >= 200 && getStevedorData.status < 300) {
        //   getStevedorData.json().then((res) => {
        //     if (res.code === '200') {
        //       if (res.mv_pur_fin_stevedore_costing) {
        //         if (res.mv_pur_fin_stevedore_costing.length > 0) {
        //           this.setState({
        //             errorMsg: "Finanaicals data already avaiable for this business number",
        //             noVendorsAvailable: false,
        //             snackBarErrorOpen: true
        //           },
        //             () => {
        //               setTimeout(() => {
        //                 this.props.onAddResponse({ screen: "MvStevedoreCosting", code: "", message: 'alreadyDataAvailable' });
        //               }, 2000)
        //             })
        //         }
        //       }
        //       else {
        //         alert('Unexpected error occured. Please contact administrator.');
        //       }

        //     }
        //   })
        // }
        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    if (
                      res.business_number.consignment_type === "Mother Vessel"
                    ) {
                      // if consignment_type === Mother Vessel check for this businessNumber mother Vessel data is avilabe or not
                      //Get Business No Operational Mother Vessel list
                      api
                        .getBusinessNumberMotherVessel(
                          businessNoID,
                          loginUserID,
                          idToken
                        )
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((res) => {
                              if (res.code === "200") {
                                if (res.idtoken)
                                  this.Cookie.setIdTokenCookie(res.idtoken);
                                if (res.business_no_MV.length > 0) {
                                  var motherVesselID = res.business_no_MV[0].id;
                                  //Get Business No Operational Mother Vessel Details
                                  api
                                    .getBusinessNoOpMVDetails(
                                      motherVesselID,
                                      loginUserID,
                                      idToken
                                    )
                                    .then((response) => {
                                      if (
                                        response.status >= 200 &&
                                        response.status < 300
                                      ) {
                                        response.json().then((res) => {
                                          if (res.business_no_MV) {
                                            //const filter_stevedore_vendors = vendors.filter((e, idx) => e.vendor_type === "Stevedore");
                                            const filter_stevedore_ids = res
                                              .business_no_MV.stevedore_vendor
                                              ? res.business_no_MV.stevedore_vendor.split(
                                                ","
                                              )
                                              : [];
                                            const filter_stevedore_vendor_names =
                                              vendors.filter((e, idx) =>
                                                filter_stevedore_ids.find(
                                                  (v, index) => v === e.id
                                                )
                                              );

                                            var vendorData = [
                                              <MenuItem value="0">
                                                Please Select
                                              </MenuItem>,
                                            ];
                                            for (var i in filter_stevedore_vendor_names) {
                                              vendorData.push(
                                                <MenuItem
                                                  value={
                                                    filter_stevedore_vendor_names[
                                                      i
                                                    ].id
                                                  }
                                                >
                                                  {
                                                    filter_stevedore_vendor_names[
                                                      i
                                                    ].vendor_name
                                                  }
                                                </MenuItem>
                                              );
                                            }
                                            // const stevedore_costing = [];
                                            if (
                                              filter_stevedore_ids.length === 0
                                            ) {
                                              this.setState(
                                                {
                                                  errorMsg:
                                                    "No Stevedore Vendors available",
                                                  noVendorsAvailable: true,
                                                  snackBarErrorOpen: true,
                                                },
                                                () => {
                                                  setTimeout(() => {
                                                    window.location.href =
                                                      "/view-business/" +
                                                      btoa(businessNoID);
                                                  }, 2000);
                                                }
                                              );
                                            }
                                            // for (var i of filter_stevedore_vendor_names) {
                                            //   stevedore_costing.push(
                                            var stevedore_costing = [
                                              {
                                                stevedore_vendor_id:
                                                  filter_stevedore_vendor_names.length >
                                                    0
                                                    ? filter_stevedore_vendor_names[0]
                                                      .id
                                                    : "0",
                                                stevedore_vendor_name:
                                                  filter_stevedore_vendor_names.length >
                                                    0
                                                    ? filter_stevedore_vendor_names[0]
                                                      .vendor_name
                                                    : "",
                                                invoice_no: "",
                                                invoice_date: null,
                                                ppn: "",
                                                quantity: res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                                  ? res.business_no_MV
                                                    .vessel_final_quantity_in_mt
                                                  : "",
                                                billing_currency: "0",
                                                stevedore_price_pmt: "",
                                                currency_exchange_rate: "",
                                                local_currency: "",
                                                vat_applicability: "",
                                                stevedore_base_price: "",
                                                stevedore_vat_percent: 10,
                                                stevedore_vat_value: "",
                                                stevedore_total: "",
                                                stevedore_pph23_percent: 2,
                                                stevedore_pph23_value: "",
                                                stevedore_payable_to_vendor: "",
                                                stevedore_cost_to_company: "",

                                                stevedore_price_per_addl_day:
                                                  "",
                                                stevedore_addl_days:
                                                  res.business_no_MV
                                                    .stevedore_additional_days ===
                                                    null
                                                    ? "0"
                                                    : res.business_no_MV
                                                      .stevedore_additional_days,
                                                stevedore_addl_base_price: "",
                                                stevedore_addl_vat_percent: 10,
                                                stevedore_addl_vat_value: "",
                                                stevedore_addl_pph23_percent: 2,
                                                stevedore_addl_pph23_value: "",
                                                stevedore_addl_total: "",
                                                stevedore_addl_payable_to_vendor:
                                                  "",
                                                stevedore_addl_cost_to_company:
                                                  "",
                                                pnbp_per_mt: "",
                                                pnbp: "",
                                                pnbp_company_to_billing: "",
                                                jasa_pbm_calculation: "",
                                                jasa_pbm_pmt: "",
                                                jasa_pbm_value: "",
                                                jasa_pbm_company_to_billing: "",
                                                status: "",
                                                wait_period_people: "",
                                                wait_period_hours: "",
                                                wait_period_price_per_hour: "",
                                                wait_period_total_price: "",
                                                others: [],
                                              },
                                            ];
                                            //  )
                                            // }
                                            // console.log(stevedore_costing);

                                            this.setState({
                                              motherVesselID: motherVesselID,
                                              vessel_type: res.business_no_MV
                                                .vessel_type
                                                ? res.business_no_MV.vessel_type
                                                : "",

                                              vessel_final_quantity_in_mt: res
                                                .business_no_MV
                                                .vessel_final_quantity_in_mt
                                                ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                                : "",
                                              quantity_in_mt: res.business_no_MV
                                                .vessel_final_quantity_in_mt
                                                ? res.business_no_MV
                                                  .vessel_final_quantity_in_mt
                                                : "",
                                              loadingRatePerDay:
                                                res.business_no_MV
                                                  .load_rate_per_day === null
                                                  ? ""
                                                  : res.business_no_MV
                                                    .load_rate_per_day,
                                              buyerAdditionalSurveyorName:
                                                res.business_no_MV
                                                  .joint_surveyor_names === null
                                                  ? "0"
                                                  : res.business_no_MV
                                                    .joint_surveyor_names,
                                              surveyorBasePrice:
                                                res.business_no_MV
                                                  .surveyor_base_price === null
                                                  ? ""
                                                  : res.business_no_MV
                                                    .surveyor_base_price,
                                              stevedore_on_mv:
                                                res.business_no_MV
                                                  .stevedore_name === null
                                                  ? ""
                                                  : res.business_no_MV
                                                    .stevedore_name,
                                              stevedore_price_pmt:
                                                res.business_no_MV
                                                  .stevedore_base_price === null
                                                  ? ""
                                                  : res.business_no_MV
                                                    .stevedore_base_price,
                                              stevedore_currency:
                                                res.business_no_MV
                                                  .stevedore_currency === null
                                                  ? ""
                                                  : res.business_no_MV
                                                    .stevedore_currency,
                                              stevedore_addl_days:
                                                res.business_no_MV
                                                  .stevedore_additional_days ===
                                                  null
                                                  ? "0"
                                                  : res.business_no_MV
                                                    .stevedore_additional_days,
                                              basePrice: (
                                                Number(
                                                  res.business_no_MV
                                                    .stevedore_base_price ===
                                                    null
                                                    ? "0"
                                                    : res.business_no_MV
                                                      .stevedore_base_price
                                                ) *
                                                Number(
                                                  res.business_no_MV
                                                    .vessel_final_quantity_in_mt
                                                )
                                              ).toFixed(4),
                                              stevedore_costing:
                                                stevedore_costing,
                                              vendors:
                                                filter_stevedore_vendor_names,
                                              vendorData: vendorData,
                                            });
                                          }
                                        });
                                      }
                                    });
                                }
                              }
                            });
                          }
                        });
                    }
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      isLoading: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      } catch (e) {
        //window.location.href = '/business-number-list';
      }
    }
  }

  StevedoreCalculationHandler = (idx) => {
    var row = this.state.stevedore_costing;
    let currency_exchange_rate = row[idx].currency_exchange_rate
      ? row[idx].currency_exchange_rate
      : 1;
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      row[idx].billing_currency === "USD"
    ) {
      currency_exchange_rate = 1;
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].billing_currency === "IDR"
    ) {
      currency_exchange_rate = 1;
    }
    var stevedore_base_price = (
      (Number(row[idx].quantity) * Number(row[idx].stevedore_price_pmt)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    var total = (
      (Number(row[idx].quantity) * Number(row[idx].stevedore_price_pmt)) /
      Number(currency_exchange_rate)
    ).toFixed(4);

    var stevedore_addl_base_price = (
      (Number(row[idx].stevedore_price_per_addl_day) *
        Number(row[idx].stevedore_addl_days)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    var addl_total = (
      (Number(row[idx].stevedore_price_per_addl_day) *
        Number(row[idx].stevedore_addl_days)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    let stevedore_vat_percent = row[idx].stevedore_vat_percent ? row[idx].stevedore_vat_percent : 10;
    let stevedore_pph23_percent = row[idx].stevedore_pph23_percent ? row[idx].stevedore_pph23_percent : 2;

    let inclusiveVatConstraint = 100 + Number(stevedore_vat_percent);

    if (row[idx].vat_applicability === "Exclusive") {
      let stevedore_vat_value = (
        (Number(stevedore_base_price) * stevedore_vat_percent) /
        100
      ).toFixed(2);
      let stevedore_total = (
        Number(stevedore_base_price) + Number(stevedore_vat_value)
      ).toFixed(2);
      let stevedore_pph23_value = (
        (Number(stevedore_base_price) * stevedore_pph23_percent) /
        100
      ).toFixed(2);
      let stevedore_payable_to_vendor = (
        Number(stevedore_total) - Number(stevedore_pph23_value)
      ).toFixed(2);
      let stevedore_cost_to_company = stevedore_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        stevedore_payable_to_vendor = (
          Number(stevedore_base_price) + Number(stevedore_vat_value)
        ).toFixed(2);
        stevedore_pph23_value = "";
      }

      row[idx].stevedore_base_price = stevedore_base_price;
      row[idx].stevedore_vat_percent = stevedore_vat_percent;
      row[idx].stevedore_vat_value = stevedore_vat_value;
      row[idx].stevedore_total = stevedore_total;
      row[idx].stevedore_pph23_value = stevedore_pph23_value;
      row[idx].stevedore_payable_to_vendor = stevedore_payable_to_vendor;
      row[idx].stevedore_cost_to_company = stevedore_cost_to_company;

      this.setState({
        stevedore_costing: row,
      });
    } else if (row[idx].vat_applicability === "Inclusive") {
      let stevedore_base_price = ((Number(total) * 100) / inclusiveVatConstraint).toFixed(2);
      let stevedore_vat_value = (
        (Number(stevedore_base_price) * stevedore_vat_percent) /
        100
      ).toFixed(2);
      let stevedore_pph23_value = (
        (Number(stevedore_base_price) * stevedore_pph23_percent) /
        100
      ).toFixed(2);
      let stevedore_total = (
        Number(stevedore_base_price) + Number(stevedore_vat_value)
      ).toFixed(2);
      let stevedore_payable_to_vendor = (
        Number(stevedore_total) - Number(stevedore_pph23_value)
      ).toFixed(2);
      let stevedore_cost_to_company = stevedore_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        stevedore_payable_to_vendor = stevedore_total;
        stevedore_pph23_value = "";
      }
      row[idx].stevedore_base_price = stevedore_base_price;
      row[idx].stevedore_vat_percent = stevedore_vat_percent;
      row[idx].stevedore_vat_value = stevedore_vat_value;
      row[idx].stevedore_total = stevedore_total;
      row[idx].stevedore_pph23_value = stevedore_pph23_value;
      row[idx].stevedore_payable_to_vendor = stevedore_payable_to_vendor;
      row[idx].stevedore_cost_to_company = stevedore_cost_to_company;
      this.setState({
        stevedore_costing: row,
      });
    }
    let stevedore_addl_vat_percent = row[idx].stevedore_addl_vat_percent ? row[idx].stevedore_addl_vat_percent : 10;
    let stevedore_addl_pph23_percent = row[idx].stevedore_addl_pph23_percent ? row[idx].stevedore_addl_pph23_percent : 2;

    let inclusiveAdlVatConstraint = 100 + stevedore_addl_vat_percent;
    if (row[idx].addl_day_vat_applicability === "Exclusive") {

      // Stevedore Additional Days Price Calculations
      let stevedore_addl_vat_value = (
        (Number(stevedore_addl_base_price) * stevedore_addl_vat_percent) /
        100
      ).toFixed(2);
      let stevedore_addl_total = (
        Number(stevedore_addl_base_price) + Number(stevedore_addl_vat_value)
      ).toFixed(2);
      let stevedore_addl_pph23_value = (
        (Number(stevedore_addl_base_price) * stevedore_addl_pph23_percent) /
        100
      ).toFixed(2);
      let stevedore_addl_payable_to_vendor = (
        Number(stevedore_addl_total) - Number(stevedore_addl_pph23_value)
      ).toFixed(2);
      let stevedore_addl_cost_to_company = stevedore_addl_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        stevedore_addl_payable_to_vendor = (
          Number(stevedore_addl_base_price) + Number(stevedore_addl_vat_value)
        ).toFixed(2);
        stevedore_addl_pph23_value = "";
      }
      row[idx].stevedore_addl_base_price = stevedore_addl_base_price;
      row[idx].stevedore_addl_vat_percent = stevedore_addl_vat_percent;
      row[idx].stevedore_addl_vat_value = stevedore_addl_vat_value;
      row[idx].stevedore_addl_total = stevedore_addl_total;
      row[idx].stevedore_addl_pph23_value = stevedore_addl_pph23_value;
      row[idx].stevedore_addl_payable_to_vendor =
        stevedore_addl_payable_to_vendor;
      row[idx].stevedore_addl_cost_to_company = stevedore_addl_cost_to_company;
      this.setState({
        stevedore_costing: row,
      });
    } else if (row[idx].addl_day_vat_applicability === "Inclusive") {
      // Stevedore Additional Days Price Calculations
      let stevedore_addl_base_price = (
        (Number(addl_total) * 100) /
        inclusiveAdlVatConstraint
      ).toFixed(2);
      let stevedore_addl_vat_value = (
        (Number(stevedore_addl_base_price) * stevedore_addl_vat_percent) /
        100
      ).toFixed(2);
      let stevedore_addl_pph23_value = (
        (Number(stevedore_addl_base_price) * stevedore_addl_pph23_percent) /
        100
      ).toFixed(2);
      let stevedore_addl_total = (
        Number(stevedore_addl_base_price) + Number(stevedore_addl_vat_value)
      ).toFixed(2);
      let stevedore_addl_payable_to_vendor = (
        Number(stevedore_addl_total) - Number(stevedore_addl_pph23_value)
      ).toFixed(2);
      let stevedore_addl_cost_to_company = stevedore_addl_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        stevedore_addl_payable_to_vendor = stevedore_addl_total;
        stevedore_addl_pph23_value = "";
      }
      row[idx].stevedore_addl_base_price = stevedore_addl_base_price;
      row[idx].stevedore_addl_vat_percent = stevedore_addl_vat_percent;
      row[idx].stevedore_addl_vat_value = stevedore_addl_vat_value;
      row[idx].stevedore_addl_total = stevedore_addl_total;
      row[idx].stevedore_addl_pph23_value = stevedore_addl_pph23_value;
      row[idx].stevedore_addl_payable_to_vendor =
        stevedore_addl_payable_to_vendor;
      row[idx].stevedore_addl_cost_to_company = stevedore_addl_cost_to_company;
      this.setState({
        stevedore_costing: row,
      });
    }

    let pnbp_per_mt = row[idx].pnbp_per_mt ? row[idx].pnbp_per_mt : 0;
    let pnbp = (
      (Number(row[idx].quantity) * Number(pnbp_per_mt)) /
      Number(currency_exchange_rate)
    ).toFixed(2);

    row[idx].pnbp = pnbp;
    this.setState({
      stevedore_costing: row,
    });
    let jasa_pbm_pmt = "";
    if (row[idx].jasa_pbm_calculation === "Per MT") {
      jasa_pbm_pmt = row[idx].jasa_pbm_pmt ? row[idx].jasa_pbm_pmt : 0;
      let jasa_pbm_value = (
        (Number(row[idx].quantity) * Number(jasa_pbm_pmt)) /
        100 /
        Number(currency_exchange_rate)
      ).toFixed(2);

      row[idx].jasa_pbm_value = jasa_pbm_value;
      //jasa_pbm_company_to_billing:""
      this.setState({
        stevedore_costing: row,
      });
    }

    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        row[idx].billing_currency === "IDR") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        row[idx].billing_currency === "USD")
    ) {
      row[idx].pnbp_company_to_billing = (
        Number(row[idx].quantity) * Number(pnbp_per_mt)
      ).toFixed(2);
      row[idx].jasa_pbm_company_to_billing = (
        (Number(row[idx].quantity) * Number(jasa_pbm_pmt)) /
        100
      ).toFixed(2);
    } else {
      row[idx].pnbp_company_to_billing = "";
      row[idx].jasa_pbm_company_to_billing = "";
    }

    // Waiting Period Calculation
    let wait_period_people = row[idx].wait_period_people;
    let wait_period_hours = row[idx].wait_period_hours;
    let wait_period_price_per_hour = row[idx].wait_period_price_per_hour;
    //let wait_period_local_currency = row[idx].wait_period_local_currency;
    let wait_period_total_price = (
      (Number(wait_period_people) *
        Number(wait_period_hours) *
        Number(wait_period_price_per_hour)) /
      Number(currency_exchange_rate)
    ).toFixed(4);

    row[idx].wait_period_total_price = wait_period_total_price;
    this.setState({
      stevedore_costing: row,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var stevedore_costing = this.state.stevedore_costing;
    var businessNoID = this.props.businessNoID;

    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    for (var i = 0; i < stevedore_costing.length; i++) {
      stevedore_costing[i].costing_files = costing_file;
    }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");

    if (businessNoID) {
      api
        .add_mv_purchase_financials_stevedore(
          loginUserID,
          idToken,
          businessNoID,
          stevedore_costing,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse({
                        screen: "MvStevedoreCosting",
                        code: res.code,
                        message: "",
                      });
                    }, 2000);
                  }
                );
                //window.location.href = "/mv-purchase-financial-view-stevedore-costing/" + btoa(businessNoID);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    } else {
      this.setState({
        errorMsg: "Invalid business no.",
        snackBarErrorOpen: true,
      });
    }
  };

  removeRowsHandler = (idx, index) => () => {
    const row = this.state.stevedore_costing[idx].others;
    row.splice(index, 1);
    this.setState({
      stevedore_costing: this.state.stevedore_costing,
    });
  };

  addMoreRowsHandler = (idx) => {
    const item = { description: "", price: "", remarks: "" };
    var otherRows = this.state.stevedore_costing[idx].others;
    let data = [...otherRows, item];
    //this.state.purchaseTypeRows[idx].purchase_types = data;
    let newState = Object.assign({}, this.state);
    newState.stevedore_costing[idx].others = data;
    this.setState({
      newState,
    });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Add Stevedore Costing</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.stevedore_costing.map((e, idx) => (
                  <div className="col-lg-12 mb-2 p-0" key={idx}>
                    {/* <div className='row col-lg-12 p-0'>
                    <h5>{e.stevedore_vendor_name}</h5>
                  </div> */}
                    <div className="row">
                      <div className="row col-lg-12 p-0 pb-2">
                        {this.state.vendors.length === 0 ? (
                          <span>No vendors available</span>
                        ) : (
                          <div className="col-lg-12 p-0">
                            {this.state.vendors.length === 1 ? (
                              <h5>{e.stevedore_vendor_name}</h5>
                            ) : (
                              <div className="col-lg-6 p-0">
                                <label className="form_label mb-0">
                                  Vendor
                                </label>
                                <TextField
                                  name="stevedore_vendor_id"
                                  placeholder="Billing Currency"
                                  margin="dense"
                                  variant="outlined"
                                  value={e.stevedore_vendor_id}
                                  onChange={(e) => {
                                    var row = this.state.stevedore_costing;
                                    row[idx].stevedore_vendor_id =
                                      e.target.value;
                                    if (e.target.value !== "0") {
                                      let stevedore_vendor =
                                        this.state.vendors.filter(
                                          (v, idx) => e.target.value === v.id
                                        );
                                      row[idx].stevedore_vendor_name =
                                        stevedore_vendor[0].vendor_name;
                                    }
                                    this.setState({
                                      stevedore_vendor: row,
                                    });
                                  }}
                                  fullWidth
                                  select
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  {this.state.vendorData}
                                </TextField>
                              </div>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                        <div className="row mb-3 ">
                          <div className="col-lg-6 pl-0">
                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label className="form_label mb-0">
                                  Quantity in MT
                                </label>
                                <TextField
                                  name="quantity_in_mt"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={e.quantity}
                                  fullWidth
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    const quan = /^\d*(\.\d{0,3})?$/;
                                    if (
                                      e.target.value === "" ||
                                      quan.test(e.target.value)
                                    ) {
                                      var row = this.state.stevedore_costing;
                                      row[idx].quantity = e.target.value;
                                      this.setState(
                                        {
                                          stevedore_costing: row,
                                        },
                                        () =>
                                          this.StevedoreCalculationHandler(idx)
                                      );
                                    }
                                  }}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 pr-0">
                                <label className="form_label mb-0">
                                  Billing Currency
                                </label>
                                <TextField
                                  name="billing_currency"
                                  placeholder="Billing Currency"
                                  margin="dense"
                                  variant="outlined"
                                  value={e.billing_currency}
                                  onChange={(e) => {
                                    var row = this.state.stevedore_costing;
                                    row[idx].billing_currency = e.target.value;
                                    if (
                                      (config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL") &&
                                      row[idx].billing_currency === "USD"
                                    ) {
                                      row[idx].currency_exchange_rate = "";
                                    }
                                    if (
                                      (config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") &&
                                      row[idx].billing_currency === "IDR"
                                    ) {
                                      row[idx].currency_exchange_rate = "";
                                    }
                                    this.setState(
                                      {
                                        stevedore_costing: row,
                                      },
                                      () => {
                                        this.StevedoreCalculationHandler(idx);
                                      }
                                    );
                                  }}
                                  fullWidth
                                  select
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                >
                                  <MenuItem value="0">Please Select</MenuItem>
                                  <MenuItem value="USD">USD</MenuItem>
                                  <MenuItem value="IDR">IDR</MenuItem>
                                </TextField>
                              </div>

                              <div className="col-lg-6 p-0">
                                <label className="form_label mb-0">
                                  Price per MT
                                </label>
                                <TextField
                                  name="stevedore_price_pmt"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={e.stevedore_price_pmt}
                                  fullWidth
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.stevedore_costing;
                                      row[idx].stevedore_price_pmt =
                                        e.target.value;
                                      this.setState(
                                        {
                                          stevedore_price_pmt: e.target.value,
                                          stevedore_base_price: (
                                            Number(this.state.quantity_in_mt) *
                                            Number(e.target.value)
                                          ).toFixed(4),
                                          basePrice: (
                                            Number(this.state.quantity_in_mt) *
                                            Number(e.target.value)
                                          ).toFixed(4),
                                          stevedore_costing: row,
                                        },
                                        () =>
                                          this.StevedoreCalculationHandler(idx)
                                      );
                                    }
                                  }}
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {e.billing_currency !== "0"
                                          ? e.billing_currency
                                          : ""}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                              </div>
                              {(config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                e.billing_currency !== "USD" &&
                                e.billing_currency !== "0" && (
                                  <div className="col-lg-6 pr-0">
                                    <label className="form_label mb-0">
                                      Exchange Rate
                                    </label>
                                    <TextField
                                      name="currency_exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      value={e.currency_exchange_rate}
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        const cr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          cr.test(e.target.value)
                                        ) {
                                          var row =
                                            this.state.stevedore_costing;
                                          row[idx].currency_exchange_rate =
                                            e.target.value;
                                          this.setState(
                                            {
                                              stevedore_costing: row,
                                            },
                                            () =>
                                              this.StevedoreCalculationHandler(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}

                              {(config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                e.billing_currency !== "IDR" &&
                                e.billing_currency !== "0" && (
                                  <div className="col-lg-6 pr-0">
                                    <label className="form_label mb-0">
                                      Exchange Rate
                                    </label>
                                    <TextField
                                      name="currency_exchange_rate"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      value={e.currency_exchange_rate}
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        const cr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          cr.test(e.target.value)
                                        ) {
                                          var row =
                                            this.state.stevedore_costing;
                                          row[idx].currency_exchange_rate =
                                            e.target.value;
                                          this.setState(
                                            {
                                              stevedore_costing: row,
                                            },
                                            () =>
                                              this.StevedoreCalculationHandler(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              <div className="col-lg-12  p-0">
                                <label className="form_label mb-0">
                                  VAT Applicability
                                </label>
                                <RadioGroup
                                  aria-label="vat_applicability"
                                  name="vat_applicability"
                                  onChange={(e) => {
                                    var row = this.state.stevedore_costing;
                                    row[idx].vat_applicability = e.target.value;
                                    this.setState(
                                      {
                                        stevedore_costing: row,
                                      },
                                      () => {
                                        this.StevedoreCalculationHandler(idx);
                                      }
                                    );
                                  }}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="Inclusive"
                                    control={
                                      <NewRadio
                                        checked={
                                          e.vat_applicability === "Inclusive"
                                        }
                                      />
                                    }
                                    label="Inclusive"
                                  />
                                  <FormControlLabel
                                    value="Exclusive"
                                    control={
                                      <NewRadio
                                        checked={
                                          e.vat_applicability === "Exclusive"
                                        }
                                      />
                                    }
                                    label="Exclusive"
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-lg-6 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Stevedore Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_base_price
                                    ? toLocaleString(e.stevedore_base_price)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-4 pr-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {/* {e.stevedore_vat_percent
                                    ? toLocaleString(e.stevedore_vat_percent)
                                    : "-"} */}
                                  <TextField
                                    name="stevedore_vat_percent"
                                    margin="dense"
                                    type="number"
                                    value={e.stevedore_vat_percent}
                                    fullWidth
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      const cr = /^\d*(\.\d{0,10})?$/;
                                      if (
                                        e.target.value === "" ||
                                        cr.test(e.target.value)
                                      ) {
                                        var row =
                                          this.state.stevedore_costing;
                                        row[idx].stevedore_vat_percent =
                                          e.target.value;
                                        this.setState(
                                          {
                                            stevedore_vat_percent: row,
                                          },
                                          () =>
                                            this.StevedoreCalculationHandler(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-4 p-0 mb-2">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_vat_value
                                    ? toLocaleString(e.stevedore_vat_value)
                                    : "-"}
                                </div>
                              </div>
                            </div>
                            {!(
                              config.company === "SRPL" ||
                              config.company === "AVS" ||
                              config.company === "PRPL"
                            ) && (
                                <div className="row mb-2">
                                  <div className="col-lg-4 p-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Total
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.stevedore_total
                                        ? toLocaleString(e.stevedore_total)
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg pr-0">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @ 2%
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.stevedore_pph23_value
                                        ? toLocaleString(e.stevedore_pph23_value)
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                              )}

                            <div className="row">
                              <div
                                className={
                                  config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "col-lg-6 p-0"
                                    : "col-lg-6 p-0"
                                }
                              >
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_payable_to_vendor
                                    ? toLocaleString(
                                      e.stevedore_payable_to_vendor
                                    )
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                              <div
                                className={
                                  config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "col-lg-6 pr-0"
                                    : "col-lg-6 pr-0"
                                }
                              >
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.stevedore_cost_to_company
                                    ? toLocaleString(
                                      e.stevedore_cost_to_company
                                    )
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="row mb-3 ">
                          <div className="row col-lg-12 p-0">
                            <h5>Additional Charges</h5>
                          </div>
                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-6 p-0">
                                  <label className="form_label mb-3">
                                    Additional Days
                                  </label>
                                  <div>
                                    {e.stevedore_addl_days
                                      ? toLocaleString(e.stevedore_addl_days)
                                      : "-"}
                                  </div>
                                </div>

                                <div className="col-lg-6 pr-0">
                                  <label className="form_label mb-0">
                                    Price Per Day
                                  </label>
                                  <TextField
                                    name="stevedore_price_per_addl_day"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={e.stevedore_price_per_addl_day}
                                    fullWidth
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {e.billing_currency !== "0"
                                            ? e.billing_currency
                                            : ""}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      const pr = /^\d*(\.\d{0,4})?$/;
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.stevedore_costing;
                                        row[idx].stevedore_price_per_addl_day =
                                          e.target.value;
                                        this.setState(
                                          {
                                            stevedore_costing: row,
                                          },
                                          () =>
                                            this.StevedoreCalculationHandler(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    VAT Applicability
                                  </label>
                                  <RadioGroup
                                    aria-label="addl_day_vat_applicability"
                                    name="addl_day_vat_applicability"
                                    onChange={(e) => {
                                      var row = this.state.stevedore_costing;
                                      row[idx].addl_day_vat_applicability =
                                        e.target.value;
                                      this.setState(
                                        { stevedore_costing: row },
                                        () =>
                                          this.StevedoreCalculationHandler(idx)
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Inclusive"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.addl_day_vat_applicability ===
                                            "Inclusive"
                                          }
                                        />
                                      }
                                      label="Inclusive"
                                    />
                                    <FormControlLabel
                                      value="Exclusive"
                                      control={
                                        <NewRadio
                                          checked={
                                            e.addl_day_vat_applicability ===
                                            "Exclusive"
                                          }
                                        />
                                      }
                                      label="Exclusive"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Stevedore Additional Days Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_base_price
                                      ? toLocaleString(
                                        e.stevedore_addl_base_price
                                      )
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {/* {e.stevedore_addl_vat_percent
                                      ? toLocaleString(
                                          e.stevedore_addl_vat_percent
                                        )
                                      : "-"} */}
                                    <TextField
                                      name="stevedore_addl_vat_percent"
                                      margin="dense"
                                      type="number"
                                      value={e.stevedore_addl_vat_percent}
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        const cr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          cr.test(e.target.value)
                                        ) {
                                          var row =
                                            this.state.stevedore_costing;
                                          row[idx].stevedore_addl_vat_percent =
                                            e.target.value;
                                          this.setState(
                                            {
                                              stevedore_addl_vat_percent: row,
                                            },
                                            () =>
                                              this.StevedoreCalculationHandler(
                                                idx
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_vat_value
                                      ? toLocaleString(
                                        e.stevedore_addl_vat_value
                                      )
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="row mb-2">
                                    <div className="col-lg-4 p-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Total
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.stevedore_addl_total
                                          ? toLocaleString(e.stevedore_addl_total)
                                          : "-"}
                                      </div>
                                    </div>

                                    <div className="col-lg pr-0">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Includes PPH23 @ 2%
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {e.stevedore_addl_pph23_value
                                          ? toLocaleString(
                                            e.stevedore_addl_pph23_value
                                          )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="row">
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 p-0"
                                      : "col-lg-6 p-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_payable_to_vendor
                                      ? toLocaleString(
                                        e.stevedore_addl_payable_to_vendor
                                      )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 pr-0"
                                      : "col-lg-6 pr-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.stevedore_addl_cost_to_company
                                      ? toLocaleString(
                                        e.stevedore_addl_cost_to_company
                                      )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <hr />

                        <div className="row mb-3">
                          <div className="row col-lg-12 p-0">
                            <h5>PNBP Charges</h5>
                          </div>

                          <div className="row col-lg-12 p-0 mb-2">
                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-0">
                                PNBP Per MT
                              </label>
                              <TextField
                                name="pnbp_per_mt"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.pnbp_per_mt}
                                fullWidth
                                inputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {e.billing_currency !== "0"
                                        ? e.billing_currency
                                        : ""}
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.stevedore_costing;
                                    row[idx].pnbp_per_mt = e.target.value;
                                    this.setState(
                                      {
                                        stevedore_costing: row,
                                      },
                                      () => {
                                        this.StevedoreCalculationHandler(idx);
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>

                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-3">PNBP</label>
                              <div className="">
                                {e.pnbp ? toLocaleString(e.pnbp) : 0}{" "}
                                {config.company === "SRPL" || config.company === "AVS"  ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}{" "}
                                {e.pnbp_company_to_billing
                                  ? "( " +
                                    toLocaleString(e.pnbp_company_to_billing) +
                                    " " +
                                    e.billing_currency +
                                    " )"
                                  : null}
                              </div>
                            </div>
                          </div>

                          <div className="row col-lg-12 p-0">
                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-0">
                                JASA PBM Calculation
                              </label>
                              <RadioGroup
                                aria-label="jasa_pbm_calculation"
                                name="jasa_pbm_calculation"
                                onChange={(e) => {
                                  var row = this.state.stevedore_costing;
                                  row[idx].jasa_pbm_calculation =
                                    e.target.value;

                                  this.setState(
                                    {
                                      stevedore_costing: row,
                                    },
                                    () => {
                                      this.StevedoreCalculationHandler(idx);
                                    }
                                  );
                                }}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="Lumpsum"
                                  control={
                                    <NewRadio
                                      checked={
                                        e.jasa_pbm_calculation === "Lumpsum"
                                      }
                                    />
                                  }
                                  label="Lumpsum"
                                />
                                <FormControlLabel
                                  value="Per MT"
                                  control={
                                    <NewRadio
                                      checked={
                                        e.jasa_pbm_calculation === "Per MT"
                                      }
                                    />
                                  }
                                  label="Per MT"
                                />
                              </RadioGroup>
                            </div>

                            {e.jasa_pbm_calculation === "Lumpsum" && (
                              <div className="col-lg-6 pl-0">
                                <label className="form_label mb-0">
                                  JASA PBM Value
                                </label>
                                <TextField
                                  name="jasa_pbm_value"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={e.jasa_pbm_value}
                                  fullWidth
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {e.billing_currency !== "0"
                                          ? e.billing_currency
                                          : ""}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="JASA PBM Value"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.stevedore_costing;
                                      row[idx].jasa_pbm_value = e.target.value;

                                      this.setState({
                                        stevedore_costing: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            )}
                            {e.jasa_pbm_calculation === "Per MT" && (
                              <div className="col-lg-6 pl-0">
                                <label className="form_label mb-0">
                                  JASA PBM Per MT
                                </label>
                                <TextField
                                  name="jasa_pbm_pmt"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={e.jasa_pbm_pmt}
                                  fullWidth
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {e.billing_currency !== "0"
                                          ? e.billing_currency
                                          : ""}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.stevedore_costing;
                                      row[idx].jasa_pbm_pmt = e.target.value;

                                      this.setState(
                                        {
                                          stevedore_costing: row,
                                        },
                                        () => {
                                          this.StevedoreCalculationHandler(idx);
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}

                            {e.jasa_pbm_calculation === "Per MT" && (
                              <div className="col-lg-6 pl-0">
                                <label className="form_label mb-3">
                                  JASA PBM Value
                                </label>
                                <div>
                                  {e.jasa_pbm_value
                                    ? toLocaleString(e.jasa_pbm_value)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS"  ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}{" "}
                                  {e.jasa_pbm_company_to_billing
                                    ? "( " +
                                      toLocaleString(
                                        e.jasa_pbm_company_to_billing
                                      ) +
                                      " " +
                                      e.billing_currency +
                                      " )"
                                    : null}
                                </div>
                              </div>
                            )}
                          </div>
                        </div> */}

                        <hr />

                        <div className="row mb-3">
                          <div className="row col-lg-12 p-0">
                            <h5>Waiting Period</h5>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 p-0">
                              <label className="form_label mb-0">
                                No Of People
                              </label>
                              <TextField
                                name="wait_period_people"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.wait_period_people}
                                placeholder="Enter Value"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                onChange={(e) => {
                                  var row = this.state.stevedore_costing;
                                  row[idx].wait_period_people = e.target.value;
                                  this.setState(
                                    {
                                      stevedore_costing: row,
                                    },
                                    () => {
                                      this.StevedoreCalculationHandler(idx);
                                    }
                                  );
                                }}
                              />
                            </div>

                            <div className="col-lg-6 pr-0">
                              <label className="form_label mb-0">
                                No Of Hours
                              </label>
                              <TextField
                                name="wait_period_hours"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.wait_period_hours}
                                placeholder="Enter Value"
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                onChange={(e) => {
                                  var row = this.state.stevedore_costing;
                                  row[idx].wait_period_hours = e.target.value;
                                  this.setState(
                                    {
                                      stevedore_costing: row,
                                    },
                                    () => {
                                      this.StevedoreCalculationHandler(idx);
                                    }
                                  );
                                }}
                              />
                            </div>

                            <div className="col-lg-6 p-0">
                              <label className="form_label mb-0">
                                Price Per Hour
                              </label>
                              <TextField
                                name="wait_period_price_per_hour"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.wait_period_price_per_hour}
                                placeholder="Enter value"
                                fullWidth
                                inputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {e.billing_currency !== "0"
                                        ? e.billing_currency
                                        : ""}
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.stevedore_costing;
                                    row[idx].wait_period_price_per_hour =
                                      e.target.value;

                                    this.setState(
                                      {
                                        stevedore_costing: row,
                                      },
                                      () => {
                                        this.StevedoreCalculationHandler(idx);
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>

                            <div className="col-lg-6 pr-0">
                              <label className="form_label mb-3">
                                Total Price
                              </label>
                              <div>
                                {e.wait_period_total_price
                                  ? toLocaleString(e.wait_period_total_price)
                                  : 0}{" "}
                                {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div className="row">
                          <div className="row col-lg-12 p-0">
                            <h5>Others</h5>
                          </div>

                          {e.others.map((p, index) => (
                            <div className="row mb-1" key={index}>
                              <div className="col-lg-11 p-2 border">
                                <div className="row">
                                  <div className="col-lg-6 p-0">
                                    <label className="form_label mb-0">
                                      Description
                                    </label>
                                    <TextField
                                      name="description"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={p.description}
                                      placeholder="Description"
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          textTransform: "uppercase",
                                        },
                                      }}
                                      onChange={(e) => {
                                        var rows =
                                          this.state.stevedore_costing[idx]
                                            .others;
                                        rows[index].description =
                                          e.target.value;
                                        this.setState({
                                          stevedore_costing:
                                            this.state.stevedore_costing,
                                        });
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-6 pr-0">
                                    <label className="form_label mb-0">
                                      Price
                                    </label>
                                    <TextField
                                      name="price"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      fullWidth
                                      value={p.price}
                                      placeholder="Enter Value"
                                      inputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                              ? "USD"
                                              : "IDR"}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      onChange={(e) => {
                                        const cr = /^\d*(\.\d{0,4})?$/;
                                        if (
                                          e.target.value === "" ||
                                          cr.test(e.target.value)
                                        ) {
                                          var rows =
                                            this.state.stevedore_costing[idx]
                                              .others;
                                          rows[index].price = e.target.value;
                                          this.setState({
                                            stevedore_costing:
                                              this.state.stevedore_costing,
                                          });
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      Remarks
                                    </label>
                                    <TextField
                                      name="remarks"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      value={p.remarks}
                                      placeholder="Remarks"
                                      multiline
                                      rows={3}
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                          textTransform: "uppercase",
                                        },
                                      }}
                                      onChange={(e) => {
                                        var rows =
                                          this.state.stevedore_costing[idx]
                                            .others;
                                        rows[index].remarks = e.target.value;
                                        this.setState({
                                          stevedore_costing:
                                            this.state.stevedore_costing,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              {e.others.length > 1 && (
                                <center className="col-lg-1 d-flex justify-content-center">
                                  <i
                                    className="fa fa-trash-o m-auto"
                                    onClick={this.removeRowsHandler(idx, index)}
                                    style={{
                                      fontSize: 24,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                  />
                                </center>
                              )}
                            </div>
                          ))}

                          <div className="row">
                            <button
                              type="button"
                              onClick={() => this.addMoreRowsHandler(idx)}
                              className="header_button header_button_text addrow_button_adjustment w-auto"
                              style={{
                                border: "none",
                                color: config.themeColor,
                              }}
                            >
                              ADD OTHERS
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(this.state.features.includes('532')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Mv_Pur_Fin_Stevedore_Costing"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.setState({}, () =>
                  this.props.returnCallbackDrawerClose(false)
                );
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
              disabled={this.state.vendors.length === 0}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
