import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField, Checkbox } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
var total_chemical_spray_value = 0;

export default class Barge_Purchase_Fin_Chemical_Spray_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      payee_name: "",
      currency_exchange_rate: "",
      local_currency: "",
      remarks: "",
      isLoading: true,
      totalBarges: [],
      bargeList: [],
      activeStep: 0,
      expanded: false,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_Contract",
        },
      ],
      billing_currency: "IDR",
      features: [],
      vendor_drop_value: [],
      selected_vendor: { name: "", value: "" },
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("577") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    try {
      // var chemical_spray_id = decodeURIComponent(window.atob(this.props.match.params.chemicalSprayID));
      const chemical_spray_id = this.props.chemicalSprayID;
      let financial_type = "barge_chemical_spray";
      await api
        .get_barges(loginUserID, idToken, financial_type)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barges) {
                  let barges = res.barges;
                  barges.sort(function (a, b) {
                    return (
                      (a.chemical_spray_costing === null) -
                      (b.chemical_spray_costing === null)
                    );
                  });
                  res.barges = barges;
                  const filter_not_costing_barges = res.barges.filter(
                    (e) =>
                      e.chemical_spray === "Yes" &&
                      (e.chemical_spray_costing === null ||
                        e.chemical_spray_costing === chemical_spray_id)
                  );
                  const filter_default_checked_barges =
                    filter_not_costing_barges.filter(
                      (e) => e.chemical_spray_costing === chemical_spray_id
                    );
                  let bargeList = [];
                  for (var i of filter_default_checked_barges) {
                    bargeList.push(i.id);
                  }
                  if (filter_not_costing_barges.length === 0) {
                    this.setState({
                      snackBarErrorOpen: true,
                      errorMsg: "No records to display",
                      barges: filter_not_costing_barges,
                      totalBarges: filter_not_costing_barges,
                      bargeList: bargeList,
                      isLoading: false,
                    });
                  } else {
                    this.setState({
                      barges: filter_not_costing_barges,
                      totalBarges: filter_not_costing_barges,
                      bargeList: bargeList,
                      isLoading: false,
                    });
                  }
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
      await api.getVendors(loginUserID, idToken, "Active").then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                let vendor_drop_list = [];
                res.vendors.forEach((item) => {
                  if (item.vendor_type === "Others") {
                    vendor_drop_list.push({
                      name: item.vendor_name,
                      value: item.id,
                    });
                  }
                });
                this.setState({ vendor_drop_value: vendor_drop_list });
              }
            }
          });
        }
      });

      await api
        .get_barge_chemical_spray(loginUserID, idToken, chemical_spray_id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_chemical_spray) {
                  this.setState({
                    costing_no: res.barge_chemical_spray[0].costing_no,
                    chemical_spray_price: res.barge_chemical_spray[0]
                      .chemical_spray_price
                      ? res.barge_chemical_spray[0].chemical_spray_price
                      : "",
                    barge_nomination: res.barge_chemical_spray[0]
                      .barge_nomination
                      ? res.barge_chemical_spray[0].barge_nomination
                      : "",
                    currency_exchange_rate: res.barge_chemical_spray[0]
                      .currency_exchange_rate
                      ? res.barge_chemical_spray[0].currency_exchange_rate
                      : "",
                    local_currency: res.barge_chemical_spray[0].local_currency
                      ? res.barge_chemical_spray[0].local_currency
                      : "",
                    payee_name: res.barge_chemical_spray[0].payee_name
                      ? res.barge_chemical_spray[0].payee_name
                      : "",
                    vendor_id: res.barge_chemical_spray[0].vendor_id
                      ? res.barge_chemical_spray[0].vendor_id
                      : "",
                    vendor_name: res.barge_chemical_spray[0].vendor_name
                      ? res.barge_chemical_spray[0].vendor_name
                      : "",
                    remarks: res.barge_chemical_spray[0].remarks
                      ? res.barge_chemical_spray[0].remarks
                      : "",
                    approval_status: res.barge_chemical_spray[0].approval_status
                      ? res.barge_chemical_spray[0].approval_status
                      : null,
                    files: res.barge_chemical_spray[0].files
                      ? res.barge_chemical_spray[0].files
                      : [],
                    selected_vendor: {
                      name: res.barge_chemical_spray[0].vendor_name,
                      value: res.barge_chemical_spray[0].vendor_id,
                    },
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      //window.location.href = '/barge-list';
    }

    document.title =
      config.documentTitle + "Barge Purchase Financial Chemical Spray";
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length === 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  submitHandler = () => {
    var {
      chemical_spray_price,
      payee_name,
      billing_currency,
      currency_exchange_rate,
      local_currency,
      remarks,
      vendor_id,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    // let chemical_spray_id = decodeURIComponent(window.atob(this.props.match.params.chemicalSprayID));
    let chemical_spray_id = this.props.chemicalSprayID;
    let barge_id = this.state.bargeList.toString();
    if (
      config.company === "SDAM" ||
      config.company === "SRK" ||
      config.company === "BTR"
    ) {
      currency_exchange_rate = 1;
      local_currency = chemical_spray_price;
    }
    // vendor_id = vendor_id === "Others" ? null : vendor_id;
    // if (vendor_id && vendor_id !== "Others") {
    //   payee_name = "";
    // }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = row.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = row.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    api
      .update_barge_chemical_spray(
        loginUserID,
        idToken,
        barge_id,
        chemical_spray_id,
        chemical_spray_price,
        vendor_id,
        payee_name,
        billing_currency,
        currency_exchange_rate,
        local_currency,
        remarks,
        existing_file_ids,
        content_files,
        costing_file
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            this.props.onUpdateCosting(res.code);
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMessage: "Costing Updated sucessfully.",
                },
                () => this.props.onUpdateCosting(res.code)
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                isLoading: false,
                errorMsg: res.mesage,
                successMessage: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  onBargesCheck(bargeId, checked, barge) {
    this.setState({ isLoading: true });
    if (checked) {
      total_chemical_spray_value += Number(barge.chemical_spray_price);
      let bargeList = [...this.state.bargeList, bargeId];
      this.setState({
        bargeList: bargeList,
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    } else {
      total_chemical_spray_value -= Number(barge.chemical_spray_price);
      var data = this.state.bargeList;
      if (data.indexOf(bargeId) >= 0) {
        let idx = data.indexOf(bargeId);
        data.splice(idx, 1);
      }
      this.setState({
        bargeList: data,
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    }
  }

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let totalBarges = this.state.totalBarges;
    let bargeList = [];
    if (checked) {
      for (var i of totalBarges) {
        total_chemical_spray_value += Number(i.chemical_spray_price);
        bargeList.push(i.id);
        this.setState({
          bargeList: bargeList,
          chemical_spray_price: total_chemical_spray_value,
          isLoading: false,
        });
      }
    } else {
      total_chemical_spray_value = 0;
      this.setState({
        bargeList: [],
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {`Chemical Spray - ${this.state.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>

          <div className="drawer-section">
            <div className="card p-0">
              <div className="card-body">
                <div className="p-3 border_dashed rounded-lg">
                  {!this.props.updateDocuments && (
                    <div>
                      <Accordion
                        expanded={this.state.expanded}
                        className="mt-0 mb-2"
                        style={{
                          border: "#f7f3f0 1px solid",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#483b34" }} />
                          }
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            height: 15,
                            backgroundColor: "#f7f3f0",
                            borderTopLeftRadius: "3px",
                            borderTopRightRadius: "3px",
                          }}
                          className="text-white pl-1"
                          onClick={() =>
                            this.setState({ expanded: !this.state.expanded })
                          }
                        >
                          <h5 className="mb-0 pl-1">Select Barges</h5>
                        </AccordionSummary>
                        {this.state.totalBarges.length > 0 && (
                          <div className="row">
                            <div
                              className="table border-0"
                              style={{ overflowX: "scroll" }}
                            >
                              <table className="table table-borderless border-0">
                                <thead className="border-0">
                                  <tr>
                                    <th
                                      className="p-2 text-center border-0"
                                      nowrap="true"
                                    >
                                      <CheckBox
                                        //value={this.state.totalBarges}
                                        color="primary"
                                        checked={
                                          this.state.totalBarges.length !== 0 &&
                                          this.state.bargeList.length ===
                                            this.state.totalBarges.length
                                        }
                                        //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                        // onChange={this.onBargesCheck}
                                        onChange={(e) => {
                                          this.onSelectAllBarges(
                                            e.target.checked
                                          );
                                        }}
                                        className="p-0"
                                      />
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-left table_header_barge_label text-uppercase border-0"
                                    >
                                      Barge ID
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-left table_header_barge_label text-uppercase border-0"
                                    >
                                      Barge Name
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-left table_header_barge_label text-uppercase border-0"
                                    >
                                      Jetty
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-left table_header_barge_label text-uppercase border-0"
                                    >
                                      Loading Date
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-left table_header_barge_label text-uppercase border-0"
                                    >
                                      Business No
                                    </th>
                                    <th
                                      style={{ fontSize: 16 }}
                                      nowrap="true"
                                      className="text-right table_header_barge_label text-uppercase border-0"
                                    >
                                      Cost
                                    </th>
                                  </tr>
                                </thead>
                                {this.state.totalBarges.map((p, index) => (
                                  <tbody key={index}>
                                    <tr className="rounded">
                                      <td className="p-0 text-center">
                                        <CheckBox
                                          value={p.id}
                                          color="primary"
                                          checked={
                                            this.state.bargeList.indexOf(
                                              p.id
                                            ) >= 0
                                          }
                                          //disabled={p.barge_pur_fin_id !== null}
                                          onChange={(e) => {
                                            this.onBargesCheck(
                                              e.target.value,
                                              e.target.checked,
                                              p
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>{pad(p.id)}</td>
                                      <td nowrap="true">
                                        {p.barge_nomination}
                                      </td>
                                      <td nowrap="true">{p.loading_jetty}</td>
                                      <td nowrap="true">
                                        {localDateFormate(p.loading_date)}
                                      </td>
                                      <td nowrap="true">{p.business_no}</td>
                                      <td className="text-right" nowrap="true">
                                        {toLocaleString(p.chemical_spray_price)}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))}
                              </table>
                            </div>
                          </div>
                        )}
                      </Accordion>
                      <div className="row mb-0">
                        <div className="col-lg-6 p-0 mb-2">
                          <label className="form_label">Chemical Spray</label>
                          <div className="mt-2">
                            {toLocaleString(this.state.chemical_spray_price) +
                              " IDR"}
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0 mb-2">
                          <label className="form_label mb-0">Vendor Name</label>
                          <Autocomplete
                            options={this.state.vendor_drop_value}
                            getOptionLabel={(option) => pad(option.name)}
                            renderOption={(option, { selected }) => (
                              <React.Fragment>
                                <div className="col-lg p-0 m-0">
                                  {pad(option.name)}
                                </div>
                              </React.Fragment>
                            )}
                            onChange={(event, value) => {
                              if (value) {
                                this.setState({
                                  vendor_id: value.value,
                                  selected_vendor: value,
                                });
                              } else {
                                this.setState({
                                  vendor_id: null,
                                  selected_vendor: {},
                                });
                              }
                            }}
                            // disableCloseOnSelect
                            name="vendor_id"
                            size="small"
                            value={this.state.selected_vendor}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Vendor"
                                variant="outlined"
                                style={{ top: 8 }}
                                fullWidth
                              />
                            )}
                            style={{ display: "contents" }}
                          />
                        </div>

                        {/* {!this.state.vendor_id ||
                          (this.state.vendor_id === "Others" && (
                            <div className="col-lg-6 p-0 mb-2">
                              <div className="row">
                                <label className="form_label">Payee Name</label>
                                <TextField
                                  name="payee_name"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Payee Name"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      textTransform: "uppercase",
                                    },
                                  }}
                                  value={this.state.payee_name}
                                  onChange={(e) => {
                                    this.setState({
                                      payee_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          ))} */}
                      </div>
                      {(config.company === "SRPL" ||
                        config.company === "PRPL" ||
                        config.company === "AVS") && (
                        <div className="row mb-0">
                          <div className="col-lg-6 p-0 mb-2">
                            <div className="row">
                              <label className="form_label">
                                Exchange rate
                              </label>
                              <TextField
                                name="currency_exchange_rate"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Exchange rate"
                                value={this.state.currency_exchange_rate}
                                onChange={(e) => {
                                  const re = /^[0-9]*\.?[0-9]*$/;
                                  if (
                                    e.target.value === "" ||
                                    re.test(e.target.value)
                                  ) {
                                    var value = e.target.value;
                                    if (value === "") {
                                      value = 1;
                                    }
                                    this.setState({
                                      currency_exchange_rate: e.target.value,
                                      local_currency: (
                                        Number(
                                          this.state.chemical_spray_price
                                            ? this.state.chemical_spray_price
                                            : 1
                                        ) / Number(value)
                                      ).toFixed(2),
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-6 pr-0 mb-2">
                            <label className="form_label">Chemical Spray</label>
                            <div className="mt-3">
                              {toLocaleString(this.state.local_currency)}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="row mb-0">
                        <div className="col-lg p-0 mb-2">
                          <div className="row">
                            <label className="form_label">Remarks</label>
                            <TextField
                              name="remarks"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={3}
                              placeholder="Remarks"
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  textTransform: "uppercase",
                                },
                              }}
                              value={this.state.remarks}
                              onChange={(e) => {
                                this.setState({
                                  remarks: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* {(this.state.features.indexOf("364") >= 0 ||
                    this.state.features.indexOf("97") >= 0) && ( */}
                  <FileUpload
                    files={this.state.files}
                    type="Costings"
                    invoice_file={this.state.invoice_file}
                    feature_name="Chemical_spray"
                    callbackFn={(data, mandatoryFiles) => {
                      this.setState({
                        files: data,
                        costing_files: mandatoryFiles,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="submit"
              onClick={() => this.props.onCancel(false)}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn next_button"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}
