import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { TextField, Snackbar, Checkbox, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";

import ReturnCargoAdd from "./ReturnCargoAdd";
import ReturnCargoView from "./ReturnCargoView";
import ReturnCargoUpdate from "./ReturnCargoUpdate";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";
export default class RetuenCargoList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      showDrawer: false,
      pc_contrcts_drop_value: [],
      barge_drop_value: [],
      features: [],
      return_cargo_charges_data: [],
      cargoAdd: false,
      cargoView: false,
      cargoUpdate: false,
      barge_id: null,
      updateDocuments: false,
    };
  }

  onCancel = (value) => {
    if (value === false) {
      this.setState(
        {
          cargoUpdate: false,
          cargoAdd: false,
          cargoView: false,
          showDrawer: false,
          bargeCostingID: null,
        },
        () => {
          this.searchBargeHandler();
        }
      );
    }
  };

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    let financial_type = "barges";
    const get_barge_list = await api.get_barges(
      loginUserID,
      idToken,
      financial_type
    );
    if (get_barge_list.status >= 200 && get_barge_list.status < 300) {
      get_barge_list.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const total_barges = [];
            for (var i of res.barges) {
              total_barges.push({ name: i.id, value: i.id, key: i.id });
            }
            this.setState({
              barge_drop_value: total_barges,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const get_pc_contracts = await api.getPurchaseContracts(
      loginUserID,
      "",
      idToken
    );
    if (get_pc_contracts.status >= 200 && get_pc_contracts.status < 300) {
      get_pc_contracts.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.purchase_contract) {
            let purchaseDataforMenu = [];
            const filteredPContracts = res.purchase_contract.filter(
              (e, indx) => (e.status !== "cancelled" && e.status !== "Closed")
              );
            for (var i of filteredPContracts) {
              purchaseDataforMenu.push({
                name: i.contract_no,
                value: i.id,
                key: i,
                vendor_name: i.vendor_name,
              });
            }

            this.setState({
              pc_contrcts_drop_value: purchaseDataforMenu,
              //purchaseContractList: res.purchase_contract,
              purchaseContractList: filteredPContracts,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_id = this.state.barge_id;
    api
      .get_list_of_return_cargo_charges(loginUserID, idToken, barge_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.return_cargo_charges_data) {
                this.setState({
                  return_cargo_charges_data: res.return_cargo_charges_data,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState({
        showDrawer: false,
        cargoAdd: false,
        cargoView: false,
        cargoUpdate: false,
      });
    }
  };

  onEdit = () => {
    this.setState({
      showDrawer: true,
      cargoAdd: false,
      cargoView: false,
      cargoUpdate: true,
    });
  };

  onUpdateResponse = () => {
    this.setState({
      showDrawer: true,
      cargoAdd: false,
      cargoView: true,
      cargoUpdate: false,
    });
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      cargoUpdate: true,
      cargoView: false,
      cargoAdd: false,
      updateDocuments: true,
    });
  };

  render() {
    let drawerComponent;
    if (this.state.cargoAdd) {
      drawerComponent = (
        <ReturnCargoAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    }
    if (this.state.cargoView && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoView
          returnCargoID={this.state.returnCargoID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    }

    if (this.state.cargoUpdate && this.state.returnCargoID) {
      drawerComponent = (
        <ReturnCargoUpdate
          returnCargoID={this.state.returnCargoID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="row mb-1 p-2 ">
            {/* <div className='col-lg-3 p-0'>
              <Autocomplete
                options={this.state.pc_contrcts_drop_value}
                getOptionLabel={option => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <div className='col-lg p-0 m-0'>
                      <label className='form_label mb-0'>{option.name}</label>
                      <label className='contract_display_header_value m-0 pl-0' style={{ fontSize: 10, paddingLeft: 5 }}>{option.vendor_name ? "( " + option.vendor_name + " )" : null}</label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    this.setState({ purchase_contract_id: value.value });
                  }
                  else {
                    this.setState({ purchase_contract_id: null });
                  }
                }}
                // disableCloseOnSelect
                name='purchase_contract'
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Purchase Contract"
                    label='Purchase Contract'
                    variant="outlined"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: 'contents' }}
              />

            </div> */}
            <div className="col-lg-3 p-0">
              <Autocomplete
                options={this.state.barge_drop_value}
                getOptionLabel={(option) => pad(option.name)}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <span className="form_label mb-0">
                        {pad(option.name)}
                      </span>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    this.setState({ barge_id: value.value });
                  } else {
                    this.setState({ barge_id: null });
                  }
                }}
                //disableCloseOnSelect
                name="barge_id"
                size="small"
                //value={this.state.barge_value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Barge"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>

            <div className="col-lg-2 pr-0 pt-2">
              <button
                onClick={() => this.searchBargeHandler()}
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "18px" }}
              >
                Load
              </button>
            </div>
            {(this.state.features.includes("536")  ||
              this.state.features.includes("97") ) && (
              <div className="col text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "27px" }}
                  onClick={() =>
                    this.setState({ cargoAdd: true, showDrawer: true })
                  }
                >
                  New
                </button>
              </div>
            )}
          </div>

          {this.state.return_cargo_charges_data.length > 0 && (
            <div className="table-responsive">
              <table className="table table-bordered table-sm mb-0">
                <thead className="table-header">
                  <tr>
                    <th className=" text-uppercase" nowrap="true"></th>
                    <th nowrap="true" className=" text-uppercase">
                      Barge ID
                    </th>
                    <th nowrap="true" className=" text-uppercase">
                      Vendor Name
                    </th>
                    <th nowrap="true" className=" text-uppercase">
                      Documentation Total Payable
                    </th>
                    <th nowrap="true" className=" text-uppercase">
                      Export Total Payable
                    </th>
                    <th nowrap="true" className=" text-uppercase">
                      Lapran Total Payable
                    </th>
                    <th nowrap="true" className="text-right  text-uppercase">
                      Total Payable
                    </th>
                    <th nowrap="true" className=" text-uppercase">
                      Paid
                    </th>
                    <th nowrap="true" className="text-right  text-uppercase">
                      Remaining Amount
                    </th>
                  </tr>
                </thead>
                {this.state.return_cargo_charges_data.map((p, index) => (
                  <tbody key={index}>
                    <tr className="rounded">
                      <td className="" nowrap="true">
                        {(this.state.features.includes("538")  ||
                          this.state.features.includes("97") ) && (
                          <i
                            className="fa fa-eye mr-2"
                            aria-hidden="true"
                            style={{
                              fontSize: 22,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.setState({
                                cargoView: true,
                                returnCargoID: p.return_cargo_charges_id,
                                showDrawer: true,
                              })
                            }
                          ></i>
                        )}
                        {(this.state.features.includes("539")  ||
                          this.state.features.includes("97") ) &&
                          p.approval_status === "Pending" && (
                            <i
                              className="fa fa-trash-o"
                              aria-hidden="true"
                              style={{
                                fontSize: 21,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  returnCargoID: p.return_cargo_charges_id,
                                  deleteData: p,
                                  closeDialog: true,
                                })
                              }
                            ></i>
                          )}
                      </td>
                      <td className=" text-left">{pad(p.barge_id)}</td>
                      <td className=" text-left">
                        {p.vendor_name ? p.vendor_name : "-"}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.dc_total)}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.et_total)}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.ls_total)}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.total_payable)}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.paid)}
                      </td>
                      <td className=" text-right" nowrap="true">
                        {this.toLocaleString(p.Remaining_amount)}
                      </td>
                    </tr>
                  </tbody>
                ))}
              </table>
            </div>
          )}
        </div>
      );
    }
  }
}
