import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  Checkbox,
  MenuItem,
  Snackbar,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

// import CoalPriceIndexes from "../../CoalIndexPrices/coalPriceIndexes";
import { pad } from "../../common/common";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default class BusinessNoSalesCoalCosting extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      sales_index_linked: "No",
      coal_cost_billing_currency:
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR",
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      coal_cost_coal_price: "",
      coal_cost_gar_penality: "",
      coal_cost_ash_penality: "",
      coal_cost_sulphur_penality: "",
      coal_cost_gar_bonus: "",
      coal_cost_adjusted_coal_price: "",

      coal_cost_pph22_export: "Yes",
      coal_cost_pph22_export_amount: "",
      coal_cost_pph22_percent: "1.5",
      coal_cost_pph22_percent_val: "",

      coal_cost_pph22_export_percent: "1.5",
      coal_cost_pph22_export_value: "",

      coal_cost_currency_xchg_rate: "",

      coal_cost_adjusted_coal_price_in_local_currency: "",
      vat_applicable: "Yes",
      vat_amount: "",
      vat_percent: "10",
      vat_value: "",
      freight_price_pmt: "",
      receivable_from_supplier: "",
      advance_adjustment: "No",
      adjustment_per_mt: "",
      advance_adjustment_value: "",
      //pph15_account :'0',
      internalPPH23Account: "0",
      coalPriceCurrency: "",
      mother_vesselName: "",
      bargingBasePriceCurrency: "",

      advance_adjustment_barge_quantity: "",
      barge_quantity: "",
      coal_cost_coal_quantity: "",
      // Advance Adjustment Rows
      advance_adjustments: [],

      sales_term: "",

      quality_calorific_value_typical: "",
      quality_calorific_value_rejection: "",
      quality_calorific_value_basis: "",
      quality_calorific_value2_typical: "",
      quality_calorific_value2_rejection: "",
      quality_calorific_value2_basis: "",
      quality_calorific_value3_typical: "",
      quality_calorific_value3_rejection: "",
      quality_calorific_value3_basis: "",

      quality_ash_typical: "",
      quality_ash_rejection: "",
      quality_ash_basis: "",

      quality_total_sulphur_typical: "",
      quality_total_sulphur_rejection: "",
      quality_total_sulphur_basis: "",

      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_ash_actual: "",
      quality_total_sulphur_actual: "",

      // Advance Adjustment Value
      sales_contract_id: "",
      isLoading: true,
      penalityCap: null,
      penalityCap2: null,
      penalityCap3: null,
      features: [],
      salesBargeList: [],
      sales_contracts: [],
      bargeList: [],
      allocationsList: [],
      selectedSalesContractId: "",
      coal_cost_coal_price_pmt_local_currency: "",
      total_barge_quantity: null,
      activeStep: 0,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Sales_fin_commercial_coal_costing",
        },
      ],
      coal_index: {
        coal_index_type: "Weekly",
        index_ids: [],
        average_coal_index_price: 0,
        prorata_gar: "0",
        prorata_coal_index_price: 0,
        bonus_coal_index_pmt: "",
        penality_coal_index_pmt: "",
        derivered_coal_index_pmt: "",
        final_coal_index_pmt: "",
      },
      performa_costings_data: [],
      sc_quality: "",
      costing_files: [],
      performa_mapping: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.indexOf("190") >= 0 || features.indexOf("97") >= 0)) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const businessNoID = this.props.businessNoID;
        const costing_type = this.props.costing_type;

        // get vat value api
        const tax_type = "vat";
        await api
          .get_tax_percentages(loginUserID, idToken, tax_type)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.tax_percentages) {
                    this.setState({
                      vat_percent:
                        res.tax_percentages.length > 0
                          ? res.tax_percentages[0].tax_percentage
                          : 10,
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: res.message,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
        await api
          .get_sc_barge_coal_list(
            loginUserID,
            idToken,
            businessNoID,
            costing_type
          )
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.sales_contracts) {
                    this.setState(
                      {
                        salesBargeList: res.sales_contracts,
                        costing_type: costing_type,
                        isLoading: false,
                      },
                      () => {
                        this.getCommercialData();
                      }
                    );
                  }
                }
              });
            }
          });
      }
      // this.callingAsync();
    }

    document.title =
      config.documentTitle + "Barge Sales Financial Coal Costing";
  }

  getCommercialData() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    let costing_type = this.props.costing_type;
    if (costing_type === "Commercial") {
      costing_type = "Performa";
      api
        .get_sc_barge_coal_list(
          loginUserID,
          idToken,
          businessNoID,
          costing_type
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.sales_contracts) {
                  var sales_contracts = res.sales_contracts;
                  for (let i = 0; i < sales_contracts.length; i++) {
                    let barges = [];
                    let bargeData = [];
                    let sales_contracts_barges = sales_contracts[i].barges;
                    let com_sales_contracts_barges =
                      this.state.salesBargeList[i].barges;
                    let newData = [];
                    let newData1 = [];
                    for (let k = 0; k < sales_contracts_barges.length; k++) {
                      sales_contracts_barges[k].com_barge_sales_fin_id =
                        com_sales_contracts_barges[k].barge_sales_fin_id;
                      sales_contracts_barges[k].com_quality_results =
                        com_sales_contracts_barges[k].quality_results;
                      sales_contracts_barges[k].com_sales_term =
                        com_sales_contracts_barges[k].sales_term;
                      let id = sales_contracts_barges[k].barge_sales_fin_id;
                      if (id !== null) {
                        barges.push(sales_contracts_barges[k]);
                      } else {
                        bargeData.push(sales_contracts_barges[k]);
                      }
                    }
                    var result = barges.reduce(function (r, a) {
                      r[a.barge_sales_fin_id] = r[a.barge_sales_fin_id] || [];
                      r[a.barge_sales_fin_id].push(a);
                      return r;
                    }, Object.create(null));
                    for (let l = 0; l < Object.keys(result).length; l++) {
                      newData.push({
                        new_barges: result[Object.keys(result)[l]],
                      });
                    }

                    var result1 = bargeData.map((r, a) => {
                      return r;
                    });
                    for (let l = 0; l < Object.keys(result1).length; l++) {
                      newData1.push({
                        new_barges: [result1[Object.keys(result1)[l]]],
                      });
                    }
                    sales_contracts[i].new_barges = newData.concat(newData1);
                  }
                  this.setState({
                    sales_contracts: sales_contracts,
                    iosLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  }

  async callingAsync(barge) {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    if (barge) {
      const business_no_id = this.props.businessNoID;
      // const costing_type = this.props.costing_type;
      const business_allocation_id = barge.business_allocation_id;
      const bargeId = barge.business_no_barge_id;
      await api
        .getAllSalesSpecByBargeID(
          loginUserID,
          idToken,
          business_no_id,
          business_allocation_id,
          bargeId
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.scq_typical_specs) {
                  this.setState({
                    garActiualValue: res.scq_typical_specs
                      .qality_calorific_value_actual
                      ? res.scq_typical_specs.qality_calorific_value_actual
                      : "",
                    garTypicalValue: res.scq_typical_specs
                      .quality_calorific_value_typical
                      ? res.scq_typical_specs.quality_calorific_value_typical
                      : "",
                    ashPenality: res.scq_typical_specs.quality_ash_penality
                      ? res.scq_typical_specs.quality_ash_penality
                      : "",
                    sulphurPenality: res.scq_typical_specs
                      .quality_total_sulphur_penality
                      ? res.scq_typical_specs.quality_total_sulphur_penality
                      : "",
                    bonusCap: res.scq_typical_specs
                      .quality_calorific_value_bonus_cap
                      ? res.scq_typical_specs.quality_calorific_value_bonus_cap
                      : "",
                    bonusCap2: res.scq_typical_specs
                      .quality_calorific_value2_bonus_cap
                      ? res.scq_typical_specs.quality_calorific_value2_bonus_cap
                      : "",
                    bonusCap3: res.scq_typical_specs
                      .quality_calorific_value3_bonus_cap
                      ? res.scq_typical_specs.quality_calorific_value3_bonus_cap
                      : "",
                    penalityCap: res.scq_typical_specs
                      .quality_calorific_value_penality_cap
                      ? res.scq_typical_specs
                        .quality_calorific_value_penality_cap
                      : "",
                    penalityCap2: res.scq_typical_specs
                      .quality_calorific_value2_penality_cap
                      ? res.scq_typical_specs
                        .quality_calorific_value2_penality_cap
                      : "",
                    penalityCap3: res.scq_typical_specs
                      .quality_calorific_value3_penality_cap
                      ? res.scq_typical_specs
                        .quality_calorific_value3_penality_cap
                      : "",

                    sales_term: res.sales_term,

                    quality_calorific_value_typical:
                      res.scq_typical_specs.quality_calorific_value_typical,
                    quality_calorific_value_rejection:
                      res.scq_typical_specs.quality_calorific_value_rejection,
                    quality_calorific_value_basis:
                      res.scq_typical_specs.quality_calorific_value_basis,
                    quality_calorific_value2_typical:
                      res.scq_typical_specs.quality_calorific_value2_typical,
                    quality_calorific_value2_rejection:
                      res.scq_typical_specs.quality_calorific_value2_rejection,
                    quality_calorific_value2_basis:
                      res.scq_typical_specs.quality_calorific_value2_basis,
                    quality_calorific_value3_typical:
                      res.scq_typical_specs.quality_calorific_value3_typical,
                    quality_calorific_value3_rejection:
                      res.scq_typical_specs.quality_calorific_value3_rejection,
                    quality_calorific_value3_basis:
                      res.scq_typical_specs.quality_calorific_value3_basis,

                    quality_ash_typical:
                      res.scq_typical_specs.quality_ash_typical,
                    quality_ash_rejection:
                      res.scq_typical_specs.quality_ash_rejection,
                    quality_ash_basis: res.scq_typical_specs.quality_ash_basis,

                    quality_total_sulphur_typical:
                      res.scq_typical_specs.quality_total_sulphur_typical,
                    quality_total_sulphur_rejection:
                      res.scq_typical_specs.quality_total_sulphur_rejection,
                    quality_total_sulphur_basis:
                      res.scq_typical_specs.quality_total_sulphur_basis,
                  });
                } else {
                  this.setState({
                    sales_term: res.sales_term,
                  });
                }
              }
            });
          }
        });

      // get Barge Purchase Quality Results By Barge ID
      await api
        .get_barge_sales_fin_quality_results(
          loginUserID,
          idToken,
          business_no_id,
          business_allocation_id,
          bargeId
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.barge_sales_qualities) {
                  this.setState({
                    barge_pur_quality_id: res.barge_sales_qualities.id,
                    garActiualValue: res.barge_sales_qualities
                      .quality_calorific_value_actual
                      ? res.barge_sales_qualities.quality_calorific_value_actual
                      : "",
                    quality_calorific_value_actual:
                      res.barge_sales_qualities.quality_calorific_value_actual,
                    quality_calorific_value2_actual:
                      res.barge_sales_qualities.quality_calorific_value2_actual,
                    quality_calorific_value3_actual:
                      res.barge_sales_qualities.quality_calorific_value3_actual,
                    quality_ash_actual:
                      res.barge_sales_qualities.quality_ash_actual,
                    quality_total_sulphur_actual:
                      res.barge_sales_qualities.quality_total_sulphur_actual,
                  });
                }
              }
            });
          }
        });

      // Calling Get Business Number Operation Barge Details API
      await api
        .get_business_no_barge(
          bargeId,
          business_allocation_id,
          business_no_id,
          loginUserID,
          idToken
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                var barge_quantity = this.state.coal_cost_coal_quantity;
                if (res.business_no_barge) {
                  // barge_quantity = res.business_no_barge.barge_quantity_in_mt;
                  // if (barge_quantity === null || Number(barge_quantity) <= 0) {
                  //   barge_quantity = res.business_no_barge.barge_planned_quantity_in_mt
                  // }
                  // var sales_type = res.business_no_barge.sales_type;

                  // if (sales_type === "FAS Anchorage" && res.business_no_barge.redraft_quantity !== null && res.business_no_barge.quantity_difference_account === "Supplier") {
                  //   barge_quantity = res.business_no_barge.redraft_quantity
                  // }

                  //Get purchase Coal Price.
                  api
                    .getCoalPriceByBargeID(
                      loginUserID,
                      idToken,
                      bargeId,
                      business_allocation_id
                    )
                    .then((response) => {
                      if (response.status >= 200 && response.status < 300) {
                        response.json().then((res) => {
                          if (res.code === "200") {
                            var garPenality = "";
                            var garBonus = "";
                            var ashPenality = "";
                            var sulphurPenality = "";

                            let garActiualValue = this.state.garActiualValue;
                            let bonusCap = this.state.bonusCap;
                            let penalityCap = this.state.penalityCap;
                            // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
                            var costing_type = this.props.costing_type;
                            if (
                              this.state.sales_term === "Specs" &&
                              costing_type === "Commercial"
                            ) {
                              if (
                                garActiualValue !== null &&
                                this.state.garTypicalValue !== null &&
                                garActiualValue !== "" &&
                                this.state.garTypicalValue !== ""
                              ) {
                                if (
                                  Number(garActiualValue) >
                                  Number(this.state.garTypicalValue)
                                ) {
                                  if (bonusCap !== null && bonusCap !== "") {
                                    if (
                                      Number(garActiualValue) > Number(bonusCap)
                                    ) {
                                      garActiualValue = bonusCap;
                                    }
                                  }
                                } else {
                                  if (
                                    penalityCap !== null &&
                                    penalityCap !== ""
                                  ) {
                                    if (
                                      Number(garActiualValue) <
                                      Number(penalityCap)
                                    ) {
                                      garActiualValue = penalityCap;
                                    }
                                  }
                                }
                                if (
                                  Number(this.state.garActiualValue) >
                                  Number(this.state.garTypicalValue)
                                ) {
                                  garBonus =
                                    ((Number(garActiualValue) -
                                      Number(this.state.garTypicalValue)) /
                                      Number(this.state.garTypicalValue)) *
                                    Number(res.sales_price_pmt);
                                }
                                if (
                                  Number(this.state.garTypicalValue) >
                                  Number(this.state.garActiualValue)
                                ) {
                                  garPenality =
                                    ((Number(this.state.garTypicalValue) -
                                      Number(garActiualValue)) /
                                      Number(this.state.garTypicalValue)) *
                                    Number(res.sales_price_pmt);
                                }
                              }
                              if (
                                this.state.quality_ash_actual !== null &&
                                this.state.quality_ash_actual !== "" &&
                                this.state.quality_ash_typical !== null &&
                                this.state.quality_ash_typical !== ""
                              ) {
                                if (
                                  Number(this.state.quality_ash_actual) >
                                  Number(this.state.quality_ash_typical) &&
                                  this.state.ashPenality !== null &&
                                  this.state.ashPenality !== ""
                                ) {
                                  ashPenality =
                                    Number(this.state.ashPenality) *
                                    ((Number(this.state.quality_ash_actual) -
                                      Number(this.state.quality_ash_typical)) /
                                      1);
                                }
                              }
                              if (
                                this.state.quality_total_sulphur_actual !==
                                null &&
                                this.state.quality_total_sulphur_actual !==
                                "" &&
                                this.state.quality_total_sulphur_typical !==
                                null &&
                                this.state.quality_total_sulphur_typical !== ""
                              ) {
                                if (
                                  Number(
                                    this.state.quality_total_sulphur_actual
                                  ) >
                                  Number(
                                    this.state.quality_total_sulphur_typical
                                  ) &&
                                  this.state.sulphurPenality !== null &&
                                  this.state.sulphurPenality !== ""
                                ) {
                                  sulphurPenality =
                                    Number(this.state.sulphurPenality) *
                                    ((Number(
                                      this.state.quality_total_sulphur_actual
                                    ) -
                                      Number(
                                        this.state.quality_total_sulphur_typical
                                      )) /
                                      0.1);
                                }
                              }
                            }

                            var coal_cost_gar_bonus = "";
                            var coal_cost_gar_penality = "";
                            var coal_cost_ash_penality = "";
                            var coal_cost_sulphur_penality = "";
                            if (
                              garBonus !== "" &&
                              (this.state.coal_cost_gar_bonus === null ||
                                this.state.coal_cost_gar_bonus === "")
                            ) {
                              coal_cost_gar_bonus = garBonus.toFixed(2);
                              this.setState({
                                coal_cost_gar_bonus:
                                  Number(coal_cost_gar_bonus),
                              });
                            }
                            if (
                              garPenality !== "" &&
                              (this.state.coal_cost_gar_penality === null ||
                                this.state.coal_cost_gar_penality === "")
                            ) {
                              coal_cost_gar_penality = garPenality.toFixed(2);
                              this.setState({
                                coal_cost_gar_penality: Number(
                                  coal_cost_gar_penality
                                ),
                              });
                            }
                            if (
                              ashPenality !== "" &&
                              (this.state.coal_cost_ash_penality === null ||
                                this.state.coal_cost_ash_penality === "")
                            ) {
                              coal_cost_ash_penality = ashPenality.toFixed(2);
                              this.setState({
                                coal_cost_ash_penality: Number(
                                  coal_cost_ash_penality
                                ),
                              });
                            }
                            if (
                              sulphurPenality !== "" &&
                              (this.state.coal_cost_sulphur_penality === null ||
                                this.state.coal_cost_sulphur_penality === "")
                            ) {
                              coal_cost_sulphur_penality =
                                sulphurPenality.toFixed(2);
                              this.setState({
                                coal_cost_sulphur_penality: Number(
                                  coal_cost_sulphur_penality
                                ),
                              });
                            }
                            if (
                              (config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                              this.state.coal_cost_billing_currency === "IDR"
                            ) {
                              this.setState({
                                coal_cost_gar_bonus: coal_cost_gar_bonus
                                  ? Number(coal_cost_gar_bonus).toFixed(0)
                                  : null,
                                coal_cost_gar_penality: coal_cost_gar_penality
                                  ? Number(coal_cost_gar_penality).toFixed(0)
                                  : null,
                                coal_cost_ash_penality: coal_cost_ash_penality
                                  ? Number(coal_cost_ash_penality).toFixed(0)
                                  : null,
                                coal_cost_sulphur_penality:
                                  coal_cost_sulphur_penality
                                    ? Number(
                                      coal_cost_sulphur_penality
                                    ).toFixed(0)
                                    : null,
                              });
                            }
                            // this.setState({
                            //   garBonus: coal_cost_gar_bonus ? Number(coal_cost_gar_bonus) : null,
                            //   garPenality: coal_cost_gar_penality ? Number(coal_cost_gar_penality) : null,
                            //   ashPenality: coal_cost_ash_penality ? Number(coal_cost_ash_penality) : null,
                            //   sulphurPenality: coal_cost_sulphur_penality ? Number(coal_cost_sulphur_penality) : null
                            // })
                            if (
                              (config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                              this.state.coal_cost_billing_currency === "IDR"
                            ) {
                              coal_cost_gar_bonus =
                                Number(coal_cost_gar_bonus).toFixed(0);
                              coal_cost_gar_penality = Number(
                                coal_cost_gar_penality
                              ).toFixed(0);
                              coal_cost_ash_penality = Number(
                                coal_cost_ash_penality
                              ).toFixed(0);
                              coal_cost_sulphur_penality = Number(
                                coal_cost_sulphur_penality
                              ).toFixed(0);
                            }
                            var sales_currency = res.sales_currency;
                            let coal_cost_coal_price_pmt = res.sales_price_pmt;

                            var coal_cost_coal_price = "";
                            if (
                              barge_quantity !== null &&
                              barge_quantity !== 0
                            ) {
                              coal_cost_coal_price = (
                                Number(barge_quantity) *
                                Number(res.sales_price_pmt)
                              ).toFixed(2);
                            }

                            var coal_cost_currency_xchg_rate =
                              this.state.coal_cost_currency_xchg_rate;
                            if (coal_cost_currency_xchg_rate === "") {
                              coal_cost_currency_xchg_rate = 1;
                            }

                            let coal_cost_gar_bonus_new = (
                              Number(coal_cost_gar_bonus) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            let coal_cost_gar_penality_new = (
                              Number(coal_cost_gar_penality) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            let coal_cost_ash_penality_new = (
                              Number(coal_cost_ash_penality) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            let coal_cost_sulphur_penality_new = (
                              Number(coal_cost_sulphur_penality) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            let sales_price_pmt = (
                              Number(coal_cost_coal_price_pmt) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            var freight_pmt = 0;

                            if (this.state.sales_type === "CIF DP" || this.state.sales_type === "CFR DP") {
                              let freight = 0;
                              if (this.state.freight_price_pmt !== "") {
                                freight = this.state.freight_price_pmt;
                              } else if (res.freight_price_pmt !== "") {
                                freight = res.freight_price_pmt;
                              }
                              freight_pmt = (
                                Number(freight) *
                                Number(coal_cost_currency_xchg_rate)
                              ).toFixed(2);
                            }

                            var coal_cost_adjusted_coal_price_pmt = Number(
                              Number(sales_price_pmt) +
                              Number(coal_cost_gar_bonus_new) -
                              Number(coal_cost_gar_penality_new) -
                              Number(coal_cost_ash_penality_new) -
                              Number(coal_cost_sulphur_penality_new)
                            ).toFixed(2);

                            // console.log('coal_cost_adjusted_coal_price_pmt', coal_cost_adjusted_coal_price_pmt);
                            // var coal_cost_adjusted_coal_price_pmt = Number(res.sales_price_pmt) + Number(coal_cost_gar_bonus) - Number(coal_cost_gar_penality) - Number(coal_cost_ash_penality) - Number(coal_cost_sulphur_penality);
                            var coal_cost_adjusted_coal_price = (
                              Number(barge_quantity) *
                              Number(coal_cost_adjusted_coal_price_pmt)
                            ).toFixed(2);
                            var coal_cost_adjusted_coal_price_in_local_currency =
                              Number(coal_cost_adjusted_coal_price).toFixed(0);

                            var coal_cost_pph22_export_percent = 1.5;
                            var coal_cost_pph22_export_value = Number(
                              (Number(
                                coal_cost_adjusted_coal_price_in_local_currency
                              ) *
                                Number(coal_cost_pph22_export_percent)) /
                              100
                            ).toFixed(0);

                            var coal_cost_pph22_percent_val = "";
                            var vat_amount = Number(
                              coal_cost_adjusted_coal_price_in_local_currency
                            );
                            var vat_percent = this.state.vat_percent
                              ? this.state.vat_percent
                              : 10;
                            var vat_value =
                              config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                ? 0
                                : (Number(vat_amount) *
                                  Number(this.state.vat_percent)) /
                                100;
                            var total_receivable = (
                              Number(barge_quantity) *
                              Number(coal_cost_adjusted_coal_price_pmt) +
                              Number(vat_value) -
                              Number(coal_cost_pph22_percent_val) -
                              Number(coal_cost_pph22_export_value)
                            ).toFixed(2);
                            //console.log("total_receivable", total_receivable)
                            //console.log("freight_pmt", freight_pmt)
                            var receivable_from_supplier = (
                              Number(total_receivable) +
                              Number(freight_pmt) * Number(barge_quantity)
                            ).toFixed(2);
                            var coal_cost_pph22_export_value_local_cur = "";
                            var coal_cost_pph22_percent_val_local_cur = "";

                            if (
                              ((config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                                sales_currency !== "IDR") ||
                              ((config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL") &&
                                sales_currency !== "USD")
                            ) {
                              if (
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) {
                                coal_cost_pph22_export_value_local_cur =
                                  coal_cost_pph22_export_value;
                                total_receivable = Number(
                                  Number(
                                    coal_cost_adjusted_coal_price_in_local_currency
                                  ) +
                                  Number(vat_value) -
                                  Number(
                                    coal_cost_pph22_export_value_local_cur
                                  )
                                ).toFixed(2);
                                receivable_from_supplier = (
                                  Number(total_receivable) +
                                  Number(freight_pmt) * Number(barge_quantity)
                                ).toFixed(2);
                              } else {
                                let total_pph22_local_amount =
                                  Number(barge_quantity) *
                                  Number(coal_cost_adjusted_coal_price_pmt);
                                coal_cost_pph22_percent_val = Number(
                                  (Number(total_pph22_local_amount) *
                                    Number(
                                      this.state.coal_cost_pph22_percent
                                    )) /
                                  100
                                );
                                coal_cost_pph22_percent_val_local_cur = Number(
                                  coal_cost_pph22_percent_val
                                ).toFixed(0);

                                coal_cost_pph22_export_value = Number(
                                  (Number(coal_cost_adjusted_coal_price) *
                                    Number(coal_cost_pph22_export_percent)) /
                                  100
                                );
                                coal_cost_pph22_export_value_local_cur = Number(
                                  coal_cost_pph22_export_value
                                ).toFixed(0);
                                total_receivable = (
                                  Number(
                                    coal_cost_adjusted_coal_price_in_local_currency
                                  ) +
                                  Number(vat_value) -
                                  Number(
                                    coal_cost_pph22_percent_val_local_cur
                                  ) -
                                  Number(coal_cost_pph22_export_value_local_cur)
                                ).toFixed(2);
                                receivable_from_supplier = (
                                  Number(total_receivable) +
                                  Number(freight_pmt) * Number(barge_quantity)
                                ).toFixed(2);
                              }
                            } else {
                              if (
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) {
                                coal_cost_pph22_export_value_local_cur =
                                  coal_cost_pph22_export_value;
                                total_receivable = Number(
                                  Number(
                                    coal_cost_adjusted_coal_price_in_local_currency
                                  ) +
                                  Number(vat_value) -
                                  Number(
                                    coal_cost_pph22_export_value_local_cur
                                  )
                                ).toFixed(2);
                                receivable_from_supplier = (
                                  Number(total_receivable) +
                                  Number(freight_pmt) * Number(barge_quantity)
                                ).toFixed(2);
                              } else {
                                let total_pph22_local_amount =
                                  Number(barge_quantity) *
                                  Number(coal_cost_adjusted_coal_price_pmt);
                                coal_cost_pph22_percent_val = Number(
                                  (Number(total_pph22_local_amount) *
                                    Number(
                                      this.state.coal_cost_pph22_percent
                                    )) /
                                  100
                                );
                                coal_cost_pph22_percent_val_local_cur = Number(
                                  coal_cost_pph22_percent_val
                                ).toFixed(0);

                                coal_cost_pph22_export_value = Number(
                                  (Number(coal_cost_adjusted_coal_price) *
                                    Number(coal_cost_pph22_export_percent)) /
                                  100
                                );
                                coal_cost_pph22_export_value_local_cur = Number(
                                  coal_cost_pph22_export_value
                                ).toFixed(0);
                                total_receivable = (
                                  Number(
                                    coal_cost_adjusted_coal_price_in_local_currency
                                  ) +
                                  Number(vat_value) -
                                  Number(
                                    coal_cost_pph22_percent_val_local_cur
                                  ) -
                                  Number(coal_cost_pph22_export_value_local_cur)
                                ).toFixed(0);
                                receivable_from_supplier = (
                                  Number(total_receivable) +
                                  Number(freight_pmt) * Number(barge_quantity)
                                ).toFixed(2);
                              }
                            }
                            let coal_cost_coal_price_pmt_local_currency = (
                              Number(res.sales_price_pmt) *
                              Number(coal_cost_currency_xchg_rate)
                            ).toFixed(2);
                            if (
                              (config.company === "SDAM" ||
                                config.company === "SRK" ||
                                config.company === "BTR") &&
                              this.state.coal_cost_billing_currency === "IDR"
                            ) {
                              coal_cost_coal_price_pmt_local_currency = (
                                Number(res.sales_price_pmt) *
                                Number(coal_cost_currency_xchg_rate)
                              ).toFixed(0);
                              coal_cost_adjusted_coal_price_pmt = Number(
                                coal_cost_adjusted_coal_price_pmt
                              ).toFixed(0);
                              coal_cost_adjusted_coal_price = Number(
                                coal_cost_adjusted_coal_price
                              ).toFixed(0);
                            }
                            this.setState(
                              {
                                sales_index_linked: res.sales_index_linked,
                                sc_quality: res.sc_quality,
                                freight_price_pmt: Number(res.freight_price_pmt).toFixed(2),
                                // coal_cost_coal_quantity: barge_quantity ? barge_quantity : '',
                                coal_cost_coal_price: Number(
                                  Number(coal_cost_coal_price) *
                                  Number(coal_cost_currency_xchg_rate)
                                ).toFixed(2),
                                coalPriceCurrency: res.sales_currency,
                                sales_currency: res.sales_currency,
                                coal_cost_coal_price_pmt_local_currency:
                                  coal_cost_coal_price_pmt_local_currency,
                                sales_price_pmt: Number(
                                  res.sales_price_pmt
                                ).toFixed(0),
                                coal_cost_coal_price_pmt: Number(
                                  res.sales_price_pmt
                                ).toFixed(2),
                                coal_cost_currency_xchg_rate:
                                  coal_cost_currency_xchg_rate !== 1
                                    ? Number(coal_cost_currency_xchg_rate)
                                    : "",

                                coal_cost_adjusted_coal_price:
                                  coal_cost_adjusted_coal_price,
                                coal_cost_adjusted_coal_price_pmt: Number(
                                  coal_cost_adjusted_coal_price_pmt
                                ),
                                coal_cost_adjusted_coal_price_in_local_currency:
                                  coal_cost_adjusted_coal_price_in_local_currency,
                                vat_amount: vat_amount,
                                vat_percent: vat_percent,
                                vat_value: vat_value,
                                receivable_from_supplier:
                                  receivable_from_supplier,

                                coal_cost_pph22_barge_quantity_in_mt:
                                  barge_quantity ? Number(barge_quantity) : "",
                                coal_cost_pph22_coal_price_per_mt: Number(
                                  coal_cost_adjusted_coal_price_pmt
                                ),
                                coal_cost_pph22_percent_val: Number(
                                  coal_cost_pph22_percent_val
                                ).toFixed(0),
                                coal_cost_pph22_percent_val_local_cur:
                                  coal_cost_pph22_percent_val_local_cur,
                                coal_cost_pph22_percent_val_local_cur_new:
                                  coal_cost_pph22_percent_val_local_cur,

                                coal_cost_pph22_export_amount: Number(
                                  coal_cost_adjusted_coal_price
                                ),
                                coal_cost_pph22_export_percent:
                                  coal_cost_pph22_export_percent,
                                coal_cost_pph22_export_value: Number(
                                  coal_cost_pph22_export_value
                                ).toFixed(0),
                                coal_cost_pph22_export_value_local_cur: Number(
                                  coal_cost_pph22_export_value_local_cur
                                ).toFixed(0),
                                coal_cost_pph22_export_value_local_cur_new:
                                  Number(
                                    coal_cost_pph22_export_value_local_cur
                                  ).toFixed(0),
                              },
                              () => {
                                this.onAdvanceAdjustmentsCal();
                              }
                            );
                          }
                        });
                      }
                    });

                  this.setState({
                    mother_vesselName: res.business_no_barge.mother_vessel_name
                      ? res.business_no_barge.mother_vessel_name
                      : "",
                    bargeNomination: res.business_no_barge.barge_nomination
                      ? res.business_no_barge.barge_nomination
                      : "",
                    businessNoID: res.business_no_barge.business_no_id,
                    sales_contract_no: res.business_no_barge.sales_contract_no,
                    remaining_cus_adv_payment: res.business_no_barge
                      .remaining_cus_adv_payment
                      ? res.business_no_barge.remaining_cus_adv_payment
                      : 0,
                    sales_index_linked:
                      res.business_no_barge.sales_index_linked,
                    sc_quality: res.business_no_barge.sc_quality,

                    barge_quantity: barge_quantity,
                    // advance_adjustment_value: Number(redraftQuantity).toFixed(4),//res.business_no_barge.redraft_quantity ? res.business_no_barge.redraft_quantity:'',
                    sales_type: res.business_no_barge.sales_type,
                    purchase_type: res.business_no_barge.purchase_type,
                    // isLoading: false
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              }
            });
          }
        });
    } else {
      alert("Invalid barge ID");
    }
  }

  handleRadioButtons = (e) => {
    if (e.target.value === "No") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_pph22_export_value: 0,
          coal_cost_pph22_export_value_local_cur: 0,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    } else {
      this.setState(
        {
          [e.target.name]: e.target.value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  submitHandler = (e) => {
    this.setState({ isLoading: true });
    var {
      invoice_no,
      invoice_date,
      ppn,
      coal_cost_coal_quantity,
      coal_cost_coal_price,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_adjusted_coal_price,
      coal_cost_currency_xchg_rate,
      coal_cost_adjusted_coal_price_in_local_currency,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_pph22_percent_val,
      coal_cost_pph22_export,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export_value,
      advance_adjustments,
      freight_price_pmt,
      receivable_from_supplier,
      sales_index_linked,
      coal_cost_billing_currency,
      vat_applicable,
      vat_amount,
      vat_percent,
      vat_value,
      performa_mapping,
    } = this.state;
    coal_cost_currency_xchg_rate = this.state.currency_xchg_rate;
    if (coal_cost_gar_bonus === "") {
      coal_cost_gar_bonus = null;
    }
    if (coal_cost_gar_penality === "") {
      coal_cost_gar_penality = null;
    }
    if (coal_cost_ash_penality === "") {
      coal_cost_ash_penality = null;
    }
    if (coal_cost_sulphur_penality === "") {
      coal_cost_sulphur_penality = null;
    }
    if (sales_index_linked === "Yes") {
      if (coal_cost_coal_price_pmt === "") {
        this.setState({ isLoading: false });
        alert("Coal Price per MT cannot be empty");
        return;
      }
    }
    if (
      coal_cost_currency_xchg_rate === "" ||
      coal_cost_currency_xchg_rate === null
    ) {
      coal_cost_currency_xchg_rate = "1";
      coal_cost_adjusted_coal_price_in_local_currency =
        coal_cost_adjusted_coal_price;
    }

    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      coal_cost_pph22_export_value =
        this.state.coal_cost_pph22_export_value_local_cur;
      if (coal_cost_pph22_export === "No") {
        coal_cost_pph22_export_amount = "";
        coal_cost_pph22_export_percent = "";
        coal_cost_pph22_export_value = "";
      }
      vat_applicable = null;
      vat_amount = "";
      //vat_percent = "";
      vat_value = "";
      coal_cost_pph22_barge_quantity_in_mt = "";
      coal_cost_pph22_coal_price_per_mt = "";
      coal_cost_pph22_percent_val = "";
      coal_cost_pph22_percent = "";
    } else {
      if (
        ((config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
          this.state.sales_currency !== "IDR") ||
        ((config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL") &&
          this.state.sales_currency !== "USD")
      ) {
        coal_cost_pph22_export_value =
          this.state.coal_cost_pph22_export_value_local_cur;
        coal_cost_pph22_percent_val =
          this.state.coal_cost_pph22_percent_val_local_cur;
      }
      if (coal_cost_pph22_export === "No") {
        coal_cost_pph22_export_amount = "";
        coal_cost_pph22_export_percent = "";
        coal_cost_pph22_export_value = "";
      }
      if (vat_applicable === "No") {
        vat_amount = "";
        //vat_percent = "";
        vat_value = "";
      }
    }

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }

    const content_files = files_data.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== ""
    );

    // Add barge purchase financial api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    const costing_type = this.props.costing_type;
    const business_allocation_id = this.state.allocationsList.toString();
    const businessNoBargeID = this.state.bargeList.toString();
    const {
      coal_index_type,
      index_ids,
      average_coal_index_price,
      prorata_gar,
      prorata_coal_index_price,
      bonus_coal_index_pmt,
      penality_coal_index_pmt,
      derivered_coal_index_pmt,
      final_coal_index_pmt,
    } = this.state.coal_index;
    if (this.state.bargeList.length > 0) {
      api
        .addBargeSalesCoalCostings(
          loginUserID,
          idToken,
          performa_mapping?.filter((e) => e.checked === true),
          businessNoID,
          business_allocation_id,
          businessNoBargeID,
          invoice_no,
          invoice_date,
          ppn,
          coal_index_type,
          index_ids,
          average_coal_index_price,
          prorata_gar,
          prorata_coal_index_price,
          bonus_coal_index_pmt,
          penality_coal_index_pmt,
          derivered_coal_index_pmt,
          final_coal_index_pmt,
          coal_cost_coal_quantity,
          coal_cost_billing_currency,
          coal_cost_coal_price_pmt,
          coal_cost_coal_price,
          coal_cost_gar_bonus,
          coal_cost_gar_penality,
          coal_cost_ash_penality,
          coal_cost_sulphur_penality,
          coal_cost_adjusted_coal_price_pmt,
          coal_cost_adjusted_coal_price,
          coal_cost_currency_xchg_rate,
          coal_cost_adjusted_coal_price_in_local_currency,
          coal_cost_pph22_barge_quantity_in_mt,
          coal_cost_pph22_coal_price_per_mt,
          coal_cost_pph22_percent,
          coal_cost_pph22_percent_val,
          coal_cost_pph22_export,
          coal_cost_pph22_export_amount,
          coal_cost_pph22_export_percent,
          coal_cost_pph22_export_value,
          advance_adjustments,
          freight_price_pmt,
          receivable_from_supplier,
          costing_type,
          vat_applicable,
          vat_amount,
          vat_percent,
          vat_value,
          content_files,
          costing_file
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    succesMsg: res.message,
                    snackBarSuccessOpen: true,
                  },
                  () => {
                    this.props.onAddResponse(businessNoID);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    } else {
      alert("Please select altleast one barge to submit");
      this.setState({
        isLoading: false,
        errorMsg: "Please select altleast one barge to submit",
        succesMsg: "",
      });
    }
  };

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  onBargesCheckCommercial = (
    bargeId,
    salesId,
    checked,
    barge,
    allocation_data
  ) => {
    let loginUserID = this.Cookie.getCookie("loginUserId");
    let idToken = this.Cookie.getIdTokenCookie();
    this.setState({
      selectedSalesContractId: salesId,
      isLoading: true,
    });
    let bargeIds = [];
    let barge_allocations = [];
    var bargeQuantity = 0;
    for (let n = 0; n < barge.length; n++) {
      bargeIds.push(barge[n].business_no_barge_id);
      barge_allocations.push(barge[n].business_allocation_id);

      if (barge[n].allocation_type === "Full") {
        if (barge[n].barge_quantity === null || barge[n].barge_quantity <= 0) {
          bargeQuantity += Number(barge[n].planned_quantity);
        } else {
          bargeQuantity += Number(barge[n].barge_quantity);
        }
      } else {
        bargeQuantity += Number(barge[n].partial_quantity);
      }
    }
    var total_barge_quantity = "";
    if (
      this.state.selectedSalesContractId === "" ||
      this.state.selectedSalesContractId === salesId
    ) {
      if (!!checked) {
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) + Number(bargeQuantity)
        ).toFixed(3);
        let bargeList = this.state.bargeList.concat(bargeId);
        let allocationsList =
          this.state.allocationsList.concat(barge_allocations);
        this.setState({
          selectedSalesContractId: salesId,
          bargeList: bargeList,
          allocationsList: allocationsList,
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length === 0) {
          // Calling Get Business Number Operation Barge Details API
          const business_no_id = this.props.businessNoID;
          // const costing_type = this.props.costing_type;
          const business_allocation_id = allocation_data.business_allocation_id;
          api
            .get_business_no_barge(
              bargeId,
              business_allocation_id,
              business_no_id,
              loginUserID,
              idToken
            )
            .then((response) => {
              if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                  if (res.code === "200") {
                    var sales_type = res.business_no_barge.sales_type;
                    let bargeQuantityNew = 0;
                    for (let n = 0; n < barge.length; n++) {
                      if (
                        sales_type === "FAS Anchorage" &&
                        barge.redraft_quantity !== null &&
                        res.business_no_barge.quantity_difference_account ===
                        "Supplier"
                      ) {
                        bargeQuantityNew += Number(barge[n].redraft_quantity);
                      }
                    }
                    if (bargeQuantityNew === 0) {
                      bargeQuantityNew = bargeQuantity;
                    }
                    total_barge_quantity = (
                      Number(this.state.coal_cost_coal_quantity) +
                      Number(bargeQuantityNew)
                    ).toFixed(3);
                    this.setState(
                      {
                        selectedSalesContractId: salesId,
                        bargeList: bargeList,
                        total_barge_quantity:
                          Number(total_barge_quantity).toFixed(3),
                        coal_cost_coal_quantity:
                          Number(total_barge_quantity).toFixed(3),
                        quantity_difference_account:
                          res.business_no_barge.quantity_difference_account,
                        sales_index_linked:
                          res.business_no_barge.sales_index_linked,
                        sc_quality: res.business_no_barge.sc_quality,
                        sales_type: sales_type,
                        isLoading: true,
                      },
                      () => {
                        this.callingAsync(allocation_data);
                      }
                    );
                  }
                });
              }
            });
        } else if (this.state.bargeList.length > 0) {
          let bargeQuantityNew = 0;
          for (let n = 0; n < barge.length; n++) {
            if (
              this.state.sales_type === "FAS Anchorage" &&
              barge[n].redraft_quantity !== null &&
              this.state.quantity_difference_account === "Supplier"
            ) {
              bargeQuantityNew += Number(barge[n].redraft_quantity);
            }
          }
          if (bargeQuantityNew === 0) {
            bargeQuantityNew = bargeQuantity;
          }
          total_barge_quantity = (
            Number(this.state.coal_cost_coal_quantity) +
            Number(bargeQuantityNew)
          ).toFixed(3);
          let value = total_barge_quantity;
          this.setState(
            {
              bargeList: bargeList,
              coal_cost_coal_quantity: Number(value).toFixed(3),
              operationalBargeQuantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      } else {
        var data = this.state.bargeList;
        let allocation = this.state.allocationsList;
        let bargeQuantityNew = 0;
        for (let n = 0; n < barge.length; n++) {
          if (
            this.state.sales_type === "FAS Anchorage" &&
            barge[n].redraft_quantity !== null &&
            this.state.quantity_difference_account === "Supplier"
          ) {
            bargeQuantityNew += Number(barge[n].redraft_quantity);
          }
        }
        if (bargeQuantityNew === 0) {
          bargeQuantityNew = bargeQuantity;
        }
        total_barge_quantity = (
          Number(this.state.coal_cost_coal_quantity) - Number(bargeQuantityNew)
        ).toFixed(3);
        if (data.indexOf(bargeId) >= 0) {
          let idx = data.indexOf(bargeId);
          data.splice(idx, 1);
        }
        if (allocation.indexOf(allocation_data.business_allocation_id) >= 0) {
          let index = data.indexOf(allocation_data.business_allocation_id);
          allocation.splice(index, 1);
        }
        this.setState({
          bargeList: data,
          allocationsList: allocation,
          total_barge_quantity: Number(total_barge_quantity).toFixed(3),
          coal_cost_coal_quantity: Number(total_barge_quantity).toFixed(3),
          isLoading: true,
        });
        if (this.state.bargeList.length !== 0) {
          let value = total_barge_quantity;
          this.setState(
            {
              coal_cost_coal_quantity: Number(value).toFixed(3),
            },
            () => {
              this.bargeQuantityChange();
            }
          );
        } else {
          this.setState({
            isLoading: false,
          });
        }
      }
    }
  };

  onSelectAllCommercialBarges = (salesId, checked, barge) => {
    this.setState({ isLoading: true });
    if (!!checked) {
      const filter_qualified_barges = barge.barges.filter(
        (e) =>
          e.com_sales_term === "Specs" &&
          e.com_quality_results === 1 &&
          e.com_sales_fin_id === null
      );
      let bargeID =
        filter_qualified_barges.length > 0
          ? filter_qualified_barges[0].business_no_barge_id
          : null;
      let allocated_data =
        filter_qualified_barges.length > 0 ? filter_qualified_barges[0] : null;
      if (!bargeID) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "No barges avaiable to create costing",
        });
        return;
      }
      // let bargeID = barge.barges[0].business_no_barge_id;
      let loginUserID = this.Cookie.getCookie("loginUserId");
      let idToken = this.Cookie.getIdTokenCookie();

      // Calling Get Business Number Operation Barge Details API
      const business_no_id = this.props.businessNoID;
      // const costing_type = this.props.costing_type;
      const business_allocation_id = allocated_data.business_allocation_id;
      api
        .get_business_no_barge(
          bargeID,
          business_allocation_id,
          business_no_id,
          loginUserID,
          idToken
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                var bargeQuantity = 0;
                var bargeList = [];
                var allocationsList = [];
                var sales_type = res.business_no_barge.sales_type;
                for (let n = 0; n < barge.barges.length; n++) {
                  if (
                    barge.barges[n].com_sales_term === "Specs" &&
                    barge.barges[n].com_quality_results === 1 &&
                    barge.barges[n].com_sales_fin_id === null
                  ) {
                    bargeList.push(barge.barges[n].business_no_barge_id);
                    allocationsList.push(
                      barge.barges[n].business_allocation_id
                    );
                    // if (barge.barges[n].barge_quantity === null || barge.barges[n].barge_quantity <= 0) {
                    //   bargeQuantity += Number(barge.barges[n].planned_quantity);
                    // }
                    // if (sales_type === "FAS Anchorage" && barge.barges[n].redraft_quantity !== null && res.business_no_barge.quantity_difference_account === "Supplier") {
                    //   bargeQuantity += barge.barges[n].redraft_quantity
                    // }
                    // else {
                    //   bargeQuantity += Number(barge.barges[n].barge_quantity);
                    // }
                    if (
                      barge.barges[n].allocation_type === "Full" ||
                      barge.barges[n].allocation_type === null
                    ) {
                      if (
                        barge.barges[n].barge_quantity === null ||
                        barge.barges[n].barge_quantity <= 0
                      ) {
                        bargeQuantity += Number(
                          barge.barges[n].planned_quantity
                        );
                      } else {
                        bargeQuantity += Number(barge.barges[n].barge_quantity);
                      }
                    } else if (barge.barges[n].allocation_type === "Partial") {
                      bargeQuantity += Number(barge.barges[n].partial_quantity);
                    }
                    if (
                      sales_type === "FAS Anchorage" &&
                      barge.barges[n].redraft_quantity !== null &&
                      res.barges.quantity_difference_account === "Supplier"
                    ) {
                      bargeQuantity += barge.barges[n].redraft_quantity;
                    } else {
                      // bargeQuantity += Number(barge.barges[n].barge_quantity_in_mt);
                    }
                  }
                }
                this.setState(
                  {
                    selectedSalesContractId: salesId,
                    bargeList: bargeList,
                    allocationsList: allocationsList,
                    bargeSelectAll: checked,
                    coal_cost_coal_quantity: bargeQuantity,
                    sales_index_linked:
                      res.business_no_barge.sales_index_linked,
                    sc_quality: res.business_no_barge.sc_quality,
                    isLoading: true,
                  },
                  () => {
                    this.callingAsync(allocated_data);
                  }
                );
              }
            });
          }
        });
    } else {
      this.setState(
        {
          // selectedSalesContractId: '',
          bargeList: [],
          allocationsList: [],
          bargeSelectAll: checked,
          coal_cost_coal_quantity: null,
          isLoading: false,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  onAdvanceAdjustmentsCal = () => {
    var {
      coal_cost_adjusted_coal_price_in_local_currency,
      vat_value,
      coal_cost_pph22_percent_val_local_cur,
      coal_cost_pph22_export_value_local_cur,
      coal_cost_coal_quantity,
      coal_cost_currency_xchg_rate,
    } = this.state;

    var display_pph22 =
      Number(coal_cost_pph22_percent_val_local_cur) +
      Number(coal_cost_pph22_export_value_local_cur);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      display_pph22 = Number(coal_cost_pph22_export_value_local_cur);
    }

    var payable_to_supplier =
      Number(coal_cost_adjusted_coal_price_in_local_currency) +
      Number(vat_value) -
      Number(coal_cost_pph22_percent_val_local_cur) -
      Number(coal_cost_pph22_export_value_local_cur);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      payable_to_supplier =
        Number(coal_cost_adjusted_coal_price_in_local_currency) -
        Number(coal_cost_pph22_export_value_local_cur);
    }
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    var freight_pmt = 0;
    if (
      (this.state.sales_type === "CIF DP" || this.state.sales_type === "CFR DP") &&
      this.state.freight_price_pmt !== ""
    ) {
      freight_pmt = (
        Number(this.state.freight_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
    }
    var total_receivable = (
      Number(payable_to_supplier) +
      Number(freight_pmt) * Number(coal_cost_coal_quantity)
    ).toFixed(2);
    this.setState({
      receivable_from_supplier: Number(total_receivable).toFixed(2),
      display_pph22: Number(display_pph22).toFixed(0),
      isLoading: false,
    });
  };

  //Barge Data calculations
  bargeQuantityChange = () => {
    var {
      coal_cost_coal_quantity,
      coal_cost_adjusted_coal_price_pmt,
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_percent,
      coal_cost_billing_currency,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export,
      coal_cost_gar_bonus,
      vat_applicable,
      vat_percent,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coalPriceCurrency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_adjusted_coal_price = Number(
      Number(coal_cost_coal_quantity) *
      Number(coal_cost_adjusted_coal_price_pmt)
    ).toFixed(2);
    var coal_cost_adjusted_coal_price_in_local_currency = Number(
      coal_cost_adjusted_coal_price
    ).toFixed(2);

    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_coal_quantity) *
        Number(coal_cost_adjusted_coal_price_pmt) *
        Number(coal_cost_pph22_percent)
      ) / 100;
    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(2);

    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_adjusted_coal_price) *
        Number(coal_cost_pph22_export_percent)
      ) / 100;
    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(2);

    var vat_value = (
      (Number(coal_cost_adjusted_coal_price) * Number(vat_percent)) /
      100
    ).toFixed(2);

    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }
    if (vat_applicable === "No") {
      vat_value = 0;
    }
    // let coal_cost_coal_price_pmt= (Number(this.state.coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate)).toFixed(2)
    // var garPenality = '';
    // var garBonus = '';
    // var ashPenality = '';
    // var sulphurPenality = '';

    // let garActiualValue= this.state.garActiualValue;
    // let bonusCap= this.state.bonusCap;

    // if(this.state.sales_term === 'Specs') {
    //   if (garActiualValue !== null && this.state.garTypicalValue !== null && garActiualValue !== "" && this.state.garTypicalValue !== "") {
    //     if (bonusCap !== null && bonusCap !== '' ) {
    //       if(Number(garActiualValue) > Number(bonusCap)) {
    //         garActiualValue= bonusCap;
    //       }
    //     }
    //     if (Number(garActiualValue) > Number(this.state.garTypicalValue)) {
    //       garBonus = ((Number(garActiualValue) - Number(this.state.garTypicalValue)) / Number(this.state.garTypicalValue)) * Number(coal_cost_coal_price_pmt)
    //     }
    //     if (Number(this.state.garTypicalValue) > Number(this.state.garActiualValue)) {
    //       garPenality = ((Number(this.state.garTypicalValue) - Number(this.state.garActiualValue)) / Number(this.state.garTypicalValue)) * Number(coal_cost_coal_price_pmt)
    //     }
    //   }
    //   if ((this.state.quality_ash_actual !== null && this.state.quality_ash_actual !== "") && (this.state.quality_ash_typical !== null && this.state.quality_ash_typical !== "")) {
    //     if (Number(this.state.quality_ash_actual) > Number(this.state.quality_ash_typical) && (this.state.ashPenality !== null && this.state.ashPenality !== "")) {
    //       ashPenality = Number(coal_cost_currency_xchg_rate) * Number(this.state.ashPenality) * ((Number(this.state.quality_ash_actual) - Number(this.state.quality_ash_typical)) / 1)
    //     }
    //   }
    //   if ((this.state.quality_total_sulphur_actual !== null && this.state.quality_total_sulphur_actual !== "") && (this.state.quality_total_sulphur_typical !== null && this.state.quality_total_sulphur_typical !== "")) {
    //     if (Number(this.state.quality_total_sulphur_actual) > Number(this.state.quality_total_sulphur_typical) && (this.state.sulphurPenality !== null && this.state.sulphurPenality !== "")) {
    //       sulphurPenality = Number(coal_cost_currency_xchg_rate) * Number(this.state.sulphurPenality) * ((Number(this.state.quality_total_sulphur_actual) - Number(this.state.quality_total_sulphur_typical)) / 0.1)
    //     }
    //   }
    // }
    // console.log( garBonus, garPenality, ashPenality, sulphurPenality );

    let coal_cost_pph22_percent_val_new = "";
    let coal_cost_pph22_export_value_new = "";
    if (coalPriceCurrency !== coal_cost_billing_currency) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
    } else {
      // coal_cost_gar_bonus_new= Number(garBonus).toFixed(0);
      // coal_cost_gar_penality_new= Number(garPenality).toFixed(0);
      // coal_cost_ash_penality_new= Number(ashPenality).toFixed(0);
      // coal_cost_sulphur_penality_new= Number(sulphurPenality).toFixed(0);
      // coal_cost_coal_price_pmt= (Number(this.state.coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate)).toFixed(0)
      // // console.log( coal_cost_gar_bonus_new, coal_cost_gar_penality_new, coal_cost_ash_penality_new, coal_cost_sulphur_penality_new );
      // // console.log('coal_cost_coal_price_pmt', coal_cost_coal_price_pmt)
      // let coal_cost_adjusted_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)+Number(coal_cost_gar_bonus_new)-Number(coal_cost_gar_penality_new)-Number(coal_cost_ash_penality_new)-Number(coal_cost_sulphur_penality_new));
      // // console.log(coal_cost_adjusted_coal_price_pmt_new)
      // // console.log('coal_cost_coal_quantity', coal_cost_coal_quantity);
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_percent)
        ) / 100;
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_export_percent)
        ) / 100;
      vat_value =
        Number(
          Number(coal_cost_coal_quantity) *
          Number(coal_cost_adjusted_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate) *
          Number(vat_percent)
        ) / 100;
    }
    // console.log(coal_cost_pph22_percent_val_new)
    // console.log(coal_cost_pph22_export_value_new)
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    // let coal_cost_coal_price= Number(coal_cost_coal_quantity)*Number(this.state.coal_cost_coal_price_pmt);
    // console.log('coal_cost_coal_price', coal_cost_coal_price)
    let coal_cost_coal_price_pmt_local_currency = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.coal_cost_billing_currency === "IDR"
    ) {
      coal_cost_coal_price_pmt_local_currency = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(0);
      coal_cost_adjusted_coal_price_in_local_currency = Number(
        coal_cost_adjusted_coal_price
      ).toFixed(0);
      coal_cost_pph22_percent_val_local_cur = Number(
        coal_cost_pph22_percent_val
      ).toFixed(0);
      coal_cost_pph22_export_value_local_cur = Number(
        coal_cost_pph22_export_value
      ).toFixed(0);
    }
    // console.log('coal_cost_coal_price_pmt_local_currency', coal_cost_coal_price_pmt_local_currency)
    // console.log('coal_cost_coal_quantity', coal_cost_coal_quantity)
    this.setState(
      {
        coal_cost_coal_price: Number(
          Number(coal_cost_coal_price_pmt_local_currency) *
          Number(coal_cost_coal_quantity)
        ).toFixed(2),
        coal_cost_adjusted_coal_price: coal_cost_adjusted_coal_price,
        coal_cost_coal_price_pmt_local_currency:
          coal_cost_coal_price_pmt_local_currency,
        coal_cost_adjusted_coal_price_in_local_currency:
          coal_cost_adjusted_coal_price_in_local_currency,

        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,

        // VAT
        vat_amount: Number(coal_cost_coal_quantity),
        vat_value: vat_value,

        //PPH22 Local
        coal_cost_pph22_barge_quantity_in_mt: Number(coal_cost_coal_quantity),
        coal_cost_pph22_coal_price_per_mt: coal_cost_adjusted_coal_price_pmt,
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val_local_cur,
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,

        //PPH22 Export
        coal_cost_pph22_export_amount: Number(
          coal_cost_adjusted_coal_price
        ).toFixed(2),
        coal_cost_pph22_export_value: coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,

        isLoading: false,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onVatChangeHandler = () => {
    let {
      coal_cost_currency_xchg_rate,
      vat_applicable,
      vat_amount,
      vat_percent,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;

    let vat_value = 0;
    if (vat_applicable === "No") {
      vat_value = 0;
      vat_amount = 0;
    }

    vat_value = Number(
      (Number(vat_amount) * Number(vat_percent ? vat_percent : 10)) / 100
    );
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    //var coal_cost_pph22_export_value_local_cur = Number(coal_cost_pph22_export_value).toFixed(0);

    //let coal_cost_pph22_export_value_new = '';
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      // coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur
    } else {
      // let coal_cost_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate));
      vat_value = Number(
        (Number(vat_amount) *
          Number(coal_cost_currency_xchg_rate) *
          Number(vat_percent ? vat_percent : 10)) /
        100
      );
    }
    // console.log('coal_cost_pph22_export_value_new',coal_cost_pph22_export_value_new);
    this.setState(
      {
        vat_value: Number(vat_value).toFixed(2),
        vat_percent: this.state.vat_percent ? this.state.vat_percent : 10,
        //coal_cost_pph22_export_value_local_cur: coal_cost_pph22_export_value_local_cur,
        // coal_cost_pph22_export_value_local_cur_new: coal_cost_pph22_export_value_new
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  //Only PPH22 Local Calculations
  onPph22LocalDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_barge_quantity_in_mt,
      coal_cost_pph22_coal_price_per_mt,
      coal_cost_pph22_percent,
      coal_cost_coal_quantity,
    } = this.state;

    var coal_cost_pph22_percent_val =
      Number(
        Number(coal_cost_pph22_barge_quantity_in_mt) *
        Number(coal_cost_pph22_coal_price_per_mt) *
        Number(coal_cost_pph22_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (coal_cost_coal_quantity === null || coal_cost_coal_quantity === "") {
      coal_cost_coal_quantity = 1;
    }

    var coal_cost_pph22_percent_val_local_cur = Number(
      coal_cost_pph22_percent_val
    ).toFixed(0);

    let coal_cost_pph22_percent_val_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      coal_cost_pph22_percent_val_new = coal_cost_pph22_percent_val_local_cur;
    } else {
      let coal_cost_pph22_coal_price_per_mt_new = Number(
        Number(coal_cost_pph22_coal_price_per_mt) *
        Number(coal_cost_currency_xchg_rate)
      );
      coal_cost_pph22_percent_val_new =
        Number(
          Number(coal_cost_pph22_barge_quantity_in_mt) *
          Number(coal_cost_pph22_coal_price_per_mt_new) *
          Number(coal_cost_pph22_percent)
        ) / 100;
    }
    this.setState(
      {
        coal_cost_pph22_percent_val: (
          Number(coal_cost_pph22_percent_val) /
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0),
        coal_cost_pph22_percent_val_local_cur:
          coal_cost_pph22_percent_val.toFixed(2),
        coal_cost_pph22_percent_val_local_cur_new:
          coal_cost_pph22_percent_val_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22LocalChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  onPph22LocalCoalPriceChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22LocalDataChange();
        }
      );
    }
  };

  //Only PPH22 Export Calculations
  onPph22ExportDataChange = () => {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_pph22_export_amount,
      coal_cost_pph22_export_percent,
      coal_cost_pph22_export,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;
    var coal_cost_pph22_export_value =
      Number(
        Number(coal_cost_pph22_export_amount) *
        Number(coal_cost_pph22_export_percent)
      ) / 100;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    var coal_cost_pph22_export_value_local_cur = Number(
      coal_cost_pph22_export_value
    ).toFixed(0);
    if (coal_cost_pph22_export === "No") {
      coal_cost_pph22_export_value_local_cur = 0;
    }

    let coal_cost_pph22_export_value_new = "";
    if (
      this.state.coal_cost_billing_currency !== this.state.coalPriceCurrency
    ) {
      coal_cost_pph22_export_value_new = coal_cost_pph22_export_value_local_cur;
    } else {
      // let coal_cost_coal_price_pmt_new= Number(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate));
      coal_cost_pph22_export_value_new =
        Number(
          Number(coal_cost_pph22_export_amount) *
          Number(this.state.coal_cost_currency_xchg_rate) *
          Number(coal_cost_pph22_export_percent)
        ) / 100;
    }
    // console.log('coal_cost_pph22_export_value_new',coal_cost_pph22_export_value_new);
    this.setState(
      {
        coal_cost_pph22_export_value: Number(
          coal_cost_pph22_export_value
        ).toFixed(2),
        coal_cost_pph22_export_value_local_cur:
          coal_cost_pph22_export_value_local_cur,
        coal_cost_pph22_export_value_local_cur_new:
          coal_cost_pph22_export_value_new,
      },
      () => {
        this.onAdvanceAdjustmentsCal();
      }
    );
  };

  onPph22ExportChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.onPph22ExportDataChange();
        }
      );
    }
  };

  //Barge quantity changes
  onBargeChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      this.setState(
        {
          [e.target.name]: value,
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Barge quantity changes
  onCoalCostChange = (e) => {
    // const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_penality_currency,
      coal_cost_billing_currency,
      sales_currency,
    } = this.state;
    // if (e.target.value === "" || quan.test(e.target.value)) {
    var value = this.state.coal_cost_coal_price_pmt;
    if (value === null || value === "") {
      value = 0;
    }
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    let coal_cost_coal_price_pmt = (
      Number(value) * Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var garPenality = "";
    var garBonus = "";
    var ashPenality = "";
    var sulphurPenality = "";

    let garActiualValue = this.state.garActiualValue;
    let bonusCap = this.state.bonusCap;
    let penalityCap = this.state.penalityCap;
    // var costing_type = decodeURIComponent(window.atob(this.props.match.params.costing_type));
    var costing_type = this.props.costing_type;

    if (this.state.sales_term === "Specs" && costing_type === "Commercial") {
      if (
        garActiualValue !== null &&
        this.state.garTypicalValue !== null &&
        garActiualValue !== "" &&
        this.state.garTypicalValue !== ""
      ) {
        if (Number(garActiualValue) > Number(this.state.garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        } else {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }
        if (
          Number(this.state.garActiualValue) >
          Number(this.state.garTypicalValue)
        ) {
          garBonus =
            ((Number(garActiualValue) - Number(this.state.garTypicalValue)) /
              Number(this.state.garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
        if (
          Number(this.state.garTypicalValue) >
          Number(this.state.garActiualValue)
        ) {
          garPenality =
            ((Number(this.state.garTypicalValue) - Number(garActiualValue)) /
              Number(this.state.garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
          Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          // console.log('ash-penality-', (Number(this.state.quality_ash_actual) - Number(this.state.quality_ash_typical)));
          // console.log(this.state.ashPenality);
          // console.log(coal_cost_currency_xchg_rate);
          ashPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
          Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
        }
      }
    }
    // console.log( garBonus, garPenality, ashPenality, sulphurPenality );

    var coal_cost_gar_bonus = "";
    var coal_cost_gar_penality = "";
    var coal_cost_ash_penality = "";
    var coal_cost_sulphur_penality = "";
    if (garBonus !== "") {
      coal_cost_gar_bonus = garBonus.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_bonus = garBonus.toFixed(0);
      }
    }
    if (garPenality !== "") {
      coal_cost_gar_penality = garPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_penality = garPenality.toFixed(0);
      }
    }
    if (ashPenality !== "") {
      coal_cost_ash_penality = ashPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_ash_penality = ashPenality.toFixed(0);
      }
    }
    if (sulphurPenality !== "") {
      coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_sulphur_penality = sulphurPenality.toFixed(0);
      }
    }
    let coal_cost_adjusted_coal_price_pmt = Number(
      Number(coal_cost_coal_price_pmt) +
      Number(coal_cost_gar_bonus) -
      Number(coal_cost_gar_penality) -
      Number(coal_cost_ash_penality) -
      Number(coal_cost_sulphur_penality)
    ).toFixed(2);
    // console.log('coal_cost_adjusted_coal_price_pmt',coal_cost_adjusted_coal_price_pmt);

    if (
      sales_currency !== coal_cost_billing_currency &&
      sales_currency === coal_cost_penality_currency
    ) {
      coal_cost_coal_price_pmt = Number(
        this.state.coal_cost_coal_price_pmt
      ).toFixed(2);
      let coal_cost_adjusted_coal_price_pmt_value = Number(
        Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
      );
      coal_cost_adjusted_coal_price_pmt = (
        Number(coal_cost_adjusted_coal_price_pmt_value) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
    }
    // console.log('coal_cost_coal_price_pmt',coal_cost_coal_price_pmt);
    // console.log(coal_cost_gar_bonus, coal_cost_gar_penality, coal_cost_ash_penality, coal_cost_sulphur_penality);
    // console.log('coal_cost_adjusted_coal_price_pmt',coal_cost_adjusted_coal_price_pmt);
    this.setState(
      {
        // [e.target.name]: e.target.value,
        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,
        coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
      },
      () => {
        this.bargeQuantityChange();
      }
    );
    // }
  };

  onPenalityChanges() {
    var {
      coal_cost_currency_xchg_rate,
      coal_cost_billing_currency,
      coalPriceCurrency,
    } = this.state;
    if (
      coal_cost_currency_xchg_rate === null ||
      coal_cost_currency_xchg_rate === ""
    ) {
      coal_cost_currency_xchg_rate = 1;
    }
    if (
      coal_cost_billing_currency === "0" ||
      coalPriceCurrency === coal_cost_billing_currency
    ) {
      coal_cost_currency_xchg_rate = 1;
    }

    let coal_cost_coal_price_pmt = (
      Number(this.state.coal_cost_coal_price_pmt) *
      Number(coal_cost_currency_xchg_rate)
    ).toFixed(2);

    var garPenality = "";
    var garBonus = "";
    var ashPenality = "";
    var sulphurPenality = "";

    let garActiualValue = this.state.garActiualValue;
    let bonusCap = this.state.bonusCap;
    let penalityCap = this.state.penalityCap;
    var costing_type = this.props.costing_type;

    if (this.state.sales_term === "Specs" && costing_type === "Commercial") {
      if (
        garActiualValue !== null &&
        this.state.garTypicalValue !== null &&
        garActiualValue !== "" &&
        this.state.garTypicalValue !== ""
      ) {
        if (Number(garActiualValue) > Number(this.state.garTypicalValue)) {
          if (bonusCap !== null && bonusCap !== "") {
            if (Number(garActiualValue) > Number(bonusCap)) {
              garActiualValue = bonusCap;
            }
          }
        } else {
          if (penalityCap !== null && penalityCap !== "") {
            if (Number(garActiualValue) < Number(penalityCap)) {
              garActiualValue = penalityCap;
            }
          }
        }
        if (
          Number(this.state.garActiualValue) >
          Number(this.state.garTypicalValue)
        ) {
          garBonus =
            ((Number(garActiualValue) - Number(this.state.garTypicalValue)) /
              Number(this.state.garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
        if (
          Number(this.state.garTypicalValue) >
          Number(this.state.garActiualValue)
        ) {
          garPenality =
            ((Number(this.state.garTypicalValue) - Number(garActiualValue)) /
              Number(this.state.garTypicalValue)) *
            Number(coal_cost_coal_price_pmt);
        }
      }
      if (
        this.state.quality_ash_actual !== null &&
        this.state.quality_ash_actual !== "" &&
        this.state.quality_ash_typical !== null &&
        this.state.quality_ash_typical !== ""
      ) {
        if (
          Number(this.state.quality_ash_actual) >
          Number(this.state.quality_ash_typical) &&
          this.state.ashPenality !== null &&
          this.state.ashPenality !== ""
        ) {
          // console.log('ash-penality-', (Number(this.state.quality_ash_actual) - Number(this.state.quality_ash_typical)));
          // console.log(this.state.ashPenality);
          // console.log(coal_cost_currency_xchg_rate);
          ashPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.ashPenality) *
            ((Number(this.state.quality_ash_actual) -
              Number(this.state.quality_ash_typical)) /
              1);
        }
      }
      if (
        this.state.quality_total_sulphur_actual !== null &&
        this.state.quality_total_sulphur_actual !== "" &&
        this.state.quality_total_sulphur_typical !== null &&
        this.state.quality_total_sulphur_typical !== ""
      ) {
        if (
          Number(this.state.quality_total_sulphur_actual) >
          Number(this.state.quality_total_sulphur_typical) &&
          this.state.sulphurPenality !== null &&
          this.state.sulphurPenality !== ""
        ) {
          sulphurPenality =
            Number(coal_cost_currency_xchg_rate) *
            Number(this.state.sulphurPenality) *
            ((Number(this.state.quality_total_sulphur_actual) -
              Number(this.state.quality_total_sulphur_typical)) /
              0.1);
        }
      }
    }
    // console.log( garBonus, garPenality, ashPenality, sulphurPenality );

    var coal_cost_gar_bonus = "";
    var coal_cost_gar_penality = "";
    var coal_cost_ash_penality = "";
    var coal_cost_sulphur_penality = "";
    if (garBonus !== "") {
      coal_cost_gar_bonus = garBonus.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_bonus = garBonus.toFixed(0);
      }
    }
    if (garPenality !== "") {
      coal_cost_gar_penality = garPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_gar_penality = garPenality.toFixed(0);
      }
    }
    if (ashPenality !== "") {
      coal_cost_ash_penality = ashPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_ash_penality = ashPenality.toFixed(0);
      }
    }
    if (sulphurPenality !== "") {
      coal_cost_sulphur_penality = sulphurPenality.toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_sulphur_penality = sulphurPenality.toFixed(0);
      }
    }
    let coal_cost_adjusted_coal_price_pmt = Number(
      Number(coal_cost_coal_price_pmt) +
      Number(coal_cost_gar_bonus) -
      Number(coal_cost_gar_penality) -
      Number(coal_cost_ash_penality) -
      Number(coal_cost_sulphur_penality)
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      this.state.coal_cost_billing_currency === "IDR"
    ) {
      coal_cost_adjusted_coal_price_pmt = Number(
        Number(coal_cost_coal_price_pmt) +
        Number(coal_cost_gar_bonus) -
        Number(coal_cost_gar_penality) -
        Number(coal_cost_ash_penality) -
        Number(coal_cost_sulphur_penality)
      ).toFixed(0);
    }
    this.setState(
      {
        coal_cost_gar_bonus: coal_cost_gar_bonus,
        coal_cost_gar_penality: coal_cost_gar_penality,
        coal_cost_ash_penality: coal_cost_ash_penality,
        coal_cost_sulphur_penality: coal_cost_sulphur_penality,
        coal_cost_adjusted_coal_price_pmt: coal_cost_adjusted_coal_price_pmt,
      },
      () => {
        this.bargeQuantityChange();
      }
    );
  }

  //Exchange rate changes
  onExchangeRateChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_coal_price_pmt,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      var value = e.target.value;
      if (value === null || value === "") {
        value = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        value = 1;
      } else if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        value = 1 / Number(value);
      }
      coal_cost_gar_bonus = (
        Number(coal_cost_gar_bonus) * Number(value)
      ).toFixed(2);
      coal_cost_gar_penality = (
        Number(coal_cost_gar_penality) * Number(value)
      ).toFixed(2);
      coal_cost_ash_penality = (
        Number(coal_cost_ash_penality) * Number(value)
      ).toFixed(2);
      coal_cost_sulphur_penality = (
        Number(coal_cost_sulphur_penality) * Number(value)
      ).toFixed(2);
      coal_cost_coal_price_pmt = (
        Number(coal_cost_coal_price_pmt) * Number(value)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(coal_cost_coal_price_pmt) * Number(value)
        ).toFixed(0);
      }
      this.setState(
        {
          [e.target.name]: e.target.value,
          coal_cost_currency_xchg_rate: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.onPenalityChanges();
        }
      );
    }
  };

  //Gar bonus changes
  onGarBonusChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(this.state.coal_cost_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0);
      }
      // let coal_cost_gar_bonus=(Number(value)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_gar_penality=(Number(coal_cost_gar_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_ash_penality=(Number(coal_cost_ash_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_sulphur_penality=(Number(coal_cost_sulphur_penality)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      // coal_cost_coal_price_pmt=(Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate)).toFixed(2);
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(value) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.bargeQuantityChange();
        }
      );
      // this.setState({
      //   [e.target.name]: value,
      // }, () => {
      //   this.onPenalityChanges();
      // });
    }
  };

  //Gar Penality changes
  onGarPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_ash_penality,
      coal_cost_sulphur_penality,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(this.state.coal_cost_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0);
      }
      var value = e.target.value;
      // coal_cost_gar_bonus=Number(coal_cost_gar_bonus)*Number(coal_cost_currency_xchg_rate);
      // let coal_cost_gar_penality=Number(value)*Number(coal_cost_currency_xchg_rate);
      // coal_cost_ash_penality=Number(coal_cost_ash_penality)*Number(coal_cost_currency_xchg_rate);
      // coal_cost_sulphur_penality=Number(coal_cost_sulphur_penality)*Number(coal_cost_currency_xchg_rate);
      // coal_cost_coal_price_pmt=Number(coal_cost_coal_price_pmt)*Number(coal_cost_currency_xchg_rate);
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(value) -
            Number(coal_cost_ash_penality) -
            Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.bargeQuantityChange();
        }
      );
      // this.setState({
      //   [e.target.name]: e.target.value,
      // }, () => {
      //   this.onPenalityChanges();
      // });
    }
  };

  //Ash Penality changes
  onAshPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_sulphur_penality,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;

      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(this.state.coal_cost_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(value) -
            Number(coal_cost_sulphur_penality)
          ).toFixed(2),
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  //Sulphur Penality changes
  onSulphurPenalityChange = (e) => {
    const quan = /^\d*(\.\d{0,4})?$/;
    var {
      coalPriceCurrency,
      coal_cost_billing_currency,
      coal_cost_gar_bonus,
      coal_cost_gar_penality,
      coal_cost_ash_penality,
      coal_cost_currency_xchg_rate,
    } = this.state;
    if (e.target.value === "" || quan.test(e.target.value)) {
      if (
        coal_cost_currency_xchg_rate === null ||
        coal_cost_currency_xchg_rate === ""
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      if (
        coal_cost_billing_currency === "0" ||
        coalPriceCurrency === coal_cost_billing_currency
      ) {
        coal_cost_currency_xchg_rate = 1;
      }
      var value = e.target.value;
      let coal_cost_coal_price_pmt = (
        Number(this.state.coal_cost_coal_price_pmt) *
        Number(coal_cost_currency_xchg_rate)
      ).toFixed(2);
      if (
        (config.company === "SDAM" ||
          config.company === "SRK" ||
          config.company === "BTR") &&
        this.state.coal_cost_billing_currency === "IDR"
      ) {
        coal_cost_coal_price_pmt = (
          Number(this.state.coal_cost_coal_price_pmt) *
          Number(coal_cost_currency_xchg_rate)
        ).toFixed(0);
      }
      this.setState(
        {
          [e.target.name]: value,
          coal_cost_adjusted_coal_price_pmt: Number(
            Number(coal_cost_coal_price_pmt) +
            Number(coal_cost_gar_bonus) -
            Number(coal_cost_gar_penality) -
            Number(coal_cost_ash_penality) -
            Number(value)
          ).toFixed(2),
        },
        () => {
          this.bargeQuantityChange();
        }
      );
    }
  };

  billingCurrencyChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.onPenalityChanges();
      }
    );
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  GetPerformaData = (data) => {
    const { sales_contract_id, sc_qualities_id, sc_sales_type_id } = data || {};
    const request = {
      business_no_id: this.props.businessNoID,
      sales_contract_id: sales_contract_id,
      quality_id: sc_qualities_id,
      sales_type_id: sc_sales_type_id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    api.get_coal_sales_perf_costings(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            const performa = [];
            for (var i of res.performa_costings_data) {
              performa.push({
                ...i,
                checked: false,
                performa_quantity: "",
                performa_amount: "",
                performa_adv_adjustment: "",
              });
            }
            this.setState({
              performa_costings_data: res.performa_costings_data,
              performa_mapping: performa,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  render() {
    try {
      var costing_type = this.props.costing_type;
    } catch (e) {
      window.location.href = "/business-number-list";
    }

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.succesMsg}
            </Alert>
          </Snackbar>
          <div className="col-sm text-left pl-0 border-bottom">
            <h6 style={{ padding: "10px 20px", margin: "0px" }}>
              Coal Costing Sales Financial for Barge {"(" + costing_type + ")"}{" "}
              {this.state.bargeNomination
                ? " (" + this.state.bargeNomination + ")"
                : ""}
              -
              {this.state.mother_vesselName
                ? " (" + this.state.mother_vesselName + ")"
                : ""}
            </h6>
          </div>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                >
                  Perfroma Invoices
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 2
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 2
                        ? "#31c197"
                        : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 2
                        ? "#31c197"
                        : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 2
                        ? "#31c197"
                        : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                  }}
                />
              </span>
            </div>
            <div className="card pl-2 pt-0 pr-2">
              {this.state.activeStep === 0 && (
                <div>
                  {this.state.sales_contracts.map((p, index) => (
                    <div key={index}>
                      <h5 className="pl-2 section_header">
                        <FormControlLabel
                          value={p.sales_contract_id}
                          color="primary"
                          control={
                            <NewRadio
                              checked={
                                p.sales_contract_id ===
                                this.state.selectedSalesContractId &&
                                p.quality === this.state.selected_sc_quality &&
                                p.sales_type === this.state.selected_sales_type
                              }
                            // disabled={this.state.selectedSalesContractId !== "" && p.sales_contract_id !== this.state.selectedSalesContractId}
                            />
                          }
                          // onChange={(e) => { this.onPurchaseCheck(p.sales_contract_id, p)}}
                          onChange={(e) => {
                            this.setState(
                              {
                                selectedSalesContractId: p.sales_contract_id,
                                selected_sc_quality: p.quality,
                                selected_sales_type: p.sales_type,
                                //sales_contract_no: p.sales_contract_no,
                                coal_cost_gar_penality: "",
                                coal_cost_ash_penality: "",
                                coal_cost_sulphur_penality: "",
                                coal_cost_gar_bonus: "",
                                bargeList: [],
                                allocationsList: [],
                              },
                              () => this.GetPerformaData(p)
                            );
                          }}
                        />
                        {p.sales_contract_no} -{" "}
                        {p.quality ? "(" + p.quality + ")" : ""} -{" "}
                        {p.sales_type} - {p.buyer_name}
                      </h5>
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-borderless mb-0 border-0">
                            <thead className="border-0">
                              <tr>
                                <th
                                  nowrap="true"
                                  className="p-0 text-center pt-1 border-0"
                                >
                                  <CheckBox
                                    color="primary"
                                    checked={
                                      this.state.bargeList.length !== 0 &&
                                      this.state.bargeList.length ===
                                      p.barges.filter(
                                        (e) =>
                                          e.com_sales_term === "Specs" &&
                                          e.com_quality_results === 1 &&
                                          e.com_sales_fin_id === null
                                      ).length
                                    }
                                    disabled={
                                      p.barges.filter(
                                        (e) =>
                                          e.com_sales_term === "Specs" &&
                                          e.com_quality_results === 1 &&
                                          e.com_sales_fin_id === null
                                      ).length === 0 ||
                                      this.state.selectedSalesContractId ===
                                      "" ||
                                      p.sales_contract_id !==
                                      this.state.selectedSalesContractId
                                    }
                                    onChange={(e) => {
                                      this.onSelectAllCommercialBarges(
                                        p.sales_contract_id,
                                        e.target.checked,
                                        p
                                      );
                                    }}
                                  />
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-left"
                                >
                                  Barge ID
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-left"
                                >
                                  Allocation ID
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-left"
                                >
                                  Barge Nomination
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                >
                                  Barge Quantity
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                >
                                  Planned Quantity
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-right"
                                >
                                  Redraft Quantity
                                </th>
                                <th
                                  nowrap="true"
                                  style={{ fontSize: 16 }}
                                  className="table_header_barge_label text-uppercase border-0 text-left"
                                >
                                  Mine
                                </th>
                              </tr>
                            </thead>
                            {p.new_barges.map((id, idx) =>
                              id.new_barges.map((idr, indx) => (
                                <tbody key={indx}>
                                  <tr
                                    style={{
                                      display:
                                        idr.com_sales_fin_id !== null &&
                                          idr.com_barge_sales_fin_id !== null
                                          ? "none"
                                          : "hide",
                                    }}
                                  >
                                    {/* {indx === 0 && */}
                                    <td className="p-0 text-center">
                                      <CheckBox
                                        value={idr.business_no_barge_id}
                                        color="primary"
                                        checked={
                                          this.state.bargeList.indexOf(
                                            idr.business_no_barge_id
                                          ) >= 0 &&
                                          this.state.allocationsList.indexOf(
                                            idr.business_allocation_id
                                          ) >= 0
                                        }
                                        disabled={
                                          (idr.com_sales_term === "Specs" &&
                                            idr.com_quality_results === 0) ||
                                          this.state.selectedSalesContractId ===
                                          "" ||
                                          p.sales_contract_id !==
                                          this.state
                                            .selectedSalesContractId ||
                                          idr.com_sales_fin_id !== null
                                        }
                                        onChange={(e) => {
                                          this.onBargesCheckCommercial(
                                            e.target.value,
                                            p.sales_contract_id,
                                            e.target.checked,
                                            id.new_barges,
                                            idr
                                          );
                                        }}
                                      />
                                      {idr.com_sales_term === "Specs" &&
                                        idr.com_quality_results === 0 &&
                                        this.state.selectedSalesContractId ===
                                        p.sales_contract_id &&
                                        p.quality ===
                                        this.state.selected_sc_quality &&
                                        this.state.selected_sales_type ===
                                        p.sales_type ? (
                                        <i
                                          className="fa fa-exclamation-triangle"
                                          style={{
                                            fontSize: 20,
                                            color: "orange",
                                            cursor: "pointer",
                                          }}
                                          data-toggle="modal"
                                          title="Quality results are not available"
                                          data-placement="bottom"
                                          data-keyboard="false"
                                          data-backdrop="static"
                                        ></i>
                                      ) : (
                                        <i
                                          className="fa fa-exclamation-triangle"
                                          style={{
                                            fontSize: 20,
                                            color: "orange",
                                            cursor: "pointer",
                                            visibility: "hidden",
                                          }}
                                          data-toggle="modal"
                                          title="Quality results are not available"
                                          data-placement="bottom"
                                          data-keyboard="false"
                                          data-backdrop="static"
                                        ></i>
                                      )}
                                    </td>
                                    {/* } */}
                                    <td className="table_td text-left">
                                      {pad(idr.business_no_barge_id)}
                                    </td>
                                    <td className="table_td text-left ">
                                      {idr.display_allocation_id}
                                    </td>
                                    <td
                                      className="table_td text-left "
                                      nowrap="true"
                                    >
                                      {idr.barge_nomination}
                                    </td>
                                    <td
                                      className="table_td text-right"
                                      nowrap="true"
                                    >
                                      {idr.allocation_type === "Full"
                                        ? this.toLocaleString(
                                          idr.barge_quantity
                                        )
                                        : this.toLocaleString(
                                          idr.partial_quantity
                                        )}
                                    </td>
                                    <td
                                      className="table_td text-right"
                                      nowrap="true"
                                    >
                                      {this.toLocaleString(
                                        idr.planned_quantity
                                      )}
                                    </td>
                                    <td
                                      className="table_td text-right"
                                      nowrap="true"
                                    >
                                      {this.toLocaleString(
                                        idr.redraft_quantity
                                      )}
                                    </td>
                                    <td
                                      className="table_td text-left"
                                      nowrap="true"
                                    >
                                      {idr.mine_name}
                                    </td>
                                  </tr>
                                </tbody>
                              ))
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {this.state.activeStep === 1 && (
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th></th>
                          <th>Contract No</th>
                          <th>Quantity</th>
                          <th>Adjust Amount</th>
                          <th>Received Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.performa_mapping?.map((e, idx) => (
                          <tr>
                            <td>
                              <CheckBox
                                color="primary"
                                checked={e.checked}
                                onChange={(event) => {
                                  let rows = this.state.performa_mapping;
                                  rows[idx].checked = event.target.checked;
                                  this.setState({
                                    performa_mapping: rows,
                                  });
                                }}
                              />
                            </td>
                            <td>{e.costing_no}</td>
                            <td>
                              <TextField
                                name="performa_quantity"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.performa_quantity}
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Quantity"
                                onChange={(event) => {
                                  let rows = this.state.performa_mapping;
                                  const quan = /^\d*(\.\d{0,4})?$/;
                                  if (
                                    event.target.value === "" ||
                                    quan.test(event.target.value)
                                  ) {
                                    rows[idx].performa_quantity =
                                      event.target.value;
                                    rows[idx]["performa_adv_adjustment"] = (
                                      (parseFloat(
                                        e.advance_adjustment_amount
                                          ? e.advance_adjustment_amount
                                          : 1
                                      ) *
                                        parseFloat(event.target.value)) /
                                      parseFloat(e.quantity ? e.quantity : 1)
                                    ).toFixed(2);
                                    rows[idx]["performa_amount"] = (
                                      (parseFloat(
                                        e.performa_received_amount
                                          ? e.performa_received_amount
                                          : 1
                                      ) *
                                        parseFloat(event.target.value)) /
                                      parseFloat(e.quantity ? e.quantity : 1)
                                    ).toFixed(2);
                                  }
                                  this.setState({
                                    performa_mapping: rows,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                name="performa_adv_adjustment"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.performa_adv_adjustment}
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Adv Adjustment"
                                onChange={(event) => {
                                  let rows = this.state.performa_mapping;
                                  const quan = /^\d*(\.\d{0,4})?$/;
                                  if (
                                    event.target.value === "" ||
                                    quan.test(event.target.value)
                                  ) {
                                    rows[idx].performa_adv_adjustment =
                                      event.target.value;
                                  }
                                  this.setState({
                                    performa_mapping: rows,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              <TextField
                                name="performa_amount"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={e.performa_amount}
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                placeholder="Amount"
                                onChange={(event) => {
                                  let rows = this.state.performa_mapping;
                                  const quan = /^\d*(\.\d{0,4})?$/;
                                  if (
                                    event.target.value === "" ||
                                    quan.test(event.target.value)
                                  ) {
                                    rows[idx].performa_amount =
                                      event.target.value;
                                  }
                                  this.setState({
                                    performa_mapping: rows,
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {this.state.activeStep === 2 && this.state.bargeList.length > 0 && (
                <div>
                  {/*=============================================
                          =           Coal Costing Section              =
                          =============================================*/}
                  <div className="col shadow-sm p-0 mb-2 bg-white border ">
                    <h5 className="mb-3 p-2 section_header">Coal Costing</h5>
                    <div className="row">
                      <div className="row col-lg-12">
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">
                            Barge Quantity in MT
                          </label>
                          <TextField
                            name="coal_cost_coal_quantity"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={
                              this.state.coal_cost_coal_quantity
                                ? Number(this.state.coal_cost_coal_quantity)
                                : ""
                            }
                            fullWidth
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "12px",
                              },
                            }}
                            placeholder="Barge Quantity in MT"
                            onChange={this.onBargeChange}
                          />
                        </div>
                        {this.state.sales_index_linked === "Yes" && (
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-0">
                              Coal Price per MT in {this.state.sales_currency}
                            </label>
                            <div className="contract_display_header_value mt-3">
                              {this.toLocaleString(
                                this.state.coal_cost_coal_price_pmt
                              )}
                            </div>
                            {/* <TextField
                              name="coal_cost_coal_price_pmt"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              value={this.state.coal_cost_coal_price_pmt}
                              fullWidth
                              disabled
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "12px",
                                },
                              }}
                              placeholder="Coal Price per MT"
                              onChange={this.onCoalCostChange}
                            /> */}
                          </div>
                        )}
                        {this.state.sales_index_linked === "No" && (
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-0">
                              Coal Price per MT
                            </label>
                            <label className="contract_display_header_value mt-3">
                              {this.toLocaleString(
                                this.state.coal_cost_coal_price_pmt
                              )}{" "}
                              {this.state.coalPriceCurrency}
                            </label>
                          </div>
                        )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.coalPriceCurrency !== "IDR" && (
                            <div className="col-lg-4  mb-2">
                              <label className="form_label mb-0">
                                Billing Currency
                              </label>
                              <TextField
                                name="coal_cost_billing_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_billing_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_billing_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.billingCurrencyChange}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                <MenuItem
                                  value={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"
                                  }
                                >
                                  {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </MenuItem>
                                <MenuItem value={this.state.coalPriceCurrency}>
                                  {this.state.coalPriceCurrency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.coalPriceCurrency !== "USD" && (
                            <div className="col-lg-4  mb-2">
                              <label className="form_label mb-0">
                                Billing Currency
                              </label>
                              <TextField
                                name="coal_cost_billing_currency"
                                margin="dense"
                                variant="outlined"
                                //inputProps={{style:{textTransform:'uppercase'}}}
                                value={this.state.coal_cost_billing_currency}
                                fullWidth
                                error={
                                  this.state.coal_cost_billing_currency_error
                                }
                                // onChange={this.handleTextChange}
                                onChange={this.billingCurrencyChange}
                                select
                              >
                                <MenuItem value="0" disabled>
                                  Select
                                </MenuItem>
                                <MenuItem
                                  value={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"
                                  }
                                >
                                  {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </MenuItem>
                                <MenuItem value={this.state.coalPriceCurrency}>
                                  {this.state.coalPriceCurrency}
                                </MenuItem>
                              </TextField>
                            </div>
                          )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          this.state.coalPriceCurrency !== "IDR" &&
                          this.state.coalPriceCurrency !==
                          this.state.coal_cost_billing_currency && (
                            <div className="col-lg-4  mb-2">
                              <label className="form_label mb-0">
                                Exchange rate {this.state.coalPriceCurrency} to
                                IDR
                              </label>
                              <TextField
                                // name='coal_cost_currency_xchg_rate'
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                value={this.state.currency_xchg_rate}
                                placeholder="Exchange rate"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                onChange={this.onExchangeRateChange}
                              />
                            </div>
                          )}
                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.coalPriceCurrency !== "USD" &&
                          this.state.coalPriceCurrency !==
                          this.state.coal_cost_billing_currency && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">
                                Exchange rate USD to{" "}
                                {this.state.coalPriceCurrency}
                              </label>
                              <TextField
                                name="currency_xchg_rate"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                fullWidth
                                value={this.state.currency_xchg_rate}
                                placeholder="Exchange rate to USD"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "12px",
                                  },
                                }}
                                onChange={this.onExchangeRateChange}
                              />
                            </div>
                          )}
                        {this.state.coal_cost_billing_currency !==
                          this.state.coalPriceCurrency &&
                          this.state.coal_cost_billing_currency !== "0" && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">
                                Coal Price per MT in{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {this.state
                                  .coal_cost_coal_price_pmt_local_currency
                                  ? this.toLocaleString(
                                    this.state
                                      .coal_cost_coal_price_pmt_local_currency
                                  ) +
                                  " " +
                                  this.state.coal_cost_billing_currency
                                  : ""}
                              </label>
                            </div>
                          )}
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">Coal Price</label>
                          <label className="contract_display_header_value mt-3">
                            {this.toLocaleString(
                              this.state.coal_cost_coal_price
                            )}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                        {this.state.sales_term === "Specs" &&
                          this.state.costing_type === "Commercial" && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">
                                Adjusted Coal Price PMT
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {this.toLocaleString(
                                  this.state.coal_cost_adjusted_coal_price_pmt
                                )}{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}
                        {this.state.sales_term === "Specs" &&
                          this.state.costing_type === "Commercial" && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">
                                Adjusted Coal Price
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {this.toLocaleString(
                                  this.state.coal_cost_adjusted_coal_price
                                )}{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">VAT Value</label>
                              <label className="contract_display_header_value mt-3">
                                {this.toLocaleString(this.state.vat_value)}
                                {this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                            <div className="col-lg-4 mb-2">
                              <label className="form_label mb-0">
                                PPh22 Local
                              </label>
                              <label className="contract_display_header_value mt-3">
                                {this.state
                                  .coal_cost_pph22_percent_val_local_cur !== "0"
                                  ? Number(
                                    this.state
                                      .coal_cost_pph22_percent_val_local_cur
                                  ) >= 0
                                    ? this.toLocaleString(
                                      this.state
                                        .coal_cost_pph22_percent_val_local_cur
                                    )
                                    : ` ( ${this.toLocaleString(
                                      this.state
                                        .coal_cost_pph22_percent_val_local_cur
                                    )} ) `
                                  : 0}{" "}
                                {this.state.coal_cost_billing_currency}
                              </label>
                            </div>
                          )}
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">
                            PPh22 Export
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {this.state
                              .coal_cost_pph22_export_value_local_cur !== "0"
                              ? Number(
                                this.state
                                  .coal_cost_pph22_export_value_local_cur
                              ) >= 0
                                ? this.toLocaleString(
                                  this.state
                                    .coal_cost_pph22_export_value_local_cur
                                )
                                : `( ${this.toLocaleString(
                                  this.state
                                    .coal_cost_pph22_export_value_local_cur
                                )} )`
                              : 0}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">
                            Advance Adjustments
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {this.state.total_advance_adjustment_value !== "0"
                              ? Number(
                                this.state.total_advance_adjustment_value
                              ) >= 0
                                ? this.toLocaleString(
                                  this.state.total_advance_adjustment_value
                                )
                                : `( ${this.toLocaleString(
                                  this.state.total_advance_adjustment_value
                                )} )`
                              : 0}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                        {(this.state.sales_type === "CIF DP" || this.state.sales_type === "CFR DP") && (
                          <div className="col-lg-4 mb-2">
                            <label className="form_label mb-0">
                              Freight Price PMT in USD
                            </label>
                            <label className="contract_display_header_value mt-3">
                              {this.state.freight_price_pmt}
                            </label>
                            {/* <TextField
                              name="freight_price_pmt"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              value={this.state.freight_price_pmt}
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "12px",
                                },
                              }}
                              placeholder="Freight Price PMT"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    freight_price_pmt: e.target.value,
                                  },
                                  () => {
                                    this.bargeQuantityChange();
                                  }
                                );
                              }}
                            /> */}
                          </div>
                        )}
                        <div className="col-lg-4 mb-2">
                          <label className="form_label mb-0">
                            Receivable from Buyer
                          </label>
                          <label className="contract_display_header_value mt-3">
                            {this.toLocaleString(
                              this.state.receivable_from_supplier
                            )}{" "}
                            {this.state.coal_cost_billing_currency}
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {this.state.sales_term === "Specs" &&
                          this.state.costing_type === "Commercial" && (
                            <div>
                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  GAR Penality / Bonus per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                              <th
                                                className="allocation_table_header_label border-0"
                                                style={{ minWidth: 95 }}
                                              >
                                                Bonus Cap
                                              </th>
                                              <th
                                                className="allocation_table_header_label border-0"
                                                style={{ minWidth: 100 }}
                                              >
                                                Penality Cap
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value2_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap2}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap2}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_calorific_value3_actual
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.bonusCap3}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.penalityCap3}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ width: "30%", padding: 8 }}>
                                        <TextField
                                          name="coal_cost_gar_penality"
                                          margin="dense"
                                          variant="outlined"
                                          type="number"
                                          value={
                                            this.state.coal_cost_gar_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="GAR Penality per MT"
                                          // label='GAR Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                              "0"
                                              ? "GAR Penality per MT in " +
                                              this.state
                                                .coal_cost_billing_currency
                                              : "GAR Penality per MT"
                                          }
                                          onChange={this.onGarPenalityChange}
                                        />
                                        <TextField
                                          name="coal_cost_gar_bonus"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={this.state.coal_cost_gar_bonus}
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="GAR Bonus per MT"
                                          // label='GAR Bonus per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                              "0"
                                              ? "GAR Bonus per MT in " +
                                              this.state
                                                .coal_cost_billing_currency
                                              : "GAR Bonus per MT"
                                          }
                                          onChange={this.onGarBonusChange}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  ASH Penality per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_basis}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_typical}
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_ash_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {this.state.quality_ash_actual}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ padding: 8 }}>
                                        <TextField
                                          name="coal_cost_ash_penality"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={
                                            this.state.coal_cost_ash_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="ASH Penality per MT"
                                          // label='ASH Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                              "0"
                                              ? "ASH Penality per MT in " +
                                              this.state
                                                .coal_cost_billing_currency
                                              : "ASH Penality per MT"
                                          }
                                          onChange={this.onAshPenalityChange}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="col shadow-sm p-0 mb-2 bg-white border ">
                                <h5
                                  className="mb-0 p-2 section_header"
                                  style={{ fontSize: 14 }}
                                >
                                  Sulphur Penality per MT
                                </h5>
                                <table className="table border-0 mb-0">
                                  <tbody>
                                    <tr>
                                      <td className="p-0">
                                        <table className="table mb-0 border-0">
                                          <thead className="border-0">
                                            <tr>
                                              <th className="allocation_table_header_label border-0">
                                                Units
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Basis
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Typical
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Rejection
                                              </th>
                                              <th className="allocation_table_header_label border-0">
                                                Actual
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td className="allocation_table_value border-0">
                                                Kcal/kg
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_basis
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_typical
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_rejection
                                                }
                                              </td>
                                              <td className="allocation_table_value border-0">
                                                {
                                                  this.state
                                                    .quality_total_sulphur_actual
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                      <td style={{ padding: 8 }}>
                                        <TextField
                                          name="coal_cost_sulphur_penality"
                                          margin="dense"
                                          type="number"
                                          variant="outlined"
                                          value={
                                            this.state
                                              .coal_cost_sulphur_penality
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          placeholder="Sulphur Penality per MT"
                                          // label='Sulphur Penality per MT'
                                          label={
                                            this.state
                                              .coal_cost_billing_currency !==
                                              "0"
                                              ? "Sulphur Penality per MT in " +
                                              this.state
                                                .coal_cost_billing_currency
                                              : "Sulphur Penality per MT"
                                          }
                                          onChange={
                                            this.onSulphurPenalityChange
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                      {(config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL") && (
                          <div className="col-lg pb-3">
                            <div className="col-lg-6 pl-0 mb-2">
                              <label className="form_label mb-0">
                                PPH22 Export Applicable
                              </label>
                              <div className="col-lg pl-0">
                                <RadioGroup
                                  aria-label="coal_cost_pph22_export"
                                  name="coal_cost_pph22_export"
                                  onChange={this.handleRadioButtons}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="Yes"
                                    control={
                                      <NewRadio
                                        checked={
                                          this.state.coal_cost_pph22_export ===
                                          "Yes"
                                        }
                                      />
                                    }
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="No"
                                    control={
                                      <NewRadio
                                        checked={
                                          this.state.coal_cost_pph22_export ===
                                          "No"
                                        }
                                      />
                                    }
                                    label="No"
                                  />
                                </RadioGroup>
                              </div>
                            </div>
                            {this.state.coal_cost_pph22_export === "Yes" && (
                              <div
                                className="col-lg-6 p-2 rounded"
                                style={{ backgroundColor: "#f7f3f0" }}
                              >
                                <div className="row col-lg-12 p-0">
                                  <h6>PPH22 Export</h6>
                                </div>
                                <div className="row">
                                  <div className="col-lg-12 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Amount
                                    </label>
                                    <div style={{ color: "#42352d" }}>
                                      <TextField
                                        name="coal_cost_pph22_export_amount"
                                        margin="dense"
                                        type="number"
                                        // variant='outlined'
                                        fullWidth
                                        value={
                                          this.state.coal_cost_pph22_export_amount
                                        }
                                        onChange={this.onPph22ExportChange}
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH22 Export %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.coal_cost_pph22_export_percent}
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH22 Export in{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state
                                        .coal_cost_pph22_export_value_local_cur
                                        ? this.toLocaleString(
                                          this.state
                                            .coal_cost_pph22_export_value_local_cur
                                        )
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                          <div className="row col-lg pb-3">
                            <div className="col-lg-6 pl-0 pr-0 pb-2">
                              <div className="col-lg-6 pl-0 mb-2">
                                <label className="form_label mb-0">
                                  VAT Applicable
                                </label>
                                <div className="col-lg pl-0">
                                  <RadioGroup
                                    aria-label="vat_applicable"
                                    name="vat_applicable"
                                    onChange={(e) => {
                                      this.setState(
                                        {
                                          vat_applicable: e.target.value,
                                        },
                                        () => {
                                          this.onVatChangeHandler();
                                        }
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.vat_applicable === "Yes"
                                          }
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.vat_applicable === "No"
                                          }
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                              {this.state.vat_applicable === "Yes" ? (
                                <div
                                  className="col-lg-12 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>VAT</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Amount
                                      </label>
                                      <div style={{ color: "#42352d" }}>
                                        <TextField
                                          name="vat_amount"
                                          margin="dense"
                                          //variant='outlined'
                                          type="number"
                                          value={this.state.vat_amount}
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          onChange={(e) => {
                                            const quan = /^\d*(\.\d{0,4})?$/;
                                            if (
                                              e.target.value === "" ||
                                              quan.test(e.target.value)
                                            ) {
                                              var value = e.target.value;
                                              this.setState(
                                                {
                                                  vat_amount: value,
                                                },
                                                () => {
                                                  this.onVatChangeHandler();
                                                }
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        VAT %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        <TextField
                                          name="vat_percent"
                                          margin="dense"
                                          type="number"
                                          variant="standard"
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                            },
                                          }}
                                          value={this.state.vat_percent}
                                          onChange={(e) => {
                                            const pr = /^\d*(\.\d{0,4})?$/;
                                            if (
                                              e.target.value === "" ||
                                              pr.test(e.target.value)
                                            ) {
                                              var value = e.target.value;
                                              this.setState(
                                                {
                                                  vat_percent: value,
                                                },
                                                () => {
                                                  this.onVatChangeHandler();
                                                }
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        VAT Value
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.vat_value
                                          ? this.toLocaleString(
                                            this.state.vat_value
                                          )
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-lg-12 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>PPH22 Local</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-6 pl-0 mb-2 pr-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Barge Quantity in MT
                                      </label>
                                      <div style={{ color: "#42352d" }}>
                                        <TextField
                                          name="coal_cost_pph22_barge_quantity_in_mt"
                                          margin="dense"
                                          // variant='outlined'
                                          type="number"
                                          value={
                                            this.state
                                              .coal_cost_pph22_barge_quantity_in_mt
                                          }
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          onChange={this.onPph22LocalChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Coal Price per MT in{" "}
                                        {this.state.coal_cost_billing_currency}
                                      </label>
                                      <div>
                                        <TextField
                                          name="coal_cost_pph22_coal_price_per_mt"
                                          margin="dense"
                                          type="number"
                                          value={
                                            this.state
                                              .coal_cost_pph22_coal_price_per_mt
                                          }
                                          // variant='outlined'
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          onChange={
                                            this.onPph22LocalCoalPriceChange
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        PPH22 Local %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state.coal_cost_pph22_percent}
                                      </div>
                                    </div>
                                    {this.state.purchase_currency ===
                                      this.state.coal_cost_billing_currency && (
                                        <div className="col-lg-6 p-0 mb-2">
                                          <label
                                            className="contract_display_header_label"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            PPH22 Local in{" "}
                                            {this.state.purchase_currency}
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {this.toLocaleString(
                                              Number(
                                                this.state
                                                  .coal_cost_pph22_percent_val_local_cur
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )}
                                    {(config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      this.state.purchase_currency !== "IDR" && (
                                        <div className="col-lg-6 p-0 mb-2">
                                          <label
                                            className="contract_display_header_label"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            PPH22 Local in{" "}
                                            {config.company === "SRPL" ||
                                              config.company === "AVS" ||
                                              config.company === "PRPL"
                                              ? " USD"
                                              : "IDR"}
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {this.state
                                              .coal_cost_pph22_percent_val_local_cur_new
                                              ? this.toLocaleString(
                                                Number(
                                                  this.state
                                                    .coal_cost_pph22_percent_val_local_cur_new
                                                ).toFixed(0)
                                              )
                                              : ""}
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="col-lg-6 pr-0 pl-2">
                              <div className="col-lg-12 pl-0 mb-2">
                                <label className="form_label mb-0">
                                  PPH22 Export Applicable
                                </label>
                                <div className="col-lg pl-0">
                                  <RadioGroup
                                    aria-label="coal_cost_pph22_export"
                                    name="coal_cost_pph22_export"
                                    onChange={this.handleRadioButtons}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.coal_cost_pph22_export ===
                                            "Yes"
                                          }
                                        />
                                      }
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={
                                        <NewRadio
                                          checked={
                                            this.state.coal_cost_pph22_export ===
                                            "No"
                                          }
                                        />
                                      }
                                      label="No"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                              {this.state.coal_cost_pph22_export === "Yes" && (
                                <div
                                  className="col-lg-12 p-2 rounded"
                                  style={{ backgroundColor: "#f7f3f0" }}
                                >
                                  <div className="row col-lg-12 p-0">
                                    <h6>PPH22 Export</h6>
                                  </div>
                                  <div className="row">
                                    <div className="col-lg-12 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Amount
                                      </label>
                                      <div style={{ color: "#42352d" }}>
                                        <TextField
                                          name="coal_cost_pph22_export_amount"
                                          margin="dense"
                                          type="number"
                                          // variant='outlined'
                                          fullWidth
                                          inputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "12px",
                                            },
                                          }}
                                          value={
                                            this.state
                                              .coal_cost_pph22_export_amount
                                          }
                                          onChange={this.onPph22ExportChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        PPH22 Export %
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {
                                          this.state
                                            .coal_cost_pph22_export_percent
                                        }
                                      </div>
                                    </div>
                                    {this.state.purchase_currency ===
                                      this.state.coal_cost_billing_currency && (
                                        <div className="col-lg-6 p-0 mb-2">
                                          <label
                                            className="contract_display_header_label"
                                            style={{
                                              color: "rgba(72, 59, 52, 0.7)",
                                            }}
                                          >
                                            PPH22 Export in{" "}
                                            {this.state.coal_cost_billing_currency}
                                          </label>
                                          <div
                                            className="contract_display_header_value"
                                            style={{ color: "#42352d" }}
                                          >
                                            {this.state
                                              .coal_cost_pph22_export_value_local_cur
                                              ? this.toLocaleString(
                                                Number(
                                                  this.state
                                                    .coal_cost_pph22_export_value_local_cur
                                                )
                                              )
                                              : ""}
                                          </div>
                                        </div>
                                      )}
                                    <div className="col-lg-6 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        PPH22 Export in{" "}
                                        {config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? " USD"
                                          : "IDR"}
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {this.state
                                          .coal_cost_pph22_export_value_local_cur_new
                                          ? this.toLocaleString(
                                            Number(
                                              this.state
                                                .coal_cost_pph22_export_value_local_cur_new
                                            ).toFixed(0)
                                          )
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {this.state.vat_applicable === "Yes" && (
                              <div
                                className="col-lg-6 p-2 rounded"
                                style={{ backgroundColor: "#f7f3f0" }}
                              >
                                <div className="row col-lg-12 p-0">
                                  <h6>PPH22 Local</h6>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 pl-0 mb-2 pr-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Barge Quantity in MT
                                    </label>
                                    <div style={{ color: "#42352d" }}>
                                      <TextField
                                        name="coal_cost_pph22_barge_quantity_in_mt"
                                        margin="dense"
                                        // variant='outlined'
                                        type="number"
                                        value={
                                          this.state
                                            .coal_cost_pph22_barge_quantity_in_mt
                                        }
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                          },
                                        }}
                                        onChange={this.onPph22LocalChange}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Coal Price per MT in{" "}
                                      {this.state.coal_cost_billing_currency}
                                    </label>
                                    <div>
                                      <TextField
                                        name="coal_cost_pph22_coal_price_per_mt"
                                        margin="dense"
                                        type="number"
                                        value={
                                          this.state
                                            .coal_cost_pph22_coal_price_per_mt
                                        }
                                        // variant='outlined'
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "12px",
                                          },
                                        }}
                                        onChange={
                                          this.onPph22LocalCoalPriceChange
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      PPH22 Local %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {this.state.coal_cost_pph22_percent}
                                    </div>
                                  </div>
                                  {this.state.purchase_currency ===
                                    this.state.coal_cost_billing_currency && (
                                      <div className="col-lg-6 p-0 mb-2">
                                        <label
                                          className="contract_display_header_label"
                                          style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                        >
                                          PPH22 Local in{" "}
                                          {this.state.purchase_currency}
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {this.toLocaleString(
                                            Number(
                                              this.state
                                                .coal_cost_pph22_percent_val_local_cur
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  {(config.company === "SDAM" ||
                                    config.company === "SRK" ||
                                    config.company === "BTR") &&
                                    this.state.purchase_currency !== "IDR" && (
                                      <div className="col-lg-6 p-0 mb-2">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          PPH22 Local in{" "}
                                          {config.company === "SRPL" ||
                                            config.company === "AVS" ||
                                            config.company === "PRPL"
                                            ? " USD"
                                            : "IDR"}
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {this.state
                                            .coal_cost_pph22_percent_val_local_cur_new
                                            ? this.toLocaleString(
                                              Number(
                                                this.state
                                                  .coal_cost_pph22_percent_val_local_cur_new
                                              ).toFixed(0)
                                            )
                                            : ""}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>

                  {/*=============================================
                          =               Advance Adjustment          =
                          =============================================*/}
                  {/* <div className="col shadow-sm mt-3 border p-0">
                    <h5 className="mb-2 p-2 section_header">
                      Advance Adjustment
                    </h5>

                    {this.state.advance_adjustments.map((e, idx) => (
                      <div key={idx} className="row pl-2 pr-2">
                        <div className="col-lg-11 border_dashed rounded-lg mb-1 p-0">
                          <div className="row">
                            <label className="label-text pl-2 col-lg-5">
                              Advance Adjustment
                            </label>
                            <div className="col-lg pl-0">
                              <RadioGroup
                                aria-label="advance_adjustment"
                                name="advance_adjustment"
                                onChange={this.handleAdvance_AdjustmentRadioButtons(
                                  idx
                                )}
                                style={{ flexDirection: "row" }}
                              >
                                <FormControlLabel
                                  value="No"
                                  control={
                                    <NewRadio
                                      checked={e.advance_adjustment === "No"}
                                    />
                                  }
                                  label="No"
                                />
                                <FormControlLabel
                                  value="Lumpsum"
                                  control={
                                    <NewRadio
                                      checked={
                                        e.advance_adjustment === "Lumpsum"
                                      }
                                    />
                                  }
                                  label="Lumpsum"
                                />
                                <FormControlLabel
                                  value="Per MT"
                                  control={
                                    <NewRadio
                                      checked={
                                        e.advance_adjustment === "Per MT"
                                      }
                                    />
                                  }
                                  label="Per MT"
                                />
                              </RadioGroup>
                            </div>
                          </div>
                          {e.advance_adjustment === "Lumpsum" && (
                            <div>
                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Sales Contract
                                </label>
                                <label className="col-lg-5 pl-0 label-text">
                                  {this.state.sales_contract_no}
                                </label>
                              </div>

                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Advance Adjustment Value{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-lg-5 pl-0">
                                  <TextField
                                    name="advance_adjustment_value"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    value={
                                      e.advance_adjustment_value
                                        ? Number(e.advance_adjustment_value)
                                        : ""
                                    }
                                    fullWidth
                                    error={e.advance_adjustment_valueError}
                                    placeholder="Advance Adjustment Value"
                                    onChange={(e) => {
                                      var row = this.state.advance_adjustments;
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        row[idx].advance_adjustment_value =
                                          e.target.value;
                                        row[
                                          idx
                                        ].advance_adjustment_valueError = false;
                                        row[idx].pc_AmountError = "";

                                        var total_advance_adjustment_value = 0;
                                        for (var i of row) {
                                          total_advance_adjustment_value +=
                                            Number(i.advance_adjustment_value);
                                        }
                                        if (
                                          Number(
                                            this.state.remaining_cus_adv_payment
                                          ) <
                                          Number(total_advance_adjustment_value)
                                        ) {
                                          row[
                                            idx
                                          ].advance_adjustment_valueError = true;
                                          row[idx].pc_AmountError =
                                            "Amount Is Exceeding Advance Customer Amount";
                                        }

                                        this.setState(
                                          {
                                            advance_adjustments: row,
                                          },
                                          () => {
                                            this.onAdvanceAdjustmentsCal();
                                          }
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          className="adornment_background"
                                        >
                                          {
                                            this.state
                                              .coal_cost_billing_currency
                                          }
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        padding: 0,
                                      },
                                    }}
                                  />
                                  <span style={{ color: "red", fontSize: 10 }}>
                                    {e.pc_AmountError ? e.pc_AmountError : ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          {e.advance_adjustment === "Per MT" && (
                            <div>
                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Sales Contract
                                </label>
                                <label className="col-lg-5 pl-0 label-text">
                                  {this.state.sales_contract_no}
                                </label>
                              </div>

                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Barge Quantity in MT{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-lg-5 pl-0">
                                  <TextField
                                    name="advance_adjustment_barge_quantity"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Barge Quantity in MT"
                                    type="number"
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={Number(
                                      e.advance_adjustment_barge_quantity
                                    )}
                                    error={
                                      e.advance_adjustment_barge_quantity_valueError
                                    }
                                    onChange={(e) => {
                                      var row = this.state.advance_adjustments;
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        var advance_adjustment_value = (
                                          Number(value) *
                                          Number(row[idx].adjustment_per_mt)
                                        ).toFixed(2);
                                        row[
                                          idx
                                        ].advance_adjustment_barge_quantity =
                                          e.target.value;
                                        row[idx].advance_adjustment_value =
                                          advance_adjustment_value;
                                        row[
                                          idx
                                        ].advance_adjustment_barge_quantity_valueError = false;
                                        row[
                                          idx
                                        ].adjustment_per_mt_valueError = false;
                                        row[idx].pc_AmountError = "";

                                        var total_advance_adjustment_value = 0;
                                        for (var i of row) {
                                          total_advance_adjustment_value +=
                                            Number(i.advance_adjustment_value);
                                        }
                                        if (
                                          Number(
                                            this.state.remaining_cus_adv_payment
                                          ) <
                                          Number(total_advance_adjustment_value)
                                        ) {
                                          row[
                                            idx
                                          ].advance_adjustment_barge_quantity_valueError = true;
                                          row[
                                            idx
                                          ].adjustment_per_mt_valueError = true;
                                          row[idx].pc_AmountError =
                                            "Amount Is Exceeding Advance Customer Amount";
                                        }

                                        this.setState({
                                          advance_adjustments: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Adjustment per MT{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <div className="col-lg-5 pl-0">
                                  <TextField
                                    name="adjustment_per_mt"
                                    margin="dense"
                                    type="number"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Adjustment per MT"
                                    value={Number(e.adjustment_per_mt)}
                                    error={e.adjustment_per_mt_valueError}
                                    onChange={(e) => {
                                      var row = this.state.advance_adjustments;
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var value = e.target.value;
                                        if (value === "") {
                                          value = "1";
                                        }
                                        var advance_adjustment_value = (
                                          Number(value) *
                                          Number(
                                            row[idx]
                                              .advance_adjustment_barge_quantity
                                          )
                                        ).toFixed(2);
                                        row[idx].adjustment_per_mt =
                                          e.target.value;
                                        row[idx].advance_adjustment_value =
                                          advance_adjustment_value;
                                        row[
                                          idx
                                        ].adjustment_per_mt_valueError = false;
                                        row[
                                          idx
                                        ].advance_adjustment_barge_quantity_valueError = false;
                                        row[idx].pc_AmountError = "";

                                        var total_advance_adjustment_value = 0;
                                        for (var i of row) {
                                          total_advance_adjustment_value +=
                                            Number(i.advance_adjustment_value);
                                        }
                                        if (
                                          Number(
                                            this.state.remaining_cus_adv_payment
                                          ) <
                                          Number(total_advance_adjustment_value)
                                        ) {
                                          row[
                                            idx
                                          ].adjustment_per_mt_valueError = true;
                                          row[
                                            idx
                                          ].advance_adjustment_barge_quantity_valueError = true;
                                          row[idx].pc_AmountError =
                                            "Amount Is Exceeding Advance Sales Contract Amount";
                                        }
                                        this.setState(
                                          {
                                            advance_adjustments: row,
                                          },
                                          () => {
                                            this.onAdvanceAdjustmentsCal();
                                          }
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          className="adornment_background"
                                        >
                                          {
                                            this.state
                                              .coal_cost_billing_currency
                                          }
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        padding: 0,
                                      },
                                    }}
                                  />
                                  <span style={{ color: "red", fontSize: 9 }}>
                                    {e.pc_AmountError ? e.pc_AmountError : ""}
                                  </span>
                                </div>
                              </div>

                              <div className="row">
                                <label className="label-text pl-2 col-lg-5">
                                  Advance Adjustment Value
                                </label>
                                <label className="col-lg-5 pl-0 label-text">
                                  {this.toLocaleString(
                                    e.advance_adjustment_value
                                  )}
                                </label>
                              </div>
                            </div>
                          )}
                        </div>

                        {this.state.advance_adjustments.length === 1 ? (
                          <div></div>
                        ) : (
                          <div>
                            <i
                              data-toggle="tooltip1"
                              title="Remove"
                              data-html="true"
                              data-placement="bottom"
                              className="fa fa-trash col-lg-1"
                              aria-hidden="true"
                              style={{
                                fontSize: 22,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={this.removeAdvanceAdjustmentsRowHandler(
                                idx
                              )}
                            ></i>
                          </div>
                        )}
                      </div>
                    ))}

                    <br />
                    <div className="row pl-2 pr-2 mb-2">
                      <button
                        type="button"
                        style={{ color: config.themeColor, width: 110 }}
                        className="header_button header_button_text addrow_button_adjustment"
                        onClick={this.addMoreAdvanceAdjustmentRowsHandler}
                      >
                        Add Row
                      </button>
                    </div>
                  </div> */}

                  <br />

                  {(this.state.features.indexOf("366") >= 0 ||
                    this.state.features.indexOf("97") >= 0) && (
                      <FileUpload
                        files={this.state.files}
                        type="Costings"
                        feature_name="Sales_fin_commercial_coal_costing"
                        callbackFn={(data, mandatoryFiles) => {
                          this.setState({
                            files: data,
                            costing_files: mandatoryFiles,
                          });
                        }}
                      />
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 2
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 2 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
