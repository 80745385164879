import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  MenuItem,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class MvBuyerDemurrageAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      buyerData: [
        {
          buyer: "0",
          invoice_date: null,
          invoice_no: "",
          ppn: "",
          demurrage_per_day: "",
          demurrage_currency: "0",
          despatch_per_day: "",
          despatch_currency: "0",
          demurrage_or_despatch: "Demurrage",
          demurrage_in_days: "",
          demurrage_incurred: "",
          despatch_in_days: "",
          despatch_incurred: "",
          buyer_currency_xchg_rate: "",
          buyer_pph23_account: "0",
          buyer_base_price: "",
          buyer_vat_percent: "10",
          buyer_vat_value: "",
          buyer_pph23_percent: "2",
          buyer_pph23_value: "",
          buyer_total: "",
          buyer_ctc: "",
        },
      ],
      buyer_menu: [],
      currencyData: [],
      features: [],
      isLoading: true,
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Buyer_Demurrage",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("49") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      try {
        var loginUserID = this.Cookie.getCookie("loginUserId");
        var idToken = this.Cookie.getIdTokenCookie();
        var businessNoID = this.props.businessNoID;

        await api.getCurrencies(loginUserID, idToken).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.Currency) {
                  var currencies = [
                    <MenuItem value="0" key={-1}>
                      Select Currency
                    </MenuItem>,
                  ];
                  for (var i in res.Currency) {
                    currencies.push(
                      <MenuItem value={res.Currency[i].currency} key={[i]}>
                        {res.Currency[i].currency}
                      </MenuItem>
                    );
                  }
                  this.setState({
                    currencyData: currencies,
                    isLoading: false,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    var buyer_menu = [
                      <MenuItem value="0" key={-1}>
                        Select Buyer
                      </MenuItem>,
                    ];
                    for (var i of res.business_number
                      .business_number_allocation) {
                      buyer_menu.push(
                        <MenuItem value={i.buyer_id} key={[i]}>
                          {i.buyer_name}
                        </MenuItem>
                      );
                    }

                    this.setState({
                      buyer_menu: buyer_menu,
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vesselName: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      isLoading: false,
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    successMsg: "",
                    isLoading: false,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        // const getBuyerData = await api.get_mv_buyer_demurrage(loginUserID, idToken, businessNoID);
        // if (getBuyerData.status >= 200 && getBuyerData.status < 300) {
        //   getBuyerData.json().then((res) => {
        //     if (res.code === '200') {
        //       if (res.mv_buyer_demurrage) {
        //         if (res.mv_buyer_demurrage.length > 0) {
        //           window.$("#AlertMessageModal").modal({
        //             backdrop: 'static',
        //             keyboard: true,
        //             show: true
        //           });
        //           this.setState({
        //             errorMsg: "Already Buyer Demurrage Data Available."
        //           })
        //         }
        //         this.setState({
        //           //buyerData: res.mv_buyer_demurrage,
        //           isLoading: false
        //         });
        //       }
        //       else {
        //         alert('Unexpected error occured. Please contact administrator.');

        //       }
        //     }
        //     else if (res.code === '601') {
        //       this.setState({
        //         errorMsg: res.message,
        //         successMsg: '',
        //         isLoading: false
        //       });
        //     }
        //     else if (res.code === '607') {
        //       window.location.href = '/logout';
        //     }
        //   })

        // }
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
    }
    document.title = config.documentTitle + "Mother Vessel Buyer Demurrage";
  }

  addMoreBuyerRowsHandler = () => {
    const item = {
      buyer: "0",
      demurrage_per_day: "",
      invoice_date: null,
      invoice_no: "",
      ppn: "",
      demurrage_currency: "0",
      despatch_per_day: "",
      despatch_currency: "0",
      demurrage_or_despatch: "Demurrage",
      demurrage_in_days: "",
      demurrage_incurred: "",
      despatch_in_days: "",
      despatch_incurred: "",
      buyer_currency_xchg_rate: "",
      buyer_pph23_account: "0",
      buyer_base_price: "",
      buyer_vat_percent: "10",
      buyer_vat_value: "",
      buyer_pph23_percent: "2",
      buyer_pph23_value: "",
      buyer_total: "",
      buyer_ctc: "",
    };

    this.setState({
      buyerData: [...this.state.buyerData, item],
    });
  };

  removeBuyerRowsHandler = (idx) => () => {
    const rows = this.state.buyerData;
    rows.splice(idx, 1);
    this.setState({
      buyerData: rows,
    });
  };

  submitHandler = () => {
    this.setState({
      isLoading: true,
    });
    var { buyerData } = this.state;
    var buyerRows = buyerData;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        isLoading: false
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        isLoading: false
      });
      return;
    }
    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    for (var i = 0; i < buyerRows.length; i++) {
      buyerRows[i].costing_files = costing_file;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    try {
      // var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
      var businessNoID = this.props.businessNoID;
      api
        .add_mv_buyer_demurrage(
          loginUserID,
          idToken,
          businessNoID,
          buyerRows,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                    isLoading: false
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse({
                        screen: "MvBuyerDemurrage",
                        code: res.code,
                        message: "",
                      });
                    }, 2000);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                  isLoading: false
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } catch (e) {
      window.location.href = "/business-number-list";
    }
  };

  buyer_demurrage_calculations = (idx) => {
    var row = this.state.buyerData;
    var currency_xchg_rate = row[idx].buyer_currency_xchg_rate
      ? row[idx].buyer_currency_xchg_rate
      : 1;
    if (
      (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") &&
      (row[idx].demurrage_currency === "USD" ||
        row[idx].despatch_currency === "USD")
    ) {
      currency_xchg_rate = 1;
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      (row[idx].demurrage_currency === "IDR" ||
        row[idx].despatch_currency === "IDR")
    ) {
      currency_xchg_rate = 1;
    }
    var despatch = Number(row[idx].demurrage_per_day) / 2;
    var demurrage_incurred = (
      (Number(row[idx].demurrage_per_day) *
        Number(row[idx].demurrage_in_days)) /
      currency_xchg_rate
    ).toFixed(2);
    var despatch_incurred = (
      (Number(despatch) * Number(row[idx].despatch_in_days)) /
      currency_xchg_rate
    ).toFixed(2);
    if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      ((row[idx].demurrage_currency !== "IDR" &&
        row[idx].demurrage_currency !== "0" &&
        row[idx].demurrage_currency) ||
        (row[idx].despatch_currency !== "IDR" &&
          row[idx].despatch_currency !== "0" &&
          row[idx].despatch_currency))
    ) {
      demurrage_incurred = (
        Number(row[idx].demurrage_per_day) *
        Number(row[idx].demurrage_in_days) *
        Number(currency_xchg_rate)
      ).toFixed(2);
      despatch_incurred = (
        Number(despatch) *
        Number(row[idx].despatch_in_days) *
        Number(currency_xchg_rate)
      ).toFixed(2);
    }
    //row[idx].demurrage_per_day = e.target.value;
    row[idx].despatch_per_day = despatch;
    row[idx].demurrage_incurred = demurrage_incurred;
    row[idx].despatch_incurred = despatch_incurred;

    var buyer_base_price = "";
    if (row[idx].demurrage_or_despatch === "Demurrage") {
      buyer_base_price = Number(demurrage_incurred).toFixed(2);
    } else if (row[idx].demurrage_or_despatch === "Despatch") {
      buyer_base_price = Number(despatch_incurred).toFixed(2);
    }

    var incurred_value = buyer_base_price;

    let buyer_vat_percent = row[idx].buyer_vat_percent ? row[idx].buyer_vat_percent : 10;
    let buyer_pph23_percent = row[idx].buyer_pph23_percent ? row[idx].buyer_pph23_percent : 2;

    let exclusiveVatConstraint = 100 - buyer_pph23_percent;

    let buyer_vat_value = ((Number(buyer_base_price) * buyer_vat_percent) / 100).toFixed(2);

    let buyer_pph23_value = Number((buyer_base_price * buyer_pph23_percent) / 100).toFixed(2);
    let buyer_total = (
      Number(buyer_base_price) +
      Number(buyer_vat_value) -
      Number(buyer_pph23_value)
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") {
      buyer_total = (
        Number(buyer_base_price) + Number(buyer_vat_value)
      ).toFixed(2);
    }
    let buyer_ctc = Number(
      Number(buyer_base_price) + Number(buyer_vat_value)
    ).toFixed(2);

    row[idx].buyer_base_price = buyer_base_price;
    row[idx].buyer_vat_percent = buyer_vat_percent;
    row[idx].buyer_vat_value = buyer_vat_value;
    row[idx].buyer_pph23_percent = buyer_pph23_percent;
    row[idx].buyer_pph23_value = buyer_pph23_value;
    row[idx].buyer_total = buyer_total;
    row[idx].buyer_ctc = buyer_ctc;

    if (row[idx].buyer_pph23_account === "SDAM") {
      let buyer_base_price = Number((incurred_value * 100) / exclusiveVatConstraint).toFixed(2);
      let buyer_pph23_value = Number(((buyer_base_price * buyer_pph23_percent) / 100).toFixed(2));
      let buyer_total = Number(incurred_value).toFixed(2);
      //let buyer_ctc = Number(buyer_base_price).toFixed(2);
      let buyer_ctc = (
        Number(buyer_base_price) + Number(buyer_pph23_value)
      ).toFixed(2);
      row[idx].buyer_base_price = buyer_base_price;
      row[idx].buyer_pph23_percent = buyer_pph23_percent;
      row[idx].buyer_pph23_value = buyer_pph23_value;
      row[idx].buyer_total = buyer_total;
      row[idx].buyer_ctc = buyer_ctc;
    }
    this.setState({
      buyerData: row,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const re = /^\d*(\.\d{0,10})?$/;

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>
                Buyer Demurrage data for Mother Vessel{" "}
                {this.state.bargeNomination
                  ? "(" + this.state.bargeNomination + ")"
                  : null}{" "}
                - {this.state.businessNo ? this.state.businessNo : ""}
                {this.state.mother_vesselName
                  ? " (" + this.state.mother_vesselName + ")"
                  : ""}
              </h4>
            </div>
          </div>
          <div
            className="card p-3"
            style={{ overflowY: "scroll", minHeight: "86vh" }}
          >
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              {this.state.buyerData.map((e, idx) => (
                <div className="row" key={idx}>
                  <div className="row col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="row col-lg-6 pl-0 mb-2">
                      <div className="col-lg-12 pl-0 mb-2">
                        <label className="contract_display_header_label mb-1">
                          Buyer
                        </label>
                        <TextField
                          name="buyer"
                          margin="dense"
                          variant="outlined"
                          type="number"
                          fullWidth
                          InputProps={{
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                          placeholder="Buyer"
                          select
                          value={e.buyer}
                          onChange={(e) => {
                            var row = this.state.buyerData;
                            row[idx].buyer = e.target.value;
                            this.setState({
                              buyerData: row,
                            });
                          }}
                        >
                          {this.state.buyer_menu}
                        </TextField>
                      </div>
                      <div className="col-lg-9 pl-0 mb-2">
                        <label
                          className="contract_display_header_label"
                          style={{ fontSize: "12px" }}
                        >
                          Buyer Demurrage per Day
                        </label>
                        <TextField
                          name="demurrage_per_day"
                          margin="dense"
                          variant="outlined"
                          value={e.demurrage_per_day}
                          className="rounded-left"
                          placeholder="Buyer Demurrage per Day"
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              var row = this.state.buyerData;
                              row[idx].demurrage_per_day = e.target.value;
                              this.setState(
                                {
                                  buyerData: row,
                                },
                                () => this.buyer_demurrage_calculations(idx)
                              );
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                // variant="outlined"
                                className="adornment_background"
                                position="end"
                                style={{ marginRight: "-14px", width: "50%" }}
                              >
                                <TextField
                                  name="demurrage_currency"
                                  margin="dense"
                                  // variant='outlined'
                                  fullWidth
                                  value={e.billing_currency}
                                  select
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      margin: 0,
                                    },
                                    disableUnderline: true,
                                  }}
                                  onChange={(e) => {
                                    var row = this.state.buyerData;
                                    row[idx].demurrage_currency =
                                      e.target.value;
                                    this.setState(
                                      {
                                        buyerData: row,
                                      },
                                      () =>
                                        this.buyer_demurrage_calculations(idx)
                                    );
                                  }}
                                >
                                  {this.state.currencyData}
                                </TextField>
                              </InputAdornment>
                            ),
                            style: {
                              fontFamily: "Poppins",
                              fontSize: "14px",
                            },
                          }}
                        />
                      </div>
                      <div className="col-lg-3 pl-0 mb-2">
                        <label className="contract_display_header_label">
                          Despatch per Day
                        </label>
                        <div className="contract_display_header_value">
                          {e.despatch_per_day
                            ? this.toLocaleString(e.despatch_per_day)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-12 pl-0 mb-2">
                        <label className="contract_display_header_label">
                          Demurrage / Despatch
                        </label>
                        <RadioGroup
                          aria-label="demurrage_or_despatch"
                          name="demurrage_or_despatch"
                          style={{ flexDirection: "row" }}
                          onChange={(e) => {
                            var row = this.state.buyerData;
                            row[idx].demurrage_or_despatch = e.target.value;
                            if (e.target.value === "Demurrage") {
                              row[idx].despatch_in_days = "";
                              row[idx].despatch_incurred = "";
                            } else if (e.target.value === "Despatch") {
                              row[idx].demurrage_in_days = "";
                              row[idx].demurrage_incurred = "";
                            }
                            this.setState(
                              {
                                buyerData: row,
                              },
                              () => this.buyer_demurrage_calculations(idx)
                            );
                          }}
                        >
                          <FormControlLabel
                            value="Demurrage"
                            control={
                              <NewRadio
                                checked={
                                  e.demurrage_or_despatch === "Demurrage"
                                }
                              />
                            }
                            label="Demurrage"
                          />
                          <FormControlLabel
                            value="Despatch"
                            control={
                              <NewRadio
                                checked={e.demurrage_or_despatch === "Despatch"}
                              />
                            }
                            label="Despatch"
                          />
                        </RadioGroup>
                      </div>
                      {e.demurrage_or_despatch === "Demurrage" && (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label">
                            Demurrage in days
                          </label>
                          <TextField
                            name="demurrage_in_days"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Demurrage in days"
                            value={e.demurrage_in_days}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var row = this.state.buyerData;
                                row[idx].demurrage_in_days = e.target.value;
                                this.setState(
                                  {
                                    buyerData: row,
                                  },
                                  () => this.buyer_demurrage_calculations(idx)
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                      {e.demurrage_or_despatch === "Despatch" && (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label">
                            Despatch in days
                          </label>
                          <TextField
                            name="despatch_in_days"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Despatch in days"
                            value={e.despatch_in_days}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var row = this.state.buyerData;
                                row[idx].despatch_in_days = e.target.value;
                                this.setState(
                                  {
                                    buyerData: row,
                                  },
                                  () => this.buyer_demurrage_calculations(idx)
                                );
                              }
                            }}
                          />
                        </div>
                      )}
                      {(config.company === "SRPL" || config.company === "AVS" ||
                        config.company === "PRPL") &&
                        ((e.demurrage_currency !== "USD" &&
                          e.demurrage_currency !== "0" &&
                          e.demurrage_currency !== null) ||
                          (e.despatch_currency !== "USD" &&
                            e.despatch_currency !== "0" &&
                            e.despatch_currency !== null)) && (
                          <div className="col-lg-6 pl-0 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ fontSize: "10px" }}
                            >
                              Exchange rate from {e.demurrage_currency}{" "}
                              {e.despatch_currency} to USD
                            </label>
                            <TextField
                              name="buyer_currency_xchg_rate"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={e.buyer_currency_xchg_rate}
                              type="number"
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Exchange rate"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  var row = this.state.buyerData;
                                  row[idx].buyer_currency_xchg_rate =
                                    e.target.value;

                                  this.setState(
                                    {
                                      buyerData: row,
                                    },
                                    () => this.buyer_demurrage_calculations(idx)
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") &&
                        ((e.demurrage_currency !== "IDR" &&
                          e.demurrage_currency !== "0" &&
                          e.demurrage_currency !== null) ||
                          (e.despatch_currency !== "IDR" &&
                            e.despatch_currency !== "0" &&
                            e.despatch_currency !== null)) && (
                          <div className="col-lg-6 pl-0 mb-2">
                            <label
                              className="contract_display_header_label"
                              style={{ fontSize: "10px" }}
                            >
                              Exchange rate from {e.demurrage_currency}{" "}
                              {e.despatch_currency} to IDR
                            </label>
                            <TextField
                              name="buyer_currency_xchg_rate"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              value={e.buyer_currency_xchg_rate}
                              type="number"
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Exchange rate"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  var row = this.state.buyerData;
                                  row[idx].buyer_currency_xchg_rate =
                                    e.target.value;

                                  this.setState(
                                    {
                                      buyerData: row,
                                    },
                                    () => this.buyer_demurrage_calculations(idx)
                                  );
                                }
                              }}
                            />
                          </div>
                        )}
                      {e.demurrage_or_despatch === "Demurrage" && (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label">
                            Demurrage Incurred
                          </label>
                          <TextField
                            name="demurrage_incurred"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Demurrage incurred"
                            value={e.demurrage_incurred}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var row = this.state.buyerData;
                                row[idx].demurrage_incurred = e.target.value;

                                var incurred_value = e.target.value;

                                let buyer_vat_percent = row[idx].buyer_vat_percent ? row[idx].buyer_vat_percent : 10;
                                let buyer_pph23_percent = row[idx].buyer_pph23_percent ? row[idx].buyer_pph23_percent : 2;
                                let buyer_vat_value = (
                                  (Number(e.target.value) * buyer_vat_percent) /
                                  100
                                ).toFixed(2);
                                let buyer_pph23_value = Number(
                                  (e.target.value * buyer_pph23_percent) / 100
                                ).toFixed(2);

                                let buyer_total = (
                                  Number(incurred_value) +
                                  Number(buyer_vat_value) -
                                  Number(buyer_pph23_value)
                                ).toFixed(2);
                                if (
                                  config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) {
                                  buyer_total = (
                                    Number(incurred_value) +
                                    Number(buyer_vat_value)
                                  ).toFixed(2);
                                }
                                let buyer_ctc = Number(
                                  Number(incurred_value) +
                                  Number(buyer_vat_value)
                                ).toFixed(2);

                                row[idx].buyer_base_price = incurred_value;
                                row[idx].buyer_vat_percent = buyer_vat_percent;
                                row[idx].buyer_vat_value = buyer_vat_value;
                                row[idx].buyer_pph23_percent =
                                  buyer_pph23_percent;
                                row[idx].buyer_pph23_value = buyer_pph23_value;
                                row[idx].buyer_total = buyer_total;
                                row[idx].buyer_ctc = buyer_ctc;

                                if (row[idx].buyer_pph23_account === "SDAM") {
                                  let buyer_base_price = Number(
                                    (incurred_value * 100) / 98
                                  ).toFixed(2);
                                  let buyer_pph23_percent = 2;
                                  let buyer_pph23_value = Number(
                                    ((buyer_base_price * 2) / 100).toFixed(2)
                                  );
                                  let buyer_total =
                                    Number(incurred_value).toFixed(2);
                                  //let buyer_ctc = Number(buyer_base_price).toFixed(2);
                                  let buyer_ctc = (
                                    Number(buyer_base_price) +
                                    Number(buyer_pph23_value)
                                  ).toFixed(2);
                                  row[idx].buyer_base_price = buyer_base_price;
                                  row[idx].buyer_pph23_percent =
                                    buyer_pph23_percent;
                                  row[idx].buyer_pph23_value =
                                    buyer_pph23_value;
                                  row[idx].buyer_total = buyer_total;
                                  row[idx].buyer_ctc = buyer_ctc;
                                }
                                this.setState({
                                  buyerData: row,
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                      {e.demurrage_or_despatch === "Despatch" && (
                        <div className="col-lg-6 pl-0 mb-2">
                          <label className="contract_display_header_label">
                            Despatch Incurred
                          </label>
                          <TextField
                            name="despatch_incurred"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Despatch Incurred"
                            value={e.despatch_incurred}
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                var row = this.state.buyerData;
                                row[idx].despatch_incurred = e.target.value;
                                var incurred_value = e.target.value;

                                let buyer_vat_percent = row[idx].buyer_vat_percent ? row[idx].buyer_vat_percent : 10;
                                let buyer_pph23_percent = row[idx].buyer_pph23_percent ? row[idx].buyer_pph23_percent : 2;
                                let buyer_vat_value = (
                                  (Number(e.target.value) * buyer_vat_percent) /
                                  100
                                ).toFixed(2);
                                let buyer_pph23_value = Number(
                                  (e.target.value * buyer_pph23_percent) / 100
                                ).toFixed(2);

                                let buyer_total = (
                                  Number(incurred_value) +
                                  Number(buyer_vat_value) -
                                  Number(buyer_pph23_value)
                                ).toFixed(2);
                                if (
                                  config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) {
                                  buyer_total = (
                                    Number(incurred_value) +
                                    Number(buyer_vat_value)
                                  ).toFixed(2);
                                }
                                let buyer_ctc = Number(
                                  Number(incurred_value) +
                                  Number(buyer_vat_value)
                                ).toFixed(2);

                                row[idx].buyer_base_price = incurred_value;
                                row[idx].buyer_vat_percent = buyer_vat_percent;
                                row[idx].buyer_vat_value = buyer_vat_value;
                                row[idx].buyer_pph23_percent =
                                  buyer_pph23_percent;
                                row[idx].buyer_pph23_value = buyer_pph23_value;
                                row[idx].buyer_total = buyer_total;
                                row[idx].buyer_ctc = buyer_ctc;
                                if (row[idx].buyer_pph23_account === "SDAM") {
                                  let buyer_base_price = Number(
                                    (incurred_value * 100) / 98
                                  ).toFixed(2);
                                  let buyer_pph23_percent = 2;
                                  let buyer_pph23_value = Number(
                                    ((buyer_base_price * 2) / 100).toFixed(2)
                                  );
                                  let buyer_total =
                                    Number(incurred_value).toFixed(2);
                                  //let buyer_ctc = Number(buyer_base_price).toFixed(2);
                                  let buyer_ctc = (
                                    Number(buyer_base_price) +
                                    Number(buyer_pph23_value)
                                  ).toFixed(2);
                                  row[idx].buyer_base_price = buyer_base_price;
                                  row[idx].buyer_pph23_percent =
                                    buyer_pph23_percent;
                                  row[idx].buyer_pph23_value =
                                    buyer_pph23_value;
                                  row[idx].buyer_total = buyer_total;
                                  row[idx].buyer_ctc = buyer_ctc;
                                }
                                this.setState({
                                  buyerData: row,
                                });
                              }
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-lg-6 pl-2 mb-2 pr-2">
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                          <div className="col-lg-12 pl-0 mb-2">
                            <label className="contract_display_header_label mb-0">
                              PPH23 Account
                            </label>
                            <TextField
                              name="buyer_pph23_account"
                              margin="dense"
                              variant="outlined"
                              value={e.buyer_pph23_account}
                              select
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              fullWidth
                              onChange={(e) => {
                                var row = this.state.buyerData;
                                row[idx].buyer_pph23_account = e.target.value;
                                this.setState(
                                  {
                                    buyerData: row,
                                  },
                                  () => this.buyer_demurrage_calculations(idx)
                                );
                              }}
                            >
                              <MenuItem value="0">Please Select</MenuItem>
                              <MenuItem value="Buyer">Buyer</MenuItem>
                              <MenuItem value="SDAM">SDAM</MenuItem>
                            </TextField>
                          </div>
                        )}
                      {(config.company === "SRPL" || config.company === "AVS" ||
                        config.company === "PRPL") && (
                          <div
                            className="col-lg-12 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Actual Demurrage</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  {e.demurrage_or_despatch === "Despatch"
                                    ? "Final Despatch"
                                    : e.demurrage_or_despatch === "Demurrage"
                                      ? "Final Demurrage"
                                      : null}
                                </label>
                                <TextField
                                  name="buyer_base_price"
                                  margin="dense"
                                  type="number"
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  // variant='outlined'
                                  value={e.buyer_base_price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.buyerData;
                                      let buyer_vat_percent = row[idx].buyer_vat_percent ? row[idx].buyer_vat_percent : 10;
                                      let buyer_pph23_percent = row[idx].buyer_pph23_percent ? row[idx].buyer_pph23_percent : 2;
                                      let buyer_vat_value = (
                                        (Number(e.target.value) * buyer_vat_percent) /
                                        100
                                      ).toFixed(2);
                                      let buyer_pph23_value = Number(
                                        (e.target.value * buyer_pph23_percent) / 100
                                      ).toFixed(2);
                                      let buyer_total = (
                                        Number(e.target.value) +
                                        Number(buyer_vat_value) -
                                        Number(buyer_pph23_value)
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) {
                                        buyer_total = (
                                          Number(e.target.value) +
                                          Number(buyer_vat_value)
                                        ).toFixed(2);
                                      }
                                      let buyer_ctc = Number(
                                        Number(e.target.value) +
                                        Number(buyer_vat_value)
                                      ).toFixed(2);

                                      row[idx].buyer_base_price = e.target.value;
                                      row[idx].buyer_vat_percent =
                                        buyer_vat_percent;
                                      row[idx].buyer_vat_value = buyer_vat_value;
                                      row[idx].buyer_pph23_percent =
                                        buyer_pph23_percent;
                                      row[idx].buyer_pph23_value =
                                        buyer_pph23_value;
                                      row[idx].buyer_total = buyer_total;
                                      row[idx].buyer_ctc = buyer_ctc;
                                      this.setState({
                                        buyerData: row,
                                      });
                                    }
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {/* {e.buyer_vat_percent
                                  ? this.toLocaleString(e.buyer_vat_percent)
                                  : "-"} */}
                                  <TextField
                                    name="buyer_vat_percent"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    //fullWidth
                                    value={e.buyer_vat_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.buyerData;
                                        let buyer_vat_value = (
                                          (Number(
                                            row[idx]
                                              .buyer_base_price
                                          ) *
                                            Number(e.target.value)) /
                                          100
                                        ).toFixed(2);
                                        let buyer_pph23_value = (
                                          (Number(
                                            row[idx]
                                              .buyer_base_price
                                          ) *
                                            Number(
                                              row[idx]
                                                .buyer_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS" ||
                                          config.company === "PRPL"
                                        ) {
                                          buyer_pph23_value = "";
                                        }
                                        let buyer_total = (
                                          Number(
                                            row[idx]
                                              .buyer_base_price
                                          ) +
                                          Number(buyer_vat_value) -
                                          Number(buyer_pph23_value)
                                        ).toFixed(2);
                                        let buyer_ctc = (
                                          Number(
                                            row[idx]
                                              .buyer_base_price
                                          ) + Number(buyer_vat_value)
                                        ).toFixed(2);
                                        row[idx].buyer_vat_percent =
                                          e.target.value;
                                        row[idx].buyer_vat_value =
                                          buyer_vat_value;
                                        row[idx].buyer_total =
                                          buyer_total;
                                        row[idx].buyer_ctc =
                                          buyer_ctc;
                                        this.setState(
                                          {
                                            buyerData: row,
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT Value
                                </label>
                                <TextField
                                  name="buyer_vat_value"
                                  margin="dense"
                                  type="number"
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  // variant='outlined'
                                  value={e.buyer_vat_value}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.buyerData;
                                      let buyer_total = (
                                        Number(row[idx].buyer_base_price) +
                                        Number(e.target.value) -
                                        Number(row[idx].buyer_pph23_value)
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) {
                                        buyer_total = (
                                          Number(row[idx].buyer_base_price) +
                                          Number(e.target.value)
                                        ).toFixed(2);
                                      }
                                      let buyer_ctc = Number(
                                        Number(row[idx].buyer_base_price) +
                                        Number(e.target.value)
                                      ).toFixed(2);

                                      row[idx].buyer_vat_value = e.target.value;
                                      row[idx].buyer_total = buyer_total;
                                      row[idx].buyer_ctc = buyer_ctc;
                                      this.setState({
                                        buyerData: row,
                                      }
                                      );
                                    }
                                  }}
                                  fullWidth
                                />
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  {e.demurrage_or_despatch === "Demurrage"
                                    ? "Receivable"
                                    : "Payable"}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.buyer_total
                                    ? this.toLocaleString(e.buyer_total)
                                    : "-"}
                                </div>
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.buyer_ctc
                                    ? this.toLocaleString(e.buyer_ctc)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                          <div
                            className="col-lg-12 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Actual Demurrage</h6>
                            </div>
                            <div className="row">
                              <div className="col-lg-12 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  {e.demurrage_or_despatch === "Despatch"
                                    ? "Final Despatch"
                                    : e.demurrage_or_despatch === "Demurrage"
                                      ? "Final Demurrage"
                                      : null}
                                </label>
                                <TextField
                                  name="buyer_base_price"
                                  margin="dense"
                                  type="number"
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  // variant='outlined'
                                  value={e.buyer_base_price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.buyerData;
                                      let buyer_vat_percent = row[idx].buyer_vat_percent ? row[idx].buyer_vat_percent : 10;
                                      let buyer_pph23_percent = row[idx].buyer_pph23_percent ? row[idx].buyer_pph23_percent : 2;
                                      let buyer_vat_value = (
                                        (Number(e.target.value) * buyer_vat_percent) /
                                        100
                                      ).toFixed(2);
                                      let buyer_pph23_value = Number(
                                        (e.target.value * buyer_pph23_percent) / 100
                                      ).toFixed(2);
                                      let buyer_total = (
                                        Number(e.target.value) +
                                        Number(buyer_vat_value) -
                                        Number(buyer_pph23_value)
                                      ).toFixed(2);
                                      if (
                                        config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                      ) {
                                        buyer_total = (
                                          Number(e.target.value) +
                                          Number(buyer_vat_value)
                                        ).toFixed(2);
                                      }
                                      let buyer_ctc = Number(
                                        Number(e.target.value) +
                                        Number(buyer_vat_value)
                                      ).toFixed(2);

                                      row[idx].buyer_base_price = e.target.value;
                                      row[idx].buyer_vat_percent =
                                        buyer_vat_percent;
                                      row[idx].buyer_vat_value = buyer_vat_value;
                                      row[idx].buyer_pph23_percent =
                                        buyer_pph23_percent;
                                      row[idx].buyer_pph23_value =
                                        buyer_pph23_value;
                                      row[idx].buyer_total = buyer_total;
                                      row[idx].buyer_ctc = buyer_ctc;

                                      this.setState({
                                        buyerData: row,
                                      });
                                    }
                                  }}
                                  fullWidth
                                />
                              </div>
                              {e.buyer_pph23_account === "Buyer" && (
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <TextField
                                    name="buyer_vat_value"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    // variant='outlined'
                                    value={e.buyer_vat_value}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.buyerData;
                                        let buyer_total = (
                                          Number(row[idx].buyer_base_price) +
                                          Number(e.target.value) -
                                          Number(row[idx].buyer_pph23_value)
                                        ).toFixed(2);
                                        let buyer_ctc = Number(
                                          Number(row[idx].buyer_base_price) +
                                          Number(e.target.value)
                                        ).toFixed(2);

                                        row[idx].buyer_vat_value = e.target.value;
                                        row[idx].buyer_total = buyer_total;
                                        row[idx].buyer_ctc = buyer_ctc;

                                        this.setState({
                                          buyerData: row,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                              )}
                              {e.buyer_pph23_account === "Buyer" && (
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT Value
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.buyer_vat_value
                                      ? this.toLocaleString(e.buyer_vat_value)
                                      : "-"}
                                  </div>
                                </div>
                              )}
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23 %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.toLocaleString(e.buyer_pph23_percent)}
                                </div>
                              </div>
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23 Value
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.buyer_pph23_value
                                    ? this.toLocaleString(e.buyer_pph23_value)
                                    : "-"}
                                </div>
                              </div>
                              {e.buyer_pph23_account === "SDAM" && (
                                <div className="col-lg-6 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label m-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {e.demurrage_or_despatch === "Demurrage"
                                      ? "Receivable"
                                      : "Payable"}
                                  </label>
                                  <TextField
                                    name="buyer_total"
                                    margin="dense"
                                    type="number"
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    // variant='outlined'
                                    value={e.buyer_total}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.buyerData;
                                        let buyer_base_price = Number(
                                          (e.target.value * 100) / 98
                                        ).toFixed(2);
                                        let buyer_pph23_percent = 2;
                                        let buyer_pph23_value = Number(
                                          ((buyer_base_price * 2) / 100).toFixed(
                                            2
                                          )
                                        );
                                        let buyer_ctc = (
                                          Number(buyer_base_price) +
                                          Number(buyer_pph23_value)
                                        ).toFixed(2);
                                        row[idx].buyer_base_price =
                                          buyer_base_price;
                                        row[idx].buyer_pph23_percent =
                                          buyer_pph23_percent;
                                        row[idx].buyer_pph23_value =
                                          buyer_pph23_value;
                                        row[idx].buyer_total = e.target.value;
                                        row[idx].buyer_ctc = buyer_ctc;

                                        this.setState({
                                          buyerData: row,
                                        });
                                      }
                                    }}
                                    fullWidth
                                  />
                                </div>
                              )}
                              <div className="col-lg-6 p-0 mb-2">
                                <label
                                  className="contract_display_header_label m-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.buyer_ctc
                                    ? this.toLocaleString(e.buyer_ctc)
                                    : 0}{" "}
                                  {config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
              {(this.state.features.indexOf("526") >= 0 ||
                this.state.features.indexOf("97") >= 0) && (
                  <>
                    <div className="card p-0">
                      <FileUpload
                        files={this.state.files}
                        type="Costings"
                        feature_name="Mv_Buyer_Demurrage"
                        callbackFn={(data, mandatoryFiles) => {
                          this.setState({
                            files: data,
                            costing_files: mandatoryFiles,
                          });
                        }}
                      />
                    </div>
                  </>
                )}
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
