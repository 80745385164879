import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import { Snackbar, TextField, Checkbox, Drawer } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

import InsuranceAdd from "./Insurance-Add";
import InsuranceView from "./Insurance-View";
import InsuranceUpdate from "./Insurance-Update";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class InsuranceList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      insurance_list: [],
      snackBarErrorOpen: false,
      snackBarSuccessOpen: false,
      isLoading: true,
      barge_nomination: "",
      jetty_name: "",
      business_no_drop_value: [],
      business_no_id: [],
      features: [],
      ChemicalSprayAdd: false,
      ChemicalSprayView: false,
      ChemicalSprayUpdate: false,
      chemicalSprayID: null,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("347") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      api.getBusinessNumbers(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.business_numbers) {
                let business_no_drop_value = [];
                for (var i of res.business_numbers) {
                  business_no_drop_value.push({
                    name: i.business_no,
                    value: i.id,
                    mother_vessel_name: i.mother_vessel_name,
                  });
                }
                this.setState({
                  business_no_drop_value: business_no_drop_value,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
  }

  searchBargeHandler() {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let business_no = this.state.business_no_id;
    api
      .get_insurance_and_declarations(loginUserID, idToken, business_no)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.insurance_and_declaration_data) {
                const filter_barge_insurances =
                  res.insurance_and_declaration_data.filter(
                    (e) => e.insurance_level === "Barge"
                  );
                this.setState({
                  insurance_list: filter_barge_insurances,
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var chemical_spray_id = data.id;
    api
      .delete_barge_chemical_spray(loginUserID, idToken, chemical_spray_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                sucessMsg: res.message,
                errorMsg: "",
                isLoading: false,
                closeDialog: false,
              });
              this.searchBargeHandler(
                this.state.barge_nomination,
                this.state.jetty_name
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                closeDialog: false,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // sucessMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
            }
          });
        }
      });
  };

  onAddResponse = (value) => {
    this.setState({ insuranceAdd: false, showDrawer: false }, () => {
      this.searchBargeHandler();
    });
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      insuranceAdd: false,
      insuranceUpdate: true,
      insuranceView: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          showDrawer: true,
          insuranceAdd: false,
          insuranceView: true,
          insuranceUpdate: false,
        },
        () => this.searchBargeHandler()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: false,
      insuranceView: false,
      insuranceUpdate: false,
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,
      insuranceAdd: false,
      insuranceUpdate: false,
      insuranceView: false,
    });
  };

  deletePaymentsHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const insurance_id = this.state.insurance_id;
    api
      .delete_insurance_and_declaration(loginUserID, idToken, insurance_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  deleteCosting: false,
                  snackBarSuccessOpen: true,
                  sucessMsg: "Deleted Successfully",
                },
                () => {
                  this.searchBargeHandler();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "650") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
          });
        }
      });
  };

  render() {
    let drawerComponent;
    if (this.state.insuranceAdd) {
      drawerComponent = (
        <InsuranceAdd
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.insuranceView && this.state.insurance_id) {
      drawerComponent = (
        <InsuranceView
          insurance_id={this.state.insurance_id}
          barge_details={this.state.barge_details}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
        />
      );
    } else if (this.state.insuranceUpdate && this.state.insurance_id) {
      drawerComponent = (
        <InsuranceUpdate
          insurance_id={this.state.insurance_id}
          barge_details={this.state.barge_details}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="row mb-1 p-1">
            <div className="col-lg-3 p-0">
              <Autocomplete
                multiple
                options={this.state.business_no_drop_value}
                getOptionLabel={(option) => option.mother_vessel_name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">
                        {option.mother_vessel_name}
                      </label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {"( " + option.name + " )"}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({
                      business_no_id: data,
                      selected_business_no: value,
                    });
                  } else {
                    this.setState({ business_no_id: [] });
                  }
                }}
                disableCloseOnSelect
                name="Business_Number"
                size="small"
                value={this.state.selected_business_no}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business No"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-2 pr-0 pt-2">
              <button
                onClick={() => this.searchBargeHandler()}
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "15px" }}
              >
                Load
              </button>
            </div>
            <div className="col text-right float-sm-left">
              <button
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "25px" }}
                onClick={() =>
                  this.setState({
                    showDrawer: true,
                    insuranceAdd: true,
                    insuranceUpdate: false,
                    insuranceView: false,
                  })
                }
              >
                New Insurance
              </button>
            </div>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>

          {this.state.insurance_list.length > 0 && (
            <div className="row">
              <div className="table-responsive">
                <table className="table table-bordered table-sm mb-0">
                  <thead className="table-header">
                    <tr>
                      <th className=" text-uppercase" nowrap="true"></th>
                      <th nowrap="true" className=" text-uppercase">
                        Barge ID
                      </th>
                      <th nowrap="true" className=" text-uppercase">
                        Barge Nomination
                      </th>
                      <th nowrap="true" className=" text-uppercase">
                        Business No
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase">
                        Total Payable
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase">
                        Paid
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase">
                        Remaining
                      </th>
                    </tr>
                  </thead>
                  {this.state.insurance_list.map((p, index) => (
                    <tbody key={index}>
                      <tr className="rounded">
                        <td className="text-center" nowrap="true">
                          {(this.state.features.includes("355") ||
                            this.state.features.includes("97")) && (
                            <i
                              className="fa fa-eye mr-2"
                              aria-hidden="true"
                              style={{
                                fontSize: 22,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  insuranceView: true,
                                  insurance_id: p.id,
                                  barge_details: p,
                                  showDrawer: true,
                                })
                              }
                            ></i>
                          )}
                          {(this.state.features.includes("357") ||
                            this.state.features.includes("97")) && (
                            <i
                              className="fa fa-trash-o"
                              aria-hidden="true"
                              style={{
                                fontSize: 21,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  deleteCosting: true,
                                  insurance_id: p.id,
                                })
                              }
                            ></i>
                          )}
                        </td>
                        <td className="table_td text-left">
                          {pad(p.barge_id)}
                        </td>
                        <td className="table_td text-left" nowrap="true">
                          {p.barge_nomination}
                        </td>
                        <td className="table_td text-left" nowrap="true">
                          {p.business_no}
                        </td>
                        <td className="table_td text-right" nowrap="true">
                          {this.toLocaleString(p.total_payable)}
                        </td>
                        <td className="table_td text-right" nowrap="true">
                          {this.toLocaleString(p.paid)}
                        </td>
                        <td className="table_td text-right" nowrap="true">
                          {this.toLocaleString(p.Remaining_amount)}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          )}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() => this.deletePaymentsHandler()}
            />
          )}
        </div>
      );
    }
  }
}
