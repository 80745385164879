import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';

import { Alert } from '@material-ui/lab';
import {
  Snackbar, TableContainer, Table, TableCell,
  TableHead, TableRow, TableBody, Paper, TextField
} from '@material-ui/core';
import Loader from '../common/Loader';

export default class PaymentLimitsForApproval extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      payments_limit_data: [
        {
          costing_type: "Barge Performa Coal Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Commercial Coal Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barging Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Chemical Spay Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Export Tax Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Documentation Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Sampling & Analysis Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Pre Shipment Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Coal Procurement Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Coal Procurement Service Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Vendor Demurrage Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Barge Supplier Demurrage Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Sales Performa Coal Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Sales Commercial Coal Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Coal Sales Paymnets",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Sales Buyer Demurrage Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Floating Crane Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Stevedore Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Freight Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Coal Sales Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Coal Sales Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Sampling & Analysis Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Pre Shipment Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Export Tax Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Documentation Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Supplier Demurrage Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
        {
          costing_type: "Mother Vessel Buyer Demurrage Payments",
          upper_limit_idr: '',
          upper_limit_usd: '',
        },
      ],
      isLoading: true,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    api.get_payment_limits(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              isLoading: false,
              payments_limit_data: res.payments_limit_data.length > 0 ? res.payments_limit_data : this.state.payments_limit_data
            })
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false
            })

          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
    document.title = config.documentTitle + "Payments Limit";


  }

  paymentsLimitsHandler = () => {
    this.setState({ submitting: true });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const payments_limit_data = this.state.payments_limit_data;
    api.add_payment_limits(loginUserID, idToken, payments_limit_data).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              submitting: false,
              snackBarSuccessOpen: true,
              successMsg: res.message
            }, () => this.componentDidMount())
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          {this.state.isLoading &&
           <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='content-header'>
            <div className='col-sm pl-0'>
              <h4 style={{ padding: '10px 20px', margin: '0px' }}> <a
                href={'/reports'}
                className="fa fa-arrow-left left_arrow_adjst"
              >
              </a>
                Payment Limits for 2nd Level Approval
                </h4>
            </div>
          </div>
          <div className='container p-2'>
            {!this.state.isLoading &&
              <div className='card p-0'>
                <div className='row'>
                  <div className='col-lg-12 p-3'>

                    <Paper>

                      <TableContainer style={{ maxHeight: 650 }}>
                        <Table stickyHeader size="small" aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell nowrap='true' className='text-uppercase'> Payment Type </TableCell>
                              <TableCell nowrap='true' className='text-uppercase'> Limit in USD </TableCell>
                              <TableCell nowrap='true' className='text-uppercase'> Limit in IDR </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody  >
                            {this.state.payments_limit_data.map((e, idx) => (

                              <TableRow key={idx}>
                                <TableCell nowrap='true' >{e.costing_type}</TableCell>
                                <TableCell nowrap='true' >
                                  <TextField
                                    name='upper_limit_usd'
                                    margin='dense'
                                    variant='outlined'
                                    placeholder='UPPER LIMIT IN USD'
                                    required
                                    value={e.upper_limit_usd}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    fullWidth
                                    onChange={(e) => {
                                      const re = /^[0-9]*\.?[0-9]*$/;
                                      if (e.target.value === '' || re.test(e.target.value)) {
                                        let row = this.state.payments_limit_data;
                                        row[idx].upper_limit_usd = e.target.value;
                                        this.setState({
                                          payments_limit_data: row,
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>
                                <TableCell nowrap='true' >
                                  <TextField
                                    name='upper_limit_idr'
                                    margin='dense'
                                    variant='outlined'
                                    placeholder='UPPER LIMIT IN IDR'
                                    required
                                    value={e.upper_limit_idr}
                                    inputProps={{ style: { textTransform: 'uppercase' } }}
                                    fullWidth
                                    onChange={(e) => {
                                      const re = /^[0-9]*\.?[0-9]*$/;
                                      if (e.target.value === '' || re.test(e.target.value)) {
                                        let row = this.state.payments_limit_data;
                                        row[idx].upper_limit_idr = e.target.value;
                                        this.setState({
                                          payments_limit_data: row,
                                        });
                                      }
                                    }}
                                  />
                                </TableCell>

                              </TableRow>

                            ))}
                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell colSpan={2} className='text-center'>
                                <button
                                  type="button"
                                  className="header_button header_button_text mt-1"
                                  onClick={this.paymentsLimitsHandler}
                                  disabled={this.state.submitting}
                                >
                                  Submit
                                  <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>
                                </button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </div>

            }
          </div>
        </div>

      </div>
    )
  }
}