import React, { Component } from 'react'
import Header from '../common/Header'
import Loader from '../common/Loader'
import SideBar from '../common/SideBar'
import config from '../../config/config'
import api from '../../api/api'
import CookieHandler from '../common/CookieHandler'
import { Alert } from "@material-ui/lab";
import {
  Snackbar,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  // TablePagination,
  TableRow,
} from "@material-ui/core";
import { localDateFormate } from '../common/common';

export default class ContractApprovals extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      contract_type: 'All',
      isLoading: true,
      contracts_data: [],
      contract_approvals_data: [],
      features: [],
      mainTabIndex: 0,
      GlobalSearchValue: ''
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    const request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie()
    }

    api.get_contracts_for_approval(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.contracts_data) {
              this.setState({
                contracts_data: res.contracts_data?.sort((a, b) => a.contract_no < b.contract_no ? -1 : (a.contract_no > b.contract_no ? 1 : 0)),
                // contract_approvals_data: res.contracts_data?.sort((a, b) => a.contract_no < b.contract_no ? -1 : (a.contract_no > b.contract_no ? 1 : 0)),
                isLoading: false
              },
                () => this.tabFilterHandler())
            }
          }
        })
      }
    })
  }

  tabFilterHandler = () => {
    if (this.state.mainTabIndex === 0) {
      this.setState({
        contract_approvals_data: this.state.contracts_data?.filter(e => e.status === "Pending Approval"),
        isLoading: false,
      },
        () => this.GlobalSearch())
    }
    else if (this.state.mainTabIndex === 1) {
      this.setState({
        contract_approvals_data: this.state.contracts_data?.filter(e => e.status === "Active"),
        isLoading: false
      }, () => this.GlobalSearch())
    }
  }

  convertExcelHandler = () => {

  }


  ContractApproveHandler = (item) => {

    const { id = null, qualities = [] } = item || {};

    if (item.type_of_contract === "Purchase Contract") {
      const request = {
        login_user_id: this.Cookie.getCookie("loginUserId"),
        idtoken: this.Cookie.getIdTokenCookie(),
        purchase_contract_id: id,
        status: "Approved",
      };

      let validation_errors = 0;
      for (var obj of qualities) {

        const { quantity_allocated = 0, barge_delivered_quantity = 0, quantity_in_mt = 0 } = obj || {};

        if (parseFloat(quantity_allocated) > parseFloat(quantity_in_mt) || parseFloat(barge_delivered_quantity) > parseFloat(quantity_in_mt)) {
          validation_errors++;
        }
      }

      if (validation_errors > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please remove allocated quantity before approving"
        });
        return;
      }

      api.approve_purchase_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    }
    else if (item.type_of_contract === "Sales Contract") {
      const request = {
        login_user_id: this.Cookie.getCookie("loginUserId"),
        idtoken: this.Cookie.getIdTokenCookie(),
        sales_contract_id: id,
        status: "Approved",
      };
      let validation_errors = 0;
      for (var i of qualities) {

        const { quantity_allocated = 0, sc_quantity_delivered = 0, quantity_in_mt = 0, } = i || {};

        if (parseFloat(quantity_allocated) > parseFloat(quantity_in_mt) || parseFloat(sc_quantity_delivered) > parseFloat(quantity_in_mt)) {
          validation_errors++;
        }
      }

      if (validation_errors > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please remove allocated quantity before approving"
        });
        return;
      }
      api.approve_sales_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    }
    else {
      const request = {
        login_user_id: this.Cookie.getCookie("loginUserId"),
        idtoken: this.Cookie.getIdTokenCookie(),
        cps_contract_id: id,
        status: "Approved",
      };
      api.approve_cps_contract(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: "Contract has been update successfully",
                snackBarSuccessOpen: true,
              });
              this.componentDidMount();
            }
          });
        }
      });
    }
  }

  GlobalSearch = (e) => {
    let input, filter, table, tr, td, i, j
    input = document.getElementById("search");
    filter = input.value.toUpperCase();
    // filter = this.state.GlobalSearchValue ? this.state.GlobalSearchValue : '';
    table = document.getElementById("contract-approvals");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td");
      for (j = 0; j < td.length; j++) {
        let tdata = td[j];
        if (tdata) {
          if (tdata.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
            break;
          } else {
            tr[i].style.display = "none";
          }
        }
      }
    }

    this.setState({
      GlobalSearchValue: input.value
    })

  }

  render() {

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading &&
            <Loader />
          }
          <div className="content-header">
            <div className="col-sm pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                Contract Approvals
                <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                  |
                </span>
                <span style={{ color: "#959cb6", fontSize: "15px" }}>
                  {this.state.contracts_data.length}
                </span>
              </h4>
            </div>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <div className="container p-2">
            {!this.state.isLoading && (
              <div className="card p-0 " style={{ overflowY: "scroll" }}>
                <div className="row border-bottom">
                  <Tabs
                    value={this.state.mainTabIndex}
                    className='mr-auto m-0'
                    onChange={(event, value) => {
                      this.setState({
                        mainTabIndex: value,
                        isLoading: true,
                      },
                        () => this.tabFilterHandler());
                    }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#6c7293",
                      padding: 0,
                    }}
                    TabIndicatorProps={{
                      style: { background: config.themeColor },
                    }}
                  >
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.mainTabIndex === 0
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Pending
                        </h4>
                      }
                      value={0}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />
                    <Tab
                      label={
                        <h4
                          style={{
                            margin: "0px",
                            color:
                              this.state.mainTabIndex === 1
                                ? config.themeColor
                                : "#6c7293",
                            fontSize: "14px",
                            border: "0px",
                          }}
                        >
                          Approved
                        </h4>
                      }
                      value={1}
                      style={{
                        fontFamily: "Poppins",
                        textTransform: "capitalize",
                        color: "#6c7293",
                      }}
                    />


                  </Tabs>
                </div>
                <br />
                <div className='d-flex justify-content-end pr-3 pb-3 '>

                  <div>
                    <input type="text" className="form-control form-control-sm mt-1" id="search" placeholder="Search...." name="search" value={this.state.GlobalSearchValue} onChange={this.GlobalSearch} />
                  </div>
                  <div>
                  </div>
                </div>


                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table
                    id='contract-approvals'
                    stickyHeader aria-label="sticky table"
                    // sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    // size={dense ? 'small' : 'medium'}
                    size={'small'}
                  >
                    <TableHead className='table-header'>
                      <TableRow>
                        <TableCell nowrap="true" className='table-header'>Contract No</TableCell>
                        <TableCell nowrap="true" className='table-header'>Contract Type</TableCell>
                        <TableCell nowrap="true" className='table-header'>Contract Date</TableCell>
                        <TableCell nowrap="true" className='table-header'>Vendor / Customer</TableCell>
                        {this.state.mainTabIndex === 0 &&
                          <TableCell nowrap="true" className='table-header'>Action</TableCell>
                        }
                        {this.state.mainTabIndex === 1 &&

                          <TableCell nowrap="true" className='table-header'>Approved By</TableCell>
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.contract_approvals_data.map((item, idx) => (
                        <TableRow key={item.id}>
                          <TableCell nowrap="true">
                            {((this.state.features.includes(item.approval_access_id) || this.state.features.includes("97"))) ?

                              <a
                                href={
                                  (item.contract_type === "Original" && item.type_of_contract === "Purchase Contract")
                                    ? "/view-purchase-contract/" + btoa(item.id)
                                    : (item.contract_type === "Addendum" && item.type_of_contract === "Purchase Contract")
                                      ? "/purchase-contract-view-addendum/" + btoa(item.id)
                                      : (item.contract_type === "Original" && item.type_of_contract === "Sales Contract")
                                        ? "/view-sales-contract/" + btoa(item.id)
                                        : (item.contract_type === "Addendum" && item.type_of_contract === "Sales Contract")
                                          ? "/sales-contract-view-addendum/" + btoa(item.id)
                                          : "/view-cps-contract/" + btoa(item.id)

                                }
                                rel="noopener noreferrer"
                                target='_blank'
                                style={{ color: config.themeColor }}
                              >
                                {item.contract_no}
                              </a>
                              :

                              <>
                                {item.contract_no}
                              </>

                            }
                          </TableCell>
                          <TableCell nowrap="true">{item.type_of_contract}</TableCell>
                          <TableCell nowrap="true">{localDateFormate(item.contract_date)}</TableCell>
                          <TableCell nowrap="true" className='text-uppercase'>{item.vendor_name}</TableCell>
                          {this.state.mainTabIndex === 0 &&

                            <TableCell nowrap="true">
                              {((this.state.features.includes(item.approval_access_id) || this.state.features.includes("97")) && item.created_by !== this.Cookie.getCookie('loginUserId')) &&
                                <div
                                  className="col-lg-3 text-right float-sm-left"
                                  style={{ padding: "5px 20px" }}
                                >
                                  <button
                                    className="header_button header_button_text add_button_adjustment"
                                    style={{ width: "auto", marginTop: "17px" }}
                                    onClick={() => this.ContractApproveHandler(item)}
                                  >
                                    Approve
                                  </button>
                                </div>
                              }
                            </TableCell>
                          }
                          {this.state.mainTabIndex === 1 &&

                            <TableCell nowrap="true" className='text-uppercase'>{item.contract_approved_by ? item.contract_approved_by : "Admin"}</TableCell>
                          }
                        </TableRow>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={this.state.contracts_data.length}
                  // rowsPerPage={rowsPerPage}
                  page={0}
                // onPageChange={handleChangePage}
                // onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}

              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
