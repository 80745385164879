import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import api from "../../api/api";
import config from "../../config/config";
import CookieHandler from "./CookieHandler";
import Logo from "../../images/SDAM_Logo.png";
// import backgroundImage from '../../images/coal-background1.2.jpg';

import "../../App.css";
import Loader from "./Loader";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      userName: "",
      password: "",
      loginUserID: "",
      idToken: "",
      errorMessage: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    if (this.Cookie.isLoggedIn()) {
      this.props.history.replace("/dashboard");
    }

    document.title = config.documentTitle + "Login";
  }

  handleText = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: "",
    });
  };

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    var { userName, password } = this.state;
    api.userLogin(userName, password).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res) {
            if (res.code === "605") {
              this.setState({
                errorMessage: res.message,
                isLoading: false,
              });
              return;
            }
            this.Cookie.setCookie("loginUserId", res.user_id);
            this.Cookie.setIdTokenCookie(res.idtoken);
            let roles = [];
            for (var i of res.user_roles) {
              roles.push(i.role_id);
            }
            this.Cookie.setCookie("role", roles);
            var userID = res.user_id;
            var loginUserID = res.user_id;
            var idToken = res.idtoken;
            api.getUser(userID, loginUserID, idToken).then((response) => {
              if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                  if (res) {
                    this.Cookie.setCookie(
                      "loginUserFullName",
                      res.user[0].full_name
                    );
                    this.Cookie.setCookie(
                      "loginUserName",
                      res.user[0].user_name
                    );
                    this.Cookie.setCookie("drawerStatus", true);
                    var loginUserName = res.user[0].user_name;
                    // Feature Access list
                    api
                      .getUserFeatureMaping(loginUserID, idToken, userID)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.user_feature_mapping) {
                              let features = [];
                              if (loginUserName === "admin") {
                                for (let i = 1; i < 98; i++) {
                                  features.push(i.toString());
                                }
                              } else {
                                for (var i of res.user_feature_mapping) {
                                  features.push(i.feature_id);
                                }
                              }
                              this.Cookie.setCookie("features", features);
                              window.location.href = "/dashboard";
                            }
                          });
                        }
                      });
                  }
                });
              }
            });
          }
        });
      }
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="right_block_login_container">
            <div className="right_block_login">
              {config.company === "SDAM" ? (
                <span className="right_block_login_logo_container">
                  <img
                    src={Logo}
                    alt="SDAM LOGO"
                    className="right_block_login_logo"
                  />
                </span>
              ) : (
                <h1>
                  {config.company === "SRPL"
                    ? "SAII RESOURCES"
                    : config.company === "PRPL"
                    ? "PRAKRUTHI RESOURCES"
                    : config.company === "BTR"
                    ? "BUMI TAMBANG RESOURCES"
                    : config.company === "AVS"
                    ? "AVS RESOURCES"
                    : "SUMBER REZEKI KALTIM"}
                </h1>
              )}
              <span className="next_heading">Sign In</span>
              <form onSubmit={this.handleLogin}>
                <div className="col">
                  {this.state.errorMessage ? (
                    <div className="row">
                      <div className="col text-center login_error_message">
                        <span style={{ color: "red", fontSize: 14 }}>
                          {this.state.errorMessage}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <br />
                  )}
                  <div className="row mt-4">
                    <TextField
                      name="userName"
                      margin="dense"
                      // variant='outlined'
                      placeholder="Username"
                      fullWidth
                      type="text"
                      autoFocus={true}
                      value={this.state.userName}
                      required
                      onChange={this.handleText}
                      InputProps={{
                        style: {
                          fontFamily: "Poppins",
                        },
                      }}
                    />
                  </div>
                  <div className="row mt-4">
                    <TextField
                      name="password"
                      margin="dense"
                      // variant='outlined'
                      placeholder="Password"
                      fullWidth
                      type="password"
                      value={this.state.password}
                      required
                      onChange={this.handleText}
                      InputProps={{
                        style: {
                          fontFamily: "Poppins",
                        },
                      }}
                    />
                  </div>
                  <span className="forget_password">Forgot Password?</span>
                </div>
                <br />
                <div className="row">
                  <div className="col text-center">
                    <button
                      type="submit"
                      className="signin_button signin_text"
                      name="submit"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div id="wraper" className="left_block_login">
            <span className="left_block_login_heading">
              <span>
                {config.company === "SRPL"
                  ? "SAII RESOURCES"
                  : config.company === "PRPL"
                  ? "PRAKRUTHI RESOURCES"
                  : config.company === "BTR"
                  ? "BUMI TAMBANG RESOURCES"
                  : config.company === "SDAM"
                  ? "PT SDAM"
                  : config.company === "AVS"
                  ? "AVS RESOURCES"
                  : "SUMBER REZEKI KALTIM"}
              </span>
              <br />
              <span className="left_block_login_text">
                Internal Automation Tool
              </span>
            </span>
          </div>
        </div>
      );
    }
  }
}
