import React, { Component } from 'react';
import SideBar from '../../common/SideBar';
import Header from '../../common/Header';
import api from '../../../api/api';
import config from '../../../config/config';
import CookieHandler from '../../common/CookieHandler';
import {
  Drawer,
  Snackbar
} from '@material-ui/core';
import DocumentsUpload from '../../DocumentsUpload';
import { Alert } from '@material-ui/lab';
import Loader from '../../common/Loader';

export default class ViewCustomer extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      pdfData: [],
      customerID: '',
      customerType: '0',
      otherCustomer: "",
      customerName: '',
      customerNo: '',
      customerAddress: '',
      customerCountry: "",

      contactPsn1: '',
      contactPsnPhone1: '',
      contactHandPhone1: "",
      contactPsnEmail1: '',

      contactPsn2: '',
      contactPsnPhone2: '',
      contactHandPhone2: "",
      contactPsnEmail2: '',

      contactPsn3: '',
      contactPsnPhone3: '',
      contactHandPhone3: "",
      contactPsnEmail3: '',

      contactPsn4: '',
      contactPsnPhone4: '',
      contactHandPhone4: "",
      contactPsnEmail4: '',

      contactPsn5: '',
      contactPsnPhone5: '',
      contactHandPhone5: "",
      contactPsnEmail5: '',

      bankName1: '',
      address1: '',
      bankACNo1: '',
      Currency1: '0',
      swiftCode1: '',
      IBAN_NUM1: '',

      bankName2: '',
      address2: '',
      bankACNo2: '',
      Currency2: '0',
      swiftCode2: '',
      IBAN_NUM2: '',

      bankName3: '',
      address3: '',
      bankACNo3: '',
      Currency3: '0',
      swiftCode3: '',
      IBAN_NUM3: '',

      bankName4: '',
      address4: '',
      bankACNo4: '',
      Currency4: '0',
      swiftCode4: '',
      IBAN_NUM4: '',

      bankName5: '',
      address5: '',
      bankACNo5: '',
      Currency5: '0',
      swiftCode5: '',
      IBAN_NUM5: '',

      taxId: "",
      shareDocLink: "",

      successMessage: "",
      errorMsg: '',
      isLoading: true,

      features: [],
      files: [],
      openDocumentsDrawer: false,
      addDocuments: false,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const status = 'Active';
    try {
      var customerID = decodeURIComponent(window.atob(this.props.match.params.customerId));
    }
    catch (e) {
      window.location.href = '/PagenotFound';
    }
    // for get_customer 
    api.getCustomer(customerID, loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.idtoken)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.customer) {
              this.setState({
                pdfData: res.customer,
                customerID: customerID,
                customerNo: res.customer[0].customer_number ? res.customer[0].customer_number : '',
                companyName: res.customer[0].company_name ? res.customer[0].company_name : '',
                customerType: res.customer[0].customer_type ? res.customer[0].customer_type : '',
                otherCustomer: res.customer[0].other_customer_type ? res.customer[0].other_customer_type : '',
                customerName: res.customer[0].customer_name ? res.customer[0].customer_name : '',
                customerAddress: res.customer[0].customer_address ? res.customer[0].customer_address : '',
                customerCountry: res.customer[0].customer_country ? res.customer[0].customer_country : '',

                contactPsn1: res.customer[0].contact_person_name_1 ? res.customer[0].contact_person_name_1 : '',
                contactPsnPhone1: res.customer[0].contact_person_phone_1 ? res.customer[0].contact_person_phone_1 : '',
                contactHandPhone1: res.customer[0].contact_person_handphone_1 ? res.customer[0].contact_person_handphone_1 : '',
                contactPsnEmail1: res.customer[0].contact_person_email_1 ? res.customer[0].contact_person_email_1 : '',

                contactPsn2: res.customer[0].contact_person_name_2 ? res.customer[0].contact_person_name_2 : '',
                contactPsnPhone2: res.customer[0].contact_person_phone_2 ? res.customer[0].contact_person_phone_2 : '',
                contactHandPhone2: res.customer[0].contact_person_handphone_2 ? res.customer[0].contact_person_handphone_2 : '',
                contactPsnEmail2: res.customer[0].contact_person_email_2 ? res.customer[0].contact_person_email_2 : '',

                contactPsn3: res.customer[0].contact_person_name_3 ? res.customer[0].contact_person_name_3 : '',
                contactPsnPhone3: res.customer[0].contact_person_phone_3 ? res.customer[0].contact_person_phone_3 : '',
                contactHandPhone3: res.customer[0].contact_person_handphone_3 ? res.customer[0].contact_person_handphone_3 : '',
                contactPsnEmail3: res.customer[0].contact_person_email_3 ? res.customer[0].contact_person_email_3 : '',

                contactPsn4: res.customer[0].contact_person_name_4 ? res.customer[0].contact_person_name_4 : '',
                contactPsnPhone4: res.customer[0].contact_person_phone_4 ? res.customer[0].contact_person_phone_4 : '',
                contactHandPhone4: res.customer[0].contact_person_handphone_4 ? res.customer[0].contact_person_handphone_4 : '',
                contactPsnEmail4: res.customer[0].contact_person_email_4 ? res.customer[0].contact_person_email_4 : '',

                contactPsn5: res.customer[0].contact_person_name_5 ? res.customer[0].contact_person_name_5 : '',
                contactPsnPhone5: res.customer[0].contact_person_phone_5 ? res.customer[0].contact_person_phone_5 : '',
                contactHandPhone5: res.customer[0].contact_person_handphone_5 ? res.customer[0].contact_person_handphone_5 : '',
                contactPsnEmail5: res.customer[0].contact_person_email_5 ? res.customer[0].contact_person_email_5 : '',
                bankAcHolderName1: res.customer[0].bank_account_holder_name_1 ? res.customer[0].bank_account_holder_name_1 : '',
                bankAcHolderName2: res.customer[0].bank_account_holder_name_2 ? res.customer[0].bank_account_holder_name_2 : '',
                bankAcHolderName3: res.customer[0].bank_account_holder_name_3 ? res.customer[0].bank_account_holder_name_3 : '',
                bankAcHolderName4: res.customer[0].bank_account_holder_name_4 ? res.customer[0].bank_account_holder_name_4 : '',
                bankAcHolderName5: res.customer[0].bank_account_holder_name_5 ? res.customer[0].bank_account_holder_name_5 : '',
                bankName1: res.customer[0].bank_name_1 ? res.customer[0].bank_name_1 : '',
                address1: res.customer[0].bank_address_1 ? res.customer[0].bank_address_1 : '',
                bankACNo1: res.customer[0].bank_account_number_1 ? res.customer[0].bank_account_number_1 : '',
                Currency1: res.customer[0].currency_1 ? res.customer[0].currency_1 : '',
                swiftCode1: res.customer[0].swift_code_1 ? res.customer[0].swift_code_1 : '',
                IBAN_NUM1: res.customer[0].iban_number_1 ? res.customer[0].iban_number_1 : '',

                bankName2: res.customer[0].bank_name_2 ? res.customer[0].bank_name_2 : '',
                address2: res.customer[0].bank_address_2 ? res.customer[0].bank_address_2 : '',
                bankACNo2: res.customer[0].bank_account_number_2 ? res.customer[0].bank_account_number_2 : '',
                Currency2: res.customer[0].currency_2 ? res.customer[0].currency_2 : '',
                swiftCode2: res.customer[0].swift_code_2 ? res.customer[0].swift_code_2 : '',
                IBAN_NUM2: res.customer[0].iban_number_2 ? res.customer[0].iban_number_2 : '',

                bankName3: res.customer[0].bank_name_3 ? res.customer[0].bank_name_3 : '',
                address3: res.customer[0].bank_address_3 ? res.customer[0].bank_address_3 : '',
                bankACNo3: res.customer[0].bank_account_number_3 ? res.customer[0].bank_account_number_3 : '',
                Currency3: res.customer[0].currency_3 ? res.customer[0].currency_3 : '',
                swiftCode3: res.customer[0].swift_code_3 ? res.customer[0].swift_code_3 : '',
                IBAN_NUM3: res.customer[0].iban_number_3 ? res.customer[0].iban_number_3 : '',

                bankName4: res.customer[0].bank_name_4 ? res.customer[0].bank_name_4 : '',
                address4: res.customer[0].bank_address_4 ? res.customer[0].bank_address_4 : '',
                bankACNo4: res.customer[0].bank_account_number_4 ? res.customer[0].bank_account_number_4 : '',
                Currency4: res.customer[0].currency_4 ? res.customer[0].currency_4 : '',
                swiftCode4: res.customer[0].swift_code_4 ? res.customer[0].swift_code_4 : '',
                IBAN_NUM4: res.customer[0].iban_number_4 ? res.customer[0].iban_number_4 : '',

                bankName5: res.customer[0].bank_name_5 ? res.customer[0].bank_name_5 : '',
                address5: res.customer[0].bank_address_5 ? res.customer[0].bank_address_5 : '',
                bankACNo5: res.customer[0].bank_account_number_5 ? res.customer[0].bank_account_number_5 : '',
                Currency5: res.customer[0].currency_5 ? res.customer[0].currency_5 : '',
                swiftCode5: res.customer[0].swift_code_5 ? res.customer[0].swift_code_5 : '',
                IBAN_NUM5: res.customer[0].iban_number_5 ? res.customer[0].iban_number_5 : '',

                taxId: res.customer[0].tax_id ? res.customer[0].tax_id : '',
                shareDocLink: res.customer[0].share_point_doc_link ? res.customer[0].share_point_doc_link : '',
                files: res.customer[0].files ? res.customer[0].files : [],
                isLoading: false
              })
            } else {
              alert('Unexpected error occured. Please contact administrator.');
            }
          } else if (res.code === '601') {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true
            });
          } else if (res.code === '607') {
            window.location.href = '/logout';
          } else if (res.code === '611') {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true
            });
          }
        })
      }
    })

    document.title = config.documentTitle + "View Customer";
  }

  onLinkClick(link) {
    var links = link.split('://');
    if (links.length === 1) {
      return 'http://' + link;
    }
    else {
      return link;
    }
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false, }, () => {
      this.componentDidMount()
    })
  }

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  }

  render() {
    let drawerComponent;
    if (this.state.addDocuments && this.state.customerID) {
      drawerComponent = <DocumentsUpload feature_name={"Customer"}
        feature_seq_id={this.state.customerID}
        onAddResponse={this.onAddResponse}
        documents={this.state.files}
        returnCallbackDrawerClose={this.CallbackDrawerClose} />;
    }
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='clearfix content-header'>
            <div className='row'>
              <div className='col-sm text-left pl-0'>
                <h4 style={{ padding: '10px 20px', margin: '0px' }}><a
                  href={'/business-partner'}
                  className="fa fa-arrow-left left_arrow_adjst"
                >
                </a>Customer - {this.state.customerNo}</h4>
              </div>
              <Drawer anchor="right" open={this.state.openDocumentsDrawer} variant="temporary" elevation={20} style={{ overflow: 'initial' }} >
                <div className='row p-0' style={{ width: 800 }} >
                  {drawerComponent}
                </div>
              </Drawer>
            </div>
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {this.state.isLoading &&
           <Loader />
          }

          {!this.state.isLoading &&
            <div className="container p-3" id='pdf'>
              <div className='col-lg card' style={{ overflowY: 'scroll' }}>
                <div className="section_block_seperator p-0">
                  <h5 className="mb-0 p-2 section_header">
                    Customer Details
                  </h5>
                  <div className="row col-lg-12 mt-2">
                    <div className="col-lg-4 p-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Name</label>
                      </div>
                      <div>
                        <label className='contract_display_header_value'>{this.state.customerName === '' ? '-' : this.state.customerName}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 pr-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Type</label>
                      </div>
                      <div>
                        <label className='contract_display_header_value'>{this.state.customerType === '' ? '-' : this.state.customerType}{this.state.customerType === "Others" ? ' (' + this.state.otherCustomer + ')' : null}</label>
                      </div>
                      {/* {this.state.vendorType === "others" ?
                      <label className='contract_display_header_value'>{' ('+this.state.otherCustomer+')'}</label>
                      :
                      null
                      } */}
                    </div>
                    <div className="col-lg-4 pr-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Country</label>
                      </div>
                      <div>
                        <label className='contract_display_header_value'>{this.state.customerCountry === '' ? '-' : this.state.customerCountry}</label>
                      </div>
                    </div>
                    <div className="col-lg-4 mt-2 p-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Address</label>
                      </div>
                      <div>
                        <label className='contract_display_header_value'>{this.state.customerAddress === '' ? '-' : this.state.customerAddress}</label>
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-12">
                    <div className="col-lg-4 mt-2 p-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Tax ID</label>
                      </div>
                      <div>
                        <label className='contract_display_header_value' >{this.state.taxId === '' ? '-' : this.state.taxId}</label>
                      </div>
                    </div>
                    <div className="col-lg-8 mt-2 pr-0">
                      <div className='row mb-0' style={{ display: 'inline-block' }}>
                        <label className='contract_display_header_label'>Share Point Document Link</label>
                      </div>
                      <div style={{ textTransform: 'uppercase' }}>
                        {this.state.shareDocLink ?
                          <label className='contract_display_header_value'><a rel="noopener noreferrer" href={this.onLinkClick(this.state.shareDocLink)} target="_blank" style={{ color: config.themeColor }}>{this.state.shareDocLink}</a></label> : '-'
                        }
                      </div>

                      {/* <label className='col' style={{ color: '#9798a0' }}><a rel="noopener noreferrer" href={this.onLinkClick(this.state.shareDocLink)} target="_blank" style={{color: config.themeColor}}>{this.state.shareDocLink === '' ? '-' : this.state.shareDocLink}</a></label> */}
                      {/* <label className='col' style={{ color: '#9798a0' }}>
                          {this.state.shareDocLink ? this.onLinkClick(this.state.shareDocLink): '-'}
                        </label> */}
                    </div>
                  </div>
                </div>
                {(this.state.contactPsn1.length > 0 || this.state.contactPsnPhone1.length > 0 || this.state.contactHandPhone1.length > 0 || this.state.contactPsnEmail1.lenght > 0) &&
                  <div className="section_block_seperator p-0">
                    <h5 className="mb-0 p-2 section_header">
                      Contact
                    </h5>
                    <div className="row">
                      {(this.state.contactPsn1 || this.state.contactPsnPhone1 || this.state.contactHandPhone1 || this.state.contactPsnEmail1) &&
                        <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                          <span className="contact_short_icon">
                            {this.state.contactPsn1.charAt(0)}
                          </span>
                          <span className="contact_details_name_view">
                            {this.state.contactPsn1}<br />
                            <span className="contact_details_view">
                              {this.state.contactPsnEmail1}<br />
                              {/* {row.phone}<br /> */}
                              {this.state.contactHandPhone1}
                            </span>
                          </span>
                        </div>
                      }
                      {(this.state.contactPsn2 || this.state.contactPsnPhone2 || this.state.contactHandPhone3 || this.state.contactPsnEmail4) &&
                        <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                          <span className="contact_short_icon">
                            {this.state.contactPsn2.charAt(0)}
                          </span>
                          <span className="contact_details_name_view">
                            {this.state.contactPsn2}<br />
                            <span className="contact_details_view">
                              {this.state.contactPsnEmail2}<br />
                              {/* {row.phone}<br /> */}
                              {this.state.contactHandPhone2}
                            </span>
                          </span>
                        </div>
                      }
                      {(this.state.contactPsn3 || this.state.contactPsnPhone3 || this.state.contactHandPhone3 || this.state.contactPsnEmail3) &&
                        <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                          <span className="contact_short_icon">
                            {this.state.contactPsn3.charAt(0)}
                          </span>
                          <span className="contact_details_name_view">
                            {this.state.contactPsn3}<br />
                            <span className="contact_details_view">
                              {this.state.contactPsnEmail3}<br />
                              {/* {row.phone}<br /> */}
                              {this.state.contactHandPhone3}
                            </span>
                          </span>
                        </div>
                      }
                      {(this.state.contactPsn4 || this.state.contactPsnPhone4 || this.state.contactHandPhone4 || this.state.contactPsnEmail4) &&
                        <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                          <span className="contact_short_icon">
                            {this.state.contactPsn4.charAt(0)}
                          </span>
                          <span className="contact_details_name_view">
                            {this.state.contactPsn4}<br />
                            <span className="contact_details_view">
                              {this.state.contactPsnEmail4}<br />
                              {/* {row.phone}<br /> */}
                              {this.state.contactHandPhone4}
                            </span>
                          </span>
                        </div>
                      }
                      {(this.state.contactPsn5 || this.state.contactPsnPhone5 || this.state.contactHandPhone5 || this.state.contactPsnEmail5) &&
                        <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                          <span className="contact_short_icon">
                            {this.state.contactPsn5.charAt(0)}
                          </span>
                          <span className="contact_details_name_view">
                            {this.state.contactPsn5}<br />
                            <span className="contact_details_view">
                              {this.state.contactPsnEmail5}<br />
                              {/* {row.phone}<br /> */}
                              {this.state.contactHandPhone5}
                            </span>
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                }
                <div className="section_block_seperator p-0">
                  <h5 className="mb-0 p-2 section_header">
                    Vendor KYC
                  </h5>
                  <div className="row">
                    {(this.state.bankName1 || this.state.address1 || this.state.bankACNo1 || this.state.Currency1 || this.state.swiftCode1 || this.state.IBAN_NUM1) &&
                      <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                        <span className="contact_details_name_view">
                          {this.state.bankAcHolderName1}<br />
                          <span className="contact_details_view" style={{ fontSize: 12, display: 'block', marginBottom: 10 }}>{'A/c No: ' + this.state.bankACNo1}</span>
                          <span className="contact_details_view">
                            {this.state.bankName1}<br />
                            {this.state.IBAN_NUM1}<br />
                            {this.state.address1}<br />
                            <div className="row">
                              <span style={{ float: 'left' }}>
                                Swift Code: {this.state.swiftCode1}
                              </span>
                              <span style={{ float: 'right', marginLeft: "60px" }}>
                                Currency: {this.state.Currency1}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    }
                    {(this.state.bankName2 || this.state.address2 || this.state.bankACNo2 || this.state.Currency2 || this.state.swiftCode2 || this.state.IBAN_NUM2) &&
                      <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                        <span className="contact_details_name_view">
                          {this.state.bankAcHolderName2}<br />
                          <span className="contact_details_view" style={{ fontSize: 12, display: 'block', marginBottom: 10 }}>{'A/c No: ' + this.state.bankACNo2}</span>
                          <span className="contact_details_view">
                            {this.state.bankName2}<br />
                            {this.state.IBAN_NUM2}<br />
                            {this.state.address2}<br />
                            <div className="row">
                              <span style={{ float: 'left' }}>
                                Swift Code: {this.state.swiftCode2}
                              </span>
                              <span style={{ float: 'right', marginLeft: "60px" }}>
                                Currency: {this.state.Currency2}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    }
                    {(this.state.bankName3 || this.state.address3 || this.state.bankACNo3 || this.state.Currency3 || this.state.swiftCode3 || this.state.IBAN_NUM3) &&
                      <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                        <span className="contact_details_name_view">
                          {this.state.bankAcHolderName3}<br />
                          <span className="contact_details_view" style={{ fontSize: 12, display: 'block', marginBottom: 10 }}>{'A/c No: ' + this.state.bankACNo3}</span>
                          <span className="contact_details_view">
                            {this.state.bankName3}<br />
                            {this.state.IBAN_NUM3}<br />
                            {this.state.address3}<br />
                            <div className="row">
                              <span style={{ float: 'left' }}>
                                Swift Code: {this.state.swiftCode3}
                              </span>
                              <span style={{ float: 'right', marginLeft: "60px" }}>
                                Currency: {this.state.Currency3}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    }
                    {(this.state.bankName4 || this.state.address4 || this.state.bankACNo4 || this.state.Currency4 || this.state.swiftCode4 || this.state.IBAN_NUM4) &&
                      <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                        <span className="contact_details_name_view">
                          {this.state.bankAcHolderName4}<br />
                          <span className="contact_details_view" style={{ fontSize: 12, display: 'block', marginBottom: 10 }}>{'A/c No: ' + this.state.bankACNo4}</span>
                          <span className="contact_details_view">
                            {this.state.bankName4}<br />
                            {this.state.IBAN_NUM4}<br />
                            {this.state.address4}<br />
                            <div className="row">
                              <span style={{ float: 'left' }}>
                                Swift Code: {this.state.swiftCode4}
                              </span>
                              <span style={{ float: 'right', marginLeft: "60px" }}>
                                Currency: {this.state.Currency4}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    }
                    {(this.state.bankName5 || this.state.address5 || this.state.bankACNo5 || this.state.Currency5 || this.state.swiftCode5 || this.state.IBAN_NUM5) &&
                      <div className="col-lg-5 m-3 section_block_seperator" style={{ flexDirection: 'row', display: 'inline-flex' }}>
                        <span className="contact_details_name_view">
                          {this.state.bankAcHolderName5}<br />
                          <span className="contact_details_view" style={{ fontSize: 12, display: 'block', marginBottom: 10 }}>{'A/c No: ' + this.state.bankACNo5}</span>
                          <span className="contact_details_view">
                            {this.state.bankName5}<br />
                            {this.state.IBAN_NUM5}<br />
                            {this.state.address5}<br />
                            <div className="row">
                              <span style={{ float: 'left' }}>
                                Swift Code: {this.state.swiftCode5}
                              </span>
                              <span style={{ float: 'right', marginLeft: "60px" }}>
                                Currency: {this.state.Currency5}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                    }
                  </div>
                  {(this.state.features.includes('325') || this.state.features.includes('97')) &&

                    <div className='row'>
                      <span className="col-lg-12 contract_display_header_value mb-2" style={{ fontWeight: 'bold' }}>Uploaded Documents</span>
                      {this.state.files.length > 0 ?
                        <div className='row'>
                          {this.state.files.map((e, index) => (
                            <div className={e.file_path ? 'col-lg-12 mb-3' : 'col-lg-12'} key={index}>
                              {e.file_path &&
                                <div className='col-lg-12 pl-2'>
                                  <a href={config.apiDomain + "/" + e.file_path} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{e.file_description}</a>
                                </div>
                              }
                            </div>
                          ))}
                        </div>
                        :
                        <div className='row col-lg-12'>
                          <p>No files to show</p>
                        </div>
                      }
                      <p className='row col-lg-12'>Add Documents &nbsp;<i className="fa fa-plus-circle" style={{ color: config.themeColor, cursor: 'pointer' }} onClick={() => {
                        this.setState({
                          openDocumentsDrawer: true,
                          addDocuments: true,
                        })
                      }} /></p>
                    </div>
                  }
                </div>
              </div>
              <div className='row bottom_buttons_section'>
                <button type='button' className='btn previous_button' name='submit' onClick={() => window.location.href = '/business-partner'}>Back</button>
                <button type='button' className='btn next_button' name='submit' onClick={() => window.location.href = '/update-customer/' + btoa(this.state.customerID)}>Edit</button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
