import React, { Component } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
import CookieHandler from "../common/CookieHandler";
import api from '../../api/api';
import exportToExcel from "../Export_To_Excel";
import config from '../../config/config';

import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import { toLocaleString } from '../common/common';

export default class BudgetAndCostingList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      loading: true,
      budget_costing: [],
      features: [],
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });

    const request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie()
    }
    api.get_budget_costings(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.budget_costing) {
              this.setState({
                budget_costing: res.budget_costing,
                isLoading: false,
                loading: false,
              })
            }
          }
        })
      }
    })
  }

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Costing No": obj.budget_costing_no,
        "Laycan": obj.laycan,
        "Vessel Name": obj.vessel_name,
        'Currency': obj.budget_currency,
        "Exchange Rate": toLocaleString(obj.exchange_rate),
        "Realization PMT": toLocaleString(obj.realization_pmt),
        "Total Cost Of Purchase PMT": toLocaleString(obj.total_cost_of_purchase_pmt),
        "Total Cost Of Purchase": toLocaleString(obj.total_cost_of_purchase),
        "Total Cost Of Sales PMT": toLocaleString(obj.total_cost_of_sales_pmt),
        "Total Cost Of Sales": toLocaleString(obj.total_cost_of_sales),
        "Status": obj.status,
      };
    });
    exportToExcel(Modified, filename);
  };

  render() {

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Budget And Costing
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.budget_costing.length}
                  </span>
                </h4>
              </div>
              <div className="col text-right float-sm-left">
                {(this.state.features.includes("7640") ||
                  this.state.features.includes("97")) && (
                    <a href="/budget-costing-add">
                      <button
                        className="header_button header_button_text add_button_adjustment"
                        style={{ width: "auto", marginTop: "20px" }}
                      >
                        New Budget & Costing
                      </button>
                    </a>
                  )}
              </div>
            </div>
          </div>
          <div className="container p-3">

            <div className="col-md card p-0">
              <Paper>
                <MaterialTable
                  columns={[
                    {
                      title: "Costing No",
                      field: "budget_costing_no",
                      render: (row) => {
                        if (
                          this.state.features.includes("641") ||
                          this.state.features.includes("97")
                        ) {
                          return (
                            <a
                              style={{
                                textDecoration: "none",
                                color: config.themeColor,
                              }}
                              href={
                                "/view-budget-costing/" + btoa(row.id)
                              }
                            >
                              {row.budget_costing_no}
                            </a>
                          );
                        } else {
                          return <span>{row.budget_costing_no}</span>;
                        }
                      },
                    },
                    {
                      title: "laycan",
                      field: "laycan",
                    },
                    {
                      title: "Customer Name",
                      field: "customer_name",
                    },
                    {
                      title: "Business No",
                      field: "business_number",
                    },
                    {
                      title: "vessel_name",
                      field: "vessel_name",
                    },
                    // {
                    //   title: "Currency",
                    //   field: "budget_currency",
                    // },
                    // {
                    //   title: "Exchange Rate",
                    //   field: "exchange_rate",
                    // },
                    // {
                    //   title: "Realization pmt",
                    //   field: "realization_pmt",
                    //   render: (row) => {
                    //     return toLocaleString(row.realization_pmt)
                    //   },
                    //   headerStyle: {
                    //     minWidth: 100,
                    //   },
                    // },
                    // {
                    //   title: "Total Cost of Purchase pmt",
                    //   field: "total_cost_of_purchase_pmt",
                    //   render: (row) => {
                    //     return toLocaleString(row.total_cost_of_purchase_pmt)
                    //   },
                    //   headerStyle: {
                    //     minWidth: 245,
                    //   },
                    // },
                    // {
                    //   title: "Total Cost of Purchase",
                    //   field: "total_cost_of_purchase",
                    //   render: (row) => {
                    //     return toLocaleString(row.total_cost_of_purchase)
                    //   },
                    //   headerStyle: {
                    //     minWidth: 245,
                    //   },
                    // },
                    // {
                    //   title: "Total Cost of sales pmt",
                    //   field: "total_cost_of_sales_pmt",
                    //   render: (row) => {
                    //     return toLocaleString(row.total_cost_of_sales_pmt)
                    //   },
                    //   headerStyle: {
                    //     minWidth: 245,
                    //   },
                    // },
                    // {
                    //   title: "Total Cost of Sales",
                    //   field: "total_cost_of_sales",
                    //   render: (row) => {
                    //     return toLocaleString(row.total_cost_of_sales)
                    //   },
                    //   headerStyle: {
                    //     minWidth: 245,
                    //   },
                    // },
                    {
                      title: "Status",
                      field: "status",
                      render: (row) => {
                        return (
                          <span>
                            {row.status === "Active" && (
                              <span className="active_status">
                                <i
                                  className="fa fa-circle dot_align"
                                  aria-hidden="true"
                                />
                                {row.status}
                              </span>
                            )}

                          </span>
                        );
                      },
                      headerStyle: {
                        minWidth: 120,
                      },
                    },
                    {
                      title: "Action",
                      field: "pcid",
                      sortable: false,
                      filterable: false,
                      headerStyle: {
                        minWidth: 120,
                      },
                      render: (row) => {
                        return (
                          <div
                            style={{
                              textAlign: "left",
                              marginTop: "-10px",
                              marginBottom: "-10px",
                            }}
                          >
                            <a
                              href={
                                "/update-budget-costing/" +
                                btoa(row.id)
                              }
                            >
                              {" "}
                              <i
                                className="fa fa-pencil edit_icon"
                                style={{
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </a>
                          </div>
                        );
                      },
                      hidden: !(
                        this.state.features.includes("642") ||
                        this.state.features.includes("97")
                      ),
                    },
                  ]}
                  title=""
                  data={this.state.budget_costing}
                  isLoading={this.state.loading}
                  style={{
                    minHeight: 200,
                    fontFamily: "Poppins",
                    overflowY: "auto",
                    boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                  }}
                  options={{
                    paginationType: "stepped",
                    pageSize: 20,
                    pageSizeOptions: [5, 10, 20, 50, 100, 200],
                    headerStyle: {
                      backgroundColor: "#f9f9f9",
                      minWidth: 215,
                      fontFamily: "Poppins",
                      textTransform: "uppercase",
                      fontSize: "14px",
                      fontWeight: 500,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.86",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "rgba(72, 59, 52, 0.7)",
                    },
                    cellStyle: {
                      fontFamily: "Poppins",
                    },
                    // exportAllData: true,
                    // exportButton: true,
                    draggable: false,
                    exportFileName: "budget-costings",
                    // tableLayout: 'fixed'
                  }}
                  actions={[
                    {
                      icon: "download",
                      tooltip: "Download",
                      isFreeAction: true,
                      onClick: (event) =>
                        this.convertExcelHandler(
                          this.state.budget_costing,
                          "budget-costing"
                        ),
                    },
                  ]}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
