import React, { useState } from 'react'
import config from '../../../config/config';
import { toLocaleString } from '../../common/common';
import QualitySpecsView from './QualitySpecsView';
import IndexLinkPriceView from './IndexLinkPriceView';

export default function QualityView({ contractType, qualities, addendum_qualities, callbackFn }) {

  const [openDrawer, setOpenDrawer] = useState(false);
  const [specs, setSpecs] = useState({});
  const [addendumSpecs, setAddendumSpecs] = useState(null);
  const [specStandard, setSpecsStandard] = useState("ASTM/ISO");
  const [openCoalIndex, setOpenCoalIndex] = useState(false);
  const [coalIndexData, setCoalindexData] = useState(null);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const CheckQualityDiffHandler = (identifier, label, value) => {
    if (addendum_qualities) {
      const filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === identifier);
      if (filter_selected_quality) {
        const diff = filter_selected_quality[label] !== value;
        if (diff) {
          return filter_selected_quality[label];
        }
        else return false;
      }

    }

    return false;
  }

  const CheckTypesDiffHandler = (quality, identifier, label, value) => {
    if (addendum_qualities) {
      let filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === quality)?.purchase_types?.find(item => item.purchase_type_seq === identifier);
      if (contractType === "Purchase") {
        filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === quality)?.purchase_types?.find(item => item.purchase_type_seq === identifier);
      } else {
        filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === quality)?.sales_types?.find(item => item.sales_type_seq === identifier);
      }

      if (filter_selected_quality) {
        const diff = filter_selected_quality[label] !== value;
        if (diff) {
          return filter_selected_quality[label];
        }
        else {
          return false
        }
      }

    }
  }

  return (
    <>

      <div className='row'>

        <div className='table-responsive'>
          <table className='table table-bordered table-sm'>
            <thead className='table-header'>
              <th className='text-center' nowrap='true'>Quality</th>
              <th className='text-right' nowrap='true'>Quantity</th>
              <th className='text-right' nowrap='true'>Tolerance %</th>
              <th className='text-center' nowrap='true'>Mine Name</th>
              <th className='text-center' nowrap='true'>Inco Term</th>
              <th className='text-center' nowrap='true'>Jetty / Anchorage Name</th>
              <th className='text-center' nowrap='true'>Index Linked</th>
              <th className='text-right' nowrap='true'>Price PMT</th>
              <th className='text-right' nowrap='true'>Freight Price PMT</th>
              <th className='text-center' nowrap='true'>{contractType === "Purchase" ? 'Purchase Term' : 'Sales Term'}</th>
              <th className='text-center' nowrap='true'>Specification Standard</th>
            </thead>
            {contractType === "Purchase" &&
              <tbody>
                {qualities?.map((e, index) => e.purchase_types.map((item, idx) => (
                  <tr key={item.id} style={{ backgroundColor: e.color ? e.color : "#fff" }}>
                    {idx === 0 &&

                      <td className='text-uppercase text-center font-bold' nowrap='true' rowSpan={e.purchase_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'quality', e.quality) ? <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{e.quality}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'quality', e.quality)} </> : e.quality}</td>
                    }
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.purchase_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'quantity_adjusted', e.quantity_adjusted) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(e.quantity_adjusted)}</s>&nbsp;{toLocaleString(CheckQualityDiffHandler(e.quality_seq, 'quantity_adjusted', e.quantity_adjusted))} </> : toLocaleString(e.quantity_adjusted)}</td>}
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.purchase_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'tolerance_adjusted', e.tolerance_adjusted) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(e.tolerance_adjusted)}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'tolerance_adjusted', e.tolerance_adjusted)} </> : toLocaleString(e.tolerance_adjusted)}</td>
                    }
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'mine_name', item.mine_name) ? <><s style={{ textDecorationColor: 'red' }}>{item.mine_name}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'mine_name', item.mine_name)} </> : item.mine_name}</td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'purchase_type', item.purchase_type) ? <><s style={{ textDecorationColor: 'red' }}>{item.purchase_type}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'purchase_type', item.purchase_type)} </> : item.purchase_type}</td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'anchorage_name', item.anchorage_name) ? <><s style={{ textDecorationColor: 'red' }}>{item.anchorage_name}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'anchorage_name', item.anchorage_name)} </> : item.anchorage_name}</td>
                    <td className='text-uppercase text-center' nowrap='true'
                      style={{ color: item.is_index_linked === "Yes" ? config.themeColor : 'black', cursor: 'pointer' }}
                      onClick={() => {
                        if (item.is_index_linked === "Yes") {
                          const coal_index = {
                            ...item,
                            index_ids: item.index_ids?.split(",") || [],
                            // index_ids: []
                          };
                          setCoalindexData(coal_index)
                          setOpenCoalIndex(true);
                          forceUpdate();

                        }
                      }}
                    >
                      {CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'is_index_linked', item.is_index_linked) ? <><s style={{ textDecorationColor: 'red' }}>{item.is_index_linked}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'is_index_linked', item.is_index_linked)} </> : item.is_index_linked ? item.is_index_linked : "No"}
                    </td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'price_pmt', item.price_pmt) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(item.price_pmt)}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'price_pmt', item.price_pmt)} </> : toLocaleString(item.price_pmt)}</td>

                    
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'freight_price_pmt', item.freight_price_pmt) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(item.freight_price_pmt)}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.purchase_type_seq, 'freight_price_pmt', item.freight_price_pmt)} </> : toLocaleString(item.freight_price_pmt)}</td>
                    
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.purchase_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'purchasing_term', e.purchasing_term) ? <><s style={{ textDecorationColor: 'red' }}>{e.purchasing_term}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'purchasing_term', e.purchasing_term)} </> : e.purchasing_term}</td>
                    }
                    {idx === 0 &&

                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.purchase_types.length} style={{ color: config.themeColor, cursor: 'pointer' }}
                        onClick={() => {
                          if (addendum_qualities) {
                            const filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === qualities[index].quality_seq);
                            if (filter_selected_quality) {
                              setAddendumSpecs(filter_selected_quality?.specifications)
                            }

                          }
                          setOpenDrawer(true);
                          setSpecs(e.specifications);
                          setSpecsStandard(addendum_qualities ? addendum_qualities.find(e => e.quality_seq === qualities[index].quality_seq)?.specification_standard : e.specification_standard);
                          forceUpdate();
                        }}
                      >
                        {CheckQualityDiffHandler(e.quality_seq, 'specification_standard', e.specification_standard) ? <><s style={{ textDecorationColor: 'red' }}>{e.specification_standard}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'specification_standard', e.specification_standard)} </> : e.specification_standard}
                      </td>
                    }
                  </tr>
                )))}
              </tbody>
            }

            {contractType === "Sales" &&
              <tbody>
                {qualities?.map((e, index) => e.sales_types.map((item, idx) => (
                  <tr key={item.id} style={{ backgroundColor: e.color ? e.color : "#fff" }}>
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'quality', e.quality) ? <><s style={{ textDecorationColor: 'red' }}>{e.quality}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'quality', e.quality)} </> : e.quality}</td>
                    }
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'quantity_adjusted', e.quantity_adjusted) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(e.quantity_adjusted)}</s>&nbsp;{toLocaleString(CheckQualityDiffHandler(e.quality_seq, 'quantity_adjusted', e.quantity_adjusted))} </> : toLocaleString(e.quantity_adjusted)}</td>
                    }
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'tolerance_adjusted', e.tolerance_adjusted) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(e.tolerance_adjusted)}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'tolerance_adjusted', e.tolerance_adjusted)} </> : toLocaleString(e.tolerance_adjusted)}</td>
                    }
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'mine_name', item.mine_name) ? <><s style={{ textDecorationColor: 'red' }}>{item.mine_name}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'mine_name', item.mine_name)} </> : item.mine_name}</td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'sales_type', item.sales_type) ? <><s style={{ textDecorationColor: 'red' }}>{item.sales_type}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'sales_type', item.sales_type)} </> : item.sales_type}</td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'anchorage_name', item.anchorage_name) ? <><s style={{ textDecorationColor: 'red' }}>{item.anchorage_name}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'anchorage_name', item.anchorage_name)} </> : item.anchorage_name}</td>
                    <td className='text-uppercase text-center' nowrap='true'
                      style={{ color: item.is_index_linked === "Yes" ? config.themeColor : 'black', cursor: 'pointer' }}

                      onClick={() => {
                        if (item.is_index_linked === "Yes") {
                          const coal_index = {
                            ...item,
                            index_ids: item.index_ids?.split(",") || [],
                          };
                          setCoalindexData(coal_index)
                          setOpenCoalIndex(true);
                          forceUpdate();

                        }
                      }}>
                      {CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'is_index_linked', item.is_index_linked) ? <><s style={{ textDecorationColor: 'red' }}>{item.is_index_linked}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'is_index_linked', item.is_index_linked)} </> : item.is_index_linked ? item.is_index_linked : "No"}
                    </td>
                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'price_pmt', item.price_pmt) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(item.price_pmt)}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'price_pmt', item.price_pmt)} </> : toLocaleString(item.price_pmt)}</td>

                    <td className='text-uppercase text-center' nowrap='true'>{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'freight_price_pmt', item.freight_price_pmt) ? <><s style={{ textDecorationColor: 'red' }}>{toLocaleString(item.freight_price_pmt)}</s>&nbsp;{CheckTypesDiffHandler(e.quality_seq, item.sales_type_seq, 'freight_price_pmt', item.freight_price_pmt)} </> : toLocaleString(item.freight_price_pmt)}</td>

                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length}>{CheckQualityDiffHandler(e.quality_seq, 'sales_term', e.sales_term) ? <><s style={{ textDecorationColor: 'red' }}>{e.sales_term}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'sales_term', e.sales_term)} </> : e.sales_term}</td>
                    }
                    {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length} style={{ color: config.themeColor, cursor: 'pointer' }}
                        onClick={() => {
                          if (addendum_qualities) {
                            const filter_selected_quality = addendum_qualities?.find(e => e.quality_seq === qualities[index].quality_seq);
                            if (filter_selected_quality) {
                              setAddendumSpecs(filter_selected_quality?.specifications)
                            }

                          }
                          setOpenDrawer(true);
                          setSpecs(e.specifications);
                          // setSpecsStandard(e.specification_standard);
                          setSpecsStandard(addendum_qualities ? addendum_qualities.find(e => e.quality_seq === qualities[index].quality_seq)?.specification_standard : e.specification_standard);
                          forceUpdate();
                        }}
                      >
                        {CheckQualityDiffHandler(e.quality_seq, 'specification_standard', e.specification_standard) ? <><s style={{ textDecorationColor: 'red' }}>{e.specification_standard}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'specification_standard', e.specification_standard)} </> : e.specification_standard}

                      </td>
                    }
                    {/* {idx === 0 &&
                      <td className='text-uppercase text-center' nowrap='true' rowSpan={e.sales_types.length} style={{ color: config.themeColor }}
                        onClick={() => {
                          setOpenCoalIndex(true);
                          forceUpdate();

                        }}
                      >
                        {CheckQualityDiffHandler(e.quality_seq, 'is_index_linked', e.is_index_linked) ? <><s style={{ textDecorationColor: 'red' }}>{e.is_index_linked}</s>&nbsp;{CheckQualityDiffHandler(e.quality_seq, 'is_index_linked', e.is_index_linked)} </> : e.is_index_linked}
                      </td>
                    } */}
                  </tr>
                )))}
              </tbody>
            }
          </table>
        </div>
      </div>

      <IndexLinkPriceView
        open={openCoalIndex}
        coal_index={coalIndexData}
        callbackFn={() => { }}
        closeDrawerHandler={() => setOpenCoalIndex(false)}
      />

      <QualitySpecsView
        open={openDrawer}
        specifications={specs}
        adendumSpecs={addendumSpecs}
        specification_standard={specStandard}
        closeDrawerHandler={() => setOpenDrawer(false)}
      />
    </>
  )
}
