import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";

export default class Mv_Pre_Shipment_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          sac_quantity_in_mt: "",
          invoice_no: "",
          invoice_date: null,
          ppn: "",
          ppn_date: null,
          sac_sampling_surveyor: "",
          sac_sampling_price_per_mt: "",
          sac_sampling_base_price: "",
          sac_sampling_vat_percent: "10",
          sac_sampling_vat_value: "",
          sac_sampling_pph23_percent: "2",
          sac_sampling_pph23_value: "",
          sac_sampling_total: "",
          sac_sampling_ctc: "",
          billing_currency:
            config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL"
              ? "USD"
              : "0",
          sac_sampling_currency_xchg_rate: "",
          sac_sampling_ctc_in_local_currency: "",
        },
      ],
      isLoading: true,
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pre_Shipment",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("252") ||
          features.includes("415") ||
          features.includes("466") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      var loginUserID = this.Cookie.getCookie("loginUserId");
      var idToken = this.Cookie.getIdTokenCookie();
      // calling Vendors Api
      var status = "Active";
      const vendorsDataResponse = await api.getVendors(
        loginUserID,
        idToken,
        status
      );
      if (
        vendorsDataResponse.status >= 200 &&
        vendorsDataResponse.status < 300
      ) {
        vendorsDataResponse.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const surveyors = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Surveyor") {
                  surveyors.push({
                    sac_sampling_surveyor_name: i.vendor_name,
                    value: i.id,
                    key: i,
                  });
                }
              }
              this.setState({
                vendorTypeAsSurveyors: surveyors,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      // for get_Currencies
      const getCurrencies = await api.getCurrencies(loginUserID, idToken);
      if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
        getCurrencies.json().then((res) => {
          if (res.code === "200") {
            if (res.Currency) {
              var currencies = [
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value="0"
                  key={-1}
                  disabled
                >
                  Please Select
                </MenuItem>,
              ];
              for (var i in res.Currency) {
                currencies.push(
                  <MenuItem
                    style={{ fontFamily: "Poppins", fontSize: "14px" }}
                    value={res.Currency[i].currency}
                    key={[i]}
                  >
                    {res.Currency[i].currency}
                  </MenuItem>
                );
              }
              this.setState({
                currencyData: currencies,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }

      try {
        //var businessNoID = decodeURIComponent(window.atob(this.props.match.params.businessNoID));
        var businessNoID = this.props.businessNoID;
        //Get businessNumber API based on businessNumber
        const getBusinessNumber = await api.getBusinessNumber(
          businessNoID,
          loginUserID,
          idToken
        );
        if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
          getBusinessNumber.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        var preShipmentID = this.props.preShipmentID;
        //Get businessNumber API based on businessNumber
        const get_mv_pur_fin_cpc = await api.get_mv_pur_fin_pre_shipment(
          loginUserID,
          idToken,
          preShipmentID
        );
        if (
          get_mv_pur_fin_cpc.status >= 200 &&
          get_mv_pur_fin_cpc.status < 300
        ) {
          get_mv_pur_fin_cpc.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_pre_shipment) {
                this.setState({
                  costing_no: res.mv_pre_shipment[0]?.costing_no,
                  invoice_file: res.mv_pre_shipment[0]?.invoice_file,
                  multiRows: res.mv_pre_shipment,
                  files:
                    res.mv_pre_shipment.length > 0
                      ? res.mv_pre_shipment[0].files
                      : [],
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }

        // const get_mv_pre_shipment = await api.get_mv_pre_shipment(loginUserID, idToken, businessNoID);
        // if (get_mv_pre_shipment.status >= 200 && get_mv_pre_shipment.status < 300) {
        //   get_mv_pre_shipment.json().then((res) => {
        //     if (res.code === '200') {
        //       if (res.mv_pre_shipment) {
        //         this.setState({
        //           multiRows: res.mv_pre_shipment,
        //           isLoading: false
        //         });
        //       }
        //     }
        //     else if (res.code === '601') {
        //       this.setState({
        //         errorMsg: res.message,
        //         snackBarErrorOpen: true
        //       });

        //     }
        //     else if (res.code === '607') {
        //       window.location.href = '/logout';
        //     }

        //   })
        // }
      } catch (e) {
        // window.location.href = '/business-number-list';
      }
    }
  }

  addMoreRowsHandler = () => {
    const item = {
      sac_quantity_in_mt: this.state.vessel_final_quantity_in_mt,
      invoice_no: "",
      invoice_date: null,
      ppn: "",
      ppn_date: null,
      sac_sampling_surveyor: "",
      sac_sampling_price_per_mt: "",
      sac_sampling_base_price: "",
      sac_sampling_vat_percent: 10,
      sac_sampling_vat_value: "",
      sac_sampling_pph23_percent: 2,
      sac_sampling_pph23_value: "",
      sac_sampling_total: "",
      sac_sampling_ctc: "",
      billing_currency:
        config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL" ? "USD" : "0",
      sac_sampling_currency_xchg_rate: "",
      sac_sampling_ctc_in_local_currency: "",
    };
    this.setState({
      multiRows: [...this.state.multiRows, item],
    });
  };

  removeMultiRowsHandler = (idx) => () => {
    const rows = this.state.multiRows;
    rows.splice(idx, 1);
    this.setState({
      multiRows: rows,
    });
  };

  samplingSurveyorCalculations = (idx) => {
    var row = this.state.multiRows;
    var currecny_xchg_rate = row[idx].sac_sampling_currency_xchg_rate
      ? row[idx].sac_sampling_currency_xchg_rate
      : 1;
    var price_per_mt = row[idx].sac_sampling_price_per_mt
      ? row[idx].sac_sampling_price_per_mt
      : 0;
    let sac_sampling_base_price = (
      (Number(row[idx].sac_quantity_in_mt) * Number(price_per_mt)) /
      Number(currecny_xchg_rate)
    ).toFixed(4);
    let sac_sampling_vat_value = (
      (Number(sac_sampling_base_price) * 10) /
      100
    ).toFixed(2);
    let sac_sampling_pph23_value = (
      (Number(sac_sampling_base_price) * 2) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") {
      sac_sampling_pph23_value = "";
    }
    let sac_sampling_total = (
      Number(sac_sampling_base_price) +
      Number(sac_sampling_vat_value) -
      Number(sac_sampling_pph23_value)
    ).toFixed(2);
    let sac_sampling_ctc = (
      Number(sac_sampling_base_price) + Number(sac_sampling_vat_value)
    ).toFixed(2);

    row[idx].sac_sampling_base_price = sac_sampling_base_price;
    row[idx].sac_sampling_vat_percent = 10;
    row[idx].sac_sampling_vat_value = sac_sampling_vat_value;
    row[idx].sac_sampling_pph23_percent = 2;
    row[idx].sac_sampling_pph23_value = sac_sampling_pph23_value;
    row[idx].sac_sampling_total = sac_sampling_total;
    row[idx].sac_sampling_ctc = sac_sampling_ctc;

    this.setState({
      multiRows: row,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    // var businessNoID = this.props.businessNoID;
    var mv_pre_shipment_data = this.state.multiRows;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    let costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    var finalData = mv_pre_shipment_data[0];
    finalData.mv_pre_shipment_id = this.props.preShipmentID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;
    finalData["costing_files"] = costing_file;
    //
    api.update_mv_pur_fin_pre_shipment(finalData).then((Response) => {
      if (Response.status >= 200 && Response.status < 300) {
        Response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                successMsg: res.message,
                snackBarSuccessOpen: true,
              },
              () => {
                setTimeout(() => {
                  this.props.onUpdateCosting({
                    screen: "MvPreShipment",
                    code: res.code,
                    message: "",
                  });
                }, 2000);
              }
            );
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4
                style={{ padding: "10px 20px" }}
              >{`Update Pre-Shipment Inspection - ${this.state.costing_no}`}</h4>
            </div>
          </div>
          <div className="drawer-section" style={{ minHeight: "85vh" }}>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {!this.props.updateDocuments && (
                  <div>
                    {this.state.multiRows.map((e, idx) => (
                      <div className="row" key={idx}>
                        <div
                          className={
                            this.state.multiRows.length > 1
                              ? "col-lg-11 border_dashed rounded-lg p-2 mb-3"
                              : "col-lg-12 border_dashed rounded-lg p-2 mb-3"
                          }
                        >
                          <div className="row mb-3 ">
                            <div className="col-lg-5 pl-0">
                              <div className="row">
                                <div className="col-lg-12 mb-1 p-0">
                                  <label className="form_label mb-1">
                                    Sampling Surveyor
                                  </label>
                                  <Autocomplete
                                    size="small"
                                    options={this.state.vendorTypeAsSurveyors}
                                    getOptionLabel={(option) =>
                                      option.sac_sampling_surveyor_name
                                    }
                                    onChange={(event, value) => {
                                      if (value) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_sampling_surveyor =
                                          value.value;
                                        row[idx].sac_sampling_surveyor_name =
                                          value.sac_sampling_surveyor_name;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                    value={e}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Search Vendor"
                                        //label='Search Vendor'
                                        variant="outlined"
                                        //error={this.state.vendorTypeError}
                                        fullWidth
                                      />
                                    )}
                                  />
                                </div>

                                <div className="col-lg-6 mb-1 p-0">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="sac_quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Enter Value"
                                    value={e.sac_quantity_in_mt}
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    onChange={(e) => {
                                      const quan = /^\d*(\.\d{0,3})?$/;
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_quantity_in_mt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () =>
                                            this.samplingSurveyorCalculations(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                <div className="col-lg-6 mb-1 pr-0">
                                  <label className="form_label mb-0">
                                    Base Price PMT
                                  </label>
                                  <TextField
                                    name="sac_sampling_price_per_mt"
                                    margin="dense"
                                    variant="outlined"
                                    value={e.sac_sampling_price_per_mt}
                                    placeholder="Enter Value"
                                    fullWidth
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                            ? "USD"
                                            : e.billing_currency !== "0"
                                            ? e.billing_currency
                                            : ""}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_sampling_price_per_mt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            multiRows: row,
                                          },
                                          () =>
                                            this.samplingSurveyorCalculations(
                                              idx
                                            )
                                        );
                                      }
                                    }}
                                  />
                                </div>

                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                  <div className="col-lg-6 mb-1 p-0">
                                    <label className="form_label mb-0">
                                      Currency
                                    </label>
                                    <TextField
                                      name="billing_currency"
                                      margin="dense"
                                      variant="outlined"
                                      fullWidth
                                      placeholder="Currency"
                                      value={e.billing_currency}
                                      select
                                      InputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      onChange={(e) => {
                                        var row = this.state.multiRows;
                                        row[idx].billing_currency =
                                          e.target.value;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }}
                                    >
                                      {this.state.currencyData}
                                    </TextField>
                                  </div>
                                )}

                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  e.billing_currency !== "IDR" &&
                                  e.billing_currency !== "0" && (
                                    <div className="col-lg-6 mb-1 pr-0">
                                      <label className="form_label mb-0">
                                        Exchange Rate
                                      </label>
                                      <TextField
                                        name="sac_sampling_currency_xchg_rate"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Enter Value"
                                        value={
                                          e.sac_sampling_currency_xchg_rate
                                        }
                                        onChange={(e) => {
                                          const re = /^\d*(\.\d{0,10})?$/;

                                          if (
                                            e.target.value === "" ||
                                            re.test(e.target.value)
                                          ) {
                                            var row = this.state.multiRows;
                                            row[
                                              idx
                                            ].sac_sampling_currency_xchg_rate =
                                              e.target.value;

                                            this.setState(
                                              {
                                                multiRows: row,
                                              },
                                              () =>
                                                this.samplingSurveyorCalculations(
                                                  idx
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>

                            <div
                              className="col-lg-7 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Surveyor Charges</h6>
                              </div>
                              <div className="row">
                                <div className="col-4 p-0 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? toLocaleString(e.base_price) : "-"}</div> */}
                                  <TextField
                                    name="sac_sampling_base_price"
                                    margin="dense"
                                    //variant='outlined'
                                    fullWidth
                                    value={e.sac_sampling_base_price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;

                                        let sac_sampling_vat_value = (
                                          (Number(e.target.value) *
                                            Number(
                                              row[idx].sac_sampling_vat_percent
                                            )) /
                                          100
                                        ).toFixed(2);
                                        let sac_sampling_pph23_value = (
                                          (Number(e.target.value) *
                                            Number(
                                              row[idx]
                                                .sac_sampling_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          sac_sampling_pph23_value = "";
                                        }
                                        let sac_sampling_total = (
                                          Number(e.target.value) +
                                          Number(sac_sampling_vat_value) -
                                          Number(sac_sampling_pph23_value)
                                        ).toFixed(2);
                                        let sac_sampling_ctc = (
                                          Number(e.target.value) +
                                          Number(sac_sampling_vat_value)
                                        ).toFixed(2);

                                        row[idx].sac_sampling_base_price =
                                          e.target.value;
                                        row[idx].sac_sampling_vat_value =
                                          sac_sampling_vat_value;
                                        row[idx].sac_sampling_pph23_value =
                                          sac_sampling_pph23_value;
                                        row[idx].sac_sampling_total =
                                          sac_sampling_total;
                                        row[idx].sac_sampling_ctc =
                                          sac_sampling_ctc;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-4 pr-0 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_percent ? toLocaleString(e.dc_vat_percent) : "-"}</div> */}
                                  <TextField
                                    name="sac_sampling_vat_percent"
                                    margin="dense"
                                    //variant='outlined'
                                    fullWidth
                                    value={e.sac_sampling_vat_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;

                                        let sac_sampling_vat_value = (
                                          (Number(
                                            row[idx].sac_sampling_base_price
                                          ) *
                                            Number(e.target.value)) /
                                          100
                                        ).toFixed(2);
                                        let sac_sampling_pph23_value = (
                                          (Number(
                                            row[idx].sac_sampling_base_price
                                          ) *
                                            Number(
                                              row[idx]
                                                .sac_sampling_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          sac_sampling_pph23_value = "";
                                        }
                                        let sac_sampling_total = (
                                          Number(
                                            row[idx].sac_sampling_base_price
                                          ) +
                                          Number(sac_sampling_vat_value) -
                                          Number(sac_sampling_pph23_value)
                                        ).toFixed(2);
                                        let sac_sampling_ctc = (
                                          Number(
                                            row[idx].sac_sampling_base_price
                                          ) + Number(sac_sampling_vat_value)
                                        ).toFixed(2);

                                        row[idx].sac_sampling_vat_percent =
                                          e.target.value;
                                        row[idx].sac_sampling_vat_value =
                                          sac_sampling_vat_value;
                                        row[idx].sac_sampling_pph23_value =
                                          sac_sampling_pph23_value;
                                        row[idx].sac_sampling_total =
                                          sac_sampling_total;
                                        row[idx].sac_sampling_ctc =
                                          sac_sampling_ctc;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </div>
                                <div className="col-4 pr-0 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? toLocaleString(e.vat_value) : "-"}</div> */}
                                  <TextField
                                    name="sac_sampling_vat_value"
                                    margin="dense"
                                    // variant='outlined'
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.sac_sampling_vat_value}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        row[idx].sac_sampling_vat_value =
                                          e.target.value;
                                        let sac_sampling_pph23_value = (
                                          (Number(
                                            row[idx].sac_sampling_base_price
                                          ) *
                                            Number(
                                              row[idx]
                                                .sac_sampling_pph23_percent
                                            )) /
                                          100
                                        ).toFixed(2);
                                        if (
                                          config.company === "SRPL" || config.company === "AVS"  ||
                                          config.company === "PRPL"
                                        ) {
                                          sac_sampling_pph23_value = "";
                                        }
                                        let sac_sampling_total = (
                                          Number(
                                            row[idx].sac_sampling_base_price
                                          ) +
                                          Number(e.target.value) -
                                          Number(sac_sampling_pph23_value)
                                        ).toFixed(2);
                                        let sac_sampling_ctc = (
                                          Number(
                                            row[idx].sac_sampling_base_price
                                          ) + Number(e.target.value)
                                        ).toFixed(2);

                                        row[idx].sac_sampling_pph23_value =
                                          sac_sampling_pph23_value;
                                        row[idx].sac_sampling_total =
                                          sac_sampling_total;
                                        row[idx].sac_sampling_ctc =
                                          sac_sampling_ctc;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") && (
                                  <div className="col-lg-6 p-0 mb-3">
                                    <label
                                      className="form_label mb-0"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Includes PPH23 @ 2%
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {e.sac_sampling_pph23_value
                                        ? toLocaleString(
                                            e.sac_sampling_pph23_value
                                          )
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="row">
                                <div className="col-lg-6 p-0">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.sac_sampling_total
                                      ? toLocaleString(e.sac_sampling_total)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.sac_sampling_ctc
                                      ? toLocaleString(e.sac_sampling_ctc)
                                      : 0}{" "}
                                    {config.company === "SRPL" || config.company === "AVS"  ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {(this.state.features.includes('521')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                  feature_name="Mv_Pre_Shipment"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("MvPreShipment");
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
