import React from 'react';

import { localDateFormate } from './common';

export default function AuditInfo(props) {
  const { approved_by_name = '', approved_date = null, approved_by_2 = '', approved_date_2 = null } = props.info || {};
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead className="table-header">
            <tr>
              <th className="text-center">Approval Type</th>
              <th className="text-center">Approved by</th>
              <th className="text-center">Approved On</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">Business</td>
              <td className="text-center">
                <div> {approved_by_name}</div>
              </td>
              <td className="text-center">
                {localDateFormate(approved_date)}
              </td>
            </tr>
            <tr>
              <td className="text-center">Financial</td>
              <td className="text-center">
                {approved_by_2}
              </td>
              <td className="text-center">
                {localDateFormate(approved_date_2)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
