import React, { Component } from 'react';

import api from '../../api/api';
import CookieHandler from '../common/CookieHandler';
import { localDateFormate, toLocaleString } from '../common/common';

export default class ViewCoalPriceIndexes extends Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();

    this.state = {
      coal_index_type: 'Weekly',
      index_ids: [],
      average_coal_index_price: 0,
      prorata_gar: '0',
      prorata_coal_index_price: 0,
      bonus_coal_index_pmt: '',
      penality_coal_index_pmt: '',
      derivered_coal_index_pmt: '',
      offset: 0,
      limit: 100,
      final_coal_index_pmt: 0,
      monthly_coal_indexes: [],
      weekly_coal_indexes: [],
    }
  }

  async componentDidMount() {
    await this.get_weekly_coal_prices();
    await this.get_monthly_coal_prices();
    const {
      coal_index_type = 'Weekly',
      index_ids = [],
      average_coal_index_price = 0,
      prorata_gar = '0',
      prorata_coal_index_price = 0,
      bonus_coal_index_pmt = '',
      penality_coal_index_pmt = '',
      derivered_coal_index_pmt = '',
      offset = 0,
      limit = 5,
      final_coal_index_pmt = 0,
      coal_index_remarks = null,
    } = this.props.coal_index;

    this.setState({
      coal_index_type: coal_index_type,
      index_ids: index_ids,
      average_coal_index_price: average_coal_index_price,
      prorata_gar: prorata_gar,
      prorata_coal_index_price: prorata_coal_index_price,
      bonus_coal_index_pmt: bonus_coal_index_pmt,
      penality_coal_index_pmt: penality_coal_index_pmt,
      derivered_coal_index_pmt: derivered_coal_index_pmt,
      final_coal_index_pmt: final_coal_index_pmt,
      coal_index_remarks: coal_index_remarks,
      offset: offset,
      limit: limit,
    },
    );

  }

  get_weekly_coal_prices = () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    api.get_weekly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.weekly_coal_indexes.length >= 10) {
                  this.setState({ showLoadMore: true })
                }
                if (res.weekly_coal_indexes.length > 0) {
                  this.setState({
                    weekly_coal_indexes: [...this.state.weekly_coal_indexes, ...res.weekly_coal_indexes],
                    submitting: false,
                  })
                }

                this.setState({ isLoading: false })

              }
            })
        }
      })
  }

  get_monthly_coal_prices = async () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    await api.get_monthly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.monthly_coal_indexes.length >= 10) {
                  this.setState({ showLoadMore: true })
                }
                if (res.monthly_coal_indexes.length > 0) {
                  this.setState({
                    monthly_coal_indexes: [...this.state.monthly_coal_indexes, ...res.monthly_coal_indexes],
                    submitting: false,
                  })
                }
                this.setState({ isLoading: false })
              }
            })
        }
      })
  }

  renderCoalPrices = () => {

    let { coal_index_type, monthly_coal_indexes, weekly_coal_indexes, index_ids } = this.state;

    let coal_indexes = coal_index_type === "Weekly"
      ? weekly_coal_indexes?.filter(e => index_ids?.find(el => el === e.id))
      : monthly_coal_indexes?.filter(e => index_ids?.find(el => el === e.id));

    if (coal_indexes.length > 0) {

      return (
        <div className="table-responsive">

          <table className="table table-sm table-bordered mb-0">
            <thead className="table-header">
              <tr>
                <th className='text-center ' nowrap="true">{coal_index_type === "Weekly" ? 'Week Ending' : "Month Ending"}</th>
                <th className='text-center ' nowrap="true">6500 GAR (6200 NAR) </th>
                <th className='text-center ' nowrap="true">5800 GAR (5500 NAR) </th>
                <th className='text-center ' nowrap="true">5000 GAR (4600 NAR) </th>
                <th className='text-center ' nowrap="true">4200 GAR (3800 NAR) </th>
                <th className='text-center ' nowrap="true">3400 GAR (3000 NAR) </th>
              </tr>
            </thead>
            <tbody>
              {coal_indexes.map((p, idx) => (
                <tr key={p.id}>
                  <td nowrap="true" className='text-center '>{localDateFormate(coal_index_type === "Weekly" ? p.week_ending_date : p.month_year)}</td>
                  <td className='text-center '>{p.gar_6500}</td>
                  <td className='text-center '>{p.gar_5800}</td>
                  <td className='text-center '>{p.gar_5000}</td>
                  <td className='text-center '>{p.gar_4200}</td>
                  <td className='text-center '>{p.gar_3400}</td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      )
    }
  }


  getProrate(prorata_gar) {

    let prorata = prorata_gar === 'gar_6500'
      ? '6500 GAR (6200 NAR)'
      : prorata_gar === 'gar_5800'
        ? '5800 GAR (5500 NAR)'
        : prorata_gar === 'gar_5000'
          ? '5000 GAR (4600 NAR)'
          : prorata_gar === 'gar_4200'
            ? "4200 GAR (3800 NAR)"
            : "3400 GAR (3000 NAR)"


    return prorata
  }


  render() {

    const { coal_index_type, prorata_gar, average_coal_index_price,
      prorata_coal_index_price, bonus_coal_index_pmt, penality_coal_index_pmt,
      derivered_coal_index_pmt, final_coal_index_pmt, coal_index_remarks } = this.state;

    return (
      <div className='card border'>
        <div className='row'>
          <div className='col-lg-4 p-0'>
            <label className='contract_display_header_label pt-2'>Coal Index Type</label>
            <div className='contract_display_header_value'>{coal_index_type}</div>
          </div>
        </div>

        <div className='row mb-3'>
          {this.renderCoalPrices()}
        </div>

        <div className='row'>
          <div className='col-lg-4 p-0 mb-3'>
            <label className='contract_display_header_label'>Prorata Grade considered</label>
            <div className='contract_display_header_value'>{prorata_gar ? this.getProrate(prorata_gar) : '-'}</div>
          </div>
          <div className='col-lg-4 p-0'>
            <label className='contract_display_header_label'>Average Coal Base Price PMT</label>
            <div className='contract_display_header_value'>{average_coal_index_price ? toLocaleString(average_coal_index_price) : 0} USD</div>
          </div>
          <div className='col-lg-4 p-0 mb-3'>
            <label className='contract_display_header_label'>Prorata Coal Base Price PMT</label>
            <div className='contract_display_header_value'>{prorata_coal_index_price ? toLocaleString(prorata_coal_index_price) : 0} USD</div>
          </div>
          <div className='col-lg-4 p-0'>
            <label className='contract_display_header_label'>Bonus Base Price PMT</label>
            <div className='contract_display_header_value'>{bonus_coal_index_pmt ? toLocaleString(bonus_coal_index_pmt) : 0} USD</div>
          </div>
          <div className='col-lg-4 p-0'>
            <label className='contract_display_header_label'>Discount Price PMT</label>
            <div className='contract_display_header_value'>{penality_coal_index_pmt ? toLocaleString(penality_coal_index_pmt) : 0} USD</div>
          </div>
          <div className='col-lg-4 p-0 mb-3'>
            <label className='contract_display_header_label'>Derived Coal Base Price PMT</label>
            <div className='contract_display_header_value'>{derivered_coal_index_pmt ? toLocaleString(derivered_coal_index_pmt) : 0} USD</div>
          </div>
          <div className='col-lg-4 p-0'>
            <label className='contract_display_header_label'>Final Coal Base Price PMT</label>
            <div className='contract_display_header_value'>{final_coal_index_pmt ? toLocaleString(final_coal_index_pmt) : 0} USD</div>

          </div>
        </div>

        {coal_index_remarks &&
          <div className='row'>
            <div className='col-lg-12 p-0'>
              <label className='contract_display_header_label'>Remarks</label>
              <div className='contract_display_header_value text-uppercase'>{coal_index_remarks}</div>

            </div>
          </div>

        }

      </div>
    )
  }
}