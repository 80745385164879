export const CompanyCodesEnum = Object.freeze({
    /**
     * USD currency Companies
     */
    SRPL: "SRPL",
    AVS: "AVS",
    PRPL: "PRPL",

    /**
     * IDR currency Companies
     */
    BTR: "BTR",
    SRK: "SRK",
    SDAM: "SDAM"
})