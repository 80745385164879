import React, { Component } from 'react';
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import Loader from "../../common/Loader";
import ViewFiles from '../../common/ViewFiles';
import AuditInfo from '../../common/AuditInfo';
import { toLocaleString, localDateFormate } from "../../common/common";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";

class PnbpChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      vendor_id: '0',
      billing_currency: "0",
      currency_exchange_rate: "",
      quantity: "",
      pnbp_per_mt: "",
      pnbp: "",
      pnbp_local_currency: "",
      jasa_pbm_calculation: "",
      jasa_pbm_pmt: "",
      jasa_pbm_value: "",
      jasa_pbm_local_currency: "",
      remarks: "",

      other_charges: [{ description: "", price: "", remarks: "" }],
      isLoading: true,
      features: [],
      vendors: [],
      vendorData: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Stevedore_Costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("631") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }

      const request = {
        login_user_id: this.Cookie.getCookie("loginUserId"),
        idtoken: this.Cookie.getIdTokenCookie(),
        costing_id: this.props.costing_id
      }

      await api.get_mv_pnbp_jasa_pbm(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              const payable_to_vendor = res.mv_pnbp_jasa_pbm?.other_charges?.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.price ? item.price : 0)), 0) + parseFloat(res.mv_pnbp_jasa_pbm.pnbp ? res.mv_pnbp_jasa_pbm.pnbp : 0) + parseFloat(res.mv_pnbp_jasa_pbm?.jasa_pbm_value ? res.mv_pnbp_jasa_pbm?.jasa_pbm_value : 0);

              let paid = 0;
              for (var i of res.mv_pnbp_jasa_pbm?.payments_data) {
                if (i.date_of_payment) {
                  paid += parseFloat(i.amount ? i.amount : 0);
                }
              }

              const remaining_amount = payable_to_vendor - paid;
              this.setState(prevState => ({
                ...prevState,
                ...res.mv_pnbp_jasa_pbm,
                payable_to_vendor: payable_to_vendor,
                paid: paid,
                remaining_amount: remaining_amount,
                isLoading: false
              }))
            }
            else if (res.code === '607') {
              window.location.href = '/logout';
            }
          })
        }
      })
    }
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pnbp_jasa_pbm",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = () => {
    const {
      id,
      payments_data = [],
      invoice_no = null,
      remaining_amount = 0,
      exchange_rate = 1,
      approval_status = "Pending",
    } = this.state;
    let payment_related_data = {
      paymentTitle: "PNBP & JASA PNB",
      payment_posting_type: "mv_pnbp_jasa",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "631",
      view_payment: "632",
      update_payment: "633",
      delete_payment: "634",
      approver: "636",
      approver2: "638",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={payments_data}
        costingType="MV PNBP JASA PBM"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const { vendor_name = '', quantity = 0, other_charges = [], pnbp_per_mt = 0, jasa_pbm_pmt = 0, pnbp = 0, jasa_pbm_value = 0, costing_no = '', currency_exchange_rate = 1, invoice_date = null, invoice_no = '', mother_vessel_name, } = this.state;
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mother_vessel_name,
      costing_name: "PNBP & JASA PBM",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency: config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
        ? "USD"
        : "IDR",
      total_payable_amount: toLocaleString(
        other_charges?.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.price ? item.price : 0)), 0) + parseFloat(pnbp ? pnbp : 0) + parseFloat(jasa_pbm_value ? jasa_pbm_value : 0)
      ),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(quantity),
          adjustedPrice: toLocaleString(pnbp_per_mt),
          desc: `PNBP - ${currency_exchange_rate > 1
            ? `Exchange rate @ ${currency_exchange_rate}`
            : ""
            }`,
          // amount: i.stevedore_total,
          amount: pnbp,
        },
        {
          qty: toLocaleString(quantity),
          adjustedPrice: toLocaleString(jasa_pbm_pmt),
          desc: `JASA PBM`,
          amount: jasa_pbm_value,
        },
        ...(other_charges.length > 0
          ?
          other_charges.map((e, idx) => (

            {
              qty: " ",
              adjustedPrice: " ",
              desc: `Other Charges -  ${e.description}`,
              amount: `${toLocaleString(e.price ? e.price : 0)}`,
            }
          ))

          : []),

      ]

    };
    return (
      <InvoiceLink data={invoiceObject} fileName={invoiceObject?.costing_id} />
    );
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-8 pl-0">
              <h4 style={{ padding: "10px 20px" }}> {`PNBP Charges - ${this.state.costing_no} `}  <span
                className={
                  this.state.approval_status_2 === "Pending" ? "type_2" : "type_1"
                }
                style={{ padding: 8, marginLeft: 16 }}
              >
                {this.state.approval_status_2}
              </span></h4>
            </div>
            {this.state.approval_status === "Pending" &&
              this.Cookie.getCookie("loginUserId") !== this.state.created_by &&
              this.state.approved_by !== this.Cookie.getCookie("loginUserId") &&
              (this.state.features.includes("635") ||
                this.state.features.includes("637") ||
                this.state.features.includes("97")) && (
                <BusinessApproveButton
                  costingID={this.state.id}
                  costingType={"MV PNBP"}
                  accessInfo={{
                    ...this.state,
                    approver_1: "635",
                    approver_2: "637",
                  }}
                  callbackFn={() => this.componentDidMount()}
                />
              )}
            <div className="col-lg-1 pl-0 float-right">
              {this.renderInvoiceLink()}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body p-0">
                <div className="row">

                  <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="table-responsive">
                      <table className="table table-bordered table-sm">
                        <thead className="table-header">
                          <tr>
                            <th className="text-center">Description</th>
                            <th className="text-right">Qty</th>
                            <th className="text-right">Unit Price</th>
                            <th className="text-right">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              {` PNBP`}
                              <div>
                                {`${this.state.currency_exchange_rate > 1
                                  ? `Exchange Rate @ ${toLocaleString(
                                    this.state.currency_exchange_rate
                                  )}`
                                  : ``
                                  }`}
                              </div>
                            </td>
                            <td className="text-right">
                              {toLocaleString(this.state.quantity)}
                            </td>
                            <td className="text-right">
                              {`${toLocaleString(this.state.pnbp_per_mt)} ${this.state.billing_currency}`}
                            </td>
                            <td className="text-right">
                              {toLocaleString(this.state.pnbp)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {`JASA PBM`}
                              <div>{`JASA PBM Calculation :  ${this.state.jasa_pbm_calculation}`}</div>
                            </td>
                            <td className="text-right">
                              {toLocaleString(this.state.quantity)}
                            </td>
                            <td className="text-right">
                              {`${toLocaleString(this.state.jasa_pbm_pmt)} ${this.state.billing_currency}`}
                            </td>
                            <td className="text-right">
                              {toLocaleString(this.state.jasa_pbm_value)}
                            </td>
                          </tr>

                          {this.state.other_charges.length > 0 &&
                            <>
                              <tr>
                                <td>
                                  {this.state.other_charges?.map(obj => (
                                    <div>Other Charges - {obj.description}</div>
                                  ))}
                                </td>
                                <td className="text-right">

                                </td>
                                <td className="text-right"></td>
                                <td className="text-right">{`${toLocaleString(
                                  this.state.other_charges.reduce((acc, item) => (Number(acc ? acc : 0) + Number(item.price ? item.price : 0)), 0)
                                )}`}</td>
                              </tr>

                            </>

                          }

                          <tr>
                            <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} >
                              {` Payable to ${this.state.vendor_name}`}
                            </td>
                            <td className="text-right" style={{ fontWeight: 'bold' }} >{`${toLocaleString(this.state.payable_to_vendor)} ${config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                              ? "USD"
                              : "IDR"
                              }`}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} > Paid</td>
                            <td className="text-right " style={{ fontWeight: 'bold' }} >{toLocaleString(this.state.paid)}</td>
                          </tr>
                          <tr>
                            <td colSpan={3} className="text-right " style={{ fontWeight: 'bold' }} > Remaing Amount</td>
                            <td className="text-right " style={{ fontWeight: 'bold' }} >{toLocaleString(this.state.remaining_amount)}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <InvoiceForm
                      open={this.state.invoiceDialog}
                      data={this.state}
                      costingID={this.state.id}
                      costing_type={"MV PNBP JASA PBM"}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />

                    {this.renderPaymentPostings()}
                  </div>
                </div>

                {/* {(this.state.features.includes('532')  || this.state.features.includes('97') ) && */}

                <ViewFiles
                  {...this.props}
                  files_data={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                />

                <AuditInfo
                  info={this.state}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("MvDoc");
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}

export default PnbpChargesView;