import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel Coasl Procurement Charges
import MvCoalSalesChargesAdd from "./MvCoalSalesChargesAdd";
import MvCoalSalesChargesView from "./MvCoalSalesChargesView";
import MvCoalSalesChargesUpdate from "./MvCoalSalesChargesUpdate";
import Loader from "../../common/Loader";

export default class MvCoalSalesChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      MvCoalSalesCharges: [],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      deletePaymentDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("235") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const businessNoID = this.props.businessNoID;

      await api
        .get_mv_sales_fin_csc(loginUserID, idToken, businessNoID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                // if (res.mv_sales_fin_csc.length <= 0) {
                //   this.setState({
                //     errorMsg: "No Sales Financial data available.",
                //     snackBarErrorOpen: true,
                //   })
                // }
                var paid_to_vendor = 0;
                var remaining_amount = 0;
                for (var i = 0; i < res.mv_sales_fin_csc.length; i++) {
                  if (res.mv_sales_fin_csc[i].csc_payments.length > 0) {
                    for (var j of res.mv_sales_fin_csc[i].csc_payments) {
                      paid_to_vendor += Number(j.amount);
                      remaining_amount =
                        Number(res.mv_sales_fin_csc[i].payable_to_vendor) -
                        Number(paid_to_vendor);
                    }
                    res.mv_sales_fin_csc[i].paid_to_vendor = paid_to_vendor;
                    res.mv_sales_fin_csc[i].remaining_amount = remaining_amount;
                    paid_to_vendor = 0;
                    remaining_amount = 0;
                  } else {
                    res.mv_sales_fin_csc[i].remaining_amount = Number(
                      res.mv_sales_fin_csc[i].payable_to_vendor
                    );
                  }
                }
                if (res.mv_sales_fin_csc.length > 0) {
                  this.setState({
                    MvCoalSalesCharges: res.mv_sales_fin_csc,
                    isLoading: false,
                  });
                } else if (res.mv_sales_fin_csc.length === 0) {
                  // this.props.response(res.mv_pur_fin_cpc.length);
                  this.setState({
                    isLoading: false,
                    //errorMsg: "No Purchase financials data available.",
                    successMsg: "",
                    closeDialog: true,
                  });
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  successMsg: "",
                  closeDialog: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  isLoading: false,
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  successMsg: "",
                  closeDialog: true,
                });
              }
            });
          }
        });

      document.title =
        config.documentTitle +
        "Mother Vessel Purchase Financial Coal Procurement Charges";
    }
  }

  // convert local prices and quality fields.
  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          MvCoalSalesChargesAdd: false,
          MvCoalSalesChargesView: false,
          MvCoalSalesChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        MvCoalSalesChargesAdd: false,
        MvCoalSalesChargesView:
          value.screen === "MvCsc" && value.message === "alreadyDataAvailable"
            ? true
            : false,
        MvCoalSalesChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        MvCoalSalesChargesAdd: false,
        MvCoalSalesChargesView: false,
        MvCoalSalesChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          MvCoalSalesChargesAdd: false,
          MvCoalSalesChargesView:
            value.code === "200" && value.screen === "MvCsc" ? true : false,
          MvCoalSalesChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvCoalSalesChargesUpdate: false,
        MvCoalSalesChargesAdd: false,
        MvCoalSalesChargesView: true,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        MvCoalSalesChargesUpdate: true,
        MvCoalSalesChargesAdd: false,
        MvCoalSalesChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.mv_sales_financials_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    // return;
    //Delete Stevedore costing charge
    api.update_mv_sales_csc(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.MvCoalSalesChargesAdd) {
      drawerComponent = (
        <MvCoalSalesChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.MvCoalSalesChargesView && this.state.businessNo) {
      drawerComponent = (
        <MvCoalSalesChargesView
          coalSalesChargesID={this.state.coalSalesChargesID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          onEdit={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              MvCoalSalesChargesUpdate: true,
              MvCoalSalesChargesView: false,
              MvCoalSalesChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.MvCoalSalesChargesUpdate && this.state.businessNo) {
      drawerComponent = (
        <MvCoalSalesChargesUpdate
          coalSalesChargesID={this.state.coalSalesChargesID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                Coal Sales Charges
              </h4>
              {(this.state.features.includes("282") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      MvCoalSalesChargesAdd: true,
                      MvCoalSalesChargesView: false,
                      MvCoalSalesChargesUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Costing ID</th>
                          <th nowrap="true"> Payment to</th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Quantity in MT
                          </th>
                          <th nowrap="true" className=" text-right">
                            Base Price PMT
                          </th>
                          <th nowrap="true" className=" text-right">
                            Payable to Vendor
                          </th>
                          <th nowrap="true" className=" text-left pl-4">
                            Action
                          </th>
                        </tr>
                      </thead>
                      {this.state.MvCoalSalesCharges.length > 0 ? (
                        <tbody>
                          {this.state.MvCoalSalesCharges.map((idr, indx) => (
                            <tr key={indx}>
                              {this.state.features.includes("283") ||
                              this.state.features.includes("97") ? (
                                <td>
                                  <i
                                    aria-hidden="true"
                                    style={{
                                      fontStyle: "normal",
                                      color: config.themeColor,
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        openDrawer: true,
                                        MvCoalSalesChargesAdd: false,
                                        MvCoalSalesChargesView: true,
                                        MvCoalSalesChargesUpdate: false,
                                        businessNo: idr.business_no_id,
                                        coalSalesChargesID: idr.id,
                                      })
                                    }
                                  >
                                    {idr.costing_no}
                                  </i>
                                </td>
                              ) : (
                                <td
                                  className="allocation_table_value"
                                  nowrap="true"
                                >
                                  {idr.costing_no}
                                </td>
                              )}

                              <td>
                                {" "}
                                {idr.business_partner_type === "Vendor"
                                  ? idr.payment_to_vendor_name
                                  : idr.payment_to_customer_name}
                              </td>
                              <td className="text-right" nowrap="true">
                                {idr.barge_quantity
                                  ? this.toLocaleString(idr.barge_quantity)
                                  : 0}
                              </td>
                              <td className="text-right" nowrap="true">
                                {idr.base_price_pmt
                                  ? this.toLocaleString(idr.base_price_pmt)
                                  : 0}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td className="text-right" nowrap="true">
                                {idr.payable_to_vendor
                                  ? this.toLocaleString(idr.payable_to_vendor)
                                  : 0}{" "}
                                {config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </td>
                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("236") ||
                                  this.state.features.includes("97")) && (
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    title="Edit floating crane charges"
                                    data-placement="bottom"
                                    onClick={() =>
                                      this.setState({
                                        businessNo: idr.business_no_id,
                                        coalSalesChargesID: idr.id,
                                        openDrawer: true,
                                        MvCoalSalesChargesUpdate: true,
                                      })
                                    }
                                  ></i>
                                )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("237") ||
                                    this.state.features.includes("97")) && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      title="Delete floating crane charges"
                                      data-placement="bottom"
                                      data-keyboard="false"
                                      data-target="#deleteConformationModal"
                                      data-backdrop="static"
                                      onClick={() => this.deleteCharges(idr)}
                                    ></i>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="5" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
