export const SPECFICIATIONS = {
  quality_calorific_value_basis: "0",
  quality_calorific_value_typical: "",
  quality_calorific_value_rejection: "",
  quality_calorific_value_penality: "",
  quality_calorific_value_bonus_cap: "",
  quality_calorific_value2_basis: "0",
  quality_calorific_value2_typical: "",
  quality_calorific_value2_rejection: "",
  quality_calorific_value2_penality: "",
  quality_calorific_value2_bonus_cap: "",
  quality_calorific_value3_basis: "0",
  quality_calorific_value3_typical: "",
  quality_calorific_value3_rejection: "",
  quality_calorific_value3_penality: "",
  quality_calorific_value3_bonus_cap: "",
  quality_total_moisture_basis: "ARB",
  quality_total_moisture_typical: "",
  quality_total_moisture_rejection: "",
  quality_inherent_moisture_basis: "ADB",
  quality_inherent_moisture_typical: "",
  quality_inherent_moisture_rejection: "",
  quality_ash_basis: "ADB",
  quality_ash_typical: "",
  quality_ash_rejection: "",
  quality_ash_penality: "",
  quality_volatile_matter_basis: "ADB",
  quality_volatile_matter_typical: "",
  quality_volatile_matter_rejection: "",
  quality_fixed_carbon_basis: "ADB",
  quality_fixed_carbon_typical: "",
  quality_fixed_carbon_rejection: "",
  quality_fuel_ratio_basis: "ADB",
  quality_fuel_ratio_typical: "",
  quality_fuel_ratio_rejection: "",
  quality_total_sulphur_basis: "ADB",
  quality_total_sulphur_typical: "",
  quality_total_sulphur_rejection: "",
  quality_total_sulphur_penality: "",
  quality_hgi_units: "",
  quality_hgi_basis: "",
  quality_hgi_typical: "",
  quality_hgi_rejection: "",

  ultimate_carbon_basis: "0",
  ultimate_carbon_typical: "",
  ultimate_carbon_rejection: "",
  ultimate_hydrogen_basis: "0",
  ultimate_hydrogen_typical: "",
  ultimate_hydrogen_rejection: "",
  ultimate_nitrogen_basis: "0",
  ultimate_nitrogen_typical: "",
  ultimate_nitrogen_rejection: "",

  size_dist1_name: "",
  size_dist1_typical: "",
  size_dist1_rejection: "",
  size_dist2_name: "",
  size_dist2_typical: "",
  size_dist2_rejection: "",
  size_dist3_name: "",
  size_dist3_typical: "",
  size_dist3_rejection: "",
  size_dist4_name: "",
  size_dist4_typical: "",
  size_dist4_rejection: "",
  size_dist5_name: "",
  size_dist5_typical: "",
  size_dist5_rejection: "",

  ash_reducing_initial_typical: "",
  ash_reducing_initial_rejection: "",
  ash_reducing_softening_typical: "",
  ash_reducing_softening_rejection: "",
  ash_reducing_hemispherical_typical: "",
  ash_reducing_hemispherical_rejection: "",
  ash_reducing_flow_typical: "",
  ash_reducing_flow_rejection: "",
  ash_oxidizing_initial_typical: "",
  ash_oxidizing_initial_rejection: "",
  ash_oxidizing_softening_typical: "",
  ash_oxidizing_softening_rejection: "",
  ash_oxidizing_hemispherical_typical: "",
  ash_oxidizing_hemispherical_rejection: "",
  ash_oxidizing_flow_typical: "",
  ash_oxidizing_flow_rejection: "",

  ash_sio2_basis: "0",
  ash_sio2_typical: "",
  ash_sio2_rejection: "",
  ash_ai203_basis: "0",
  ash_ai203_typical: "",
  ash_ai203_rejection: "",
  ash_fe203_basis: "0",
  ash_fe203_typical: "",
  ash_fe203_rejection: "",
  ash_cao_basis: "0",
  ash_cao_typical: "",
  ash_cao_rejection: "",
  ash_mgo_basis: "0",
  ash_mgo_typical: "",
  ash_mgo_rejection: "",
  ash_na2o_basis: "0",
  ash_na2o_typical: "",
  ash_na2o_rejection: "",
  ash_k2o_basis: "0",
  ash_k2o_typical: "",
  ash_k2o_rejection: "",
  ash_tio2_basis: "0",
  ash_tio2_typical: "",
  ash_tio2_rejection: "",
  ash_mn3o4_basis: "0",
  ash_mn3o4_typical: "",
  ash_mn3o4_rejection: "",
  ash_so3_basis: "0",
  ash_so3_typical: "",
  ash_so3_rejection: "",
  ash_p2o5_basis: "0",
  ash_p2o5_typical: "",
  ash_p2o5_rejection: "",

  trace_arsenic_basis: "0",
  trace_arsenic_typical: "",
  trace_arsenic_rejection: "",
  trace_mercury_basis: "0",
  trace_mercury_typical: "",
  trace_mercury_rejection: "",
  trace_selenium_basis: "0",
  trace_selenium_typical: "",
  trace_selenium_rejection: "",
  trace_boron_basis: "0",
  trace_boron_typical: "",
  trace_boron_rejection: "",
  minor_ba_basis: "0",
  minor_ba_typical: "",
  minor_ba_rejection: "",
  minor_phosphorous_basis: "0",
  minor_phosphorous_typical: "",
  minor_phosphorous_rejection: "",

  other_coal_bulk_density_typical: "",
  other_stowage_factor_typical: "",
  other_imsbc_group_typical: "",
  other_GandY_index_typical: "",
  other_transmittance_value_typical: "",
  other_max_lump_size_typical: "",
  other_angle_maximum_typical: "",
  other_angle_minimum_typical: "",

  // specification (ISO) tables
  quality_calorific_value_basis_iso: "0",
  quality_calorific_value_typical_iso: "",
  quality_calorific_value_rejection_iso: "",
  quality_calorific_value_penality_iso: "",
  quality_calorific_value_bonus_cap_iso: "",
  quality_calorific_value2_basis_iso: "0",
  quality_calorific_value2_typical_iso: "",
  quality_calorific_value2_rejection_iso: "",
  quality_calorific_value2_penality_iso: "",
  quality_calorific_value2_bonus_cap_iso: "",
  quality_calorific_value3_basis_iso: "0",
  quality_calorific_value3_typical_iso: "",
  quality_calorific_value3_rejection_iso: "",
  quality_calorific_value3_penality_iso: "",
  quality_calorific_value3_bonus_cap_iso: "",
  quality_total_moisture_basis_iso: "ARB",
  quality_total_moisture_typical_iso: "",
  quality_total_moisture_rejection_iso: "",
  quality_inherent_moisture_basis_iso: "ADB",
  quality_inherent_moisture_typical_iso: "",
  quality_inherent_moisture_rejection_iso: "",
  quality_ash_basis_iso: "ADB",
  quality_ash_typical_iso: "",
  quality_ash_rejection_iso: "",
  quality_ash_penality_iso: "",
  quality_volatile_matter_basis_iso: "ADB",
  quality_volatile_matter_typical_iso: "",
  quality_volatile_matter_rejection_iso: "",
  quality_fixed_carbon_basis_iso: "ADB",
  quality_fixed_carbon_typical_iso: "",
  quality_fixed_carbon_rejection_iso: "",
  quality_fuel_ratio_basis_iso: "ADB",
  quality_fuel_ratio_typical_iso: "",
  quality_fuel_ratio_rejection_iso: "",
  quality_total_sulphur_basis_iso: "ADB",
  quality_total_sulphur_typical_iso: "",
  quality_total_sulphur_rejection_iso: "",
  quality_total_sulphur_penality_iso: "",
  quality_hgi_units_iso: "",
  quality_hgi_basis_iso: "",
  quality_hgi_typical_iso: "",
  quality_hgi_rejection_iso: "",

  ultimate_carbon_basis_iso: "0",
  ultimate_carbon_typical_iso: "",
  ultimate_carbon_rejection_iso: "",
  ultimate_hydrogen_basis_iso: "0",
  ultimate_hydrogen_typical_iso: "",
  ultimate_hydrogen_rejection_iso: "",
  ultimate_nitrogen_basis_iso: "0",
  ultimate_nitrogen_typical_iso: "",
  ultimate_nitrogen_rejection_iso: "",

  size_dist1_name_iso: "",
  size_dist1_typical_iso: "",
  size_dist1_rejection_iso: "",
  size_dist2_name_iso: "",
  size_dist2_typical_iso: "",
  size_dist2_rejection_iso: "",
  size_dist3_name_iso: "",
  size_dist3_typical_iso: "",
  size_dist3_rejection_iso: "",
  size_dist4_name_iso: "",
  size_dist4_typical_iso: "",
  size_dist4_rejection_iso: "",
  size_dist5_name_iso: "",
  size_dist5_typical_iso: "",
  size_dist5_rejection_iso: "",

  ash_reducing_initial_typical_iso: "",
  ash_reducing_initial_rejection_iso: "",
  ash_reducing_softening_typical_iso: "",
  ash_reducing_softening_rejection_iso: "",
  ash_reducing_hemispherical_typical_iso: "",
  ash_reducing_hemispherical_rejection_iso: "",
  ash_reducing_flow_typical_iso: "",
  ash_reducing_flow_rejection_iso: "",
  ash_oxidizing_initial_typical_iso: "",
  ash_oxidizing_initial_rejection_iso: "",
  ash_oxidizing_softening_typical_iso: "",
  ash_oxidizing_softening_rejection_iso: "",
  ash_oxidizing_hemispherical_typical_iso: "",
  ash_oxidizing_hemispherical_rejection_iso: "",
  ash_oxidizing_flow_typical_iso: "",
  ash_oxidizing_flow_rejection_iso: "",

  ash_sio2_basis_iso: "0",
  ash_sio2_typical_iso: "",
  ash_sio2_rejection_iso: "",
  ash_ai203_basis_iso: "0",
  ash_ai203_typical_iso: "",
  ash_ai203_rejection_iso: "",
  ash_fe203_basis_iso: "0",
  ash_fe203_typical_iso: "",
  ash_fe203_rejection_iso: "",
  ash_cao_basis_iso: "0",
  ash_cao_typical_iso: "",
  ash_cao_rejection_iso: "",
  ash_mgo_basis_iso: "0",
  ash_mgo_typical_iso: "",
  ash_mgo_rejection_iso: "",
  ash_na2o_basis_iso: "0",
  ash_na2o_typical_iso: "",
  ash_na2o_rejection_iso: "",
  ash_k2o_basis_iso: "0",
  ash_k2o_typical_iso: "",
  ash_k2o_rejection_iso: "",
  ash_tio2_basis_iso: "0",
  ash_tio2_typical_iso: "",
  ash_tio2_rejection_iso: "",
  ash_mn3o4_basis_iso: "0",
  ash_mn3o4_typical_iso: "",
  ash_mn3o4_rejection_iso: "",
  ash_so3_basis_iso: "0",
  ash_so3_typical_iso: "",
  ash_so3_rejection_iso: "",
  ash_p2o5_basis_iso: "0",
  ash_p2o5_typical_iso: "",
  ash_p2o5_rejection_iso: "",

  trace_arsenic_basis_iso: "0",
  trace_arsenic_typical_iso: "",
  trace_arsenic_rejection_iso: "",
  trace_mercury_basis_iso: "0",
  trace_mercury_typical_iso: "",
  trace_mercury_rejection_iso: "",
  trace_selenium_basis_iso: "0",
  trace_selenium_typical_iso: "",
  trace_selenium_rejection_iso: "",
  trace_boron_basis_iso: "0",
  trace_boron_typical_iso: "",
  trace_boron_rejection_iso: "",
  minor_ba_basis_iso: "0",
  minor_ba_typical_iso: "",
  minor_ba_rejection_iso: "",
  minor_phosphorous_basis_iso: "0",
  minor_phosphorous_typical_iso: "",
  minor_phosphorous_rejection_iso: "",

  other_coal_bulk_density_typical_iso: "",
  other_stowage_factor_typical_iso: "",
  other_imsbc_group_typical_iso: "",
  other_GandY_index_typical_iso: "",
  other_transmittance_value_typical_iso: "",
  other_max_lump_size_typical_iso: "",
  other_angle_maximum_typical_iso: "",
  other_angle_minimum_typical_iso: "",

  activeTabIndex: false,
  ISOactiveTabIndex: false,

  ARBitem: false,
  ADBitem: false,
  DAFitem: false,
  DBitem: false,
  NARitem: false,
  Iso_ARBitem: false,
  Iso_ADBitem: false,
  Iso_DAFitem: false,
  Iso_DBitem: false,
  Iso_NARitem: false,
}