import React, { Component } from 'react';
import {
    TableContainer, Table, TableHead, TableBody,
    Tooltip, Button
} from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import Loader from '../../common/Loader';
import { PRE_SHIPMENT_TYPES } from '../../../common/enums';


export default class PreShipmentWithBargeReport extends Component {
    constructor(props) {
        super(props);
        this.Cookie = new CookieHandler();
        this.state = {
            preShipmentData: [],
            isLoading: true,
            activeStep: 0,
            succesMsg: '',
            errorMsg: '',

        }
    }


    componentDidMount() {
        if (!this.Cookie.isLoggedIn()) {
            this.props.history.replace("/logout");
        }
        const features = this.Cookie.getCookie("features");
        this.setState({ features: features });
        if (
            !(
                features.indexOf("55") >= 0 ||
                features.indexOf("57") >= 0 ||
                features.indexOf("97") >= 0
            )
        ) {
            window.location.href = "/dashboard";
        }
        this.getPreShipmentData();
    }

    getPreShipmentData = async () => {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const type = PRE_SHIPMENT_TYPES.WITH_BARGE;
        await api.getPreShipmentData(loginUserID, idToken, type).then((response) => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then((res) => {
                    if (res.code === "200") {
                        if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                        if (res.data) {
                            this.setState({
                                preShipmentData: res.data,
                                isLoading: false,
                            });
                        } else {
                            alert("Unexpected error occured. Please contact administrator.");
                            this.setState({ isLoading: false });
                        }
                    } else if (res.code === "607") {
                        this.setState({ isLoading: false });
                        window.location.href = "/logout";
                    }
                });
            }
        });
    }

    render() {
        const { preShipmentData } = this.state;
        return (
            <div>
                {!!this.state.isLoading && <Loader />}
                {!this.state.isLoading && (<section>
                    <div className="row">
                        <TableContainer className="table-responsive" style={{ maxHeight: "80vh", width: "90vw" }}>
                            <Table stickyHeader aria-label="sticky table" className="table table-bordered table-sm mb-0">
                                <TableHead className="table-header sticky-header-table">
                                    <tr>
                                        {/* <th nowrap="true" className="text-uppercase text-center">Action</th> */}
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center">
                                            Barge ID
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center">
                                            Barge Name
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Business Number
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center">
                                            Purchase Contract
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Supplier
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Method
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Basis
                                        </th>
                                        <th nowrap="true" colSpan={3} className="text-uppercase text-center ">
                                            Gross Calorific Value
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Ash (%)
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Total Sulphur (%)
                                        </th>
                                        <th nowrap="true" rowSpan={2} className="text-uppercase text-center ">
                                            Total Moisture (%)
                                        </th>
                                        <th
                                            nowrap="true" rowSpan={2}
                                            className="text-uppercase text-center"
                                        >
                                            Inherent Moisture (%)
                                        </th>
                                        <th
                                            nowrap="true" rowSpan={2}
                                            className="text-uppercase text-center"
                                        >
                                            Volatile Moisture (%)
                                        </th>
                                        <th
                                            nowrap="true" rowSpan={2}
                                            className="text-uppercase text-center"
                                        >
                                            Fixed Carbon (%)
                                        </th>
                                        <th
                                            nowrap="true" rowSpan={2}
                                            className="text-uppercase text-center"
                                        >
                                            Fuel Ratio(FC/VM)
                                        </th>
                                        <th
                                            nowrap="true" rowSpan={2}
                                            className="text-uppercase text-center"
                                        >
                                            HGI
                                        </th>
                                    </tr>
                                    <tr>
                                        <th className="text-uppercase text-center ">value 1</th>
                                        <th className="text-uppercase text-center ">value 2</th>
                                        <th className="text-uppercase text-center ">value 3</th>
                                    </tr>
                                </TableHead>
                                {!this.state.isLoading && <TableBody>
                                    {preShipmentData.map((preShipment, index) => {
                                        return <tr>
                                            <td><a
                                                style={{
                                                    textDecoration: "none",
                                                    color: config.themeColor,
                                                }}
                                                href={"/view-barge/" + btoa(preShipment.bargeId)}
                                            >
                                                {preShipment.bargeId}
                                            </a></td>
                                            <td>{preShipment.bargeName}</td>
                                            <td>{preShipment.businessNo}</td>

                                            <td>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        color: config.themeColor,
                                                    }}
                                                    href={"/view-purchase-contract/" + btoa(preShipment.purchaseContractID)}
                                                >
                                                    {preShipment.purchaseContractNo}
                                                </a>
                                            </td>
                                            <td class="text-nowrap">{preShipment.supplier}</td>
                                            <td>{preShipment.method}</td>
                                            <td>{preShipment.basis}</td>

                                            <td>{preShipment.grossCalorificValue1}</td>
                                            <td>{preShipment.grossCalorificValue2}</td>
                                            <td>{preShipment.grossCalorificValue3}</td>

                                            <td>{preShipment.ash}</td>
                                            <td>{preShipment.totalSulphur}</td>
                                            <td>{preShipment.totalMoisture}</td>
                                            <td>{preShipment.inherentMoisture}</td>
                                            <td>{preShipment.volatileMatter}</td>
                                            <td>{preShipment.fixedCarbon}</td>
                                            <td>{preShipment.fuelRatio}</td>
                                            <td>{preShipment.hgi}</td>
                                        </tr>
                                    })}

                                </TableBody>}
                            </Table>
                        </TableContainer>
                    </div>
                </section>
                )}
            </div>
        )
    }
}
