import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import SideBar from '../common/SideBar';
import Paper from '@material-ui/core/Paper';
import { IconButton } from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@material-ui/icons";
import {
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  Slide
} from '@material-ui/core';
import VendorIcon from '../../images/icons/vendor_icon.svg';
import CustomerIcon from '../../images/icons/customer_icon.svg';
import MaterialTable from 'material-table';

import CookieHandler from '../common/CookieHandler';
import api from '../../api/api';
// import '../../App.css';
import config from '../../config/config';

import Header from '../common/Header';
import exportToExcel from '../Export_To_Excel';



const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class BusinessPartner extends React.Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      vendorType: '0',
      vendorData: [],
      customerData: [],
      loading: true,
      isLoading: true,
      errorMessage: '',
      successMessage: '',
      features: [],
      page: 0,
      rowsPerPage: 10,
      page1: 0,
      rowsPerPage1: 10,
      activeTabIndex: 0,
      open: false
    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const status = 'Active';
    //Get Vendors Data
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.vendors) {

              this.setState({
                vendorData: res.vendors,
                //isLoading: false,
              });
            }
            else {
              alert('Unexpected error occured. Please contact administrator.');
            }
          } else if (res.code === '607') {
            window.location.href = '/logout'

          }
        })
      }
    });
    //Get Customer data
    await api.getCustomers(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === '200') {

            if (res.customers) {
              this.setState({
                customerData: res.customers,
                isLoading: false,
              });
            } else {
              alert('Unexpected error occured. Please contact administrator.');
            }
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    });
    document.title = config.documentTitle + "Business Partner";
  }

  vendorTypeDropdown = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });

    if (e.target.value === '0') {
      this.setState({
        rawData: this.state.vendorData
      });
      setTimeout(() => {
        this.fetchingFilteredData();
      });
    }
    else {
      var value = e.target.value;
      var filteredVendorData = this.state.vendorData.filter((e, index) => (e.vendor_type === value))
      this.setState({
        rawData: filteredVendorData
      });
      setTimeout(() => {
        this.fetchingFilteredData();
      });
    }
  }

  fetchingFilteredData() {
    this.fetchData(this.state.fetchDataState);
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    })
  };

  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0
    })
  };

  TablePaginationActions = () => {
    return (
      <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
        <IconButton
          onClick={() => this.setState({ page: 0 })}
          disabled={this.state.page === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page: this.state.page - 1 })}
          disabled={this.state.page === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page: this.state.page + 1 })}
          disabled={this.state.page === Math.ceil(this.state.customerData.length / this.state.rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page: Math.ceil(this.state.customerData.length / this.state.rowsPerPage) - 1 })}
          disabled={this.state.page === Math.ceil(this.state.customerData.length / this.state.rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  vendorTablePaginationActions = () => {
    return (
      <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
        <IconButton
          onClick={() => this.setState({ page1: 0 })}
          disabled={this.state.page1 === 0}
          aria-label="first page"
        >
          <FirstPage />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page1: this.state.page1 - 1 })}
          disabled={this.state.page1 === 0}
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page1: this.state.page1 + 1 })}
          disabled={this.state.page1 === Math.ceil(this.state.vendorData.length / this.state.rowsPerPage1) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={() => this.setState({ page1: Math.ceil(this.state.vendorData.length / this.state.rowsPerPage) - 1 })}
          disabled={this.state.page1 === Math.ceil(this.state.vendorData.length / this.state.rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPage />
        </IconButton>
      </div>
    );
  }

  TabsHandler = (event, value) => {
    this.setState({
      activeTabIndex: value
    });
  }

  openDailog = () => {
    this.setState({
      open: true
    });
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  }


  render() {
    const { customerData, vendorData, activeTabIndex } = this.state;
    var vendorCount = <h4 style={{ padding: '10px 20px', margin: '0px', color: activeTabIndex === 0 ? config.themeColor : '#6c7293', fontSize: '18px' }}>Vendor<span className="number_count">{vendorData.length}</span></h4>;
    var customerCount = <h4 style={{ padding: '10px 20px', margin: '0px', color: activeTabIndex === 1 ? config.themeColor : '#6c7293', fontSize: '18px' }}>Customer<span className="number_count">{customerData.length}</span></h4>;
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='clearfix content-header'>
            <div className='row'>
              <div className='col-sm text-left pl-0'>
                <h4 style={{ padding: '10px 20px', margin: '0px' }}>Business Partner list<span style={{ color: 'lightgrey', margin: '0px 5px' }}>|</span><span style={{ color: '#959cb6', fontSize: '15px' }}>{customerData.length + vendorData.length}</span></h4>
              </div>
              {!this.state.isLoading &&
                <div className='col text-right float-sm-left'>
                  <button onClick={this.openDailog} className='header_button header_button_text add_button_adjustment' style={{ top: 10 }}>Add</button>
                </div>
              }
            </div>
          </div>
          {this.state.isLoading &&
            <div className="lds-roller">
              <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
          }
          {!this.state.isLoading &&
            <div className='container p-3'>
              <div className='col-sm card p-0'>
                {this.state.activeTabIndex === 0 &&
                  <Paper>
                    <MaterialTable
                      title={
                        <Tabs
                          value={this.state.activeTabIndex}
                          onChange={this.TabsHandler}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          style={{ backgroundColor: '#ffffff', color: '#6c7293', padding: 0 }}
                          TabIndicatorProps={{ style: { background: config.themeColor } }}
                        >
                          <Tab label={vendorCount} value={0} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />
                          <Tab label={customerCount} value={1} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />
                        </Tabs>
                      }
                      columns={[
                        {
                          title: 'Name',
                          field: 'vendor_name',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <a className="view_link" href={'/view-vendor/' + btoa(row.id)}>{row.vendor_name}</a>
                            )
                          }
                        },
                        {
                          title: 'Type',
                          field: 'vendor_type',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <span>
                                {row.vendor_type === 'Coal' &&
                                  <span className="type_6">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Barge' &&
                                  <span className="type_2">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Stevedore' &&
                                  <span className="type_1">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Surveyor' &&
                                  <span className="type_4">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Vessel Agent' &&
                                  <span className="type_5">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Floating Crane' &&
                                  <span className="type_3">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Vessel Owner' &&
                                  <span className="type_1">
                                    {row.vendor_type}
                                  </span>
                                }
                                {row.vendor_type === 'Others' &&
                                  <span className="type_7">
                                    {row.vendor_type}
                                  </span>
                                }
                              </span>
                            )
                          }
                        },
                        {
                          title: 'Contact',
                          field: 'contact_person_name_1',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                                {row.contact_person_name_1 != null ?
                                  <span className="contact_short_icon">
                                    {row.contact_person_name_1.charAt(0)}
                                  </span>
                                  :
                                  ''
                                }
                                <span className="contact_details">
                                  {row.contact_person_name_1}<br />
                                  {row.contact_person_phone_1}
                                </span>
                              </div>
                            )
                          }
                        },
                        {
                          title: 'Action',
                          field: 'id',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <span>
                                {(this.state.features.includes('3')  || this.state.features.includes('97') ) &&
                                  <i className="fa fa-pencil" style={{ fontSize: 20, color: config.themeColor, cursor: 'pointer', marginRight: '10px' }} data-toggle="modal" title="Delete Advance Payment" data-placement="bottom" data-target="#updateAdvancePayment" data-backdrop="static" data-keyboard="false"
                                    onClick={() => window.location.href = '/update-vendor/' + btoa(row.id)}>
                                  </i>

                                  // <IconButton href={'/update-vendor/' + btoa(row.id)} className="edit_botton_link" style={{textTransform:"capitalize", height: 50}}>
                                  //   <EditIcon style={{fontSize:24, color: config.themeColor}} />
                                  // </IconButton>
                                }
                              </span>
                            )
                          },
                          sorting: false
                        },
                      ]}
                      data={this.state.vendorData}
                      isLoading={this.state.isLoading}
                      style={{ minHeight: 200, fontFamily: 'Poppins', overflowY: 'auto', boxShadow: '0 0 5px 0 rgba(0, 39, 77, 0.1)' }}
                      options={{
                        paginationType: "stepped",
                        pageSize: 20,
                        pageSizeOptions: [5, 10, 20, 100, 200],
                        headerStyle: {
                          backgroundColor: '#f9f9f9'
                        }
                      }}
                      actions={[
                        {
                          icon: 'save_alt',
                          tooltip: 'Export as Excel',
                          isFreeAction: true,
                          onClick: (event) => exportToExcel(this.state.vendorData, 'vendors')
                        }
                      ]}
                    // components={{
                    //   Action: props => (
                    //     <span><FontAwesomeIcon icon={faFileExcel}  style={{ margin: 5, fontSize: 20, color: '#0abb87', cursor: "pointer" }} onClick={(e) => exportToExcel(this.state.vendorData, "Vendors")} /></span>
                    //   ),
                    // }}
                    />
                    {/* <span><FontAwesomeIcon icon={faFileExcel} style={{ margin: 5, fontSize: 30, color: '#0abb87', cursor: "pointer" }} onClick={() => this.convertExcelHandler(this.state.vendorData, "")} /></span> */}
                  </Paper>
                }
                {this.state.activeTabIndex === 1 &&
                  <Paper>
                    <MaterialTable
                      title={
                        <Tabs
                          value={this.state.activeTabIndex}
                          onChange={this.TabsHandler}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          style={{ backgroundColor: '#ffffff', color: '#6c7293', padding: 0 }}
                          TabIndicatorProps={{ style: { background: config.themeColor } }}
                        >
                          <Tab label={vendorCount} value={0} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />
                          <Tab label={customerCount} value={1} style={{ fontFamily: 'Poppins', textTransform: 'capitalize', color: '#6c7293' }} />
                        </Tabs>
                      }
                      columns={[
                        {
                          title: 'Name',
                          field: 'customer_name',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <a className="view_link" href={'/view-customer/' + btoa(row.id)}>{row.customer_name}</a>
                            )
                          }
                        },
                        {
                          title: 'Type',
                          field: 'customer_type',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <span>
                                {row.customer_type === 'Local Trader' &&
                                  <span className="type_1">
                                    {row.customer_type}
                                  </span>
                                }
                                {row.customer_type === 'International Trader' &&
                                  <span className="type_2">
                                    {row.customer_type}
                                  </span>
                                }
                                {row.customer_type === 'End User' &&
                                  <span className="type_3">
                                    {row.customer_type}
                                  </span>
                                }
                                {row.customer_type === 'Others' &&
                                  <span className="type_7">
                                    {row.customer_type}
                                  </span>
                                }
                              </span>
                            )
                          }
                        },
                        {
                          title: 'Contact',
                          field: 'contact_person_name_1',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                                {row.contact_person_name_1 != null ?
                                  <span className="contact_short_icon">
                                    {row.contact_person_name_1.charAt(0)}
                                  </span>
                                  :
                                  ''
                                }
                                <span className="contact_details">
                                  {row.contact_person_name_1}<br />
                                  {row.contact_person_phone_1}
                                </span>
                              </div>
                            )
                          }
                        },
                        {
                          title: 'Action',
                          field: 'id',
                          headerStyle: {
                            fontFamily: 'Poppins',
                            textTransform: 'uppercase',
                            color: "rgb(108, 114, 147)"
                          },
                          render: row => {
                            return (
                              <span>
                                {(this.state.features.includes('3')  || this.state.features.includes('97') ) &&
                                  <i className="fa fa-pencil" style={{ fontSize: 20, color: config.themeColor, cursor: 'pointer', marginRight: '10px' }} data-toggle="modal" title="Delete Advance Payment" data-placement="bottom" data-target="#updateAdvancePayment" data-backdrop="static" data-keyboard="false"
                                    onClick={() => window.location.href = '/update-customer/' + btoa(row.id)}>
                                  </i>
                                }
                              </span>
                            )
                          },
                          sorting: false
                        },
                      ]}
                      data={this.state.customerData}
                      isLoading={this.state.isLoading}
                      style={{ minHeight: 200, fontFamily: 'Poppins', overflowY: 'auto', boxShadow: '0 0 5px 0 rgba(0, 39, 77, 0.1)' }}
                      options={{
                        // paginationType:"stepped",
                        pageSize: 20,
                        pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      }}
                      actions={[
                        {
                          icon: 'save_alt',
                          tooltip: 'Export as Excel',
                          isFreeAction: true,
                          onClick: (event) => exportToExcel(this.state.customerData, 'customers')
                        }
                      ]}
                    // components={{
                    //   Action: props => (
                    //     <span><FontAwesomeIcon icon={faFileExcel}  style={{ margin: 5, fontSize: 20, color: '#0abb87', cursor: "pointer" }} onClick={(e) => exportToExcel(this.state.customerData, "Customers")} /></span>
                    //   ),
                    // }}
                    />
                  </Paper>
                }
              </div>
              <Dialog
                onClose={this.handleClose}
                open={this.state.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle style={{ width: '450px' }}>
                  <span className="popup_title">
                    Select type of Business Partner
                  </span>
                </DialogTitle>
                <DialogContent style={{ margin: '10px auto 25px' }}>
                  <div style={{ flexDirection: 'row', display: 'inline-flex' }}>
                    <a href="/add-vendor" className="link">
                      <Paper className="popup_block">
                        <img src={VendorIcon} alt="Vendor Icon" className="popup_block_image" />
                        <span className="popup_name">
                          Vendor
                        </span>
                      </Paper>
                    </a>
                    <a href="/add-customer">
                      <Paper className="popup_block">
                        <img src={CustomerIcon} alt="Customer Icon" className="popup_block_image" />
                        <span className="popup_name">
                          Customer
                        </span>
                      </Paper>
                    </a>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(BusinessPartner);