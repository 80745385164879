import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  MenuItem,
  TextField,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default class MvCoalSalesChargesAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "IDR",
          currency_xchg_rate: "1",
          payment_to: "0",
          payee_name: "",
          invoice_date: null,
          invoice_no: "",
          ppn: "",
          pph23_account: "0",
          barge_quantity: "",
          base_price_pmt: "",
          base_price: "",
          vat_percent: "10",
          vat_value: "",
          pph23_percent: "2",
          pph23_value: "",
          payable_to_vendor: "",
          cost_to_company: "",
          remarks: "",
        },
      ],
      vendorTypeAsOtherMenu: [],
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Sales_Fin_Csc",
        },
      ],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("282") || features.includes("97"))) {
        window.location.href = "/dashboard";
      } else {
        const loginUserID = this.Cookie.getCookie("loginUserId");
        const idToken = this.Cookie.getIdTokenCookie();
        const businessNoID = this.props.businessNoID;
        // calling Vendors Api
        const status = "Active";
        const vendorTypeAsOther = this.state.vendorTypeAsOtherMenu;

        await api.getVendors(loginUserID, idToken, status).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.vendors) {
                  for (var i of res.vendors) {
                    vendorTypeAsOther.push({
                      name: i.vendor_name,
                      value: i.id,
                      type: "Vendor",
                    });
                  }
                  this.setState({
                    vendorTypeAsOtherMenu: vendorTypeAsOther,
                  });
                } else {
                  alert(
                    "Unexpected error occured. Please contact administrator."
                  );
                }
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });

        await api
          .getCustomers(loginUserID, idToken, status)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
                if (res.code === "200") {
                  if (res.customers) {
                    for (var i of res.customers) {
                      vendorTypeAsOther.push({
                        name: i.customer_name,
                        value: i.id,
                        type: "Customer",
                      });
                    }
                    this.setState({
                      vendorTypeAsOtherMenu: vendorTypeAsOther,
                    });
                  } else {
                    alert(
                      "Unexpected error occured. Please contact administrator."
                    );
                  }
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    if (
                      res.business_number.consignment_type === "Mother Vessel"
                    ) {
                      //Get Business No Operational Mother Vessel list
                      api
                        .getBusinessNumberMotherVessel(
                          businessNoID,
                          loginUserID,
                          idToken
                        )
                        .then((response) => {
                          if (response.status >= 200 && response.status < 300) {
                            response.json().then((res) => {
                              if (res.idtoken)
                                this.Cookie.setIdTokenCookie(res.idtoken);
                              if (res.code === "200") {
                                if (res.business_no_MV.length > 0) {
                                  var motherVesselID = res.business_no_MV[0].id;
                                  //Get Business No Operational Mother Vessel Details
                                  api
                                    .getBusinessNoOpMVDetails(
                                      motherVesselID,
                                      loginUserID,
                                      idToken
                                    )
                                    .then((response) => {
                                      if (
                                        response.status >= 200 &&
                                        response.status < 300
                                      ) {
                                        response.json().then((res) => {
                                          if (res.business_no_MV) {
                                            var row = this.state.multiRows;
                                            if (row.length > 0) {
                                              row[0].barge_quantity = res
                                                .business_no_MV
                                                .vessel_final_quantity_in_mt
                                                ? res.business_no_MV
                                                    .vessel_final_quantity_in_mt
                                                : "";
                                            }
                                            this.setState({
                                              motherVesselID: motherVesselID,
                                              vessel_type: res.business_no_MV
                                                .vessel_type
                                                ? res.business_no_MV.vessel_type
                                                : "",
                                              vessel_final_quantity_in_mt: res
                                                .business_no_MV
                                                .vessel_final_quantity_in_mt
                                                ? res.business_no_MV
                                                    .vessel_final_quantity_in_mt
                                                : "",
                                            });
                                          } else {
                                            alert(
                                              "Unexpected error occured. Please contact administrator."
                                            );
                                          }
                                        });
                                      }
                                    });
                                }
                              } else {
                                alert(
                                  "Unexpected error occured. Please contact administrator."
                                );
                              }
                            });
                          }
                        });
                    }
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      isLoading: false,
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });
      }
    }

    //document.title = config.documentTitle + "Mother Vessel Sales Financial Coal Sales Charges";
  }

  onPriceCalculation = (id) => {
    var row = this.state.multiRows;
    var barge_quantity = row[id].barge_quantity;
    var base_price_pmt = row[id].base_price_pmt;
    var totalBasePrice = Number(barge_quantity) * Number(base_price_pmt);
    var base_price = Number(totalBasePrice).toFixed(4);
    var vat_percent = row[id].vat_percent;
    var vat_value = ((Number(base_price) * Number(vat_percent)) / 100).toFixed(
      2
    );
    var pph23_percent = row[id].pph23_percent;
    var pph23_value = (
      (Number(base_price) * Number(pph23_percent)) /
      100
    ).toFixed(2);
    var cost_to_company = 0;
    var payable_to_vendor = (Number(base_price) + Number(vat_value)).toFixed(2);
    if (
      config.company === "SRPL" ||
      config.company === "AVS" ||
      config.company === "PRPL"
    ) {
      cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
    } else {
      if (row[id].pph23_account === "Supplier") {
        payable_to_vendor = (
          Number(base_price) +
          Number(vat_value) -
          Number(pph23_value)
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(vat_value)).toFixed(2);
      } else if (row[id].pph23_account === "SDAM") {
        payable_to_vendor = Number(totalBasePrice).toFixed(2);
        base_price = (Number(payable_to_vendor * 100) / 98).toFixed(2);
        pph23_value = (
          (Number(base_price) * Number(pph23_percent)) /
          100
        ).toFixed(2);
        cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
      }
    }

    row[id].base_price = base_price;
    row[id].vat_percent = vat_percent;
    row[id].vat_value = vat_value;
    row[id].pph23_percent = pph23_percent;
    row[id].pph23_value = pph23_value;
    row[id].payable_to_vendor = payable_to_vendor;
    row[id].cost_to_company = cost_to_company;

    this.setState({
      multiRows: row,
    });
  };

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  submitHandler = (e) => {
    this.setState({ isLoading: true });
    var { multiRows } = this.state;

    // for (var i in multiRows) {
    //   if (multiRows[i].payment_to === "Others") {
    //     multiRows[i].payment_to = null;
    //   }
    // }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    // Add barge purchase financial coal procurement charge api
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;
    api
      .add_mv_sales_fin_csc(
        loginUserID,
        idToken,
        businessNoID,
        multiRows,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  sucessMsg: res.message,
                },
                () => {
                  setTimeout(() => {
                    this.props.onAddResponse({
                      screen: "MvCsc",
                      code: res.code,
                      message: "",
                    });
                  }, 2000);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                isLoading: false,
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
  };

  render() {
    const quan = /^\d*(\.\d{0,3})?$/;
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Add Coal Sales Charges</h4>
            </div>
          </div>
          <div className="drawer-section" style={{ minHeight: "86vh" }}>
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <div className="row" key={idx}>
                    <div
                      className={
                        this.state.multiRows.length > 1
                          ? "col-lg-11 border_dashed rounded-lg p-2 mb-3"
                          : "col-lg-12 border_dashed rounded-lg p-2 mb-3"
                      }
                    >
                      <div className="row mb-3 ">
                        <div className="col-lg-5 pl-0">
                          <div className="row">
                            <div className="col-lg-12 mb-1 p-0">
                              <label className="form_label mb-0">
                                Payment to
                              </label>
                              <Autocomplete
                                options={this.state.vendorTypeAsOtherMenu}
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, { selected }) => (
                                  <React.Fragment>
                                    <div className="col-lg p-0 m-0">
                                      {option.name}
                                    </div>
                                  </React.Fragment>
                                )}
                                onChange={(event, value) => {
                                  let row = this.state.multiRows;
                                  row[idx].payment_to = value.value;
                                  row[idx].name = value.name;
                                  row[idx].business_partner_type = value.type;
                                  this.setState({
                                    multiRows: row,
                                  });
                                }}
                                disableCloseOnSelect
                                name="payment_to"
                                size="small"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                                style={{ display: "contents" }}
                              />
                            </div>

                            <div className="col-lg-6 mb-1 p-0">
                              <label className="form_label mb-0">
                                Quantity in MT
                              </label>
                              <TextField
                                name="barge_quantity"
                                margin="dense"
                                type="number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Quantity in MT"
                                value={e.barge_quantity}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    var row = this.state.multiRows;
                                    row[idx].barge_quantity = e.target.value;
                                    this.setState(
                                      {
                                        multiRows: row,
                                      },
                                      () => this.onPriceCalculation(idx)
                                    );
                                  }
                                }}
                              />
                            </div>

                            <div className="col-lg-6 mb-1 pr-0">
                              <label className="form_label mb-0">
                                Base Price PMT
                              </label>
                              <TextField
                                name="base_price_pmt"
                                margin="dense"
                                type="number"
                                variant="outlined"
                                fullWidth
                                placeholder="Base Price PMT"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </InputAdornment>
                                  ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                value={e.base_price_pmt}
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    var row = this.state.multiRows;
                                    row[idx].base_price_pmt = e.target.value;
                                    this.setState(
                                      {
                                        multiRows: row,
                                      },
                                      () => this.onPriceCalculation(idx)
                                    );
                                  }
                                }}
                              />
                            </div>

                            {(config.company === "SDAM" ||
                              config.company === "SRK" ||
                              config.company === "BTR") && (
                              <div className="col-lg-12 mb-1 p-0">
                                <label className="form_label mb-0">
                                  PPH23 Account
                                </label>
                                <TextField
                                  name="pph23_account"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  select
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={e.pph23_account}
                                  onChange={(e) => {
                                    var row = this.state.multiRows;
                                    row[idx].pph23_account = e.target.value;
                                    row[idx].pph23_percent =
                                      e.target.value === "SDAM" ? 2 : 2;
                                    this.setState(
                                      {
                                        multiRows: row,
                                      },
                                      () => this.onPriceCalculation(idx)
                                    );
                                  }}
                                >
                                  <MenuItem value="0">Please Select</MenuItem>
                                  <MenuItem value="Supplier">Supplier</MenuItem>
                                  <MenuItem value="SDAM">SDAM</MenuItem>
                                </TextField>
                              </div>
                            )}
                          </div>
                        </div>

                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") &&
                          e.pph23_account !== "0" &&
                          e.pph23_account === "Supplier" && (
                            <div
                              className="col-lg-7 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-4 p-0 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? this.toLocaleString(e.base_price) : "-"}</div> */}
                                  <TextField
                                    name="base_price"
                                    margin="dense"
                                    type="number"
                                    //variant='outlined'
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.base_price}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        let vat_value = Number(
                                          (Number(e.target.value) *
                                            Number(row[idx].vat_percent)) /
                                            100
                                        ).toFixed(2);
                                        let pph23_value = Number(
                                          (
                                            (Number(e.target.value) *
                                              Number(row[idx].pph23_percent)) /
                                            100
                                          ).toFixed(2)
                                        );
                                        let payable_to_vendor = (
                                          Number(e.target.value) +
                                          Number(vat_value) -
                                          Number(pph23_value)
                                        ).toFixed(2);
                                        let cost_to_company = (
                                          Number(e.target.value) +
                                          Number(vat_value)
                                        ).toFixed(2);
                                        row[idx].base_price = e.target.value;
                                        row[idx].vat_value = vat_value;
                                        row[idx].pph23_value = pph23_value;
                                        row[idx].payable_to_vendor =
                                          payable_to_vendor;
                                        row[idx].cost_to_company =
                                          cost_to_company;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-4 pr-0 mb-2">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.dc_vat_percent ? this.toLocaleString(e.dc_vat_percent) : "-"}</div> */}
                                  <TextField
                                    name="vat_percent"
                                    margin="dense"
                                    type="number"
                                    //variant='outlined'
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.vat_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        let vat_value = Number(
                                          (Number(row[idx].base_price) *
                                            Number(e.target.value)) /
                                            100
                                        ).toFixed(2);
                                        let payable_to_vendor = (
                                          Number(row[idx].base_price) +
                                          Number(vat_value) -
                                          Number(row[idx].pph23_value)
                                        ).toFixed(2);
                                        let cost_to_company = (
                                          Number(row[idx].base_price) +
                                          Number(vat_value)
                                        ).toFixed(2);
                                        row[idx].vat_percent = e.target.value;
                                        row[idx].vat_value = vat_value;
                                        row[idx].payable_to_vendor =
                                          payable_to_vendor;
                                        row[idx].cost_to_company =
                                          cost_to_company;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-4 pr-0 mb-2">
                                  <label
                                    className="form_label mb-2"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.vat_value
                                      ? this.toLocaleString(e.vat_value)
                                      : "-"}
                                  </div>
                                  {/* <TextField
                                name='dc_vat_value'
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px'
                                  }
                                }}
                                value={e.dc_vat_value}
                                onChange={(e) => {
                                  if (e.target.value === '' || pr.test(e.target.value)) {
                                    var row = this.state.multiRows;
                                    let pph23_value = ((Number(row[idx].base_price) * Number(row[idx].dc_pph23_percent)) / 100).toFixed(0);
                                    let payable_to_vendor = (Number(row[idx].base_price) + Number(e.target.value) - Number(pph23_value)).toFixed(2);
                                    let cost_to_company = (Number(row[idx].base_price) + Number(e.target.value)).toFixed(2);
                                    row[idx].dc_vat_value = e.target.value;
                                    row[idx].pph23_value = pph23_value;
                                    row[idx].payable_to_vendor = payable_to_vendor;
                                    row[idx].cost_to_company = cost_to_company;
                                    this.setState({
                                      multiRows: row
                                    });
                                  }
                                }}
                              /> */}
                                </div>
                                <div className="col-lg-6 p-0 mb-3">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    {" "}
                                    PPH23 %
                                  </label>
                                  {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div> */}
                                  <TextField
                                    name="pph23_percent"
                                    margin="dense"
                                    type="number"
                                    //variant='outlined'
                                    fullWidth
                                    InputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    value={e.pph23_percent}
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row = this.state.multiRows;
                                        let pph23_value = Number(
                                          (
                                            (Number(row[idx].base_price) *
                                              Number(e.target.value)) /
                                            100
                                          ).toFixed(2)
                                        );
                                        let payable_to_vendor = (
                                          Number(row[idx].base_price) +
                                          Number(row[idx].vat_value) -
                                          Number(pph23_value)
                                        ).toFixed(2);
                                        let cost_to_company = (
                                          Number(row[idx].base_price) +
                                          Number(row[idx].vat_value)
                                        ).toFixed(2);
                                        row[idx].pph23_percent = e.target.value;
                                        row[idx].pph23_value = pph23_value;
                                        row[idx].cost_to_company =
                                          cost_to_company;
                                        row[idx].payable_to_vendor =
                                          payable_to_vendor;
                                        this.setState({
                                          multiRows: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                                <div className="col-lg-6 pr-0 mb-3">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Includes PPH23 @{" "}
                                    {e.pph23_percent
                                      ? e.pph23_percent + "%"
                                      : null}
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.pph23_value
                                      ? this.toLocaleString(e.pph23_value)
                                      : "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-6 p-0">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.payable_to_vendor
                                      ? this.toLocaleString(e.payable_to_vendor)
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div className="col-lg-6 pr-0">
                                  <label
                                    className="form_label mb-0"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {e.cost_to_company
                                      ? this.toLocaleString(e.cost_to_company)
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {e.pph23_account === "SDAM" && (
                          <div
                            className="col-lg-7 p-2 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-6 p-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.base_price
                                    ? this.toLocaleString(e.base_price)
                                    : "-"}
                                </div>
                              </div>
                              {/* <div className='col-6 pr-0 mb-2'>
                                <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT %</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_vat_percent ? this.toLocaleString(p.dc_vat_percent) : "-"}</div>
                              </div>
                              <div className='col-6 p-0 mb-2'>
                                <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>VAT</label>
                                <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.vat_value ? this.toLocaleString(e.vat_value) : "-"}</div>
                              </div> */}
                              <div className="col-lg-6 pr-0 mb-3">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Includes PPH23 @{" "}
                                  {e.pph23_percent
                                    ? e.pph23_percent + " %"
                                    : null}
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.pph23_value
                                    ? this.toLocaleString(e.pph23_value)
                                    : "-"}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.payable_to_vendor ? this.toLocaleString(e.payable_to_vendor) : "-"}</div> */}
                                <TextField
                                  name="payable_to_vendor"
                                  margin="dense"
                                  type="number"
                                  //variant='outlined'
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={e.payable_to_vendor}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      let base_price = (
                                        (Number(e.target.value) * 100) /
                                        98
                                      ).toFixed(2);
                                      let pph23_value = (
                                        (Number(base_price) *
                                          Number(row[idx].pph23_percent)) /
                                        100
                                      ).toFixed(2);
                                      var cost_to_company = (
                                        Number(base_price) + Number(pph23_value)
                                      ).toFixed(2);
                                      row[idx].payable_to_vendor =
                                        e.target.value;
                                      row[idx].base_price = base_price;
                                      row[idx].pph23_value = pph23_value;
                                      row[idx].cost_to_company =
                                        cost_to_company;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.cost_to_company
                                    ? this.toLocaleString(e.cost_to_company)
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL") && (
                          <div
                            className="col-lg-7 p-3 rounded"
                            style={{ backgroundColor: "#f7f3f0" }}
                          >
                            <div className="row col-lg-12 p-0">
                              <h6>Price</h6>
                            </div>
                            <div className="row">
                              <div className="col-4 p-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Base price
                                </label>
                                {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.base_price ? this.toLocaleString(e.base_price) : "-"}</div> */}
                                <TextField
                                  name="base_price"
                                  margin="dense"
                                  type="number"
                                  //variant='outlined'
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={e.base_price}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      var vat_value = Number(
                                        (Number(e.target.value) *
                                          Number(row[idx].vat_percent)) /
                                          100
                                      ).toFixed(2);
                                      var cost_to_company = (
                                        Number(e.target.value) +
                                        Number(vat_value)
                                      ).toFixed(2);
                                      row[idx].base_price = e.target.value;
                                      row[idx].vat_value = vat_value;
                                      row[idx].cost_to_company =
                                        cost_to_company;
                                      row[idx].payable_to_vendor =
                                        cost_to_company;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-4 pr-0 mb-2">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT %
                                </label>
                                {/* <div className='contract_display_header_value' style={{ color: '#42352d' }}>{p.dc_vat_percent ? this.toLocaleString(p.dc_vat_percent) : "-"}</div> */}
                                <TextField
                                  name="vat_percent"
                                  margin="dense"
                                  type="number"
                                  //variant='outlined'
                                  fullWidth
                                  InputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  value={e.vat_percent}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row = this.state.multiRows;
                                      var vat_value = Number(
                                        (Number(row[idx].base_price) *
                                          Number(e.target.value)) /
                                          100
                                      ).toFixed(2);
                                      var cost_to_company = (
                                        Number(row[idx].base_price) +
                                        Number(vat_value)
                                      ).toFixed(0);
                                      row[idx].vat_percent = e.target.value;
                                      row[idx].vat_value = vat_value;
                                      row[idx].cost_to_company =
                                        cost_to_company;
                                      row[idx].payable_to_vendor =
                                        cost_to_company;
                                      this.setState({
                                        multiRows: row,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <div className="col-4 pr-0 mb-2">
                                <label
                                  className="form_label mb-2"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  VAT
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.vat_value
                                    ? this.toLocaleString(e.vat_value)
                                    : "-"}
                                </div>
                              </div>
                              {/* <div className='col-lg-6 mb-3'>
                              <label className="form_label mb-0" style={{ color: 'rgba(72, 59, 52, 0.7)' }}>Includes PPH23 @ {e.pph23_percent ? (e.pph23_percent + ' %') : null}</label>
                              <div className='contract_display_header_value' style={{ color: '#42352d' }}>{e.pph23_value ? this.toLocaleString(e.pph23_value) : "-"}</div>
                            </div> */}
                            </div>

                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Payable to Vendor
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.payable_to_vendor
                                    ? this.toLocaleString(e.payable_to_vendor)
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                                {/* <TextField
                                name='payable_to_vendor'
                                margin='dense'
                                type="number"
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                  style: {
                                    fontFamily: 'Poppins',
                                    fontSize: '14px'
                                  }
                                }}
                                value={e.payable_to_vendor}
                                onChange={(e) => {
                                  if (e.target.value === '' || pr.test(e.target.value)) {
                                    var row = this.state.multiRows;
                                    let base_price = ((Number(e.target.value) * 100) / 98).toFixed(2);
                                    let pph23_value = (Number(base_price) * Number(row[idx].pph23_percent) / 100).toFixed(2)
                                    var cost_to_company = (Number(base_price) + Number(pph23_value)).toFixed(2);
                                    row[idx].payable_to_vendor = e.target.value;
                                    row[idx].base_price = base_price;
                                    row[idx].pph23_value = pph23_value;
                                    row[idx].cost_to_company = cost_to_company;
                                    this.setState({
                                      multiRows: row
                                    });
                                  }
                                }}
                              /> */}
                              </div>
                              <div className="col-lg-6 pr-0">
                                <label
                                  className="form_label mb-0"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  Cost to Company
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {e.cost_to_company
                                    ? this.toLocaleString(e.cost_to_company)
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-12 p-0">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            placeholder="Remarks"
                            value={e.remarks}
                            multiline
                            rows={3}
                            onChange={(e) => {
                              var row = this.state.multiRows;
                              row[idx].remarks = e.target.value;
                              this.setState({
                                multiRows: row,
                              });
                            }}
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* {(this.state.features.includes('525')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  feature_name="Mv_Sales_Fin_Csc"
                  callbackFn={(data) => this.setState({ files: data })}
                />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
