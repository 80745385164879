import React, { useEffect, useState } from 'react'

import {
  TextField, Radio, RadioGroup, FormControlLabel,
  MenuItem, IconButton, InputAdornment,
} from '@material-ui/core';
import Loader from '../../common/Loader';
import CookieHandler from '../../common/CookieHandler';
import api from '../../../api/api';
import config from '../../../config/config';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { dateFormateToDB } from '../../common/common';
import FileUpload from '../../common/FileUpload';
import { withStyles } from '@material-ui/core/styles';

import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';


const NewRadio = withStyles({
  root: {
    color: '#ccc',
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


const Cookie = new CookieHandler();

export default function BargeAgreementUpdate(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(null);
  const [vendorDataForMenu, setBargeVendors] = useState([]);
  const [currencyData, setCurrencies] = useState([]);
  const [files, setFiles] = useState([]);
  const { agreementID = null } = props || {};

  const features = Cookie.getCookie("features");
  useEffect(() => {

    if (!Cookie.isLoggedIn()) {
      window.location.href = '/logout';
    }
    if (!(features.includes('557') || features.includes('97'))) {
      window.location.href = '/dashboard';
    }
    async function fetchData() {


      await api.getCurrencies(Cookie.getCookie('loginUserId'), Cookie.getIdTokenCookie()).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              var currencies = [<MenuItem value='0' key={-1} disabled>Please Select</MenuItem>];
              res.Currency.forEach(item => {
                currencies.push(<MenuItem value={item.currency} key={[item.id]}>{item.currency}</MenuItem>);
              });
              setCurrencies(currencies);
            }
          })
        }
      });

      await api.getVendors(Cookie.getCookie('loginUserId'), Cookie.getIdTokenCookie(), "Active").then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              const vendors_menu = [];

              if (res.vendors) {
                res.vendors.filter(e => e.vendor_type === "Barge").forEach(item => {
                  vendors_menu.push({ name: item.vendor_name, value: item.id, key: item.id });
                })
                setBargeVendors(vendors_menu)
              }
            }
          })
        }
      })

      let request = {
        login_user_id: Cookie.getCookie('loginUserId'),
        idtoken: Cookie.getIdTokenCookie(),
        barging_agreement_id: agreementID
      }
      await api.get_barging_agreement(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === '200') {
              if (res.barging_agreement) {
                const { files = [], barge_vendor_name, barge_vendor_id } = res.barging_agreement;
                let stateCopy = {
                  ...state,
                  ...res.barging_agreement,
                  vendor_drop_value: { name: barge_vendor_name, value: barge_vendor_id }
                }
                setState(stateCopy);
                setFiles(files)
                setIsLoading(false);
              }

            }
          })
        }
      })
    }

    fetchData();

  }, [agreementID]);


  const RemoveMultiRowsHandler = (idx) => {

    const items = [...state?.barging_agreement_price_list];
    items.splice(idx, 1);

    let stateCopy = {
      ...state,
      barging_agreement_price_list: items
    }

    setState(stateCopy);

  }

  const submitHandler = () => {
    let { agreement_date, barging_agreement_price_list,
      barge_vendor_id, currency } = state;

    // files validation
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = files;

    let stateCopy = {
      ...state,
    }

    if (barge_vendor_id === null || barge_vendor_id === '') {
      stateCopy['barge_vendor_error'] = true;
      missing_fields++;
    }

    if (agreement_date === null || agreement_date === '') {
      stateCopy['agreement_date_error'] = true;
      missing_fields++;
    }

    if (currency === "0" || currency === '') {
      stateCopy['currency_error'] = true;
      missing_fields++;
    }

    for (var i of barging_agreement_price_list) {
      if (i.area === null || i.area === '') {
        i['area_error'] = true;
        missing_fields++;

      }
      if (i.price === null || i.price === '') {
        i['price_error'] = true;
        missing_fields++;
      }
    }


    for (var obj of files_data) {
      if (obj.file_type !== '' || obj.file_content !== '') {
        if (obj.file_content !== '') {
          if (obj.file_description === '') {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== '') {
          if (obj.file_content === '') {
            files_missing++;
          }
        }
      }
    }

    setFiles(files);

    if (missing_fields > 0) {
      stateCopy['barging_agreement_price_list'] = barging_agreement_price_list;
      setState(stateCopy);
      // this.setState({
      //   barging_agreement_price_list: barging_agreement_price_list,
      //   snackBarErrorOpen: true,
      //   errorMsg: "Please fill mandatory fields.",
      //   submitLoading: false,
      // })
      return;
    }
    if (files_missing > 0) {
      // this.setState({
      //   snackBarErrorOpen: true,
      //   errorMsg: "Please Select file.",
      //   submitLoading: false
      // });
      return;
    }

    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");

    let request = {
      login_user_id: Cookie.getCookie('loginUserId'),
      idtoken: Cookie.getIdTokenCookie(),
      ...state,
      barging_agreement_id: props.agreementID,
      existing_file_ids: existing_file_ids,
      files: content_files,
    }

    api.update_barging_agreement(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            props.onCancel(true);
          }
        })
      }
    })

  }

  return (
    <>
      <div
        className="container bg-white p-0"
        style={{ minHeight: "100vh" }}
      >
        <div className="clearfix content-header border-bottom">
          <div className="row">
            <div className="col-lg-10 text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Barging Agreement - ${state?.fixture_note_no} `}
                <span
                  className={
                    state?.status === "Active"
                      ? "type_1"
                      : "type_2"
                  }
                  style={{ padding: 8, marginLeft: 16 }}
                >
                  {state?.status}
                </span>
              </h4>
            </div>
          </div>
        </div>
        <div className="drawer-section">
          {isLoading ? <Loader /> :
            <div className="card">
              <div className='card-body'>

                <div className='row mb-3'>
                  <div className='col-lg-4'>
                    <label className="form_label mb-0">
                      Barge Vendor <span className='text-danger'>*</span>
                    </label>
                    <Autocomplete
                      options={vendorDataForMenu}
                      getOptionLabel={option => option.name}
                      onChange={(event, value) => {
                        let stateCopy = {
                          ...state,
                        }
                        if (value) {
                          stateCopy['barge_vendor_id'] = value.value;
                          stateCopy['barge_vendor_name'] = value.name;
                          stateCopy['barge_vendor_error'] = false;
                          stateCopy['vendor_drop_value'] = { name: value.name, value: value.value };
                        }
                        else {
                          stateCopy['barge_vendor_id'] = null;
                          stateCopy['barge_vendor_name'] = null;
                          stateCopy['vendor_drop_value'] = { name: '', value: '' };
                        }
                        setState(stateCopy)
                      }}
                      InputProps={{
                        style: {
                          fontFamily: 'Poppins',
                          fontSize: '14px'
                        }
                      }}
                      size="small"
                      name='purchaseContract'
                      value={state?.vendor_drop_value}
                      renderOption={option => (
                        <React.Fragment>
                          <div className='col-lg-12 p-0 m-0'>
                            <label className='form_label mb-0 mb-0'>{option.name}</label>
                          </div>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Vendors"
                          variant="outlined"
                          fullWidth
                          error={state?.barge_vendor_error}
                          style={{ top: 8 }}
                        />
                      )}
                    />
                  </div>
                  <div className='col-lg-4'>
                    <label className="form_label mb-0">
                      Agreement Date <span className='text-danger'>*</span>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk={true}
                        inputVariant="outlined"
                        variant="inline"
                        margin="dense"
                        fullWidth
                        format="dd/MM/yyyy"
                        value={state?.agreement_date}
                        onChange={(date) => {
                          let stateCopy = {
                            ...state,
                            agreement_date: dateFormateToDB(date),
                            agreement_date_error: false,
                          }
                          setState(stateCopy);
                        }}
                        error={state?.agreement_date_error}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          className: 'pl-0'
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className='col-lg-4'>
                    <label className="form_label mb-0">
                      Currency <span className='text-danger'>*</span>
                    </label>
                    <TextField
                      name='currency'
                      variant='outlined'
                      margin='dense'
                      select
                      value={state?.currency}
                      onChange={(e) => {
                        let stateCopy = {
                          ...state,
                          currency: e.target.value,
                          currency_error: false,
                        }
                        setState(stateCopy)
                      }}
                      error={state?.currency_error}
                      fullWidth
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    >
                      {currencyData}
                    </TextField>
                  </div>

                </div>

                {state?.barging_agreement_price_list.length > 0 &&
                  <div className="table-responsive">

                    <table className="table table-sm">
                      <thead className="table-header">
                        <tr>
                          <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 200 }}>Area <span className='text-danger'>*</span></th>
                          <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 150 }}>Price <span className='text-danger'>*</span></th>
                          <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 240 }}>Barging Type</th>
                          <th className='text-center border  text-uppercase ' nowrap="true" style={{ minWidth: 240 }}>VAT Applicability (PPN)</th>
                          {state?.barging_agreement_price_list.length > 1 &&
                            <th style={{ minWidth: 80 }}></th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {state?.barging_agreement_price_list?.map((e, idx) => (
                          <tr key={e.price_list_id}>
                            <td nowrap="true" className='text-center text-uppercase border'>
                              <TextField
                                name='area'
                                value={e.area}
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                error={e.area_error}
                                placeholder='Area'
                                onChange={(e) => {
                                  let row = state?.barging_agreement_price_list;
                                  row[idx].area = e.target.value;
                                  row[idx].area_error = false;
                                  let stateCopy = {
                                    ...state,
                                    barging_agreement_price_list: row
                                  }
                                  setState(stateCopy)
                                }}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    textTransform: 'uppercase'
                                  },
                                }}
                              >
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-right border'>
                              <TextField
                                name='price'
                                value={e.price}
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                type='number'
                                error={e.price_error}
                                placeholder='Enter Value'
                                onChange={(e) => {
                                  let row = state?.barging_agreement_price_list;
                                  row[idx].price = e.target.value;
                                  row[idx].price_error = false;
                                  let stateCopy = {
                                    ...state,
                                    barging_agreement_price_list: row
                                  }
                                  setState(stateCopy)
                                }}

                              >
                              </TextField>
                            </td>
                            <td nowrap="true" className='text-center border'>
                              <RadioGroup aria-label="barging_type" name="barging_type" onChange={(e) => {
                                let row = state?.barging_agreement_price_list;
                                row[idx].barging_type = e.target.value
                                let stateCopy = {
                                  ...state,
                                  barging_agreement_price_list: row
                                }
                                setState(stateCopy)
                              }} style={{ flexDirection: 'row' }}>
                                <FormControlLabel value="Lumpsum" control={<NewRadio checked={e.barging_type === "Lumpsum"} />} label="Lumpsum" />
                                <FormControlLabel value="Per MT" control={<NewRadio checked={e.barging_type === "Per MT"} />} label="Per MT" />
                              </RadioGroup>
                            </td>
                            <td nowrap="true" className='text-center border'>
                              <RadioGroup aria-label="vat_incl_or_excl" name="vat_incl_or_excl" onChange={(e) => {
                                let row = state?.barging_agreement_price_list;
                                row[idx].vat_incl_or_excl = e.target.value
                                let stateCopy = {
                                  ...state,
                                  barging_agreement_price_list: row
                                }
                                setState(stateCopy)
                              }} style={{ flexDirection: 'row' }}>
                                <FormControlLabel value="Exclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Exclusive"} />} label="Exclusive" />
                                <FormControlLabel value="Inclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Inclusive"} />} label="Inclusive" />
                              </RadioGroup>
                            </td>
                            {state?.barging_agreement_price_list.length > 1 &&

                              <td className='text-center border'>
                                <i className="fa fa-trash-o m-auto" onClick={() => RemoveMultiRowsHandler(idx)} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                              </td>
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                }
                {/* {state?.barging_agreement_price_list.map((e, idx) => (
                  <div className='row mb-3' key={e.purchase_contract_id}>

                    <div className='col-lg-11 p-0' >
                      <div className='card-body border_dashed'>

                        <div className='row'>
                          <div className='col-lg-4'>
                            <label className='form_label mb-0 mb-0'>Area <span className='text-danger'>*</span></label>
                            
                          </div>
                          <div className='col-lg-4'>
                            <label className='form_label mb-0 mb-0'>Price <span className='text-danger'>*</span></label>
                            
                          </div>

                          <div className='col-lg-4 p-0'>
                            <label className='form_label mb-0 mb-0'>Barging Type</label>
                                
                                                 </div>
                          <div className='col-lg-6'>
                            <label className='form_label mb-0 mb-0'>VAT Applicability</label>
                                                     </div>

                        </div>
                      </div>
                    </div>

                    {(state?.barging_agreement_price_list.length > 1) &&
                      <div className='col-lg-1 d-flex justify-content-center m-auto'>
                        <div className="icon_hover text-align-center">
                        </div>
                      </div>
                    }
                  </div>
                ))} */}
                <div className='row mt-2 mb-2'>
                  <button type='button' onClick={() => {
                    const item =
                    {
                      area: '', price: '',
                      barging_type: 'Lumpsum',
                      vat_incl_or_excl: 'Exclusive',
                    }

                    const items = [...state?.barging_agreement_price_list, item];

                    let stateCopy = {
                      ...state,
                      barging_agreement_price_list: items
                    }
                    setState(stateCopy)
                  }}
                    className='header_button header_button_text addrow_button_adjustment' style={{ color: config.themeColor }}>Add More
                  </button>
                </div>

                <div className='row mb-3'>
                  <div className='col-lg-12 p-0'>
                    <label className='form_label mb-0'>Remarks</label>
                    <TextField
                      name='remarks'
                      margin='dense'
                      variant='outlined'
                      multiline
                      rows={4}
                      placeholder='Remarks'
                      value={state?.remarks}
                      onChange={(e) => {
                        let stateCopy = {
                          ...state,
                          remarks: e.target.value
                        }
                        setState(stateCopy)
                      }}
                      fullWidth
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  </div>
                </div>


                <FileUpload
                  files={files}
                  feature_name="Barging_agreements"
                  callbackFn={(data) => setFiles(data)}
                />
              </div>

            </div>
          }
        </div>
        <div
          className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
          style={{ bottom: 0, background: "white" }}
        >
          <button
            type="button"
            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
            onClick={() => props.onCancel(false)}
          >
            Cancel
          </button>
          {(!isLoading && state?.status !== "Closed" && state?.status !== "Cancelled") &&

            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={submitHandler}
            >
              Submit
            </button>
          }
        </div>
      </div>
    </>
  )
}
