import React, { useEffect, useState } from 'react'
import Loader from '../../common/Loader';
import api from '../../../api/api';
import CookieHandler from '../../common/CookieHandler';
import { localDateFormate, toLocaleString } from '../../common/common';

import ViewFiles from '../../common/ViewFiles';
import DashboardIcon from "@material-ui/icons/Dashboard";

import {
  MenuItem,
  Popover,
} from "@material-ui/core";

const Cookie = new CookieHandler();
const features = Cookie.getCookie("features");

export default function BargeAgreementView(props) {

  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState(null);
  const [options, setOptions] = useState(false);
  const [anchorEn, setAnchorEn] = useState(null);
  const [refresh, setRefersh] = useState(false)

  const { agreementID = null } = props || {};

  useEffect(() => {
    if (!Cookie.isLoggedIn()) {
      window.location.href = '/logout';
    }
    if (!(features.includes('556') || features.includes('97'))) {
      window.location.href = '/dashboard';
    }

    let request = {
      login_user_id: Cookie.getCookie('loginUserId'),
      idtoken: Cookie.getIdTokenCookie(),
      barging_agreement_id: agreementID
    }
    api.get_barging_agreement(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.barging_agreement) {
              setState(res.barging_agreement);
              setOptions(false);
              setIsLoading(false);
            }
          }
        })
      }
    })
  }, [agreementID, refresh]);

  const AgreementStatusHandler = (status) => {
    let request = {
      login_user_id: Cookie.getCookie('loginUserId'),
      idtoken: Cookie.getIdTokenCookie(),
      barging_agreement_id: agreementID,
      status: status,
    }
    api.update_barging_agreement_status(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            setOptions(false);
            setRefersh(!refresh)
          }
        })
      }
    })
  }

  const { status = null, created_by } = state || {};
  return (
    <>
      <div
        className="container bg-white p-0"
        style={{ minHeight: "100vh" }}
      >
        <div className="clearfix content-header border-bottom">
          <div className="row">
            <div className={(status === "Draft" || status === "Pending Level1 Approval" || status === "Pending Level2 Approval") ? "col-lg-8 text-left pl-0" : "col-lg-11 text-left pl-0"}>
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Barging Agreement - ${state?.fixture_note_no} `}
                <span
                  className={
                    status === "Active"
                      ? "type_1"
                      : "type_2"
                  }
                  style={{ padding: 8, marginLeft: 16 }}
                >
                  {status}
                </span>
              </h4>
            </div>
            {(status === "Draft" && created_by !== Cookie.getCookie('loginUserId')) &&
              <div
                className="col-lg-3 text-right float-sm-left"
                style={{ padding: "5px 20px" }}
              >
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "17px" }}
                  onClick={() => AgreementStatusHandler("Pending Level1 Approval")}
                >
                  Send for Approval
                </button>
              </div>
            }
            {(status === "Pending Level1 Approval" && (features.includes('559') || features.includes("97")) && created_by !== Cookie.getCookie('loginUserId')) &&
              <div
                className="col-lg-3 text-right float-sm-left"
                style={{ padding: "5px 20px" }}
              >
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "17px" }}
                  onClick={() => AgreementStatusHandler("Pending Level2 Approval")}
                >
                  Approve
                </button>
              </div>
            }
            {(status === "Pending Level2 Approval" && (features.includes('561') || features.includes("97")) && created_by !== Cookie.getCookie('loginUserId')) &&
              <div
                className="col-lg-3 text-right float-sm-left"
                style={{ padding: "5px 20px" }}
              >
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "17px" }}
                  onClick={() => AgreementStatusHandler("Active")}
                >
                  Approve
                </button>
              </div>
            }

            <div className="col-lg-1 text-right float-sm-left">
              <DashboardIcon
                style={{
                  fontSize: 30,
                  color: "#138496",
                  cursor: "pointer",
                  marginTop: 10,
                }}
                onClick={(event) => {
                  setOptions(true);
                  setAnchorEn(event.currentTarget)
                }}
              />
            </div>
          </div>
        </div>
        <div className="drawer-section">
          {isLoading ? <Loader /> :
            <div className="card">
              <div className='card-body'>

                <div className='row mb-3'>
                  <div className='col-lg-4 p-0'>
                    <label className="contract_display_header_label">
                      Barge Vendor
                    </label>
                    <label className="contract_display_header_value">
                      {state?.barge_vendor_name}
                    </label>
                  </div>
                  <div className='col-lg-4'>
                    <label className="contract_display_header_label">
                      Agreement Date
                    </label>
                    <label className="contract_display_header_value">
                      {localDateFormate(state?.agreement_date)}
                    </label>
                  </div>
                  <div className='col-lg-4'>
                    <label className="contract_display_header_label">
                      Currency
                    </label>
                    <label className="contract_display_header_value">
                      {state?.currency}
                    </label>
                  </div>
                </div>

                {state?.barging_agreement_price_list.length > 0 &&
                  <div className="table-responsive">

                    <table className="table table-sm">
                      <thead className="table-header">
                        <tr>
                          <th className='text-center border text-uppercase'>Area</th>
                          <th className='text-right border text-uppercase'>Price</th>
                          <th className='text-center border text-uppercase'>Barging Type</th>
                          <th className='text-center border  text-uppercase '>VAT Applicability (PPN)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state?.barging_agreement_price_list?.map((item, idx) => (
                          <tr key={item.price_list_id}>
                            <td className='text-center text-uppercase border'>{item.area}</td>
                            <td className='text-right border'>{toLocaleString(item.price)}</td>
                            <td className='text-center border'>{item.barging_type}</td>
                            <td className='text-center border'>{item.vat_incl_or_excl}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                }

                <div className='row mb-3'>
                  <div className='col-lg-12 p-0'>
                    <label className="contract_display_header_label">
                      Remarks
                    </label>
                    <div className="contract_display_header_value text-uppercase" style={{ whiteSpace: 'pre-line' }}>
                      {state?.remarks ? state.remarks : "-"}
                    </div>
                  </div>
                </div>
                <ViewFiles {...props} files_data={state?.files} />
              </div>
            </div>
          }
        </div>
        <div
          className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
          style={{ bottom: 0, background: "white" }}
        >
          <button
            type="button"
            className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
            onClick={() => props.onCancel(false)}
          >
            Cancel
          </button>

          {!isLoading &&
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => props.onEdit(true)}
            >
              Edit
            </button>
          }

        </div>
      </div>


      <Popover
        open={options}
        anchorEl={anchorEn}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        targetOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={() => setOptions(false)}
      >
        <div className="card border">
          <MenuItem
            style={{ fontFamily: "Poppins" }}
            onClick={() => AgreementStatusHandler("Closed")}
          >
            <i
              className="fa fa-times mr-4 ml-0"
              style={{
                fontSize: 26,
                color: "#3256a8",
                opacity: "0.5",
                margin: "0px 6px",
                width: "16px",
              }}
            ></i>
            Close
          </MenuItem>
          {status === "Draft" &&

            <MenuItem
              style={{ fontFamily: "Poppins" }}
              onClick={() => AgreementStatusHandler("Cancel")}
            >
              <i
                className="fa fa-times mr-4 ml-0"
                style={{
                  fontSize: 26,
                  color: "#3256a8",
                  opacity: "0.5",
                  margin: "0px 6px",
                  width: "16px",
                }}
              ></i>
              Cancelled
            </MenuItem>
          }

        </div>
      </Popover>

    </>
  )
}
