import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import CPSAdd from "./CPS-Add";
import CoalProChargesView from "./CPS-View";
import CoalProChargesUpdate from "./CPS-Update";

import { TextField, Snackbar, Checkbox, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CPSList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      cpc_costing_list: [],
      isLoading: true,
      barge_nomination: "",
      jetty_name: "",
      cps_contract_drop_value: [],
      cps_contract_ids: [],
      coal_vendor_drop_value: [],
      coal_vendor_ids: [],
      pc_contrcts_drop_value: [],
      pc_contract_ids: [],
      features: [],
      showDrawer: false,
      CoalProChargesAdd: false,
      CoalProChargesView: false,
      CoalProChargesUpdate: false,
      invoiceID: null,
      closeDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("507") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    await api
      .get_cps_contracts(loginUserID, idToken, status)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.cps_contract_data) {
                let cps_contract_drop_value = [];
                res.cps_contract_data.forEach((item) => {
                  cps_contract_drop_value.push({
                    name: item.cps_contract_no,
                    value: item.cps_id,
                    contract_no: item.contract_no,
                  });
                });
                this.setState({
                  cps_contract_drop_value: cps_contract_drop_value,
                  //loading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    await api
      .getPurchaseContracts(loginUserID, "", idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.purchase_contract) {
                let purchaseDataforMenu = [];
                res.purchase_contract
                  .filter(
                    (e, indx) =>
                      e.status !== "cancelled" && e.status !== "Closed"
                  )
                  .forEach((item) => {
                    purchaseDataforMenu.push({
                      name: item.contract_no,
                      value: item.id,
                      key: item.id,
                      vendor_name: item.vendor_name,
                    });
                  });

                this.setState({
                  pc_contrcts_drop_value: purchaseDataforMenu,
                  //isLoading: false
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api.getVendors(loginUserID, idToken, status).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const coal_vendor_drop_value = [];
              for (var i of res.vendors) {
                if (i.vendor_type === "Coal") {
                  coal_vendor_drop_value.push({
                    name: i.vendor_name,
                    value: i.id,
                  });
                }
              }
              this.setState({
                coal_vendor_drop_value: coal_vendor_drop_value,
                isLoading: false,
              });
            }
          }
        });
      }
    });
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const purchase_contract_id = this.state.pc_contract_ids;
    const cps_contract_id = this.state.cps_contract_ids;

    api
      .get_barge_cps_costings(
        loginUserID,
        idToken,
        purchase_contract_id,
        cps_contract_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  cpc_costing_list: purchase_contracts_barges.reverse(),
                  isLoading: false,
                });
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  deleteBargePurchaseFinancial = (data) => {
    // this.setState({ isLoading: true })
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const costing_id = data.id;
    api
      .delete_barge_cps_costing(loginUserID, idToken, costing_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                deleteCosting: false,
                successMsg: res.message,
                errorMsg: "",
                isLoading: false,
                closeDialog: false,
              });
              this.searchBargeHandler();
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                closeDialog: true,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // successMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState(
        {
          CoalProChargesUpdate: false,
          CoalProChargesAdd: false,
          CoalProChargesView: true,
        },
        () => this.searchBargeHandler()
      );
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState(
        {
          CoalProChargesAdd: false,
          CoalProChargesView: false,
          showDrawer: false,
        },
        () => {
          this.searchBargeHandler();
        }
      );
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        CoalProChargesUpdate: false,
        CoalProChargesAdd: false,
        CoalProChargesView: false,
        showDrawer: false,
        invoiceID: null,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      CoalProChargesUpdate: true,
      CoalProChargesView: false,
      CoalProChargesAdd: false,
      updateDocuments: true,
    });
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        CoalProChargesAdd: false,
        CoalProChargesView: false,
        CoalProChargesUpdate: true,
        showDrawer: true,
        updateDocuments: false,
      });
    }
  };

  render() {
    let drawerComponent;
    if (this.state.CoalProChargesAdd) {
      drawerComponent = (
        <CPSAdd onAddResponse={this.onAddResponse} onCancel={this.onCancel} />
      );
    } else if (this.state.CoalProChargesView && this.state.invoiceID !== null) {
      drawerComponent = (
        <CoalProChargesView
          invoiceID={this.state.invoiceID}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
          returnDocumentsHandler={this.addDocuments}
        />
      );
    } else if (
      this.state.CoalProChargesUpdate &&
      this.state.invoiceID !== null
    ) {
      drawerComponent = (
        <CoalProChargesUpdate
          invoiceID={this.state.invoiceID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 850 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="row mb-1 p-2">
            <div className="col-lg-4 p-0">
              <Autocomplete
                multiple
                options={this.state.cps_contract_drop_value}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">{option.name}</label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {option.contract_no
                          ? "( " + option.contract_no + " )"
                          : null}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ cps_contract_ids: data });
                  } else {
                    this.setState({ cps_contract_ids: [] });
                  }
                }}
                disableCloseOnSelect
                name="cps_contract"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Contract No"
                    label="Contract No"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-4 pr-0">
              <Autocomplete
                multiple
                options={this.state.pc_contrcts_drop_value}
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">{option.name}</label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {option.vendor_name
                          ? "( " + option.vendor_name + " )"
                          : null}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ pc_contract_ids: data });
                  } else {
                    this.setState({ pc_contract_ids: [] });
                  }
                }}
                disableCloseOnSelect
                name="Purchase Contract"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Purchase Contract"
                    label="Purchase Contract"
                    variant="standard"
                    style={{ top: 8 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>

            <div className="col mt-2 float-sm-left">
              <button
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "15px" }}
                onClick={() => this.searchBargeHandler()}
              >
                Load 
              </button>
            </div>
            {(this.state.features.includes("142") ||
              this.state.features.includes("97")) && (
              <div className="col pr-0 mt-2 text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "16px" }}
                  onClick={() =>
                    this.setState({ CoalProChargesAdd: true, showDrawer: true })
                  }
                >
                  New 
                </button>
              </div>
            )}
          </div>

          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>

          {this.state.cpc_costing_list.length > 0 && (
            <div className="row">
              <div className="table-responsive">
                <table className="table table-bordered table-sm mb-0">
                  <thead className="table-header">
                    <tr>
                      <th nowrap="true" className=" text-uppercase "></th>
                      <th nowrap="true" className=" text-uppercase ">
                        Costing ID
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        CPS Contract
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Barge ID
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Barge Nomination
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Business No
                      </th>
                      <th nowrap="true" className=" text-uppercase ">
                        Vendor
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Quantity
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Price
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Advance Adjustment
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Total Payable
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Paid
                      </th>
                      <th nowrap="true" className="text-right  text-uppercase ">
                        Remaining
                      </th>
                    </tr>
                  </thead>
                  {this.state.cpc_costing_list.map((p, index) => (
                    <tbody key={index}>
                      {p.new_barges.map((idr, indx) => (
                        <tr key={indx} className="rounded">
                          {indx === 0 && (
                            <td
                              className="text-center"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              <i
                                className="fa fa-eye mr-2"
                                aria-hidden="true"
                                style={{
                                  fontSize: 22,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    CoalProChargesView: true,
                                    invoiceID: idr.id,
                                    showDrawer: true,
                                  })
                                }
                              ></i>
                              {(this.state.features.includes("509") ||
                                this.state.features.includes("97")) &&
                                idr.approval_status === "Pending" && (
                                  <i
                                    className="fa fa-trash-o"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: 21,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        deleteCosting: true,
                                        barge_pur_fin_id: idr.id,
                                        deleteData: idr,
                                      })
                                    }
                                  ></i>
                                )}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-left"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {idr.costing_no ? idr.costing_no : "-"}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-left"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {idr.cps_contract_no ? idr.cps_contract_no : "-"}
                            </td>
                          )}
                          <td className=" text-left">{pad(idr.barge_id)}</td>
                          <td className=" text-left" nowrap="true">
                            {idr.barge_nomination ? idr.barge_nomination : "-"}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {idr.business_no ? idr.business_no : "-"}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {idr.payment_to_Vendor
                              ? idr.payment_to_Vendor
                              : "-"}
                          </td>
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.barge_quantity)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {idr.price_type === "Per MT"
                                ? toLocaleString(idr.base_price_pmt)
                                : toLocaleString(idr.lumpsum_price)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.advance_adjustments)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.total_payable)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.paid)}
                            </td>
                          )}
                          {indx === 0 && (
                            <td
                              className=" text-right"
                              nowrap="true"
                              rowSpan={p.new_barges.length}
                            >
                              {toLocaleString(idr.Remaining_amount)}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  ))}
                </table>
              </div>
            </div>
          )}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() =>
                this.deleteBargePurchaseFinancial(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
