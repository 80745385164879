import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableFooter from './InvoiceTableFooter'

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
});

const InvoiceItemsTable = ({ invoice,demurrage_name }) => (
  <View style={styles.tableContainer}>
    <InvoiceTableHeader demurrage_name={demurrage_name} />
    <InvoiceTableRow items={invoice} />
    <InvoiceTableFooter items={invoice} />
  </View>
);

export default InvoiceItemsTable