const addZero = (i) => {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
};

export const dateFormateToDB = (date) => {
  if (date) {
    let dateFormat = new Date(date);
    let day = addZero(dateFormat.getDate());
    let month = addZero(dateFormat.getMonth() + 1);
    let year = dateFormat.getFullYear();
    let newDate = year + "-" + month + "-" + day;
    let hours = addZero(dateFormat.getHours());
    let minutes = addZero(dateFormat.getMinutes());
    let time = hours + ":" + minutes;
    let finalDate = newDate + " " + time;
    return finalDate;
  }
  else return null;
};

const getMonth = (month) => {
  if (month) {
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JULY",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const monthValue = Number(month) - 1;
    return months[monthValue];
  }
};

export const localDateFormate = (date) => {
  if (date) {
    let dateValue = date.split(" ");
    let dateString = dateValue[0].split("-");
    var newDate =
      dateString[2] + "-" + getMonth(dateString[1]) + "-" + dateString[0];
    //var time = dateValue[1];
    // var finalDate = newDate + " " + time;
    var finalDate = newDate;
    return finalDate;
  }
  else return '-'
};
export const localTimeFormate = (date) => {
  if (date) {
    let dateValue = date.split(" ");
    let dateString = dateValue[0].split("-");
    var newDate =
      dateString[2] + "-" + getMonth(dateString[1]) + "-" + dateString[0];
    var time = dateValue[1];
    var finalDate = newDate + " " + time;
    return finalDate;
  }
  else return '-'
};

export const toLocaleString = (data)=>{
  return data ? Number(data).toLocaleString() : 0
}

export const pad = (id)=> {
  return id ? id.padStart(4, 0) : null;
}

export const progressBarSize = (data, type) => {
  if (data) {
    if (type === "NPC") {
      var remainingPurchaseQuality =
        Number(
          Number(data.barge_delivered_quantity) /
            Number(data.quantity_adjusted)
        ) * 100;
      return remainingPurchaseQuality;
    }
  }
}

export const _toBeDelivered = (data) => {
  if (data) {
    if (data.purchase_contract_id) {
      var remainingPurchaseQuality = Number(
        Number(data.quantity_adjusted) - Number(data.barge_delivered_quantity)
      );
      return remainingPurchaseQuality;
    }
  }
}
