import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import OtherExpenseAdd from "./Other-Expense-Add";
import OtherExpenseView from "./Other-Expense-View";
import OtherExpenseUpdate from "./Other-Expense-Update";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";
export default class OtherExpenseList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: false,
      otherExpenseAdd: false,
      otherExpenseView: false,
      otherExpenseUpdate: false,
      features: [],
      comboID: null,
      otherExpensesList: [],
      management_remarks: "",
      vessel_completion_id: null,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("339") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.get_barges_for_other_expenses(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              let newData = [];
              var result = res.barges.reduce(function (r, a) {
                r[a.id] = r[a.id] || [];
                r[a.id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({ new_barges: result[Object.keys(result)[l]] });
              }
              let purchase_contracts_barges = newData;
              if (newData.length === 0) {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: "No records to display",
                });
              }
              this.setState({
                otherExpensesList: purchase_contracts_barges,
                isLoading: false,
              });
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  onAddResponse = (value) => {
    this.setState({ otherExpenseAdd: false, showDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  editCallback = (childData) => {
    this.setState({
      showDrawer: true,
      otherExpenseAdd: false,
      otherExpenseUpdate: true,
      otherExpenseView: false,
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          showDrawer: false,
          otherExpenseAdd: false,
          otherExpenseView: false,
          otherExpenseUpdate: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: false,
      otherExpenseView: false,
      otherExpenseUpdate: false,
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      otherExpenseAdd: false,
      otherExpenseUpdate: false,
      otherExpenseView: false,
    });
  };

  deletePaymentsHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const other_expenses_id = this.state.other_expenses_id;
    api
      .delete_barge_pur_fin_other_expenses(
        loginUserID,
        idToken,
        other_expenses_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  deleteCosting: true,
                  snackBarSuccessOpen: true,
                  successMsg: "Deleted Successfully",
                },
                () => {
                  this.componentDidMount();
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "650") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            }
          });
        }
      });
  };

  render() {
    let drawerComponent;
    if (this.state.otherExpenseAdd) {
      drawerComponent = (
        <OtherExpenseAdd
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (this.state.otherExpenseView && this.state.other_expense_id) {
      drawerComponent = (
        <OtherExpenseView
          costing_id={this.state.other_expense_id}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
        />
      );
    }
    if (this.state.otherExpenseUpdate && this.state.other_expense_id) {
      drawerComponent = (
        <OtherExpenseUpdate
          costing_id={this.state.other_expense_id}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="row w-100">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card mt-2 p-2 w-100">
            <div className="card-body">
              <div className="row">
                <div className="col"></div>

                <div className="col text-right float-sm-left">
                  <button
                    className="header_button header_button_text add_button_adjustment"
                    style={{ width: "auto", marginTop: "0px" }}
                    onClick={() =>
                      this.setState({
                        otherExpenseAdd: true,
                        showDrawer: true,
                      })
                    }
                  >
                    New
                  </button>
                </div>
              </div>
              {this.state.otherExpensesList.length > 0 ? (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm mb-0">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true" className=" text-uppercase"></th>
                          <th nowrap="true" className=" text-uppercase">
                            Costing ID
                          </th>
                          <th nowrap="true" className=" text-uppercase">
                            Barge ID
                          </th>
                          <th nowrap="true" className=" text-uppercase">
                            Barge Nomination
                          </th>
                          <th nowrap="true" className=" text-uppercase">
                            Business No
                          </th>
                          <th nowrap="true" className=" text-uppercase">
                            Item Description
                          </th>
                          <th
                            nowrap="true"
                            className="text-right  text-uppercase"
                          >
                            Price
                          </th>
                        </tr>
                      </thead>
                      {this.state.otherExpensesList.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr key={indx} className="rounded">
                              {indx === 0 && (
                                <td
                                  className="table_td"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  <i
                                    className="fa fa-eye mr-2"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: 22,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      this.setState({
                                        otherExpenseView: true,
                                        other_expense_id: idr.id,
                                        showDrawer: true,
                                      })
                                    }
                                  ></i>
                                  {(this.state.features.includes("341") ||
                                    this.state.features.includes("97")) && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        fontSize: 20,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          deleteCosting: true,
                                          other_expenses_id: idr.id,
                                        })
                                      }
                                    ></i>
                                  )}
                                </td>
                              )}
                              {indx === 0 && (
                                <td nowrap="true" rowSpan={p.new_barges.length}>
                                  {idr.costing_no}
                                </td>
                              )}
                              <td>{pad(idr.barge_id)}</td>
                              <td nowrap="true">{idr.barge_nomination}</td>
                              <td nowrap="true">{idr.business_no}</td>
                              <td nowrap="true">{idr.item_description}</td>
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.price)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              ) : (
                <div>
                  <span className="contract_display_header_value pt-2">
                    No costings made
                  </span>
                </div>
              )}
            </div>
          </div>

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() => this.deletePaymentsHandler()}
            />
          )}
        </div>
      );
    }
  }
}
