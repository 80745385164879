import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteConfirmation = ({ open, close, callbackFn }) => {
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#f1f1f1", width: 400 }}>
          <h6
            className="modal-title"
          >
            {" "}
            <i
              className="fa fa-exclamation-triangle"
              aria-hidden="true"
              style={{ color: "#e0a51e", marginBottom: "5px" }}
            ></i>
            &emsp; Are you sure to delete ?
          </h6>
        </DialogTitle>
        <DialogActions>
          <button
            className="btn btn-danger mr-3"
            onClick={callbackFn}
          >
            Delete
          </button>
          <button className="btn btn-secondary"
            onClick={close}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>

  )
}

export default DeleteConfirmation;