import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";

import {
  TextField,
  Snackbar,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class RetuenCargoUpdate extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 1,
      isLoading: true,
      features: [],
      barges: [],
      barge_id: null,
      vendor_id: "",
      dc_price_type: "",
      dc_quantity_in_mt: "",
      dc_price_per_mt: "",
      dc_base_price: "",
      dc_vat_percent: 10,
      dc_vat_value: "",
      dc_pph23_percent: 2,
      dc_pph23_value: "",
      dc_total: "",
      dc_ctc: "",
      billing_currency: "0",
      dc_currency_xchg_rate: "",
      dc_ctc_in_local_currency: "",
      et_vendor_id: "",
      et_price_type: "",
      et_quantity_in_mt: "",
      et_price_per_mt: "",
      et_base_price: "",
      et_vat_percent: 10,
      et_vat_value: "",
      et_pph23_percent: 2,
      et_pph23_value: "",
      et_total: "",
      et_ctc: "",
      et_currency: "0",
      et_currency_xchg_rate: "",
      et_ctc_in_local_currency: "",
      invoice_no: "",
      invoice_date: null,
      currencyData: [],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("537") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    // for get_Currencies
    const getCurrencies = await api.getCurrencies(loginUserID, idToken);
    if (getCurrencies.status >= 200 && getCurrencies.status < 300) {
      getCurrencies.json().then((res) => {
        if (res.code === "200") {
          if (res.Currency) {
            var currencies = [
              <MenuItem
                style={{ fontFamily: "Poppins", fontSize: "14px" }}
                value="0"
                key={-1}
                disabled
              >
                Please Select
              </MenuItem>,
            ];
            for (var i in res.Currency) {
              currencies.push(
                <MenuItem
                  style={{ fontFamily: "Poppins", fontSize: "14px" }}
                  value={res.Currency[i].currency}
                  key={[i]}
                >
                  {res.Currency[i].currency}
                </MenuItem>
              );
            }
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            sucessMsg: "",
            // isLoading: false
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    // calling Vendors Api
    let status = "Active ";
    const vendorsDataResponse = await api.getVendors(
      loginUserID,
      idToken,
      status
    );
    if (vendorsDataResponse.status >= 200 && vendorsDataResponse.status < 300) {
      vendorsDataResponse.json().then((res) => {
        if (res.code === "200") {
          if (res.vendors) {
            const surveyors = [];
            const vendorAsOthers = [];
            for (var i of res.vendors) {
              if (i.vendor_type === "Surveyor") {
                surveyors.push({ name: i.vendor_name, value: i.id, key: i });
              }
              if (i.vendor_type === "Others" || i.vendor_type === "Coal") {
                vendorAsOthers.push({
                  name: i.vendor_name,
                  value: i.id,
                  key: i,
                });
              }
            }
            this.setState({
              vendorTypeAsSurveyors: surveyors,
              vendorTypeAsOthers: vendorAsOthers,
              documentation_agent_drop_value: vendorAsOthers,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    const get_return_cargo_barges = await api.get_barges_for_return_cargo(
      loginUserID,
      idToken
    );

    if (
      get_return_cargo_barges.status >= 200 &&
      get_return_cargo_barges.status < 300
    ) {
      get_return_cargo_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            this.setState({
              barges: res.barges,
              //  isLoading: false
            });
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
            isLoading: false,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    const return_cargo_charges_id = this.props.returnCargoID;

    const get_return_cargo = await api.get_return_cargo_charges(
      loginUserID,
      idToken,
      return_cargo_charges_id
    );

    if (get_return_cargo.status >= 200 && get_return_cargo.status < 300) {
      get_return_cargo.json().then((res) => {
        if (res.code === "200") {
          if (res.return_cargo_charges_data) {
            this.setState({
              auto_vendor_name: {
                name: res.return_cargo_charges_data.vendor_name,
                value: res.return_cargo_charges_data.vendor_id,
              },
              vendor_id: res.return_cargo_charges_data.vendor_id,
              invoice_file: res.return_cargo_charges_data.invoice_file,
              barge_id: res.return_cargo_charges_data.barge_id,
              dc_price_type: res.return_cargo_charges_data.dc_price_type,
              dc_quantity_in_mt:
                res.return_cargo_charges_data.dc_quantity_in_mt,
              dc_price_per_mt: res.return_cargo_charges_data.dc_price_per_mt,
              dc_lumpsum_price: res.return_cargo_charges_data.dc_lumpsum_price,
              dc_base_price: res.return_cargo_charges_data.dc_base_price,
              dc_vat_percent: res.return_cargo_charges_data.dc_vat_percent,
              dc_vat_value: res.return_cargo_charges_data.dc_vat_value,
              dc_pph23_percent: res.return_cargo_charges_data.dc_pph23_percent,
              dc_pph23_value: res.return_cargo_charges_data.dc_pph23_value,
              dc_total: res.return_cargo_charges_data.dc_total,
              dc_ctc: res.return_cargo_charges_data.dc_ctc,
              billing_currency: res.return_cargo_charges_data.billing_currency
                ? res.return_cargo_charges_data.billing_currency
                : "0",
              dc_currency_xchg_rate:
                res.return_cargo_charges_data.dc_currency_xchg_rate,
              dc_ctc_in_local_currency:
                res.return_cargo_charges_data.dc_ctc_in_local_currency,

              et_price_type: res.return_cargo_charges_data.et_price_type,
              et_quantity_in_mt:
                res.return_cargo_charges_data.et_quantity_in_mt,
              et_price_per_mt: res.return_cargo_charges_data.et_price_per_mt,
              et_lumpsum_price: res.return_cargo_charges_data.et_lumpsum_price,
              et_base_price: res.return_cargo_charges_data.et_base_price,
              et_vat_percent: res.return_cargo_charges_data.et_vat_percent,
              et_vat_value: res.return_cargo_charges_data.et_vat_value,
              et_pph23_percent: res.return_cargo_charges_data.et_pph23_percent,
              et_pph23_value: res.return_cargo_charges_data.et_pph23_value,
              et_total: res.return_cargo_charges_data.et_total,
              et_ctc: res.return_cargo_charges_data.et_ctc,
              et_currency: res.return_cargo_charges_data.et_currency
                ? res.return_cargo_charges_data.et_currency
                : "0",
              et_currency_xchg_rate:
                res.return_cargo_charges_data.et_currency_xchg_rate,
              et_ctc_in_local_currency:
                res.return_cargo_charges_data.et_ctc_in_local_currency,

              ls_price_type: res.return_cargo_charges_data.ls_price_type,
              ls_quantity_in_mt:
                res.return_cargo_charges_data.ls_quantity_in_mt,
              ls_price_per_mt: res.return_cargo_charges_data.ls_price_per_mt,
              ls_lumpsum_price: res.return_cargo_charges_data.ls_lumpsum_price,
              ls_base_price: res.return_cargo_charges_data.ls_base_price,
              ls_vat_percent: res.return_cargo_charges_data.ls_vat_percent,
              ls_vat_value: res.return_cargo_charges_data.ls_vat_value,
              ls_pph23_percent: res.return_cargo_charges_data.ls_pph23_percent,
              ls_pph23_value: res.return_cargo_charges_data.ls_pph23_value,
              ls_total: res.return_cargo_charges_data.ls_total,
              ls_ctc: res.return_cargo_charges_data.ls_ctc,
              ls_currency: res.return_cargo_charges_data.ls_currency
                ? res.return_cargo_charges_data.ls_currency
                : "0",
              ls_currency_xchg_rate:
                res.return_cargo_charges_data.ls_currency_xchg_rate,
              ls_ctc_in_local_currency:
                res.return_cargo_charges_data.ls_ctc_in_local_currency,

              invoice_no: res.return_cargo_charges_data.invoice_no,
              invoice_date: res.return_cargo_charges_data.invoice_date,
              ppn: res.return_cargo_charges_data.ppn,
              ppn_date: res.return_cargo_charges_data.ppn_date,

              files: res.return_cargo_charges_data.files
                ? res.return_cargo_charges_data.files
                : [],
              isLoading: false,
            });
          }
        } else if (res.code === "601") {
          this.setState({
            errorMsg: res.message,
            snackBarErrorOpen: true,
            isLoading: false,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  documentation_charges_calculations_handler() {
    let quantity = this.state.dc_quantity_in_mt
      ? this.state.dc_quantity_in_mt
      : 0;
    let price = this.state.dc_price_per_mt ? this.state.dc_price_per_mt : 0;
    let dc_currency_xchg_rate = this.state.dc_currency_xchg_rate
      ? this.state.dc_currency_xchg_rate
      : 1;
    let dc_lumpsum_price = this.state.dc_lumpsum_price
      ? this.state.dc_lumpsum_price
      : 0;
    var dc_base_price = 0;
    dc_base_price = (
      (Number(quantity) * Number(price)) /
      Number(dc_currency_xchg_rate)
    ).toFixed(4);
    if (this.state.dc_price_type === "Lumpsum") {
      dc_base_price = (
        Number(dc_lumpsum_price) / Number(dc_currency_xchg_rate)
      ).toFixed(4);
    }
    let dc_vat_value = (
      (Number(dc_base_price) * Number(this.state.dc_vat_percent)) /
      100
    ).toFixed(2);
    let dc_pph23_value = (
      (Number(dc_base_price) * Number(this.state.dc_pph23_percent)) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") {
      dc_pph23_value = 0;
    }
    let dc_total = (
      Number(dc_base_price) +
      Number(dc_vat_value) -
      Number(dc_pph23_value)
    ).toFixed(2);
    let dc_ctc = (Number(dc_base_price) + Number(dc_vat_value)).toFixed(2);
    let dc_ctc_in_local_currency = dc_ctc;

    this.setState({
      dc_base_price: dc_base_price,
      dc_vat_value: dc_vat_value,
      dc_pph23_value: dc_pph23_value,
      dc_total: dc_total,
      dc_ctc: dc_ctc,
      dc_ctc_in_local_currency: dc_ctc_in_local_currency,
    });
  }

  export_calculations_handler() {
    let quantity = this.state.et_quantity_in_mt
      ? this.state.et_quantity_in_mt
      : 0;
    let price = this.state.et_price_per_mt ? this.state.et_price_per_mt : 0;
    let currency_xchg_rate = this.state.et_currency_xchg_rate
      ? this.state.et_currency_xchg_rate
      : 1;
    let et_lumpsum_price = this.state.et_lumpsum_price
      ? this.state.et_lumpsum_price
      : 0;
    var et_base_price = 0;
    et_base_price = (
      (Number(quantity) * Number(price)) /
      Number(currency_xchg_rate)
    ).toFixed(4);
    if (this.state.dc_price_type === "Lumpsum") {
      et_base_price = (
        Number(et_lumpsum_price) / Number(currency_xchg_rate)
      ).toFixed(4);
    }
    let et_vat_value = (
      (Number(et_base_price) * Number(this.state.dc_vat_percent)) /
      100
    ).toFixed(2);
    let et_pph23_value = (
      (Number(et_base_price) * Number(this.state.dc_pph23_percent)) /
      100
    ).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") {
      et_pph23_value = 0;
    }
    let et_total = (
      Number(et_base_price) +
      Number(et_vat_value) -
      Number(et_pph23_value)
    ).toFixed(2);
    let et_ctc = (Number(et_base_price) + Number(et_vat_value)).toFixed(2);
    let et_ctc_in_local_currency = et_ctc;

    this.setState({
      et_base_price: et_base_price,
      et_vat_value: et_vat_value,
      et_pph23_value: et_pph23_value,
      et_total: et_total,
      et_ctc: et_ctc,
      et_ctc_in_local_currency: et_ctc_in_local_currency,
    });
  }

  lapranCalculationHandler() {
    var Quantity = this.state.ls_quantity_in_mt
      ? this.state.ls_quantity_in_mt
      : 1;
    var laprn_price_pmt = this.state.ls_price_per_mt
      ? this.state.ls_price_per_mt
      : 0;
    var exchange_rate = this.state.ls_currency_xchg_rate
      ? this.state.ls_currency_xchg_rate
      : 1;
    let ls_base_price = 0;
    let lumpsum_price = this.state.ls_lumpsum_price
      ? this.state.ls_lumpsum_price
      : 0;
    if (this.state.ls_price_type === "Per MT") {
      ls_base_price = (
        (Number(Quantity) * Number(laprn_price_pmt)) /
        exchange_rate
      ).toFixed(4);
    } else {
      ls_base_price = (Number(lumpsum_price) / Number(exchange_rate)).toFixed(
        4
      );
    }

    let ls_vat_percent = this.state.ls_vat_percent ? this.state.ls_vat_percent : 10;
    let ls_pph23_percent = this.state.ls_pph23_percent ? this.state.ls_pph23_percent : 2;

    let ls_vat_value = ((Number(ls_base_price) * ls_vat_percent) / 100).toFixed(2);
    let ls_pph23_value = ((Number(ls_base_price) * ls_pph23_percent) / 100).toFixed(2);
    if (config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") {
      ls_pph23_value = 0;
    }
    let ls_total = (
      Number(ls_base_price) +
      Number(ls_vat_value) -
      Number(ls_pph23_value)
    ).toFixed(2);
    let ls_ctc = (Number(ls_base_price) + Number(ls_vat_value)).toFixed(2);

    this.setState({
      ls_base_price: ls_base_price,
      ls_vat_percent: ls_vat_percent,
      ls_vat_value: ls_vat_value,
      ls_pph23_percent: ls_pph23_percent,
      ls_pph23_value: ls_pph23_value,
      ls_total: ls_total,
      ls_ctc: ls_ctc,
    });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  submitHandler = () => {
    this.setState({ isLoading: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let {
      barge_id,
      vendor_id,
      dc_price_type,
      dc_quantity_in_mt,
      dc_price_per_mt,
      dc_lumpsum_price,
      dc_base_price,
      dc_vat_percent,
      dc_vat_value,
      dc_pph23_percent,
      dc_pph23_value,
      dc_total,
      dc_ctc,
      billing_currency,
      dc_currency_xchg_rate,
      dc_ctc_in_local_currency,

      et_price_type,
      et_quantity_in_mt,
      et_price_per_mt,
      et_lumpsum_price,
      et_base_price,
      et_vat_percent,
      et_vat_value,
      et_pph23_percent,
      et_pph23_value,
      et_total,
      et_ctc,
      et_currency,
      et_currency_xchg_rate,
      et_ctc_in_local_currency,
      ls_price_type,
      ls_quantity_in_mt,
      ls_price_per_mt,
      ls_lumpsum_price,
      ls_base_price,
      ls_vat_percent,
      ls_vat_value,
      ls_pph23_percent,
      ls_pph23_value,
      ls_total,
      ls_ctc,
      ls_currency,
      ls_currency_xchg_rate,
      ls_ctc_in_local_currency,
      invoice_no,
      invoice_date,
      ppn,
      ppn_date,
    } = this.state;

    if (vendor_id === null) {
      this.setState({
        vendor_name_error: true,
        submitting: false,
        isLoading:false
      });
      return;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please fill mandatory fields.",
        isLoading:false
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        submitLoading: false,
        errorMsg: "Please Select file.",
        isLoading:false
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    const return_cargo_charges_id = this.props.returnCargoID;

    api
      .update_return_cargo_charges(
        loginUserID,
        idToken,
        barge_id,
        return_cargo_charges_id,
        vendor_id,
        dc_price_type,
        dc_quantity_in_mt,
        dc_price_per_mt,
        dc_lumpsum_price,
        dc_base_price,
        dc_vat_percent,
        dc_vat_value,
        dc_pph23_percent,
        dc_pph23_value,
        dc_total,
        dc_ctc,
        billing_currency,
        dc_currency_xchg_rate,
        dc_ctc_in_local_currency,

        et_price_type,
        et_quantity_in_mt,
        et_price_per_mt,
        et_lumpsum_price,
        et_base_price,
        et_vat_percent,
        et_vat_value,
        et_pph23_percent,
        et_pph23_value,
        et_total,
        et_ctc,
        et_currency,
        et_currency_xchg_rate,
        et_ctc_in_local_currency,
        ls_price_type,
        ls_quantity_in_mt,
        ls_price_per_mt,
        ls_lumpsum_price,
        ls_base_price,
        ls_vat_percent,
        ls_vat_value,
        ls_pph23_percent,
        ls_pph23_value,
        ls_total,
        ls_ctc,
        ls_currency,
        ls_currency_xchg_rate,
        ls_ctc_in_local_currency,
        invoice_no,
        invoice_date,
        ppn,
        ppn_date,
        existing_file_ids,
        content_files,
        costing_file
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                isLoading: true,
                snackBarSuccessOpen: true,
                sucessMsg: res.message,
              });
              this.props.onUpdateCosting(res.code);
            } else if (res.code === "601") {
              this.setState({
                isLoading: false,
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0 border-bottom">
                <h4 style={{ padding: "8px 20px", margin: "0px" }}>
                  Return Cargo Charges
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          {this.props.updateDocuments ? (
            <div className="drawer-section">
              <div className="card">
                <FileUpload
                  files={this.state.files}
                  invoice_file={this.state.invoice_file}
                  type="Costings"
                  feature_name="Return_cargo_charges"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="drawer-section">
              <div className="card">
                <div className="card-body border_dashed">
                  <div className="col-lg-12 p-0">
                    <div className="row mb-2">
                      <div className="col-lg-4 p-0 mb-2">
                        <label className="form_label mb-0">Vendor Name</label>
                        <Autocomplete
                          options={this.state.vendorTypeAsOthers}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                vendor_id: value.value,
                                auto_vendor_name: value,
                                vendor_name_error: false,
                              });
                            } else {
                              this.setState({
                                vendor_id: null,
                                auto_vendor_name: { name: "", value: "" },
                              });
                            }
                          }}
                          value={this.state.auto_vendor_name}
                          name="vendorName"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Vendor"
                              variant="outlined"
                              error={this.state.vendor_name_error}
                              fullWidth
                            />
                          )}
                          className="mt-2"
                        />
                      </div>
                    </div>

                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        lineHeight: "0.1em",
                        margin: "10px 0 20px",
                        borderBottom: "1px solid #dddd",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#fff",
                          padding: "0 10px",
                          color: "#ec7833",
                        }}
                      >
                        Documentation Charges
                      </span>
                    </p>

                    <div className="row mb-3">
                      <div className="col-lg-6 pl-0 mb-2">
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Price Type
                            </label>
                            <RadioGroup
                              aria-label="dc_price_type"
                              name="dc_price_type"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    dc_price_type: e.target.value,
                                  },
                                  () => {
                                    this.documentation_charges_calculations_handler();
                                  }
                                );
                              }}
                              style={{ flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="Per MT"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.dc_price_type === "Per MT"
                                    }
                                  />
                                }
                                label="Per MT"
                              />
                              <FormControlLabel
                                value="Lumpsum"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.dc_price_type === "Lumpsum"
                                    }
                                  />
                                }
                                label="Lumpsum"
                              />
                            </RadioGroup>
                          </div>

                          {this.state.dc_price_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Quantity in MT
                              </label>
                              <TextField
                                name="dc_quantity_in_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    // fontSize: '14px'
                                  },
                                }}
                                value={this.state.dc_quantity_in_mt}
                                placeholder="Quantity in MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dc_quantity_in_mt: e.target.value,
                                      },
                                      () => {
                                        this.documentation_charges_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {this.state.dc_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Price per MT
                              </label>
                              <TextField
                                name="dc_price_per_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.dc_price_per_mt}
                                placeholder="Price per MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dc_price_per_mt: e.target.value,
                                      },
                                      () => {
                                        this.documentation_charges_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="billing_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.billing_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                billing_currency: e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}
                          {this.state.dc_price_type === "Lumpsum" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Lumpsum Price
                              </label>
                              <TextField
                                name="dc_lumpsum_price"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.dc_lumpsum_price}
                                placeholder="Lumpsum Price"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        dc_lumpsum_price: e.target.value,
                                      },
                                      () => {
                                        this.documentation_charges_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="billing_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.billing_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                billing_currency: e.target.value,
                                              },
                                              () => {
                                                this.documentation_charges_calculations_handler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}
                          {(config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL") &&
                            this.state.billing_currency !== "USD" &&
                            this.state.billing_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="dc_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.dc_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        dc_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.documentation_charges_calculations_handler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) &&
                            this.state.billing_currency !== "IDR" &&
                            this.state.billing_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="dc_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.dc_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        dc_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.documentation_charges_calculations_handler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className="col-lg-6 p-2 rounder"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-1">
                          <h6>Documentation Charges</h6>
                        </div>
                        <div className="row pl-2">
                          <div className="col-lg-4 p-0 mb-2">
                            <label className="form_label mb-0">
                              Base Price
                            </label>
                            <TextField
                              name="dc_base_price"
                              margin="dense"
                              variant="standard"
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              disabled={
                                this.state.dc_price_type === "Lumpsum"
                                  ? true
                                  : false
                              }
                              //fullWidth
                              value={this.state.dc_base_price}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = "1";
                                  }
                                  var dc_vat_value = (
                                    (Number(value) *
                                      Number(this.state.dc_vat_percent)) /
                                    100
                                  ).toFixed(0);
                                  let dc_pph23_value = (
                                    (Number(value) *
                                      Number(this.state.dc_pph23_percent)) /
                                    100
                                  ).toFixed(0);
                                  if (
                                    config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    dc_pph23_value = 0;
                                  }
                                  let dc_ctc = (
                                    Number(e.target.value) +
                                    Number(dc_vat_value)
                                  ).toFixed(2);
                                  let dc_ctc_in_local_currency = dc_ctc;
                                  this.setState({
                                    dc_base_price: e.target.value,
                                    dc_vat_value: dc_vat_value,
                                    dc_pph23_value: dc_pph23_value,
                                    dc_total: (
                                      Number(e.target.value) +
                                      Number(dc_vat_value) -
                                      Number(dc_pph23_value)
                                    ).toFixed(2),
                                    dc_ctc: dc_ctc,
                                    dc_ctc_in_local_currency:
                                      dc_ctc_in_local_currency,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label
                              className="form_label mb-0"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <TextField
                              name="dc_vat_percent"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.dc_vat_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      dc_vat_percent: e.target.value,
                                    },
                                    () => {
                                      this.documentation_charges_calculations_handler();
                                    }
                                  );
                                }
                              }}
                            />
                            {/* <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.dc_vat_percent}
                            </div> */}
                          </div>
                          <div className="col-lg-4 mb-2 p-0">
                            <label className="form_label mb-0">VAT</label>
                            <TextField
                              name="dc_vat_value"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.dc_vat_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  let dc_pph23_value = (
                                    (Number(this.state.dc_base_price) *
                                      Number(this.state.dc_pph23_percent)) /
                                    100
                                  ).toFixed(0);
                                  if (
                                    config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    dc_pph23_value = 0;
                                  }
                                  let dc_ctc = (
                                    Number(this.state.dc_base_price) +
                                    Number(e.target.value)
                                  ).toFixed(2);
                                  let dc_ctc_in_local_currency = "";
                                  if (
                                    (config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL") &&
                                    this.state.billing_currency !== "USD" &&
                                    this.state.billing_currency !== "0"
                                  ) {
                                    dc_ctc_in_local_currency = (
                                      Number(this.state.dc_currency_xchg_rate) *
                                      Number(dc_ctc)
                                    ).toFixed(0);
                                  }
                                  this.setState({
                                    dc_vat_value: e.target.value,
                                    dc_pph23_value: dc_pph23_value,
                                    dc_total:
                                      Number(this.state.dc_base_price) +
                                      Number(e.target.value) -
                                      Number(dc_pph23_value),
                                    dc_ctc: dc_ctc,
                                    dc_ctc_in_local_currency:
                                      dc_ctc_in_local_currency,
                                  });
                                }
                              }}
                            />
                          </div>
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23 %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.dc_pph23_percent}
                                </div>
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.dc_pph23_value
                                    ? this.toLocaleString(
                                      this.state.dc_pph23_value
                                    )
                                    : null}
                                </div>
                              </div>
                            )}
                          <div className="col-lg-6 mb-2 p-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Payable to Vendor
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.dc_total
                                ? this.toLocaleString(this.state.dc_total)
                                : null}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Cost to Company
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.dc_ctc
                                ? this.toLocaleString(this.state.dc_ctc)
                                : null}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        lineHeight: "0.1em",
                        margin: "10px 0 20px",
                        borderBottom: "1px solid #dddd",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#fff",
                          padding: "0 10px",
                          color: "#ec7833",
                        }}
                      >
                        Export Tax
                      </span>
                    </p>

                    <div className="row mb-3">
                      <div className="col-lg-6 pl-0 mb-2">
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Price Type
                            </label>
                            <RadioGroup
                              aria-label="et_price_type"
                              name="et_price_type"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    et_price_type: e.target.value,
                                  },
                                  () => {
                                    this.export_calculations_handler();
                                  }
                                );
                              }}
                              style={{ flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="Per MT"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.et_price_type === "Per MT"
                                    }
                                  />
                                }
                                label="Per MT"
                              />
                              <FormControlLabel
                                value="Lumpsum"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.et_price_type === "Lumpsum"
                                    }
                                  />
                                }
                                label="Lumpsum"
                              />
                            </RadioGroup>
                          </div>
                          {this.state.et_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Quantity in MT
                              </label>
                              <TextField
                                name="et_quantity_in_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    // fontSize: '14px'
                                  },
                                }}
                                value={this.state.et_quantity_in_mt}
                                placeholder="Quantity in MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        et_quantity_in_mt: e.target.value,
                                      },
                                      () => {
                                        this.export_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {this.state.et_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Export Tax per MT
                              </label>
                              <TextField
                                name="et_price_per_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.et_price_per_mt}
                                placeholder="Price per MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        et_price_per_mt: e.target.value,
                                      },
                                      () => {
                                        this.export_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="et_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.et_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                et_currency: e.target.value,
                                              },
                                              () => {
                                                this.export_calculations_handler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}
                          {this.state.et_price_type === "Lumpsum" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Lumpsum Price
                              </label>
                              <TextField
                                name="et_lumpsum_price"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.et_lumpsum_price}
                                placeholder="Price per MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        et_lumpsum_price: e.target.value,
                                      },
                                      () => {
                                        this.export_calculations_handler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="et_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.et_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                et_currency: e.target.value,
                                              },
                                              () => {
                                                this.export_calculations_handler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}
                          {(config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL") &&
                            this.state.et_currency !== "USD" &&
                            this.state.et_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="et_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.et_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        et_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.export_calculations_handler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) &&
                            this.state.et_currency !== "IDR" &&
                            this.state.et_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="et_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.et_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        et_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.export_calculations_handler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className="col-lg-6 p-2 rounder"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-1">
                          <h6>Export Tax</h6>
                        </div>
                        <div className="row pl-2">
                          <div className="col-lg-4 p-0 mb-2">
                            <label className="form_label mb-0">
                              Base Price
                            </label>
                            <TextField
                              name="et_base_price"
                              margin="dense"
                              variant="standard"
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              //fullWidth
                              disabled={
                                this.state.et_price_type === "Lumpsum"
                                  ? true
                                  : false
                              }
                              value={this.state.et_base_price}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = "1";
                                  }
                                  var et_vat_value = (
                                    (Number(value) *
                                      Number(this.state.et_vat_percent)) /
                                    100
                                  ).toFixed(0);
                                  let et_pph23_value = (
                                    (Number(value) *
                                      Number(this.state.et_pph23_percent)) /
                                    100
                                  ).toFixed(0);
                                  if (
                                    config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    et_pph23_value = 0;
                                  }
                                  let dc_ctc = (
                                    Number(e.target.value) +
                                    Number(et_vat_value)
                                  ).toFixed(2);
                                  let dc_ctc_in_local_currency = dc_ctc;
                                  this.setState({
                                    et_base_price: e.target.value,
                                    et_vat_value: et_vat_value,
                                    dc_pph23_value: et_pph23_value,
                                    et_total: (
                                      Number(e.target.value) +
                                      Number(et_vat_value) -
                                      Number(et_pph23_value)
                                    ).toFixed(2),
                                    et_ctc: dc_ctc,
                                    et_ctc_in_local_currency:
                                      dc_ctc_in_local_currency,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label
                              className="form_label mb-0"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <TextField
                              name="et_vat_percent"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.et_vat_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      et_vat_percent: e.target.value,
                                    },
                                    () => {
                                      this.export_calculations_handler()
                                    }
                                  );
                                }
                              }}
                            />
                            {/* <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.et_vat_percent}
                            </div> */}
                          </div>
                          <div className="col-lg-4 mb-2 p-0">
                            <label className="form_label mb-0">VAT</label>
                            <TextField
                              name="et_vat_value"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.et_vat_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  let et_pph23_value = (
                                    (Number(this.state.et_base_price) *
                                      Number(this.state.et_pph23_percent)) /
                                    100
                                  ).toFixed(0);
                                  if (
                                    config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    et_pph23_value = 0;
                                  }
                                  let et_ctc = (
                                    Number(this.state.et_base_price) +
                                    Number(e.target.value)
                                  ).toFixed(2);
                                  let et_ctc_in_local_currency = "";
                                  if (
                                    (config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL") &&
                                    this.state.billing_currency !== "USD" &&
                                    this.state.et_currency !== "0"
                                  ) {
                                    et_ctc_in_local_currency = (
                                      Number(this.state.et_currency_xchg_rate) *
                                      Number(et_ctc)
                                    ).toFixed(0);
                                  }
                                  this.setState({
                                    et_vat_value: e.target.value,
                                    et_pph23_value: et_pph23_value,
                                    et_total:
                                      Number(this.state.et_base_price) +
                                      Number(e.target.value) -
                                      Number(et_pph23_value),
                                    et_ctc: et_ctc,
                                    et_ctc_in_local_currency:
                                      et_ctc_in_local_currency,
                                  });
                                }
                              }}
                            />
                          </div>
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23 %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.et_pph23_percent}
                                </div>
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.et_pph23_value
                                    ? this.toLocaleString(
                                      this.state.dc_pph23_value
                                    )
                                    : null}
                                </div>
                              </div>
                            )}
                          <div className="col-lg-6 mb-2 p-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Payable to Vendor
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.et_total
                                ? this.toLocaleString(this.state.et_total)
                                : null}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Cost to Company
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.et_ctc
                                ? this.toLocaleString(this.state.et_ctc)
                                : null}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        lineHeight: "0.1em",
                        margin: "10px 0 20px",
                        borderBottom: "1px solid #dddd",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#fff",
                          padding: "0 10px",
                          color: "#ec7833",
                        }}
                      >
                        Lapran Survey Charges
                      </span>
                    </p>

                    <div className="row mb-3">
                      <div className="col-lg-6 pl-0 mb-2">
                        <div className="row">
                          <div className="col-lg-12 p-0">
                            <label className="form_label mb-0">
                              Price Type
                            </label>
                            <RadioGroup
                              aria-label="ls_price_type"
                              name="ls_price_type"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    ls_price_type: e.target.value,
                                  },
                                  () => {
                                    this.lapranCalculationHandler();
                                  }
                                );
                              }}
                              style={{ flexDirection: "row" }}
                            >
                              <FormControlLabel
                                value="Per MT"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.ls_price_type === "Per MT"
                                    }
                                  />
                                }
                                label="Per MT"
                              />
                              <FormControlLabel
                                value="Lumpsum"
                                control={
                                  <NewRadio
                                    checked={
                                      this.state.ls_price_type === "Lumpsum"
                                    }
                                  />
                                }
                                label="Lumpsum"
                              />
                            </RadioGroup>
                          </div>
                          {this.state.ls_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Quantity in MT
                              </label>
                              <TextField
                                name="ls_quantity_in_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                type="number"
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    // fontSize: '14px'
                                  },
                                }}
                                value={this.state.ls_quantity_in_mt}
                                placeholder="Quantity in MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    quan.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        ls_quantity_in_mt: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler();
                                      }
                                    );
                                  }
                                }}
                              />
                            </div>
                          )}
                          {this.state.ls_price_type === "Per MT" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Price per MT
                              </label>
                              <TextField
                                name="ls_price_per_mt"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.ls_price_per_mt}
                                placeholder="Price per MT"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        ls_price_per_mt: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="ls_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.ls_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                ls_currency: e.target.value,
                                              },
                                              () => {
                                                this.lapranCalculationHandler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}
                          {this.state.ls_price_type === "Lumpsum" && (
                            <div className="col-lg-12 p-0 mb-2">
                              <label className="form_label mb-0">
                                Lumpsum Price
                              </label>
                              <TextField
                                name="ls_lumpsum_price"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                value={this.state.ls_lumpsum_price}
                                placeholder="Lumpsum price"
                                onChange={(e) => {
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        ls_lumpsum_price: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler();
                                      }
                                    );
                                  }
                                }}
                                InputProps={{
                                  endAdornment: (config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") && (
                                      <InputAdornment
                                        className="adornment_background"
                                        // variant="outlined"
                                        position="end"
                                        style={{
                                          marginRight: "-14px",
                                          width: "50%",
                                        }}
                                      >
                                        <TextField
                                          name="ls_currency"
                                          margin="dense"
                                          // variant='outlined'
                                          fullWidth
                                          select
                                          InputProps={{
                                            style: {
                                              fontFamily: "Poppins",
                                              fontSize: "14px",
                                              margin: 0,
                                            },
                                            disableUnderline: true,
                                          }}
                                          value={this.state.ls_currency}
                                          onChange={(e) => {
                                            this.setState(
                                              {
                                                ls_currency: e.target.value,
                                              },
                                              () => {
                                                this.lapranCalculationHandler();
                                              }
                                            );
                                          }}
                                        >
                                          {this.state.currencyData}
                                        </TextField>
                                      </InputAdornment>
                                    ),
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                              />
                            </div>
                          )}

                          {(config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL") &&
                            this.state.ls_currency !== "USD" &&
                            this.state.ls_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="ls_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.ls_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        ls_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) &&
                            this.state.ls_currency !== "IDR" &&
                            this.state.ls_currency !== "0" && (
                              <div className="col-lg-12 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Currency Exchange Rate
                                </label>
                                <TextField
                                  name="ls_currency_xchg_rate"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  placeholder="Exchange rate"
                                  value={this.state.ls_currency_xchg_rate}
                                  type="number"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        ls_currency_xchg_rate: e.target.value,
                                      },
                                      () => {
                                        this.lapranCalculationHandler();
                                      }
                                    );
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div
                        className="col-lg-6 p-2 rounder"
                        style={{ backgroundColor: "#f7f3f0" }}
                      >
                        <div className="row col-lg-12 p-1">
                          <h6>Laporan Survey Charges</h6>
                        </div>
                        <div className="row pl-2">
                          <div className="col-lg-4 p-0 mb-2">
                            <label className="form_label mb-0">
                              Base Price
                            </label>
                            <TextField
                              name="ls_base_price"
                              margin="dense"
                              variant="standard"
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              disabled={
                                this.ls_price_type === "Lumpusm" ? true : false
                              }
                              //fullWidth
                              value={this.state.ls_base_price}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = "1";
                                  }
                                  let ls_vat_value = (
                                    (Number(value) *
                                      Number(this.state.dc_vat_percent)) /
                                    100
                                  ).toFixed(2);
                                  let ls_pph23_value = (
                                    (Number(value) *
                                      Number(this.state.dc_pph23_percent)) /
                                    100
                                  ).toFixed(2);
                                  if (
                                    config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL"
                                  ) {
                                    ls_pph23_value = 0;
                                  }
                                  this.setState({
                                    ls_base_price: e.target.value,
                                    ls_vat_value: ls_vat_value,
                                    ls_pph23_value: ls_pph23_value,
                                    ls_total: (
                                      Number(e.target.value) +
                                      Number(ls_vat_value) -
                                      Number(ls_pph23_value)
                                    ).toFixed(2),
                                    ls_ctc: (
                                      Number(e.target.value) +
                                      Number(ls_vat_value)
                                    ).toFixed(2),
                                  });
                                }
                              }}
                            />
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label
                              className="form_label mb-0"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <TextField
                              name="ls_vat_percent"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.ls_vat_percent}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      ls_vat_percent: e.target.value,
                                    },
                                    () => {
                                      this.lapranCalculationHandler()
                                    }
                                  );
                                }
                              }}
                            />
                            {/* <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              VAT %
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.ls_vat_percent}
                            </div> */}
                          </div>
                          <div className="col-lg-4 mb-2 pr-0">
                            <label className="form_label mb-0">VAT</label>
                            <TextField
                              name="ls_vat_value"
                              margin="dense"
                              variant="standard"
                              fullWidth
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              value={this.state.ls_vat_value}
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState({
                                    ls_vat_value: e.target.value,
                                    ls_total: (
                                      Number(this.state.ls_base_price) +
                                      Number(e.target.value) -
                                      Number(this.state.ls_pph23_value)
                                    ).toFixed(2),
                                    ls_ctc: (
                                      Number(this.state.ls_base_price) +
                                      Number(e.target.value)
                                    ).toFixed(2),
                                  });
                                }
                              }}
                            />
                          </div>
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 p-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23 %
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.ls_pph23_percent}
                                </div>
                              </div>
                            )}
                          {!(
                            config.company === "SRPL" || config.company === "AVS" ||
                            config.company === "PRPL"
                          ) && (
                              <div className="col-lg-6 mb-2 pr-0">
                                <label
                                  className="contract_display_header_label"
                                  style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                >
                                  PPH23
                                </label>
                                <div
                                  className="contract_display_header_value"
                                  style={{ color: "#42352d" }}
                                >
                                  {this.state.ls_pph23_value
                                    ? this.toLocaleString(
                                      this.state.ls_pph23_value
                                    )
                                    : 0}
                                </div>
                              </div>
                            )}
                          <div className="col-lg-6 mb-2 p-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Payable to Vendor
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.ls_total
                                ? this.toLocaleString(this.state.ls_total)
                                : 0}
                            </div>
                          </div>
                          <div className="col-lg-6 mb-2 pr-0">
                            <label
                              className="contract_display_header_label"
                              style={{ color: "rgba(72, 59, 52, 0.7)" }}
                            >
                              Cost to Company
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ color: "#42352d" }}
                            >
                              {this.state.ls_ctc
                                ? this.toLocaleString(this.state.ls_ctc)
                                : 0}{" "}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FileUpload
                    files={this.state.files}
                    feature_name="Return_cargo_charges"
                    callbackFn={(data) => this.setState({ files: data })}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={() => this.props.onCancel(false)}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={this.submitHandler}
              disabled={this.state.submitLoading}
            >
              SUBMIT
              <span
                className={
                  this.state.submitLoading
                    ? "spinner-grow spinner-grow-sm mr-2"
                    : ""
                }
              ></span>
            </button>
          </div>
        </div>
      );
    }
  }
}
