import React, { Component } from "react";
import Header from "../common/Header";
import SideBar from "../common/SideBar";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";

import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@material-ui/core";
import Loader from "../common/Loader";

export default class MailConfig extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      action_types: [
        {
          feature_name: "Barge Purchase Performa Coal Costing",
          action_type: "costing_bg_pur_performa_coal",
          emails: null,
        },
        {
          feature_name: "Barge Purchase Commerical Coal Costing",
          action_type: "costing_bg_pur_commercial_coal",
          emails: null,
        },
        {
          feature_name: "Barge Costing",
          action_type: "costing_bg_barge",
          emails: null,
        },
        {
          feature_name: "Chemical Spray",
          action_type: "costing_bg_chemical_spray",
          emails: null,
        },
        {
          feature_name: "Barge Export Tax",
          action_type: "costing_bg_export_tax",
          emails: null,
        },
        {
          feature_name: "Barge Purchase Documentation Charges",
          action_type: "costing_bg_doc_charges",
          emails: null,
        },
        {
          feature_name: "Barge Sampling & Analysis",
          action_type: "costing_bg_sac",
          emails: null,
        },
        {
          feature_name: "Barge Pre Shipment",
          action_type: "costing_bg_pre_shipment",
          emails: null,
        },
        {
          feature_name: "Barge Coal Procurement Charges",
          action_type: "costing_bg_cpc",
          emails: null,
        },
        {
          feature_name: "Barge Coal Procurement Service Fee",
          action_type: "costing_bg_cps",
          emails: null,
        },
        {
          feature_name: "Barge Vendor Demurrage",
          action_type: "costing_bg_vendor_dmr",
          emails: null,
        },
        {
          feature_name: `${config.company} to Supplier Demurrage`,
          action_type: "costing_bg_company2supplier_dmr",
          emails: null,
        },
        {
          feature_name: `Supplier to ${config.company} Demurrage`,
          action_type: "costing_bg_supplier2company_dmr",
          emails: null,
        },
        {
          feature_name: `${config.company} to Buyer Demurrage`,
          action_type: "costing_bg_company2buyer_dmr",
          emails: null,
        },
        {
          feature_name: `Buyer to ${config.company} Demurrage`,
          action_type: "costing_bg_buyer2company_dmr",
          emails: null,
        },
        {
          feature_name: "Barge Other Expenses",
          action_type: "costing_bg_other_expenses",
          emails: null,
        },
        {
          feature_name: "Barge Insurance",
          action_type: "costing_bg_insurance",
          emails: null,
        },
        {
          feature_name: "Return Cargo Charges",
          action_type: "costing_bg_return_cargo",
          emails: null,
        },
        {
          feature_name: "Barge Sales Performa Coal Costing",
          action_type: "costing_mv_sales_performa_coal",
          emails: null,
        },
        {
          feature_name: "Barge Sales Commerical Coal Costing",
          action_type: "costing_mv_sales_commercial_coal",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Floating Crane Charges",
          action_type: "costing_mv_floating_crane",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Stevedore Costing",
          action_type: "costing_mv_stevedore",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Freight Costing",
          action_type: "costing_mv_freight",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Coal Procurement Charges",
          action_type: "costing_mv_cpc",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Coal Sales Charges",
          action_type: "costing_mv_csc",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Sampling & Analysis",
          action_type: "costing_mv_sac",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Pre Shipment Inspection",
          action_type: "costing_mv_pre_shipment",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Export tax",
          action_type: "costing_mv_export_tax",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Documentation Charges",
          action_type: "costing_mv_doc_charges",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Supplier Demurrage",
          action_type: "costing_mv_supplier_dmr",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Buyer Demurrage",
          action_type: "costing_mv_buyer_dmr",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Incoming Debit Note",
          action_type: "costing_mv_in_debit_note",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Outgoing Debit Note",
          action_type: "costing_mv_out_debit_note",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Incoming Credit Note",
          action_type: "costing_mv_in_credit_note",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Outgoing Credit Note",
          action_type: "costing_mv_out_credit_note",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Other Expenses",
          action_type: "costing_mv_other_expenses",
          emails: null,
        },
        {
          feature_name: "Mother Vessel Other Income",
          action_type: "costing_mv_other_income",
          emails: null,
        },
        {
          feature_name: "Barge Agreement Level1",
          action_type: "Barging Agreement Level1",
          emails: null,
        },
        {
          feature_name: "Barge Agreement Level2",
          action_type: "Barging Agreement Level2",
          emails: null,
        },
      ],
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.get_email_config(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            const results = this.state.action_types.filter(
              (elem) =>
                !res.action_types.find(
                  ({ action_type }) => elem.action_type === action_type
                )
            );
            this.setState({
              isLoading: false,
              action_types: [...res.action_types, ...results],
            });
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              isLoading: false,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
    document.title = config.documentTitle + "Email Config";
  }

  submitHandler = () => {
    this.setState({ submitting: true });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const action_types = this.state.action_types;
    api
      .update_email_config(loginUserID, idToken, action_types)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  submitting: false,
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && <Loader />}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="content-header">
            <div className="col-sm pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {" "}
                <a href={"/reports"}>
                  <i className="fa fa-arrow-left left_arrow_adjst" />
                </a>
                Email Config
              </h4>
            </div>
          </div>
          {!this.state.isLoading && (
            <div className="container p-2">
              <div className="card" style={{ overflowY: "auto" }}>
                <div className="row">
                  <div className="col-lg-12 p-3">
                    <div className="section_block" style={{ width: "100%" }}>
                      <div className="row">
                        <div className="table-responsive">
                          <table className="table table-borderless">
                            <thead
                              style={{ backgroundColor: "rgb(249, 249, 249)" }}
                            >
                              <tr>
                                <th>Feature Name</th>
                                <th>Emails</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.action_types.map((e, idx) => (
                                <tr key={e.action_type}>
                                  <td>{e.feature_name}</td>
                                  <td>
                                    <TextField
                                      name="emails"
                                      margin="dense"
                                      multiline
                                      rows={3}
                                      variant="outlined"
                                      placeholder="Emails"
                                      value={e.emails}
                                      onChange={(e) => {
                                        let row = this.state.action_types;
                                        row[idx].emails = e.target.value;
                                        // console.log(row);
                                        this.setState({
                                          action_types: row,
                                        });
                                      }}
                                      inputProps={{
                                        style: { textTransform: "uppercase" },
                                      }}
                                      fullWidth
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() => (window.location.href = "/reports")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={this.submitHandler}
                >
                  Submit
                </button>
              </section>
            </div>
          )}
        </div>
      </div>
    );
  }
}
