function Workbook() {
  if (!(this instanceof Workbook))
    return new Workbook()

  this.SheetNames = []
  this.Sheets = {}

}

const download = (url, name) => {
  let a = document.createElement('a')
  a.href = url
  a.download = name
  a.click()

  window.URL.revokeObjectURL(url)
}


function s2ab(s) {
  const buf = new ArrayBuffer(s.length)

  const view = new Uint8Array(buf)

  for (let i = 0; i !== s.length; ++i)
    view[i] = s.charCodeAt(i) & 0xFF

  return buf
}

function toLocaleString(value, quantity) {
  if (value) {
    return Number(value).toLocaleString("en-IN", { minimumFractionDigits: quantity ? 3 : 2 });
  }
  else {
    return null
  }
}


const excel_data = (data, name) => {
  import('xlsx').then(XLSX => {
    const wb = new Workbook();
    if (name === "Profit And Loss") {
      var row = data;

      // Purchase
      let Purchase = []
      for (var cp of row.coal_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Coal Purchase - " + cp.supplier_name,
          "GRADE": cp.quality,
          "QTY (MT)": toLocaleString(Number(cp.barge_quantity ? cp.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(cp.adjusted_coal_price_pmt_idr ? cp.adjusted_coal_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(cp.adjusted_coal_price_pmt_usd ? cp.adjusted_coal_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(cp.adjusted_coal_price_idr ? cp.adjusted_coal_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(cp.adjusted_coal_price_usd ? cp.adjusted_coal_price_usd : 0))
        });
      }
      for (var bc of row.barge_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Barging - " + bc.barge_vendor_name,
          "GRADE": bc.quality,
          "QTY (MT)": toLocaleString(Number(bc.barge_quantity ? bc.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(bc.barging_base_price_idr ? bc.barging_base_price_idr : 0)),
          "RATE ($)": toLocaleString(Number(bc.barging_base_price_usd ? bc.barging_base_price_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(bc.barge_ctc_idr ? bc.barge_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(bc.barge_ctc_usd ? bc.barge_ctc_usd : 0))
        });
      }
      for (var cs of row.chemical_spray_data) {
        Purchase.push({
          "DESCRIPTION": "Chemical",
          "GRADE": cs.quality,
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(cs.chemical_spray_price_idr ? cs.chemical_spray_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(cs.chemical_spray_price_usd ? cs.chemical_spray_price_usd : 0))
        });
      }
      for (var ex_tax of row.export_tax_data) {
        Purchase.push({
          "DESCRIPTION": "Export Tax",
          "GRADE": ex_tax.quality,
          "QTY (MT)": toLocaleString(Number(ex_tax.quantity_for_export_tax ? ex_tax.quantity_for_export_tax : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(ex_tax.export_tax_per_mt_idr ? ex_tax.export_tax_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(ex_tax.export_tax_per_mt_usd ? ex_tax.export_tax_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(ex_tax.export_tax_idr ? ex_tax.export_tax_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(ex_tax.export_tax_usd ? ex_tax.export_tax_usd : 0))
        });
      }
      for (var doc of row.doc_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Documentation Charges",
          "GRADE": doc.quality,
          "QTY (MT)": toLocaleString(Number(doc.dc_quantity_in_mt ? doc.dc_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(doc.dc_price_per_mt_idr ? doc.dc_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(doc.dc_price_per_mt_usd ? doc.dc_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(doc.dc_ctc_idr ? doc.dc_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(doc.dc_ctc_usd ? doc.dc_ctc_usd : 0)),
        });
      }
      for (var doc_lapr of row.doc_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Laporan Charges",
          "GRADE": doc_lapr.quality,
          "QTY (MT)": toLocaleString(Number(doc_lapr.dc_quantity_in_mt ? doc_lapr.dc_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(doc_lapr.dc_laprn_surv_price_per_mt_idr ? doc_lapr.dc_laprn_surv_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(doc_lapr.dc_laprn_surv_price_per_mt_usd ? doc_lapr.dc_laprn_surv_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(doc_lapr.dc_laprn_surv_ctc_idr ? doc_lapr.dc_laprn_surv_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(doc_lapr.dc_laprn_surv_ctc_usd ? doc_lapr.dc_laprn_surv_ctc_usd : 0)),
        });
      }
      for (var sac of row.sac_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Sampling & Anlaysis",
          "GRADE": sac.quality,
          "QTY (MT)": toLocaleString(Number(sac.sac_quantity_in_mt ? sac.sac_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(sac.sac_price_per_mt_idr ? sac.sac_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(sac.sac_price_per_mt_usd ? sac.sac_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(sac.sac_surveyor_ctc_idr ? sac.sac_surveyor_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(sac.sac_surveyor_ctc_usd ? sac.sac_surveyor_ctc_usd : 0))
        });
      }
      for (var pre of row.pre_shipment_data) {
        Purchase.push({
          "DESCRIPTION": "Pre Shipment",
          "GRADE": pre.quality,
          "QTY (MT)": toLocaleString(Number(pre.sac_quantity_in_mt ? pre.sac_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(pre.pre_shipment_price_per_mt_idr ? pre.pre_shipment_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(pre.pre_shipment_price_per_mt_usd ? pre.pre_shipment_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(pre.pre_shipment_ctc_idr ? pre.pre_shipment_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(pre.pre_shipment_ctc_usd ? pre.pre_shipment_ctc_usd : 0))
        });
      }
      for (var cpc of row.cpc_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Coal Procurement Charges",
          "GRADE": cpc.quality,
          "QTY (MT)": toLocaleString(Number(cpc.barge_quantity ? cpc.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(cpc.base_price_pmt_idr ? cpc.base_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(cpc.base_price_pmt_usd ? cpc.base_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(cpc.cost_to_company_idr ? cpc.cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(cpc.cost_to_company_usd ? cpc.cost_to_company_usd : 0))
        });
      }
      for (var csc of row.csc_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Barge Coal Sales Charges",
          "GRADE": csc.quality,
          "QTY (MT)": toLocaleString(Number(csc.barge_quantity ? csc.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(csc.base_price_pmt_idr ? csc.base_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(csc.base_price_pmt_usd ? csc.base_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(csc.cost_to_company_idr ? csc.cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(csc.cost_to_company_usd ? csc.cost_to_company_usd : 0))
        });
      }
      for (var mv_csc of row.mv_sales_fin_csc) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Coal Sales Charges",
          "GRADE": mv_csc.quality,
          "QTY (MT)": toLocaleString(Number(mv_csc.barge_quantity ? mv_csc.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_csc.base_price_pmt_idr ? mv_csc.base_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_csc.base_price_pmt_usd ? mv_csc.base_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_csc.cost_to_company_idr ? mv_csc.cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_csc.cost_to_company_usd ? mv_csc.cost_to_company_usd : 0))
        });
      }
      for (var mv_sc of row.mv_stevedore_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Stevedore Costing - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.stevedore_price_pmt_idr ? mv_sc.stevedore_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.stevedore_price_pmt_usd ? mv_sc.stevedore_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.stevedore_base_price_idr ? mv_sc.stevedore_base_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.stevedore_base_price_usd ? mv_sc.stevedore_base_price_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "PNBP - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.pnbp_per_mt_idr ? mv_sc.pnbp_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.pnbp_per_mt_usd ? mv_sc.pnbp_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.pnbp_idr ? mv_sc.pnbp_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.pnbp_usd ? mv_sc.pnbp_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "PBM - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.jasa_pbm_pmt_idr ? mv_sc.jasa_pbm_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.jasa_pbm_pmt_usd ? mv_sc.jasa_pbm_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.jasa_pbm_value_idr ? mv_sc.jasa_pbm_value_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.jasa_pbm_value_usd ? mv_sc.jasa_pbm_value_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Additional Days - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.stevedore_price_per_addl_day_idr ? mv_sc.stevedore_price_per_addl_day_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.stevedore_price_per_addl_day_usd ? mv_sc.stevedore_price_per_addl_day_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.stevedore_addl_cost_to_company_idr ? mv_sc.stevedore_addl_cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.stevedore_addl_cost_to_company_usd ? mv_sc.stevedore_addl_cost_to_company_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Waiting Period - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.wait_period_price_per_hour_idr ? mv_sc.wait_period_price_per_hour_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.wait_period_price_per_hour_usd ? mv_sc.wait_period_price_per_hour_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.wait_period_total_price_idr ? mv_sc.wait_period_total_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.wait_period_total_price_usd ? mv_sc.wait_period_total_price_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Others - " + mv_sc.stevedore_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.total_price_idr ? mv_sc.total_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.total_price_usd ? mv_sc.total_price_usd : 0))
        });
      }
      for (var mv_fc of row.mv_floating_crane_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Floating Crane - " + mv_fc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_fc.quantity ? mv_fc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_fc.floating_crane_price_pmt_idr ? mv_fc.floating_crane_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_fc.floating_crane_price_pmt_usd ? mv_fc.floating_crane_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_fc.floating_crane_base_price_idr ? mv_fc.floating_crane_base_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_fc.floating_crane_base_price_usd ? mv_fc.floating_crane_base_price_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "PNBP - " + mv_sc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.pnbp_per_mt_idr ? mv_sc.pnbp_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.pnbp_per_mt_usd ? mv_sc.pnbp_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.pnbp_idr ? mv_sc.pnbp_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.pnbp_usd ? mv_sc.pnbp_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "PBM - " + mv_sc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.jasa_pbm_pmt_idr ? mv_sc.jasa_pbm_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.jasa_pbm_pmt_usd ? mv_sc.jasa_pbm_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.jasa_pbm_value_idr ? mv_sc.jasa_pbm_value_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.jasa_pbm_value_usd ? mv_sc.jasa_pbm_value_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Additional Days - " + mv_sc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.floating_crane_price_per_addl_day_idr ? mv_sc.floating_crane_price_per_addl_day_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.floating_crane_price_per_addl_day_usd ? mv_sc.floating_crane_price_per_addl_day_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.floating_crane_addl_cost_to_company_idr ? mv_sc.floating_crane_addl_cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.floating_crane_addl_cost_to_company_usd ? mv_sc.floating_crane_addl_cost_to_company_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Waiting Period - " + mv_sc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sc.wait_period_price_per_hour_idr ? mv_sc.wait_period_price_per_hour_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sc.wait_period_price_per_hour_usd ? mv_sc.wait_period_price_per_hour_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.wait_period_total_price_idr ? mv_sc.wait_period_total_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.wait_period_total_price_usd ? mv_sc.wait_period_total_price_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Others - " + mv_sc.floating_crane_vendor_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sc.quantity ? mv_sc.quantity : 0), "quantity"),
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(mv_sc.total_price_idr ? mv_sc.total_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sc.total_price_usd ? mv_sc.total_price_usd : 0))
        });
      }
      for (var fr of row.mv_freight_costing_data) {
        Purchase.push({
          "DESCRIPTION": "Floating Crane - " + fr.vessel_owner_name,
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(fr.quantity ? fr.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(fr.price_pmt_idr ? fr.price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(fr.price_pmt_usd ? fr.price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(fr.amount_idr ? fr.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(fr.amount_usd ? fr.amount_usd : 0))
        });
      }
      for (var mv_cpc of row.mv_pur_fin_cpc_data) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Coal Procurement",
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_cpc.quantity ? mv_cpc.quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_cpc.base_price_pmt_idr ? mv_cpc.base_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_cpc.base_price_pmt_usd ? mv_cpc.base_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_cpc.cost_to_company_idr ? mv_cpc.cost_to_company_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_cpc.cost_to_company_usd ? mv_cpc.cost_to_company_usd : 0))
        });
      }
      for (var mv_sac of row.mv_sac_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Sampling & Analaysis",
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_sac.sac_quantity_in_mt ? mv_sac.sac_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_sac.sac_price_per_mt_idr ? mv_sac.sac_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_sac.sac_price_per_mt_usd ? mv_sac.sac_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_sac.sac_surveyor_ctc_idr ? mv_sac.sac_surveyor_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_sac.sac_surveyor_ctc_usd ? mv_sac.sac_surveyor_ctc_usd : 0))
        });
      }
      for (var mv_pre of row.mv_pre_shipment_data) {
        Purchase.push({
          "DESCRIPTION": "Pre Shipment",
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_pre.sac_quantity_in_mt ? mv_pre.sac_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_pre.pre_shipment_price_per_mt_idr ? mv_pre.pre_shipment_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_pre.pre_shipment_price_per_mt_usd ? mv_pre.pre_shipment_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_pre.pre_shipment_ctc_idr ? mv_pre.pre_shipment_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_pre.pre_shipment_ctc_usd ? mv_pre.pre_shipment_ctc_usd : 0))
        });
      }
      for (var mv_exp of row.mv_export_tax_data) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Export Tax",
          "GRADE": mv_exp.quality,
          "QTY (MT)": toLocaleString(Number(mv_exp.quantity_for_export_tax ? mv_exp.quantity_for_export_tax : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_exp.export_tax_per_mt_idr ? mv_exp.export_tax_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_exp.export_tax_per_mt_usd ? mv_exp.export_tax_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_exp.export_tax_idr ? mv_exp.export_tax_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_exp.export_tax_usd ? mv_exp.export_tax_usd : 0))
        });
      }
      for (var mv_doc of row.mv_doc_charges_data) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Documentation Charges",
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_doc.dc_quantity_in_mt ? mv_doc.dc_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_doc.dc_price_per_mt_idr ? mv_doc.dc_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_doc.dc_price_per_mt_usd ? mv_doc.dc_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_doc.dc_ctc_idr ? mv_doc.dc_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_doc.dc_ctc_usd ? mv_doc.dc_ctc_usd : 0))
        });
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Laporan Charges",
          "GRADE": "-",
          "QTY (MT)": toLocaleString(Number(mv_doc.dc_quantity_in_mt ? mv_doc.dc_quantity_in_mt : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(mv_doc.dc_laprn_surv_price_per_mt_idr ? mv_doc.dc_laprn_surv_price_per_mt_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_doc.dc_laprn_surv_price_per_mt_usd ? mv_doc.dc_laprn_surv_price_per_mt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_doc.dc_laprn_surv_ctc_idr ? mv_doc.dc_laprn_surv_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_doc.dc_laprn_surv_ctc_usd ? mv_doc.dc_laprn_surv_ctc_usd : 0))
        });
      }

      const mv_sup_dem_despatch = row.mv_supplier_demurrage.filter(i => i.demurrage_or_despatch === "Despatch");
      for (var mv_des of mv_sup_dem_despatch) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Supplier Demurrage - " + mv_des.supplier_name ? mv_des.supplier_name : '',
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": toLocaleString(Number(mv_des.supplier_base_price_idr ? mv_des.supplier_base_price_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_des.supplier_base_price_usd ? mv_des.supplier_base_price_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_des.supplier_ctc_idr ? mv_des.supplier_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_des.supplier_ctc_usd ? mv_des.supplier_ctc_usd : 0))
        });
      }
      const mv_buy_demurrage = row.mv_buyer_demurrage.filter(i => i.demurrage_or_despatch === "Demurrage");
      for (var mv_buy_dem of mv_buy_demurrage) {
        Purchase.push({
          "DESCRIPTION": "Mother Vessel Buyer Demurrage - " + mv_buy_dem.buyer_name,
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": toLocaleString(Number(mv_buy_dem.buyer_base_price_idr ? mv_buy_dem.buyer_base_price_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_buy_dem.buyer_base_price_usd ? mv_buy_dem.buyer_base_price_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_buy_dem.buyer_ctc_idr ? mv_buy_dem.buyer_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_buy_dem.buyer_ctc_usd ? mv_buy_dem.buyer_ctc_usd : 0))
        });
      }
      for (var exp of row.business_no_other_expenses_data) {
        Purchase.push({
          "DESCRIPTION": "Other Expenses - " + exp.vendor_name,
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(exp.amount_idr ? exp.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(exp.amount_usd ? exp.amount_usd : 0))
        });
      }
      for (var incom of row.business_no_other_income_data) {
        Purchase.push({
          "DESCRIPTION": "Other Income - " + incom.customer_name,
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(incom.amount_idr ? incom.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(incom.amount_usd ? incom.amount_usd : 0))
        });
      }
      const incoming_debit_note = row.business_no_debit_note_data.filter(i => i.debit_type === "Incoming");
      for (var in_deb of incoming_debit_note) {
        Purchase.push({
          "DESCRIPTION": "Incoming Debit Note",
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(in_deb.amount_idr ? in_deb.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(in_deb.amount_usd ? in_deb.amount_usd : 0))
        });
      }
      const outgoing_credit_note = row.business_no_credit_note_data.filter(i => i.credit_type === "Outgoing");
      for (var out_cre of outgoing_credit_note) {
        Purchase.push({
          "DESCRIPTION": "Outgoing Credit Note",
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(out_cre.amount_idr ? out_cre.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(out_cre.amount_usd ? out_cre.amount_usd : 0))
        });
      }


      var ws = XLSX.utils.json_to_sheet(Purchase);
      wb.SheetNames.push("Purchase");
      wb.Sheets["Purchase"] = ws;

      // Sales Finanacials
      let sales = [];

      for (var scs of row.sales_coal_costing_data) {
        sales.push({
          "DESCRIPTION": "Sales Purchase - " + scs.buyer_name ? scs.buyer_name : '',
          "GRADE": scs.quality,
          "QTY (MT)": toLocaleString(Number(scs.barge_quantity ? scs.barge_quantity : 0), "quantity"),
          "RATE (IDR)": toLocaleString(Number(scs.adjusted_coal_price_pmt_idr ? scs.adjusted_coal_price_pmt_idr : 0)),
          "RATE ($)": toLocaleString(Number(scs.adjusted_coal_price_pmt_usd ? scs.adjusted_coal_price_pmt_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(scs.adjusted_coal_price_idr ? scs.adjusted_coal_price_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(scs.adjusted_coal_price_usd ? scs.adjusted_coal_price_usd : 0))
        });
      }
      const mv_demurrage = row.mv_supplier_demurrage.filter(i => i.demurrage_or_despatch === "Demurrage");
      for (var mv_dem of mv_demurrage) {
        sales.push({
          "DESCRIPTION": "Mother Vessel Supplier Demurrage - " + mv_dem.supplier_name ? mv_dem.supplier_name : '',
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": toLocaleString(Number(mv_dem.supplier_base_price_idr ? mv_dem.supplier_base_price_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_dem.supplier_base_price_usd ? mv_dem.supplier_base_price_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_dem.supplier_ctc_idr ? mv_dem.supplier_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_dem.supplier_ctc_usd ? mv_dem.supplier_ctc_usd : 0))
        });
      }
      const mv_buy_dem_despatch = row.mv_buyer_demurrage.filter(i => i.demurrage_or_despatch === "Despatch");
      for (var mv_dem_des of mv_buy_dem_despatch) {
        sales.push({
          "DESCRIPTION": "Mother Vessel Buyer Demurrage - " + mv_dem_des.buyer_name ? mv_dem_des.buyer_name : '',
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": toLocaleString(Number(mv_dem_des.buyer_base_price_idr ? mv_dem_des.buyer_base_price_idr : 0)),
          "RATE ($)": toLocaleString(Number(mv_dem_des.buyer_base_price_usd ? mv_dem_des.buyer_base_price_usd : 0)),
          "AMOUNT (IDR)": toLocaleString(Number(mv_dem_des.buyer_ctc_idr ? mv_dem_des.buyer_ctc_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(mv_dem_des.buyer_ctc_usd ? mv_dem_des.buyer_ctc_usd : 0))
        });
      }
      const outgoing_debit_note = row.business_no_debit_note_data.filter(i => i.debit_type === "Outgoing");
      for (var out_deb of outgoing_debit_note) {
        sales.push({
          "DESCRIPTION": "Outgoing Debit Note",
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(out_deb.amount_idr ? out_deb.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(out_deb.amount_usd ? out_deb.amount_usd : 0))
        });
      }
      const incoming_credit_note = row.business_no_credit_note_data.filter(i => i.credit_type === "Incoming");
      for (var in_cre of incoming_credit_note) {
        sales.push({
          "DESCRIPTION": "Incoming Credit Note",
          "GRADE": "-",
          "QTY (MT)": "-",
          "RATE (IDR)": "-",
          "RATE ($)": "-",
          "AMOUNT (IDR)": toLocaleString(Number(in_cre.amount_idr ? in_cre.amount_idr : 0)),
          "AMOUNT ($)": toLocaleString(Number(in_cre.amount_usd ? in_cre.amount_usd : 0))
        });
      }
      ws = XLSX.utils.json_to_sheet(sales);
      wb.SheetNames.push("sales");
      wb.Sheets["sales"] = ws;
    }
    else {
      let ws = XLSX.utils.json_to_sheet(data);
      wb.SheetNames.push(name);
      wb.Sheets[name] = ws;
    }

    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

    let url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }))
    download(url, name + '.xlsx');
  });
}

export default excel_data;
