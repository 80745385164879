import React, { Component } from "react";
import CookieHandler from "../../common/CookieHandler";
import { Alert } from "@material-ui/lab";
import {
  Snackbar,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import config from "../../../config/config";
import api from "../../../api/api";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import DateFnsUtils from "@date-io/date-fns";
import WarningIcon from "@material-ui/icons/Warning";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";
import SalesAdvanceAdjustments from "../../contracts/SalesAdvanceAdjustments";
import InvoiceForm from "../../common/InvoiceForm";

export default class ViewSalesPI extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      description: "",
      coal_cost_billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      coal_cost_penality_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",

      coal_cost_coal_quantity: "",
      coal_cost_coal_price: "",
      coal_cost_adjusted_coal_price_pmt: "",
      coal_cost_adjusted_coal_price: "",
      coal_cost_pph22_barge_quantity_in_mt: "",
      coal_cost_pph22_coal_price_per_mt: "",
      coal_cost_pph22_percent: "",
      coal_cost_pph22_percent_val: "",
      coal_cost_pph22_export: "",
      coal_cost_pph22_export_amount: "",
      coal_cost_pph22_export_percent: "1.5",
      coal_cost_pph22_export_value: "",
      coal_cost_currency_xchg_rate: "",
      coal_cost_adjusted_coal_price_in_local_currency: "",
      openAdvAdjustments: false,
      remaining_cus_adv_payment: 100000,
      //Advance adjustments part

      advance_adjustments: [
        {
          advance_adjustment: "No",
          vendor_adv_contract_id: "",
          advance_adjustment_value: "",
          advance_adjustment_barge_quantity: "",
          adjustment_per_mt: "",
          adjustment_from: "",
          sales_contract_id: "",
          total_customer_adv_payment: "",
        },
      ],

      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
        invoice_percentage: "",
      },

      sales_term: "Non Specs",

      performa_advance_adjustment: 0,
      totalCoalPaymentsAmount: "",
      // Invoices date
      invoiceFormTitle: "Add",
      performaInvoicesData: [],
      performa_invoices: [],
      date_of_payment: null,
      performaExpandSelected: null,

      errorMsg: "",
      successMsg: "",
      isLoading: true,
      features: [],
      files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("191") ||
        features.includes("404") ||
        features.includes("455") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const mv_sales_costing_id = this.props.mv_sales_costing_id;

    await api
      .get_mv_sales_performa_coal_costing(
        loginUserID,
        idToken,
        mv_sales_costing_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.mv_sales_performa) {
                var totalAdjustments = 0;
                for (var obj of res.mv_sales_performa.advance_adjustments) {
                  totalAdjustments += Number(obj.advance_adjustment_value);
                  obj["sales_contract_no"] =
                    res.mv_sales_performa.sales_contract_no;
                }
                let coal_cost_coal_price_pmt_local_currency = (
                  Number(res.mv_sales_performa.coal_price_pmt) *
                  Number(res.mv_sales_performa.currency_xchg_rate)
                ).toFixed(2);
                if (
                  (config.company === "SDAM" ||
                    config.company === "SRK" ||
                    config.company === "BTR") &&
                  res.mv_sales_performa.billing_currency === "IDR"
                ) {
                  coal_cost_coal_price_pmt_local_currency = (
                    Number(res.mv_sales_performa.coal_price_pmt) *
                    Number(res.mv_sales_performa.currency_xchg_rate)
                  ).toFixed(0);
                }
                if (
                  config.company === "SRPL" ||
                  config.company === "AVS" ||
                  config.company === "PRPL"
                ) {
                  coal_cost_coal_price_pmt_local_currency = (
                    Number(res.mv_sales_performa.coal_price_pmt) /
                    Number(res.mv_sales_performa.currency_xchg_rate)
                  ).toFixed(2);
                }
                let invoiceTotalAmount = 0;
                let receivedAmount = 0;
                for (var i of res.mv_sales_performa.invoices) {
                  invoiceTotalAmount += Number(i.invoice_amount);
                  for (var j of i.payments) {
                    receivedAmount += Number(j.amount);
                  }
                }
                // var performa_advance_adjustment =0;
                // for (let n = 0; n < res.mv_sales_performa.advance_adjustments.length; n++) {
                //   performa_advance_adjustment += Number(res.mv_sales_performa.advance_adjustments[n].advance_adjustment_value)
                // }

                let remaining_amount =
                  Number(invoiceTotalAmount) - Number(receivedAmount);

                let invoice_details = {
                  ...this.state.invoiceDetails,
                  invoice_date: res.mv_sales_performa.invoice_date,
                  ppn: res.mv_sales_performa.ppn,
                  ppn_date: res.mv_sales_performa.ppn_date,
                  invoice_no: res.mv_sales_performa.invoice_no,
                };
                this.setState({
                  openAdvAdjustments: false,
                  invoiceDetails: invoice_details,
                  invoiceTotalAmount: invoiceTotalAmount,
                  receivedAmount: receivedAmount,
                  receivedFromBuyer: receivedAmount,
                  remaining_amount: remaining_amount,
                  remainingAmount:
                    Number(res.mv_sales_performa.receivable_from_supplier) -
                    Number(receivedAmount),
                  barge_sales_fin_id: mv_sales_costing_id,
                  sales_contract_id: res.mv_sales_performa.sale_contract_id,
                  sales_contract_no: res.mv_sales_performa.sales_contract_no,
                  sales_index_linked: res.mv_sales_performa.sales_index_linked,
                  sales_currency: res.mv_sales_performa.sales_currency,
                  sales_type: res.mv_sales_performa.sales_type,
                  sales_term: res.mv_sales_performa.sales_term,
                  invoice_file: res.mv_sales_performa.invoice_file,
                  invoice_number: res.mv_sales_performa.invoice_no
                    ? res.mv_sales_performa.invoice_no
                    : null,
                  costing_no: res.mv_sales_performa.costing_no
                    ? res.mv_sales_performa.costing_no
                    : null,
                  created_by: res.mv_sales_performa.created_by
                    ? res.mv_sales_performa.created_by
                    : null,
                  approval_status: res.mv_sales_performa.approval_status
                    ? res.mv_sales_performa.approval_status
                    : "Pending",
                  approved_by: res.mv_sales_performa.approved_by
                    ? res.mv_sales_performa.approved_by
                    : "",
                  approval_status_2: res.mv_sales_performa.approval_status_2
                    ? res.mv_sales_performa.approval_status_2
                    : "Pending",

                  coal_cost_coal_quantity: res.mv_sales_performa.barge_quantity
                    ? res.mv_sales_performa.barge_quantity
                    : "",
                  coal_cost_coal_price_pmt: res.mv_sales_performa.coal_price_pmt
                    ? res.mv_sales_performa.coal_price_pmt
                    : null,
                  coal_cost_coal_price_pmt_local_currency:
                    res.mv_sales_performa.coal_price_pmt &&
                    res.mv_sales_performa.currency_xchg_rate
                      ? coal_cost_coal_price_pmt_local_currency
                      : null,
                  coal_cost_coal_price: res.mv_sales_performa.coal_price
                    ? res.mv_sales_performa.coal_price
                    : "",
                  coal_cost_billing_currency: res.mv_sales_performa
                    .billing_currency
                    ? res.mv_sales_performa.billing_currency
                    : "",
                  coal_cost_penality_currency: res.mv_sales_performa
                    .penality_currency
                    ? res.mv_sales_performa.penality_currency
                    : "",
                  //performa_advance_adjustment: performa_advance_adjustment,
                  performa_invoices: res.mv_sales_performa.invoices,
                  performaInvoicesData: res.mv_sales_performa.invoices,
                  coal_cost_adjusted_coal_price_pmt: res.mv_sales_performa
                    .adjusted_coal_price_pmt
                    ? res.mv_sales_performa.adjusted_coal_price_pmt
                    : "",
                  coal_cost_adjusted_coal_price: res.mv_sales_performa
                    .adjusted_coal_price
                    ? res.mv_sales_performa.adjusted_coal_price
                    : "",
                  coal_cost_currency_xchg_rate: res.mv_sales_performa
                    .currency_xchg_rate
                    ? res.mv_sales_performa.currency_xchg_rate
                    : "",
                  currency_xchg_rate: res.mv_sales_performa.currency_xchg_rate
                    ? res.mv_sales_performa.currency_xchg_rate
                    : "",
                  coal_cost_adjusted_coal_price_in_local_currency: res
                    .mv_sales_performa.adjusted_coal_price_in_local_currency
                    ? res.mv_sales_performa
                        .adjusted_coal_price_in_local_currency
                    : "",
                  coal_cost_pph22_barge_quantity_in_mt: res.mv_sales_performa
                    .pph22_barge_quantity
                    ? res.mv_sales_performa.pph22_barge_quantity
                    : "",
                  coal_cost_pph22_coal_price_per_mt: res.mv_sales_performa
                    .pph22_coal_price_pmt
                    ? res.mv_sales_performa.pph22_coal_price_pmt
                    : "",
                  coal_cost_pph22_percent: res.mv_sales_performa.pph22_percent
                    ? res.mv_sales_performa.pph22_percent
                    : "",
                  coal_cost_pph22_percent_val: res.mv_sales_performa.pph22_value
                    ? res.mv_sales_performa.pph22_value
                    : "",
                  coal_cost_pph22_export: res.mv_sales_performa
                    .pph22_export_applicable
                    ? res.mv_sales_performa.pph22_export_applicable
                    : "No",
                  coal_cost_pph22_export_amount: res.mv_sales_performa
                    .pph22_export_amount
                    ? res.mv_sales_performa.pph22_export_amount
                    : "",
                  coal_cost_pph22_export_percent: res.mv_sales_performa
                    .pph22_export_percent
                    ? res.mv_sales_performa.pph22_export_percent
                    : "",
                  coal_cost_pph22_export_value: res.mv_sales_performa
                    .pph22_export_value
                    ? res.mv_sales_performa.pph22_export_value
                    : "",
                  receivable_from_supplier: res.mv_sales_performa
                    .receivable_from_supplier
                    ? res.mv_sales_performa.receivable_from_supplier
                    : "",
                  sales_price_pmt: res.mv_sales_performa.coal_price_pmt
                    ? res.mv_sales_performa.coal_price_pmt
                    : "",
                  display_adjustments: totalAdjustments,
                  advance_adjustments:
                    res.mv_sales_performa.advance_adjustments,
                  vat_applicable: res.mv_sales_performa.vat_applicable
                    ? res.mv_sales_performa.vat_applicable
                    : "No",
                  vat_amount: res.mv_sales_performa.vat_amount,
                  vat_percent: res.mv_sales_performa.vat_percent
                    ? res.mv_sales_performa.vat_percent
                    : 10,
                  vat_value: res.mv_sales_performa.vat_value,
                  files: res.mv_sales_performa.mv_performa_files
                    ? res.mv_sales_performa.mv_performa_files
                    : [],
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  addPaymentsHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      date_of_payment,
      amount,
      invoice_no,
      description,
      invoiceID,
      invoiceNumber,
      performa_payments_amount,
      performa_advance_adjustment,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const status = "Active";
    if (description !== "" && description !== null) {
      description = description.toUpperCase();
    }
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoiceNumber === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }

    // Coal Costing payments
    var remainingAmount =
      Number(this.state.receivable_from_supplier) -
      Number(this.state.totalCoalPaymentsAmount) -
      Number(performa_payments_amount) -
      Number(performa_advance_adjustment);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.receivable_from_supplier) -
        Number(this.state.totalCoalPaymentsAmount) -
        Number(performa_payments_amount) -
        Number(performa_advance_adjustment) +
        Number(this.state.existingAmount);
    }
    if (Number(amount) > Number(remainingAmount)) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount Is Exceeding Receivable from Buyer",
      });
      return;
    }
    invoice_no = invoiceNumber;

    if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
      api
        .add_mv_sales_performa_coal_invoice_payment(
          loginUserID,
          idToken,
          invoiceID,
          amount,
          date_of_payment,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    paymentTitle: "",
                    invoiceID: "",
                    amount: "",
                    date_of_payment: null,
                    invoice_no: "",
                    description: "",
                    date_of_paymentError: false,
                    amountError: false,
                    invoice_noError: false,
                    paymentErrorMsg: "",
                    submitting: false,
                  },
                  () => {
                    window.$("#bargeSalesPaymentPostingModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
      });
      alert("Please fill mandatory fields.");
    }
  };

  getPaymentsHandler = (e) => {
    if (e) {
      var paymentTitle = "";
      this.setState({
        existingAmount: e.amount,
        payment_posting_id: e.id,
        paymentTitle: paymentTitle,
        barge_sales_fin_id: e.barge_sales_fin_id,
        amount: e.amount,
        date_of_payment: e.date_of_payment,
        invoiceNumber: e.invoice_no ? e.invoice_no : "",
        invoice_no: e.invoice_no ? e.invoice_no : "",
        description: e.description ? e.description : "",
        date_of_paymentError: false,
        amountError: false,
        paymentErrorMsg: "",
        paymentMode: "Update",
      });
      window.$("#bargeSalesPaymentPostingModal").modal({
        backdrop: "static",
        keyboard: true,
        show: true,
      });
    }
  };

  deletePaymentsHandler = (e) => {
    let paymentData = this.state.deleteData;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const invoiceID = paymentData.invoice_id;
    const payment_posting_id = paymentData.id;
    const amount = paymentData.amount;
    const date_of_payment = paymentData.date_of_payment;
    const invoice_no = paymentData.invoice_no ? paymentData.invoice_no : "";
    const description = paymentData.description ? paymentData.description : "";
    const status = "Deleted";
    api
      .update_mv_sales_performa_coal_invoice_payment(
        loginUserID,
        idToken,
        invoiceID,
        payment_posting_id,
        amount,
        date_of_payment,
        invoice_no,
        description,
        status
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              window.$("#deletePaymentPostingModal").modal("hide");
              this.componentDidMount();
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                succesMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  updatePaymentsHandler = () => {
    this.setState({
      submitting: true,
    });
    var {
      invoiceID,
      date_of_payment,
      amount,
      performa_payments_amount,
      performa_advance_adjustment,
      invoice_no,
      description,
    } = this.state;
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const payment_posting_id = this.state.payment_posting_id;
    const status = "Active";
    if (description !== "" && description !== null) {
      description = description.toUpperCase();
    }
    if (date_of_payment === null) {
      this.setState({
        date_of_paymentError: true,
        submitting: false,
      });
    }
    if (amount === "") {
      this.setState({
        amountError: true,
        submitting: false,
      });
    }
    if (invoice_no === "") {
      this.setState({
        invoice_noError: true,
        submitting: false,
      });
    }
    // Coal Costing payments
    var remainingAmount = 0;
    remainingAmount =
      Number(this.state.receivable_from_supplier) -
      Number(this.state.totalCoalPaymentsAmount) -
      Number(performa_payments_amount) -
      Number(performa_advance_adjustment);
    if (this.state.paymentMode === "Update") {
      remainingAmount =
        Number(this.state.receivable_from_supplier) -
        Number(this.state.totalCoalPaymentsAmount) -
        Number(performa_payments_amount) -
        Number(performa_advance_adjustment) +
        Number(this.state.existingAmount);
    }
    if (Number(amount) > Number(remainingAmount)) {
      this.setState({
        amountError: true,
        submitting: false,
        paymentErrorMsg: "Amount Is Exceeding Receivable from Buyer",
      });
      return;
    }

    if (date_of_payment !== null && amount !== "" && invoice_no !== "") {
      api
        .update_mv_sales_performa_coal_invoice_payment(
          loginUserID,
          idToken,
          invoiceID,
          payment_posting_id,
          amount,
          date_of_payment,
          invoice_no,
          description,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  paymentTitle: "",
                  amount: "",
                  date_of_payment: null,
                  invoice_no: "",
                  description: "",
                  date_of_paymentError: false,
                  amountError: false,
                  invoice_noError: false,
                  paymentErrorMsg: "",
                  submitting: false,
                });
                window.$("#bargeSalesPaymentPostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  succesMsg: "",
                  submitting: false,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      this.setState({
        submitting: false,
      });
      alert("Please fill mandatory fields.");
    }
  };

  // add invoics api
  addInvoiesHandler = () => {
    var {
      invoice_date,
      invoice_amount,
      invoice_percent,
      invoice_type,
      remarks,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var invoice_number = this.state.invoice_number
      ? this.state.invoice_number
      : "";
    if (remarks !== "" && remarks !== null) {
      remarks = remarks.toUpperCase();
    }
    if (invoice_date === null) {
      this.setState({
        invoice_dateError: true,
      });
    }
    if (invoice_amount === "") {
      this.setState({
        invoice_amountError: true,
      });
    }
    if (invoice_number === "") {
      this.setState({
        invoice_numberError: true,
      });
    }
    const mv_sales_costing_id = this.props.mv_sales_costing_id;

    if (
      invoice_date !== null &&
      invoice_amount !== "" &&
      invoice_number !== ""
    ) {
      api
        .add_mv_sales_performa_coal_invoice(
          loginUserID,
          idToken,
          mv_sales_costing_id,
          invoice_number,
          invoice_date,
          invoice_amount,
          invoice_percent,
          invoice_type,
          remarks
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (res.id) {
                  this.setState({
                    invoice_no: "",
                    invoice_amount: "",
                    invoice_percent: "",
                    invoice_type: "",
                    invoice_date: null,
                    remarks: "",
                  });
                  window.$("#invoicesPostingModal").modal("hide");
                  this.componentDidMount();
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  sucessMsg: "",
                });
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.message,
                  sucessMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  getInvoiceData = (e) => {
    if (e) {
      this.setState(
        {
          performa_costing_id: e.performa_costing_id,
          invoice_id: e.id,
          invoice_number: e.invoice_no,
          invoice_amount: e.invoice_amount,
          invoice_percent: e.invoice_percent,
          // invoice_type: e.invoice_type,
          invoice_date: e.invoice_date,
          remarks: e.remarks,
          invoiceMode: "Update",
        },
        () => {
          window.$("#invoicesPostingModal").modal({
            backdrop: "static",
            keyboard: true,
            show: true,
          });
        }
      );
    }
  };

  updateInvoicesHandler = () => {
    var {
      invoice_id,
      invoice_date,
      invoice_amount,
      invoice_percent,
      invoice_type,
      remarks,
    } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var invoice_number = this.state.invoice_number
      ? this.state.invoice_number
      : "";
    if (remarks !== "" && remarks !== null) {
      remarks = remarks.toUpperCase();
    }
    var status = "Active";
    if (invoice_date === null) {
      this.setState({
        invoice_dateError: true,
      });
    }
    if (invoice_amount === "") {
      this.setState({
        invoice_amountError: true,
      });
    }
    if (invoice_number === "") {
      this.setState({
        invoice_numberError: true,
      });
    }
    const mv_sales_costing_id = this.props.mv_sales_costing_id;
    if (
      invoice_date !== null &&
      invoice_amount !== "" &&
      invoice_number !== ""
    ) {
      api
        .update_mv_sales_performa_coal_invoice(
          loginUserID,
          idToken,
          mv_sales_costing_id,
          invoice_id,
          invoice_number,
          invoice_date,
          invoice_amount,
          invoice_percent,
          invoice_type,
          remarks,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    invoice_no: "",
                    invoice_amount: "",
                    invoice_percent: "",
                    invoice_type: "",
                    invoice_date: null,
                    remarks: "",
                  },
                  () => {
                    window.$("#invoicesPostingModal").modal("hide");
                    this.componentDidMount();
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    } else {
      alert("Please fill mandatory fields.");
    }
  };

  deleteInvoicePosting = (e) => {
    if (e) {
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const mv_sales_costing_id = this.props.mv_sales_costing_id;
      const invoice_id = e.id;
      const invoice_number = e.invoice_no ? e.invoice_no : "";
      const invoice_date = e.invoice_date;
      const invoice_percent = e.invoice_percent;
      const invoice_type = e.invoice_type;
      const invoice_amount = e.invoice_amount;
      const remarks = e.remarks ? e.remarks : "";
      const status = "Deleted";
      api
        .update_mv_sales_performa_coal_invoice(
          loginUserID,
          idToken,
          mv_sales_costing_id,
          invoice_id,
          invoice_number,
          invoice_date,
          invoice_amount,
          invoice_percent,
          invoice_type,
          remarks,
          status
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState({
                  invoice_no: "",
                  invoice_amount: "",
                  invoice_date: null,
                  remarks: "",
                });
                window.$("#deleteInvoicePostingModal").modal("hide");
                this.componentDidMount();
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  sucessMsg: "",
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.props.mv_sales_costing_id,
      costing_type: "Barge Sales Performa Coal Costing",
      approval_status: "Approved",
    };
    api.approve_costing_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  approvePaymentsHandler = (id) => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      payment_id: id,
      costing_type: "sales_performa_coal_costing_payments_data",
      approval_status: "Approved",
    };
    api.approve_payment_postings_api(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  };

  payable_to_vendor = () => {
    // $payable = $adjusted_coal_price_in_local_currency + $vat_value - $pph22_value - $pph22_export_value - $comm_adv_adj_value - $performa_payments_amount - $performa_adv_adj_value;
    const {
      coal_cost_coal_price = 0,
      vat_value = 0,
      pph22_value = 0,
      pph22_export_value = 0,
      total_advance_adjustment_value = 0,
      performa_payments_amount = 0,
      performa_advance_adjustment = 0,
    } = this.state;

    return (
      parseFloat(coal_cost_coal_price) +
      parseFloat(vat_value) -
      parseFloat(pph22_value) -
      parseFloat(pph22_export_value) -
      parseFloat(total_advance_adjustment_value) -
      parseFloat(performa_payments_amount) -
      parseFloat(performa_advance_adjustment)
    );
  };

  renderInvoiceLink = () => {
    const {
      costing_no,
      invoiceDetails: { invoice_no = "", invoice_date = "" } = {},
      coal_cost_coal_quantity,
      // coal_cost_coal_price,
      coal_cost_coal_price = 0,
      // paid_to_supplier = 0,
      advance_adjustments,
      coal_cost_currency_xchg_rate = 1,
      // coal_cost_adjusted_coal_price,
      coal_cost_adjusted_coal_price_pmt,
      // coal_index = {},
      vendor_name = "",
      mother_vessel_name = "",
      sales_type = "FOB Barge",
      sales_index_linked = "No",
    } = this.state;

    const filter_advance = advance_adjustments.filter(
      (e) => e.advance_adjustment !== "No"
    );

    const advanceAdjustments = [];
    for (var i of filter_advance) {
      advanceAdjustments.push({
        desc: `Adv deduction against contract: ${this.state.sales_contract_no}`,
        qty: `${toLocaleString(
          i.advance_adjustment_barge_quantity
            ? i.advance_adjustment_barge_quantity
            : " "
        )}`,
        adjustedPrice: `${toLocaleString(
          i.adjustment_per_mt ? i.adjustment_per_mt : " "
        )}`,
        amount: `( ${toLocaleString(i.advance_adjustment_value)} )`,
      });
    }
    // const Grand_total = this.payable_to_vendor();
    const invoiceObject = {
      costing_id: costing_no,
      to: vendor_name,
      business_no: mother_vessel_name,
      costing_name: "Sales Perfoma",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      total_payable_amount: toLocaleString(this.state.receivable_from_supplier),
      sales_type: sales_type,
      sales_index_linked: sales_index_linked,
      description: [
        {
          qty: toLocaleString(coal_cost_coal_quantity),
          adjustedPrice: toLocaleString(coal_cost_adjusted_coal_price_pmt),
          desc: `Coal price ${
            this.props.costing_type === "Performa"
              ? `@ ${toLocaleString(this.state.invoice_percentage)} %`
              : ""
          }`,
          amount: toLocaleString(Number(coal_cost_coal_price)),
        },
        ...(coal_cost_currency_xchg_rate > 1
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Exchange Rate @ ${coal_cost_currency_xchg_rate}  ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.purchase_currency !==
        this.state.coal_cost_billing_currency
          ? [
              {
                qty: "",
                adjustedPrice: "",
                desc: `Billing Currency ${this.state.coal_cost_billing_currency} `,
              },
            ]
          : []),

        ...(this.state.performa_advance_adjustment &&
        this.state.costing_type === "Commercial"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Performa advance adjustments",
                amount: `( ${toLocaleString(
                  this.state.performa_advance_adjustment
                )} )`,
              },
            ]
          : []),
        ...(this.state.costing_type === "Commercial" &&
        this.state.performa_payments_amount
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "Received against performa invoice",
                amount: `( ${toLocaleString(
                  this.state.performa_payments_amount
                )} )`,
              },
            ]
          : []),
        ...advanceAdjustments,
        ...(this.state.vat_applicable === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.vat_value)}`,
              },
            ]
          : []),
        ...(this.state.coal_cost_pph22_export === "Yes"
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Export",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_export_value
                )} )`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) &&
        Number(this.state.coal_cost_pph22_percent_val) !== 0 &&
        this.state.coal_cost_pph22_percent
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH22 Local",
                amount: `( ${toLocaleString(
                  this.state.coal_cost_pph22_percent_val
                )} )`,
              },
            ]
          : []),
      ],
    };

    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_sales_performa_coal_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>

          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-8 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {" "}
                  {`Sales Performa Coal Costing - ${this.state.costing_no}`}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status_2}
                  </span>
                </h4>
              </div>
              <div className="col-lg-1 pl-0">{this.renderInvoiceLink()}</div>

              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                this.state.approved_by !==
                  this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("404") ||
                  this.state.features.includes("455") ||
                  this.state.features.includes("97")) && (
                  <div
                    className="col text-right float-sm-left"
                    style={{ padding: "5px 20px" }}
                  >
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: "17px" }}
                      onClick={this.approveHandler}
                    >
                      Approve
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-3">
              <div className="card-body p-0">
                {/*=============================================
                    =                 Coal Costing Section                  =
                    =============================================*/}
                <div className="col shadow-sm p-0 mb-2 bg-white border">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered mb-0">
                      <thead className="table-header">
                        <tr>
                          <th className="text-center">Description</th>
                          <th className="text-right">Qty</th>
                          <th className="text-right">Unit Price</th>
                          <th className="text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            Coal Price
                            {this.state.coal_cost_currency_xchg_rate > 1 && (
                              <>
                                <div>
                                  Coal Price Per MT @
                                  {parseFloat(
                                    this.state.coal_cost_coal_price_pmt
                                  ).toFixed(2)}
                                </div>
                                <div>
                                  Billing Currency{" "}
                                  {this.state.coal_cost_billing_currency}
                                </div>
                                {this.state.purchase_currency !==
                                  this.state.coal_cost_billing_currency && (
                                  <div>
                                    Exchange Rate @{" "}
                                    {this.state.coal_cost_currency_xchg_rate}
                                  </div>
                                )}
                              </>
                            )}
                          </td>
                          <td className="text-right">
                            {this.state.coal_cost_coal_quantity
                              ? toLocaleString(
                                  this.state.coal_cost_coal_quantity
                                )
                              : ""}
                          </td>
                          <td className="text-right">
                            {`${toLocaleString(
                              this.state.coal_cost_adjusted_coal_price_pmt
                            )} ${this.state.sales_currency}`}
                          </td>
                          <td className="text-right">
                            {parseFloat(this.state.coal_cost_coal_price)}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan={4}>
                            Advance Adjustments &nbsp;
                            <i
                              className="fa fa-plus-circle"
                              style={{
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                this.setState({ openAdvAdjustments: true });
                              }}
                            />
                          </th>
                        </tr>

                        {this.state.advance_adjustments
                          ?.filter((e) => e.advance_adjustment !== "No")
                          .map((item, idx) => (
                            <tr key={item.id}>
                              <td className="">
                                {`Adv deduction against contract:
                               ${this.state.sales_contract_no}`}
                                &nbsp;
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState((prevState) => ({
                                      openAdvAdjustments:
                                        !prevState.openAdvAdjustments,
                                    }))
                                  }
                                />
                              </td>
                              <td className="text-right ">
                                {item.advance_adjustment !== "Lumpsum"
                                  ? toLocaleString(
                                      item.advance_adjustment_barge_quantity
                                    )
                                  : ""}
                              </td>
                              <td className="text-right ">
                                {item.advance_adjustment !== "Lumpsum"
                                  ? toLocaleString(item.adjustment_per_mt)
                                  : ""}
                              </td>
                              <td className="text-right ">
                                {`( ${toLocaleString(
                                  item.advance_adjustment_value
                                )} )`}
                              </td>
                            </tr>
                          ))}
                        {(config.company !== "SRPL" ||
                          config.company !== "PRPL" ||
                          config.company !== "AVS") &&
                          this.state.vat_value !== 0 && (
                            <tr>
                              <td>VAT @ {this.state.vat_percent} %</td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                {toLocaleString(this.state.vat_value)}
                              </td>
                            </tr>
                          )}
                        {this.state.coal_cost_pph22_export === "Yes" && (
                          <tr>
                            <td>
                              PPH22 Export @{" "}
                              {toLocaleString(
                                this.state.coal_cost_pph22_export_percent
                              )}{" "}
                              %
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              (
                              {Number(
                                this.state.coal_cost_pph22_export_value
                              ).toFixed(2)}
                              )
                            </td>
                          </tr>
                        )}
                        {!(
                          config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                        ) &&
                          Number(this.state.coal_cost_pph22_percent_val) !==
                            0 &&
                          this.state.coal_cost_pph22_percent && (
                            <tr>
                              <td>
                                PPH22 Local @{" "}
                                {toLocaleString(
                                  this.state.coal_cost_pph22_percent
                                )}
                              </td>
                              <td className="text-right"></td>
                              <td className="text-right"></td>
                              <td className="text-right">
                                (
                                {Number(
                                  this.state.coal_cost_pph22_percent_val
                                ).toFixed(2)}
                                )
                              </td>
                            </tr>
                          )}
                        <tr>
                          <td
                            colSpan={3}
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            Receivable From Buyer :{" "}
                          </td>
                          <td
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            {` ${
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                                ? "USD"
                                : "IDR"
                            } `}
                            {/* {toLocaleString(this.payable_to_vendor())} */}
                            {toLocaleString(
                              this.state.receivable_from_supplier
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            Received From Buyer :{" "}
                          </td>
                          <td
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            {` ${
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                                ? "USD"
                                : "IDR"
                            } `}
                            {/* {toLocaleString(this.payable_to_vendor())} */}
                            {toLocaleString(this.state.receivedFromBuyer)}
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={3}
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            Remaining Amount :{" "}
                          </td>
                          <td
                            className="text-right "
                            style={{ fontWeight: "bold" }}
                          >
                            {` ${
                              config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                                ? "USD"
                                : "IDR"
                            } `}
                            {/* {toLocaleString(this.payable_to_vendor())} */}
                            {toLocaleString(this.state.remainingAmount)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                {/**
                 *
                 *
                 *  ADVANCE ADJUSTMENTS
                 *
                 */}

                <SalesAdvanceAdjustments
                  open={this.state.openAdvAdjustments}
                  adjustments={this.state.advance_adjustments}
                  table_name="mv_sales_performa_adv_adjustments"
                  costing_id={this.props.mv_sales_costing_id}
                  callbackFn={() => this.componentDidMount()}
                  info={{
                    sales_contract_id: this.state.sales_contract_id,
                    sales_contract_no: this.state.sales_contract_no,
                    remaining_cus_adv_payment:
                      this.state.remaining_cus_adv_payment,
                  }}
                  closeModal={() =>
                    this.setState({ openAdvAdjustments: false })
                  }
                />
                {/**
                 *
                 *
                 *
                 *
                 */}
                {/* <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Coal Costing</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Invoice No
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.invoice_number
                            ? this.state.invoice_number
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Invoice Date
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.costing_invoice_date
                            ? localDateFormate(this.state.costing_invoice_date)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Fakthur Pazak No
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.ppn ? this.state.ppn : "-"}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Fakthur Pazak Date
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.ppn_date
                            ? localDateFormate(this.state.ppn_date)
                            : "-"}
                        </div>
                      </div>

                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Barge Quantity in MT
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.coal_cost_coal_quantity
                            ? this.toLocaleString(
                                this.state.coal_cost_coal_quantity
                              )
                            : null}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Coal Price per MT
                        </label>
                        <div className="contract_display_header_value">
                          {this.toLocaleString(this.state.sales_price_pmt)}{" "}
                          {this.state.sales_currency}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Billing Currency
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                      {(config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL") &&
                        this.state.sales_currency !== "USD" &&
                        this.state.sales_currency && (
                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              Exchange rate USD to{" "}
                              {this.state.sales_currency}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_currency_xchg_rate
                                ? this.toLocaleString(
                                    this.state.coal_cost_currency_xchg_rate
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") &&
                        this.state.sales_currency !== "IDR" &&
                        this.state.sales_currency && (
                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              Exchange rate {this.state.sales_currency} to
                              IDR
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.coal_cost_currency_xchg_rate
                                ? this.toLocaleString(
                                    this.state.coal_cost_currency_xchg_rate
                                  )
                                : ""}
                            </div>
                          </div>
                        )}
                      {this.state.coal_cost_billing_currency !==
                        this.state.sales_currency &&
                        this.state.coal_cost_billing_currency !== "0" && (
                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              Coal Price per MT in{" "}
                              {this.state.coal_cost_billing_currency}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state
                                .coal_cost_coal_price_pmt_local_currency
                                ? this.toLocaleString(
                                    this.state
                                      .coal_cost_coal_price_pmt_local_currency
                                  ) +
                                  " " +
                                  this.state.coal_cost_billing_currency
                                : ""}
                            </div>
                          </div>
                        )}
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Coal Price
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.coal_cost_coal_price
                            ? this.toLocaleString(
                                this.state.coal_cost_coal_price
                              )
                            : 0}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>

                      {this.state.coal_cost_penality_currency === "USD" &&
                        this.state.coal_cost_billing_currency !==
                          this.state.sales_currency &&
                        this.state.sales_term === "Specs" &&
                        this.state.costing_type === "Commercial" && (
                          <div className="col-sm-4 mb-3">
                            <label className="contract_display_header_label">
                              Adjusted Coal Price PMT in{" "}
                              {this.state.coal_cost_penality_currency}
                            </label>
                            <div className="contract_display_header_value">
                              {this.toLocaleString(
                                (
                                  Number(
                                    this.state.coal_cost_adjusted_coal_price_pmt
                                  ) /
                                  Number(
                                    this.state.coal_cost_currency_xchg_rate
                                  )
                                ).toFixed(2)
                              )}
                            </div>
                          </div>
                        )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            VAT Value
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.vat_value !== "0"
                              ? "(" +
                                this.toLocaleString(this.state.vat_value) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </div>
                        </div>
                      )}
                      {(config.company === "SDAM" ||
                        config.company === "SRK" ||
                        config.company === "BTR") && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            PPh22 Local
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.coal_cost_pph22_percent_val !== "0"
                              ? "(" +
                                this.toLocaleString(
                                  this.state.coal_cost_pph22_percent_val
                                ) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          PPh22 Export
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.coal_cost_pph22_export_value !== "0"
                            ? "(" +
                              this.toLocaleString(
                                this.state.coal_cost_pph22_export_value
                              ) +
                              ")"
                            : "(0)"}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Advace Adjustment
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.display_adjustments
                            ? "(" +
                              this.toLocaleString(
                                this.state.display_adjustments
                              ) +
                              ")"
                            : "(0)"}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                      {this.state.costing_type === "Commercial" && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            Received against Performa
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.performa_payments_amount !== 0
                              ? "(" +
                                this.toLocaleString(
                                  this.state.performa_payments_amount
                                ) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Receivable from Buyer
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ color: config.themeColor }}
                        >
                          {this.toLocaleString(
                            this.state.receivable_from_supplier
                          )}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                      {this.state.costing_type === "Commercial" && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            Received against Performa
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.performa_payments_amount !== 0
                              ? "(" +
                                this.toLocaleString(
                                  this.state.performa_payments_amount
                                ) +
                                ")"
                              : "(0)"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Received from Buyer
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ color: config.themeColor }}
                        >
                          {"(" +
                            this.toLocaleString(this.state.receivedFromBuyer) +
                            ")"}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                      {this.state.costing_type !== "Commercial" && (
                        <div className="col-sm-4 mb-3">
                          <label className="contract_display_header_label">
                            Adjusted to Commercial
                          </label>
                          <div
                            className="contract_display_header_value"
                            style={{ color: config.themeColor }}
                          >
                            {"(" +
                              this.toLocaleString(
                                this.state.performaInvoicesAdjustedAmount
                              ) +
                              ")"}{" "}
                            {this.state.coal_cost_billing_currency}
                          </div>
                        </div>
                      )}
                      <div className="col-sm-4 mb-3">
                        <label className="contract_display_header_label">
                          Remaining Amount
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ color: config.themeColor }}
                        >
                          {this.toLocaleString(this.state.remainingAmount)}{" "}
                          {this.state.coal_cost_billing_currency}
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <InvoiceForm
                  open={this.state.invoiceDialog}
                  costingID={this.props.mv_sales_costing_id}
                  costing_type={"MV Sales Performa Coal Costing"}
                  data={this.state.invoiceDetails}
                  callbackFn={(data) => this.updateInvoice(data)}
                />

                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Performa Invoices</h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        {this.state.performaInvoicesData.length <= 0 && (
                          <h6>No invoices added</h6>
                        )}
                      </div>

                      {(this.state.features.includes("194") ||
                        this.state.features.includes("97")) && (
                        <div className="col text-right float-right p-0 mb-3 pr-1">
                          <button
                            className="btn payment_button"
                            data-toggle="modal"
                            title="Barge Payment Postings"
                            data-placement="bottom"
                            data-keyboard="false"
                            data-target="#invoicesPostingModal"
                            data-backdrop="static"
                            onClick={() => {
                              this.setState({
                                invoiceFormTitle: "Add",
                                invoice_date: null,
                                invoice_amount: "",
                                invoice_percent: "",
                                invoice_type: "Performa Invoices",
                                invoice_number: "",
                                remarks: "",
                                invoiceMode: "Add",
                              });
                            }}
                          >
                            Add invoice
                          </button>
                        </div>
                      )}
                    </div>
                    {this.state.performaInvoicesData.length > 0 && (
                      <div className="row">
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Total Invoiced Amount
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(this.state.invoiceTotalAmount)}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Amount Received
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(this.state.receivedAmount)}
                          </div>
                        </div>
                        {/* <div className='col-lg-4 mb-3'>
                            <label className='contract_display_header_label'>Adjusted to Commercial</label>
                            <div className='contract_display_header_value'>{this.state.performaInvoicesAdjustedAmount ? this.toLocaleString(this.state.performaInvoicesAdjustedAmount) : 0}</div>
                          </div> */}
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Remaining Amount
                          </label>
                          <div className="contract_display_header_value">
                            {toLocaleString(this.state.remaining_amount)}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.performaInvoicesData.length > 0 && (
                      <div className="row">
                        <div className="col-lg">
                          {this.state.performaInvoicesData.map((e, index) => (
                            <div
                              className="table-responsive border mb-2"
                              key={index}
                            >
                              <table className="table table-borderless border-0">
                                <thead
                                  style={{
                                    visibility:
                                      index === 0 ? "visible" : "collapse",
                                  }}
                                >
                                  <tr>
                                    <th
                                      nowrap="true"
                                      className="table_header_barge_label text-left border-0"
                                    >
                                      Invoice No
                                    </th>
                                    <th
                                      nowrap="true"
                                      className="table_header_barge_label text-right border-0"
                                    >
                                      Invoice Amount
                                    </th>
                                    <th
                                      nowrap="true"
                                      className="table_header_barge_label text-left border-0"
                                    >
                                      Invoice Date
                                    </th>
                                    <th
                                      className="table_header_barge_label text-left border-0"
                                      style={{ minWidth: 100 }}
                                    >
                                      Remarks
                                    </th>
                                    <th
                                      nowrap="true"
                                      className="table_header_barge_label text-center border-0"
                                    >
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td nowrap="true">{e.invoice_no}</td>
                                    <td nowrap="true" className="text-right">
                                      {toLocaleString(e.invoice_amount)}
                                    </td>
                                    <td nowrap="true">{e.invoice_percent}</td>
                                    <td nowrap="true">
                                      {e.invoice_date
                                        ? localDateFormate(e.invoice_date)
                                        : null}
                                    </td>
                                    <td nowrap="true">{e.remarks}</td>
                                    <td nowrap="true">
                                      {(this.state.features.includes("196") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <Tooltip title="Edit" arrow>
                                          <i
                                            className="fa fa-pencil-square-o mr-3"
                                            style={{
                                              fontSize: 20,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            onClick={() => {
                                              this.setState({
                                                invoice_type:
                                                  "Performa Invoices",
                                              });
                                              this.getInvoiceData(e);
                                            }}
                                          ></i>
                                        </Tooltip>
                                      )}
                                      {(this.state.features.includes("197") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <Tooltip title="Delete" arrow>
                                          <i
                                            className="fa fa-trash-o mr-3"
                                            style={{
                                              fontSize: 20,
                                              color: "red",
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            title="Delete Invoice"
                                            data-placement="bottom"
                                            data-keyboard="false"
                                            data-target="#deleteInvoicePostingModal"
                                            data-backdrop="static"
                                            onClick={() =>
                                              this.setState({
                                                invoiceDeleteData: e,
                                              })
                                            }
                                          ></i>
                                        </Tooltip>
                                      )}
                                      {(this.state.features.includes("198") >=
                                        0 ||
                                        this.state.features.includes("97") >=
                                          0) && (
                                        <Tooltip title="Add Payment" arrow>
                                          <i
                                            className="fa fa-plus-circle mr-2"
                                            style={{
                                              fontSize: 20,
                                              color: config.themeColor,
                                              cursor: "pointer",
                                            }}
                                            data-toggle="modal"
                                            title="Add Payment"
                                            data-placement="bottom"
                                            data-keyboard="false"
                                            data-target="#bargeSalesPaymentPostingModal"
                                            data-backdrop="static"
                                            onClick={() =>
                                              this.setState({
                                                invoiceID: e.id,
                                                invoiceNumber: e.invoice_no,
                                                paymentMode: "Add",
                                              })
                                            }
                                          ></i>
                                        </Tooltip>
                                      )}
                                      {this.state.performaExpandSelected ===
                                      index ? (
                                        <i
                                          className="fa fa-chevron-up"
                                          style={{
                                            fontSize: 20,
                                            color: "skyblue",
                                            cursor: "pointer",
                                            float: "right",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              performaExpandSelected: null,
                                            })
                                          }
                                        />
                                      ) : (
                                        <i
                                          className="fa fa-chevron-down "
                                          style={{
                                            fontSize: 20,
                                            color: "skyblue",
                                            cursor: "pointer",
                                            float: "right",
                                            display:
                                              this.state.features.includes(
                                                "80"
                                              ) ||
                                              this.state.features.includes("97")
                                                ? "initial"
                                                : "none",
                                            visibility:
                                              e.payments.length > 0
                                                ? "initial"
                                                : "hidden",
                                          }}
                                          onClick={() =>
                                            this.setState({
                                              performaExpandSelected: index,
                                            })
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {e.payments.length > 0 ? (
                                <div
                                  className="row"
                                  style={{
                                    visibility:
                                      this.state.performaExpandSelected ===
                                      index
                                        ? "visible"
                                        : "collapse",
                                    padding:
                                      this.state.performaExpandSelected ===
                                      index
                                        ? 6
                                        : 0,
                                  }}
                                >
                                  <div className="table-responsive">
                                    <table className="table table-hover table-bordered table-sm mb-0">
                                      <thead className="table-header">
                                        <tr>
                                          <th
                                            className="table_header_barge_label text-left"
                                            nowrap="true"
                                          >
                                            Receivable ID
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left text-right"
                                            nowrap="true"
                                          >
                                            Amount
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left"
                                            nowrap="true"
                                          >
                                            Date Received
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left"
                                            style={{
                                              width: 300,
                                              maxWidth: 250,
                                              textAlign: "center",
                                            }}
                                          >
                                            Description
                                          </th>
                                          <th
                                            className="table_header_barge_label text-left"
                                            nowrap="true"
                                          >
                                            Created Date
                                          </th>
                                          <th nowrap="true">Action</th>
                                        </tr>
                                      </thead>
                                      {e.payments.map((p, idx) => (
                                        <tr
                                          key={idx}
                                          style={{
                                            backgroundColor:
                                              p.description ===
                                              "Adjusted to Commercial"
                                                ? "#FF9800"
                                                : "#fff",
                                          }}
                                        >
                                          <td className="text-uppercase">
                                            {p.payment_id}
                                          </td>
                                          <td
                                            nowrap="true"
                                            className="text-right"
                                          >
                                            {toLocaleString(p.amount)}
                                          </td>
                                          <td nowrap="true">
                                            {p.date_of_payment
                                              ? localDateFormate(
                                                  p.date_of_payment
                                                )
                                              : null}
                                          </td>
                                          <td className="text-uppercase">
                                            {p.description}
                                          </td>
                                          <td nowrap="true">
                                            {p.created_date
                                              ? localDateFormate(p.created_date)
                                              : null}
                                          </td>{" "}
                                          <td nowrap="true">
                                            <Tooltip title="Edit" arrow>
                                              <i
                                                className="fa fa-pencil-square-o mr-3"
                                                style={{
                                                  fontSize: 20,
                                                  color: config.themeColor,
                                                  cursor: "pointer",
                                                }}
                                                data-toggle="modal"
                                                title="Edit Coal Payment Posting"
                                                data-placement="bottom"
                                                onClick={() =>
                                                  this.getPaymentsHandler(p)
                                                }
                                              ></i>
                                            </Tooltip>
                                            {(this.state.features.includes(
                                              "201"
                                            ) ||
                                              this.state.features.includes(
                                                "97"
                                              )) && (
                                              <Tooltip title="Delete" arrow>
                                                <i
                                                  className="fa fa-trash-o"
                                                  style={{
                                                    fontSize: 20,
                                                    color: "red",
                                                    cursor: "pointer",
                                                  }}
                                                  data-toggle="modal"
                                                  title="Delete Coal Payment Posting"
                                                  data-placement="bottom"
                                                  data-keyboard="false"
                                                  data-target="#deletePaymentPostingModal"
                                                  data-backdrop="static"
                                                  onClick={() =>
                                                    this.setState({
                                                      deleteData: p,
                                                    })
                                                  }
                                                ></i>
                                              </Tooltip>
                                            )}
                                          </td>
                                        </tr>
                                      ))}
                                    </table>
                                  </div>
                                </div>
                              ) : (
                                <h6
                                  style={{
                                    display:
                                      this.state.performaExpandSelected ===
                                      index
                                        ? "inline"
                                        : "none",
                                    padding:
                                      this.state.performaExpandSelected ===
                                      index
                                        ? 6
                                        : 0,
                                  }}
                                >
                                  No payments made
                                </h6>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <ViewFiles
                  {...this.props}
                  files_data={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                />

                <AuditInfo info={this.state} />
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("192") ||
                this.state.features.includes("404") ||
                this.state.features.includes("97")
              : this.state.features.includes("97")) && (
              <button
                type="button"
                className="drawer_text drawer_btn_success"
                name="Back"
                onClick={() => {
                  this.props.onEdit(true);
                }}
              >
                Edit
              </button>
            )}
          </footer>
          {/*
           *
           * Barge Payements Posting Modals
           *
           */}

          <div
            className="modal fade"
            id="bargeSalesPaymentPostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.paymentTitle} Payment Posting
                  </h6>
                  {/* //<button type="button" className="close" data-dismiss="modal">&times;</button> */}
                </div>
                <div className="modal-body">
                  <div
                    style={{
                      fontSize: 14,
                      color: "red",
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                  >
                    {this.state.paymentErrorMsg}
                  </div>
                  <div className="row">
                    <div className="col-lg-4 mb-2">
                      <label className="form_label mb-0">
                        Date Received <span style={{ color: "red" }}>*</span>
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.date_of_payment}
                          error={this.state.date_of_paymentError}
                          onChange={(date) => {
                            this.setState({
                              date_of_payment: date,
                              date_of_paymentError: false,
                            });
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: "pl-0",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label className="form_label mb-0">
                        Amount <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="amount"
                        margin="dense"
                        variant="outlined"
                        placeholder="Amount"
                        required
                        value={this.state.amount}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        fullWidth
                        error={this.state.amountError}
                        onChange={(e) => {
                          const re = /^[0-9]*\.?[0-9]*$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            this.setState({
                              amount: e.target.value,
                              amountError: false,
                              paymentErrorMsg: "",
                            });
                            var remainingAmount =
                              Number(this.state.receivable_from_supplier) -
                              Number(this.state.totalCoalPaymentsAmount);
                            if (this.state.paymentMode === "Update") {
                              remainingAmount =
                                Number(this.state.receivable_from_supplier) -
                                Number(this.state.totalCoalPaymentsAmount) +
                                Number(this.state.existingAmount);
                            }
                            if (
                              Number(e.target.value) > Number(remainingAmount)
                            ) {
                              this.setState({
                                amountError: true,
                                paymentErrorMsg:
                                  "Amount Is Exceeding Receivable from Buyer",
                              });
                              return;
                            }
                          }
                        }}
                      />
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label className="form_label mb-2">
                        Invoice No <span style={{ color: "red" }}>*</span>
                      </label>
                      <div>{this.state.invoiceNumber}</div>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <label className="form_label mb-0">Description</label>
                      <TextField
                        name="description"
                        margin="dense"
                        variant="outlined"
                        placeholder="Description"
                        multiline
                        rows={3}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        paymentTitle: "",
                        date_of_payment: null,
                        amount: "",
                        invoice_no: "",
                        description: "",
                        paymentErrorMsg: "",
                        date_of_paymentError: false,
                        amountError: false,
                        invoice_noError: false,
                        paymentMode: "",
                        submitting: false,
                      });
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.paymentMode === "Add" && (
                    <button
                      type="button"
                      className="btn next_button"
                      onClick={this.addPaymentsHandler}
                      disabled={this.state.submitting}
                    >
                      Submit
                      <span
                        className={
                          this.state.submitting
                            ? "spinner-grow spinner-grow-sm mr-2"
                            : ""
                        }
                      />
                    </button>
                  )}

                  {this.state.paymentMode === "Update" && (
                    <button
                      type="button"
                      className="btn next_button"
                      onClick={this.updatePaymentsHandler}
                      disabled={this.state.submitting}
                    >
                      Submit
                      <span
                        className={
                          this.state.submitting
                            ? "spinner-grow spinner-grow-sm mr-2"
                            : ""
                        }
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="confirmationAddPaymentModal"
            role="dialog"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <WarningIcon
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />
                    Please ensure performa payments are entered before creating
                    commercial invoice.
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-keyboard="false"
                    data-target="#invoicesPostingModal"
                    data-backdrop="static"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        invoiceFormTitle: "Add",
                        invoice_date: null,
                        invoice_amount: "",
                        invoice_percent: "",
                        invoice_type: "Commercial Invoices",
                        invoice_number: "",
                        remarks: "",
                        invoiceMode: "Add",
                      });
                    }}
                  >
                    Proceed
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="invoicesPostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content  payment-modal">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {this.state.invoiceFormTitle} Invoice
                  </h6>
                  {/* //<button type="button" className="close" data-dismiss="modal">&times;</button> */}
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Invoice No <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="invoice_number"
                        margin="dense"
                        variant="outlined"
                        placeholder="Invoice No"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        error={this.state.invoice_numberError}
                        value={this.state.invoice_number}
                        onChange={(e) =>
                          this.setState({
                            invoice_number: e.target.value,
                            invoice_numberError: false,
                          })
                        }
                        fullWidth
                      />
                    </div>
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Invoice Amount <span style={{ color: "red" }}>*</span>
                      </label>
                      <TextField
                        name="invoice_amount"
                        margin="dense"
                        variant="outlined"
                        placeholder="Amount"
                        required
                        value={this.state.invoice_amount}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        fullWidth
                        error={this.state.invoice_amountError}
                        onChange={(e) => {
                          const pr = /^\d*(\.\d{0,4})?$/;
                          if (
                            e.target.value === "" ||
                            pr.test(e.target.value)
                          ) {
                            this.setState({
                              invoice_amount: e.target.value,
                              invoice_amountError: false,
                              //paymentErrorMsg: ''
                            });
                          }
                        }}
                      />
                    </div>
                    {this.state.invoice_type === "Performa Invoices" && (
                      <div className="col-lg-6 mb-2">
                        <label className="form_label mb-0">Invoice %</label>
                        <TextField
                          name="invoice_percent"
                          margin="dense"
                          variant="outlined"
                          placeholder="Percent"
                          required
                          value={this.state.invoice_percent}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          fullWidth
                          error={this.state.invoice_percentError}
                          onChange={(e) =>
                            this.setState({ invoice_percent: e.target.value })
                          }
                        />
                      </div>
                    )}
                    <div className="col-lg-6 mb-2">
                      <label className="form_label mb-0">
                        Invoice Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.invoice_date}
                          error={this.state.invoice_dateError}
                          onChange={(date) => {
                            this.setState({
                              invoice_date: date,
                              invoice_dateError: false,
                            });
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: "pl-0",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-lg-12 mb-2">
                      <label className="form_label mb-0">Remarks</label>
                      <TextField
                        name="remarks"
                        margin="dense"
                        variant="outlined"
                        placeholder="Remarks"
                        multiline
                        rows={3}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={this.state.remarks}
                        onChange={(e) =>
                          this.setState({ remarks: e.target.value })
                        }
                        fullWidth
                      />
                    </div>
                  </div>
                </div>
                <div className="row bottom_buttons_section">
                  <button
                    type="button"
                    className="btn previous_button"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        invoiceFormTitle: "",
                        invoice_date: null,
                        invoice_amount: "",
                        invoice_type: "",
                        invoice_percent: "",
                        invoice_number: "",
                        remarks: "",
                        invoiceMode: "",
                        submitting: false,
                      });
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.invoiceMode === "Add" && (
                    <button
                      type="button"
                      className="btn next_button"
                      onClick={this.addInvoiesHandler}
                      disabled={this.state.submitting}
                    >
                      Submit
                      <span
                        className={
                          this.state.submitting
                            ? "spinner-grow spinner-grow-sm mr-2"
                            : ""
                        }
                      ></span>
                    </button>
                  )}

                  {this.state.invoiceMode === "Update" && (
                    <button
                      type="button"
                      className="btn next_button"
                      onClick={this.updateInvoicesHandler}
                      disabled={this.state.submitting}
                    >
                      Update
                      <span
                        className={
                          this.state.submitting
                            ? "spinner-grow spinner-grow-sm mr-2"
                            : ""
                        }
                      ></span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id="deleteInvoicePostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <WarningIcon
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />{" "}
                    Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() =>
                      this.deleteInvoicePosting(this.state.invoiceDeleteData)
                    }
                  >
                    Delete
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="deletePaymentPostingModal"
            role="dialog"
            tabIndex="-1"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div
                  className="modal-header border-0"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  <h6
                    className="modal-title"
                    style={{ border: "none", padding: 0 }}
                  >
                    {" "}
                    <WarningIcon
                      style={{ color: "#e0a51e", marginBottom: "5px" }}
                    />{" "}
                    Are you sure to delete ?
                  </h6>
                </div>
                <div className="modal-footer border-0">
                  <button
                    className="btn btn-danger mr-3"
                    onClick={() =>
                      this.deletePaymentsHandler(this.state.deleteData)
                    }
                  >
                    Delete
                  </button>
                  <button className="btn btn-secondary" data-dismiss="modal">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
