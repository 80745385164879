import React from 'react'

import config from '../../../config/config';
import {
  Radio,
  withStyles
} from "@material-ui/core";
import { toLocaleString } from '../../common/common';
import { useState } from 'react';
const NewRadio = withStyles({
  root: {
    color: config.themeColor,
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
export default function QualitySelection(props) {
  const { bargeAgreements = [], callbackFn } = props;

  const [state, setState] = useState(null);

  return (
    <div>
      <button type='button' id='modalOpen' className='btn d-none' data-toggle="modal"
        data-placement="bottom"
        data-keyboard="true"
        data-target="#cpsfQualitySelection" />


      <div className="modal fade" id="cpsfQualitySelection" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content payment-modal">
            <div
              className="modal-header border-0"
              style={{ backgroundColor: "#f1f1f1" }}
            >
              <h6
                className="modal-title"
                style={{ border: "none", padding: 0 }}
              >
                Select Quality
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">

              {bargeAgreements?.length > 0 &&

                <div className="table-responsive">

                  <table className="table table-sm">
                    <thead className="table-header">
                      <tr>
                        <th className='text-center border ' nowrap="true"></th>
                        <th className='text-center border text-uppercase' nowrap="true">Qualtiy</th>
                        <th className='text-center border text-uppercase' nowrap="true">Inco Term</th>
                        <th className='text-center border text-uppercase' nowrap="true">Price Type</th>
                        <th className='text-center border text-uppercase' nowrap="true">Quantity</th>
                        <th className='text-center border text-uppercase' nowrap="true">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bargeAgreements?.map((item, idx) => (
                        <tr key={item.price_list_id}>
                          <td className='text-center border'>
                            <NewRadio
                              onClick={() => {
                                setState(item);
                                callbackFn(item);
                              }}
                              checked={item.id === state?.id}
                              color="primary"
                              size="small"
                            />
                          </td>
                          <td className='text-center text-uppercase border'>{item.quality}</td>
                          <td className='text-center border'>{item.purchase_type}</td>
                          <td className='text-center border'>{item.price_type}</td>
                          <td className='text-center border'>{toLocaleString(item.quantity_in_mt)}</td>
                          <td className='text-center border'>{`${item.price_type === "Per MT" ? toLocaleString(item.price_pmt) : toLocaleString(item.total_price)}`}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              }

            </div>
            <div className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button"
                name="submit"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                data-dismiss="modal"
                className="btn next_button"
                onClick={() => {
                  if (!state) {
                    callbackFn(state);
                  }
                }}
              >
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
