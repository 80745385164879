
import React, { useState } from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import EventIcon from "@material-ui/icons/Event";
import Slide from '@material-ui/core/Slide';
import config from '../../config/config';
import { dateFormateToDB, localDateFormate } from './common';
import CookieHandler from './CookieHandler';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const InvoiceForm = ({ open, data, costingID, callbackFn, costing_type }) => {

  const Cookie = new CookieHandler();

  const { invoice_no = '',
    invoice_date = null,
    ppn = '',
    ppn_date = null, invoice_percentage = '', invoice_amount = '' } = data;

  const [showModal, setShowModal] = useState(false);

  const [state, setState] = useState({
    invoice_no: invoice_no,
    invoice_date: invoice_date,
    ppn: ppn,
    ppn_date: ppn_date,
    invoice_percentage: invoice_percentage ? invoice_percentage : 1,
    costing_id: costingID,
    invoice_amount: invoice_amount ? invoice_amount : 1,
    login_user_id: Cookie.getCookie("loginUserId"),
    idtoken: Cookie.getIdTokenCookie(),
  })

  const renderInvoiceDetails = () => {
    // const { invoice_no, ppn_date, ppn, invoice_date, invoice_percentage = '' } = data;

    return (
      <div className="col shadow-sm p-0 mb-2 bg-white">
        <div className="table-responsive">
          <table className="table table-sm table-bordered mb-0">
            <thead className="table-header">
              <tr>
                <th
                  nowrap="true"
                // className="table_header_barge_label text-left border-0"
                >
                  Invoice No
                </th>
                <th nowrap="true"
                // className="table_header_barge_label text-left border-0"
                >
                  Invoice Date
                </th>
                {costing_type === "Barge Costing" &&
                  <th nowrap="true"
                    // className="table_header_barge_label text-left border-0"
                    className="text-center"
                  >Invoice %</th>
                }
                <th
                  nowrap="true"
                  // className="table_header_barge_label text-left border-0"
                  className="text-center"
                >
                  Fakthur Pazak No
                </th>
                <th
                  nowrap="true"
                  // className="table_header_barge_label text-left border-0"
                  className="text-center"
                >
                  Fakthur Pazak Date
                </th>

                <th
                  nowrap="true"
                  // className="table_header_barge_label text-left border-0"
                  className="text-center"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td nowrap="true" className="table_td text-uppercase">{invoice_no}</td>
                <td nowrap="true" className="table_td">{localDateFormate(invoice_date)}</td>
                {costing_type === "Barge Costing" &&
                  <td nowrap="true" className="table_td">{invoice_percentage}</td>
                }
                <td nowrap="true" className="table_td text-uppercase">{ppn}</td>
                <td nowrap="true" className="table_td">{localDateFormate(ppn_date)}</td>
                <td nowrap="true">
                  {invoice_no ? (
                    <>
                      <i
                        className="fa fa-pencil mr-2"
                        style={{
                          fontSize: 20,
                          color: config.themeColor,
                          cursor: "pointer",
                        }}
                        onClick={() => setShowModal(true)}
                      ></i>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn payment_button"
                        onClick={() => setShowModal(true)}

                      >
                        Add Invoice
                      </button>
                    </>
                  )}{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>

      {renderInvoiceDetails()}
      <Dialog
        open={showModal}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "#f1f1f1" }}>
          <h6
            className="modal-title"
            style={{ border: "none", padding: 0 }}
          >Invoice Details
          </h6>
        </DialogTitle>
        <DialogContent>
          <div className='row'>
            <div className="col-lg-6 mb-1">
              <label className="form_label mb-0">
                Invoice No <span className='text-danger'>*</span>
              </label>
              <TextField
                name="invoice_no"
                margin="dense"
                type="text"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    textTransform: "uppercase",
                  },
                }}
                placeholder="invoice no"
                value={state.invoice_no}
                error={state.invoice_error}
                onChange={(e) => {
                  setState({ ...state, invoice_no: e.target.value, invoice_error: false });
                }}
              />
            </div>
            <div className="col-lg-6  mb-1">
              <label className="form_label mb-0">
                Invoice Date
              </label>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={state.invoice_date}
                  // error={this.state.date_of_paymentError}
                  onChange={(date) => {
                    setState({ ...state, invoice_date: dateFormateToDB(date) })
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "pl-0",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            {costing_type === "Barge Costing" &&

              <div className="col-lg-6 mb-1">
                <label className="form_label mb-0">
                  Invoice % <span className='text-danger'>*</span>
                </label>
                <TextField
                  name="invoice_percentage"
                  margin="dense"
                  type="text"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: {
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      textTransform: "uppercase",
                    },
                  }}
                  placeholder="invoice %"
                  value={state.invoice_percentage}
                  onChange={(e) => {
                    setState({ ...state, invoice_percentage: e.target.value });
                  }}
                />
              </div>
            }
            <div className="col-lg-6 mb-3">
              <label className="form_label mb-0">
                Fakthur Pazak No
              </label>
              <TextField
                name="ppn"
                margin="dense"
                type="text"
                variant="outlined"
                fullWidth
                inputProps={{
                  style: {
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    textTransform: "uppercase",
                  },
                }}
                placeholder="Fakthur Pazak No"
                value={state.ppn}
                onChange={(e) => {
                  setState({ ...state, ppn: e.target.value })
                }}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form_label mb-0">
                Fakthur Pazak Date
              </label>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  autoOk={true}
                  inputVariant="outlined"
                  variant="inline"
                  margin="dense"
                  fullWidth
                  format="dd/MM/yyyy"
                  value={state.ppn_date}
                  // error={this.state.date_of_paymentError}
                  onChange={(date) => {
                    setState({ ...state, ppn_date: dateFormateToDB(date) })
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <EventIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "pl-0",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            className="btn payment_button mr-4"
            onClick={() => {
              if (state.invoice_no === '' || state.invoice_no == null) {
                setState({ ...state, invoice_error: true })
                return
              }

              let stateCopy = state;
              if (!(costing_type === "Barge Costing" || costing_type === "Coal Costing")) {
                delete stateCopy['invoice_percentage']
              }

              callbackFn(stateCopy)
              setShowModal(false)
            }}
          >
            Submit
          </button>

          <button className="btn btn-secondary" type='button'

            onClick={() => {
              setState({ ...state, invoice_no: "", invoice_date: null, ppn: "", ppn_date: null, invoice_percentage: null, invoice_error: false, invoice_amount: "" })
              setShowModal(false)

            }}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvoiceForm