import React, { Component } from 'react';
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
class PnbpChargesAdd extends Component {

  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      vendor_id: '0',
      billing_currency: "0",
      currency_exchange_rate: "",
      quantity: "",
      pnbp_per_mt: "",
      pnbp: "",
      pnbp_local_currency: "",
      jasa_pbm_calculation: "",
      jasa_pbm_pmt: "",
      jasa_pbm_value: "",
      jasa_pbm_local_currency: "",
      remarks: "",

      other_charges: [{ description: "", price: "", remarks: "" }],
      isLoading: true,
      features: [],
      vendors: [],
      vendorData: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Stevedore_Costing",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("631") || features.includes("97"))) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const status = "Active";
      await api.getVendors(loginUserID, idToken, status).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.vendors) {
                const vendors = [<MenuItem value='0'> Please Select</MenuItem>];
                const data = res.vendors.filter(e => e.vendor_type === 'Stevedore' || e.vendor_type === 'Surveyor' || e.vendor_type === 'Vessel Agent');
                for (var element of data) {
                  vendors.push(<MenuItem value={element.id}>{element.vendor_name}</MenuItem>);
                }
                this.setState({
                  vendors: res.vendors,
                  vendorData: vendors,
                  isLoading: false
                })
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
  }


  StevedoreCalculationHandler = () => {

    const { pnbp_per_mt = 1, jasa_pbm_pmt = 1, billing_currency = "USD", quantity = 1, currency_exchange_rate = 1, jasa_pbm_calculation = "Per MT" } = this.state

    let pnbp = (
      (Number(quantity) * Number(pnbp_per_mt ? pnbp_per_mt : 0)) /
      Number(currency_exchange_rate ? currency_exchange_rate : 1)
    ).toFixed(2);
    this.setState({
      pnbp: pnbp,
    });
    if (jasa_pbm_calculation === "Per MT") {
      let jasa_pbm_value = (
        (Number(quantity) * Number(jasa_pbm_pmt ? jasa_pbm_pmt : 1)) /
        100 /
        Number(currency_exchange_rate ? currency_exchange_rate : 1)
      ).toFixed(2);

      //jasa_pbm_company_to_billing:""
      this.setState({
        jasa_pbm_value: jasa_pbm_value,
      });
    }

    let pnbp_company_to_billing = '';
    let jasa_pbm_company_to_billing = '';

    if (
      ((config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL") &&
        billing_currency === "IDR") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        billing_currency === "USD")
    ) {

      pnbp_company_to_billing = (
        Number(quantity) * Number(pnbp_per_mt ? pnbp_per_mt : 1)
      ).toFixed(2);
      jasa_pbm_company_to_billing = (
        (Number(quantity) * Number(jasa_pbm_pmt ? jasa_pbm_pmt : 1)) /
        100
      ).toFixed(2);
    } else {
      pnbp_company_to_billing = "";
      jasa_pbm_company_to_billing = "";
    }

    this.setState({
      pnbp_company_to_billing: pnbp_company_to_billing,
      jasa_pbm_company_to_billing: jasa_pbm_company_to_billing,
    });
  };

  removeRowsHandler = (index) => () => {
    const row = this.state.other_charges;
    row.splice(index, 1);
    this.setState({
      other_charges: row,
    });
  };

  addMoreRowsHandler = (idx) => {
    const item = { description: "", price: "", remarks: "" };
    const data = [...this.state.other_charges, item];
    this.setState({
      other_charges: data,
    });
  };

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;

    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");

    const { vendor_id, billing_currency, currency_exchange_rate, quantity, pnbp_per_mt, pnbp,
      pnbp_local_currency, jasa_pbm_calculation, jasa_pbm_pmt, jasa_pbm_value,
      jasa_pbm_local_currency, remarks, other_charges = [] } = this.state;
    const request = {
      "login_user_id": loginUserID,
      "idtoken": idToken,
      "business_no_id": businessNoID,
      "vendor_id": vendor_id,
      "billing_currency": billing_currency,
      "currency_exchange_rate": currency_exchange_rate,
      "quantity": quantity,
      "pnbp_per_mt": pnbp_per_mt,
      "pnbp": pnbp,
      "pnbp_local_currency": pnbp_local_currency,
      "jasa_pbm_calculation": jasa_pbm_calculation,
      "jasa_pbm_pmt": jasa_pbm_pmt,
      "jasa_pbm_value": jasa_pbm_value,
      "jasa_pbm_local_currency": jasa_pbm_local_currency,
      "remarks": remarks,
      "other_charges_data": other_charges,
      "files": content_files,
      "costing_files": costing_file,
    }
    if (businessNoID) {
      api
        .add_mv_pnbp_jasa_pbm(request)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    setTimeout(() => {
                      this.props.onAddResponse({
                        screen: "MvStevedoreCosting",
                        code: res.code,
                        message: "",
                      });
                    }, 2000);
                  }
                );
                //window.location.href = "/mv-purchase-financial-view-stevedore-costing/" + btoa(businessNoID);
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
    } else {
      this.setState({
        errorMsg: "Invalid business no.",
        snackBarErrorOpen: true,
      });
    }
  };


  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4 style={{ padding: "10px 20px" }}>Add PNBP Charges</h4>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    <div className="col-lg-6 p-0">
                      <label className="form_label mb-0">
                        Vendor
                      </label>
                      <TextField
                        name="stevedore_vendor_id"
                        placeholder="Billing Currency"
                        margin="dense"
                        variant="outlined"
                        value={this.state.vendor_id}
                        onChange={(e) => {
                          this.setState({
                            vendor_id: e.target.value,
                          });
                        }}
                        fullWidth
                        select
                        inputProps={{
                          style: {
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          },
                        }}
                      >
                        {this.state.vendorData}
                      </TextField>
                    </div>
                  </div>

                  <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                    <div className="row mb-3">
                      <div className="row col-lg-12 p-0 mb-2">
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Quantity in MT
                          </label>
                          <TextField
                            name="quantity_in_mt"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.quantity}
                            fullWidth
                            placeholder="Enter Value"
                            onChange={(e) => {
                              const quan = /^\d*(\.\d{0,3})?$/;
                              if (
                                e.target.value === "" ||
                                quan.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    quantity: e.target.value,
                                  },
                                  () =>
                                    this.StevedoreCalculationHandler()
                                );
                              }
                            }}
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Billing Currency
                          </label>
                          <TextField
                            name="billing_currency"
                            placeholder="Billing Currency"
                            margin="dense"
                            variant="outlined"
                            value={this.state.billing_currency}
                            onChange={(e) => {
                              this.setState(
                                {
                                  billing_currency: e.target.value,
                                },
                                () => {
                                  this.StevedoreCalculationHandler();
                                }
                              );
                            }}
                            fullWidth
                            select
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                          >
                            <MenuItem value="0">Please Select</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="IDR">IDR</MenuItem>
                          </TextField>
                        </div>

                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            PNBP Per MT
                          </label>
                          <TextField
                            name="pnbp_per_mt"
                            margin="dense"
                            variant="outlined"
                            type="number"
                            value={this.state.pnbp_per_mt}
                            fullWidth
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {this.state.billing_currency !== "0"
                                    ? this.state.billing_currency
                                    : ""}
                                </InputAdornment>
                              ),
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                              },
                            }}
                            placeholder="Enter Value"
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState(
                                  {
                                    pnbp_per_mt: e.target.value,
                                  },
                                  () => {
                                    this.StevedoreCalculationHandler();
                                  }
                                );
                              }
                            }}
                          />
                        </div>
                        {(config.company === "SRPL" || config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.billing_currency !== "USD" &&
                          this.state.billing_currency !== "0" && (
                            <div className="col-lg-6">
                              <label className="form_label mb-0">
                                Exchange Rate
                              </label>
                              <TextField
                                name="currency_exchange_rate"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={this.state.currency_exchange_rate}
                                fullWidth
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                  },
                                }}
                                placeholder="Enter Value"
                                onChange={(e) => {
                                  const cr = /^\d*(\.\d{0,10})?$/;
                                  if (
                                    e.target.value === "" ||
                                    cr.test(e.target.value)
                                  ) {
                                    this.setState(
                                      {
                                        currency_exchange_rate: e.target.value,
                                      },
                                      () => this.StevedoreCalculationHandler());
                                  }
                                }}
                              />
                            </div>
                          )}

                        <div className="col-lg-6">
                          <label className="form_label mb-3">PNBP</label>
                          <div className="">
                            {this.state.pnbp ? toLocaleString(this.state.pnbp) : 0}{" "}
                            {config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL"
                              ? "USD"
                              : "IDR"}{" "}
                            {this.state.pnbp_company_to_billing
                              ? "( " +
                              toLocaleString(this.state.pnbp_company_to_billing) +
                              " " +
                              this.state.billing_currency +
                              " )"
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="row col-lg-12 p-0">
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            JASA PBM Calculation
                          </label>
                          <RadioGroup
                            aria-label="jasa_pbm_calculation"
                            name="jasa_pbm_calculation"
                            onChange={(e) => {
                              this.setState(
                                {
                                  jasa_pbm_calculation: e.target.value,
                                },
                                () => {
                                  this.StevedoreCalculationHandler();
                                }
                              );
                            }}
                            style={{ flexDirection: "row" }}
                          >
                            <FormControlLabel
                              value="Lumpsum"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.jasa_pbm_calculation === "Lumpsum"
                                  }
                                />
                              }
                              label="Lumpsum"
                            />
                            <FormControlLabel
                              value="Per MT"
                              control={
                                <NewRadio
                                  checked={
                                    this.state.jasa_pbm_calculation === "Per MT"
                                  }
                                />
                              }
                              label="Per MT"
                            />
                          </RadioGroup>
                        </div>

                        {this.state.jasa_pbm_calculation === "Lumpsum" && (
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              JASA PBM Value
                            </label>
                            <TextField
                              name="jasa_pbm_value"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              value={this.state.jasa_pbm_value}
                              fullWidth
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {this.state.billing_currency !== "0"
                                      ? this.state.billing_currency
                                      : ""}
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="JASA PBM Value"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState({
                                    jasa_pbm_value: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        )}
                        {this.state.jasa_pbm_calculation === "Per MT" && (
                          <div className="col-lg-6">
                            <label className="form_label mb-0">
                              JASA PBM Per MT
                            </label>
                            <TextField
                              name="jasa_pbm_pmt"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              value={this.state.jasa_pbm_pmt}
                              fullWidth
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {this.state.billing_currency !== "0"
                                      ? this.state.billing_currency
                                      : ""}
                                  </InputAdornment>
                                ),
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Enter Value"
                              onChange={(e) => {
                                if (
                                  e.target.value === "" ||
                                  pr.test(e.target.value)
                                ) {
                                  this.setState(
                                    {
                                      jasa_pbm_pmt: e.target.value,
                                    },
                                    () => this.StevedoreCalculationHandler());
                                }
                              }}
                            />
                          </div>
                        )}

                        {this.state.jasa_pbm_calculation === "Per MT" && (
                          <div className="col-lg-6">
                            <label className="form_label mb-3">
                              JASA PBM Value
                            </label>
                            <div>
                              {toLocaleString(this.state.jasa_pbm_value)}
                              {config.company === "SRPL" || config.company === "AVS" ||
                                config.company === "PRPL"
                                ? "USD"
                                : "IDR"}{" "}
                              {this.state.jasa_pbm_company_to_billing
                                ? "( " +
                                toLocaleString(
                                  this.state.jasa_pbm_company_to_billing
                                ) +
                                " " +
                                this.state.billing_currency +
                                " )"
                                : null}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <hr />

                    <div className="row">
                      <div className="row col-lg-12 p-0">
                        <h5>Others</h5>
                      </div>

                      {this.state.other_charges.map((p, index) => (
                        <div className="row mb-1" key={index}>
                          <div className="col-lg-11 p-2 border-bottom">
                            <div className="row">
                              <div className="col-lg-6 p-0">
                                <label className="form_label mb-0">
                                  Description
                                </label>
                                <TextField
                                  name="description"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={p.description}
                                  placeholder="Description"
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      textTransform: "uppercase",
                                    },
                                  }}
                                  onChange={(e) => {
                                    var rows =
                                      this.state.other_charges;
                                    rows[index].description =
                                      e.target.value;
                                    this.setState({
                                      other_charges: rows
                                    });
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 pr-0">
                                <label className="form_label mb-0">
                                  Price
                                </label>
                                <TextField
                                  name="price"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  fullWidth
                                  value={p.price}
                                  placeholder="Enter Value"
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {config.company === "SRPL" || config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? "USD"
                                          : "IDR"}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    const cr = /^\d*(\.\d{0,4})?$/;
                                    if (
                                      e.target.value === "" ||
                                      cr.test(e.target.value)
                                    ) {
                                      var rows =
                                        this.state.other_charges;
                                      rows[index].price = e.target.value;
                                      this.setState({
                                        other_charges: rows
                                      });
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-lg-12 p-0">
                                <label className="form_label mb-0">
                                  Remarks
                                </label>
                                <TextField
                                  name="remarks"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  value={p.remarks}
                                  placeholder="Remarks"
                                  multiline
                                  rows={3}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      textTransform: "uppercase",
                                    },
                                  }}
                                  onChange={(e) => {
                                    var rows =
                                      this.state.other_charges;
                                    rows[index].remarks = e.target.value;
                                    this.setState({
                                      other_charges: rows
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.other_charges.length > 1 && (
                            <center className="col-lg-1 d-flex justify-content-center">
                              <i
                                className="fa fa-trash-o m-auto"
                                onClick={this.removeRowsHandler(index)}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </center>
                          )}
                        </div>
                      ))}

                      <div className="row">
                        <button
                          type="button"
                          onClick={() => this.addMoreRowsHandler()}
                          className="header_button header_button_text addrow_button_adjustment w-auto"
                          style={{
                            border: "none",
                            color: config.themeColor,
                          }}
                        >
                          ADD OTHERS
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {(this.state.features.includes('532')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  feature_name="Mv_Pur_Fin_Stevedore_Costing"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.setState({}, () =>
                  this.props.returnCallbackDrawerClose(false)
                );
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
              disabled={this.state.vendors.length === 0}
            >
              Submit
            </button>
          </div>
        </div>
      );
    }
  }
}

export default PnbpChargesAdd;