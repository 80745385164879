import React from "react";
import CookieHandler from "../../common/CookieHandler";
import api from "../../../api/api";
import config from "../../../config/config";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import Button from "@material-ui/core/Button";
import { Drawer } from "@material-ui/core";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import exportToExcel from "../../Export_To_Excel";
import { localDateFormate } from "../../common/common";
import BargeAgreementView from "../BargeAgreements/BargeAgreementView";
import BargeAgreementUpdate from "../BargeAgreements/BargeAgreementUpdate";
import Loader from "../../common/Loader";

class FixtureNoteList extends React.Component {
  constructor() {
    super();
    this.Cookie = new CookieHandler();
    this.state = {
      rawData: [],
      data: [],
      loading: true,
      isLoading: true,
      errorMessage: "",
      successMessage: "",
      active_chip_index: 0,
      fixture_notes: [],
      features: [],
      showDrawer: false,
      viewBargeAgreement: false,
      updateBargeAgreement: false,
      bargeAgreementID: null,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      let features = this.Cookie.getCookie("features");
      this.setState({ features: features ? features : [] }, () => {
        if (
          !(
            this.state.features.includes("334") ||
            this.state.features.includes("97")
          )
        ) {
          window.location.href = "/dashboard";
        }
      });
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      api.get_fixture_notes(loginUserID, idToken).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
              if (res.fixture_notes_data) {
                this.setState(
                  {
                    fixture_notes: res.fixture_notes_data,
                    rawData: res.fixture_notes_data,
                    loading: false,
                    isLoading: false,
                  },
                  () => this.chipsFiltersHandler()
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    }
    document.title = config.documentTitle + "Fixture Notes";
  }

  convertExcelHandler = (data, filename, ShowLabel) => {
    var arrData = typeof data != "object" ? JSON.parse(data) : data;
    let Modified = arrData.map((obj) => {
      return {
        "Contract No": obj.fixture_note_no,
        "Fixture Note Type": obj.fixture_note_type,
        "Supplier Reference no": obj.supplier_reference_no,
        "Mother Vessel Name": obj.mother_vessel_name,
        "Agreement Date": localDateFormate(obj.agreement_date),
        Vendor: obj.vendor_name,
        Laycan: obj.laycan,
        "Quantity in MT": obj.quantity_in_mt,
        "Allowed Days": obj.allowed_days,
        "Loading Rate Per Day": obj.loading_rate_per_day,
        "Dead Freight": obj.dead_freight,
        "Vessel Type": obj.vessel_type,
        "Port Of Loading": obj.port_of_loading,
        Currency: obj.currency,
        "Price Per MT": obj.price_per_mt,
        "Demurrage Type": obj.demurrage_type,
        "Demurrage Rate per Day": obj.demurrage_rate_per_day,
        "Cancellation Fee": obj.cancellation_fee,
        "Payment Terms": obj.payment_terms,
        "Other T&C": obj.other_t_and_c,
        Remarks: obj.remarks,
      };
    });
    exportToExcel(Modified, filename);
  };

  chipsFiltersHandler = () => {
    var data = this.state.rawData;
    if (this.state.active_chip_index === 0) {
      this.setState({
        fixture_notes: data.filter(
          (e) => e.fixture_note_type === "Stevedoring"
        ),
      });
    }
    if (this.state.active_chip_index === 1) {
      this.setState({
        fixture_notes: data.filter(
          (e) => e.fixture_note_type === "Floating Crane"
        ),
      });
    }
    if (this.state.active_chip_index === 2) {
      this.setState({
        fixture_notes: data.filter(
          (e) => e.fixture_note_type === "Vessel Chartering"
        ),
      });
    }
  };

  BargingAgreementsHandler = () => {
    this.setState({ loading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    api.get_barging_agreements(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState({
              fixture_notes: res.barging_agreements,
              loading: false,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.viewBargeAgreement && this.state.bargeAgreementID) {
      drawerComponent = (
        <BargeAgreementView
          agreementID={this.state.bargeAgreementID}
          onCancel={() => {
            this.setState({
              showDrawer: false,
              viewBargeAgreement: false,
              updateBargeAgreement: false,
            });
          }}
          onEdit={() => {
            this.setState({
              showDrawer: true,
              viewBargeAgreement: false,
              updateBargeAgreement: true,
            });
          }}
        />
      );
    }
    if (this.state.updateBargeAgreement && this.state.bargeAgreementID) {
      drawerComponent = (
        <BargeAgreementUpdate
          agreementID={this.state.bargeAgreementID}
          onCancel={() => {
            this.setState(
              {
                showDrawer: true,
                viewBargeAgreement: true,
                updateBargeAgreement: false,
              },
              () => {
                this.componentDidMount();
              }
            );
          }}
          onEdit={() => {}}
        />
      );
    }
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="clearfix content-header">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Fixture Notes
                  <span style={{ color: "lightgrey", margin: "0px 5px" }}>
                    |
                  </span>
                  <span style={{ color: "#959cb6", fontSize: "15px" }}>
                    {this.state.fixture_notes.length}
                  </span>
                </h4>
              </div>
              {this.state.active_chip_index !== 3 && (
                <div className="col text-right float-sm-left">
                  <a href="/new-fixture-note">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: 17 }}
                    >
                      New Fixture Note
                    </button>
                  </a>
                </div>
              )}
              {this.state.active_chip_index === 3 && (
                <div className="col text-right float-sm-left">
                  <a href="/new-barging-agreement">
                    <button
                      className="header_button header_button_text add_button_adjustment"
                      style={{ width: "auto", marginTop: 17, borderRadius: 20 }}
                    >
                      New Barging Agreement
                    </button>
                  </a>
                </div>
              )}
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          <div className="container p-3">
            {this.state.errorMessage && (
              <div className="col text-center">
                <p className="error-class">{this.state.errorMessage}</p>
              </div>
            )}
            {this.state.successMessage && (
              <div className="col text-center">
                <p className="success-class">{this.state.successMessage}</p>
              </div>
            )}
            {!this.state.isLoading && (
              <div className="col-md card p-0">
                <Paper>
                  <MaterialTable
                    title={
                      <div className="row">
                        <div className="col-lg-12">
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState(
                                {
                                  active_chip_index: 0,
                                },
                                () => this.chipsFiltersHandler()
                              );
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.active_chip_index === 0
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.active_chip_index === 0
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Stevedoring
                          </Button>
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState(
                                {
                                  active_chip_index: 1,
                                },
                                () => this.chipsFiltersHandler()
                              );
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.active_chip_index === 1
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.active_chip_index === 1
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Floating Crane
                          </Button>
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState(
                                {
                                  active_chip_index: 2,
                                },
                                () => this.chipsFiltersHandler()
                              );
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.active_chip_index === 2
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.active_chip_index === 2
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Vessel Chartering
                          </Button>
                          <Button
                            className="tab_button"
                            onClick={(e, value) => {
                              this.setState(
                                {
                                  active_chip_index: 3,
                                },
                                () => this.BargingAgreementsHandler()
                              );
                            }}
                            style={{
                              textTransform: "capitalize",
                              margin: "5px 0px",
                              padding: "5px 20px",
                              borderRadius: 35,
                              height: 30,
                              color:
                                this.state.active_chip_index === 3
                                  ? config.themeColor
                                  : "#483b34b3",
                              backgroundColor:
                                this.state.active_chip_index === 3
                                  ? "#fff7f2"
                                  : "#fff",
                              fontSize: "14px",
                              border: "0px",
                            }}
                          >
                            Barging Agreements
                          </Button>
                        </div>
                      </div>
                    }
                    columns={[
                      {
                        title: "Fixture Note",
                        field: "fixture_note_no",
                        render: (row) => {
                          if (this.state.active_chip_index !== 3) {
                            if (
                              this.state.features.includes("334") ||
                              this.state.features.includes("97")
                            ) {
                              return (
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: config.themeColor,
                                  }}
                                  href={"/view-fixture-note/" + btoa(row.id)}
                                >
                                  {row.fixture_note_no}
                                </a>
                              );
                            } else {
                              return <span>{row.fixture_note_no}</span>;
                            }
                          } else {
                            return (
                              <span
                                style={{
                                  cursor: "pointer",
                                  color: config.themeColor,
                                }}
                                onClick={() => {
                                  this.setState({
                                    bargeAgreementID: row.id,
                                    showDrawer: true,
                                    viewBargeAgreement: true,
                                    updateBargeAgreement: false,
                                  });
                                }}
                              >
                                {row.fixture_note_no}
                              </span>
                            );
                          }
                        },
                      },
                      {
                        title: "Fixture Note Type",
                        field: "fixture_note_type",
                        hidden: this.state.active_chip_index === 3,
                      },
                      {
                        title: "Mother Vessel Name",
                        field: "mother_vessel_name",
                        hidden: this.state.active_chip_index === 3,

                        // headerStyle: {
                        //   minWidth: 245,
                        // },
                      },
                      {
                        title: "Agreement Date",
                        field: "agreement_date",
                        render: (row) => {
                          return localDateFormate(row.agreement_date);
                        },
                        // headerStyle: {
                        //   minWidth: 245,
                        // },
                      },
                      {
                        title: "Vendor Name",
                        field: "vendor_name",
                        hidden: this.state.active_chip_index === 3,

                        // headerStyle: {
                        //   minWidth: 245,
                        // },
                      },
                      {
                        title: "Vendor Name",
                        field: "barge_vendor_name",
                        hidden: this.state.active_chip_index !== 3,

                        // headerStyle: {
                        //   minWidth: 245,
                        // },
                      },

                      {
                        title: "Status",
                        field: "status",
                        render: (row) => {
                          return (
                            <span>
                              {row.status === "Active" ? (
                                <span className="active_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {row.status}
                                </span>
                              ) : (
                                <span className="freezed_status">
                                  <i
                                    className="fa fa-circle dot_align"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {row.status}
                                </span>
                              )}
                            </span>
                          );
                        },
                        headerStyle: {
                          minWidth: 120,
                        },
                      },
                      {
                        title: "Action",
                        field: "id",
                        sortable: false,
                        filterable: false,
                        headerStyle: {
                          minWidth: 120,
                        },
                        render: (row) => {
                          return (
                            <div
                              style={{
                                textAlign: "left",
                                marginTop: "-10px",
                                marginBottom: "-10px",
                              }}
                            >
                              {row.status === "Active" &&
                                this.state.active_chip_index !== 3 &&
                                (this.state.features.includes("335") ||
                                  this.state.features.includes("97")) && (
                                  <a
                                    href={
                                      "/update-fixture-note/" + btoa(row.id)
                                    }
                                  >
                                    {" "}
                                    <i
                                      data-html="true"
                                      data-toggle="tooltip1"
                                      title="Update Service"
                                      data-placement="bottom"
                                      className="fa fa-pencil edit_icon"
                                      aria-hidden="true"
                                      style={{
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </a>
                                )}
                            </div>
                          );
                        },
                        hidden: !(
                          this.state.features.includes("335") ||
                          this.state.features.includes("97")
                        ),
                      },
                    ]}
                    data={this.state.fixture_notes}
                    isLoading={this.state.loading}
                    style={{
                      minHeight: 200,
                      fontFamily: "Poppins",
                      overflowY: "auto",
                      boxShadow: "0 0 5px 0 rgba(0, 39, 77, 0.1)",
                    }}
                    options={{
                      paginationType: "stepped",
                      pageSize: 20,
                      pageSizeOptions: [5, 10, 20, 50, 100, 200],
                      headerStyle: {
                        backgroundColor: "#f9f9f9",
                        minWidth: 215,
                        fontFamily: "Poppins",
                        textTransform: "uppercase",
                        fontSize: "14px",
                        fontWeight: 500,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "0.86",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "rgba(72, 59, 52, 0.7)",
                      },
                      cellStyle: {
                        fontFamily: "Poppins",
                      },
                      // exportAllData: true,
                      // exportButton: true,
                      draggable: false,
                      exportFileName: "purchase-contracts",
                      // tableLayout: 'fixed'
                    }}
                    actions={[
                      {
                        icon: "download",
                        tooltip: "Download",
                        isFreeAction: true,
                        onClick: (event) =>
                          this.convertExcelHandler(
                            this.state.fixture_notes,
                            "Fixture Notes"
                          ),
                      },
                    ]}
                  />
                </Paper>
              </div>
            )}
          </div>
        </div>
        <Drawer
          anchor="right"
          open={this.state.showDrawer}
          variant="temporary"
          elevation={20}
          style={{ overflow: "initial" }}
        >
          <div className="row" style={{ width: 800 }}>
            {drawerComponent}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default FixtureNoteList;
