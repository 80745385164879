import React, { Component } from 'react'
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import AuditInfo from '../../common/AuditInfo';
export default class LaporanChargesView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      dc_laprn_surv_party: "Third Party",
      dc_laprn_surv_by: "",
      dc_laprn_surv_currency: "0",
      dc_laprn_surv_currency_xchg_rate: "",
      dc_laprn_surv_ctc_in_local_currency: "",
      dc_laprn_surv_price_per_mt: "",
      dc_laprn_surv_base_price: "",
      dc_laprn_surv_vat_percent: "10",
      dc_laprn_surv_vat_value: "",
      dc_laprn_surv_pph23_percent: "2",
      dc_laprn_surv_pph23_value: "",
      dc_laprn_surv_total: "",
      dc_laprn_surv_ctc: "",
      dc_cek_fisic: "",
      isLoading: true,
      features: [],
      files: [],
      barge_id: "",
      invoiceDialog: false,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("183") ||
        features.includes("402") ||
        features.includes("453") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const laparon_charges_id = this.props.laparon_charges_id;
    await api
      .get_barge_laparon_survey_charges(loginUserID, idToken, laparon_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_laparon_survey_charges) {
                let invoice_details = {

                  ...this.state.invoiceDetails,

                  invoice_no: res.barge_laparon_survey_charges[0].invoice_no,
                  invoice_date: res.barge_laparon_survey_charges[0].invoice_date,
                  ppn: res.barge_laparon_survey_charges[0].ppn,
                  ppn_date: res.barge_laparon_survey_charges[0].ppn_date,
                };


                this.setState((prevState) => ({
                  ...prevState,
                  invoiceDetails: invoice_details,
                  ...res.barge_laparon_survey_charges[0],
                  isLoading: false,
                }), () => {

                  let laprn_paid_to_vendor = 0;
                  let laprn_remaining_amount = 0;

                  if (this.state.laparon_charges_payments.length > 0) {
                    for (var j of this.state.laparon_charges_payments) {
                      if (j.date_of_payment) {
                        laprn_paid_to_vendor += Number(j.amount);
                      }
                    }
                    laprn_remaining_amount =
                      Number(this.state.dc_laprn_surv_total) -
                      Number(laprn_paid_to_vendor);
                  } else {
                    laprn_remaining_amount = Number(
                      this.state.dc_laprn_surv_total
                    );
                  }

                  this.setState({
                    // laparon_charges_payments: this.state.laparon_charges_payments,
                    laprn_paid_to_vendor: laprn_paid_to_vendor,
                    laprn_remaining_amount: laprn_remaining_amount,
                  })

                  if (this.state.barge_id) {

                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: this.state.barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                })

              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    await api.get_barges(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.barges) {
              this.setState({
                Barges: res.barges,
                // isLoading: false
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_laparon_survey_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      laparon_charges_payments = [],
      laprn_remaining_amount = 0,
      approval_status = "Pending",
      dc_laprn_surv_currency_xchg_rate = 1,
    } = data;
    const { invoiceDetails: { invoice_no } = {} } = data;
    let payment_related_data = {
      paymentTitle: "Laprn_Charges",
      invoice_no: invoice_no,
      outstanding_amount: laprn_remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: dc_laprn_surv_currency_xchg_rate,
      costing_id: this.props.laparon_charges_id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "607",
      view_payment: "606",
      update_payment: "605",
      delete_payment: "604",
      approver: "600",
      approver2: "602",
    };
    return (
      <PaymentPostings
        access_details={access_details}
        payments={laparon_charges_payments}
        costingType="Laparon Survey Charges"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.state.Barges?.filter(
      (obj) => obj.id === this.state.barge_id
    );
    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      dc_total,
      mv_name,
      dc_laprn_surv_name,
    } = this.state;
    const Grand_total = parseFloat(dc_total ? dc_total : 0).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: dc_laprn_surv_name,
      business_no: mv_name,
      costing_name: "Laporan charges",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        //lapran survey doc charges
        {
          qty: this.state.dc_laprn_quantity_in_mt,
          adjustedPrice: this.state.dc_laprn_surv_price_per_mt,
          desc: `${this.state.dc_laprn_surv_currency_xchg_rate > 0
            ? `Laprn Survey Charges rate @ ${parseFloat(
              this.state.dc_laprn_surv_currency_xchg_rate
            ).toFixed(2)}`
            : "Laprn Survey Charges"
            }`,
          amount: this.state.dc_laprn_surv_base_price,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc:`VAT @ ${this.state.dc_laprn_surv_vat_percent}`,
          amount: `${this.state.dc_laprn_surv_vat_value}`,
        },
        ...(!(config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL")
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: `PPH @ ${this.state.dc_laprn_surv_pph23_percent}`,
              amount: `( ${this.state.dc_laprn_surv_pph23_value} )`,
            },
          ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-lg-10 text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  {` Laporan Charges - ${this.state.costing_no}`}{" "}
                  <span
                    className={
                      this.state.approval_status === "Pending"
                        ? "type_2"
                        : "type_1"
                    }
                    style={{ padding: 8, marginLeft: 16 }}
                  >
                    {this.state.approval_status}
                  </span>
                </h4>
              </div>
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                this.state.created_by &&
                this.state.approved_by !==
                this.Cookie.getCookie("loginUserId") &&
                (this.state.features.includes("600") ||
                  this.state.features.includes("602") ||
                  this.state.features.includes("97")) && (
                  <BusinessApproveButton
                    costingID={this.props.laparon_charges_id}
                    costingType={"Docuementation Charges"}
                    accessInfo={{
                      ...this.state,
                      approver_1: "402",
                      approver_2: "453",
                    }}
                    callbackFn={() => this.componentDidMount()}
                  />
                )}
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div className=" mb-2 p-3 border_dashed rounder">
                {/* <div className="col-lg-12 p-0">
                  <div className="row mb-3">
                    <div className="col-lg-6 pl-0">
                      <div className="row pl-0">
                        <div className="col-lg-6 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Laporan Survey Party
                          </label>
                          <div className="mt-1">
                            {this.state.dc_laprn_surv_party}
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Laporan Survey by
                          </label>
                          <div className="mt-1">
                            {this.state.dc_laprn_surv_name}
                          </div>
                        </div>
                        <div className="col-lg-6 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Quantity in MT
                          </label>
                          <div className="mt-1">
                            {toLocaleString(
                              this.state.dc_laprn_quantity_in_mt
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Price Type
                          </label>
                          <div className="mt-1">
                            {this.state.dc_laprn_surv_price_type}
                          </div>
                        </div>

                        {(this.state.dc_laprn_surv_party ===
                          "Internal" || this.state.dc_laprn_surv_party ===
                          "Third Party") && (
                            <div className="col-lg-6 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                {this.state.dc_laprn_surv_price_type === "Per MT" ? "Price" : "Lumpsu"}
                              </label>
                              <div className="mt-1">
                                {toLocaleString(
                                  this.state.dc_laprn_surv_price_per_mt
                                )}
                                {config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? this.state.dc_laprn_surv_currency
                                  : null}
                              </div>
                            </div>
                          )}

                        {(config.company === "SRPL" || config.company === "AVS" ||
                          config.company === "PRPL") &&
                          this.state.dc_laprn_surv_currency !== "USD" &&
                          this.state.dc_laprn_surv_currency !== null && (
                            <div className="col-lg-6 p-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                Exchange Rate
                              </label>
                              <div className="mt-1">
                                {toLocaleString(
                                  this.state
                                    .dc_laprn_surv_currency_xchg_rate
                                )}
                              </div>
                            </div>
                          )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                            <div className="col-lg-6 p-0">
                              <label className="contract_display_header_label mb-0">
                                Cek Fisic
                              </label>
                              <div className="mt-1">
                                {toLocaleString(
                                  this.state.dc_cek_fisic
                                ) + " IDR"
                                }
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div
                      className="col-lg-6 p-1 rounded"
                      style={{ backgroundColor: "#f7f3f0" }}
                    >
                      <div className="row col-lg-12 p-1">
                        <h6>Laporan Survey Charges</h6>
                      </div>
                      <div className="row pl-2">
                        <div className="col-lg-4 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Base Price
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.dc_laprn_surv_base_price
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            VAT %
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.dc_laprn_surv_vat_percent
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            VAT
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.dc_laprn_surv_vat_percent
                            )}
                          </div>
                        </div>
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                            <div className="col-lg-4 p-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH23 %
                              </label>
                              <div>
                                {toLocaleString(
                                  this.state.dc_laprn_surv_pph23_percent
                                )}
                              </div>
                            </div>
                          )}
                        {(config.company === "SDAM" ||
                          config.company === "SRK" ||
                          config.company === "BTR") && (
                            <div className="col-lg-6 pr-0 mb-2">
                              <label className="contract_display_header_label mb-0">
                                PPH23
                              </label>
                              <div>
                                {toLocaleString(
                                  this.state.dc_laprn_surv_pph23_value
                                )}
                              </div>
                            </div>
                          )}


                        <div className="col-lg-6 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Payable to Vendor
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.dc_laprn_surv_total
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Cost to Company
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.dc_laprn_surv_ctc
                            )}
                            {config.company === "SRPL" || config.company === "AVS" ||
                              config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 p-0">
                      <div className="row">
                        <div className="col-lg-3 p-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Paid to Vendor
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.laprn_paid_to_vendor
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 pr-0 mb-2">
                          <label className="contract_display_header_label mb-0">
                            Remaining Amount
                          </label>
                          <div>
                            {toLocaleString(
                              this.state.laprn_remaining_amount
                            )}
                          </div>
                        </div>
                      </div>
                      {(this.state.features.includes("187") ||
                        this.state.features.includes("97")) && (
                          <div className="card p-0 col-lg-12 border mb-3">
                            <div className="card-header section_header">
                              <h5>Laporan Survey Payment Postings</h5>
                            </div>
                            <br />

                            <InvoiceForm
                              data={this.state.invoiceDetails}
                              open={this.state.invoiceDialog}
                              costingID={this.props.laparon_charges_id}
                              callbackFn={(data) => this.updateInvoice(data)}
                            />
                            {this.renderPaymentPostings(
                              this.state,
                              "Laporan Survey"
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div> */}

                <div className='row'>
                  <div className='table-responsive'>
                    <table className='table table-bordered table-sm'>
                      <thead className='table-header'>
                        <tr>
                          <th className="text-center">Description</th>
                          <th className="text-right">Qty</th>
                          <th className="text-right">Unit Price</th>
                          <th className="text-right">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {`${this.state.dc_laprn_surv_currency_xchg_rate > 1 ? `Exchange Rate @ ${toLocaleString(this.state.dc_laprn_surv_currency_xchg_rate)}` : ``}`}
                            {`Price Type : ${this.state.dc_laprn_surv_price_type}`}

                          </td>
                          <td className='text-right'>{toLocaleString(this.state.dc_laprn_quantity_in_mt)}</td>
                          <td className='text-right'>{toLocaleString(this.state.dc_laprn_surv_price_per_mt)}</td>
                          <td className='text-right'>{toLocaleString(this.state.dc_laprn_surv_base_price)}</td>
                        </tr>
                        <tr>
                          <td>
                            {`VAT @ ${this.state.dc_laprn_surv_vat_percent} `}
                          </td>
                          <td className='text-right'></td>
                          <td className='text-right'></td>
                          <td className='text-right'>{toLocaleString(this.state.dc_laprn_surv_vat_value)}</td>
                        </tr>
                        {!(
                          config.company === "SRPL" ||
                          config.company === "PRPL" ||
                          config.company === "AVS"
                        ) && (
                            <tr>
                              <td>
                                {`PPH @ ${this.state.dc_laprn_surv_pph23_percent} `}
                              </td>
                              <td className='text-right'></td>
                              <td className='text-right'></td>
                              <td className='text-right'>{toLocaleString(this.state.dc_laprn_surv_pph23_value)}</td>
                            </tr>
                          )}
                        {this.state.dc_cek_fisic && (
                          <tr>
                            <td>
                              {`Cek Fisic`}
                            </td>
                            <td className='text-right'></td>
                            <td className='text-right'></td>
                            <td className='text-right'>{toLocaleString(this.state.dc_cek_fisic)}</td>
                          </tr>
                        )}
                        <tr>
                          <th colSpan={3} className="text-right ">
                            {` Payable to ${this.state.dc_laprn_surv_name}`}
                          </th>
                          <th className="text-right ">{`${toLocaleString(
                            this.state.dc_laprn_surv_total
                          )} ${config.company === "SRPL" ||
                            config.company === "PRPL" ||
                            config.company === "AVS"
                            ? "USD"
                            : "IDR"
                            }`}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-lg-12 p-0">
                  <div className="row">
                    <div className="col-lg-3 p-0 mb-2">
                      <label className="contract_display_header_label mb-0">
                        Paid to Vendor
                      </label>
                      <div>
                        {toLocaleString(
                          this.state.laprn_paid_to_vendor
                        )}
                      </div>
                    </div>
                    <div className="col-lg-3 pr-0 mb-2">
                      <label className="contract_display_header_label mb-0">
                        Remaining Amount
                      </label>
                      <div>
                        {toLocaleString(
                          this.state.laprn_remaining_amount
                        )}
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("187") ||
                    this.state.features.includes("97")) && (
                      <div className="card p-0 col-lg-12">

                        <InvoiceForm
                          data={this.state.invoiceDetails}
                          open={this.state.invoiceDialog}
                          costingID={this.props.laparon_charges_id}
                          callbackFn={(data) => this.updateInvoice(data)}
                        />
                        {this.renderPaymentPostings(
                          this.state)}
                      </div>
                    )}
                </div>

              </div>

              <ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
              />

              <AuditInfo
                info={this.state}
              />
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            {(this.state.approval_status === "Pending"
              ? this.state.features.includes("184") ||
              this.state.features.includes("402") ||
              this.state.features.includes("97")
              : this.state.features.includes("97")) && (
                <button
                  type="button"
                  className="drawer_button drawer_text"
                  onClick={() => this.props.onEdit(true)}
                >
                  Edit
                </button>
              )}
          </div>
        </div>
      );
    }
  }
}
