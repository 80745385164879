import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";

import api from "../../../api/api";
import config from "../../../config/config";
import CookieHandler from "../../common/CookieHandler";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import DocumentsUpload from "../../DocumentsUpload";
import { localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";

export default class FixtureNoteView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      fixture_note_type: "0",
      fixture_note_no: "",
      supplier_reference_no: "",
      mother_vessel_name: "",
      agreement_date: null,
      vendor: "",
      laycan: "",
      quantity_in_mt: "",
      allowed_days: "",
      loading_rate_per_day: "",
      dead_freight: "",
      vessel_type: "0",
      port_of_loading: "",
      currency: "0",
      price_per_mt: "",
      price_per_mt_flf: "",
      price_per_day: "",
      demurrage_type: "0",
      demurrage_rate_per_day: "",
      cancellation_fee: "",
      payment_terms: "",
      other_t_and_c: "",
      remarks: "",
      isLoading: true,
      files: [],
      features: [],
      openDocumentsDrawer: false,
      addDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("334") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    try {
      var fixtureID = decodeURIComponent(
        window.atob(this.props.match.params.fixtureID)
      );
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }

    const get_fixture = await api.get_fixture_note(
      loginUserID,
      idToken,
      fixtureID
    );
    if (get_fixture.status >= 200 && get_fixture.status < 300) {
      get_fixture.json().then((res) => {
        if (res.code === "200") {
          if (res.fixture_notes_data) {
            if (res.fixture_notes_data.length > 0) {
              this.setState({
                fixture_note_type: res.fixture_notes_data[0].fixture_note_type,
                fixture_note_no: res.fixture_notes_data[0].fixture_note_no,
                supplier_reference_no:
                  res.fixture_notes_data[0].supplier_reference_no,
                mother_vessel_name:
                  res.fixture_notes_data[0].mother_vessel_name,
                agreement_date: res.fixture_notes_data[0].agreement_date,
                vendor: res.fixture_notes_data[0].vendor,
                laycan: res.fixture_notes_data[0].laycan,
                quantity_in_mt: res.fixture_notes_data[0].quantity_in_mt,
                allowed_days: res.fixture_notes_data[0].allowed_days,
                loading_rate_per_day:
                  res.fixture_notes_data[0].loading_rate_per_day,
                dead_freight: res.fixture_notes_data[0].dead_freight,
                vessel_type: res.fixture_notes_data[0].vessel_type,
                port_of_loading: res.fixture_notes_data[0].port_of_loading,
                currency: res.fixture_notes_data[0].currency,
                price_per_mt: res.fixture_notes_data[0].price_per_mt,
                price_per_mt_flf: res.fixture_notes_data[0].price_per_mt_flf,
                price_per_day: res.fixture_notes_data[0].price_per_day,
                demurrage_type: res.fixture_notes_data[0].demurrage_type,
                demurrage_rate_per_day:
                  res.fixture_notes_data[0].demurrage_rate_per_day,
                cancellation_fee: res.fixture_notes_data[0].cancellation_fee,
                payment_terms: res.fixture_notes_data[0].payment_terms,
                other_t_and_c: res.fixture_notes_data[0].other_t_and_c,
                remarks: res.fixture_notes_data[0].remarks,
                status: res.fixture_notes_data[0].status,
                vendor_name: res.fixture_notes_data[0].vendor_name,
                files: res.fixture_notes_data[0].files,
                isLoading: false,
              });
            }
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "601") {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message,
          });
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    document.title = config.documentTitle + "View Fixture Note";
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  onAddResponse = (value) => {
    this.setState({ addDocuments: false, openDocumentsDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      openDocumentsDrawer: false,
      addDocuments: false,
    });
  };

  render() {
    try {
      var fixtureID = decodeURIComponent(
        window.atob(this.props.match.params.fixtureID)
      );
    } catch (e) {
      window.location.href = "/Pagenotfound";
    }
    let drawerComponent;
    if (this.state.addDocuments && fixtureID) {
      drawerComponent = (
        <DocumentsUpload
          feature_name={"Fixture_notes"}
          feature_seq_id={fixtureID}
          onAddResponse={this.onAddResponse}
          documents={this.state.files}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && (
           <Loader />
          )}
          <Drawer
            anchor="right"
            open={this.state.openDocumentsDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="col-sm pl-0">
                <div className="col-lg-8 text-left pl-0">
                  <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                    <a href={"/fixture-notes"}>
                      <i className="fa fa-arrow-left left_arrow_adjst" />
                    </a>
                    Fixture Note - {this.state.fixture_note_no}
                    <span
                      className={
                        this.state.status === "Active" ? "type_1" : "type_2"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {this.state.status}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMessage}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5> Fixture Note Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row col-lg-12 p-0">
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Fixture Note Type
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.fixture_note_type
                            ? this.state.fixture_note_type
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Supplier Reference No
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.supplier_reference_no
                            ? this.state.supplier_reference_no
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Mother Vessel Name
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.mother_vessel_name
                            ? this.state.mother_vessel_name
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Agreement Date
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.agreement_date
                            ? localDateFormate(this.state.agreement_date)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4  mb-3">
                        <label className="contract_display_header_label">
                          Vendor
                        </label>
                        {/* <div className="contract_display_header_value">{this.state.vendor_name ? this.state.vendor_name : '-'}</div> */}
                        {this.state.vendor_name ? (
                          <a
                            href={"/view-vendor/" + btoa(this.state.vendor)}
                            rel="noopener noreferrer"
                            role="button"
                            style={{ color: config.themeColor }}
                          >
                            {this.state.vendor_name}
                          </a>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Laycan
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.laycan ? this.state.laycan : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Quantity in MT
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.quantity_in_mt
                            ? this.toLocaleString(this.state.quantity_in_mt)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Allowed Days
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.allowed_days
                            ? this.toLocaleString(this.state.allowed_days)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Loading Rate per Day
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.loading_rate_per_day
                            ? this.toLocaleString(
                                this.state.loading_rate_per_day
                              )
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Dead Freight
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.dead_freight
                            ? this.toLocaleString(this.state.dead_freight)
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Vessel Type
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.vessel_type
                            ? this.state.vessel_type
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Port of Loading
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.port_of_loading
                            ? this.state.port_of_loading
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5> Price Details</h5>
                  </div>
                  <div className="card-body">
                    <div className="row col-lg-12 p-0">
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Currency
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.currency ? this.state.currency : "-"}
                        </div>
                      </div>
                      <div className="col-lg-4 mb-3">
                        <label className="contract_display_header_label">
                          Price per MT{" "}
                          {this.state.fixture_note_type === "Floating Crane"
                            ? "(FC)"
                            : null}
                        </label>
                        <div className="contract_display_header_value">
                          {this.state.price_per_mt
                            ? this.toLocaleString(this.state.price_per_mt)
                            : "-"}
                        </div>
                      </div>
                      {this.state.fixture_note_type === "Floating Crane" && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Price per MT (FLF)
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.price_per_mt_flf
                              ? this.toLocaleString(this.state.price_per_mt_flf)
                              : "-"}
                          </div>
                        </div>
                      )}
                      {this.state.fixture_note_type === "Vessel Chartering" && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Price per Day
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.price_per_day
                              ? this.toLocaleString(this.state.price_per_day)
                              : "-"}
                          </div>
                        </div>
                      )}{" "}
                      {(this.state.fixture_note_type === "Stevedoring" ||
                        this.state.fixture_note_type === "Floating Crane") && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Demurrage Type
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.demurrage_type
                              ? this.state.demurrage_type
                              : "-"}
                          </div>
                        </div>
                      )}{" "}
                      {this.state.fixture_note_type && (
                        <div className="col-lg-4 mb-3">
                          <label className="contract_display_header_label">
                            Demurrage Rate per Day
                          </label>
                          <div className="contract_display_header_value">
                            {this.state.demurrage_rate_per_day
                              ? this.toLocaleString(
                                  this.state.demurrage_rate_per_day
                                )
                              : "-"}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="card p-0 border mb-3">
                  <div className="card-header section_header">
                    <h5>Terms</h5>
                  </div>
                  <div className="card-body">
                    <div className="row col-lg-12 p-0">
                      <div className="col-lg-12 mb-3">
                        <label className="contract_display_header_label">
                          Cancellation Fee
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{ textTransform: "upperCase" }}
                        >
                          {this.state.cancellation_fee
                            ? this.state.cancellation_fee
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="contract_display_header_label">
                          Payment Terms
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{
                            textTransform: "upperCase",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {this.state.payment_terms
                            ? this.state.payment_terms
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="contract_display_header_label">
                          Other T&C
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{
                            textTransform: "upperCase",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {this.state.other_t_and_c
                            ? this.state.other_t_and_c
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label className="contract_display_header_label">
                          Remarks
                        </label>
                        <div
                          className="contract_display_header_value"
                          style={{
                            textTransform: "upperCase",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {this.state.remarks ? this.state.remarks : "-"}
                        </div>
                      </div>
                    </div>
                    {(this.state.features.includes("337") ||
                      this.state.features.includes("97")) && (
                      <div className="row">
                        <span
                          className="col-lg-12 contract_display_header_value pl-0 mb-2"
                          style={{ fontWeight: "bold" }}
                        >
                          Uploaded Documents
                        </span>
                        {this.state.files.length > 0 ? (
                          <div className="row">
                            {this.state.files.map((e, index) => (
                              <div
                                className={
                                  e.file_path ? "col-lg-12 mb-3" : "col-lg-12"
                                }
                                key={index}
                              >
                                {e.file_path && (
                                  <div className="col-lg-12 p-0">
                                    <a
                                      href={
                                        config.apiDomain + "/" + e.file_path
                                      }
                                      role="button"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ color: config.themeColor }}
                                    >
                                      {e.file_description}
                                    </a>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="row col-lg-12">
                            <p>No files to show</p>
                          </div>
                        )}
                        <p className="row col-lg-12">
                          Add Documents &nbsp;
                          <i
                            className="fa fa-plus-circle"
                            style={{
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.setState({
                                openDocumentsDrawer: true,
                                addDocuments: true,
                              });
                            }}
                          />
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={() => (window.location.href = "/fixture-notes")}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={() =>
                    (window.location.href =
                      "/update-fixture-note/" + btoa(fixtureID))
                  }
                >
                  EDIT
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
