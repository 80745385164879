import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import { TextField, Snackbar, Checkbox, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExportTaxAdd from "./Export-Tax-Add";
import ExportTaxView from "./Export-Tax-View";
import ExportTaxUpdate from "./Export-Tax-Update";
import config from "../../../config/config";
import { withStyles } from "@material-ui/core/styles";
import DeleteConfirmation from "../../common/DeleteConfirmation";
import { pad } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class ExportTaxList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      export_tax_costing_barges: [],
      barge_nomination: "",
      business_no_id: [],
      business_no_drop_value: [],
      jetty_name: "",
      isLoading: true,
      features: [],
      ExportTaxAdd: false,
      ExportTaxUpdate: false,
      ExportTaxView: false,
      exportTaxID: null,
      updateDocuments: false,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("175") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });
  }

  searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_name = this.state.barge_nomination;
    let jetty_name = this.state.jetty_name;
    let business_no = this.state.business_no_id;
    let barge_id = this.state.barge_id;
    // if (barge_name === '') {
    //   this.setState({
    //     barge_name_error: true,
    //     snackBarErrorOpen: true,
    //     errorMsg: "Please Fill Mandatory Fields"
    //   });
    //   return;
    // }
    api
      .get_barges_for_export_tax(
        loginUserID,
        idToken,
        business_no,
        barge_id,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                if (res.barges.length === 0) {
                  this.setState({
                    export_tax_costing_barges: res.barges,
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                } else {
                  this.setState({
                    export_tax_costing_barges: res.barges,
                  });
                }
              }
            }
          });
        }
      });
  }

  toLocaleString(value) {
    return value ? Number(value).toLocaleString() : 0;
  }

  deleteBargePurchaseFinancial = (data) => {
    this.setState({ isLoading: true });
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var export_tax_id = data.id;
    api
      .delete_barge_export_tax(loginUserID, idToken, export_tax_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                successMsg: res.message,
                errorMsg: "",
                isLoading: false,
                deleteCosting: false,
              });
              this.searchBargeHandler();
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
                isLoading: false,
              });
            } else if (res.code === "650") {
              this.setState({
                deleteCosting: false,
                snackBarErrorOpen: true,
                errorMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                deleteAlertMsg:
                  "Payments are available for this record. Please delete Payments before deleting this record.",
                // successMsg: "",
                // errorMsg: res.message,
                isLoading: false,
              });
            }
          });
        }
      });
  };

  onUpdateResponse = (value) => {
    if (value === "200") {
      this.setState({
        ExportTaxUpdate: false,
        ExportTaxAdd: false,
        ExportTaxView: true,
        updateDocuments: false,
      });
    }
  };

  onAddResponse = (value) => {
    if (value === "200") {
      this.setState(
        { ExportTaxAdd: false, ExportTaxView: false, showDrawer: false },
        () => this.searchBargeHandler()
      );
    }
  };

  onCancel = (value) => {
    if (value === false) {
      this.setState({
        ExportTaxUpdate: false,
        ExportTaxAdd: false,
        ExportTaxView: false,
        showDrawer: false,
        exportTaxID: null,
        updateDocuments: false,
      });
    }
  };

  onEdit = (value) => {
    if (value === true) {
      this.setState({
        ExportTaxUpdate: true,
        showDrawer: true,
        ExportTaxAdd: false,
        ExportTaxView: false,
        updateDocuments: false,
      });
    }
  };

  addDocuments = () => {
    this.setState({
      showDrawer: true,
      ExportTaxUpdate: true,
      ExportTaxView: false,
      ExportTaxAdd: false,
      updateDocuments: true,
    });
  };

  render() {
    let drawerComponent;
    if (this.state.ExportTaxAdd) {
      drawerComponent = (
        <ExportTaxAdd
          onAddResponse={this.onAddResponse}
          onCancel={this.onCancel}
        />
      );
    } else if (this.state.ExportTaxView && this.state.exportTaxID !== null) {
      drawerComponent = (
        <ExportTaxView
          exportTaxID={this.state.exportTaxID}
          returnDocumentsHandler={this.addDocuments}
          onCancel={this.onCancel}
          onEdit={this.onEdit}
        />
      );
    } else if (this.state.ExportTaxUpdate && this.state.exportTaxID !== null) {
      drawerComponent = (
        <ExportTaxUpdate
          exportTaxID={this.state.exportTaxID}
          onUpdateCosting={this.onUpdateResponse}
          onCancel={this.onCancel}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="card">
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>

          <div className="row mb-1 p-2">
            <div className="col-lg-3 p-0">
              <Autocomplete
                multiple
                options={this.state.business_no_drop_value}
                getOptionLabel={(option) => option.mother_vessel_name}
                renderOption={(option, { selected }) => (
                  <React.Fragment>
                    <CheckBox style={{ marginRight: 8 }} checked={selected} />
                    <div className="col-lg p-0 m-0">
                      <label className="form_label mb-0">
                        {/* {option.name} */}
                        {option.mother_vessel_name}
                      </label>
                      <label
                        className="contract_display_header_value m-0 pl-0"
                        style={{ fontSize: 10, paddingLeft: 5 }}
                      >
                        {"( " + option.name + " )"}
                      </label>
                    </div>
                  </React.Fragment>
                )}
                onChange={(event, value) => {
                  if (value) {
                    var data = [];
                    for (var i of value) {
                      data.push(i.value);
                    }
                    //var floating_crane_vendor = data.toString();
                    this.setState({ business_no_id: data });
                  } else {
                    this.setState({ business_no_id: [] });
                  }
                }}
                disableCloseOnSelect
                name="Business_Number"
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business No"
                    // placeholder="Business No"
                    variant="standard"
                    style={{ top: 5 }}
                    fullWidth
                  />
                )}
                style={{ display: "contents" }}
              />
            </div>
            <div className="col-lg-3 pr-0 pt-2">
              <button
                onClick={() => this.searchBargeHandler()}
                className="header_button header_button_text add_button_adjustment"
                style={{ width: "auto", marginTop: "15px" }}
              >
                Load
              </button>
            </div>
            {(this.state.features.includes("134") ||
              this.state.features.includes("97")) && (
              <div className="col text-right float-sm-left">
                <button
                  className="header_button header_button_text add_button_adjustment"
                  style={{ width: "auto", marginTop: "15px" }}
                  onClick={() =>
                    this.setState({ ExportTaxAdd: true, showDrawer: true })
                  }
                >
                  New
                </button>
              </div>
            )}
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>

          {this.state.export_tax_costing_barges.length > 0 && (
            <div
              className={
                this.state.export_tax_costing_barges.length > 0
                  ? "shadow-sm p-2 mb-2 bg-white border"
                  : ""
              }
            >
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-bordered table-sm mb-0">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true" className=" text-uppercase"></th>
                        <th nowrap="true" className=" text-uppercase">
                          Costing ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge ID
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Barge Nomination
                        </th>
                        <th nowrap="true" className=" text-uppercase">
                          Business No
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Quantity
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Price PMT
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Total Payable
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Paid
                        </th>
                        <th
                          nowrap="true"
                          className="text-right  text-uppercase"
                        >
                          Remaining
                        </th>
                      </tr>
                    </thead>
                    {this.state.export_tax_costing_barges.map((p, index) => (
                      <tbody key={index}>
                        <tr className="rounded">
                          <td className="text-center" nowrap="true">
                            {/* <a href={"/barge-pur-fin-view-export-tax/" + btoa(p.id)}>
                              <i className="fa fa-eye mr-2" aria-hidden="true"
                                style={{ fontSize: 22, color: '#3256a8', cursor: 'pointer' }}></i>
                            </a> */}
                            <i
                              className="fa fa-eye mr-2"
                              aria-hidden="true"
                              style={{
                                fontSize: 22,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                this.setState({
                                  ExportTaxView: true,
                                  exportTaxID: p.id,
                                  showDrawer: true,
                                })
                              }
                            ></i>
                            {(this.state.features.includes("177") ||
                              this.state.features.includes("97")) &&
                              p.approval_status === "Pending" && (
                                <i
                                  className="fa fa-trash-o"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: 21,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      barge_pur_fin_id: p.id,
                                      deleteData: p,
                                      deleteCosting: true,
                                    })
                                  }
                                ></i>
                              )}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {p.costing_no}
                          </td>
                          <td className="">{pad(p.barge_id)}</td>
                          <td className=" text-left" nowrap="true">
                            {p.barge_nomination}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {p.business_no}
                          </td>
                          <td className=" text-left" nowrap="true">
                            {this.toLocaleString(p.quantity_for_export_tax)}
                          </td>
                          <td className=" text-right" nowrap="true">
                            {this.toLocaleString(p.export_tax_per_mt)}
                          </td>
                          <td className=" text-right" nowrap="true">
                            {this.toLocaleString(p.total_payable)}
                          </td>
                          <td className=" text-right" nowrap="true">
                            {this.toLocaleString(p.paid)}
                          </td>
                          <td className=" text-right" nowrap="true">
                            {this.toLocaleString(p.Remaining_amount)}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          )}
          {/**
           *
           *  Delete Barge Costing Alert Message Modal
           *
           */}

          {this.state.deleteCosting && (
            <DeleteConfirmation
              open={this.state.deleteCosting}
              close={() => this.setState({ deleteCosting: false })}
              callbackFn={() =>
                this.deleteBargePurchaseFinancial(this.state.deleteData)
              }
            />
          )}
        </div>
      );
    }
  }
}
