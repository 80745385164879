import React, { Component } from "react";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";

import api from "../../../api/api";
import config from "../../../config/config";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CookieHandler from "../../common/CookieHandler";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";

import {
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import { localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";

export default class FixtureNoteAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      fixture_note_type: "0",
      fixture_note_no: "",
      supplier_reference_no: "",
      mother_vessel_name: "",
      agreement_date: null,
      vendor: "",
      laycan: "",
      quantity_in_mt: "",
      allowed_days: "",
      loading_rate_per_day: "",
      dead_freight: "",
      vessel_type: "0",
      port_of_loading: "",
      currency: "0",
      price_per_mt: "",
      price_per_mt_flf: "",
      price_per_day: "",
      demurrage_type: "0",
      demurrage_rate_per_day: "",
      cancellation_fee: "",
      payment_terms: "",
      other_t_and_c: "",
      remarks: "",
      isLoading: true,
      vendors_list: [],
      vendors_data: [],
      currencyData: [],
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Fixture_notes",
        },
      ],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] }, () => {
      if (
        !(
          this.state.features.includes("333") ||
          this.state.features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
    });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var status = "";

    // Get Currency data
    const get_currencies = await api.getCurrencies(loginUserID, idToken);
    if (get_currencies.status >= 200 && get_currencies.status < 300) {
      get_currencies.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.Currency) {
            var currencies = [
              <MenuItem value="0" key={-1}>
                {" "}
                Please Select
              </MenuItem>,
            ];
            res.Currency.forEach((item) => {
              currencies.push(
                <MenuItem value={item.currency} key={[item.id]}>
                  {item.currency}
                </MenuItem>
              );
            });
            this.setState({
              currencyData: currencies,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }

    //Get vendors
    const vendors = await api.getVendors(loginUserID, idToken, status);
    if (vendors.status >= 200 && vendors.status < 300) {
      vendors.json().then((res) => {
        if (res.code === "200") {
          if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);

          if (res.vendors) {
            const surveyors = [];
            const bargeData = [];
            const vendorType_Vessel_Data = [];
            const vendorType_Stevedore_Data = [];
            res.vendors.forEach((item) => {
              if (item.vendor_type === "Surveyor") {
                surveyors.push({
                  name: item.vendor_name,
                  value: item.id,
                  key: item.id,
                });
              }
              if (item.vendor_type === "Vessel Agent") {
                vendorType_Vessel_Data.push({
                  name: item.vendor_name,
                  value: item.id,
                  key: item.id,
                });
              }
              if (item.vendor_type === "Stevedore") {
                vendorType_Stevedore_Data.push({
                  name: item.vendor_name,
                  value: item.id,
                  key: item.id,
                });
              }
              if (item.vendor_type === "Barge") {
                bargeData.push({
                  name: item.vendor_name,
                  value: item.id,
                  key: item.id,
                });
              }
            });
            this.setState({
              surveyorsData: surveyors,
              vendorType_Vessel_Data: vendorType_Vessel_Data,
              stevedoresData: vendorType_Stevedore_Data,
              bargeData: bargeData,
              vendors_list: res.vendors,
              isLoading: false,
            });
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
    document.title = config.documentTitle + "New Fixture Note";
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  nextStepHandler = () => {
    let { fixture_note_type, agreement_date, vendor } = this.state;
    if (this.state.activeStep === 0) {
      if (fixture_note_type === "0") {
        this.setState({
          fixture_note_type_error: true,
        });
      }

      if (agreement_date === null) {
        this.setState({
          agreement_date_error: true,
        });
      }

      if (vendor === "") {
        this.setState({
          vendor_name_error: true,
        });
      }

      if (
        fixture_note_type === "0" ||
        agreement_date === null ||
        vendor === ""
      ) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
    }
    if (this.state.activeStep === 2) {
      /**
       *  Checking Mandatory files
       */
      var missing_fields = 0;
      let files_missing = 0;
      var row = this.state.files;
      for (var obj of row) {
        if (obj.file_type !== "" || obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
          if (obj.file_content === "") {
            files_missing++;
          }
        } else if (obj.file_type === "" || obj.file_content === "") {
          missing_fields--;
          files_missing--;
        }
      }
      this.setState({
        files: row,
      });

      if (missing_fields > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please fill mandatory fields.",
        });
        return;
      }
      if (files_missing > 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select file.",
        });
        return;
      }
    }
    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    this.setState({
      isLoading: true,
    });

    let {
      fixture_note_type,
      supplier_reference_no,
      mother_vessel_name,
      agreement_date,
      vendor,
      laycan,
      quantity_in_mt,
      allowed_days,
      loading_rate_per_day,
      dead_freight,
      vessel_type,
      port_of_loading,
      currency,
      price_per_mt,
      price_per_mt_flf,
      price_per_day,
      demurrage_type,
      demurrage_rate_per_day,
      cancellation_fee,
      payment_terms,
      other_t_and_c,
      remarks,
      files,
    } = this.state;

    api
      .add_fixture_notes(
        loginUserID,
        idToken,
        fixture_note_type,
        supplier_reference_no,
        mother_vessel_name,
        agreement_date,
        vendor,
        laycan,
        quantity_in_mt,
        allowed_days,
        loading_rate_per_day,
        dead_freight,
        vessel_type,
        port_of_loading,
        currency,
        price_per_mt,
        price_per_mt_flf,
        price_per_day,
        demurrage_type,
        demurrage_rate_per_day,
        cancellation_fee,
        payment_terms,
        other_t_and_c,
        remarks,
        files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: "created sucessfully",
                },
                () => {
                  if (res.id) {
                    window.location.href = "/view-fixture-note/" + btoa(res.id);
                  } else {
                    window.location.href = "/fixture-notes";
                  }
                }
              );
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
                isLoading: false,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else {
              alert("Unexpected error occured. Please contact administrator.");
              this.setState({ isLoading: false });
            }
          });
        }
      });
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    const quan = /^\d*(\.\d{0,3})?$/;
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          {this.state.isLoading && (
           <Loader />
          )}
          {!this.state.isLoading && (
            <div className="content-header">
              <div className="col-sm pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  New Fixture Note
                </h4>
              </div>
            </div>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading && (
            <form className="container p-3">
              <div className="row mb-0 stepper">
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 0
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Fixture Note Details
                  </span>
                  <i
                    className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                    aria-hidden="true"
                    style={{
                      color:
                        this.state.activeStep > 0
                          ? "#31c197"
                          : this.state.activeStep === 0
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                </span>

                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 1
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 1
                          ? "#31c197"
                          : this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 1
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Price Details
                  </span>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 2
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 2
                          ? "#31c197"
                          : this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 2
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Terms
                  </span>
                </span>
                <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                  <i
                    className={
                      this.state.activeStep > 3
                        ? "fa fa-check-circle"
                        : "fa fa-check-circle-o"
                    }
                    aria-hidden="true"
                    style={{
                      fontSize: 20,
                      color:
                        this.state.activeStep > 3
                          ? "#31c197"
                          : this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  ></i>
                  <span
                    className="stepper_text"
                    style={{
                      color:
                        this.state.activeStep === 3
                          ? config.themeColor
                          : "#ccc",
                    }}
                  >
                    Preview and Submit
                  </span>
                </span>
              </div>
              <div className="col-lg card" style={{ overflowY: "scroll" }}>
                {this.state.activeStep === 0 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create Fixture Note
                      </h5>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6">
                        <div className="row mb-2">
                          <label className="form_label mb-0">
                            Fixture Note Type{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <TextField
                            name="fixture_note_type"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              let vendors = [];
                              const data = this.state.vendors_list;
                              const filter_vendors = data.filter(
                                (e) =>
                                  e.vendor_type === "Stevedore" ||
                                  e.vendor_type === "Floating Crane"
                              );
                              for (var i of filter_vendors) {
                                vendors.push({
                                  name: i.vendor_name,
                                  value: i.id,
                                  key: i.id,
                                });
                              }
                              this.setState({
                                fixture_note_type: e.target.value,
                                vendors_data: vendors,
                                fixture_note_type_error: false,
                              });
                            }}
                            value={this.state.fixture_note_type}
                            error={this.state.fixture_note_type_error}
                            select
                          >
                            <MenuItem value="0">Please Select</MenuItem>
                            <MenuItem value="Stevedoring">Stevedoring</MenuItem>
                            <MenuItem value="Floating Crane">
                              Floating Crane
                            </MenuItem>
                            <MenuItem value="Vessel Chartering">
                              Vessel Chartering
                            </MenuItem>
                          </TextField>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Supplier Reference No
                        </label>
                        <TextField
                          name="supplier_reference_no"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              supplier_reference_no: e.target.value,
                            });
                          }}
                          value={this.state.supplier_reference_no}
                          placeholder="Supplier Reference No"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Mother Vessel Name
                        </label>
                        <TextField
                          name="mother_vessel_name"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              mother_vessel_name: e.target.value,
                            });
                          }}
                          value={this.state.mother_vessel_name}
                          placeholder="Mother Vessel Name"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Agreement Date <span className="text-danger">*</span>
                        </label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk
                            hideTabs
                            inputVariant="outlined"
                            variant="inline"
                            margin="dense"
                            fullWidth
                            ampm={false}
                            format="dd/MM/yyyy"
                            value={this.state.agreement_date}
                            error={this.state.agreement_date_error}
                            onChange={(date) => {
                              this.setState({
                                agreement_date: date,
                                agreement_date_error: false,
                              });
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <EventIcon />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              className: "pl-0",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Vendor <span className="text-danger">*</span>
                        </label>
                        <Autocomplete
                          options={this.state.vendors_data}
                          getOptionLabel={(option) => option.name}
                          size="small"
                          onChange={(event, value) => {
                            value != null
                              ? this.setState({
                                  vendor: value.value,
                                  bargeVendorNameValue: value,
                                  vendor_name: value.name,
                                  vendor_name_error: false,
                                })
                              : this.setState({
                                  vendor: "",
                                  vendor_name_error: false,
                                  vendor_name: "",
                                });
                          }}
                          name="bargeVendorName"
                          value={this.state.bargeVendorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Vendor"
                              variant="outlined"
                              error={this.state.vendor_name_error}
                              fullWidth
                              style={{ top: 8 }}
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Laycan</label>
                        <TextField
                          name="laycan"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              laycan: e.target.value,
                            });
                          }}
                          value={this.state.laycan}
                          placeholder="Laycan"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Quantity in MT
                        </label>
                        <TextField
                          name="quantity_in_mt"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              quan.test(e.target.value)
                            ) {
                              this.setState({
                                quantity_in_mt: e.target.value,
                              });
                            }
                          }}
                          value={this.state.quantity_in_mt}
                          placeholder="Quantity in MT"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Allowed Days</label>
                        <TextField
                          name="allowed_days"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              allowed_days: e.target.value,
                            });
                          }}
                          value={this.state.allowed_days}
                          placeholder="Allowed Days"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Loading Rate per Day
                        </label>
                        <TextField
                          name="loading_rate_per_day"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                loading_rate_per_day: e.target.value,
                              });
                            }
                          }}
                          value={this.state.loading_rate_per_day}
                          placeholder="Loading Rate per Day"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Dead Freight</label>
                        <TextField
                          name="dead_freight"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                dead_freight: e.target.value,
                              });
                            }
                          }}
                          value={this.state.dead_freight}
                          placeholder="Dead Freight"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Vessel Type</label>
                        <TextField
                          name="vessel_type"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              vessel_type: e.target.value,
                            });
                          }}
                          value={this.state.vessel_type}
                          placeholder="Vessel Type"
                          select
                        >
                          <MenuItem value="0">Please Select</MenuItem>
                          <MenuItem value="Geared">Geared</MenuItem>
                          <MenuItem value="Gearless">Gearless</MenuItem>
                        </TextField>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Port of Loading
                        </label>
                        <TextField
                          name="port_of_loading"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              port_of_loading: e.target.value,
                            });
                          }}
                          value={this.state.port_of_loading}
                          placeholder="Port of Loading"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.activeStep === 1 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Price Details
                      </h5>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6">
                        <label className="form_label mb-0">Currency</label>
                        <TextField
                          name="currency"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              currency: e.target.value,
                            });
                          }}
                          value={this.state.currency}
                          select
                        >
                          {this.state.currencyData}
                        </TextField>
                      </div>
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Price per MT{" "}
                          {this.state.fixture_note_type === "Floating Crane"
                            ? "(FC)"
                            : null}
                        </label>
                        <TextField
                          name="price_per_mt"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            if (
                              e.target.value === "" ||
                              pr.test(e.target.value)
                            ) {
                              this.setState({
                                price_per_mt: e.target.value,
                              });
                            }
                          }}
                          value={this.state.price_per_mt}
                          placeholder="Price per MT"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      {this.state.fixture_note_type === "Floating Crane" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Price per MT (FLF)
                          </label>
                          <TextField
                            name="price_per_mt_flf"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState({
                                  price_per_mt_flf: e.target.value,
                                });
                              }
                            }}
                            value={this.state.price_per_mt_flf}
                            placeholder="Price per MT"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      )}
                      {this.state.fixture_note_type === "Vessel Chartering" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Price per Day
                          </label>
                          <TextField
                            name="price_per_day"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState({
                                  price_per_day: e.target.value,
                                });
                              }
                            }}
                            value={this.state.price_per_day}
                            placeholder="Price per Day"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      )}
                      {(this.state.fixture_note_type === "Stevedoring" ||
                        this.state.fixture_note_type === "Floating Crane") && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Demurrage Type
                          </label>
                          <TextField
                            name="demurrage_type"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              this.setState({
                                demurrage_type: e.target.value,
                              });
                            }}
                            value={this.state.demurrage_type}
                            placeholder="Price per Day"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            select
                          >
                            <MenuItem value="0">Please Select</MenuItem>
                            <MenuItem value="Demurrage/Despatch">
                              Demurrage/Despatch
                            </MenuItem>
                            <MenuItem value="CQD">CQD</MenuItem>
                          </TextField>
                        </div>
                      )}
                      {this.state.fixture_note_type !== "0" && (
                        <div className="col-lg-6">
                          <label className="form_label mb-0">
                            Demurrage Rate per Day
                          </label>
                          <TextField
                            name="demurrage_rate_per_day"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            onChange={(e) => {
                              if (
                                e.target.value === "" ||
                                pr.test(e.target.value)
                              ) {
                                this.setState({
                                  demurrage_rate_per_day: e.target.value,
                                });
                              }
                            }}
                            value={this.state.demurrage_rate_per_day}
                            placeholder="Demurrage Rate per Day"
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {this.state.activeStep === 2 && (
                  <div className="section_block">
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Terms
                      </h5>
                    </div>

                    <div className="row mb-0">
                      <div className="col-lg-6">
                        <label className="form_label mb-0">
                          Cancellation Fee
                        </label>
                        <TextField
                          name="cancellation_fee"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              cancellation_fee: e.target.value,
                            });
                          }}
                          value={this.state.cancellation_fee}
                          placeholder="Cancellation Fee"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="form_label mb-0">Payment Terms</label>
                        <TextField
                          name="payment_terms"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              payment_terms: e.target.value,
                            });
                          }}
                          multiline
                          rows={4}
                          value={this.state.payment_terms}
                          placeholder="Payment Terms"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="form_label mb-0">Other T&C</label>
                        <TextField
                          name="other_t_and_c"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              other_t_and_c: e.target.value,
                            });
                          }}
                          multiline
                          rows={4}
                          value={this.state.other_t_and_c}
                          placeholder="Other T&C"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                      <div className="col-lg-12">
                        <label className="form_label mb-0">Remarks</label>
                        <TextField
                          name="remarks"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          onChange={(e) => {
                            this.setState({
                              remarks: e.target.value,
                            });
                          }}
                          multiline
                          rows={4}
                          value={this.state.remarks}
                          placeholder="remarks"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </div>
                    </div>
                    {(this.state.features.includes("337") ||
                      this.state.features.includes("97")) && (
                      <FileUpload
                        files={this.state.files}
                        feature_name="Fixture_notes"
                        callbackFn={(data) => this.setState({ files: data })}
                      />
                    )}
                  </div>
                )}

                {this.state.activeStep === 3 && (
                  <div className="section_block">
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5> Fixture Note Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Fixture Note Type
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.fixture_note_type
                                ? this.state.fixture_note_type
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Supplier Reference No
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.supplier_reference_no
                                ? this.state.supplier_reference_no
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Mother Vessel Name
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.mother_vessel_name
                                ? this.state.mother_vessel_name
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Agreement Date
                            </label>
                            <div className="contract_display_header_value">
                              {localDateFormate(this.state.agreement_date)
                                }
                            </div>
                          </div>
                          <div className="col-lg-4  mb-3">
                            <label className="contract_display_header_label">
                              Vendor
                            </label>
                            {/* <div className="contract_display_header_value">{this.state.vendor_name ? this.state.vendor_name : '-'}</div> */}
                            {this.state.vendor_name ? (
                              <a
                                href={"/view-vendor/" + btoa(this.state.vendor)}
                                rel="noopener noreferrer"
                                role="button"
                                style={{ color: config.themeColor }}
                              >
                                {this.state.vendor_name}
                              </a>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Laycan
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.laycan ? this.state.laycan : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Quantity in MT
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.quantity_in_mt
                                ? this.toLocaleString(this.state.quantity_in_mt)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Allowed Days
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.allowed_days
                                ? this.toLocaleString(this.state.allowed_days)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Loading Rate per Day
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.loading_rate_per_day
                                ? this.toLocaleString(
                                    this.state.loading_rate_per_day
                                  )
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Dead Freight
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.dead_freight
                                ? this.toLocaleString(this.state.dead_freight)
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Vessel Type
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.vessel_type
                                ? this.state.vessel_type
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Port of Loading
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.port_of_loading
                                ? this.state.port_of_loading
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5> Price Details</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0">
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Currency
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.currency ? this.state.currency : "-"}
                            </div>
                          </div>
                          <div className="col-lg-4 mb-3">
                            <label className="contract_display_header_label">
                              Price per MT{" "}
                              {this.state.fixture_note_type === "Floating Crane"
                                ? "(FC)"
                                : null}
                            </label>
                            <div className="contract_display_header_value">
                              {this.state.price_per_mt
                                ? this.toLocaleString(this.state.price_per_mt)
                                : "-"}
                            </div>
                          </div>
                          {this.state.fixture_note_type ===
                            "Floating Crane" && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Price per MT (FLF)
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.price_per_mt_flf
                                  ? this.toLocaleString(
                                      this.state.price_per_mt_flf
                                    )
                                  : "-"}
                              </div>
                            </div>
                          )}
                          {this.state.fixture_note_type ===
                            "Vessel Chartering" && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Price per Day
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.price_per_day
                                  ? this.toLocaleString(
                                      this.state.price_per_day
                                    )
                                  : "-"}
                              </div>
                            </div>
                          )}{" "}
                          {(this.state.fixture_note_type === "Stevedoring" ||
                            this.state.fixture_note_type ===
                              "Floating Crane") && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Demurrage Type
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.demurrage_type
                                  ? this.state.demurrage_type
                                  : "-"}
                              </div>
                            </div>
                          )}{" "}
                          {this.state.fixture_note_type && (
                            <div className="col-lg-4 mb-3">
                              <label className="contract_display_header_label">
                                Demurrage Rate per Day
                              </label>
                              <div className="contract_display_header_value">
                                {this.state.demurrage_rate_per_day
                                  ? this.toLocaleString(
                                      this.state.demurrage_rate_per_day
                                    )
                                  : "-"}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card p-0 border mb-3">
                      <div className="card-header section_header">
                        <h5>Terms</h5>
                      </div>
                      <div className="card-body">
                        <div className="row col-lg-12 p-0">
                          <div className="col-lg-12 mb-3">
                            <label className="contract_display_header_label">
                              Cancellation Fee
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{ textTransform: "upperCase" }}
                            >
                              {this.state.cancellation_fee
                                ? this.state.cancellation_fee
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label className="contract_display_header_label">
                              Payment Terms
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{
                                textTransform: "upperCase",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {this.state.payment_terms
                                ? this.state.payment_terms
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label className="contract_display_header_label">
                              Other T&C
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{
                                textTransform: "upperCase",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {this.state.other_t_and_c
                                ? this.state.other_t_and_c
                                : "-"}
                            </div>
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label className="contract_display_header_label">
                              Remarks
                            </label>
                            <div
                              className="contract_display_header_value"
                              style={{
                                textTransform: "upperCase",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {this.state.remarks ? this.state.remarks : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row bottom_buttons_section">
                <button
                  type="button"
                  className="btn previous_button"
                  name="submit"
                  onClick={
                    this.state.activeStep === 0
                      ? () => (window.location.href = "/fixture-notes")
                      : this.previousStepHandler
                  }
                >
                  {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
                </button>
                <button
                  type="button"
                  className="btn next_button"
                  name="Back"
                  onClick={
                    this.state.activeStep === 3
                      ? this.submitHandler
                      : this.nextStepHandler
                  }
                >
                  {this.state.activeStep === 3 ? "SUBMIT" : "NEXT STEP"}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
