import React, { Component } from 'react';
import config from '../../config/config';
import SideBar from './SideBar';

import pageNotFound from '../../images/pagenotfound.jpg';
export default class PageNotFound extends Component {

 componentDidMount() {
   document.title = config.documentTitle +"Page Not Found";
 }

 render() {
   return(
     <div id='wraper'>
       <SideBar />
       <div className='content'>
        <div className='card' >
          <div className='row'>
            <div className='col card'>
              <img src={pageNotFound} alt= "" id="page-not-found-image" style={{height: 400, width:1000, display: 'block', margin: 'auto', position: 'relative' }} />
              <br />
                <center><a href="/"> Return Home</a></center>
            </div>
          </div>
        </div>
       </div>        
     </div>
   )
 }
}