import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import config from "../../config/config";
const InvoiceLink = ({ data, fileName ,demurrage_name,costing_name }) => {
  return (
    <div className="App" style={{ marginTop: 10 }}>
      <PDFDownloadLink
        document={<Invoice invoice={data} demurrage_name={demurrage_name} costing_name={costing_name} />}
        fileName={`${fileName}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading.."
          ) : (
            <i
              className="fa fa-print"
              style={{ fontSize: 20, color: config.themeColor }}
              aria-hidden="true"
            />
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default InvoiceLink;
