import React, { Component } from "react";

import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import api from "../../api/api";
import {
  TextField,
  Snackbar,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import FileUpload from "../common/FileUpload";
import { Alert } from "@material-ui/lab";
import { dateFormateToDB } from "../common/common";
import Loader from "../common/Loader";
export default class BusinessNoVesselReportAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      business_no_id: "",
      management_remarks: "",
      business_no_vessel_com_bl_data: [
        {
          bl_no: "",
          bl_date: null,
        },
      ],
      business_no_vessel_com_coo_data: [
        {
          coo_no: "",
          coo_date: null,
        },
      ],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Vessel_completion",
        },
      ],

      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("329")  || features.includes("97") )) {
      window.location.href = "/dashboard";
    }
    this.setState({ isLoading: false });
  }

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const business_no_id = this.props.businessNo;

    let {
      management_remarks,
      business_no_vessel_com_bl_data,
      business_no_vessel_com_coo_data,
      files,
    } = this.state;
    for (var i of business_no_vessel_com_bl_data) {
      if (i.bl_no !== "" && i.bl_no) {
        i.bl_no = i.bl_no.toUpperCase();
      }
    }
    for (var j of business_no_vessel_com_coo_data) {
      if (j.coo_no !== "" && j.coo_no) {
        j.coo_no = j.coo_no.toUpperCase();
      }
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = files.filter((e) => e.file_content !== "");
    if (this.props.businessNo) {
      api
        .add_business_no_vessel_completion(
          loginUserID,
          idToken,
          business_no_id,
          management_remarks,
          business_no_vessel_com_bl_data,
          business_no_vessel_com_coo_data,
          content_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onAddResponse(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-12 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Vessel Completion Report
              </h6>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card p-0">
              <div className="card-body">
                <div className="p-3 border_dashed  rounded-lg">
                  {this.state.business_no_vessel_com_bl_data.map((e, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-lg-11 p-0">
                        <div className="row">
                          <div className="col-lg-6 pl-0">
                            <label className="form_label mb-0">BL No</label>
                            <TextField
                              name="bl_no"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={e.bl_no}
                              placeholder="BL No"
                              fullWidth
                              //error={e.name_error}
                              onChange={(e) => {
                                let row =
                                  this.state.business_no_vessel_com_bl_data;
                                row[idx].bl_no = e.target.value;
                                this.setState({
                                  business_no_vessel_com_bl_data: row,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6 pr-0">
                            <label className="form_label mb-0">BL Date</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                disableToolbar
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={e.bl_date}
                                onChange={(date) => {
                                  let row =
                                    this.state.business_no_vessel_com_bl_data;
                                  row[idx].bl_date = dateFormateToDB(date);
                                  this.setState({
                                    business_no_vessel_com_bl_data: row,
                                  });
                                }}
                                placeholder="BL Date"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      </div>
                      {this.state.business_no_vessel_com_bl_data.length > 1 && (
                        <div className="col-lg-1 d-flex justify-content-center">
                          <div className="text-align-center m-auto">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={() => {
                                const row =
                                  this.state.business_no_vessel_com_bl_data;
                                row.splice(idx, 1);
                                this.setState({
                                  business_no_vessel_com_bl_data: row,
                                });
                              }}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="row mt-3 mb-3">
                    <button
                      type="button"
                      style={{ color: config.themeColor, width: 110 }}
                      className="header_button header_button_text addrow_button_adjustment"
                      onClick={() => {
                        const item = { bl_no: "", bl_date: null };
                        this.setState({
                          business_no_vessel_com_bl_data: [
                            ...this.state.business_no_vessel_com_bl_data,
                            item,
                          ],
                        });
                      }}
                    >
                      Add Row
                    </button>
                  </div>
                  <hr />
                  {this.state.business_no_vessel_com_coo_data.map((e, idx) => (
                    <div className="row" key={idx}>
                      <div className="col-lg-11 p-0">
                        <div className="row">
                          <div className="col-lg-6 pl-0">
                            <label className="form_label mb-0">COO No</label>
                            <TextField
                              name="coo_no"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={e.coo_no}
                              fullWidth
                              placeholder="COO No"
                              onChange={(e) => {
                                let row =
                                  this.state.business_no_vessel_com_coo_data;
                                row[idx].coo_no = e.target.value;
                                this.setState({
                                  business_no_vessel_com_coo_data: row,
                                });
                              }}
                            />
                          </div>
                          <div className="col-lg-6 pr-0">
                            <label className="form_label mb-0">COO Date</label>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <DatePicker
                                disableToolbar
                                autoOk={true}
                                inputVariant="outlined"
                                variant="inline"
                                margin="dense"
                                fullWidth
                                format="dd/MM/yyyy"
                                value={e.coo_date}
                                onChange={(date) => {
                                  let row =
                                    this.state.business_no_vessel_com_coo_data;
                                  row[idx].coo_date = dateFormateToDB(date);
                                  this.setState({
                                    business_no_vessel_com_coo_data: row,
                                  });
                                }}
                                placeholder="COO Date"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton>
                                        <EventIcon />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        </div>
                      </div>
                      {this.state.business_no_vessel_com_coo_data.length >
                        1 && (
                        <div className="col-lg-1 d-flex justify-content-center">
                          <div className="text-align-center m-auto">
                            <i
                              className="fa fa-trash-o m-auto"
                              onClick={() => {
                                const row =
                                  this.state.business_no_vessel_com_coo_data;
                                row.splice(idx, 1);
                                this.setState({
                                  business_no_vessel_com_coo_data: row,
                                });
                              }}
                              style={{
                                fontSize: 24,
                                color: config.themeColor,
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div className="row mt-3">
                    <button
                      type="button"
                      style={{ color: config.themeColor, width: 110 }}
                      className="header_button header_button_text addrow_button_adjustment"
                      onClick={() => {
                        const item = { coo_no: "", coo_date: null };
                        this.setState({
                          business_no_vessel_com_coo_data: [
                            ...this.state.business_no_vessel_com_coo_data,
                            item,
                          ],
                        });
                      }}
                    >
                      Add Row
                    </button>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-12 pl-0">
                      <label className="form_label mb-0">
                        Management Remarks
                      </label>
                      <TextField
                        name="management_remarks"
                        margin="dense"
                        variant="outlined"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={this.state.management_remarks}
                        placeholder="Management Remarks"
                        fullWidth
                        multiline
                        rows={4}
                        onChange={(e) => {
                          this.setState({
                            management_remarks: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  {(this.state.features.includes("364")  ||
                    this.state.features.includes("97") ) && (
                    <FileUpload
                      files={this.state.files}
                      feature_name="Vessel_completion"
                      callbackFn={(data) => this.setState({ files: data })}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
