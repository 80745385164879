import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

import FileUpload from "../../common/FileUpload";
import { TextField, Snackbar, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { Alert } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default class OtherExpenseAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      barge_id: "",
      other_expenses_data: [
        {
          vendor_id: "",
          item_description: "",
          price: "",
          remarks: "",
          billing_currency:
            config.company === "SRPL" ||
            config.company === "AVS" ||
            config.company === "PRPL"
              ? "USD"
              : "IDR",
          currency_xchg_rate: "1",
        },
      ],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barge_pur_fin_other_expenses",
        },
      ],
      bargeList: [],
      coal_vendor_drop_value: [],
      barges: [],
      activeStep: 0,
      isLoading: true,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    let features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.indexOf("338") >= 0 || features.indexOf("97") >= 0)) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api.getVendors(loginUserID, idToken, "Active").then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              const coal_vendor_drop_value = [];
              for (var i of res.vendors) {
                coal_vendor_drop_value.push({
                  vendor_name: i.vendor_name,
                  value: i.id,
                });
              }
              this.setState({
                coal_vendor_drop_value: coal_vendor_drop_value,
                vendors: res.vendors,
                //isLoading: false
              });
            }
          }
        });
      }
    });
    api
      .get_barges_list_for_other_expenses(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState({
                barges: res.barges,
                isLoading: false,
              });
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  submitHandler = () => {
    let { other_expenses_data } = this.state;
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = row.filter((e) => e.file_content !== "");
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const barge_id = this.state.bargeList.toString();
    for (var i of other_expenses_data) {
      if (i.vendor_id === "") {
        i.vendor_name_error = true;
        this.setState({
          other_expenses_data: other_expenses_data,
        });
        return;
      }
      i.item_description = i.item_description
        ? i.item_description.toUpperCase()
        : null;
      i.remarks = i.remarks ? i.remarks.toUpperCase() : null;
    }

    api
      .add_barge_pur_fin_other_expenses(
        loginUserID,
        idToken,
        barge_id,
        other_expenses_data,
        content_files
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => {
                  this.props.onAddResponse(true);
                }
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  };

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length <= 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please select atleast one.",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-12 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Other Expenses
              </h6>
            </div>
          </div>

          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                />
              </span>
            </div>
            {this.state.barges.length > 0 && this.state.activeStep === 0 && (
              <div className="row">
                <div className="table border-0">
                  <table className="table table-borderless border-0">
                    <thead className="border-0">
                      <tr>
                        <th
                          nowrap="true"
                          className="p-0 text-center pt-1 border-0"
                        >
                          <CheckBox
                            color="primary"
                            checked={
                              this.state.barges.length !== 0 &&
                              this.state.bargeList.length ===
                                this.state.barges.length
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                let checked = [];
                                for (var i of this.state.barges) {
                                  checked.push(i.id);
                                }
                                this.setState({
                                  bargeList: checked,
                                });
                              } else {
                                this.setState({
                                  bargeList: [],
                                });
                              }
                            }}
                          />
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Barge ID
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Barge Nomination
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Jetty
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Loading Date
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0"
                          nowrap="true"
                        >
                          Business No
                        </th>
                        <th
                          style={{ fontSize: 16 }}
                          className="table_header_barge_label text-uppercase border-0 text-right"
                          nowrap="true"
                        >
                          Quantity
                        </th>
                      </tr>
                    </thead>
                    {this.state.barges.map((p, index) => (
                      <tbody key={index}>
                        <tr className="rounded">
                          <td className="p-0 text-center">
                            <CheckBox
                              value={p.id}
                              color="primary"
                              checked={this.state.bargeList.indexOf(p.id) >= 0}
                              //disabled={p.barge_pur_fin_id !== null}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.setState({
                                    bargeList: [
                                      ...this.state.bargeList,
                                      e.target.value,
                                    ],
                                  });
                                } else {
                                  let data = this.state.bargeList;
                                  let idx = data.indexOf(e.target.value);
                                  data.splice(idx, 1);
                                  this.setState({
                                    bargeList: data,
                                  });
                                }
                              }}
                            />
                          </td>
                          <td>{pad(p.id)}</td>
                          <td nowrap="true">{p.barge_nomination}</td>
                          <td nowrap="true">{p.loading_jetty}</td>
                          <td nowrap="true">
                            {localDateFormate(p.loading_date)}
                          </td>
                          <td nowrap="true">{p.business_no}</td>
                          <td nowrap="true" className="text-right">
                            {toLocaleString(p.barge_quantity_in_mt)}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            )}

            {this.state.activeStep === 1 && (
              <div className="card p-0">
                <div className="card-body">
                  <div className="p-3 border_dashed  rounded-lg">
                    {this.state.other_expenses_data.map((e, idx) => (
                      <div className="row mb-3" key={idx}>
                        <div className="col-lg-11 p-0">
                          <div className="row">
                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-2">Vendor <span className="text-danger">*</span> </label>
                              <Autocomplete
                                options={this.state.coal_vendor_drop_value}
                                getOptionLabel={(option) => option.vendor_name}
                                size="small"
                                onChange={(event, value) => {
                                  let row = this.state.other_expenses_data;
                                  if (value) {
                                    row[idx].vendor_id = value.value;
                                    row[idx].vendor_name = value.vendor_name;
                                  } else {
                                    row[idx].vendor_id = null;
                                    row[idx].vendor_name = null;
                                  }
                                  row[idx].vendor_name_error = false;
                                  this.setState({
                                    other_expenses_data: row,
                                  });
                                }}
                                name="vendorName"
                                value={e}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Search Vendor"
                                    variant="outlined"
                                    error={e.vendor_name_error}
                                    fullWidth
                                  />
                                )}
                              />
                            </div>
                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-0">
                                Item Description
                              </label>
                              <TextField
                                name="item_description"
                                margin="dense"
                                variant="outlined"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.item_description}
                                placeholder="Item Description"
                                fullWidth
                                onChange={(e) => {
                                  let row = this.state.other_expenses_data;
                                  row[idx].item_description = e.target.value;
                                  this.setState({
                                    other_expenses_data: row,
                                  });
                                }}
                              />
                            </div>
                            <div className="col-lg-6 pl-0">
                              <label className="form_label mb-0">Price</label>
                              <TextField
                                name="price"
                                margin="dense"
                                variant="outlined"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.price}
                                placeholder="price"
                                fullWidth
                                onChange={(e) => {
                                  const pr = /^\d*(\.\d{0,4})?$/;
                                  if (
                                    e.target.value === "" ||
                                    pr.test(e.target.value)
                                  ) {
                                    let row = this.state.other_expenses_data;
                                    row[idx].price = e.target.value;
                                    this.setState({
                                      other_expenses_data: row,
                                    });
                                  }
                                }}
                              />
                            </div>
                            <div className="col-lg-12 pl-0">
                              <label className="form_label mb-0">Remarks</label>
                              <TextField
                                name="remarks"
                                margin="dense"
                                variant="outlined"
                                inputProps={{
                                  style: { textTransform: "uppercase" },
                                }}
                                value={e.remarks}
                                placeholder="Remarks"
                                fullWidth
                                multiline
                                rows={4}
                                onChange={(e) => {
                                  let row = this.state.other_expenses_data;
                                  row[idx].remarks = e.target.value;
                                  this.setState({
                                    other_expenses_data: row,
                                  });
                                }}
                              />
                            </div>
                            {/* File upload */}
                            <div className="card p-0">
                              <FileUpload
                                files={this.state.files}
                                feature_name="Barge_pur_fin_other_expenses"
                                callbackFn={(data, mandatoryFiles) => {
                                  this.setState({
                                    files: data,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.other_expenses_data.length > 1 && (
                          <div className="col-lg-1 d-flex justify-content-center">
                            <div className="text-align-center m-auto">
                              <i
                                className="fa fa-trash-o m-auto"
                                onClick={() => {
                                  const row = this.state.other_expenses_data;
                                  row.splice(idx, 1);
                                  this.setState({
                                    other_expenses_data: row,
                                  });
                                }}
                                style={{
                                  fontSize: 24,
                                  color: config.themeColor,
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </div>
                        )}

                        <hr />
                      </div>
                    ))}
                    <div className="row mt-3 mb-3">
                      <button
                        type="button"
                        style={{ color: config.themeColor, width: 110 }}
                        className="header_button header_button_text addrow_button_adjustment"
                        onClick={() => {
                          const item = {
                            item_description: "",
                            price: "",
                            remarks: "",
                          };
                          this.setState({
                            other_expenses_data: [
                              ...this.state.other_expenses_data,
                              item,
                            ],
                          });
                        }}
                      >
                        Add Row
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <footer className="row bottom_buttons_section">
            {/* <button type='button' className='btn previous_button btn-light' name='submit' onClick={() => {
              this.props.returnCallbackDrawerClose(false);
            }}>Close</button>
            <button type='button' className='drawer_text drawer_btn_success' name='Back' onClick={this.submitHandler}>Submit</button> */}
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.returnCallbackDrawerClose(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </footer>
        </div>
      );
    }
  }
}
