import React, { Component } from 'react';

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from '../../../api/api';
import Loader from '../../common/Loader';
import { toLocaleString } from "../../common/common";

import PnbpChargesAdd from './PnbpChargesAdd';
import PnbpChargesUpdate from './PnbpChargesUpdate';
import PnbpChargesView from './PnbpChargesView';

class PnbpChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      isLoading: true,
      openDrawer: false,
      mv_pnbp_jasa_pbm: [],
      features: [],
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("275") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }

    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const businessNoID = this.props.businessNoID;

    api.get_mv_pnbp_jasa_pbm_by_bno(loginUserID, idToken, businessNoID).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.mv_pnbp_jasa_pbm) {
              this.setState({ mv_pnbp_jasa_pbm: res.mv_pnbp_jasa_pbm, isLoading: false });
            }
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }
        })
      }
    })

  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          PnbpChargesAdd: false,
          PnbpChargesView: false,
          PnbpChargesUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        PnbpChargesAdd: false,
        PnbpChargesView:
          value.screen === "MvDoc" && value.message === "alreadyDataAvailable"
            ? true
            : false,
        PnbpChargesUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        PnbpChargesAdd: false,
        PnbpChargesView: false,
        PnbpChargesUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          PnbpChargesAdd: false,
          PnbpChargesView:
            value.code === "200" && value.screen === "MvDoc" ? true : false,
          PnbpChargesUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        PnbpChargesUpdate: false,
        PnbpChargesAdd: false,
        PnbpChargesView: childData === "MvDoc" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        PnbpChargesUpdate: childData === "MvDoc" ? true : false,
        PnbpChargesAdd: false,
        PnbpChargesView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };


  render() {
    let drawerComponent;
    if (this.state.PnbpChargesAdd) {
      drawerComponent = (
        <PnbpChargesAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.PnbpChargesView && this.props.businessNoID) {
      drawerComponent = (
        <PnbpChargesView
          costing_id={this.state.cositng_id}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              PnbpChargesUpdate: true,
              PnbpChargesView: false,
              PnbpChargesAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.PnbpChargesUpdate && this.props.businessNoID) {
      drawerComponent = (
        <PnbpChargesUpdate
          costing_id={this.state.cositng_id}
          businessNoID={this.props.businessNoID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />
    }
    else {

      return (
        <>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                PNBP Charges
              </h4>
              {(this.state.features.includes("274") ||
                this.state.features.includes("97")) && (
                  <i
                    className="fa fa-plus-circle ml-2 mt-3"
                    aria-hidden="true"
                    style={{
                      fontSize: 22,
                      color: config.themeColor,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.setState({
                        openDrawer: true,
                        PnbpChargesAdd: true,
                        PnbpChargesView: false,
                        PnbpChargesUpdate: false,
                      });
                    }}
                  />
                )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card">
            <div className='card-body p-0'>
              <div className="col-lg-12 mb-2 p-0">
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Costing ID </th>
                          <th nowrap="true">Vendor Name</th>
                          <th nowrap="true" className='text-right'>Quantity in MT</th>
                          <th nowrap="true" className='text-right'> PNBP</th>
                          <th nowrap="true" className='text-right'>JASA PBM</th>
                          <th nowrap="true">Action</th>
                        </tr>
                      </thead>
                      {this.state.mv_pnbp_jasa_pbm.length > 0 ? (
                        <tbody>
                          {this.state.mv_pnbp_jasa_pbm.map((idr, indx) => (
                            <tr key={indx}>
                              <td className="allocation_table_value">
                                {(this.state.features.includes("628") ||
                                  this.state.features.includes("97")) && (
                                    <i
                                      aria-hidden="true"
                                      style={{
                                        fontStyle: "normal",
                                        color: config.themeColor,
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                      }}
                                      onClick={() =>
                                        this.setState({
                                          openDrawer: true,
                                          PnbpChargesAdd: false,
                                          PnbpChargesView: true,
                                          PnbpChargesUpdate: false,
                                          businessNo: idr.business_no_id,
                                          cositng_id: idr.id,
                                        })
                                      }
                                    >
                                      {idr.costing_no}
                                    </i>
                                  )}
                              </td>
                              <td
                                className="allocation_table_value"
                                nowrap="true"
                              >
                                {idr.vendor_name}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {toLocaleString(idr.quantity)}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {`${toLocaleString(idr.pnbp)} ${idr.billing_currency}`}
                              </td>
                              <td
                                className="allocation_table_value text-right"
                                nowrap="true"
                              >
                                {`${toLocaleString(idr.jasa_pbm_value)} ${config.company === "SRPL" || config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}`}
                              </td>
                              <td
                                nowrap="true"
                                className="table_td text-left pl-4"
                              >
                                {(this.state.features.includes("629") ||
                                  this.state.features.includes("97")) && (
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      data-toggle="modal"
                                      title="Edit floating crane charges"
                                      data-placement="bottom"
                                      onClick={() =>
                                        this.setState({
                                          businessNo: idr.business_no_id,
                                          cositng_id: idr.id,
                                          openDrawer: true,
                                          PnbpChargesUpdate: true,
                                        })
                                      }
                                    ></i>
                                  )}
                                {idr.payment_postings_availability === 0 &&
                                  (this.state.features.includes("630") ||
                                    this.state.features.includes("97")) &&
                                  idr.approval_status === "Pending" && (
                                    <i
                                      className="fa fa-trash-o"
                                      style={{
                                        paddingLeft: 10,
                                        fontSize: 14,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => this.deleteCharges(idr)}
                                    />
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="7" className="p-2">
                              No data available
                            </td>
                          </tr>{" "}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>

      );
    }
  }
}

export default PnbpChargesList;