import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import SideBar from "../common/SideBar";
import TextField from "@material-ui/core/TextField";
import Header from "../common/Header";

import "../../App.css";
import config from "../../config/config";
import api from "../../api/api";
import Loader from "../common/Loader";

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      userName: "",
      password: "",
      email: "",
      status: "",
      userNameError: false,
      passwordError: false,
      emailError: false,
      errorMessage: "",
      successMessage: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var userID = decodeURIComponent(
      window.atob(this.props.match.params.userID)
    );
    api.getUser(userID, loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res) {
            //this.Cookie.setIdTokenCookie(res.idtoken);TokenCookie(res.idtoken);
            this.setState({
              userId: userID,
              userName: res.user[0].user_name,
              email: res.user[0].email,
              status: res.user[0].status,
              isLoading: false,
            });
          }
        });
      } else if (response.status === 607) {
        response.json().then((res) => {
          this.setState({
            errorMessage: res.message,
            successMessage: "",
          });
        });
        setTimeout(() => {
          window.location.href = "/logout";
        }, 2000);
      }
    });
    document.title = config.documentTitle + "Update User";
  }

  handleText = (e) => {
    if (e.target.name === "userName") {
      this.setState({
        userNameError: false,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "email") {
      this.setState({
        emailError: false,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "password") {
      this.setState({
        passwordError: false,
        [e.target.name]: e.target.value,
      });
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    var { userName, password, email } = this.state;
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    if (userName === "" && email === "" && password === "") {
      this.setState({
        userNameError: true,
        emailError: true,
        passwordError: true,
      });
    }
    if (userName === "") {
      this.setState({
        userNameError: true,
      });
      return;
    } else {
      this.setState({
        userNameError: false,
      });
    }
    if (email === "") {
      this.setState({
        emailError: true,
      });
      return;
    } else {
      this.setState({
        emailError: false,
      });
    }
    if (password === "") {
      this.setState({
        passwordError: false,
      });
      return;
    } else {
      this.setState({
        passwordError: true,
      });
    }
    var userID = decodeURIComponent(
      window.atob(this.props.match.params.userID)
    );
    api
      .updateUser(userID, loginUserID, userName, email, idToken)
      .then((Response) => {
        if (Response.status >= 200 && Response.status < 300) {
          Response.json().then((res) => {
            if (res) {
              //this.Cookie.setIdTokenCookie(res.idtoken);TokenCookie(res.idtoken);
              this.setState({
                successMessage: res.message,
                errorMessage: "",
              });
              setTimeout(() => {
                window.location.href = "/user-list";
              }, 2000);
            }
            if (res.id) {
              setTimeout(() => {
                window.location.href = "/view-user/" + btoa(res.id);
              });
            }
          });
        } else if (Response.status === 601) {
          Response.json().then((res) => {
            this.setState({
              errorMessage: res.message,
              successMessage: "",
            });
          });
        } else if (Response.status === 607) {
          Response.json().then((res) => {
            this.setState({
              errorMessage: res.message,
              successMessage: "",
            });
          });
          setTimeout(() => {
            window.location.href = "/logout";
          }, 2000);
        }
      });
  };

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                Update User
              </h4>
            </div>
          </div>
          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <form className="container p-3">
              {this.state.errorMessage && (
                <div className="row">
                  <div className="col-25"></div>
                  <div className="col-75">
                    <p className="error-class">{this.state.errorMessage}</p>
                  </div>
                </div>
              )}
              {this.state.successMessage && (
                <div className="row">
                  <div className="col-25"></div>
                  <div className="col-75">
                    <p className="success-class">{this.state.successMessage}</p>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-25">
                  <label className="label-text">
                    user Name <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-75">
                  <TextField
                    name="userName"
                    placeholder="User Name"
                    margin="dense"
                    variant="outlined"
                    value={this.state.userName}
                    error={this.state.userNameError}
                    onChange={this.handleText}
                    fullWidth
                  ></TextField>
                </div>
              </div>

              <div className="row">
                <div className="col-25">
                  <label className="label-text">
                    Email <span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-75">
                  <TextField
                    name="email"
                    placeholder="Email"
                    margin="dense"
                    variant="outlined"
                    value={this.state.email}
                    error={this.state.emailError}
                    onChange={this.handleText}
                    fullWidth
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label className="label-text">
                    Password<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                <div className="col-75">
                  <TextField
                    name="password"
                    placeholder="Password"
                    margin="dense"
                    variant="outlined"
                    value={this.state.password}
                    error={this.state.passwordError}
                    onChange={this.handleText}
                    fullWidth
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <button
                  type="button"
                  className="btn btn-info btn-responsive mr-3"
                  name="submit"
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
                <a
                  href={"/user-list"}
                  className="btn btn-secondary btn-responsive"
                  role="button"
                >
                  Back
                </a>
                {/* <button type='button' className='btn btn-secondary btn-responsive' name='Back' onClick={()=>{window.location.href='/user-list'}}>Back</button> */}
              </div>
            </form>
          )}
        </div>
      </div>
    );
  }
}
