import React, { Component } from "react";

import CookieHandler from "./common/CookieHandler";
//import CookieHandler from '../Common/CookieHandler';
import config from "../config/config";
import api from "../api/api";
// import api from '../../api/api';
import { TextField, Snackbar } from "@material-ui/core";

import { Alert } from "@material-ui/lab";
import Loader from "./common/Loader";
export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeStep: 0,
      files: this.props.documents ? this.props.documents : [],
      isLoading: true,
    };
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    this.setState({ isLoading: false });
  }

  addMoreRowsHandler = () => {
    const item = { name: "", phone_no: "", address: "" };
    this.setState({
      notify_party_data: [...this.state.notify_party_data, item],
    });
  };

  removeRowHandler = (idx) => () => {
    const row = this.state.notify_party_data;
    row.splice(idx, 1);
    this.setState({
      notify_party_data: row,
    });
  };

  submitHandler = () => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let { files } = this.state;

    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;

    var existing_file_ids = [];
    const existing_files = files.filter((e) => e.id);
    for (var i of existing_files) {
      existing_file_ids.push(i.id);
    }
    const new_files = files.filter((e) => !e.id);
    var row = files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_description === "") {
          missing_fields++;
          obj.file_description_error = true;
        } else {
          obj.file_description = obj.file_description.toUpperCase();
        }
        if (obj.file_content === "") {
          files_missing++;
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const feature_name = this.props.feature_name;
    const feature_seq_id = this.props.feature_seq_id;
    if (feature_seq_id) {
      api
        .update_files_data(
          loginUserID,
          idToken,
          feature_name,
          feature_seq_id,
          existing_file_ids,
          new_files
        )
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                this.setState(
                  {
                    snackBarSuccessOpen: true,
                    successMsg: res.message,
                  },
                  () => {
                    this.props.onAddResponse(true);
                  }
                );
              } else if (res.code === "601") {
                this.setState({
                  snackBarErrorOpen: true,
                  errorMsg: res.message,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              }
            });
          }
        });
    }
  };

  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    let file_data = "";
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row = this.state.files ? this.state.files : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;

      this.setState({
        files: row,
      });
    };
  };

  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: this.props.feature_name,
    };

    this.setState({
      files: [...this.state.files, data],
    });
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="row">
            <div className="col-lg-12 p-0 border-bottom">
              <h6 style={{ padding: "15px", marginBottom: 0 }}>
                Add Documents
              </h6>
            </div>
          </div>
          <div className="drawer-section">
            <div className="card">
              <div className="card-body border_dashed">
                {this.state.files.map((e, index) => (
                  <div className="" key={index}>
                    {e.file_path ? (
                      <div className="row mb-3" key={index}>
                        <div className="col-lg-10 p-0">
                          <a
                            href={config.apiDomain + "/" + e.file_path}
                            role="button"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: config.themeColor }}
                          >
                            {e.file_description}
                          </a>
                        </div>
                        <center className="col-lg-1 d-flex justify-content-center">
                          <i
                            className="fa fa-trash-o m-auto"
                            onClick={(e) => {
                              const row = this.state.files;
                              row.splice(index, 1);
                              this.setState({
                                files: row,
                              });
                            }}
                            style={{
                              fontSize: 24,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                          />
                        </center>
                      </div>
                    ) : (
                      <div className="row mb-3" key={index}>
                        <div className="col-lg-4 p-0">
                          <label className="form_label mb-0">
                            File Description
                          </label>
                          <TextField
                            name="file_description"
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            inputProps={{
                              style: { textTransform: "uppercase" },
                            }}
                            value={e.file_description}
                            error={e.file_description_error}
                            onChange={(e) => {
                              var row = this.state.files;
                              row[index].file_description = e.target.value;
                              row[index].file_description_error = false;
                              this.setState({
                                files: row,
                              });
                            }}
                          />
                        </div>
                        <div className="col-lg-6 pr-0">
                          <label className="form_label mb-2">
                            Select file to upload
                          </label>
                          <input
                            type="file"
                            name="fileToUpload"
                            id="fileToUpload"
                            className="form-control border-0 pl-0"
                            onChange={this.fileUploadHandler(index)}
                          />
                        </div>

                        <center className="col-lg-1 d-flex justify-content-center">
                          <i
                            className="fa fa-trash-o m-auto"
                            onClick={(e) => {
                              const row = this.state.files;
                              row.splice(index, 1);
                              this.setState({
                                files: row,
                              });
                            }}
                            style={{
                              fontSize: 24,
                              color: config.themeColor,
                              cursor: "pointer",
                            }}
                          />
                        </center>
                      </div>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  style={{ color: config.themeColor, width: 110 }}
                  className="header_button header_button_text addrow_button_adjustment"
                  onClick={this.addMoreFileHandler}
                >
                  Add More
                </button>
              </div>
            </div>
          </div>

          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Submit
            </button>
          </footer>
        </div>
      );
    }
  }
}
