
import React, { Component } from 'react';
import SideBar from '../../common/NewSidebar';
import Header from '../../common/Header';
import CookieHandler from '../../common/CookieHandler';
import api from '../../../api/api';
import config from '../../../config/config';
import { Alert } from '@material-ui/lab';
import {
  TextField, Snackbar, Radio, RadioGroup, FormControlLabel,
  MenuItem, IconButton, InputAdornment,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FileUpload from '../../common/FileUpload';
import { dateFormateToDB } from '../../common/common';
import Loader from '../../common/Loader';
const NewRadio = withStyles({
  root: {
    color: '#ccc',
    '&$checked': {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class BargeAgreementNew extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      barge_vendor_id: null,
      agreement_date: null,
      barging_agreement_price_list: [
        {
          area: '', price: '',
          barging_type: 'Lumpsum',
          vat_incl_or_excl: 'Exclusive',
        }
      ],
      vendorDataForMenu: [],
      isLoading: true,
      features: [],
      remarks: '',
      currency: (config.company === "SRPL" || config.company === "AVS"  || config.company === "PRPL") ? "USD" : "IDR",
      vendor_drop_value: { name: '', value: '' },
      currencyData: [],
      files: [
        {
          file_description: '',
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Barging_agreements",
        }
      ],

    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }

    const features = this.Cookie.getCookie("features");
    this.setState({ features: features ? features : [] },
      () => {
        if (!(this.state.features.includes('555') || this.state.features.includes('97'))) {
          window.location.href = '/dashboard';
        }
      });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();

    // for get_Currencies
    await api.getCurrencies(loginUserID, idToken).then((response) => {

      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.idtoken)
            this.Cookie.setIdTokenCookie(res.idtoken);
          if (res.code === '200') {
            var currencies = [<MenuItem value='0' key={-1} disabled>Please Select</MenuItem>];
            res.Currency.forEach(item => {
              currencies.push(<MenuItem value={item.currency} key={[item.id]}>{item.currency}</MenuItem>);
            });
            this.setState({
              currencyData: currencies,
            });
          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })
          } else if (res.code === '607') {
            window.location.href = '/logout';
          }

        });
      }
    });

    var status = 'Active';
    await api.getVendors(loginUserID, idToken, status).then((response) => {

      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            if (res.idtoken)
              this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.vendors) {
              const vendors_menu = [];

              if (res.vendors) {
                res.vendors.filter(e => e.vendor_type === "Barge").forEach(item => {
                  vendors_menu.push({ name: item.vendor_name, value: item.id, key: item.id });
                })

                this.setState({
                  vendorDataForMenu: vendors_menu,
                  vendors: res.vendors,
                  isLoading: false
                })
              }
            }

          }
          else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message
            })
          }
          else if (res.code === '607') {
            window.location.href = '/logout';
          }
        });
      }
    });
    document.title = config.documentTitle + "New Barging Agreement";
  }

  removeProcurementRowsHandler = (idx) => () => {
    const rows = this.state.barging_agreement_price_list
    rows.splice(idx, 1);
    this.setState({
      barging_agreement_price_list: rows
    });
  }

  submitHandler = (e) => {
    this.setState({ submitLoading: true });
    let { agreement_date, barging_agreement_price_list,
      barge_vendor_id, currency, remarks, files } = this.state
    // files validation
    let missing_fields = 0;
    let files_missing = 0;
    var files_data = files;


    if (barge_vendor_id === null || barge_vendor_id === '') {
      this.setState({
        barge_vendor_error: true,
      })
      missing_fields++;
    }

    if (agreement_date === null || agreement_date === '') {
      this.setState({ agreement_date_error: true });
      missing_fields++;
    }

    if (currency === "0" || currency === '') {
      this.setState({ currency_error: true });
      missing_fields++;
    }

    for (var i of barging_agreement_price_list) {
      if (i.area === null || i.area === '') {
        i['area_error'] = true;
        missing_fields++;

      }
      if (i.price === null || i.price === '') {
        i['price_error'] = true;
        missing_fields++;
      }
    }



    for (var obj of files_data) {
      if (obj.file_type !== '' || obj.file_content !== '') {
        if (obj.file_content !== '') {
          if (obj.file_description === '') {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== '') {
          if (obj.file_content === '') {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data
    });

    if (missing_fields > 0) {
      this.setState({
        barging_agreement_price_list: barging_agreement_price_list,
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
        submitLoading: false,
      })
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
        submitLoading: false
      });
      return;
    }
    const content_files = files_data.filter(e => e.file_content !== '');


    let request = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      currency: currency,
      barge_vendor_id: barge_vendor_id,
      agreement_date: agreement_date,
      remarks: remarks ? remarks.toUpperCase() : null,
      barging_agreement_price_list: barging_agreement_price_list,
      files: content_files
    }

    api.add_barging_agreement(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === '200') {
            this.setState({
              snackBarSuccessOpen: true,
              successMsg: res.message
            }, () => {
              window.location.href = '/fixture-notes';
            })

          } else if (res.code === '601') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.message,
              submitLoading: false,
            })
          } else if (res.code === '607') {
            window.location.href = '/logout';
          } else if (res.code === '624') {
            this.setState({
              snackBarErrorOpen: true,
              errorMsg: res.columns.toString(),
              submitLoading: false
            })
          }
        })
      }
    })
  }

  render() {
    return (
      <div id='wraper'>
        <SideBar />
        <div className='content'>
          <Header />
          <div className='content-header'>
            <div className='col-sm text-left pl-0'>
              <h4 style={{ padding: '10px 20px', margin: '0px' }}>New Barging Agreement</h4>
            </div>
          </div>
          {this.state.isLoading &&
            <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          {!this.state.isLoading &&
            <section className='container p-3'>
              <div className='card' style={{ overflowY: 'scroll' }}>
                <div className="section_block" style={{ width: '85%' }}>
                  <div className='row mb-3'>

                    <div className='col-lg-4 pl-0'>
                      <label className='form_label mb-0'>Barge Vendor <span style={{ color: 'red' }}>*</span></label>
                      <Autocomplete
                        options={this.state.vendorDataForMenu}
                        getOptionLabel={option => option.name}
                        onChange={(event, value) => {
                          if (value) {
                            this.setState({
                              barge_vendor_id: value.value,
                              vendor_name: value.name,
                              barge_vendor_error: false,
                              vendor_drop_value: { name: value.name, value: value.value }
                            });
                          }
                          else {
                            this.setState({
                              barge_vendor_id: "",
                              vendor_drop_value: { name: "", value: "" }
                            })
                          }
                        }}
                        InputProps={{
                          style: {
                            fontFamily: 'Poppins',
                            fontSize: '14px'
                          }
                        }}
                        size="small"
                        name='purchaseContract'
                        value={this.state.vendor_drop_value}
                        renderOption={option => (
                          <React.Fragment>
                            <div className='col-lg-12 p-0 m-0'>
                              <label className='form_label mb-0'>{option.name}</label>
                            </div>
                          </React.Fragment>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Vendors"
                            variant="outlined"
                            fullWidth
                            error={this.state.barge_vendor_error}
                            style={{ top: 8 }}
                          />
                        )}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <label className='form_label mb-0'>Agreement Date <span style={{ color: 'red' }}>*</span></label>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk={true}
                          inputVariant="outlined"
                          variant="inline"
                          margin="dense"
                          fullWidth
                          format="dd/MM/yyyy"
                          value={this.state.agreement_date}
                          onChange={(date) => {
                            this.setState({
                              agreement_date: dateFormateToDB(date),
                              agreement_date_error: false
                            });
                          }}
                          error={this.state.agreement_date_error}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                            className: 'pl-0'
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className='col-lg-4'>
                      <label className='form_label mb-0'>Currency <span style={{ color: 'red' }}>*</span></label>
                      <TextField
                        name='currency'
                        variant='outlined'
                        margin='dense'
                        select
                        value={this.state.currency}
                        onChange={(e) => {
                          this.setState({
                            currency: e.target.value,
                            currency_error: false,
                          });
                        }}
                        error={this.state.currency_error}
                        fullWidth
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                      >
                        {this.state.currencyData}
                      </TextField>
                    </div>
                  </div>
                  {this.state.barging_agreement_price_list.length > 0 &&
                    <div className="table-responsive">

                      <table className="table table-sm">
                        <thead className="table-header">
                          <tr>
                            <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 200 }}>Area <span className='text-danger'>*</span></th>
                            <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 150 }}>Price <span className='text-danger'>*</span></th>
                            <th className='text-center border text-uppercase' nowrap="true" style={{ minWidth: 240 }}>Barging Type</th>
                            <th className='text-center border  text-uppercase ' nowrap="true" style={{ minWidth: 240 }}>VAT Applicability (PPN)</th>
                            {this.state.barging_agreement_price_list.length > 1 &&
                              <th style={{ minWidth: 80 }}></th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.barging_agreement_price_list?.map((e, idx) => (
                            <tr key={e.price_list_id}>
                              <td nowrap="true" className='text-center text-uppercase border'>
                                <TextField
                                  name='area'
                                  value={e.area}
                                  margin='dense'
                                  variant='outlined'
                                  fullWidth
                                  error={e.area_error}
                                  placeholder='Area'
                                  onChange={(e) => {
                                    let row = this.state.barging_agreement_price_list;
                                    row[idx].area = e.target.value;
                                    row[idx].area_error = false;
                                    this.setState({
                                      barging_agreement_price_list: row,
                                    })
                                  }}
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                      textTransform: 'uppercase'
                                    },
                                  }}
                                >
                                </TextField>
                              </td>
                              <td nowrap="true" className='text-right border'>
                                <TextField
                                  name='price'
                                  value={e.price}
                                  margin='dense'
                                  variant='outlined'
                                  fullWidth
                                  type='number'
                                  error={e.price_error}
                                  placeholder='Enter Value'
                                  onChange={(e) => {
                                    let row = this.state.barging_agreement_price_list;
                                    row[idx].price = e.target.value;
                                    row[idx].price_error = false;
                                    this.setState({
                                      barging_agreement_price_list: row,
                                    })
                                  }}

                                >
                                </TextField>
                              </td>
                              <td nowrap="true" className='text-center border'>
                                <RadioGroup aria-label="barging_type" name="barging_type" onChange={(e) => {
                                  let row = this.state.barging_agreement_price_list;
                                  row[idx].barging_type = e.target.value
                                  this.setState({
                                    barging_agreement_price_list: row
                                  });
                                }} style={{ flexDirection: 'row' }}>
                                  <FormControlLabel value="Lumpsum" control={<NewRadio checked={e.barging_type === "Lumpsum"} />} label="Lumpsum" />
                                  <FormControlLabel value="Per MT" control={<NewRadio checked={e.barging_type === "Per MT"} />} label="Per MT" />
                                </RadioGroup>
                              </td>
                              <td nowrap="true" className='text-center border'>
                                <RadioGroup aria-label="vat_incl_or_excl" name="vat_incl_or_excl" onChange={(e) => {
                                  let row = this.state.barging_agreement_price_list;
                                  row[idx].vat_incl_or_excl = e.target.value
                                  this.setState({
                                    barging_agreement_price_list: row
                                  });
                                }} style={{ flexDirection: 'row' }}>
                                  <FormControlLabel value="Exclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Exclusive"} />} label="Exclusive" />
                                  <FormControlLabel value="Inclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Inclusive"} />} label="Inclusive" />
                                </RadioGroup>
                              </td>
                              {this.state.barging_agreement_price_list.length > 1 &&

                                <td className='text-center border'>
                                  <i className="fa fa-trash-o m-auto" onClick={this.removeProcurementRowsHandler(idx)} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                                </td>
                              }
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    </div>
                  }
                  {/* {this.state.barging_agreement_price_list.map((e, idx) => (
                    <div className='row mb-3' key={e.purchase_contract_id}>

                      <div className='col-lg-11 p-0' >
                        <div className='card-body border_dashed'>

                          <div className='row'>
                            <div className='col-lg-4 pr-0'>
                              <label className='form_label mb-0'>Area <span style={{ color: 'red' }}>*</span></label>
                              <TextField
                                name='area'
                                value={e.area}
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                error={e.area_error}
                                placeholder='Area'
                                onChange={(e) => {
                                  let row = this.state.barging_agreement_price_list;
                                  row[idx].area = e.target.value;
                                  row[idx].area_error = false;
                                  this.setState({
                                    barging_agreement_price_list: row,
                                  })
                                }}
                                inputProps={{
                                  style: {
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    textTransform: 'uppercase'
                                  },
                                }}
                              >
                              </TextField>
                            </div>

                            <div className='col-lg-4 pr-0'>
                              <label className='form_label mb-0'>Price <span style={{ color: 'red' }}>*</span></label>
                              <TextField
                                name='price'
                                value={e.price}
                                margin='dense'
                                variant='outlined'
                                fullWidth
                                type='number'
                                error={e.price_error}
                                placeholder='Enter Value'
                                onChange={(e) => {
                                  let row = this.state.barging_agreement_price_list;
                                  row[idx].price = e.target.value;
                                  row[idx].price_error = false;
                                  this.setState({
                                    barging_agreement_price_list: row,
                                  })
                                }}

                              >
                              </TextField>
                            </div>
                            <div className='col-lg-4 pr-0'>
                              <label className='form_label mb-0'>Barging Type</label>
                              <RadioGroup aria-label="barging_type" name="barging_type" onChange={(e) => {
                                let row = this.state.barging_agreement_price_list;
                                row[idx].barging_type = e.target.value
                                this.setState({
                                  barging_agreement_price_list: row
                                });
                              }} style={{ flexDirection: 'row' }}>
                                <FormControlLabel value="Lumpsum" control={<NewRadio checked={e.barging_type === "Lumpsum"} />} label="Lumpsum" />
                                <FormControlLabel value="Per MT" control={<NewRadio checked={e.barging_type === "Per MT"} />} label="Per MT" />
                              </RadioGroup>
                            </div>
                            <div className='col-lg-4 pr-0'>
                              <label className='form_label mb-0'>VAT Applicability</label>
                              <RadioGroup aria-label="vat_incl_or_excl" name="vat_incl_or_excl" onChange={(e) => {
                                let row = this.state.barging_agreement_price_list;
                                row[idx].vat_incl_or_excl = e.target.value
                                this.setState({
                                  barging_agreement_price_list: row
                                });
                              }} style={{ flexDirection: 'row' }}>
                                <FormControlLabel value="Exclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Exclusive"} />} label="Exclusive" />
                                <FormControlLabel value="Inclusive" control={<NewRadio checked={e.vat_incl_or_excl === "Inclusive"} />} label="Inclusive" />
                              </RadioGroup>
                            </div>


                          </div>
                        </div>
                      </div>
                      {(this.state.barging_agreement_price_list.length > 1) &&
                        <div className='col-lg-1 d-flex justify-content-center m-auto'>
                          <div className="icon_hover text-align-center">
                            <i className="fa fa-trash-o m-auto" onClick={this.removeProcurementRowsHandler(idx)} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                          </div>
                        </div>
                      }
                    </div>

                  ))} */}
                  <div className='row mt-2 mb-2'>
                    <button type='button' onClick={() => {
                      const item =
                      {
                        area: '', price: '',
                        barging_type: 'Lumpsum',
                        vat_incl_or_excl: 'Exclusive',
                      }

                      this.setState({
                        barging_agreement_price_list: [...this.state.barging_agreement_price_list, item]
                      })
                    }}
                      className='header_button header_button_text addrow_button_adjustment' style={{ color: config.themeColor }}>Add More
                    </button>
                  </div>
                  <div className='row mb-3'>
                    <div className='col-lg-12 p-0'>
                      <label className='form_label mb-0'>Remarks</label>
                      <TextField
                        name='remarks'
                        margin='dense'
                        variant='outlined'
                        multiline
                        rows={4}
                        placeholder='Remarks'
                        value={this.state.remarks}
                        onChange={(e) => {
                          this.setState({
                            remarks: e.target.value
                          })
                        }}
                        fullWidth
                        inputProps={{ style: { textTransform: 'uppercase' } }}
                      />
                    </div>
                  </div>

                  <FileUpload
                    files={this.state.files}
                    feature_name="Barging_agreements"
                    callbackFn={(data) => this.setState({ files: data })}
                  />
                </div>
              </div>
              <footer className='row bottom_buttons_section'>
                <button type='button' className='btn previous_button' name='submit' onClick={() => window.location.href = '/coal-procurement-contracts'}>CANCEL</button>
                <button type='button' className='btn next_button' name='Back' onClick={this.submitHandler}
                  disabled={this.state.submitLoading}>
                  <span className={this.state.submitLoading ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>
                  SUBMIT</button>
              </footer>
            </section>
          }
        </div>
      </div >
    )
  }
}