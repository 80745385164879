import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";
import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, toLocaleString } from "../../common/common";

import FreightCostingAdd from "./Mv_Freight_Costing_Add";
import FreightCostingUpdate from "./Mv_Freight_Costing_Update";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import AuditInfo from "../../common/AuditInfo";
export default class Mv_Freight_Costing_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      freightCostingAdd: false,
      freightCostingUpdate: false,
      vessel_owner: "",
      quantity: "",
      price_pmt: "",
      total: "",
      remarks: "",
      isLoading: true,
      vendors: [],
      freight_costing_payments: [],
      features: [],
      mv_pur_fin_freight_costing: false,
      closeDialog: false,
      approval_status: "Pending",
      files: [],
      invoiceDialog: false,
      invoiceDetails: {
        invoice_no: "",
        invoice_date: null,
        ppn: "",
        ppn_date: null,
      },
      costing_id: "",
      mother_vessel_name: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      const features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("380") ||
          features.includes("417") ||
          features.includes("468") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      const businessNoID = this.props.businessNoID;
      if (businessNoID) {
        //Get businessNumber API based on businessNumber
        await api
          .getBusinessNumber(businessNoID, loginUserID, idToken)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.business_number) {
                    this.setState({
                      businessNo: res.business_number.business_no
                        ? res.business_number.business_no
                        : "",
                      mother_vessel_name: res.business_number.mother_vessel_name
                        ? res.business_number.mother_vessel_name
                        : "",
                      //isLoading: false
                    });
                  }
                } else if (res.code === "601") {
                  this.setState({
                    errorMsg: res.message,
                    snackBarErrorOpen: true,
                  });
                } else if (res.code === "607") {
                  window.location.href = "/logout";
                }
              });
            }
          });

        // get Freight costing api
        await api
          .get_mv_pur_fin_freight_costing(loginUserID, idToken, businessNoID)
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              response.json().then((res) => {
                if (res.code === "200") {
                  if (res.mv_pur_fin_freight_costing) {
                    let invoice_details = {
                      ...this.state.invoiceDetails,
                      invoice_no: res.mv_pur_fin_freight_costing.invoice_no,
                      invoice_date: res.mv_pur_fin_freight_costing.invoice_date,
                      ppn: res.mv_pur_fin_freight_costing.ppn,
                      ppn_date: res.mv_pur_fin_freight_costing.ppn_date,
                    };

                    this.setState(
                      {
                        invoiceDetails: invoice_details,
                        mv_pur_fin_freight_costing: res
                          .mv_pur_fin_freight_costing.id
                          ? true
                          : false,
                        mv_freight_costing_id:
                          res.mv_pur_fin_freight_costing.id,
                        costing_no: res.mv_pur_fin_freight_costing.costing_no,
                        invoice_file:
                          res.mv_pur_fin_freight_costing.invoice_file,
                        vessel_owner:
                          res.mv_pur_fin_freight_costing.vessel_owner_name,
                        quantity: res.mv_pur_fin_freight_costing.quantity,
                        price_pmt: res.mv_pur_fin_freight_costing.price_pmt,
                        total: res.mv_pur_fin_freight_costing.total,
                        remarks: res.mv_pur_fin_freight_costing.remarks,
                        vat_percent: res.mv_pur_fin_freight_costing.vat_percent
                          ? res.mv_pur_fin_freight_costing.vat_percent
                          : "",
                        vat_value: res.mv_pur_fin_freight_costing.vat_value
                          ? res.mv_pur_fin_freight_costing.vat_value
                          : "",
                        cost_to_company: res.mv_pur_fin_freight_costing
                          .cost_to_company
                          ? res.mv_pur_fin_freight_costing.cost_to_company
                          : "",

                        created_by: res.mv_pur_fin_freight_costing.created_by
                          ? res.mv_pur_fin_freight_costing.created_by
                          : null,
                        approved_date: res.mv_pur_fin_freight_costing
                          .approved_date
                          ? res.mv_pur_fin_freight_costing.approved_date
                          : null,
                        approved_by: res.mv_pur_fin_freight_costing.approved_by
                          ? res.mv_pur_fin_freight_costing.approved_by
                          : "",
                        approved_by_name: res.mv_pur_fin_freight_costing
                          .approved_by_name
                          ? res.mv_pur_fin_freight_costing.approved_by_name
                          : "",
                        approval_status: res.mv_pur_fin_freight_costing
                          .approval_status
                          ? res.mv_pur_fin_freight_costing.approval_status
                          : "Pending",
                        approved_date_2: res.mv_pur_fin_freight_costing
                          .approved_date_2
                          ? res.mv_pur_fin_freight_costing.approved_date_2
                          : null,
                        approved_by_2: res.mv_pur_fin_freight_costing
                          .approved_by_2
                          ? res.mv_pur_fin_freight_costing.approved_by_2
                          : "",
                        approved_by_name_2: res.mv_pur_fin_freight_costing
                          .approved_by_name_2
                          ? res.mv_pur_fin_freight_costing.approved_by_name_2
                          : "",
                        approval_status_2: res.mv_pur_fin_freight_costing
                          .approval_status_2
                          ? res.mv_pur_fin_freight_costing.approval_status_2
                          : "Pending",

                        files: res.mv_pur_fin_freight_costing.files
                          ? res.mv_pur_fin_freight_costing.files
                          : [],

                        //isLoading: false
                      },
                      () =>
                        api
                          .get_mv_freight_costing_payment_postings(
                            loginUserID,
                            idToken,
                            res.mv_pur_fin_freight_costing.id
                          )
                          .then((response) => {
                            if (
                              response.status >= 200 &&
                              response.status < 300
                            ) {
                              response.json().then((pay_res) => {
                                if (pay_res.code === "200") {
                                  if (
                                    pay_res.mv_freight_costing_payment_postings
                                  ) {
                                    var total_paid = 0;
                                    var remaining_amount = 0;
                                    for (var i of pay_res.mv_freight_costing_payment_postings) {
                                      total_paid += Number(i.amount);
                                    }
                                    remaining_amount =
                                      Number(
                                        res.mv_pur_fin_freight_costing.total
                                      ) - Number(total_paid);
                                    this.setState({
                                      total_paid: total_paid,
                                      remaining_amount: remaining_amount,
                                      remainingAmount: remaining_amount,
                                      freight_costing_payments:
                                        pay_res.mv_freight_costing_payment_postings,
                                      isLoading: false,
                                    });
                                  } else {
                                    alert(
                                      "Unexpected error occured. Please contact administrator."
                                    );
                                  }
                                }
                              });
                            }
                          })
                    );
                  } else if (res.mv_pur_fin_freight_costing === false) {
                    this.setState({
                      mv_pur_fin_freight_costing: false,
                      errorMsg: "No freight costing available",
                      // snackBarErrorOpen: true,
                      isLoading: false,
                    });
                    // setTimeout(
                    //   ()=>this.props.returnCallbackDrawerClose(false),
                    //   2000
                    // );
                  }
                }
              });
            }
          });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid business number",
        });
      }
    }
  }

  onAddResponse = (value) => {
    this.setState({ freightCostingAdd: false, showDrawer: false }, () => {
      this.componentDidMount();
    });
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          showDrawer: false,
          freightCostingAdd: false,
          freightCostingUpdate: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,
      freightCostingAdd: false,
      freightCostingUpdate: false,
    });
  };

  approveHandler = () => {
    this.setState({ isLoading: true });
    let request = {
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
      costing_id: this.state.mv_freight_costing_id,
      costing_type: "MV Freight Costing",
      approval_status: "Approved",
    };
    if (this.state.mv_freight_costing_id) {
      api.approve_costing_api(request).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.setState(
                {
                  snackBarSuccessOpen: true,
                  successMsg: res.message,
                },
                () => this.componentDidMount()
              );
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    } else {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Invalid Freight Costing",
      });
    }
  };

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "mv_pur_fin_freight_costing",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      mv_freight_costing_id,
      freight_costing_payments = [],
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",

      invoiceDetails: { invoice_no = null } = {},
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Freight Costing",
      payment_posting_type: "freight_costing",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate,
      costing_id: mv_freight_costing_id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "383",
      view_payment: "384",
      update_payment: "385",
      delete_payment: "386",
      approver: "418",
      approver2: "469",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={freight_costing_payments}
        costingType="MV Freight Costing"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const data = this.props.mv_freight_costing_id;
    const {
      invoiceDetails: { invoice_no, invoice_date } = {},
      costing_no,
      currency_xchg_rate = 1,
      billing_currency,
    } = this.state;
    const Grand_total = parseFloat(
      this.state.cost_to_company ? this.state.cost_to_company : 0
    ).toFixed(2);

    const invoiceObject = {
      costing_id: costing_no,
      to: this.state.vessel_owner,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Freight Costing",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data,
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(this.state.quantity),
          adjustedPrice: toLocaleString(this.state.price_pmt),
          desc: `${
            currency_xchg_rate > 1
              ? `MV Freight @ ${parseFloat(currency_xchg_rate)}`
              : "MV Freight"
          }`,
          amount: this.state.total,
        },
        ...(this.state.pph15_account !== "SDAM" && !!this.state.vat_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "VAT",
                amount: `${toLocaleString(this.state.vat_value)}`,
              },
            ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) && !!this.state.pph23_value
          ? [
              {
                qty: " ",
                adjustedPrice: " ",
                desc: "PPH",
                amount: `( ${toLocaleString(this.state.pph23_value)} )`,
              },
            ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    let drawerComponent;

    if (
      !this.state.mv_pur_fin_freight_costing &&
      this.state.freightCostingAdd &&
      this.props.businessNoID
    ) {
      drawerComponent = (
        <FreightCostingAdd
          businessNo={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }
    if (
      this.state.mv_pur_fin_freight_costing &&
      this.state.freightCostingUpdate &&
      this.props.businessNoID
    ) {
      drawerComponent = (
        <FreightCostingUpdate
          businessNo={this.props.businessNoID}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    }

    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="bg-white p-0">
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.showDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row p-0" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="row clearfix content-header border-bottom">
            <h4 className="mt-2" style={{ padding: "4px 20px 0px 8px" }}>
              Freight Costing - {this.state.mother_vessel_name}{" "}
              {"(" + this.state.businessNo + ")"}
            </h4>
            {!this.state.mv_pur_fin_freight_costing &&
              (this.state.features.includes("379") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //this.props.returnAddCallBack(true);
                    this.setState({
                      showDrawer: true,
                      freightCostingAdd: true,
                      freightCostingUpdate: false,
                    });
                  }}
                />
              )}
            {this.state.mv_pur_fin_freight_costing &&
              (this.state.features.includes("381") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-pencil mt-2 ml-2"
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    //this.props.returnEditCallback("MvFreightCosting");
                    this.setState({
                      showDrawer: true,
                      freightCostingAdd: false,
                      freightCostingUpdate: true,
                    });
                  }}
                />
              )}
            {this.state.mv_pur_fin_freight_costing && (
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            )}
          </div>
          {!this.state.mv_pur_fin_freight_costing && (
            <div className="ml-2 mt-2 pb-4">No data available</div>
          )}
          <div
            className="card p-3"
            style={{
              overflowY: "scroll",
              height: "inherit",
              display: this.state.mv_pur_fin_freight_costing ? "" : "none",
            }}
          >
            <div
              className="card-body p-0"
              style={{ backgroundColor: "rgba(25,118,210,.01)" }}
              id="accordion"
            >
              {this.state.approval_status === "Pending" &&
                this.state.invoice_file &&
                this.Cookie.getCookie("loginUserId") !==
                  this.state.created_by &&
                (this.state.features.includes("417") ||
                  this.state.features.includes("468") ||
                  this.state.features.includes("97")) && (
                  <div className="col-lg-4 mb-1">
                    {/* <label className='contract_display_header_label mb-1'>Fakthur Pazak No</label> */}
                    <button
                      className="btn payment_button"
                      type="button"
                      onClick={this.approveHandler}
                    >
                      Approve
                    </button>
                  </div>
                )}

              <div className="table-responsive">
                <table className="table table-sm table-bordered mb-0">
                  <thead className="table-header">
                    <tr>
                      <th className="text-center">Description</th>
                      <th className="text-right">Qty</th>
                      <th className="text-right">Unit Price</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      <tr>
                        <td>
                          MV Freight Charges
                          <div>
                            {" "}
                            Vessel Owner :{" "}
                            {this.state.vessel_owner
                              ? this.state.vessel_owner
                              : "-"}
                          </div>
                        </td>
                        <td className="text-right">
                          {toLocaleString(this.state.quantity)}
                        </td>
                        <td className="text-right">
                          {toLocaleString(this.state.price_pmt)}
                        </td>
                        <td className="text-right">
                          {toLocaleString(this.state.total)}
                        </td>
                      </tr>
                      {(this.state.vat_applicability === "Inclusive" ||
                        config.company !== "SRPL" ||
                        config.company !== "PRPL" ||
                        config.company !== "AVS") &&
                        !!this.state.vat_value && (
                          <tr>
                            <td>VAT @ {this.state.vat_percent} %</td>
                            <td className="text-right"></td>
                            <td className="text-right"></td>
                            <td className="text-right">
                              {toLocaleString(this.state.vat_value)}
                            </td>
                          </tr>
                        )}
                      <tr>
                        <td colSpan={3} className="text-right ">
                          Payable to Vendor :{" "}
                        </td>
                        <td className="text-right ">
                          <span>
                            {" "}
                            {config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                              ? "USD"
                              : "IDR"}
                          </span>{" "}
                          {toLocaleString(this.state.cost_to_company)}
                        </td>
                      </tr>
                    </>
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-1">
                  <label className="contract_display_header_label mb-0">
                    Remarks
                  </label>
                  <div
                    className="contract_display_header_value"
                    style={{
                      whiteSpace: "pre-line",
                      textTransform: "uppercase",
                    }}
                  >
                    {this.state.remarks ? this.state.remarks : "-"}
                  </div>
                </div>
              </div>
              <div className="card col-lg-12 p-0 border">
                <div className="card-header section_header">
                  <h5>Payment Postings</h5>
                </div>
                <div className="card-body">
                  <InvoiceForm
                    open={this.invoiceDialog}
                    data={this.state.invoiceDetails}
                    costingID={this.state.mv_freight_costing_id}
                    callbackFn={(data) => this.updateInvoice(data)}
                  />
                  <div className="row">
                    <div className="col-lg-4 mb-2">
                      <label className="contract_display_header_label">
                        Total Paid
                      </label>
                      <div className="contract_display_header_value">
                        {" "}
                        {toLocaleString(this.state.total_paid)}
                        {config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                          ? "USD"
                          : "IDR"}
                      </div>
                    </div>
                    <div className="col-lg-4 mb-2">
                      <label className="contract_display_header_label">
                        Remaining Amount
                      </label>
                      <div className="contract_display_header_value">
                        {toLocaleString(this.state.remaining_amount)}
                        {config.company === "SRPL" ||
                        config.company === "AVS" ||
                        config.company === "PRPL"
                          ? "USD"
                          : "IDR"}
                      </div>
                    </div>
                  </div>
                  {(this.state.features.includes("384") ||
                    this.state.features.includes("97")) && (
                    <div>{this.renderPaymentPostings(this.state)}</div>
                  )}
                </div>
              </div>
              <ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
              />

              <AuditInfo info={this.state} />
            </div>
          </div>
        </div>
      );
    }
  }
}
