import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { localDateFormate, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
export default class Other_Income_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();

    this.state = {
      multiRows: [
        {
          credit_type: "Incoming",
          vendor_or_customer: "Vendor",
          vendor_id: "",
          customer_id: "",
          income_date: null,
          amount: "",
          remarks: "",
          costing_no: "",
        },
      ],
      getBarges: [],
      customers: [],
      vendors: [],
      mother_vessel_name:"",
      isLoading: true,

      features: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    if (
      !(
        features.includes("303") ||
        features.includes("489") ||
        features.includes("495") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const OtherIncomeID = this.props.OtherIncomeID;
    await api
      .get_buss_no_other_income(loginUserID, idToken, OtherIncomeID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_no_other_income) {
                for (var i in res.business_no_other_income) {
                  var total_paid_amout = 0;
                  for (var j of res.business_no_other_income[i]
                    .other_income_payments) {
                    total_paid_amout += Number(j.amount);
                  }
                  res.business_no_other_income[i].total_paid_amout =
                    total_paid_amout;
                  res.business_no_other_income[i].remaining_amount =
                    Number(res.business_no_other_income[i].amount) -
                    Number(total_paid_amout);
                }
                this.setState({
                  multiRows: res.business_no_other_income,
                  costing_no: res.business_no_other_income[0].costing_no,
                  exchange_rate: res.business_no_other_income[0].exchange_rate,
                  billing_currency:
                    res.business_no_other_income[0].billing_currency,
                  customer_name: res.business_no_other_income[0].customer_name,
                  invoice_no: res.business_no_other_income[0].invoice_no,
                  invoice_date: res.business_no_other_income[0].invoice_date,

                  isLoading: false,
                });
                if (res.business_no_other_income.length <= 0) {
                  this.setState({
                    errorMsg: "No Income data available",
                  });
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const businessNoID = this.props.businessNoID;
    //Get businessNumber API based on businessNumber
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  isLoading: false,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                sucuessMsg: "",
              });
              window.$("#AlertMessageModal").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    document.title = config.documentTitle + "View Other Incomes ";
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "business_no_other_income",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      other_income_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      exchange_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "MV Other Income",
      payment_posting_type: "other_income",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      currency_xchg_rate: exchange_rate ? exchange_rate : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };

    let access_details = {
      costing_approval: approval_status,
      add_payment: "306",
      view_payment: "307",
      update_payment: "308",
      delete_payment: "309",
      approver: "490",
      approver2: "496",
    };

    return (
      <PaymentPostings
        access_details={access_details}
        payments={other_income_payments}
        costingType="MV Other Income"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };
  renderInvoiceLink = () => {
    const { costing_no } = this.state;

    let total_adjustment = 0;

    const Grand_total = (
      parseFloat(
        this.state.multiRows[0].amount ? this.state.multiRows[0].amount : 0
      ) + parseFloat(total_adjustment ? total_adjustment : 0)
    ).toFixed(2);

    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.customer_name,
      business_no: this.state.mother_vessel_name,
      costing_name: "MV Other Income",
      invoice_no: this.state.invoice_no,
      invoice_date: localDateFormate(this.state.invoice_date),
      bargeDetails: [],
      billing_currency:
        config.company === "SRPL" || config.company === "AVS" || config.company === "PRPL" ? "USD" : "IDR",
      total_payable_amount: Grand_total,
      purchase_type: "FOB Barge",
      description: [
        {
          qty: " ",
          adjustedPrice: " ",
          desc: `${this.state.exchange_rate > 1
            ? `Other Incomes @${parseFloat(this.state.exchange_rate).toFixed(2)}`
            : "Other Incomes"
            }`,
          amount: this.state.multiRows[0].amount,
        },
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };
  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          {this.state.multiRows.map((e, idx) => (
            <div className="clearfix content-header border-bottom">
              <div className="row">
                <div className="col-lg-10 text-left pl-0">
                  <h4 style={{ padding: "10px 20px" }}>
                    Other Income - {e.costing_no}
                    <span
                      className={
                        e.approval_status === "Pending" ? "type_2" : "type_1"
                      }
                      style={{ padding: 8, marginLeft: 16 }}
                    >
                      {e.approval_status}
                    </span>
                  </h4>
                </div>
                {e.approval_status === "Pending" &&
                  this.Cookie.getCookie("loginUserId") !== e.created_by &&
                  e.approved_by !== this.Cookie.getCookie("loginUserId") &&
                  (this.state.features.includes("489") ||
                    this.state.features.includes("495") ||
                    this.state.features.includes("97")) && (
                    <BusinessApproveButton
                      costingID={e.id}
                      costingType={"MV Other Income"}
                      accessInfo={{
                        ...e,
                        approver_1: "489",
                        approver_2: "495",
                      }}
                      callbackFn={() => this.componentDidMount()}
                    />
                  )}
                <div className="col-lg-1 pl-0 float-right">
                  {this.renderInvoiceLink()}
                </div>
              </div>
            </div>
          ))}
          <div className="drawer-section">
            <div className="card p-3">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {this.state.multiRows.map((e, idx) => (
                  <>
                    <div
                      className="row border_dashed rounded-lg p-2 mb-3"
                      key={idx}
                    >
                      <div className="col shadow-sm p-0 mb-2 bg-white border">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>
                                    Other Incomes
                                    {(((config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL") &&
                                      e.billing_currency !== "USD" &&
                                      e.billing_currency) ||
                                      ((config.company === "SDAM" ||
                                        config.company === "SRK" ||
                                        config.company === "BTR") &&
                                        e.billing_currency !== "IDR" &&
                                        e.billing_currency)) &&
                                      e.exchange_rate > 1 && (
                                        <div>
                                          Exchange Rate @ {parseFloat(e.exchange_rate).toFixed(2)}
                                        </div>
                                      )}
                                    <div>From : {e.customer_name ? e.customer_name : "Others"} </div>
                                    {e.payee_name
                                      &&
                                      <div>Payee Name : {e.payee_name} </div>
                                    }
                                    <div>Date : {e.income_date ? localDateFormate(e.income_date) : "_"}</div>
                                  </td>
                                  <td className="text-right"></td>
                                  <td className="text-right"></td>
                                  <td className="text-right">{e.amount}</td>
                                </tr>
                                <tr>
                                  <td colSpan={3} style={{ fontWeight: 'bold' }} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  {(((config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL") &&
                                    e.billing_currency !== "USD" &&
                                    e.billing_currency) ||
                                    ((config.company === "SDAM" ||
                                      config.company === "SRK" ||
                                      config.company === "BTR") &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency)) &&
                                    <td style={{ fontWeight: 'bold' }} className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        } ${e.local_currency_amount}`}
                                    </td>
                                  }
                                  {(config.company === "SRPL" || config.company === "AVS" ||
                                    config.company === "PRPL")
                                    &&
                                    (config.company !== "SDAM" || config.company !== "SRK" ||
                                      config.company !== "BTR") &&
                                    <td style={{ fontWeight: 'bold' }} className="text-right ">
                                      {`${config.company === "SRPL" ||
                                        config.company === "PRPL" ||
                                        config.company === "AVS"
                                        ? "USD"
                                        : "IDR"
                                        } ${e.amount}`}
                                    </td>
                                  }
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="row col-lg-12 pl-0 pr-0">
                        <div className="col-lg-12 mb-1 pl-0">
                          <label className="contract_display_header_label">
                            Remarks
                          </label>
                          <label
                            className="contract_display_header_value"
                            style={{
                              textTransform: "uppercase",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {e.remarks ? e.remarks : "-"}
                          </label>
                        </div>
                      </div>
                      <div className="card col-lg-12 p-0 border mb-3">
                        <div className="card-header section_header">
                          <h5>Payment Postings</h5>
                        </div>
                        <div className="card-body p-2 pt-3">
                          <InvoiceForm
                            open={false}
                            data={{
                              invoice_no: e.invoice_no,
                              invoice_date: e.invoice_date,
                              ppn: e.ppn,
                              ppn_date: e.ppn_date,
                            }}
                            costingID={e.id}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />
                          {(this.state.features.includes("390") ||
                            this.state.features.includes("97")) && (
                              <div>
                                <div className="row">
                                  <div className="col-lg-6 mb-1">
                                    <label className="contract_display_header_label">
                                      Received Amount
                                    </label>
                                    <label className="contract_display_header_value">
                                      {toLocaleString(e.total_paid_amout)}
                                      {config.company === "SRPL" || config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? " USD"
                                        : " IDR"}
                                    </label>
                                  </div>
                                  <div className="col-lg-6 mb-1 pl-0">
                                    <label className="contract_display_header_label">
                                      Remaining Amount
                                    </label>
                                    {e.remaining_amount < 0 ? (
                                      <label className="contract_display_header_value">
                                        {toLocaleString(
                                          Math.abs(e.remaining_amount)
                                        )}
                                        {config.company === "SRPL" || config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? " USD"
                                          : " IDR"}
                                      </label>
                                    ) : (
                                      <label className="contract_display_header_value">
                                        {toLocaleString(e.remaining_amount)}
                                        {config.company === "SRPL" || config.company === "AVS" ||
                                          config.company === "PRPL"
                                          ? " USD"
                                          : " IDR"}
                                      </label>
                                    )}
                                  </div>
                                </div>
                                {this.renderPaymentPostings(e)}
                                {/* <div className="col-lg-12">
                              <div className="clearfix">
                                <div className="float-left">
                                  {!e.other_income_payments
                                    ? ""
                                    : e.other_income_payments.length <= 0 && (
                                        <p style={{ fontWeight: "bold" }}>
                                          No payments made
                                        </p>
                                      )}
                                </div>
                                <div className="float-right">
                                  {(this.state.features.includes("389") ||
                                    this.state.features.includes("97")) && (
                                    <button
                                      className="header_button header_button_text w-auto"
                                      onClick={() => {
                                        this.setState({
                                          paymentTitle: "Add Other Income",
                                          payment_posting_type: "Other_Income",
                                          remainingAmount: e.remaining_amount,
                                          other_income_id: e.id,
                                          date_of_payment: null,
                                          amount: "",
                                          invoice_no: "",
                                          description: "",
                                          paymentErrorMsg: "",
                                          date_of_paymentError: false,
                                          amountError: false,
                                          paymentMode: "Add",
                                          invoice_noError: false,
                                          paymentPostingsModal: true,
                                        });
                                      }}
                                    >
                                      Add Payment
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>

                            {!e.other_income_payments
                              ? ""
                              : e.other_income_payments.length > 0 && (
                                  <div className="row mt-2">
                                    <div className="col-lg p-0">
                                      <div className="table-responsive">
                                        <table className="table table-borderless border-0">
                                          <thead>
                                            <tr>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Date Received
                                              </th>
                                              <th
                                                className="table_header_barge_label text-right"
                                                nowrap="true"
                                              >
                                                Amount
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Invoice No
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                style={{
                                                  width: 300,
                                                  maxWidth: 250,
                                                }}
                                              >
                                                Description
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Created Date
                                              </th>
                                              <th
                                                className="table_header_barge_label"
                                                nowrap="true"
                                              >
                                                Action
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {e.other_income_payments.map(
                                              (v, index) => (
                                                <tr key={index}>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {v.date_of_payment
                                                      ? localDateFormate(
                                                          v.date_of_payment
                                                        )
                                                      : null}
                                                  </td>
                                                  <td
                                                    className="table_td border-0 text-right"
                                                    nowrap="true"
                                                  >
                                                    {toLocaleString(
                                                          v.amount
                                                        )
                                                     }
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {v.invoice_no}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    style={{
                                                      whiteSpace: "pre-line",
                                                    }}
                                                  >
                                                    {v.description}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {v.created_date
                                                      ? localDateFormate(
                                                          v.created_date
                                                        )
                                                      : null}
                                                  </td>
                                                  <td
                                                    className="table_td border-0"
                                                    nowrap="true"
                                                  >
                                                    {(this.state.features.includes(
                                                      "391"
                                                    ) ||
                                                      this.state.features.includes(
                                                        "97"
                                                      )) && (
                                                      <Tooltip
                                                        title="Edit"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-pencil mr-2"
                                                          style={{
                                                            fontSize: 20,
                                                            color:
                                                              config.themeColor,
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Edit Coal Payment Posting"
                                                          data-placement="bottom"
                                                          onClick={() => {
                                                            this.setState({
                                                              remainingAmount:
                                                                e.remaining_amount,
                                                            });
                                                            this.getPaymentDetalis(
                                                              v
                                                            );
                                                          }}
                                                        ></i>
                                                      </Tooltip>
                                                    )}
                                                    {(this.state.features.includes(
                                                      "392"
                                                    ) ||
                                                      this.state.features.includes(
                                                        "97"
                                                      )) && (
                                                      <Tooltip
                                                        title="Delete"
                                                        arrow
                                                      >
                                                        <i
                                                          className="fa fa-trash-o"
                                                          style={{
                                                            fontSize: 20,
                                                            color: "red",
                                                            cursor: "pointer",
                                                          }}
                                                          data-toggle="modal"
                                                          title="Delete Coal Payment Posting"
                                                          data-placement="bottom"
                                                          data-keyboard="false"
                                                          data-target="#deleteConformationModal"
                                                          data-backdrop="static"
                                                          onClick={() =>
                                                            this.setState({
                                                              deletePaymentData:
                                                                v,
                                                            })
                                                          }
                                                        />
                                                      </Tooltip>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                              </div>
                            )}
                        </div>
                      </div>
                      <ViewFiles {...this.props} files_data={e.files} />
                    </div>
                    <div className="col shadow-sm p-0 mb-2 bg-white border">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead className="table-header">
                            <tr>
                              <th className="text-center">Approval Type</th>
                              <th className="text-center">Approved by</th>
                              <th className="text-center">Approved On</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">Business</td>
                              <td className="text-center">
                                <div> {e.approved_by_name}</div>
                              </td>
                              <td className="text-center">
                                {localDateFormate(e.approved_date)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Financial</td>
                              <td className="text-center">
                                {e.approved_by_name_2}
                              </td>
                              <td className="text-center">
                                {localDateFormate(e.approved_date_2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
          <footer className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackDrawerClose(false);
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={() => {
                this.props.returnEditCallback("OtherIncome");
              }}
            >
              Edit
            </button>
          </footer>
        </div>
      );
    }
  }
}
