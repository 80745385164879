import config from "../config/config";

export const SamplingAPIs = {
    parentPurchaseUrl: config.apiDomain + "/purchase-sampling",
    parentSalesUrl: config.apiDomain + "/sales-sampling",
    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @param {*} samplingType 
     * @returns 
     */
    getPurchaseSamplingData(loginUserID, idToken, samplingType, loadMore) {
        let url = this.parentPurchaseUrl + "/get-purchase-sampling-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                login_user_id: loginUserID,
                idtoken: idToken,
                samplingType: samplingType,
                loadMore: loadMore
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @param {*} bargeID 
     * @param {*} quality_calorific_value_actual 
     * @param {*} quality_calorific_value2_actual 
     * @param {*} quality_calorific_value3_actual 
     * @param {*} quality_total_moisture_actual 
     * @param {*} quality_inherent_moisture_actual 
     * @param {*} quality_ash_actual 
     * @param {*} quality_volatile_matter_actual 
     * @param {*} quality_fixed_carbon_actual 
     * @param {*} quality_fuel_ratio_actual 
     * @param {*} quality_total_sulphur_actual 
     * @param {*} quality_hgi_actual 
     * @param {*} status 
     * @param {*} files 
     * @param {*} samplingType 
     * @param {*} previousGroupID 
     * @param {*} existing_file_ids 
     * @returns 
     */
    savePurchaseSamplingData(
        loginUserID,
        idToken,
        bargeID,
        quality_calorific_value_actual,
        quality_calorific_value2_actual,
        quality_calorific_value3_actual,
        quality_total_moisture_actual,
        quality_inherent_moisture_actual,
        quality_ash_actual,
        quality_volatile_matter_actual,
        quality_fixed_carbon_actual,
        quality_fuel_ratio_actual,
        quality_total_sulphur_actual,
        quality_hgi_actual,
        status,
        files,
        samplingType,
        previousGroupID,
        existing_file_ids
    ) {
        var url = this.parentPurchaseUrl + "/save-purchase-sampling-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                bargeID: bargeID,
                samplingType: samplingType,
                quality_calorific_value_actual: quality_calorific_value_actual
                    ? quality_calorific_value_actual.toUpperCase()
                    : null,
                quality_calorific_value2_actual: quality_calorific_value2_actual
                    ? quality_calorific_value2_actual.toUpperCase()
                    : null,
                quality_calorific_value3_actual: quality_calorific_value3_actual
                    ? quality_calorific_value3_actual.toUpperCase()
                    : null,
                quality_total_moisture_actual: quality_total_moisture_actual
                    ? quality_total_moisture_actual.toUpperCase()
                    : null,
                quality_inherent_moisture_actual: quality_inherent_moisture_actual
                    ? quality_inherent_moisture_actual.toUpperCase()
                    : null,
                quality_ash_actual: quality_ash_actual
                    ? quality_ash_actual.toUpperCase()
                    : null,
                quality_volatile_matter_actual: quality_volatile_matter_actual
                    ? quality_volatile_matter_actual.toUpperCase()
                    : null,
                quality_fixed_carbon_actual: quality_fixed_carbon_actual
                    ? quality_fixed_carbon_actual.toUpperCase()
                    : null,
                quality_fuel_ratio_actual: quality_fuel_ratio_actual
                    ? quality_fuel_ratio_actual.toUpperCase()
                    : null,
                quality_total_sulphur_actual: quality_total_sulphur_actual
                    ? quality_total_sulphur_actual.toUpperCase()
                    : null,
                quality_hgi_actual: quality_hgi_actual
                    ? quality_hgi_actual.toUpperCase()
                    : null,
                status: status,
                files: files,
                existing_file_ids: existing_file_ids,
                previousGroupID: previousGroupID ? previousGroupID : null
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @param {*} samplingType 
     * @param {*} groupId 
     * @returns 
     */
    deletePurchaseSamples(loginUserID, idToken, samplingType, groupId) {
        let url = this.parentPurchaseUrl + "/delete-purchase-sampling";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                samplingType: samplingType,
                groupId: groupId
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @param {*} samplingType 
     * @param {*} bargeId 
     * @returns 
     */
    dissolvePurchaseSamples(loginUserID, idToken, samplingType, bargeId) {
        let url = this.parentPurchaseUrl + "/dissolve-purchase-sampling";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                samplingType: samplingType,
                bargeId: bargeId
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @param {*} bargeID 
     * @param {*} quality_calorific_value_actual 
     * @param {*} quality_calorific_value2_actual 
     * @param {*} quality_calorific_value3_actual 
     * @param {*} quality_total_moisture_actual 
     * @param {*} quality_inherent_moisture_actual 
     * @param {*} quality_ash_actual 
     * @param {*} quality_volatile_matter_actual 
     * @param {*} quality_fixed_carbon_actual 
     * @param {*} quality_fuel_ratio_actual 
     * @param {*} quality_total_sulphur_actual 
     * @param {*} quality_hgi_actual 
     * @param {*} status 
     * @param {*} files 
     * @param {*} samplingType 
     * @param {*} jetty 
     * @param {*} supplier 
     * @param {*} type 
     * @param {*} existing_file_ids 
     * @returns 
     */
    savePreShipmentSamplingData(
        loginUserID,
        idToken,
        bargeID,
        quality_calorific_value_actual,
        quality_calorific_value2_actual,
        quality_calorific_value3_actual,
        quality_total_moisture_actual,
        quality_inherent_moisture_actual,
        quality_ash_actual,
        quality_volatile_matter_actual,
        quality_fixed_carbon_actual,
        quality_fuel_ratio_actual,
        quality_total_sulphur_actual,
        quality_hgi_actual,
        quality_calorific_value_basis,
        quality_calorific_value2_basis,
        quality_calorific_value3_basis,
        quality_total_moisture_basis,
        quality_inherent_moisture_basis,
        quality_ash_basis,
        quality_volatile_matter_basis,
        quality_fixed_carbon_basis,
        quality_fuel_ratio_basis,
        quality_total_sulphur_basis,
        status,
        files,
        samplingType,
        jetty,
        supplier, type,
        existing_file_ids
    ) {
        var url = this.parentPurchaseUrl + "/save-pre-shipment-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                bargeID: bargeID,
                samplingType: samplingType,
                quality_calorific_value_actual: quality_calorific_value_actual
                    ? quality_calorific_value_actual.toUpperCase()
                    : null,
                quality_calorific_value2_actual: quality_calorific_value2_actual
                    ? quality_calorific_value2_actual.toUpperCase()
                    : null,
                quality_calorific_value3_actual: quality_calorific_value3_actual
                    ? quality_calorific_value3_actual.toUpperCase()
                    : null,
                quality_total_moisture_actual: quality_total_moisture_actual
                    ? quality_total_moisture_actual.toUpperCase()
                    : null,
                quality_inherent_moisture_actual: quality_inherent_moisture_actual
                    ? quality_inherent_moisture_actual.toUpperCase()
                    : null,
                quality_ash_actual: quality_ash_actual
                    ? quality_ash_actual.toUpperCase()
                    : null,
                quality_volatile_matter_actual: quality_volatile_matter_actual
                    ? quality_volatile_matter_actual.toUpperCase()
                    : null,
                quality_fixed_carbon_actual: quality_fixed_carbon_actual
                    ? quality_fixed_carbon_actual.toUpperCase()
                    : null,
                quality_fuel_ratio_actual: quality_fuel_ratio_actual
                    ? quality_fuel_ratio_actual.toUpperCase()
                    : null,
                quality_total_sulphur_actual: quality_total_sulphur_actual
                    ? quality_total_sulphur_actual.toUpperCase()
                    : null,
                quality_hgi_actual: quality_hgi_actual
                    ? quality_hgi_actual.toUpperCase()
                    : null,
                quality_calorific_value_basis: quality_calorific_value_basis
                    ? quality_calorific_value_basis.toUpperCase()
                    : null,

                quality_calorific_value2_basis: quality_calorific_value2_basis
                    ? quality_calorific_value2_basis.toUpperCase()
                    : null,

                quality_calorific_value3_basis: quality_calorific_value3_basis
                    ? quality_calorific_value3_basis.toUpperCase()
                    : null,

                quality_total_moisture_basis: quality_total_moisture_basis
                    ? quality_total_moisture_basis.toUpperCase()
                    : null,

                quality_inherent_moisture_basis: quality_inherent_moisture_basis
                    ? quality_inherent_moisture_basis.toUpperCase()
                    : null,

                quality_ash_basis: quality_ash_basis
                    ? quality_ash_basis.toUpperCase()
                    : null,

                quality_volatile_matter_basis: quality_volatile_matter_basis
                    ? quality_volatile_matter_basis.toUpperCase()
                    : null,

                quality_fixed_carbon_basis: quality_fixed_carbon_basis
                    ? quality_fixed_carbon_basis.toUpperCase()
                    : null,

                quality_fuel_ratio_basis: quality_fuel_ratio_basis
                    ? quality_fuel_ratio_basis.toUpperCase()
                    : null,

                quality_total_sulphur_basis: quality_total_sulphur_basis
                    ? quality_total_sulphur_basis.toUpperCase()
                    : null,
                status: status,
                files: files,
                existing_file_ids: existing_file_ids,
                jetty: jetty,
                supplier: supplier,
                type: type
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    /**
     * 
     * @param {*} loginUserID 
     * @param {*} idToken 
     * @returns 
     */
    getDelayedPurchaseSamplingReportData(loginUserID, idToken, loadMore) {
        let url = this.parentPurchaseUrl + "/get-delayed-sampling-report-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                loadMore: loadMore
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    getPreShipmentData(loginUserID, idToken, type) {
        let url = this.parentPurchaseUrl + "/get-pre-shipment-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                type: type
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    getAllJettiesData(loginUserID, idToken) {
        let url = this.parentPurchaseUrl + "/get-all-jetties";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    getSalesSamplingData(loginUserID, idToken, samplingType, loadMore) {
        let url = this.parentSalesUrl + "/get-sales-sampling-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                login_user_id: loginUserID,
                idtoken: idToken,
                samplingType: samplingType,
                loadMore: loadMore
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    getDelayedSalesSamplingReportData(loginUserID, idToken, loadMore) {
        let url = this.parentSalesUrl + "/get-delayed-sampling-report-data";
        return fetch(url, {
            method: "Post",
            body: JSON.stringify({
                loginUserId: loginUserID,
                tokenID: idToken,
                loadMore: loadMore
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
};