import React, { Component } from "react";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import {
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import api from "../../../api/api";
import { withStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import FileUpload from "../../common/FileUpload";
import Loader from "../../common/Loader";
import { toLocaleString } from "../../common/common";
const NewRadio = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export default class Mv_Floating_Crane_Charges_Update extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      openDrawer: false,
      floating_crane_vendor: [
        {
          floating_crane_vendor_id: "0",
          floating_crane_vendor_name: "",
          floating_crane_charges: [
            {
              floating_crane_type: "Floating Crane",
              invoice_no: "",
              invoice_date: null,
              ppn: "",
              ppn_date: null,
              floating_crane_vendor_id: "",
              floating_crane_vendor_name: "",
              quantity: "",
              billing_currency: "0",
              floating_crane_price_pmt: "",
              currency_exchange_rate: "",
              local_currency: "",
              vat_applicability: "",
              floating_crane_base_price: "",
              floating_crane_vat_percent: 10,
              floating_crane_vat_value: "",
              floating_crane_total: "",
              floating_crane_pph23_percent: 2,
              floating_crane_pph23_value: "",
              floating_crane_payable_to_vendor: "",
              floating_crane_cost_to_company: "",

              floating_crane_price_per_addl_day: "",
              floating_crane_addl_days: "",
              floating_crane_addl_base_price: "",
              floating_crane_addl_vat_percent: 10,
              floating_crane_addl_vat_value: "",
              floating_crane_addl_pph23_percent: 2,
              floating_crane_addl_pph23_value: "",
              floating_crane_addl_total: "",
              floating_crane_addl_payable_to_vendor: "",
              floating_crane_addl_cost_to_company: "",
              pnbp_per_mt: "",
              pnbp: "",
              pnbp_company_to_billing: "",
              jasa_pbm_calculation: "",
              jasa_pbm_pmt: "",
              jasa_pbm_value: "",
              jasa_pbm_company_to_billing: "",
              status: "",
              wait_period_people: "",
              wait_period_hours: "",
              wait_period_price_per_hour: "",
              wait_period_total_price: "",
              others: [],
            },
          ],
        },
      ],
      isLoading: true,
      features: [],
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Mv_Pur_Fin_Floating_Crane_Charges",
        },
      ],
      costing_files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("260") ||
        features.includes("423") ||
        features.includes("474") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    const businessNoID = this.props.businessNoID;
    await api
      .getBusinessNumber(businessNoID, loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.business_number) {
                this.setState({
                  businessNo: res.business_number.business_no
                    ? res.business_number.business_no
                    : "",
                  mother_vessel_name: res.business_number.mother_vessel_name
                    ? res.business_number.mother_vessel_name
                    : "",
                  //isLoading: false
                });
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });

    const floatingCraneID = this.props.floatingCraneID;
    let floating_crane_vendor_data = [];
    await api
      .getNewFloatingCraneCharges(loginUserID, idToken, floatingCraneID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.floating_crane_vendor) {
              floating_crane_vendor_data = res.floating_crane_vendor;
              // this.setState({
              //   floating_crane_vendor: res.floating_crane_vendor
              // });
            }
          });
        }
      });

    await api
      .get_mv_pur_fin_floating_crane_charges(loginUserID, idToken, businessNoID)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.floating_crane_vendor) {
                var row = floating_crane_vendor_data;
                //var remaining_amount = 0;
                var total_payable_to_vendor = 0;
                var total_paid_to_vendor = 0;
                for (var i = 0; i < row.length; i++) {
                  total_payable_to_vendor += Number(
                    row[i].floating_crane_payable_to_vendor
                  );
                  total_payable_to_vendor += Number(
                    row[i].floating_crane_addl_payable_to_vendor
                  );
                  total_payable_to_vendor += Number(row[i].pnbp);
                  total_payable_to_vendor += Number(row[i].jasa_pbm_value);
                  total_payable_to_vendor += Number(
                    row[i].wait_period_total_price
                  );
                  for (var k of row[i].others) {
                    total_payable_to_vendor += Number(k.price);
                  }
                  if (
                    ((config.company === "SRPL" ||
                      config.company === "AVS" ||
                      config.company === "PRPL") &&
                      row[i].billing_currency === "IDR") ||
                    ((config.company === "SDAM" ||
                      config.company === "SRK" ||
                      config.company === "BTR") &&
                      row[i].billing_currency === "USD")
                  ) {
                    row[i].pnbp_company_to_billing = (
                      Number(row[i].quantity) * Number(row[i].pnbp_per_mt)
                    ).toFixed(2);
                    row[i].jasa_pbm_company_to_billing = (
                      (Number(row[i].quantity) * Number(row[i].jasa_pbm_pmt)) /
                      100
                    ).toFixed(2);

                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].quantity ? row[i].floating_crane_charges[j].quantity : 1) * Number(row[i].floating_crane_charges[j].pnbp_per_mt ? row[i].floating_crane_charges[j].pnbp_per_mt : 1)).toFixed(2);
                    //total_payable_to_vendor += ((Number(row[i].floating_crane_charges[j].quantity ? row[i].floating_crane_charges[j].quantity : 1) * Number(row[i].floating_crane_charges[j].jasa_pbm_pmt ? row[i].floating_crane_charges[j].jasa_pbm_pmt : 1)) / 100).toFixed(2);
                  } else {
                    row[i].pnbp_company_to_billing = "";
                    row[i].jasa_pbm_company_to_billing = "";

                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].pnbp));
                    //total_payable_to_vendor += (Number(row[i].floating_crane_charges[j].jasa_pbm_value));
                  }

                  for (var p of row[i].floating_crane_charges_payments) {
                    total_paid_to_vendor += Number(p.amount);
                  }
                  row[i].total_payable_to_vendor = total_payable_to_vendor;
                  row[i].total_paid_to_vendor = total_paid_to_vendor;
                  row[i].remaining_amount =
                    Number(total_payable_to_vendor) -
                    Number(total_paid_to_vendor);
                  total_paid_to_vendor = 0;
                }
                this.setState({
                  floating_crane_vendor: row,
                  costing_no: row.length > 0 ? row[0]?.costing_no : "",
                  files: row.length > 0 ? row[0]?.files : "",
                  invoice_file: row.length > 0 ? row[0]?.invoice_file : "",
                  isLoading: false,
                });
              }
              if (res.floating_crane_vendor.length === 0) {
                this.setState({
                  errorMsg: "No floating crane charges available.",
                  snackBarErrorOpen: true,
                  isLoading: false,
                });
                setTimeout(
                  () => this.props.returnCallbackDrawerClose(false),
                  2000
                );
              }
            } else if (res.code === "601") {
              this.setState({
                errorMsg: res.message,
                snackBarErrorOpen: true,
              });
            }
          });
        }
      });
    // if (businessNoID) {
    //   const status = "Active";
    //   var vendors = [];
    //   await api.getVendors(loginUserID, idToken, status).then((response) => {
    //     if (response.status >= 200 && response.status < 300) {
    //       response.json().then((res) => {
    //         if (res.code === "200") {
    //           if (res.vendors) {
    //             vendors = res.vendors;
    //           } else {
    //             alert(
    //               "Unexpected error occured. Please contact administrator."
    //             );
    //           }
    //         } else if (res.code === "601") {
    //           this.setState({
    //             errorMsg: res.message,
    //             snackBarErrorOpen: true,
    //           });
    //         } else if (res.code === "607") {
    //           window.location.href = "/logout";
    //         }
    //       });
    //     }
    //   });

    //   const floatingCraneID = this.props.floatingCraneID;
    //   var data = [];
    //   await api
    //     .getNewFloatingCraneCharges(loginUserID, idToken, floatingCraneID)
    //     .then((response) => {
    //       if (response.status >= 200 && response.status < 300) {
    //         response.json().then((res) => {
    //           data = res.floating_crane_vendor;
    //           if (res.code == "200") {
    //             this.setState({
    //               floating_crane_vendor: res.floating_crane_vendor,
    //               isLoading: false,
    //             });
    //           }
    //         });
    //       }
    //     });

    //   // await api
    //   //   .get_mv_pur_fin_floating_crane_charges(
    //   //     loginUserID,
    //   //     idToken,
    //   //     businessNoID
    //   //   )
    //   //   .then((response) => {
    //   //     if (response.status >= 200 && response.status < 300) {
    //   //       response.json().then((res) => {
    //   //         if (res.code === "200") {
    //   //           if (res.floating_crane_vendor) {
    //   //             var floating_crane_available =
    //   //               res.floating_crane_vendor.filter(
    //   //                 (e, idx) =>
    //   //                   e.floating_crane_vendor_id ===
    //   //                   data[0].floating_crane_vendor_id
    //   //               );
    //   //             res.floating_crane_vendor = floating_crane_available;
    //   //             res.floating_crane_vendor[0].floating_crane_charges = data;
    //   //             if (res.floating_crane_vendor.length > 0) {
    //   //               var motherVesselID =
    //   //                 res.floating_crane_vendor[0].mother_vessel_id;
    //   //               if (motherVesselID) {
    //   //                 api
    //   //                   .getBusinessNoOpMVDetails(
    //   //                     motherVesselID,
    //   //                     loginUserID,
    //   //                     idToken
    //   //                   )
    //   //                   .then((response) => {
    //   //                     if (response.status >= 200 && response.status < 300) {
    //   //                       response.json().then((mv_res) => {
    //   //                         if (mv_res.business_no_MV) {
    //   //                           const filter_floating_crane_ids = mv_res
    //   //                             .business_no_MV.floating_crane_vendor
    //   //                             ? mv_res.business_no_MV.floating_crane_vendor.split(
    //   //                                 ","
    //   //                               )
    //   //                             : [];
    //   //                           if (filter_floating_crane_ids.length === 0) {
    //   //                             this.setState({
    //   //                               errorMsg:
    //   //                                 "No Floating Crane Vendors available for this business number",
    //   //                               snackBarErrorOpen: true,
    //   //                             });
    //   //                           }
    //   //                           const filter_available_floating =
    //   //                             res.floating_crane_vendor.filter((e, idx) =>
    //   //                               filter_floating_crane_ids.find(
    //   //                                 (i, index) =>
    //   //                                   i === e.floating_crane_vendor_id
    //   //                               )
    //   //                             );
    //   //                           const filter_floating_crane_vendor_names =
    //   //                             vendors.filter((e, idx) =>
    //   //                               filter_floating_crane_ids.find(
    //   //                                 (v, index) => v === e.id
    //   //                               )
    //   //                             );

    //   //                           var vendorData = [
    //   //                             <MenuItem value="0">Please Select</MenuItem>,
    //   //                           ];
    //   //                           for (var i in filter_floating_crane_vendor_names) {
    //   //                             vendorData.push(
    //   //                               <MenuItem
    //   //                                 value={
    //   //                                   filter_floating_crane_vendor_names[i].id
    //   //                                 }
    //   //                               >
    //   //                                 {
    //   //                                   filter_floating_crane_vendor_names[i]
    //   //                                     .vendor_name
    //   //                                 }
    //   //                               </MenuItem>
    //   //                             );
    //   //                           }
    //   //                           console.log(
    //   //                             "filter_available_floating",
    //   //                             filter_available_floating
    //   //                           );
    //   //                           var row = filter_available_floating;
    //   //                           for (let i of row) {
    //   //                             if (
    //   //                               ((config.company === "SRPL" ||
    //   //                                 config.company === "AVS" ||
    //   //                                 config.company === "PRPL") &&
    //   //                                 i.billing_currency === "IDR") ||
    //   //                               ((config.company === "SDAM" ||
    //   //                                 config.company === "SRK" ||
    //   //                                 config.company === "BTR") &&
    //   //                                 i.billing_currency === "USD")
    //   //                             ) {
    //   //                               i.pnbp_company_to_billing = (
    //   //                                 Number(i.quantity) * Number(i.pnbp_per_mt)
    //   //                               ).toFixed(2);
    //   //                               i.jasa_pbm_company_to_billing = (
    //   //                                 (Number(i.quantity) *
    //   //                                   Number(i.jasa_pbm_pmt)) /
    //   //                                 100
    //   //                               ).toFixed(2);
    //   //                             } else {
    //   //                               i.pnbp_company_to_billing = "";
    //   //                               i.jasa_pbm_company_to_billing = "";
    //   //                             }
    //   //                           }
    //   //                           this.setState({
    //   //                             motherVesselID: motherVesselID,
    //   //                             vessel_type: mv_res.business_no_MV.vessel_type
    //   //                               ? mv_res.business_no_MV.vessel_type
    //   //                               : "",
    //   //                             vendorData: vendorData,
    //   //                             vendors: filter_floating_crane_vendor_names,
    //   //                             vessel_final_quantity_in_mt: mv_res
    //   //                               .business_no_MV.vessel_final_quantity_in_mt
    //   //                               ? mv_res.business_no_MV
    //   //                                   .vessel_final_quantity_in_mt
    //   //                               : "",
    //   //                             quantity_in_mt: mv_res.business_no_MV
    //   //                               .vessel_final_quantity_in_mt
    //   //                               ? mv_res.business_no_MV
    //   //                                   .vessel_final_quantity_in_mt
    //   //                               : "",
    //   //                             floating_crane_addl_days:
    //   //                               mv_res.business_no_MV
    //   //                                 .floating_crane_additional_days === null
    //   //                                 ? "0"
    //   //                                 : mv_res.business_no_MV
    //   //                                     .floating_crane_additional_days,
    //   //                             floating_crane_vendor: row,
    //   //                             costing_no: row[0]?.costing_no,
    //   //                             invoice_file: row[0]?.invoice_file,
    //   //                             files: row[0]?.files,
    //   //                           });
    //   //                         }
    //   //                       });
    //   //                     }
    //   //                   });
    //   //               } else {
    //   //                 alert("Invalid mother vessel id");
    //   //               }

    //   //               this.setState({
    //   //                 //floating_crane_vendor: row,
    //   //                 available_floating_crane_vendors_data:
    //   //                   res.floating_crane_vendor,
    //   //                 isLoading: false,
    //   //               });
    //   //             } else if (res.floating_crane_vendor.length <= 0) {
    //   //               this.setState(
    //   //                 {
    //   //                   errorMsg: "No Purchase Financial Data Available",
    //   //                   snackBarErrorOpen: true,
    //   //                   isLoading: false,
    //   //                 },
    //   //                 () => {
    //   //                   window.location.href =
    //   //                     "/view-business/" + btoa(businessNoID);
    //   //                 }
    //   //               );
    //   //             }
    //   //           } else {
    //   //             alert(
    //   //               "Unexpected error occured. Please contact administrator."
    //   //             );
    //   //           }
    //   //         }
    //   //       });
    //   //     }
    //   //   });
    // } else {
    //   this.setState(
    //     {
    //       snackBarErrorOpen: true,
    //       errorMsg: "Invalid business no.",
    //     },
    //     () => {
    //       setTimeout(() => {
    //         window.location.href = "/business-number-list";
    //       }, 2000);
    //     }
    //   );
    // }
  }

  FlotingCraneCalculationHandler = (idx) => {
    var row = this.state.floating_crane_vendor;
    let currency_exchange_rate = row[idx].currency_exchange_rate
      ? row[idx].currency_exchange_rate
      : 1;
    var total = (
      (Number(row[idx].quantity) * Number(row[idx].floating_crane_price_pmt)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    if (
      (config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
      row[idx].billing_currency === "USD"
    ) {
      currency_exchange_rate = 1;
    } else if (
      (config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
      row[idx].billing_currency === "IDR"
    ) {
      currency_exchange_rate = 1;
    }
    //let local_currency = '';
    var floating_crane_base_price = (
      (Number(row[idx].quantity) * Number(row[idx].floating_crane_price_pmt)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    var floating_crane_addl_base_price = (
      (Number(row[idx].floating_crane_price_per_addl_day) *
        Number(row[idx].floating_crane_addl_days)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    var addl_total = (
      (Number(row[idx].floating_crane_price_per_addl_day) *
        Number(row[idx].floating_crane_addl_days)) /
      Number(currency_exchange_rate)
    ).toFixed(4);
    let floating_crane_vat_percent = row[idx].floating_crane_vat_percent ? row[idx].floating_crane_vat_percent : 10;
    let floating_crane_pph23_percent = row[idx].floating_crane_pph23_percent ? row[idx].floating_crane_pph23_percent : 2;

    let inclusiveVatConstraint = 100 + Number(floating_crane_vat_percent);
    if (row[idx].vat_applicability === "Exclusive") {

      let floating_crane_vat_value = (
        (Number(floating_crane_base_price) * floating_crane_vat_percent) /
        100
      ).toFixed(2);
      let floating_crane_total = (
        Number(floating_crane_base_price) + Number(floating_crane_vat_value)
      ).toFixed(2);
      let floating_crane_pph23_value = (
        (Number(floating_crane_base_price) * floating_crane_pph23_percent) /
        100
      ).toFixed(2);
      let floating_crane_payable_to_vendor = (
        Number(floating_crane_base_price) +
        Number(floating_crane_vat_value) -
        Number(floating_crane_pph23_value)
      ).toFixed(2);
      let floating_crane_cost_to_company = floating_crane_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        floating_crane_payable_to_vendor = (
          Number(floating_crane_base_price) + Number(floating_crane_vat_value)
        ).toFixed(2);
        floating_crane_cost_to_company = floating_crane_total;
        floating_crane_pph23_value = "";
      }

      row[idx].floating_crane_base_price = floating_crane_base_price;
      row[idx].floating_crane_vat_percent = floating_crane_vat_percent;
      row[idx].floating_crane_vat_value = floating_crane_vat_value;
      row[idx].floating_crane_total = floating_crane_total;
      row[idx].floating_crane_pph23_value = floating_crane_pph23_value;
      row[idx].floating_crane_payable_to_vendor =
        floating_crane_payable_to_vendor;
      row[idx].floating_crane_cost_to_company = floating_crane_cost_to_company;

      this.setState({
        floating_crane_vendor: row,
      });
    } else if (row[idx].vat_applicability === "Inclusive") {
      let floating_crane_base_price = ((Number(total) * 100) / inclusiveVatConstraint).toFixed(2);
      let floating_crane_vat_value = (
        (Number(floating_crane_base_price) * floating_crane_vat_percent) /
        100
      ).toFixed(2);
      let floating_crane_pph23_value = (
        (Number(floating_crane_base_price) * floating_crane_pph23_percent) /
        100
      ).toFixed(2);
      let floating_crane_total = (
        Number(floating_crane_base_price) + Number(floating_crane_vat_value)
      ).toFixed(2);
      let floating_crane_payable_to_vendor = (
        Number(floating_crane_base_price) +
        Number(floating_crane_vat_value) -
        Number(floating_crane_pph23_value)
      ).toFixed(2);
      let floating_crane_cost_to_company = floating_crane_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        floating_crane_payable_to_vendor = floating_crane_total;
        floating_crane_cost_to_company = floating_crane_total;
        floating_crane_pph23_value = "";
      }
      row[idx].floating_crane_base_price = floating_crane_base_price;
      row[idx].floating_crane_vat_percent = floating_crane_vat_percent;
      row[idx].floating_crane_vat_value = floating_crane_vat_value;
      row[idx].floating_crane_total = floating_crane_total;
      row[idx].floating_crane_pph23_value = floating_crane_pph23_value;
      row[idx].floating_crane_payable_to_vendor =
        floating_crane_payable_to_vendor;
      row[idx].floating_crane_cost_to_company = floating_crane_cost_to_company;
      this.setState({
        floating_crane_vendor: row,
      });
    }
    let floating_crane_addl_vat_percent = row[idx].floating_crane_addl_vat_percent ? row[idx].floating_crane_addl_vat_percent : 10;
    let floating_crane_addl_pph23_percent = row[idx].floating_crane_addl_pph23_percent ? row[idx].floating_crane_addl_pph23_percent : 2;

    let inclusiveAdlVatConstraint = 100 + floating_crane_addl_vat_percent;
    if (row[idx].addl_day_vat_applicability === "Exclusive") {
      // floating_crane Additional Days Price Calculations
      let floating_crane_addl_vat_value = (
        (Number(floating_crane_addl_base_price) * floating_crane_addl_vat_percent) /
        100
      ).toFixed(2);
      let floating_crane_addl_total = (
        Number(floating_crane_addl_base_price) +
        Number(floating_crane_addl_vat_value)
      ).toFixed(2);
      let floating_crane_addl_pph23_value = (
        (Number(floating_crane_addl_base_price) * floating_crane_addl_pph23_percent) /
        100
      ).toFixed(2);
      let floating_crane_addl_payable_to_vendor = (
        Number(floating_crane_addl_base_price) +
        Number(floating_crane_addl_vat_value) -
        Number(floating_crane_addl_pph23_value)
      ).toFixed(2);
      let floating_crane_addl_cost_to_company = floating_crane_addl_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        floating_crane_addl_payable_to_vendor = (
          Number(floating_crane_addl_base_price) +
          Number(floating_crane_addl_vat_value)
        ).toFixed(2);
        floating_crane_addl_cost_to_company = floating_crane_addl_total;
        floating_crane_addl_pph23_value = "";
      }
      row[idx].floating_crane_addl_base_price = floating_crane_addl_base_price;
      row[idx].floating_crane_addl_vat_percent =
        floating_crane_addl_vat_percent;
      row[idx].floating_crane_addl_vat_value = floating_crane_addl_vat_value;
      row[idx].floating_crane_addl_total = floating_crane_addl_total;
      row[idx].floating_crane_addl_pph23_value =
        floating_crane_addl_pph23_value;
      row[idx].floating_crane_addl_payable_to_vendor =
        floating_crane_addl_payable_to_vendor;
      row[idx].floating_crane_addl_cost_to_company =
        floating_crane_addl_cost_to_company;
      this.setState({
        floating_crane_vendor: row,
      });
    } else if (row[idx].addl_day_vat_applicability === "Inclusive") {
      // floating_crane Additional Days Price Calculations
      let floating_crane_addl_base_price = (
        (Number(addl_total) * 100) /
        inclusiveAdlVatConstraint
      ).toFixed(2);
      let floating_crane_addl_vat_value = (
        (Number(floating_crane_addl_base_price) * floating_crane_addl_vat_percent) /
        100
      ).toFixed(2);
      let floating_crane_addl_pph23_value = (
        (Number(floating_crane_addl_base_price) * floating_crane_addl_pph23_percent) /
        100
      ).toFixed(2);
      let floating_crane_addl_total = (
        Number(floating_crane_addl_base_price) +
        Number(floating_crane_addl_vat_value)
      ).toFixed(2);
      let floating_crane_addl_payable_to_vendor = (
        Number(floating_crane_addl_total) +
        Number(floating_crane_addl_vat_value) -
        Number(floating_crane_addl_pph23_value)
      ).toFixed(2);
      let floating_crane_addl_cost_to_company = floating_crane_addl_total;
      if (
        config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL"
      ) {
        floating_crane_addl_payable_to_vendor = floating_crane_addl_total;
        floating_crane_addl_cost_to_company = floating_crane_addl_total;
        floating_crane_addl_pph23_value = "";
      }
      row[idx].floating_crane_addl_base_price = floating_crane_addl_base_price;
      row[idx].floating_crane_addl_vat_percent =
        floating_crane_addl_vat_percent;
      row[idx].floating_crane_addl_vat_value = floating_crane_addl_vat_value;
      row[idx].floating_crane_addl_total = floating_crane_addl_total;
      row[idx].floating_crane_addl_pph23_value =
        floating_crane_addl_pph23_value;
      row[idx].floating_crane_addl_payable_to_vendor =
        floating_crane_addl_payable_to_vendor;
      row[idx].floating_crane_addl_cost_to_company =
        floating_crane_addl_cost_to_company;
      this.setState({
        floating_crane_vendor: row,
      });
    }

    let pnbp_per_mt = row[idx].pnbp_per_mt ? row[idx].pnbp_per_mt : 0;
    let pnbp = (
      (Number(row[idx].quantity) * Number(pnbp_per_mt)) /
      Number(currency_exchange_rate)
    ).toFixed(2);

    row[idx].pnbp = pnbp;
    this.setState({
      floating_crane_vendor: row,
    });
    let jasa_pbm_pmt = "";
    if (row[idx].jasa_pbm_calculation === "Per MT") {
      jasa_pbm_pmt = row[idx].jasa_pbm_pmt ? row[idx].jasa_pbm_pmt : 0;
      //let jasa_pbm_local_currency = this.state.jasa_pbm_local_currency ? this.state.jasa_pbm_local_currency : 1;
      let jasa_pbm_value = (
        (Number(row[idx].quantity) * Number(jasa_pbm_pmt)) /
        100 /
        Number(currency_exchange_rate)
      ).toFixed(2);

      row[idx].jasa_pbm_value = jasa_pbm_value;
      this.setState({
        floating_crane_vendor: row,
      });
    }

    if (
      ((config.company === "SRPL" ||
        config.company === "AVS" ||
        config.company === "PRPL") &&
        row[idx].billing_currency === "IDR") ||
      ((config.company === "SDAM" ||
        config.company === "SRK" ||
        config.company === "BTR") &&
        row[idx].billing_currency === "USD")
    ) {
      row[idx].pnbp_company_to_billing = (
        Number(row[idx].quantity) * Number(pnbp_per_mt)
      ).toFixed(2);
      row[idx].jasa_pbm_company_to_billing = (
        (Number(row[idx].quantity) * Number(jasa_pbm_pmt)) /
        100
      ).toFixed(2);
    } else {
      row[idx].pnbp_company_to_billing = "";
      row[idx].jasa_pbm_company_to_billing = "";
    }
    // Waiting Period Calculation
    let wait_period_people = row[idx].wait_period_people;
    let wait_period_hours = row[idx].wait_period_hours;
    let wait_period_price_per_hour = row[idx].wait_period_price_per_hour;
    //let wait_period_local_currency = row[idx].wait_period_local_currency;
    let wait_period_total_price = (
      (Number(wait_period_people) *
        Number(wait_period_hours) *
        Number(wait_period_price_per_hour)) /
      Number(currency_exchange_rate)
    ).toFixed(2);
    row[idx].wait_period_total_price = wait_period_total_price;
    this.setState({
      floating_crane_vendor: row,
    });
  };

  removeRowsHandler = (idx, index) => () => {
    const row = this.state.floating_crane_vendor[idx].others;
    row.splice(index, 1);
    this.setState({
      floating_crane_vendor: this.state.floating_crane_vendor,
    });
  };

  addMoreRowsHandler = (idx) => {
    const item = { description: "", price: "", remarks: "" };
    var otherRows = this.state.floating_crane_vendor[idx].others;
    let data = [...otherRows, item];
    //this.state.purchaseTypeRows[idx].purchase_types = data;
    let newState = Object.assign({}, this.state);
    newState.floating_crane_vendor[idx].others = data;
    this.setState({
      newState,
    });
  };

  submitHandler = () => {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    try {
      var businessNoID = this.props.businessNoID;
    } catch (e) {
      window.location.href = "/business-number-list";
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var files_data = this.state.files;
    for (var obj of files_data) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: files_data,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        isLoading: false,
        errorMsg: "Please Select file.",
      });
      return;
    }
    var existing_file_ids = [];
    const existing_files = files_data.filter((e) => e.id);
    for (var j of existing_files) {
      existing_file_ids.push(j.id);
    }
    const new_files = files_data.filter((e) => !e.id);
    const content_files = new_files.filter((e) => e.file_content !== "");
    const costing_file = this.state.costing_files?.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );

    var floating_crane_vendor = this.state.floating_crane_vendor;
    var finalData = floating_crane_vendor[0];
    finalData.floating_crane_id = this.props.floatingCraneID;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.existing_file_ids = existing_file_ids;
    finalData.files = content_files;
    finalData["costing_files"] = costing_file;

    if (floating_crane_vendor[0].floating_crane_vendor_id === "0") {
      alert("Please select vendor");
    } else {
      if (businessNoID) {
        api.update_mv_floating_crane_charges(finalData).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then((res) => {
              if (res.code === "200") {
                if (businessNoID) {
                  this.setState(
                    {
                      snackBarSuccessOpen: true,
                      successMsg: res.message,
                    },
                    () => {
                      setTimeout(() => {
                        this.props.onUpdateCosting({
                          screen: "MvFloatingCrane",
                          code: res.code,
                          message: "",
                        });
                      }, 2000);
                    }
                  );
                  //window.location.href = "/mv-purchase-view-floating-crane/" + btoa(businessNoID);
                }
              } else if (res.code === "601") {
                this.setState({
                  errorMsg: res.message,
                  snackBarErrorOpen: true,
                });
              } else if (res.code === "607") {
                window.location.href = "/logout";
              } else if (res.code === "624") {
                this.setState({
                  errorMsg: res.columns.length > 0 ? res.columns[0] : "",
                  snackBarErrorOpen: true,
                });
              }
            });
          }
        });
      } else {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Invalid business no.",
        });
      }
    }
  };

  render() {
    const pr = /^\d*(\.\d{0,4})?$/;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div
          className="container bg-white p-0"
          style={{ minHeight: "100vh", width: 850 }}
        >
          <div className="row clearfix content-header border-bottom">
            <div className="col-lg-12 pl-0">
              <h4
                style={{ padding: "10px 20px" }}
              >{`Floating Crane Charges - ${this.state.costing_no}`}</h4>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              <div
                className="card-body p-0"
                style={{ backgroundColor: "rgba(25,118,210,.01)" }}
                id="accordion"
              >
                {!this.props.updateDocuments && (
                  <div>
                    {this.state.floating_crane_vendor.map((p, index) => (
                      <div className="row" key={index}>
                        <div className="row col-lg-12 p-0">
                          <h5>{p.floating_crane_vendor_name}</h5>
                        </div>
                        <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                          <div className="row mb-3 ">
                            <div className="col-lg-6 pl-0">
                              <div className="row">
                                <div className="col-lg-12 p-0">
                                  <label className="form_label mb-0">
                                    Type
                                  </label>
                                  <RadioGroup
                                    aria-label="floating_crane_type"
                                    name="floating_crane_type"
                                    onChange={(e) => {
                                      var row =
                                        this.state.floating_crane_vendor;
                                      row[index].floating_crane_type =
                                        e.target.value;
                                      this.setState({
                                        floating_crane_vendor: row,
                                      });
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Floating Crane"
                                      control={
                                        <NewRadio
                                          checked={
                                            p.floating_crane_type ===
                                            "Floating Crane"
                                          }
                                        />
                                      }
                                      label="Floating Crane"
                                    />
                                    <FormControlLabel
                                      value="FLF"
                                      control={
                                        <NewRadio
                                          checked={
                                            p.floating_crane_type === "FLF"
                                          }
                                        />
                                      }
                                      label="FLF"
                                    />
                                  </RadioGroup>
                                </div>
                                <div className="col-lg-6 p-0">
                                  <label className="form_label mb-0">
                                    Quantity in MT
                                  </label>
                                  <TextField
                                    name="quantity_in_mt"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={p.quantity}
                                    fullWidth
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      const quan = /^\d*(\.\d{0,3})?$/;
                                      if (
                                        e.target.value === "" ||
                                        quan.test(e.target.value)
                                      ) {
                                        var row =
                                          this.state.floating_crane_vendor;
                                        row[index].quantity = e.target.value;
                                        this.setState(
                                          {
                                            floating_crane_vendor: row,
                                          },
                                          () =>
                                            this.FlotingCraneCalculationHandler(
                                              index
                                            )
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </div>

                                <div className="col-lg-6 pr-0">
                                  <label className="form_label mb-0">
                                    Billing Currency
                                  </label>
                                  <TextField
                                    name="billing_currency"
                                    placeholder="Billing Currency"
                                    margin="dense"
                                    variant="outlined"
                                    value={p.billing_currency}
                                    onChange={(e) => {
                                      var row =
                                        this.state.floating_crane_vendor;
                                      row[index].billing_currency =
                                        e.target.value;
                                      if (
                                        (config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL") &&
                                        row[index].billing_currency === "USD"
                                      ) {
                                        row[index].currency_exchange_rate = "";
                                      }
                                      if (
                                        (config.company === "SDAM" ||
                                          config.company === "SRK" ||
                                          config.company === "BTR") &&
                                        row[index].billing_currency === "IDR"
                                      ) {
                                        row[index].currency_exchange_rate = "";
                                      }
                                      this.setState(
                                        {
                                          floating_crane_vendor: row,
                                        },
                                        () => {
                                          this.FlotingCraneCalculationHandler(
                                            index
                                          );
                                        }
                                      );
                                    }}
                                    fullWidth
                                    select
                                    inputProps={{
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  >
                                    <MenuItem value="0">Please Select</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                    <MenuItem value="IDR">IDR</MenuItem>
                                  </TextField>
                                </div>

                                <div className="col-lg-6 p-0">
                                  <label className="form_label mb-0">
                                    Price Per MT
                                  </label>
                                  <TextField
                                    name="floating_crane_price_pmt"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={p.floating_crane_price_pmt}
                                    fullWidth
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row =
                                          this.state.floating_crane_vendor;
                                        row[index].floating_crane_price_pmt =
                                          e.target.value;
                                        this.setState(
                                          {
                                            floating_crane_price_pmt:
                                              e.target.value,
                                            floating_crane_base_price: (
                                              Number(
                                                this.state.quantity_in_mt
                                              ) * Number(e.target.value)
                                            ).toFixed(4),
                                            basePrice: (
                                              Number(
                                                this.state.quantity_in_mt
                                              ) * Number(e.target.value)
                                            ).toFixed(4),
                                            floating_crane_vendor: row,
                                          },
                                          () =>
                                            this.FlotingCraneCalculationHandler(
                                              index
                                            )
                                        );
                                      }
                                    }}
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          position="end"
                                          style={{ backgroundColor: "#f7f3f0" }}
                                        >
                                          {p.billing_currency !== "0"
                                            ? p.billing_currency
                                            : ""}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                </div>
                                {(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                  p.billing_currency !== "USD" &&
                                  p.billing_currency !== "0" && (
                                    <div className="col-lg-6 pr-0">
                                      <label className="form_label mb-0">
                                        Exchange Rate
                                      </label>
                                      <TextField
                                        name="currency_exchange_rate"
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        value={p.currency_exchange_rate}
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Enter Value"
                                        onChange={(e) => {
                                          const cr = /^\d*(\.\d{0,10})?$/;
                                          if (
                                            e.target.value === "" ||
                                            cr.test(e.target.value)
                                          ) {
                                            var row =
                                              this.state.floating_crane_vendor;
                                            row[index].currency_exchange_rate =
                                              e.target.value;
                                            this.setState(
                                              {
                                                floating_crane_vendor: row,
                                              },
                                              () =>
                                                this.FlotingCraneCalculationHandler(
                                                  index
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}

                                {(config.company === "SDAM" ||
                                  config.company === "SRK" ||
                                  config.company === "BTR") &&
                                  p.billing_currency !== "IDR" &&
                                  p.billing_currency !== "0" && (
                                    <div className="col-lg-6 pr-0">
                                      <label className="form_label mb-0">
                                        Exchange Rate
                                      </label>
                                      <TextField
                                        name="currency_exchange_rate"
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        value={p.currency_exchange_rate}
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Enter Value"
                                        onChange={(e) => {
                                          const cr = /^\d*(\.\d{0,10})?$/;
                                          if (
                                            e.target.value === "" ||
                                            cr.test(e.target.value)
                                          ) {
                                            var row =
                                              this.state.floating_crane_vendor;
                                            row[index].currency_exchange_rate =
                                              e.target.value;
                                            this.setState(
                                              {
                                                floating_crane_vendor: row,
                                              },
                                              () =>
                                                this.FlotingCraneCalculationHandler(
                                                  index
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  )}
                                <div className="col-lg-12  p-0">
                                  <label className="form_label mb-0">
                                    VAT Applicability
                                  </label>
                                  <RadioGroup
                                    aria-label="vat_applicability"
                                    name="vat_applicability"
                                    onChange={(e) => {
                                      var row =
                                        this.state.floating_crane_vendor;
                                      row[index].vat_applicability =
                                        e.target.value;
                                      this.setState(
                                        {
                                          floating_crane_vendor: row,
                                        },
                                        () => {
                                          this.FlotingCraneCalculationHandler(
                                            index
                                          );
                                        }
                                      );
                                    }}
                                    style={{ flexDirection: "row" }}
                                  >
                                    <FormControlLabel
                                      value="Inclusive"
                                      control={
                                        <NewRadio
                                          checked={
                                            p.vat_applicability === "Inclusive"
                                          }
                                        />
                                      }
                                      label="Inclusive"
                                    />
                                    <FormControlLabel
                                      value="Exclusive"
                                      control={
                                        <NewRadio
                                          checked={
                                            p.vat_applicability === "Exclusive"
                                          }
                                        />
                                      }
                                      label="Exclusive"
                                    />
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-lg-6 p-2 rounded"
                              style={{ backgroundColor: "#f7f3f0" }}
                            >
                              <div className="row col-lg-12 p-0">
                                <h6>Floating Crane Price</h6>
                              </div>
                              <div className="row">
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Base price
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {p.floating_crane_base_price
                                      ? toLocaleString(
                                        p.floating_crane_base_price
                                      )
                                      : "-"}
                                  </div>
                                </div>
                                <div className="col-lg-4 pr-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT %
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {/* {p.floating_crane_vat_percent
                                      ? toLocaleString(
                                          p.floating_crane_vat_percent
                                        )
                                      : "-"} */}
                                    <TextField
                                      name="floating_crane_vat_percent"
                                      margin="dense"
                                      type="number"
                                      value={p.floating_crane_vat_percent}
                                      fullWidth
                                      inputProps={{
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        const cr = /^\d*(\.\d{0,10})?$/;
                                        if (
                                          e.target.value === "" ||
                                          cr.test(e.target.value)
                                        ) {
                                          var row =
                                            this.state.floating_crane_vendor;
                                          row[index].floating_crane_vat_percent =
                                            e.target.value;
                                          this.setState(
                                            {
                                              floating_crane_vat_percent: row,
                                            },
                                            () =>
                                              this.FlotingCraneCalculationHandler(
                                                index
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-4 p-0 mb-2">
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    VAT
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {p.floating_crane_vat_value
                                      ? toLocaleString(
                                        p.floating_crane_vat_value
                                      )
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                              {!(
                                config.company === "SRPL" ||
                                config.company === "AVS" ||
                                config.company === "PRPL"
                              ) && (
                                  <div className="row">
                                    <div className="col-lg-4 p-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Total
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {p.floating_crane_total
                                          ? toLocaleString(p.floating_crane_total)
                                          : "-"}
                                      </div>
                                    </div>

                                    <div className="col-lg pr-0 mb-2">
                                      <label
                                        className="contract_display_header_label"
                                        style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                      >
                                        Includes PPH23 @ 2%
                                      </label>
                                      <div
                                        className="contract_display_header_value"
                                        style={{ color: "#42352d" }}
                                      >
                                        {p.floating_crane_pph23_value
                                          ? toLocaleString(
                                            p.floating_crane_pph23_value
                                          )
                                          : "-"}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              <div className="row">
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 p-0"
                                      : "col-lg-6 p-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Payable to Vendor
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {p.floating_crane_payable_to_vendor
                                      ? toLocaleString(
                                        p.floating_crane_payable_to_vendor
                                      )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                                <div
                                  className={
                                    config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "col-lg-6 pr-0"
                                      : "col-lg-6 pr-0"
                                  }
                                >
                                  <label
                                    className="contract_display_header_label"
                                    style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                  >
                                    Cost to Company
                                  </label>
                                  <div
                                    className="contract_display_header_value"
                                    style={{ color: "#42352d" }}
                                  >
                                    {p.floating_crane_cost_to_company
                                      ? toLocaleString(
                                        p.floating_crane_cost_to_company
                                      )
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="row mb-3 ">
                            <div className="row col-lg-12 p-0">
                              <h5>Additional Charges</h5>
                            </div>
                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-6 pl-0">
                                <div className="row">
                                  <div className="col-lg-6 p-0">
                                    <label className="form_label mb-3">
                                      Additional Days
                                    </label>
                                    <div>
                                      {p.floating_crane_addl_days
                                        ? toLocaleString(
                                          p.floating_crane_addl_days
                                        )
                                        : "-"}
                                    </div>
                                  </div>

                                  <div className="col-lg-6 pr-0">
                                    <label className="form_label mb-0">
                                      Price Per Day
                                    </label>
                                    <TextField
                                      name="floating_crane_price_per_addl_day"
                                      margin="dense"
                                      variant="outlined"
                                      type="number"
                                      value={
                                        p.floating_crane_price_per_addl_day
                                      }
                                      fullWidth
                                      inputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {p.billing_currency !== "0"
                                              ? p.billing_currency
                                              : ""}
                                          </InputAdornment>
                                        ),
                                        style: {
                                          fontFamily: "Poppins",
                                          fontSize: "14px",
                                        },
                                      }}
                                      placeholder="Enter Value"
                                      onChange={(e) => {
                                        const pr = /^\d*(\.\d{0,4})?$/;
                                        if (
                                          e.target.value === "" ||
                                          pr.test(e.target.value)
                                        ) {
                                          var row =
                                            this.state.floating_crane_vendor;
                                          row[
                                            index
                                          ].floating_crane_price_per_addl_day =
                                            e.target.value;
                                          this.setState(
                                            {
                                              floating_crane_vendor:
                                                this.state
                                                  .floating_crane_vendor,
                                            },
                                            () =>
                                              this.FlotingCraneCalculationHandler(
                                                index
                                              )
                                          );
                                        }
                                      }}
                                    />
                                  </div>

                                  <div className="col-lg-12 p-0">
                                    <label className="form_label mb-0">
                                      VAT Applicability
                                    </label>
                                    <RadioGroup
                                      aria-label="addl_day_vat_applicability"
                                      name="addl_day_vat_applicability"
                                      onChange={(e) => {
                                        var row =
                                          this.state.floating_crane_vendor;
                                        row[index].addl_day_vat_applicability =
                                          e.target.value;
                                        this.setState(
                                          {
                                            floating_crane_vendor: row,
                                          },
                                          () =>
                                            this.FlotingCraneCalculationHandler(
                                              index
                                            )
                                        );
                                      }}
                                      style={{ flexDirection: "row" }}
                                    >
                                      <FormControlLabel
                                        value="Inclusive"
                                        control={
                                          <NewRadio
                                            checked={
                                              p.addl_day_vat_applicability ===
                                              "Inclusive"
                                            }
                                          />
                                        }
                                        label="Inclusive"
                                      />
                                      <FormControlLabel
                                        value="Exclusive"
                                        control={
                                          <NewRadio
                                            checked={
                                              p.addl_day_vat_applicability ===
                                              "Exclusive"
                                            }
                                          />
                                        }
                                        label="Exclusive"
                                      />
                                    </RadioGroup>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-lg-6 p-2 rounded"
                                style={{ backgroundColor: "#f7f3f0" }}
                              >
                                <div className="row col-lg-12 p-0">
                                  <h6>Floating Crane Additional Price</h6>
                                </div>
                                <div className="row">
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Base price
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {p.floating_crane_addl_base_price
                                        ? toLocaleString(
                                          p.floating_crane_addl_base_price
                                        )
                                        : "-"}
                                    </div>
                                  </div>
                                  <div className="col-lg-4 pr-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT %
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {/* {p.floating_crane_addl_vat_percent
                                        ? toLocaleString(
                                            p.floating_crane_addl_vat_percent
                                          )
                                        : "-"} */}
                                      <TextField
                                        name="floating_crane_addl_vat_percent"
                                        margin="dense"
                                        type="number"
                                        value={p.floating_crane_addl_vat_percent}
                                        fullWidth
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        placeholder="Enter Value"
                                        onChange={(e) => {
                                          const cr = /^\d*(\.\d{0,10})?$/;
                                          if (
                                            e.target.value === "" ||
                                            cr.test(e.target.value)
                                          ) {
                                            var row =
                                              this.state.floating_crane_vendor;
                                            row[index].floating_crane_addl_vat_percent =
                                              e.target.value;
                                            this.setState(
                                              {
                                                floating_crane_addl_vat_percent: row,
                                              },
                                              () =>
                                                this.FlotingCraneCalculationHandler(
                                                  index
                                                )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-4 p-0 mb-2">
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      VAT
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {p.floating_crane_addl_vat_value
                                        ? toLocaleString(
                                          p.floating_crane_addl_vat_value
                                        )
                                        : "-"}
                                    </div>
                                  </div>
                                </div>
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && (
                                    <div className="row mb-2">
                                      <div className="col-lg-4 p-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Total
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {p.floating_crane_addl_total
                                            ? toLocaleString(
                                              p.floating_crane_addl_total
                                            )
                                            : "-"}
                                        </div>
                                      </div>

                                      <div className="col-lg pr-0">
                                        <label
                                          className="contract_display_header_label"
                                          style={{
                                            color: "rgba(72, 59, 52, 0.7)",
                                          }}
                                        >
                                          Includes PPH23 @ 2%
                                        </label>
                                        <div
                                          className="contract_display_header_value"
                                          style={{ color: "#42352d" }}
                                        >
                                          {p.floating_crane_addl_pph23_value
                                            ? toLocaleString(
                                              p.floating_crane_addl_pph23_value
                                            )
                                            : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                <div className="row">
                                  <div
                                    className={
                                      config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "col-lg-6 p-0"
                                        : "col-lg-6 p-0"
                                    }
                                  >
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Payable to Vendor
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {p.floating_crane_addl_payable_to_vendor
                                        ? toLocaleString(
                                          p.floating_crane_addl_payable_to_vendor
                                        )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "col-lg-6 pr-0"
                                        : "col-lg-6 pr-0"
                                    }
                                  >
                                    <label
                                      className="contract_display_header_label"
                                      style={{ color: "rgba(72, 59, 52, 0.7)" }}
                                    >
                                      Cost to Company
                                    </label>
                                    <div
                                      className="contract_display_header_value"
                                      style={{ color: "#42352d" }}
                                    >
                                      {p.floating_crane_addl_cost_to_company
                                        ? toLocaleString(
                                          p.floating_crane_addl_cost_to_company
                                        )
                                        : 0}{" "}
                                      {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <hr />

                          <div className="row mb-3">
                            <div className="row col-lg-12 p-0">
                              <h5>PNBP Charges</h5>
                            </div>

                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-0">
                                  PNBP Per MT
                                </label>
                                <TextField
                                  name="pnbp_per_mt"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={p.pnbp_per_mt}
                                  fullWidth
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {p.billing_currency !== "0"
                                          ? p.billing_currency
                                          : ""}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  placeholder="Enter Value"
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row =
                                        this.state.floating_crane_vendor;
                                      row[index].pnbp_per_mt = e.target.value;
                                      this.setState(
                                        {
                                          floating_crane_vendor: row,
                                        },
                                        () => {
                                          this.FlotingCraneCalculationHandler(
                                            index
                                          );
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-lg-4">
                                <label className="form_label mb-3">PNBP</label>
                                <div className="">
                                  {p.pnbp ? toLocaleString(p.pnbp) : 0}{" "}
                                  {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}{" "}
                                  {p.pnbp_company_to_billing
                                    ? "(" +
                                      toLocaleString(
                                        p.pnbp_company_to_billing
                                      ) +
                                      " " +
                                      p.billing_currency +
                                      ")"
                                    : null}
                                </div>
                              </div>
                            </div>

                            <div className="row col-lg-12 p-0">
                              <div className="col-lg-4 pl-0">
                                <label className="form_label mb-0">
                                  JASA PBM Calculation
                                </label>
                                <RadioGroup
                                  aria-label="jasa_pbm_calculation"
                                  name="jasa_pbm_calculation"
                                  onChange={(e) => {
                                    var row = this.state.floating_crane_vendor;
                                    row[index].jasa_pbm_calculation =
                                      e.target.value;

                                    this.setState(
                                      {
                                        floating_crane_vendor: row,
                                      },
                                      () => {
                                        this.FlotingCraneCalculationHandler(
                                          index
                                        );
                                      }
                                    );
                                  }}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FormControlLabel
                                    value="Lumpsum"
                                    control={
                                      <NewRadio
                                        checked={
                                          p.jasa_pbm_calculation === "Lumpsum"
                                        }
                                      />
                                    }
                                    label="Lumpsum"
                                  />
                                  <FormControlLabel
                                    value="Per MT"
                                    control={
                                      <NewRadio
                                        checked={
                                          p.jasa_pbm_calculation === "Per MT"
                                        }
                                      />
                                    }
                                    label="Per MT"
                                  />
                                </RadioGroup>
                              </div>

                              {p.jasa_pbm_calculation === "Lumpsum" && (
                                <div className="col-lg-4 pl-0">
                                  <label className="form_label mb-0">
                                    JASA PBM Value
                                  </label>
                                  <TextField
                                    name="jasa_pbm_value"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={p.jasa_pbm_value}
                                    fullWidth
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {p.billing_currency !== "0"
                                            ? p.billing_currency
                                            : ""}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row =
                                          this.state.floating_crane_vendor;
                                        row[index].jasa_pbm_value =
                                          e.target.value;

                                        this.setState({
                                          floating_crane_vendor: row,
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              )}
                              {p.jasa_pbm_calculation === "Per MT" && (
                                <div className="col-lg-4 pl-0">
                                  <label className="form_label mb-0">
                                    JASA PBM Per MT
                                  </label>
                                  <TextField
                                    name="jasa_pbm_pmt"
                                    margin="dense"
                                    variant="outlined"
                                    type="number"
                                    value={p.jasa_pbm_pmt}
                                    fullWidth
                                    inputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          {p.billing_currency !== "0"
                                            ? p.billing_currency
                                            : ""}
                                        </InputAdornment>
                                      ),
                                      style: {
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                      },
                                    }}
                                    placeholder="Enter Value"
                                    onChange={(e) => {
                                      if (
                                        e.target.value === "" ||
                                        pr.test(e.target.value)
                                      ) {
                                        var row =
                                          this.state.floating_crane_vendor;
                                        row[index].jasa_pbm_pmt =
                                          e.target.value;

                                        this.setState(
                                          {
                                            floating_crane_vendor: row,
                                          },
                                          () => {
                                            this.FlotingCraneCalculationHandler(
                                              index
                                            );
                                          }
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              )}

                              {p.jasa_pbm_calculation === "Per MT" && (
                                <div className="col-lg-4 pl-0">
                                  <label className="form_label mb-3">
                                    JASA PBM Value
                                  </label>
                                  <div>
                                    {p.jasa_pbm_value
                                      ? toLocaleString(p.jasa_pbm_value)
                                      : 0}{" "}
                                    {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"}{" "}
                                    {p.jasa_pbm_company_to_billing
                                      ? "(" +
                                        toLocaleString(
                                          p.jasa_pbm_company_to_billing
                                        ) +
                                        " " +
                                        p.billing_currency +
                                        ")"
                                      : null}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div> */}

                          <hr />

                          <div className="row mb-3">
                            <div className="row col-lg-12 p-0">
                              <h5>Waiting Period</h5>
                            </div>

                            <div className="row">
                              <div className="col-lg-6 p-0 mb-2">
                                <label className="form_label mb-0">
                                  No Of People
                                </label>
                                <TextField
                                  name="wait_period_people"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={p.wait_period_people}
                                  placeholder="Enter Value"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    var row = this.state.floating_crane_vendor;
                                    row[index].wait_period_people =
                                      e.target.value;
                                    this.setState(
                                      {
                                        floating_crane_vendor: row,
                                      },
                                      () => {
                                        this.FlotingCraneCalculationHandler(
                                          index
                                        );
                                      }
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 pr-0 mb-2">
                                <label className="form_label mb-0">
                                  No Of Hours
                                </label>
                                <TextField
                                  name="wait_period_hours"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={p.wait_period_hours}
                                  placeholder="Enter Value"
                                  fullWidth
                                  inputProps={{
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    var row = this.state.floating_crane_vendor;
                                    row[index].wait_period_hours =
                                      e.target.value;
                                    this.setState(
                                      {
                                        floating_crane_vendor: row,
                                      },
                                      () => {
                                        this.FlotingCraneCalculationHandler(
                                          index
                                        );
                                      }
                                    );
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 p-0 mb-2">
                                <label className="form_label mb-0">
                                  Price Per Hour
                                </label>
                                <TextField
                                  name="wait_period_price_per_hour"
                                  margin="dense"
                                  variant="outlined"
                                  type="number"
                                  value={p.wait_period_price_per_hour}
                                  placeholder="Enter Value"
                                  fullWidth
                                  inputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {p.billing_currency !== "0"
                                          ? p.billing_currency
                                          : ""}
                                      </InputAdornment>
                                    ),
                                    style: {
                                      fontFamily: "Poppins",
                                      fontSize: "14px",
                                    },
                                  }}
                                  onChange={(e) => {
                                    if (
                                      e.target.value === "" ||
                                      pr.test(e.target.value)
                                    ) {
                                      var row =
                                        this.state.floating_crane_vendor;
                                      row[index].wait_period_price_per_hour =
                                        e.target.value;

                                      this.setState(
                                        {
                                          floating_crane_vendor: row,
                                        },
                                        () => {
                                          this.FlotingCraneCalculationHandler(
                                            index
                                          );
                                        }
                                      );
                                    }
                                  }}
                                />
                              </div>

                              <div className="col-lg-6 pr-0 mb-2">
                                <label className="form_label mb-0">
                                  Total Price
                                </label>
                                <div className="m-3">
                                  {p.wait_period_total_price
                                    ? toLocaleString(p.wait_period_total_price)
                                    : 0}{" "}
                                  {config.company === "SRPL" ||
                                    config.company === "AVS" ||
                                    config.company === "PRPL"
                                    ? "USD"
                                    : "IDR"}
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />

                          <div className="row">
                            <div className="row col-lg-12 p-0">
                              <h5>Others</h5>
                            </div>

                            {p.others.map((o, idex) => (
                              <div className="row mb-1" key={idex}>
                                <div className="col-lg-11 p-2 border">
                                  <div className="row">
                                    <div className="col-lg-6 p-0">
                                      <label className="form_label mb-0">
                                        Description
                                      </label>
                                      <TextField
                                        name="description"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={o.description}
                                        placeholder="Description"
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            textTransform: "uppercase",
                                          },
                                        }}
                                        onChange={(e) => {
                                          var rows =
                                            this.state.floating_crane_vendor[
                                              index
                                            ].others;
                                          rows[idex].description =
                                            e.target.value;
                                          this.setState({
                                            floating_crane_vendor:
                                              this.state.floating_crane_vendor,
                                          });
                                        }}
                                      />
                                    </div>

                                    <div className="col-lg-6 pr-0">
                                      <label className="form_label mb-0">
                                        Price
                                      </label>
                                      <TextField
                                        name="price"
                                        margin="dense"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        value={o.price}
                                        placeholder="Enter Value"
                                        inputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {config.company === "SRPL" ||
                                                config.company === "AVS" ||
                                                config.company === "PRPL"
                                                ? "USD"
                                                : "IDR"}
                                            </InputAdornment>
                                          ),
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                          },
                                        }}
                                        onChange={(e) => {
                                          const cr = /^\d*(\.\d{0,4})?$/;
                                          if (
                                            e.target.value === "" ||
                                            cr.test(e.target.value)
                                          ) {
                                            var rows =
                                              this.state.floating_crane_vendor[
                                                index
                                              ].others;
                                            rows[idex].price = e.target.value;
                                            this.setState({
                                              floating_crane_vendor:
                                                this.state
                                                  .floating_crane_vendor,
                                            });
                                          }
                                        }}
                                      />
                                    </div>

                                    <div className="col-lg-12 p-0">
                                      <label className="form_label mb-0">
                                        Remarks
                                      </label>
                                      <TextField
                                        name="remarks"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={3}
                                        value={o.remarks}
                                        placeholder="Remarks"
                                        inputProps={{
                                          style: {
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            textTransform: "uppercase",
                                          },
                                        }}
                                        onChange={(e) => {
                                          var rows =
                                            this.state.floating_crane_vendor[
                                              index
                                            ].others;
                                          rows[idex].remarks = e.target.value;
                                          this.setState({
                                            floating_crane_vendor:
                                              this.state.floating_crane_vendor,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {p.others.length > 1 && (
                                  <center className="col-lg-1 d-flex justify-content-center">
                                    <i
                                      className="fa fa-trash-o m-auto"
                                      onClick={this.removeRowsHandler(
                                        index,
                                        idex
                                      )}
                                      style={{
                                        fontSize: 24,
                                        color: config.themeColor,
                                        cursor: "pointer",
                                      }}
                                    />
                                  </center>
                                )}
                              </div>
                            ))}

                            <div className="row">
                              <button
                                type="button"
                                onClick={() => this.addMoreRowsHandler(index)}
                                className="header_button header_button_text addrow_button_adjustment w-auto"
                                style={{
                                  border: "none",
                                  color: config.themeColor,
                                }}
                              >
                                ADD OTHERS
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {/* {(this.state.features.includes('522')  || this.state.features.includes('97') ) && */}

                <FileUpload
                  files={this.state.files}
                  type="Costings"
                  invoice_file={this.state.invoice_file}
                  feature_name="Mv_Pur_Fin_Floating_Crane_Charges"
                  callbackFn={(data, mandatoryFiles) => {
                    this.setState({
                      files: data,
                      costing_files: mandatoryFiles,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button btn-light"
              name="submit"
              onClick={() => {
                this.props.returnCallbackViewPage("MvFloatingCrane");
              }}
            >
              Back
            </button>
            <button
              type="button"
              className="drawer_text drawer_btn_success"
              name="Back"
              onClick={this.submitHandler}
            >
              Update
            </button>
          </div>
        </div>
      );
    }
  }
}
