import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import FileUpload from "../../common/FileUpload";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import Loader from "../../common/Loader";

const CheckBox = withStyles({
  root: {
    color: "#ccc",
    "&$checked": {
      color: config.themeColor,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

var total_chemical_spray_value = 0;
export default class ChemicalSprayAdd extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      payee_name: "",
      currency_exchange_rate: "",
      local_currency: "",
      remarks: "",
      isLoading: true,
      activeStep: 0,
      barges: [],
      totalBarges: [],
      bargeList: [],
      business_no_drop_value: [],
      barge_id: null,
      billing_currency: "IDR",
      files: [
        {
          file_description: "",
          file_name: "",
          file_extension: "",
          file_content: "",
          feature_name: "Chemical_spray",
        },
      ],
      costing_files: [],
      features: [],
      vendor_drop_value: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (!(features.includes("575") || features.includes("97"))) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();

    await api.getBusinessNumbers(loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.idtoken) this.Cookie.setIdTokenCookie(res.idtoken);
            if (res.business_numbers) {
              let business_no_drop_value = [];
              for (var i of res.business_numbers) {
                business_no_drop_value.push({
                  name: i.business_no,
                  value: i.id,
                  mother_vessel_name: i.mother_vessel_name,
                });
              }
              this.setState({
                business_no_drop_value: business_no_drop_value,
                //loading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    });

    await api.getVendors(loginUserID, idToken, "Active").then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            if (res.vendors) {
              let vendor_drop_list = [];
              res.vendors.forEach((item) => {
                if (item.vendor_type === "Others") {
                  vendor_drop_list.push({
                    name: item.vendor_name,
                    value: item.id,
                  });
                }
              });
              this.setState({ vendor_drop_value: vendor_drop_list });
            }
          }
        });
      }
    });

    let financial_type = "barge_chemical_spray";
    await api
      .get_barges(loginUserID, idToken, financial_type)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                const filter_not_costing_barges = res.barges.filter(
                  (e) =>
                    e.chemical_spray === "Yes" &&
                    e.chemical_spray_costing === null &&
                    e.status === "Active"
                );
                if (filter_not_costing_barges.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                    barges: filter_not_costing_barges,
                    totalBarges: filter_not_costing_barges,
                    isLoading: false,
                  });
                } else {
                  this.setState({
                    barges: filter_not_costing_barges,
                    totalBarges: filter_not_costing_barges,
                    isLoading: false,
                  });
                }
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    document.title =
      config.documentTitle + "Barge Purchase Financial Chemical Spray";
  }

  nextStepHandler = () => {
    if (this.state.activeStep === 0) {
      if (this.state.bargeList.length === 0) {
        this.setState({
          snackBarErrorOpen: true,
          errorMsg: "Please Select Barge",
        });
        return;
      }
    }

    this.setState({
      activeStep: this.state.activeStep + 1,
    });
  };

  previousStepHandler = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };

  submitHandler = () => {
    var {
      chemical_spray_price,
      vendor_id,
      payee_name,
      currency_exchange_rate,
      local_currency,
      remarks,
      billing_currency,
      files,
      costing_files,
    } = this.state;

    if (
      config.company === "SDAM" ||
      config.company === "SRK" ||
      config.company === "BTR"
    ) {
      currency_exchange_rate = 1;
      local_currency = chemical_spray_price;
    }
    /**
     *  Checking Mandatory files
     */
    var missing_fields = 0;
    let files_missing = 0;
    var row = this.state.files;
    for (var obj of row) {
      if (obj.file_type !== "" || obj.file_content !== "") {
        if (obj.file_content !== "") {
          if (obj.file_description === "") {
            missing_fields++;
            obj.file_description_error = true;
          } else {
            obj.file_description = obj.file_description.toUpperCase();
          }
        } else if (obj.file_description !== "") {
          if (obj.file_content === "") {
            files_missing++;
          }
        }
      }
    }
    this.setState({
      files: row,
    });

    if (missing_fields > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please fill mandatory fields.",
      });
      return;
    }
    if (files_missing > 0) {
      this.setState({
        snackBarErrorOpen: true,
        errorMsg: "Please Select file.",
      });
      return;
    }
    const content_files = files.filter((e) => e.file_content !== "");
    const costing = costing_files.filter(
      (e) => e.file_content !== "" && e.column === "invoice_file"
    );
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var barge_id = this.state.bargeList.toString();
    api
      .add_barge_chemical_spray(
        loginUserID,
        idToken,
        barge_id,
        chemical_spray_price,
        vendor_id,
        payee_name,
        billing_currency,
        currency_exchange_rate,
        local_currency,
        remarks,
        content_files,
        costing
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              this.props.onAddResponse(res.code);
              this.setState({
                snackBarSuccessOpen: true,
                sucessMsg: "Costing created sucessfully.",
                errorMsg: "",
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            } else if (res.code === "624") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "Record already exist for given barge id",
              });
            }
          });
        }
      });
  };

  async searchBargeHandler() {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    let barge_name = this.state.barge_nomination;
    let jetty_name = this.state.loading_jetty;
    if (barge_name === "" || !barge_name) {
      this.setState({
        barge_name_error: true,
        snackBarErrorOpen: true,
        errorMsg: "Please Fill Mandatory Fields",
      });
      return;
    }
    let financial_type = "barge_chemical_spray";
    const get_barges = await api.get_barges(
      loginUserID,
      idToken,
      financial_type,
      barge_name,
      jetty_name
    );
    if (get_barges.status >= 200 && get_barges.status < 300) {
      get_barges.json().then((res) => {
        if (res.code === "200") {
          if (res.barges) {
            const filter_not_costing_barges = res.barges.filter(
              (e) =>
                e.chemical_spray === "Yes" && e.chemical_spray_costing === null
            );
            if (filter_not_costing_barges.length === 0) {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: "No records to display",
                barges: filter_not_costing_barges,
                isLoading: false,
              });
            } else {
              this.setState({
                barges: filter_not_costing_barges,
                isLoading: false,
              });
            }
          } else {
            alert("Unexpected error occured. Please contact administrator.");
          }
        } else if (res.code === "607") {
          window.location.href = "/logout";
        }
      });
    }
  }

  onBargesCheck(bargeId, checked, barge) {
    this.setState({ isLoading: true });
    if (checked) {
      total_chemical_spray_value += Number(barge.chemical_spray_price);
      let bargeList = [...this.state.bargeList, bargeId];
      this.setState({
        bargeList: bargeList,
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    } else {
      total_chemical_spray_value -= Number(barge.chemical_spray_price);
      var data = this.state.bargeList;
      if (data.indexOf(bargeId) >= 0) {
        let idx = data.indexOf(bargeId);
        data.splice(idx, 1);
      }
      this.setState({
        bargeList: data,
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    }
  }

  onSelectAllBarges = (checked) => {
    this.setState({ isLoading: true });
    let totalBarges = this.state.totalBarges;
    let bargeList = [];
    if (checked) {
      for (var i of totalBarges) {
        total_chemical_spray_value += Number(i.chemical_spray_price);
        bargeList.push(i.id);
        this.setState({
          bargeList: bargeList,
          chemical_spray_price: total_chemical_spray_value,
          isLoading: false,
        });
      }
    } else {
      total_chemical_spray_value = 0;
      this.setState({
        bargeList: [],
        chemical_spray_price: total_chemical_spray_value,
        isLoading: false,
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                  Barge Purchase Financial Chemical Spray
                </h4>
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.sucessMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="row mb-0 stepper">
              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 0
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Barge Selection
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 0
                        ? "#31c197"
                        : this.state.activeStep === 0
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
              </span>

              <span className="col-lg-2 stepper_icon_section stepper_icon_section_part">
                <i
                  className={
                    this.state.activeStep > 1
                      ? "fa fa-check-circle"
                      : "fa fa-check-circle-o"
                  }
                  aria-hidden="true"
                  style={{
                    fontSize: 20,
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
                <span
                  className="stepper_text"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                >
                  Costing
                </span>
                <i
                  className="fa fa-chevron-right stepper_sub_icon stepper_arrow"
                  aria-hidden="true"
                  style={{
                    color:
                      this.state.activeStep > 1
                        ? "#31c197"
                        : this.state.activeStep === 1
                        ? config.themeColor
                        : "#ccc",
                  }}
                ></i>
              </span>
            </div>

            <div className="card">
              <div className="card-body border_dashed">
                {this.state.activeStep === 0 && (
                  <div className="section_block w-100 m-0">
                    <div className="row mb-1 p-2">
                      {this.state.barges.length > 0 && (
                        <div className="table-responsive">
                          <div
                            className="table border-0"
                            style={{ overflowX: "scroll" }}
                          >
                            <table className="table table-borderless border-0">
                              <thead className="border-0">
                                <tr>
                                  <th
                                    className="p-2 text-center border-0"
                                    nowrap="true"
                                  >
                                    <CheckBox
                                      //value={this.state.totalBarges}
                                      color="primary"
                                      checked={
                                        this.state.totalBarges.length !== 0 &&
                                        this.state.bargeList.length ===
                                          this.state.totalBarges.length
                                      }
                                      //disabled={this.state.selectedPurchaseContractId === "" || p.purchase_contract_id !== this.state.selectedPurchaseContractId}
                                      // onChange={this.onBargesCheck}
                                      onChange={(e) => {
                                        this.onSelectAllBarges(
                                          e.target.checked
                                        );
                                      }}
                                      className="p-0"
                                    />
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-left table_header_barge_label text-uppercase border-0"
                                  >
                                    Barge ID
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-left table_header_barge_label text-uppercase border-0"
                                  >
                                    Barge Name
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-left table_header_barge_label text-uppercase border-0"
                                  >
                                    Jetty
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-left table_header_barge_label text-uppercase border-0"
                                  >
                                    Loading Date
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-left table_header_barge_label text-uppercase border-0"
                                  >
                                    Business No
                                  </th>
                                  <th
                                    style={{ fontSize: 16 }}
                                    nowrap="true"
                                    className="text-right table_header_barge_label text-uppercase border-0"
                                  >
                                    Cost
                                  </th>
                                </tr>
                              </thead>
                              {this.state.totalBarges.map((p, index) => (
                                <tbody key={index}>
                                  <tr className="rounded">
                                    <td className="p-0 text-center">
                                      <CheckBox
                                        value={p.id}
                                        color="primary"
                                        checked={
                                          this.state.bargeList.indexOf(p.id) >=
                                          0
                                        }
                                        //disabled={p.barge_pur_fin_id !== null}
                                        onChange={(e) => {
                                          this.onBargesCheck(
                                            e.target.value,
                                            e.target.checked,
                                            p
                                          );
                                        }}
                                      />
                                    </td>
                                    <td>{pad(p.id)}</td>
                                    <td nowrap="true">{p.barge_nomination}</td>
                                    <td nowrap="true">{p.loading_jetty}</td>
                                    <td nowrap="true">
                                      {localDateFormate(p.loading_date)}
                                    </td>
                                    <td nowrap="true">{p.business_no}</td>
                                    <td className="text-right" nowrap="true">
                                      {toLocaleString(p.chemical_spray_price)}
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {this.state.activeStep === 1 && (
                  <div className="section_block" style={{ width: "97%" }}>
                    <div style={{ width: "70%" }}>
                      <h5 style={{ padding: "10px 0px", margin: "0px" }}>
                        Add details to create costing
                      </h5>
                    </div>
                    <div className="row mb-2">
                      <div className="col-lg-6 p-0">
                        <label className="form_label mb-0">
                          Chemical Spray
                        </label>
                        <div className="mt-2">
                          {toLocaleString(this.state.chemical_spray_price) +
                            " IDR"}
                        </div>
                      </div>

                      <div className="col-lg-6 pr-0">
                        <label className="form_label mb-0">Vendor Name</label>
                        <Autocomplete
                          options={this.state.vendor_drop_value}
                          getOptionLabel={(option) => pad(option.name)}
                          renderOption={(option, { selected }) => (
                            <React.Fragment>
                              <div className="col-lg p-0 m-0">
                                {pad(option.name)}
                              </div>
                            </React.Fragment>
                          )}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({ vendor_id: value.value });
                            } else {
                              this.setState({ vendor_id: null });
                            }
                          }}
                          disableCloseOnSelect
                          name="vendor_id"
                          size="small"
                          //value={this.state.barge_value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Vendor"
                              variant="outlined"
                              style={{ top: 8 }}
                              fullWidth
                            />
                          )}
                          style={{ display: "contents" }}
                        />
                      </div>
                      {/* {this.state.vendor_id === "Others" && (
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Payee Name
                            </label>
                            <TextField
                              name="payee_name"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              placeholder="Payee Name"
                              inputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  textTransform: "uppercase",
                                },
                              }}
                              value={this.state.payee_name}
                              onChange={(e) => {
                                this.setState({
                                  payee_name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      )} */}
                    </div>
                    {(config.company === "SRPL" ||
                      config.company === "PRPL" || config.company === "AVS") && (
                      <div className="row mb-2">
                        <div className="col-lg-6 p-0">
                          <div className="row mb-2">
                            <label className="form_label mb-0">
                              Exchange rate from USD to IDR
                            </label>
                            <TextField
                              name="currency_exchange_rate"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                style: {
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                },
                              }}
                              placeholder="Exchange rate"
                              value={this.state.currency_exchange_rate}
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === "" ||
                                  re.test(e.target.value)
                                ) {
                                  var value = e.target.value;
                                  if (value === "") {
                                    value = 1;
                                  }
                                  this.setState({
                                    currency_exchange_rate: e.target.value,
                                    local_currency: (
                                      Number(
                                        this.state.chemical_spray_price
                                          ? this.state.chemical_spray_price
                                          : 1
                                      ) / Number(value)
                                    ).toFixed(2),
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 pr-0">
                          <label className="form_label mb-0">
                            Chemical Spray
                          </label>
                          <div className="mt-3">
                            {toLocaleString(this.state.local_currency)}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row mb-2">
                      <div className="col-lg p-0">
                        <div className="row mb-2">
                          <label className="form_label mb-0">Remarks</label>
                          <TextField
                            name="remarks"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            placeholder="Remarks"
                            inputProps={{
                              style: {
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                textTransform: "uppercase",
                              },
                            }}
                            value={this.state.remarks}
                            onChange={(e) => {
                              this.setState({
                                remarks: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* {(this.state.features.indexOf("367") >= 0 ||
                      this.state.features.indexOf("97") >= 0) && ( */}
                    <FileUpload
                      files={this.state.files}
                      type="Costings"
                      feature_name="Chemical_spray"
                      callbackFn={(data, mandatoryFiles) => {
                        this.setState({
                          files: data,
                          costing_files: mandatoryFiles,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row bottom_buttons_section">
            <button
              type="button"
              className="btn previous_button"
              name="previous"
              onClick={
                this.state.activeStep === 0
                  ? () => this.props.onCancel(false)
                  : this.previousStepHandler
              }
            >
              {this.state.activeStep === 0 ? "CANCEL" : "PREVIOUS"}
            </button>
            <button
              type="button"
              className="btn next_button"
              name="next"
              onClick={
                this.state.activeStep === 1
                  ? this.submitHandler
                  : this.nextStepHandler
              }
            >
              {this.state.activeStep === 1 ? "SUBMIT" : "NEXT STEP"}
            </button>
          </div>
        </div>
      );
    }
  }
}
