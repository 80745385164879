import React, { Component } from "react";

import { Snackbar, Drawer } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import api from "../../../api/api";

// import mother vessel Coasl Procurement Charges
import OtherExpenseAdd from "./Other-Expenses-Add";
import OtherExpenseView from "./Other-Expenses-View";
import OtherExpenseUpdate from "./Other-Expenses-Update";
import { localDateFormate } from "../../common/common";
import Loader from "../../common/Loader";

export default class Other_Expenses_List extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      OtherExpensesData: [],
      isLoading: true,
      stevedor_price_payments: [],
      features: [],
      closeDialog: false,
      deletePaymentDialog: false,
      updateDocuments: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (
        !(
          features.includes("294") ||
          features.includes("295") ||
          features.includes("296") ||
          features.includes("297") ||
          features.includes("97")
        )
      ) {
        window.location.href = "/dashboard";
      }
      const loginUserID = this.Cookie.getCookie("loginUserId");
      const idToken = this.Cookie.getIdTokenCookie();
      try {
        var businessNoID = this.props.businessNoID;
      } catch (e) {
        window.location.href = "/business-number-list";
      }
      //Get businessNumber API based on businessNumber
      const getBusinessNumber = await api.getBusinessNumber(
        businessNoID,
        loginUserID,
        idToken
      );
      if (getBusinessNumber.status >= 200 && getBusinessNumber.status < 300) {
        getBusinessNumber.json().then((res) => {
          if (res.code === "200") {
            if (res.business_number) {
              this.setState({
                // businessNo: res.business_number.business_no ? res.business_number.business_no : '',
                mother_vessel_name: res.business_number.mother_vessel_name
                  ? res.business_number.mother_vessel_name
                  : "",
              });
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      const getExpense = await api.get_business_no_other_expenses(
        loginUserID,
        idToken,
        businessNoID
      );
      if (getExpense.status >= 200 && getExpense.status < 300) {
        getExpense.json().then((res) => {
          if (res.code === "200") {
            if (res.business_no_other_expenses) {
              //var remaining_amount = 0;
              for (var i in res.business_no_other_expenses) {
                var total_paid_amout = 0;
                for (var j of res.business_no_other_expenses[i]
                  .other_expenses_payments) {
                  total_paid_amout += Number(j.amount);
                }
                res.business_no_other_expenses[i].total_paid_amout =
                  total_paid_amout;
                res.business_no_other_expenses[i].remaining_amount =
                  Number(res.business_no_other_expenses[i].amount) -
                  Number(total_paid_amout);
              }
              this.setState({
                OtherExpensesData: res.business_no_other_expenses,
                isLoading: false,
              });
            } else {
              alert("Unexpected error occured. Please contact administrator.");
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }

      document.title =
        config.documentTitle +
        "Mother Vessel Purchase Financial Coal Procurement Charges";
    }
  }

  // convert local prices and quality fields.
  toLocaleString(value) {
    return Number(value).toLocaleString();
  }

  onAddResponse = (value) => {
    if (value.code === "200") {
      this.setState(
        {
          OtherExpenseAdd: false,
          OtherExpenseView: false,
          OtherExpenseUpdate: false,
          openDrawer: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    } else {
      this.setState({
        OtherExpenseAdd: false,
        OtherExpenseView:
          value.screen === "OtherExpense" &&
          value.message === "alreadyDataAvailable"
            ? true
            : false,
        OtherExpenseUpdate: false,
        openDrawer: true,
        updateDocuments: false,
      });
    }
  };

  CallbackDrawerClose = (childData) => {
    this.setState(
      {
        openDrawer: childData,
        OtherExpenseAdd: false,
        OtherExpenseView: false,
        OtherExpenseUpdate: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  onUpdateResponse = (value) => {
    if (value) {
      this.setState(
        {
          OtherExpenseAdd: false,
          OtherExpenseView:
            value.code === "200" && value.screen === "OtherExpense"
              ? true
              : false,
          OtherExpenseUpdate: false,
          updateDocuments: false,
        },
        () => this.componentDidMount()
      );
    }
  };

  CallbackViewPage = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OtherExpenseUpdate: false,
        OtherExpenseAdd: false,
        OtherExpenseView: childData === "OtherExpense" ? true : false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  editCallback = (childData) => {
    this.setState(
      {
        openDrawer: true,
        OtherExpenseUpdate: childData === "OtherExpense" ? true : false,
        OtherExpenseAdd: false,
        OtherExpenseView: false,
        updateDocuments: false,
      },
      () => this.componentDidMount()
    );
  };

  deleteCharges = (data) => {
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    var finalData = data;
    finalData.other_expense_id = data.id;
    finalData.login_user_id = loginUserID;
    finalData.idtoken = idToken;
    finalData.status = "Deleted";
    //Delete Stevedore costing charge
    api.update_buss_no_other_expenses(finalData).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.setState(
              {
                snackBarSuccessOpen: true,
                successMsg: res.message,
              },
              () => this.componentDidMount()
            );
          } else if (res.code === "601") {
            this.setState({ errorMsg: res.message, snackBarErrorOpen: true });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          } else if (res.code === "624") {
            this.setState({
              errorMsg: res.columns.length > 0 ? res.columns[0] : "",
              snackBarErrorOpen: true,
            });
          }
        });
      }
    });
  };

  render() {
    let drawerComponent;
    if (this.state.OtherExpenseAdd) {
      drawerComponent = (
        <OtherExpenseAdd
          businessNoID={this.props.businessNoID}
          onAddResponse={this.onAddResponse}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
        />
      );
    } else if (this.state.OtherExpenseView && this.state.businessNo) {
      drawerComponent = (
        <OtherExpenseView
          OtherExpenseID={this.state.OtherExpenseID}
          businessNoID={this.state.businessNo}
          returnCallbackDrawerClose={this.CallbackDrawerClose}
          returnEditCallback={this.editCallback}
          returnDocumentsHandler={() => {
            this.setState({
              showDrawer: true,
              OtherExpenseUpdate: true,
              OtherExpenseView: false,
              OtherExpenseAdd: false,
              updateDocuments: true,
            });
          }}
        />
      );
    } else if (this.state.OtherExpenseUpdate && this.state.businessNo) {
      drawerComponent = (
        <OtherExpenseUpdate
          OtherExpenseID={this.state.OtherExpenseID}
          businessNoID={this.state.businessNo}
          onUpdateCosting={this.onUpdateResponse}
          returnCallbackViewPage={this.CallbackViewPage}
          updateDocuments={this.state.updateDocuments}
        />
      );
    }
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div>
          <div className="row clearfix content-header border-bottom">
            <div className="row clearfix content-header border-bottom">
              <h4 className="mt-2" style={{ padding: "4px" }}>
                Other Expenses - {this.state.mother_vessel_name}{" "}
                {"(" + this.state.businessNo + ")"}
              </h4>
              {(this.state.features.includes("294") ||
                this.state.features.includes("97")) && (
                <i
                  className="fa fa-plus-circle ml-2 mt-3"
                  aria-hidden="true"
                  style={{
                    fontSize: 22,
                    color: config.themeColor,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.setState({
                      openDrawer: true,
                      OtherExpenseAdd: true,
                      OtherExpenseView: false,
                      OtherExpenseUpdate: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <Drawer
            anchor="right"
            open={this.state.openDrawer}
            variant="temporary"
            elevation={20}
            style={{ overflow: "initial" }}
          >
            <div className="row" style={{ width: 800 }}>
              {drawerComponent}
            </div>
          </Drawer>
          <div className="card pl-0 pr-0">
            <div className="col-lg-12 mb-2 p-0">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead className="table-header">
                      <tr>
                        <th nowrap="true">Costing ID</th>
                        <th nowrap="true">To</th>
                        <th nowrap="true"> Date</th>
                        <th nowrap="true" className="text-right">
                          Amount
                        </th>
                        <th nowrap="true" className="text-right">
                          Currency{" "}
                        </th>
                        <th nowrap="true" className="text-right">
                          {" "}
                          Amount in{" "}
                          {config.company === "SRPL" ||
                          config.company === "AVS" ||
                          config.company === "PRPL"
                            ? "USD"
                            : "IDR"}
                        </th>
                        <th nowrap="true" className="text-left pl-4">
                          {" "}
                          Action
                        </th>
                      </tr>
                    </thead>
                    {this.state.OtherExpensesData.length > 0 ? (
                      <tbody>
                        {this.state.OtherExpensesData.map((idr, indx) => (
                          <tr key={indx}>
                            <td className="allocation_table_value">
                              {(this.state.features.includes("295") ||
                                this.state.features.includes("97")) && (
                                <i
                                  aria-hidden="true"
                                  style={{
                                    fontStyle: "normal",
                                    color: config.themeColor,
                                    cursor: "pointer",
                                    whiteSpace: "nowrap",
                                  }}
                                  onClick={() =>
                                    this.setState({
                                      openDrawer: true,
                                      OtherExpenseAdd: false,
                                      OtherExpenseView: true,
                                      OtherExpenseUpdate: false,
                                      businessNo: idr.business_no_id,
                                      OtherExpenseID: idr.id,
                                    })
                                  }
                                >
                                  {idr.costing_no}
                                </i>
                              )}
                            </td>
                            <td
                              className="allocation_table_value  text-uppercase"
                              nowrap="true"
                            >
                              {idr.vendor_name !== null
                                ? idr.vendor_name
                                : idr.payee_name}
                            </td>
                            <td
                              className="allocation_table_value"
                              nowrap="true"
                            >
                              {localDateFormate(idr.expense_date)}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.amount ? this.toLocaleString(idr.amount) : 0}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.currency}
                            </td>
                            <td
                              className="allocation_table_value text-right"
                              nowrap="true"
                            >
                              {idr.local_currency_amount
                                ? this.toLocaleString(idr.local_currency_amount)
                                : 0}
                            </td>
                            <td
                              nowrap="true"
                              className="table_td text-left pl-4"
                            >
                              {(this.state.features.includes("236") ||
                                this.state.features.includes("97")) && (
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    fontSize: 14,
                                    color: config.themeColor,
                                    cursor: "pointer",
                                  }}
                                  data-toggle="modal"
                                  title="Edit floating crane charges"
                                  data-placement="bottom"
                                  onClick={() =>
                                    this.setState({
                                      businessNo: idr.business_no_id,
                                      OtherExpenseID: idr.id,
                                      openDrawer: true,
                                      OtherExpenseUpdate: true,
                                    })
                                  }
                                ></i>
                              )}
                              {idr.payment_postings_availability === 0 &&
                                (this.state.features.includes("237") ||
                                  this.state.features.includes("97")) &&
                                idr.approval_status === "Pending" && (
                                  <i
                                    className="fa fa-trash-o"
                                    style={{
                                      paddingLeft: 10,
                                      fontSize: 14,
                                      color: config.themeColor,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => this.deleteCharges(idr)}
                                  ></i>
                                )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan="5" className="p-2">
                            No data available
                          </td>
                        </tr>{" "}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
