import React, { Component } from "react";
import SideBar from "../common/SideBar";
import Header from "../common/Header";
import api from "../../api/api";
import "../../App.css";
import CookieHandler from "../common/CookieHandler";
import config from "../../config/config";
import Loader from "../common/Loader";
export default class ViewUser extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      userId: "",
      userName: "",
      email: "",
      status: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    var loginUserID = this.Cookie.getCookie("loginUserId");
    var idToken = this.Cookie.getIdTokenCookie();
    var userID = decodeURIComponent(
      window.atob(this.props.match.params.userID)
    );
    api.getUser(userID, loginUserID, idToken).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res) {
            //this.Cookie.setIdTokenCookie(res.idtoken);TokenCookie(res.idtoken);
            this.setState({
              userId: userID,
              userName: res.user[0].user_name,
              email: res.user[0].email,
              status: res.user[0].status,
              isLoading: false,
            });
          }
        });
      } else if (response.status === 607) {
        response.json().then((res) => {
          this.setState({
            errorMessage: res.message,
            successMessage: "",
          });
        });
        setTimeout(() => {
          window.location.href = "/logout";
        }, 2000);
      }
    });
    document.title = config.documentTitle + "View User";
  }

  render() {
    return (
      <div id="wraper">
        <SideBar />
        <div className="content">
          <Header />
          <div className="content-header">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                User Details
              </h4>
            </div>
          </div>

          {this.state.isLoading && <Loader />}
          {!this.state.isLoading && (
            <div
              className="container p-3"
              style={{
                backgroundColor: "rgb(249, 249, 252)",
                boxShadow: "none",
              }}
            >
              <div
                className=""
                style={{
                  margin: 5,
                  padding: 10,
                  backgroundColor: "#fff",
                  boxShadow: "0 0 14px 0 rgba(53,64,82,.05)",
                }}
              >
                <div className="row">
                  <div className="col-25">
                    <label>User Name :</label>
                  </div>
                  <div className="col-75">
                    <label>{this.state.userName}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-25">
                    <label>Email :</label>
                  </div>
                  <div className="col-75">
                    <label>{this.state.email}</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-25">
                    <label>Status :</label>
                  </div>
                  <div className="col-75">
                    <label>{this.state.status}</label>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <a
                  href={"/update-user/" + btoa(this.state.userId)}
                  className="btn btn-info btn-responsive mr-3"
                  role="button"
                >
                  Edit
                </a>
                <a
                  href={"/user-list"}
                  className="btn btn-secondary btn-responsive"
                  role="button"
                >
                  Back
                </a>
                {/* <button type='button' className='btn btn-secondary btn-responsive' onClick={()=>{window.location.href='/user-list'}}>Back</button> */}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
