import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

export default class PreShipmentInspectionView extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      multiRows: [
        {
          sac_quantity_in_mt: "",
          sac_sampling_surveyor: "",
          sac_sampling_price_per_mt: "",
          sac_sampling_base_price: "",
          sac_sampling_vat_percent: "10",
          sac_sampling_vat_value: "",
          sac_sampling_pph23_percent: "2",
          sac_sampling_pph23_value: "",
          sac_sampling_total: "",
          sac_sampling_ctc: "",
          billing_currency: "0",
          sac_sampling_currency_xchg_rate: "",
          sac_sampling_ctc_in_local_currency: "",
        },
      ],
      barge_group_id: "",
      invoice_no: "",
      invoice_date: "",
      isLoading: true,
      activeStep: 0,
      barges: [],
      barge_id: 1,
      barge_ids: [],
      totalBarges: [],
      bargeList: [],
      files: [],
      features: [],
      shipment_costing_list: [],
      invoiceDialog: false,
      mv_name: "",
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("167") ||
        features.includes("398") ||
        features.includes("449") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    const barge_name = "";
    const jetty_name = "";
    api
      .get_barges_for_barge_pur_fin_pre_shipments(
        loginUserID,
        idToken,
        barge_name,
        jetty_name
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  shipment_costing_list: purchase_contracts_barges,
                  // isLoading: false
                });
              } else {
                alert();
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    // const comboID = this.props.comboID;
    // const barge_id =  comboID.split("$#")[0];
    const barge_id = "";
    // const pre_shipment_id = comboID;
    // if (this.props.shipment_type === "Without Barge") {
    // const  pre_shipment_id = comboID.split("$#")[1];
    const pre_shipment_id = this.props.costing_id;
    //   // barge_id = null;
    // }
    // get pre shipment inspection data
    await api
      .get_barge_pur_fin_pre_shipment(
        loginUserID,
        idToken,
        barge_id,
        pre_shipment_id
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_pur_fin_pre_shipment) {
                var paid_to_vendor = 0;
                var remaining_amount = 0;
                for (
                  var i = 0;
                  i < res.barge_pur_fin_pre_shipment.length;
                  i++
                ) {
                  if (
                    res.barge_pur_fin_pre_shipment[i].pre_shipment_payments
                      .length > 0
                  ) {
                    for (var j of res.barge_pur_fin_pre_shipment[i]
                      .pre_shipment_payments) {
                      if (j.date_of_payment) {
                        paid_to_vendor += Number(j.amount);
                      }
                      remaining_amount =
                        Number(
                          res.barge_pur_fin_pre_shipment[i].sac_sampling_total
                        ) - Number(paid_to_vendor);
                    }
                    res.barge_pur_fin_pre_shipment[i].paid_to_vendor =
                      paid_to_vendor;
                    res.barge_pur_fin_pre_shipment[i].remaining_amount =
                      remaining_amount;
                    paid_to_vendor = 0;
                    remaining_amount = 0;
                  } else {
                    res.barge_pur_fin_pre_shipment[i].remaining_amount = Number(
                      res.barge_pur_fin_pre_shipment[i].sac_sampling_total
                    );
                  }
                }
                this.setState(
                  {
                    costing_no: res.barge_pur_fin_pre_shipment[0]?.costing_no,
                    barge_group_id:
                      res.barge_pur_fin_pre_shipment[0]?.barge_group_id,
                    invoice_file:
                      res.barge_pur_fin_pre_shipment[0]?.invoice_file,
                    multiRows: res.barge_pur_fin_pre_shipment,
                    files: res.files ? res.files : [],

                    isLoading: false,
                  },
                  () => {
                    // const barge_id = this.state.barge_group_id;
                    const split_barge_id =
                      this.state.barge_group_id?.split(",")[0];

                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: split_barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });
                  }
                );
              } else {
                alert(
                  "Unexpected error occured. Please contact administrator."
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_pur_fin_pre_shipment",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      pre_shipment_payments = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_sampling_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Pre Shipment",
      payment_posting_type: "Pre_Shipment",
      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: sac_sampling_currency_xchg_rate
        ? sac_sampling_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "170",
      view_payment: "171",
      update_payment: "172",
      delete_payment: "173",
      approver: "399",
      approver2: "450",
    };

    return (
      <PaymentPostings
        payments={pre_shipment_payments}
        access_details={access_details}
        costingType="Pre Shipment Data"
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    // const comboID = this.props.comboID;
    // const pre_shipment_id = comboID.split("$#")[1];
    const pre_shipment_id = this.props.costing_id;
    const data = this.state.shipment_costing_list.map((e) =>
      e.new_barges.filter((obj) => obj.id === pre_shipment_id)
    );
    const { costing_no, billing_currency } = this.state;

    const {
      invoice_no = "",
      invoice_date = "",
      sac_quantity_in_mt = 0,
      sac_sampling_price_per_mt = 0,
      sac_sampling_currency_xchg_rate = 0,
      sac_sampling_base_price = 0,
      sac_sampling_vat_value = 0,
      sac_sampling_pph23_value = 0,
      sac_sampling_total = 0,
      sac_sampling_surveyor_name = "",
    } = this.state.multiRows[0] || {};

    const Grand_total = parseFloat(
      sac_sampling_total ? sac_sampling_total : 0
    ).toFixed(2);
    const invoiceObject = {
      costing_id: costing_no,
      to: sac_sampling_surveyor_name,
      business_no: this.state.mv_name,
      costing_name: "Pre Shipment Inspection",
      invoice_no: invoice_no,
      invoice_date: localDateFormate(invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        {
          qty: toLocaleString(sac_quantity_in_mt),
          adjustedPrice: toLocaleString(sac_sampling_price_per_mt),
          desc: `Pre Shipment ${sac_sampling_currency_xchg_rate > 1
            ? ` @ ${parseFloat(sac_sampling_currency_xchg_rate).toFixed(2)}`
            : "Pre Shipment"
            }`,
          amount: `${toLocaleString(sac_sampling_base_price)}`,
        },
        {
          qty: " ",
          adjustedPrice: " ",
          desc: "VAT",
          amount: `${toLocaleString(sac_sampling_vat_value)}`,
        },
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) && sac_sampling_pph23_value
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "PPH",
              amount: `(${toLocaleString(sac_sampling_pph23_value)})`,
            },
          ]
          : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const CostingID = this.state.costing_id;
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="clearfix content-header border-bottom">
            <div className="row">
              <div className="col-sm text-left pl-0">
                <h4
                  style={{ padding: "10px 20px", margin: "0px" }}
                >{`Pre Shipment Inspection - ${this.state.costing_no}`}</h4>
              </div>
              <div className="col-lg-1 pl-0 float-right">
                {this.renderInvoiceLink()}
              </div>
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card mt-1">
              {this.state.shipment_costing_list.length > 0 && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Barge ID</th>
                          <th nowrap="true"> Barge Nomination</th>
                          <th nowrap="true"> Business No </th>
                          <th nowrap="true"> Surveyor </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Quantity{" "}
                          </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Price PMT
                          </th>
                          <th nowrap="true" className=" text-right">
                            Total Payable
                          </th>
                          <th nowrap="true" className="text-right">
                            {" "}
                            Paid
                          </th>
                          <th nowrap="true" className=" text-right">
                            {" "}
                            Remaining
                          </th>
                        </tr>
                      </thead>
                      {this.state.shipment_costing_list.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display: CostingID === idr.id ? "" : "none",
                              }}
                            >
                              <td>
                                {idr.barge_id !== "0" ? (
                                  <a
                                    href={"/view-barge/" + btoa(idr.barge_id)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: config.themeColor }}
                                  >
                                    {pad(idr.barge_id)}
                                  </a>
                                ) : (
                                  "Without Barge"
                                )}
                              </td>
                              <td nowrap="true">
                                {idr.barge_nomination
                                  ? idr.barge_nomination
                                  : "-"}
                              </td>
                              <td nowrap="true">
                                {idr.business_no ? idr.business_no : "-"}
                              </td>
                              <td nowrap="true">
                                {idr.sac_sampling_surveyor_name
                                  ? idr.sac_sampling_surveyor_name
                                  : "-"}
                              </td>
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.barge_quantity)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.price_per_mt)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.total_payable)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.paid)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="table_td text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.Remaining_amount)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}
              <div className="col shadow-sm p-0 mb-2 bg-white border">
                {/* New Changes */}
                <div className="table-responsive">
                  <table className="table table-sm table-bordered mb-0">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Description</th>
                        <th className="text-right">Qty</th>
                        <th className="text-right">Unit Price</th>
                        <th className="text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.multiRows.map((e, index) => (
                        <>
                          <tr>
                            <td>
                              Pre Shipment Inspection
                              {e.sac_sampling_currency_xchg_rate > 1 &&
                                (config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL") &&
                                e.billing_currency !== "USD" && (
                                  <div>
                                    Exchange Rate @
                                    {parseFloat(
                                      e.sac_sampling_currency_xchg_rate
                                    ).toFixed(2)}
                                  </div>
                                )}
                            </td>
                            <td className="text-right">
                              {toLocaleString(e.sac_quantity_in_mt)}
                            </td>
                            <td className="text-right">
                              {toLocaleString(e.sac_sampling_price_per_mt)}
                            </td>
                            <td className="text-right">
                              {toLocaleString(e.sac_sampling_base_price)}
                            </td>
                          </tr>
                          {e.sac_sampling_vat_value > 0 &&
                            (this.state.vat_applicability === "Inclusive" ||
                              config.company !== "SRPL" ||
                              config.company !== "PRPL" ||
                              config.company !== "AVS") && (
                              <tr>
                                <td>
                                  VAT @{" "}
                                  {toLocaleString(e.sac_sampling_vat_percent)} %
                                </td>
                                <td className="text-right"></td>
                                <td className="text-right"></td>
                                <td className="text-right">
                                  {toLocaleString(e.sac_sampling_vat_value)}
                                </td>
                              </tr>
                            )}
                          {!(
                            config.company === "SRPL" ||
                            config.company === "AVS" ||
                            config.company === "PRPL"
                          ) &&
                            e.sac_sampling_pph23_value && (
                              <tr>
                                <td>
                                  PPH23 @{" "}
                                  {toLocaleString(e.sac_sampling_pph23_percent)}{" "}
                                  %{" "}
                                </td>
                                <td></td>
                                <td></td>
                                <td className="text-right">
                                  ({toLocaleString(e.sac_sampling_pph23_value)})
                                </td>
                              </tr>
                            )}
                          <tr>
                            <td colSpan={3} className="text-right ">
                              {" "}
                              Payable to Vendor :{" "}
                            </td>
                            <td style={{ fontWeight: "bold" }} className="text-right ">{`${config.company === "SRPL" ||
                              config.company === "PRPL" ||
                              config.company === "AVS"
                              ? "USD"
                              : "IDR"
                              } ${parseFloat(e.sac_sampling_total).toFixed(2)}`}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {this.state.multiRows.map((e, idx) => (
                <div className="row mb-2 p-3 border rounder" key={idx}>
                  <div className="col-lg-12 pl-0">
                    <div className="row">
                      {e.approval_status === "Pending" &&
                        this.Cookie.getCookie("loginUserId") !== e.created_by &&
                        e.approved_by !==
                        this.Cookie.getCookie("loginUserId") &&
                        (this.state.features.includes("398") ||
                          this.state.features.includes("449") ||
                          this.state.features.includes("97")) && (
                          <BusinessApproveButton
                            costingID={e.id}
                            costingType={"Pre Shipment Data"}
                            accessInfo={{
                              ...e,
                              approver_1: "398",
                              approver_2: "449",
                            }}
                          />
                        )}
                    </div>
                    <InvoiceForm
                      open={this.state.invoiceDialog}
                      data={e}
                      costingID={e.id}
                      callbackFn={(data) => this.updateInvoice(data)}
                    />

                    <div className="col-lg-12 p-0">
                      {(this.state.features.includes("171") ||
                        this.state.features.includes("97")) && (
                          <div className="card col-lg-12 p-0 border mb-3">
                            {/* <div className="card-header section_header">
                              <h5>Pre Shipment Payment Postings</h5>
                            </div> */}

                            {this.renderPaymentPostings(e)}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              ))}
              <ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
              />

              <div className="col shadow-sm p-0 mb-2 bg-white border">
                <div className="table-responsive">
                  <table className="table table-sm table-bordered">
                    <thead className="table-header">
                      <tr>
                        <th className="text-center">Approval Type</th>
                        <th className="text-center">Approved by</th>
                        <th className="text-center">Approved On</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.multiRows.map((e, idx) => (
                        <>
                          <tr>
                            <td className="text-center">Business</td>
                            <td className="text-center">
                              <div> {e.approved_by_name}</div>
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date)}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center">Financial</td>
                            <td className="text-center">
                              {e.approved_by_name_2}
                            </td>
                            <td className="text-center">
                              {localDateFormate(e.approved_date_2)}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => this.props.onEdit(true)}
            >
              Edit
            </button>
          </div>
        </div>
      );
    }
  }
}
