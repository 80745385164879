import React, { Component } from "react";
import api from "../../../api/api";
import CookieHandler from "../../common/CookieHandler";
import config from "../../../config/config";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { localDateFormate, pad, toLocaleString } from "../../common/common";
import ViewFiles from "../../common/ViewFiles";
import InvoiceLink from "../../InvoicePdf/InvoiceLink";
import InvoiceForm from "../../common/InvoiceForm";
import PaymentPostings from "../../common/PaymentPostings";
import Loader from "../../common/Loader";
import BusinessApproveButton from "../../Approvals/BusinessApproveButton";

export default class Barge_Purchase_Fin_Sac_View extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      activeTabIndex: 1,

      chemicalSprayPrice: "",
      bargePurchaseFinancialID: "",
      barge_purchase_financial: true,
      multiRows: [
        {
          sac_applicable: "Yes",
          sac_surveyor_name: "",
          sac_quantity_in_mt: "",
          sac_price_per_mt: "",
          sac_surveyor_base_price: "",
          sac_surveyor_vat_percent: "10",
          sac_surveyor_vat_value: "",
          sac_surveyor_pph23_percent: "2",
          sac_surveyor_pph23_value: "",
          sac_surveyor_total: "",
          sac_surveyor_ctc: "",
          sac_surveyor_currency_xchg_rate: "",
          sac_surveyor_ctc_in_local_currency: "",

          // sac_other_base_price: "",
          // sac_other_description: "",
          // sac_other_vat_percent: "10",
          // sac_other_vat_value: "",
          // sac_other_pph23_percent: "2",
          // sac_other_pph23_value: "",
          // sac_other_total: "",
          // sac_other_ctc: "",
          // sac_other_currency_xchg_rate: "",
          // sac_other_ctc_in_local_currency: "",
          sac_surveyor_price_per_addl_day: "",
          sac_surveyor_addl_days: "",
          sac_surveyor_addl_days_base_price: "",
          sac_surveyor_addl_days_vat_percent: "10",
          sac_surveyor_addl_days_vat_value: "",
          sac_surveyor_addl_days_pph23_percent: "2",
          sac_surveyor_addl_days_pph23_value: "",
          sac_surveyor_addl_days_total: "",
          sac_surveyor_addl_days_ctc: "",
          //sac_surveyor_addl_days_currency:'',
          sac_surveyor_addl_days_currency_xchg_rate: "",
          sac_surveyor_addl_days_ctc_in_local_currency: "",
        },
      ],
      sac_other_base_price: "",
      sac_other_description: "",
      sac_other_vat_percent: "10",
      sac_other_vat_value: "",
      sac_other_pph23_percent: "2",
      sac_other_pph23_value: "",
      sac_other_total: "",
      sac_other_ctc: "",
      sac_other_currency_xchg_rate: "",
      sac_other_ctc_in_local_currency: "",
      barge_group_id: "",
      mv_name: "",
      vendor_name: "",
      // Barge Purchase payments
      payment_posting_id: "",
      paymentTitle: "",
      payment_posting_type: "",
      amount: "",
      date_of_payment: null,
      invoice_no: "",
      description: "",
      errorMsg: "",
      succesMsg: "",
      dialogOpen: false,
      isLoading: true,
      submitting: false,
      features: [],
      purchaseBargeList: [],
      sac_charges_payment: [],
      invoiceDialog: false,
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    }
    const features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    if (
      !(
        features.includes("159") ||
        features.includes("396") ||
        features.includes("447") ||
        features.includes("97")
      )
    ) {
      window.location.href = "/dashboard";
    }
    const loginUserID = this.Cookie.getCookie("loginUserId");
    const idToken = this.Cookie.getIdTokenCookie();
    await api
      .get_barges_for_sac_charges(loginUserID, idToken)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barges) {
                let newData = [];
                var result = res.barges.reduce(function (r, a) {
                  r[a.id] = r[a.id] || [];
                  r[a.id].push(a);
                  return r;
                }, Object.create(null));
                for (let l = 0; l < Object.keys(result).length; l++) {
                  newData.push({ new_barges: result[Object.keys(result)[l]] });
                }
                let purchase_contracts_barges = newData;
                if (newData.length === 0) {
                  this.setState({
                    snackBarErrorOpen: true,
                    errorMsg: "No records to display",
                  });
                }
                this.setState({
                  sac_costing_barges: purchase_contracts_barges,
                });
              }
            } else if (res.code === "601") {
              this.setState({
                snackBarErrorOpen: true,
                errorMsg: res.message,
              });
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
    const comboID = this.props.comboID;
    const barge_sac_charges_id = comboID.split("$#")[1];
    await api
      .get_barge_sac_charges(loginUserID, idToken, barge_sac_charges_id)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((res) => {
            if (res.code === "200") {
              if (res.barge_sac_charges) {
                for (var i of res.barge_sac_charges) {
                  var total_payable_vendor = 0;
                  var total_paid_to_vendor = 0;
                  var remaining_amount = 0;
                  total_payable_vendor += Number(
                    i.sac_surveyor_addl_days_total
                  );
                  total_payable_vendor += Number(i.sac_surveyor_total);
                  for (var obj of i.sac_other_charges) {
                    total_payable_vendor += Number(obj.sac_other_total);
                  }

                  for (var j of i.sac_charges_payment) {
                    if (j.date_of_payment) {
                      total_paid_to_vendor += Number(j.amount);
                    }
                  }
                  remaining_amount =
                    Number(total_payable_vendor) - Number(total_paid_to_vendor);
                  i.total_payable_vendor = total_payable_vendor;
                  i.total_paid_to_vendor = total_paid_to_vendor;
                  i.remaining_amount = remaining_amount;
                }

                this.setState(
                  {
                    costing_no: res.barge_sac_charges[0]?.costing_no,
                    invoice_file: res.barge_sac_charges[0]?.invoice_file,

                    invoice_date: res.barge_sac_charges[0]?.invoice_date,
                    invoice_no: res.barge_sac_charges[0]?.invoice_no,

                    barge_group_id: res.barge_sac_charges[0].barge_group_id
                      ? res.barge_sac_charges[0].barge_group_id
                      : [],

                    sac_price_per_mt:
                      res.barge_sac_charges[0]?.sac_price_per_mt,
                    sac_quantity_in_mt:
                      res.barge_sac_charges[0]?.sac_quantity_in_mt,
                    sac_surveyor_vat_value:
                      res.barge_sac_charges[0]?.sac_surveyor_vat_value,
                    sac_surveyor_total:
                      res.barge_sac_charges[0]?.sac_surveyor_total,
                    sac_surveyor_base_price:
                      res.barge_sac_charges[0]?.sac_surveyor_base_price,
                    sac_surveyor_ctc:
                      res.barge_sac_charges[0]?.sac_surveyor_ctc,
                    sac_surveyor_currency_xchg_rate:
                      res.barge_sac_charges[0]?.sac_surveyor_currency_xchg_rate,
                    sac_surveyor_pph23_value:
                      res.barge_sac_charges[0]?.sac_surveyor_pph23_value,

                    sac_surveyor_addl_days_base_price:
                      res.barge_sac_charges[0]
                        ?.sac_surveyor_addl_days_base_price,
                    sac_surveyor_addl_days_ctc:
                      res.barge_sac_charges[0]?.sac_surveyor_addl_days_ctc,
                    sac_surveyor_addl_days_pph23_value:
                      res.barge_sac_charges[0]
                        ?.sac_surveyor_addl_days_pph23_value,
                    sac_surveyor_addl_days_total:
                      res.barge_sac_charges[0]?.sac_surveyor_addl_days_total,
                    sac_surveyor_addl_days_vat_value:
                      res.barge_sac_charges[0]
                        ?.sac_surveyor_addl_days_vat_value,
                    sac_surveyor_price_per_addl_day:
                      res.barge_sac_charges[0]?.sac_surveyor_price_per_addl_day,
                    sac_surveyor_addl_days_currency_xchg_rate:
                      res.barge_sac_charges[0]
                        ?.sac_surveyor_addl_days_currency_xchg_rate,

                    sac_other_pph23_value:
                      res.barge_sac_charges[0]?.sac_other_charges[0]
                        .sac_other_pph23_value,
                    sac_other_total:
                      res.barge_sac_charges[0]?.sac_other_charges[0]
                        .sac_other_total,
                    sac_other_vat_value:
                      res.barge_sac_charges[0]?.sac_other_charges[0]
                        .sac_other_vat_value,
                    sac_other_base_price:
                      res.barge_sac_charges[0]?.sac_other_charges[0]
                        .sac_other_base_price,
                    sac_other_currency_xchg_rate:
                      res.barge_sac_charges[0]?.sac_other_charges[0]
                        .sac_other_currency_xchg_rate,

                    sac_charges_vendor_name:
                      res.barge_sac_charges[0]?.sac_charges_vendor_name,
                    multiRows: res.barge_sac_charges,
                    files: res.files ? res.files : [],
                    //sac_charges_vendor_id: res.barge_sac_charges.length > 0 ? res.barge_sac_charges[0].sac_charges_vendor_id : null,
                    isLoading: false,
                  },
                  () => {
                    const barge_id = this.state.barge_group_id.split(",")[0];
                    let request = {
                      login_user_id: this.Cookie.getCookie("loginUserId"),
                      idtoken: this.Cookie.getIdTokenCookie(),
                      barge_id: barge_id,
                      vendor_type: "Barge",
                    };
                    api
                      .get_business_no_info_for_barge(request)
                      .then((response) => {
                        if (response.status >= 200 && response.status < 300) {
                          response.json().then((res) => {
                            if (res.code === "200") {
                              this.setState({
                                vendor_name: res.vendor_name,
                                mv_name: res.mv_name,
                              });
                            }
                          });
                        }
                      });

                    // api.get_barge_sac_charges_payments(loginUserID, idToken, barge_id).then((response) => {
                    //   if (response.status >= 200 && response.status < 300) {
                    //     response.json().then((pay_res) => {
                    //       if (pay_res.code === '200') {
                    //         if (pay_res.barge_sac_charges_payment_postings) {
                    //           for (var obj of pay_res.barge_sac_charges_payment_postings) {
                    //             total_paid_to_vendor += (Number(obj.amount))
                    //           }
                    //           remaining_amount = (Number(total_payable_vendor) - Number(total_paid_to_vendor));
                    //           this.setState({
                    //             sac_charges_payment: pay_res.barge_sac_charges_payment_postings,
                    //             total_payable_vendor: total_payable_vendor,
                    //             total_paid_to_vendor: total_paid_to_vendor,
                    //             remaining_amount: remaining_amount,
                    //             remainingAmount: remaining_amount,
                    //             isLoading: false
                    //           });
                    //         }
                    //         else {
                    //           alert('Unexpected error occured. Please contact administrator.');
                    //         }
                    //       }
                    //       else if (pay_res.code === '607') {
                    //         window.location.href = '/logout';
                    //       }
                    //     })
                    //   }
                    // });
                  }
                );
              }
            } else if (res.code === "607") {
              window.location.href = "/logout";
            }
          });
        }
      });
  }

  updateInvoice = (data) => {
    let request = {
      ...data,
      table_name: "barge_sac_charges",
    };
    api.update_invoice_details(request).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        response.json().then((res) => {
          if (res.code === "200") {
            this.componentDidMount();
            this.setState({
              invoiceDialog: false,
            });
          }
        });
      }
    });
  };

  renderPaymentPostings = (data) => {
    const {
      id,
      sac_charges_payment = [],
      invoice_no = null,
      remaining_amount = 0,
      sac_surveyor_currency_xchg_rate = 1,
      approval_status = "Pending",
    } = data;
    let payment_related_data = {
      paymentTitle: "Sampling & Anlaysis",
      payment_posting_type: "Sampling & Analysis",
      costing_type: "Sampling & Analaysis Charges",

      invoice_no: invoice_no,
      outstanding_amount: remaining_amount,
      billing_currency:
        config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
          ? "USD"
          : "IDR",
      currency_xchg_rate: sac_surveyor_currency_xchg_rate
        ? sac_surveyor_currency_xchg_rate
        : 1,
      costing_id: id,
      login_user_id: this.Cookie.getCookie("loginUserId"),
      idtoken: this.Cookie.getIdTokenCookie(),
    };
    let access_details = {
      costing_approval: approval_status,
      add_payment: "246",
      view_payment: "247",
      update_payment: "248",
      delete_payment: "249",
      approver: "420",
      approver2: "471",
    };

    return (
      <PaymentPostings
        payments={sac_charges_payment}
        access_details={access_details}
        costingType={"Sampling & Analaysis Charges"}
        data={payment_related_data}
        callbackFn={() => {
          this.componentDidMount();
        }}
      />
    );
  };

  renderInvoiceLink = () => {
    const comboID = this.props.comboID;
    const barge_sac_charges_id = comboID.split("$#")[1];
    const data = this.state.sac_costing_barges.map((e) =>
      e.new_barges.filter((obj) => obj.id === barge_sac_charges_id)
    );
    const { costing_no, billing_currency } = this.state;


    const othercharges = [];
    for (var i of this.state.multiRows) {

      for (var k of i.sac_other_charges) {
        var othertotal = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_total)), 0)
        var otherVatValue = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_vat_value)), 0)
        var otherpphValue = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_pph23_value)), 0)
        // var otherExchgRate = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_currency_xchg_rate)), 0)
        // var otherBasePrice = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_base_price)), 0)

        othercharges.push(
          (k.sac_other_base_price ?
            {
              qty: "",
              adjustedPrice: k.sac_other_base_price ? parseFloat(
                k.sac_other_base_price
              ).toFixed(2) : "",
              desc: `${k.sac_other_currency_xchg_rate > 1 ? `Other Charges @ ${parseFloat(k.sac_other_currency_xchg_rate).toFixed(2)}` : "Other Charges"}`,
              amount: k.sac_other_total,
            }
            : []
          ),
        )
      }
    }

    const Grand_total = (
      parseFloat(
        this.state.multiRows[0].sac_surveyor_total
          ? this.state.multiRows[0].sac_surveyor_total
          : 0
      ) +
      parseFloat(othertotal ? othertotal : 0) +
      parseFloat(
        this.state.multiRows[0].sac_surveyor_addl_days_total
          ? this.state.multiRows[0].sac_surveyor_addl_days_total
          : 0
      )
    ).toFixed(2);
    const invoiceObject = {
      costing_id: this.state.multiRows[0].costing_no,
      to: this.state.sac_charges_vendor_name,
      business_no: this.state.mv_name,
      costing_name: "Sampling And Analysis Charges",
      invoice_no: this.state.invoice_no,
      invoice_date: localDateFormate(this.state.invoice_date),
      bargeDetails: data ? data.flat() : [],
      billing_currency: billing_currency,
      total_payable_amount: toLocaleString(Grand_total),
      purchase_type: "FOB Barge",
      description: [
        //Sac_Charges
        (this.state.multiRows.length > 0 && this.state.multiRows[0].sac_surveyor_base_price ?
          {
            qty: toLocaleString(this.state.multiRows[0].sac_quantity_in_mt),
            adjustedPrice: toLocaleString(
              this.state.multiRows[0].sac_price_per_mt
            ),
            desc: `${this.state.multiRows[0].sac_surveyor_currency_xchg_rate > 1
              ? `S & A Charges @ ${parseFloat(
                this.state.multiRows[0].sac_surveyor_currency_xchg_rate
              ).toFixed(2)}`
              : "S & A Charges"
              }`,
            amount: this.state.multiRows[0].sac_surveyor_base_price,
          }
          : []),
        ...(othercharges),
        (this.state.multiRows.length > 0 && this.state.multiRows[0].sac_surveyor_addl_days ?
          {
            qty: toLocaleString(this.state.multiRows[0].sac_surveyor_addl_days),
            adjustedPrice: toLocaleString(
              this.state.multiRows[0].sac_surveyor_price_per_addl_day
            ),
            desc: `${this.state.multiRows[0].sac_surveyor_addl_days_currency_xchg_rate >
              1
              ? `Additional Charges @ ${parseFloat(
                this.state.multiRows[0]
                  .sac_surveyor_addl_days_currency_xchg_rate
              ).toFixed(2)}`
              : "Additional Charges"
              }`,
            amount: this.state.multiRows[0].sac_surveyor_addl_days_base_price ? parseFloat(this.state.multiRows[0].sac_surveyor_addl_days_base_price).toFixed(2) : "",
          }
          : []),

        ...(this.state.pph15_account !== "SDAM" &&
          !!(this.state.multiRows[0].sac_surveyor_vat_value || otherVatValue || this.state.multiRows[0].sac_surveyor_addl_days_vat_value)
          ? [
            {
              qty: " ",
              adjustedPrice: " ",
              desc: "VAT",
              amount: `${parseFloat(parseFloat(
                this.state.multiRows[0].sac_surveyor_vat_value
              ) + parseFloat(otherVatValue) + parseFloat(this.state.multiRows[0].sac_surveyor_addl_days_vat_value)).toFixed(2)}`,
            },
          ]
          : []),
        ...(!(
          config.company === "SRPL" ||
          config.company === "AVS" ||
          config.company === "PRPL"
        ) && !!(this.state.multiRows[0].sac_surveyor_pph23_value || otherpphValue || this.state.multiRows[0].sac_surveyor_addl_days_pph23_value)
          ? [
            {
              qty: "",
              adjustedPrice: "",
              desc: "PPH",
              amount: `( ${parseFloat
                (Number(
                  this.state.multiRows[0].sac_surveyor_pph23_value
                ) + Number(otherpphValue) + Number(this.state.multiRows[0].sac_surveyor_addl_days_pph23_value)).toFixed(2)} )`,
            },
          ]
          : []),
        //Additional_Charges
        // (!!this.state.multiRows[0].sac_surveyor_addl_days_base_price &&
        // {
        //   qty: toLocaleString(this.state.multiRows[0].sac_surveyor_addl_days),
        //   adjustedPrice: toLocaleString(
        //     this.state.multiRows[0].sac_surveyor_price_per_addl_day
        //   ),
        //   desc: `${this.state.multiRows[0].sac_surveyor_addl_days_currency_xchg_rate >
        //     1
        //     ? `Additional Charges @ ${parseFloat(
        //       this.state.multiRows[0]
        //         .sac_surveyor_addl_days_currency_xchg_rate
        //     ).toFixed(2)}`
        //     : "Additional Charges"
        //     }`,
        //   amount: this.state.multiRows[0].sac_surveyor_addl_days_base_price ? parseFloat(this.state.multiRows[0].sac_surveyor_addl_days_base_price).toFixed(2) : "",
        // }),
        // (!!this.state.multiRows[0].sac_surveyor_addl_days_vat_value &&
        // {
        //   qty: " ",
        //   adjustedPrice: " ",
        //   desc: "VAT",
        //   amount: `${toLocaleString(
        //     this.state.multiRows[0].sac_surveyor_addl_days_vat_value
        //   )}`,
        // }),
        // ...(!(
        //   config.company === "SRPL" ||
        //   config.company === "AVS" ||
        //   config.company === "PRPL"
        // ) && !!this.state.multiRows[0].sac_surveyor_addl_days_pph23_value
        //   ? [
        //     {
        //       qty: " ",
        //       adjustedPrice: " ",
        //       desc: "PPH",
        //       amount: `( ${toLocaleString(
        //         this.state.multiRows[0].sac_surveyor_addl_days_pph23_value
        //       )} )`,
        //     },
        //   ]
        //   : []),
        // //Other Charges
        // (!!this.state.sac_other_total &&
        // {
        //   qty: " ",
        //   adjustedPrice: toLocaleString(
        //     this.state.multiRows[0].sac_other_base_price
        //   ),
        //   desc: `${this.state.sac_other_currency_xchg_rate > 0
        //     ? `Other Charges @ ${parseFloat(
        //       this.state.sac_other_currency_xchg_rate
        //     ).toFixed(2)}`
        //     : "Other Charges"
        //     }`,
        //   amount: this.state.sac_other_total ? parseFloat(this.state.sac_other_total).toFixed(2) : "",
        // }),
        // (!!this.state.sac_other_vat_value &&
        // {
        //   qty: " ",
        //   adjustedPrice: " ",
        //   desc: "VAT",
        //   amount: `${toLocaleString(this.state.sac_other_vat_value)}`,
        // }
        // ),
        // ...(!(
        //   config.company === "SRPL" ||
        //   config.company === "AVS" ||
        //   config.company === "PRPL"
        // ) && !!this.state.sac_other_pph23_value
        //   ? [
        //     {
        //       qty: " ",
        //       adjustedPrice: " ",
        //       desc: "PPH",
        //       amount: `( ${toLocaleString(
        //         this.state.sac_other_pph23_value
        //       )} )`,
        //     },
        //   ]
        //   : []),
      ],
    };
    return <InvoiceLink data={invoiceObject} fileName={costing_no} />;
  };

  render() {
    const comboID = this.props.comboID;
    const CostingID = comboID.split("$#")[1];
    if (this.state.isLoading) {
      return <Loader />;
    } else {
      for (var i of this.state.multiRows) {
        // for (var k of i.sac_other_charges) {
        // var othertotal = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_total)), 0)
        var otherVatValue = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_vat_value)), 0)
        var otherpphValue = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_pph23_value)), 0)
        // var otherExchgRate = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_currency_xchg_rate)), 0)
        // var otherBasePrice = i.sac_other_charges.reduce((acc, item) => (Number(acc) + Number(item.sac_other_base_price)), 0)
        // }
      }
      return (
        <div className="container bg-white p-0" style={{ minHeight: "100vh" }}>
          <div className="row">
            <div className="col-sm text-left pl-0">
              <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                {`Sampling & Anlaysis
              Charges - ${this.state.costing_no}`}
              </h4>
            </div>
            <div className="col-lg-1 pl-0 float-right">
              {this.renderInvoiceLink()}
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={this.state.snackBarErrorOpen}
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: "450px" }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarErrorOpen: false })}
              severity="error"
              style={{ width: "100%" }}
            >
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ width: "450px" }}
            open={this.state.snackBarSuccessOpen}
            autoHideDuration={100000}
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => this.setState({ snackBarSuccessOpen: false })}
              severity="success"
              style={{ width: "100%" }}
            >
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className="drawer-section">
            <div className="card">
              {this.state.sac_costing_barges.length > 0 && (
                <div className="row">
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-header">
                        <tr>
                          <th nowrap="true"> Barge ID </th>
                          <th nowrap="true"> Barge Nomination</th>
                          <th nowrap="true"> Business No</th>
                          <th nowrap="true"> Vendor </th>
                          <th nowrap="true">Quantity</th>
                          <th nowrap="true"> Price PMT</th>
                          <th nowrap="true"> Cargo Charges </th>
                          <th nowrap="true">Additional Charges </th>
                          <th nowrap="true">Other Charges</th>
                          <th nowrap="true">Total Payable</th>
                          <th nowrap="true">Paid</th>
                          <th nowrap="true">Remaining</th>
                        </tr>
                      </thead>
                      {this.state.sac_costing_barges.map((p, index) => (
                        <tbody key={index}>
                          {p.new_barges.map((idr, indx) => (
                            <tr
                              key={indx}
                              style={{
                                display: CostingID === idr.id ? "" : "none",
                              }}
                            >
                              <td className=""><a
                                href={"/view-barge/" + btoa(idr.barge_id)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: config.themeColor }}
                              >
                                {pad(idr.barge_id)}
                              </a></td>
                              <td className=" text-left" nowrap="true">
                                {idr.barge_nomination}
                              </td>
                              <td className=" text-left" nowrap="true">
                                {idr.business_no}
                              </td>
                              <td className=" text-left" nowrap="true">
                                {idr.sampling_surveyor}
                              </td>
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.sac_quantity_in_mt)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.sac_price_per_mt)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.cargo_charges)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.additional_charges)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.other_charges)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.total_payable)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className="text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.paid)}
                                </td>
                              )}
                              {indx === 0 && (
                                <td
                                  className=" text-right"
                                  nowrap="true"
                                  rowSpan={p.new_barges.length}
                                >
                                  {toLocaleString(idr.Remaining_amount)}
                                </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      ))}
                    </table>
                  </div>
                </div>
              )}

              <div className="col shadow-sm p-0 mb-2 bg-white border">
                {/* New Changes */}
                {this.state.multiRows.map((e, index) => (
                  <>
                    {!!e.sac_surveyor_base_price &&
                      <>
                        <label className="contract_display_header_value">
                          Sampling & Analysis Charges Applicable
                        </label>
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Qty</th>
                                <th className="text-right">Unit Price</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                <tr>
                                  <td>
                                    Sampling And Analysis (Barges)
                                    {e.sac_surveyor_currency_xchg_rate > 1 && !(
                                      config.company === "SRPL" || config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.billing_currency !== "IDR" &&
                                      e.billing_currency !== "0" && (
                                        <div>
                                          Exchange Rate @
                                          {parseFloat(e.sac_surveyor_currency_xchg_rate).toFixed(2)}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_quantity_in_mt)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_price_per_mt)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_base_price)}
                                  </td>

                                </tr>
                                {e.sac_other_charges.map((p, index) => (<tr>
                                  <td>
                                    Other Charges
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      p.sac_other_currency !== "IDR" &&
                                      p.sac_other_currency !== "0" && p.sac_other_currency_xchg_rate
                                      &&
                                      <div>
                                        Exchange Rate @ {parseFloat(p.sac_other_currency_xchg_rate).toFixed(2)}
                                      </div>
                                    }
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td className="text-right">
                                    {toLocaleString(
                                      p.sac_other_base_price
                                    )}
                                  </td>
                                </tr>))}
                                {e.sac_surveyor_addl_days && <tr>
                                  <td>
                                    Additional Days
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.sac_surveyor_addl_days_currency !== "IDR" &&
                                      e.sac_surveyor_addl_days_currency && (
                                        <div>
                                          Exchange Rate
                                          {parseFloat(this.state.multiRows[0].sac_surveyor_addl_days_currency_xchg_rate).toFixed(2)}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_addl_days)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_price_per_addl_day)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_addl_days_base_price)}
                                  </td>
                                </tr>
                                }
                                {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  !!(e.sac_surveyor_vat_value || otherVatValue || e.sac_surveyor_addl_days_vat_value) && (
                                    <tr>
                                      <td>VAT @ {toLocaleString(e.sac_surveyor_vat_percent)} %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {parseFloat(Number(e.sac_surveyor_vat_value) + Number(otherVatValue) + Number(e.sac_surveyor_addl_days_vat_value)).toFixed(2)}
                                      </td>
                                    </tr>
                                  )}
                                {!(config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL")
                                  && !!(e.sac_surveyor_pph23_value || otherpphValue || e.sac_surveyor_addl_days_pph23_value) &&
                                  <tr>
                                    <td>PPH23 @ 2% </td>
                                    <td></td>
                                    <td></td>
                                    <td className="text-right">
                                      ({(Number(e.sac_surveyor_pph23_value) + Number(otherpphValue) + Number(e.sac_surveyor_addl_days_pph23_value)).toFixed(2)})</td>
                                  </tr>
                                }
                                <tr>
                                  <td colSpan={3} className="text-right">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td style={{ fontWeight: "bold" }} className="text-right">
                                    {e.sac_surveyor_total ? `${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      }
                                    ${parseFloat(e.total_payable_vendor).toFixed(2)}` : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="text-right">
                                    {" "}
                                    Paid to Vendor :{" "}
                                  </td>
                                  <td style={{ fontWeight: "bold" }} className="text-right">
                                    {config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                      ? "USD"
                                      : "IDR"} {e.total_paid_to_vendor ? ` ${parseFloat(e.total_paid_to_vendor).toFixed(2)}` : ""}
                                  </td>
                                </tr>
                                <tr>
                                  {e.remaining_amount ? <>
                                    <td colSpan={3} className="text-right ">
                                      {" "}
                                      Remaining Amount{" "}
                                    </td>
                                    <td style={{ fontWeight: "bold" }} className="text-right ">
                                      {config.company === "SRPL" ||
                                        config.company === "AVS" ||
                                        config.company === "PRPL"
                                        ? "USD"
                                        : "IDR"} {e.remaining_amount ? ` ${parseFloat(e.remaining_amount).toFixed(2)}` : ""}
                                    </td>
                                  </> : ""}
                                </tr>
                              </>
                            </tbody>
                          </table>
                        </div>
                      </>
                    }


                    {/* {!!e.sac_other_charges[0].sac_other_base_price
                      && e.sac_other_charges.map((p, index) => (
                        <>
                          <label className="contract_display_header_value">
                            Other Charges
                          </label>
                          <div> */}
                    {/* Other Changes */}
                    {/* <div className="table-responsive">
                              <table className="table table-sm table-bordered mb-0">
                                <thead className="table-header">
                                  <tr>
                                    <th className="text-center">Description</th>
                                    <th className="text-right">Qty</th>
                                    <th className="text-right">Unit Price</th>
                                    <th className="text-right">Amount</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <>
                                    <tr>
                                      <td>
                                        Other Charges
                                        {!(
                                          config.company === "SRPL" ||
                                          config.company === "AVS" ||
                                          config.company === "PRPL"
                                        ) &&
                                          p.sac_other_currency !== "IDR" &&
                                          p.sac_other_currency !== "0" && p.sac_other_currency_xchg_rate
                                          &&
                                          <div>
                                            Exchange Rate @ {parseFloat(p.sac_other_currency_xchg_rate).toFixed(2)}
                                          </div>
                                        }
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">
                                        {toLocaleString(
                                          p.sac_other_base_price
                                        )}
                                      </td>
                                    </tr>
                                    {(this.state.vat_applicability === "Inclusive" ||
                                      config.company !== "SRPL" ||
                                      config.company !== "PRPL" ||
                                      config.company !== "AVS") &&
                                      e.sac_surveyor_vat_value && (
                                        <tr>
                                          <td>VAT @ {toLocaleString(p.sac_other_vat_percent)} %</td>
                                          <td className="text-right"></td>
                                          <td className="text-right"></td>
                                          <td className="text-right">
                                            {toLocaleString(p.sac_other_vat_value)}
                                          </td>
                                        </tr>
                                      )}
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) && e.sac_surveyor_pph23_value && (
                                        <tr>
                                          <td>PPH23 @ 2% </td>
                                          <td></td>
                                          <td></td>
                                          <td className="text-right">( {toLocaleString(p.sac_other_pph23_value)})</td>
                                        </tr>
                                      )}
                                    <tr>
                                      <td colSpan={3} className="text-right ">
                                        {" "}
                                        Payable to Vendor :{" "}
                                      </td>
                                      <td className="text-right ">
                                        {`${config.company === "SRPL" ||
                                          config.company === "PRPL" ||
                                          config.company === "AVS"
                                          ? "USD"
                                          : "IDR"
                                          } ${parseFloat(p.sac_other_total).toFixed(2)}`}
                                      </td>
                                    </tr>
                                  </>
                                </tbody>
                              </table>
                            </div> */}
                    {/* </div> */}
                    {/* </> */}
                    {/* )) */}
                    {/* } */}

                    {/* {!!e.sac_surveyor_addl_days && */}
                    {/* <> */}
                    {/* <label className="contract_display_header_value">
                      Additional Charges
                    </label> */}
                    {/* <div className="table-responsive"> */}
                    {/* <table className="table table-sm table-bordered mb-0">
                            <thead className="table-header">
                              <tr>
                                <th className="text-center">Description</th>
                                <th className="text-right">Extra Days</th>
                                <th className="text-right">Price per Day</th>
                                <th className="text-right">Amount</th>
                              </tr>
                            </thead> */}
                    {/* <tbody>
                              <>
                                <tr>
                                  <td>
                                    Additional Days
                                    {!(
                                      config.company === "SRPL" ||
                                      config.company === "AVS" ||
                                      config.company === "PRPL"
                                    ) &&
                                      e.sac_surveyor_addl_days_currency !== "IDR" &&
                                      e.sac_surveyor_addl_days_currency && (
                                        <div>
                                          Exchange Rate
                                          {parseFloat(this.state.multiRows[0].sac_surveyor_addl_days_currency_xchg_rate).toFixed(2)}
                                        </div>
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_addl_days)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_price_per_addl_day)}
                                  </td>
                                  <td className="text-right">
                                    {toLocaleString(e.sac_surveyor_addl_days_base_price)}
                                  </td>

                                </tr>
                                {(this.state.vat_applicability === "Inclusive" ||
                                  config.company !== "SRPL" ||
                                  config.company !== "PRPL" ||
                                  config.company !== "AVS") &&
                                  !!e.sac_surveyor_addl_days_vat_value && (
                                    <tr>
                                      <td>VAT @ {toLocaleString(e.sac_surveyor_addl_days_vat_percent)} %</td>
                                      <td className="text-right"></td>
                                      <td className="text-right"></td>
                                      <td className="text-right">
                                        {toLocaleString(e.sac_surveyor_addl_days_vat_value)}
                                      </td>
                                    </tr>
                                  )}
                                {!(
                                  config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                ) && !!e.sac_surveyor_pph23_value && (
                                    <tr>
                                      <td>PPH23 @ {toLocaleString(e.sac_surveyor_addl_days_pph23_percent)} % </td>
                                      <td></td>
                                      <td></td>
                                      <td className="text-right">( {toLocaleString(e.sac_surveyor_addl_days_pph23_value)})</td>
                                    </tr>
                                  )}
                                <tr>
                                  <td colSpan={3} className="text-right ">
                                    {" "}
                                    Payable to Vendor :{" "}
                                  </td>
                                  <td className="text-right ">
                                    {e.sac_surveyor_addl_days_total ? `${config.company === "SRPL" ||
                                      config.company === "PRPL" ||
                                      config.company === "AVS"
                                      ? "USD"
                                      : "IDR"
                                      }
                                ${parseFloat(e.sac_surveyor_addl_days_total).toFixed(2)}` : ""}
                                  </td>
                                </tr>
                              </>

                            </tbody> */}
                    {/* </table>
                        </div> */}
                    {/* </>} */}
                  </>
                ))}
              </div>

              {
                this.state.multiRows.map((e, idx) => (
                  <div className="row" key={idx}>
                    <div className="col-lg-12 border_dashed rounded-lg p-2 mb-3">
                      <div className="row">
                        {e.approval_status === "Pending" &&
                          e.invoice_file &&
                          this.Cookie.getCookie("loginUserId") !== e.created_by &&
                          e.approved_by !==
                          this.Cookie.getCookie("loginUserId") &&
                          (this.state.features.includes("396") ||
                            this.state.features.includes("447") ||
                            this.state.features.includes("97")) && (
                            <BusinessApproveButton
                              costingID={e.id}
                              costingType={"Sampling & Analaysis Charges"}
                              accessInfo={{
                                ...e,
                                approver_1: "396",
                                approver_2: "447",
                              }}
                            />
                          )}
                      </div>
                      {e.sac_applicable === "Yes" && (
                        <div>


                          <InvoiceForm
                            open={this.state.invoiceDialog}
                            data={e}
                            costingID={e.id}
                            callbackFn={(data) => this.updateInvoice(data)}
                          />

                          {/* <div className="row"> */}
                          {/* <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Total Payable To Vendor
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.total_payable_vendor)}
                                {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div> */}
                          {/* <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Total Paid To Vendor
                              </label>
                              <div className="contract_display_header_value">
                                {toLocaleString(e.total_paid_to_vendor)}
                                {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div> */}
                          {/* <div className="col-lg-4 mb-2">
                              <label className="contract_display_header_label">
                                Remaining Amount
                              </label>
                              <div className="contract_display_header_value">
                                {" "}
                                {toLocaleString(e.remaining_amount)}
                                {config.company === "SRPL" ||
                                  config.company === "AVS" ||
                                  config.company === "PRPL"
                                  ? "USD"
                                  : "IDR"}
                              </div>
                            </div> */}
                          {/* </div> */}

                          <div className="row">
                            <div className="card col-lg-12 p-0 border">
                              <div className="card-header section_header">
                                <h5>Payment Postings</h5>
                              </div>
                              {this.renderPaymentPostings(e)}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                ))
              }

              < ViewFiles
                {...this.props}
                files_data={this.state.files}
                type="Costings"
                invoice_file={this.state.invoice_file}
              />
            </div>
          </div>
          <div className="col shadow-sm p-0 mb-2 bg-white border">
            <div className="table-responsive">
              <table className="table table-sm table-bordered">
                <thead className="table-header">
                  <tr>
                    <th className="text-center">Approval Type</th>
                    <th className="text-center">Approved by</th>
                    <th className="text-center">Approved On</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.multiRows.map((e, idx) => (
                    <>
                      <tr>
                        <td className="text-center">Business</td>
                        <td className="text-center">
                          <div> {e.approved_by_name}</div>
                        </td>
                        <td className="text-center">
                          {localDateFormate(e.approved_date)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Financial</td>
                        <td className="text-center">
                          {e.approved_by_name_2}
                        </td>
                        <td className="text-center">
                          {localDateFormate(e.approved_date_2)}
                        </td>
                      </tr>
                    </>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="row col-lg-12 modal-footer justify-content-end bottom_buttons_section"
            style={{ bottom: 0, background: "white" }}
          >
            <button
              type="button"
              className="drawer_button drawer_text drawer_calculate_button drawer_calculate_adjustment"
              onClick={() => this.props.onCancel(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="drawer_button drawer_text"
              onClick={() => this.props.onEdit(true)}
            >
              Edit
            </button>
          </div>
        </div>
      );
    }
  }
}
