


import React, { useEffect, useState } from 'react'
import {
  TextField
} from "@material-ui/core";

import config from '../../config/config';

import { COSTING_FILES } from '../constants/constants';

const FileUpload = (props) => {

  const { callbackFn, feature_name } = props;

  const [files, setFiles] = useState([{
    file_description: '',
    file_name: "",
    file_extension: "",
    file_content: "",
    feature_name: "Barge_pur_fin_barge_costing",
  }])
  const [costingFiles, setCostingFiles] = useState([...COSTING_FILES])

  useEffect(() => {
    setFiles(props.files)
  }, [props.files])


  const fileUploadHandler = (idx) => event => {
    let file = event.target.files[0];
    let file_data = '';
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf('.') + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row = files ? [...files] : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;
      setFiles(row);
      callbackFn(row, costingFiles)
    }
  }

  const handleChangeDataget = (name) => event => {
    const idx = costingFiles.findIndex((id) => id.column === name);
    let file = event.target.files[0];
    let file_data = '';
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf('.') + 1);
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");


      let row = [...costingFiles];
      row[idx].file_extension = file_extension;
      row[idx].file_content = file_data;
      row[idx].update_content = true;
      setCostingFiles(row);
      callbackFn(files, row);
    }

  };

  const addMoreFileHandler = () => {
    const data = {
      file_description: '',
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: feature_name,
    }
    let copyFiles = [...files, data];
    setFiles(copyFiles)
    callbackFn(copyFiles, costingFiles)

  }

  return (
    <div className='card p-0 border mb-3'>
      <div className='card-header section_header'>
        <h5>File Upload Details</h5>
      </div>
      <div className='card-body'>
        {props.type === "Costings" &&
          <>
            <div className='row mb-0'>
              <div className='col-lg-4 p-0'>
                <label className='form_label mb-0'>{feature_name === "Business_no_credit_note" ? 'CREDIT NOTE' : feature_name === "Business_no_debit_note" ? "DEBIT NOTE" : "INVOICE"} </label>
              </div>

              {(props.invoice_file && props.invoice_file !== '' && props.invoice_file !== null) &&

                <div className='col-lg-4'>
                  <a href={config.apiDomain + "/" + props.invoice_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{props.invoice_file}</a>

                </div>
              }

              <div className='col-lg-4 pr-0'>
                <input type="file" name="fileToUpload" id="fileToUpload" className="form-control border-0 pl-0" onChange={handleChangeDataget('invoice_file')} />
              </div>
            </div>
            {(feature_name === "Purchase_fin_coal_costing" || feature_name === "Sales_fin_commercial_coal_costing") &&
              <>
                <div className='row mb-0' >
                  <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>DSR</label>
                  </div>
                  {(props.dsr_file && props.dsr_file !== '' && props.dsr_file !== null) &&

                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.dsr_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{props.dsr_file}</a>

                    </div>
                  }
                  <div className='col-lg-4 pr-0'>
                    <input type="file" name="fileToUpload" id="fileToUpload" className="form-control border-0 pl-0" onChange={handleChangeDataget('dsr_file')} />
                  </div>
                </div>
                <div className='row mb-0'>
                  <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>COA</label>
                  </div>
                  {(props.coa_file && props.coa_file !== '' && props.coa_file !== null) &&

                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.coa_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{props.coa_file}</a>

                    </div>
                  }
                  <div className='col-lg-4 pr-0'>
                    <input type="file" name="fileToUpload" id="fileToUpload" className="form-control border-0 pl-0" onChange={handleChangeDataget('coa_file')} />
                  </div>
                </div>
                <div className='row mb-0'>
                  <div className='col-lg-4 p-0'>
                    <label className='form_label mb-0'>BL</label>
                  </div>
                  {(props.bl_file && props.bl_file !== '' && props.bl_file !== null) &&

                    <div className='col-lg-4'>
                      <a href={config.apiDomain + "/" + props.bl_file} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{props.bl_file}</a>

                    </div>
                  }
                  <div className='col-lg-4 pr-0'>
                    <input type="file" name="fileToUpload" id="fileToUpload" className="form-control border-0 pl-0" onChange={handleChangeDataget('bl_file')} />
                  </div>
                </div>
              </>
            }
            <br />
            <hr />
          </>
        }


        {files.map((e, index) => (
          <div key={index}>
            {e.file_path ?
              <div className='row mb-3' >
                <div className='col-lg-10 p-0'>
                  <a href={config.apiDomain + "/" + e.file_path} role="button" target='_blank' rel="noopener noreferrer" style={{ color: config.themeColor }}>{e.file_description}</a>
                </div>
                <center className="col-lg-1 d-flex justify-content-center">
                  <i className="fa fa-trash-o m-auto" onClick={(e) => {
                    const row = files
                    row.splice(index, 1);
                    setFiles(row);
                  }}
                    style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                </center>
              </div>
              :
              <div className='row mb-3'>

                <div className='col-lg-4 p-0'>
                  <label className='form_label mb-0'>File Description</label>
                  <TextField
                    name='file_description'
                    variant='outlined'
                    margin='dense'
                    fullWidth
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    value={e.file_description}
                    error={e.file_description_error}
                    onChange={(e) => {
                      var row = [...files];
                      row[index].file_description = e.target.value;
                      row[index].file_description_error = false;
                      setFiles(row);
                      callbackFn(row, costingFiles)
                    }}

                  />
                </div>

                <div className='col-lg-6 pr-0'>
                  <label className='form_label mb-2'>Select file to upload</label>
                  <input type="file" name="fileToUpload" id="fileToUpload" className="form-control border-0 pl-0" onChange={fileUploadHandler(index)} />                </div>
                <center className="col-lg-1 d-flex justify-content-center">
                  <i className="fa fa-trash-o m-auto" onClick={(e) => {
                    const row = [...files]
                    row.splice(index, 1);
                    setFiles(row);
                    callbackFn(row, costingFiles);
                  }} style={{ fontSize: 24, color: config.themeColor, cursor: 'pointer' }} />
                </center>
              </div>
            }
          </div>)
        )}
        <button type='button' style={{ color: config.themeColor, width: 110 }} className='header_button header_button_text addrow_button_adjustment' onClick={addMoreFileHandler}>Add Row</button>
      </div>
    </div >)
}


export default FileUpload;
