import React, { Component } from 'react';
import api from '../../../api/api';

import CookieHandler from '../../common/CookieHandler';
import Drawer from '@material-ui/core/Drawer';
import {
  TextField, Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';

import BargeCoalSalesChargesAdd from './Barge_Coal_Sales_Charges_Add';
import CoalSalesChargesView from './Barge_Coal_Sales_Charges_View';
import config from '../../../config/config';
import { pad } from '../../common/common';
import Loader from '../../common/Loader';

export default class BargeCoalSalesChargesList extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      showDrawer: false,
      isLoading: true,
      CscAdd: false,
      performaCostingList: [],

    }
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");
    this.setState({ features: features });
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const get_sales_contracts = await api.getSalesContractList(loginUserID, idToken);
    if (get_sales_contracts.status >= 200 && get_sales_contracts.status < 300) {
      get_sales_contracts.json().then((res) => {
        if (res.code === '200') {
          if (res.sale_contract) {
            const salesDataforMenu = [];
            const filteredSContracts = res.sale_contract.filter((e, indx) => e.status === "Active" || e.status === "Freezed");
            for (var i of filteredSContracts) {
              // salesDataforMenu.push(<MenuItem value={key.contract_no} key={key.id}>{key.contract_no}</MenuItem>)
              salesDataforMenu.push({ name: i.contract_no, value: i.id, key: i.id, customer_name: i.customer_name });
            }
            this.setState({
              salesDataforMenu: salesDataforMenu,
              salesContractsList: filteredSContracts,
              isLoading: false,
            })
          } else {
            alert('Unexpected error occured. Please contact administrator.');
          }

        } else if (res.code === '601') {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message
          });
        } else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }
  }

  getPerformaList = async () => {
    const loginUserID = this.Cookie.getCookie('loginUserId');
    const idToken = this.Cookie.getIdTokenCookie();
    const sale_contract_id = this.state.sale_contract_id;
    const sales_fin_csc = await api.get_barge_for_sales_fin_csc(loginUserID, idToken, sale_contract_id);
    if (sales_fin_csc.status >= 200 && sales_fin_csc.status < 300) {
      sales_fin_csc.json().then((res) => {
        if (res.code === '200') {
          if (res.barges) {
            // let listCount = 0;
            var sales_contracts = res.barges;
            for (let i = 0; i < sales_contracts.length; i++) {
              let barges = [];
              let purchase_contracts_barges = sales_contracts[i].barges;
              let newData = [];
              for (let k = 0; k < purchase_contracts_barges.length; k++) {
                let id = purchase_contracts_barges[k].barge_id;
                if (id !== null) {
                  barges.push(purchase_contracts_barges[k])
                }
              }
              var result = barges.reduce(function (r, a) {
                r[a.barge_id] = r[a.barge_id] || [];
                r[a.barge_id].push(a);
                return r;
              }, Object.create(null));
              for (let l = 0; l < Object.keys(result).length; l++) {
                newData.push({ new_barges: result[Object.keys(result)[l]] })
              }
              sales_contracts[i].new_barges = newData;
            }
            this.setState({
              performaCostingList: sales_contracts,
              submitting: false,
              isLoading: false
            });
          }
        } else if (res.code === '601') {
          this.setState({
            snackBarErrorOpen: true,
            errorMsg: res.message
          });
        } else if (res.code === '607') {
          window.location.href = '/logout';
        }
      })
    }
  }

  CallbackDrawerClose = (childData) => {
    this.setState({
      showDrawer: childData,

      CscAdd: false,
      coalCostingView: false
    });
  }

  onEdit = (childData) => {
    this.setState({
      showDrawer: true,
      CscAdd: false,
      coalCostingUpdate: true,
      coalCostingView: false,

    });
  }

  onAddResponse = (value) => {
    this.setState({ CscAdd: false, showDrawer: false, })
  }

  onUpdateResponse = (value) => {
    if (value === '200') {
      this.setState({
        showDrawer: true,
        CscAdd: false,
        coalCostingView: true,
        coalCostingUpdate: false,
      })
    }
  }

  CallbackViewPage = (childData) => {
    this.setState({
      showDrawer: true,
      coalCostingView: childData === 'CoalSalesCharges' ? true : false,
      coalCostingUpdate: false,
    });
  }

  toLocaleString(value) {
    return Number(value).toLocaleString();
  }


  render() {
    let drawerComponent;
    if (this.state.CscAdd) {
      drawerComponent = <BargeCoalSalesChargesAdd onAddResponse={this.onAddResponse} returnCallbackDrawerClose={this.CallbackDrawerClose} />;
    } else if (this.state.coalCostingView && this.state.comboID) {
      drawerComponent = <CoalSalesChargesView
        comboID={this.state.comboID}
        returnCallbackDrawerClose={this.CallbackDrawerClose}
        onEdit={this.onEdit}
      />
    }
    if (this.state.isLoading) {
      return <Loader />
    }
    else {
      return (
        <div id='wraper'>
          <div className='content'>
            {!this.state.isLoading &&
              <div className='content-header'>
                <Drawer anchor="right" open={this.state.showDrawer} variant="temporary" elevation={20} style={{ overflow: 'initial' }} >
                  <div className='row' style={{ width: 800 }} >
                    {drawerComponent}
                  </div>
                </Drawer>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
                  style={{ width: '450px' }}
                >
                  <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
                    {this.state.errorMsg}
                  </Alert>
                </Snackbar>
                <Snackbar
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  style={{ width: '450px' }}
                  open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
                >
                  <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
                    {this.state.successMsg}
                  </Alert>
                </Snackbar>
                <div className='p-0'>
                  <div className='card p-2'>

                    <div className='row'>
                      <div className='col-lg-4 pl-0 pt-2'>
                        {/* <label className='form_label mb-0'>Purchase Contract *</label> */}
                        <Autocomplete
                          options={this.state.salesDataforMenu}
                          getOptionLabel={option => option.name}
                          onChange={(event, value) => {
                            if (value) {
                              this.setState({
                                sale_contract_id: value.value,
                                sales_contract_no: value.name,
                                selected_sc: value,
                                sales_contract_error: false
                              });
                            }
                            else {
                              this.setState({
                                sale_contract_id: '',
                                sales_quality: '',
                                sc_qualities_id: '',
                                sales_type: '',
                                sc_sales_type_id: '',
                                sales_price_pmt: 0,
                                coalPriceCurrency: '',
                                selected_sc: { name: '', value: '' }
                              });
                            }
                          }}
                          value={this.state.selected_sc}
                          renderOption={option => (
                            <React.Fragment>
                              <div className='col-lg p-0 m-0'>
                                <label className='form_label mb-0'>{option.name}</label>
                                <label className='contract_display_header_value m-0 pl-0' style={{ fontSize: 10, paddingLeft: 5 }}>{option.customer_name ? "( " + option.customer_name + " )" : null}</label>
                              </div>
                            </React.Fragment>
                          )}
                          size='small'
                          name='sales_contract'
                          fullWidth
                          style={{ padding: '0px' }}
                          // value= {this.state.supplierSurveyorNameValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={this.state.sales_contract_error}
                              // inputProps={{ style:{padding: '2px'}}}
                              placeholder="Sales Contract *"
                              //label="Sales Contract *"
                              variant="standard"
                              //style={{ width: 230 }}
                              fullWidth
                            />
                          )}
                        />
                      </div>
                      {/* {(this.state.features.includes('126')  || this.state.features.includes('97') ) && */}

                      <div className='col-lg-2'>
                        <button onClick={this.getPerformaList} className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', marginTop: "25px" }}>Load costings</button>
                      </div>
                      {/* } */}
                      <div className='col text-right float-sm-left'>
                        <button className='header_button header_button_text add_button_adjustment' style={{ width: 'auto', marginTop: "25px" }}
                          onClick={() => this.setState({ CscAdd: true, coalCostingView: false, coalCostingUpdate: false, showDrawer: true })}
                        >New Costing</button>
                      </div>
                    </div>
                    {this.state.performaCostingList.length > 0 ?
                      <div>
                        {this.state.performaCostingList.map((p, index) => (
                          <div className={p.new_barges.length > 0 ? 'shadow-sm p-2 mb-2 bg-white border mt-2' : ""} key={index}>
                            {p.new_barges.length > 0 &&
                              <h5 className='bg-light mb-3 p-2 section_header'>
                                {p.sales_contract_no} - {p.quality ? "(" + p.quality + ")" : ''} - {p.sales_type} - {p.buyer_name}
                              </h5>
                            }
                            {p.new_barges.length > 0 ?
                              <div className='row'>
                                <div className='table-responsive'>
                                  <table className='table'>
                                    <thead style={{ backgroundColor: 'rgb(249, 249, 249)' }}>
                                      <tr>
                                        <th nowrap='true' style={{ textAlign: 'center', minWidth: 90 }}></th>
                                        <th nowrap='true'>Barge ID</th>
                                        <th nowrap='true'>Barge Nomination</th>
                                        <th nowrap='true'>Business No</th>
                                        {/* <th nowrap='true'>Sales Contract</th>
                                      <th nowrap='true'>Quality</th>
                                      <th nowrap='true'>Sales Type</th> */}
                                        <th nowrap='true' className='text-right'>Quantity</th>
                                        <th nowrap='true' className='text-right'>Price PMT</th>
                                        <th nowrap='true' className='text-right'>Total Receivable</th>
                                        <th nowrap='true' className='text-right'>Received</th>
                                        <th nowrap='true' className='text-right'>Remaining</th>
                                      </tr>
                                    </thead>
                                    {p.new_barges.map((id, idx) => (
                                      <tbody key={idx}>
                                        {id.new_barges.map((idr, indx) => (
                                          <tr key={indx}>
                                            {indx === 0 &&
                                              <td className="table_td" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6" }} >
                                                <i className="fa fa-eye mr-2" aria-hidden="true"
                                                  style={{ fontSize: 22, color: config.themeColor, cursor: 'pointer' }}
                                                  onClick={() => this.setState({ coalCostingView: true, CscAdd: false, comboID: idr.barge_id + '$#' + p.sales_contract_id, showDrawer: true })}
                                                ></i>
                                                {(this.state.features.includes('129')  || this.state.features.includes('97') ) &&
                                                  <i className="fa fa-trash-o" style={{ fontSize: 20, color: config.themeColor, cursor: 'pointer' }} data-toggle="modal" title="Delete" data-placement="bottom" data-keyboard="false"
                                                    data-target="#deleteCoalCostingModal" data-backdrop="static" onClick={() => this.setState({ deleting_barge_sales_fin_id: idr.barge_sales_fin_csc_id, closeDialog: true })} >
                                                  </i>
                                                }
                                              </td>
                                            }
                                            <td >{pad(idr.barge_id)}</td>
                                            <td nowrap='true'>{idr.barge_nomination}</td>
                                            <td nowrap='true'>{idr.business_no}</td>
                                            {/* {indx === 0 && <td rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{idr.sales_contract_no}</td>}
                                          {indx === 0 && <td rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{idr.quality}</td>}
                                          {indx === 0 && <td rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{idr.sales_type}</td>} */}
                                            {indx === 0 && <td className="text-right" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{this.toLocaleString(idr.barge_quantity)}</td>}
                                            {indx === 0 && <td className="text-right" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{this.toLocaleString(idr.base_price_pmt) + ' ' + idr.sales_currency}</td>}
                                            {indx === 0 && <td className="text-right" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{this.toLocaleString(idr.total_payable)}</td>}
                                            {indx === 0 && <td className="text-right" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{this.toLocaleString(idr.paid)}</td>}
                                            {indx === 0 && <td className="text-right" rowSpan={id.new_barges.length} style={{ borderRight: "1px solid #dee2e6", borderLeft: "1px solid #dee2e6" }} nowrap='true'>{this.toLocaleString(idr.remaining_amount)}</td>}
                                          </tr>
                                        ))}
                                      </tbody>
                                    ))}
                                  </table>
                                </div>
                              </div>
                              :
                              <div>
                                <span className="contract_display_header_value pt-2">No costings made</span>
                              </div>
                            }
                          </div>
                        ))}
                      </div>
                      :
                      <div>
                        <span className="contract_display_header_value pt-2">No costings made</span>
                      </div>
                    }
                  </div>
                </div>

              </div>
            }

          </div>
          {/**
                 * 
                 * Delete Payament Postings Conformation Modal
                 * 
                 */}

          <div className='modal fade' id='deleteCoalCostingModal' role='dialog' tabIndex='-1'>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0" style={{ backgroundColor: '#f1f1f1' }} >
                  <h6 className="modal-title" style={{ border: 'none', padding: 0, }}><i className="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: '#e0a51e', marginBottom: '5px' }} ></i>&emsp; Are you sure to delete ?</h6>
                </div>
                <div className="modal-footer border-0">
                  <button className='btn btn-sm btn-warning mr-3' onClick={() => this.deletePerformaCoalCostingHandler(this.state.deleting_barge_sales_fin_id)}>Ok</button>
                  <button className='btn btn-sm btn-secondary' data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

}