import React, { Component } from 'react';
import CookieHandler from '../common/CookieHandler';
import config from '../../config/config';
import api from '../../api/api';

import { Alert } from '@material-ui/lab';
import {
  Snackbar, TableContainer, Table, TableCell,
  TableHead, TableRow, TableBody, Paper
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { localDateFormate } from '../common/common';
import Loader from '../common/Loader';

export default class MonthlyCoalPrices extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      month_year: null,
      gar_6500: '',
      gar_5800: '',
      gar_5000: '',
      gar_4200: '',
      gar_3400: '',
      monthly_coal_indexes: [],
      offset: 0,
      limit: 10,
      files: [],
      isLoading: true,
      showLoadMore: false,
    }
  }

  componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace('/logout');
    }
    var features = this.Cookie.getCookie("features");

    if (!(features.includes('97') || features.includes('549'))) {
      window.location.href = '/'
    }
    this.setState({ features: features });
    this.get_monthly_coal_prices();
    document.title = config.documentTitle + "Montly Coal Price Index";
  }

  get_monthly_coal_prices = () => {
    const { offset, limit } = this.state;
    const reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      offset: offset,
      limit: limit
    }
    api.get_monthly_coal_indexes(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                if (res.monthly_coal_indexes.length >= 10) {
                  this.setState({ showLoadMore: true })
                } else { this.setState({ showLoadMore: false }) }
                if (this.state.loadMore) {
                  this.setState({
                    monthly_coal_indexes: [...this.state.monthly_coal_indexes, ...res.monthly_coal_indexes],
                  });
                }
                else {
                  this.setState({
                    monthly_coal_indexes: [...res.monthly_coal_indexes],
                  })
                }
                this.setState({ isLoading: false, submitting: false, loadMore: false });
              }
            })
        }
      })
  }

  fileUploadHandler = (idx) => (event) => {
    let file = event.target.files[0];
    let file_data = "";
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onloadend = () => {
      let file_content = fileReader.result;
      let file_extension = file.name.substr(file.name.lastIndexOf(".") + 1);
      let file_name = file.name;
      let file_type = file.type;
      file_data = file_content.replace("data:" + file_type + ";base64,", "");

      var row = this.state.files ? this.state.files : [];
      row[idx].file_name = file_name;
      row[idx].file_extension = file_extension;
      row[idx].file_type = file_type;
      row[idx].file_content = file_data;

      this.setState({
        files: row,
      });
    };
  };

  addMoreFileHandler = () => {
    const data = {
      file_description: "",
      file_name: "",
      file_extension: "",
      file_content: "",
      feature_name: "Purchase_fin_coal_costing",
    };

    this.setState({
      files: [...this.state.files, data],
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ submitting: true });

    const { month_year, gar_6500, gar_5800, gar_5000, gar_4200, gar_3400 } = this.state;

    let reqParams = {
      login_user_id: this.Cookie.getCookie('loginUserId'),
      idtoken: this.Cookie.getIdTokenCookie(),
      "month_year": month_year,
      "6500_gar": gar_6500,
      "5800_gar": gar_5800,
      "5000_gar": gar_5000,
      "4200_gar": gar_4200,
      "3400_gar": gar_3400,
    }

    api.add_monthly_coal_index(reqParams)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json()
            .then((res) => {
              if (res.code === '200') {
                this.setState({
                  month_year: null,
                  gar_6500: '',
                  gar_5800: '',
                  gar_5000: '',
                  gar_4200: '',
                  gar_3400: '',
                  submitting: false,
                },
                  () => this.get_monthly_coal_prices
                )
              }
            })
        }
      })
  }

  render() {
    return (
      <div id='wraper'>
        {/* <SideBar /> */}
        <div className='content'>
          {/* <Header /> */}
          {this.state.isLoading &&
            <Loader />
          }
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackBarErrorOpen} onClose={() => this.setState({ snackBarErrorOpen: false })}
            style={{ width: '450px' }}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarErrorOpen: false })} severity="error" style={{ width: '100%' }}>
              {this.state.errorMsg}
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            style={{ width: '450px' }}
            open={this.state.snackBarSuccessOpen} autoHideDuration={100000} onClose={() => this.setState({ snackBarSuccessOpen: false })}
          >
            <Alert elevation={6} variant="filled" onClose={() => this.setState({ snackBarSuccessOpen: false })} severity="success" style={{ width: '100%' }}>
              {this.state.successMsg}
            </Alert>
          </Snackbar>
          <div className='container p-2'>
            {!this.state.isLoading &&
              <div className='card p-0'>

                {this.state.monthly_coal_indexes.length > 0 &&

                  <div className='row'>
                    <div className='col-lg-12 p-3'>

                      <Paper>

                        <TableContainer style={{ maxHeight: 650 }}>
                          <Table stickyHeader size="small" aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell nowrap='true' className='text-uppercase'> Month Ending </TableCell>
                                <TableCell nowrap='true' className='text-uppercase'> 6500 GAR (6200 NAR) </TableCell>
                                <TableCell nowrap='true' className='text-uppercase'> 5800 GAR (5500 NAR) </TableCell>
                                <TableCell nowrap='true' className='text-uppercase'> 5000 GAR (4600 NAR) </TableCell>
                                <TableCell nowrap='true' className='text-uppercase'> 4200 GAR (3800 NAR) </TableCell>
                                <TableCell nowrap='true' className='text-uppercase'> 3400 GAR (3000 NAR) </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody  >
                              {this.state.monthly_coal_indexes.map((e, idx) => (

                                <TableRow key={idx}>
                                  <TableCell nowrap='true' >{localDateFormate(e.month_year)}</TableCell>
                                  <TableCell nowrap='true' align='right'> {e.gar_6500}
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_6500) < parseFloat(this.state.monthly_coal_indexes[idx].gar_6500)) &&
                                      <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                    }
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_6500) > parseFloat(this.state.monthly_coal_indexes[idx].gar_6500)) &&
                                      <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                    }
                                  </TableCell>

                                  <TableCell nowrap='true' align='right'>{e.gar_5800}
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_5800) < parseFloat(this.state.monthly_coal_indexes[idx].gar_5800)) &&
                                      <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                    }
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_5800) > parseFloat(this.state.monthly_coal_indexes[idx].gar_5800)) &&
                                      <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                    }
                                  </TableCell>

                                  <TableCell nowrap='true' align='right'>{e.gar_5000}
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_5000) < parseFloat(this.state.monthly_coal_indexes[idx].gar_5000)) &&
                                      <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                    }
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_5000) > parseFloat(this.state.monthly_coal_indexes[idx].gar_5000)) &&
                                      <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                    }
                                  </TableCell>

                                  <TableCell nowrap='true' align='right'>{e.gar_4200}
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_4200) < parseFloat(this.state.monthly_coal_indexes[idx].gar_4200)) &&
                                      <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                    }
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_4200) > parseFloat(this.state.monthly_coal_indexes[idx].gar_4200)) &&
                                      <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                    }
                                  </TableCell>

                                  <TableCell nowrap='true' align='right' >{e.gar_3400}
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_3400) < parseFloat(this.state.monthly_coal_indexes[idx].gar_3400)) &&
                                      <ArrowUpwardIcon style={{ color: '#4E9F3D', width: 18, height: 18, margin: 5 }} />
                                    }
                                    {!!this.state.monthly_coal_indexes[idx + 1] && (parseFloat(this.state.monthly_coal_indexes[idx + 1].gar_3400) > parseFloat(this.state.monthly_coal_indexes[idx].gar_3400)) &&
                                      <ArrowDownwardIcon style={{ color: '#FF2626 ', width: 18, height: 18, margin: 5 }} />
                                    }
                                  </TableCell>
                                </TableRow>

                              ))}

                              {this.state.showLoadMore && (

                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell colSpan={3} className='text-center'>
                                    <button
                                      type="button"
                                      className="header_button header_button_text mt-1"
                                      style={{ width: 100, borderRadius: 20 }}

                                      onClick={() => {
                                        this.setState((state) => {
                                          return { offset: state.offset + 10, submitting: true }
                                        },
                                          () => this.get_monthly_coal_prices)
                                      }}
                                      disabled={this.state.submitting}
                                    >
                                      Load More
                                      <span className={this.state.submitting ? "spinner-grow spinner-grow-sm mr-2" : ""}></span>
                                    </button>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </div>
                  </div>
                }

              </div>

            }

          </div>
        </div>

      </div>
    )
  }
}