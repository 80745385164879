import React, { Component } from 'react';
import {
    Tabs,
    Tab,
    Snackbar,
} from "@material-ui/core";

import { Alert } from "@material-ui/lab";
/**
 * common required imports
 */
import Loader from "../../common/Loader";
import SideBar from "../../common/SideBar";
import Header from "../../common/Header";
import config from "../../../config/config";
import CookieHandler from "../../common/CookieHandler";
import { PURCHASE_SAMPLING_TABS } from '../../../common/utils'
import { TabPanel } from '../../common/TabPanel';

import { SAMPLING_TYPES, ContractTypesEnum } from '../../../common/enums'
import SalesContainer from './Sales-Container';

import '../Purchase-Sampling';
import DelayedSampleReport from '../Purchase-Sampling/delayed-sample-report';

export default class SalesSampling extends Component {
    constructor(props) {
        super(props);
        this.Cookie = new CookieHandler();

        this.state = {
            isLoading: false,
            snackBarSuccessOpen: false,
            successMsg: '',
            snackBarErrorOpen: false,
            errorMsg: '',
            showDrawer: false,
            features: [],
            activeTabIndex: 1,
        }
    }

    componentDidMount() {
        if (!this.Cookie.isLoggedIn()) {
            this.props.history.replace("/logout");
        }
        var features = this.Cookie.getCookie("features");
        const samplingType = decodeURIComponent(window.atob(this.props?.match?.params?.samplingType));
        const presentTabData = PURCHASE_SAMPLING_TABS.find(item => item.samplingType === samplingType);
        this.setState({ features: features, activeTabIndex: presentTabData.tabIndex });
    }

    tabsHandler = (event, value) => {
        this.setState({ activeTabIndex: value })
    }
    setDrawerVisible = (visibleState) => {
        this.setState({ showDrawer: visibleState })
    }
    render() {
        const { features, activeTabIndex, showDrawer } = this.state;
        const samplingType = decodeURIComponent(window.atob(this.props?.match?.params?.samplingType));
        const presentTabData = PURCHASE_SAMPLING_TABS.find(item => item.samplingType === samplingType);
        return (
            <div id="wraper">
                <SideBar />
                <div className="content">
                    <Header />
                    <div className="clearfix content-header">
                        <div className="row">
                            <div className="col-sm text-left pl-0">
                                <h4 style={{ padding: "10px 20px", margin: "0px" }}>
                                    {presentTabData.samplingType !== SAMPLING_TYPES.NONE ? `S&A Sales` : 'Aging report'}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        open={this.state.snackBarErrorOpen}
                        onClose={() => this.setState({ snackBarErrorOpen: false })}
                        style={{ width: "450px" }}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            onClose={() => this.setState({ snackBarErrorOpen: false })}
                            severity="error"
                            style={{ width: "100%" }}
                        >
                            {this.state.errorMsg}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        style={{ width: "450px" }}
                        open={this.state.snackBarSuccessOpen}
                        autoHideDuration={100000}
                        onClose={() => this.setState({ snackBarSuccessOpen: false })}
                    >
                        <Alert
                            elevation={6}
                            variant="filled"
                            onClose={() => this.setState({ snackBarSuccessOpen: false })}
                            severity="success"
                            style={{ width: "100%" }}
                        >
                            {this.state.successMsg}
                        </Alert>
                    </Snackbar>
                    <div className="container p-2" style={{ overflow: 'unset' }}>
                        {this.state.isLoading && <Loader />}
                        {!this.state.isLoading && (
                            <div className="card p-0">
                                <div className="row border-bottom">
                                    <Tabs
                                        value={this.state.activeTabIndex}
                                        onChange={this.tabsHandler}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        aria-label="scrollable auto tabs example"
                                        style={{
                                            backgroundColor: "#ffffff",
                                            color: "#6c7293",
                                            padding: 0,
                                        }}
                                        TabIndicatorProps={{
                                            style: { background: config.themeColor },
                                        }}
                                    >
                                        {
                                            [presentTabData].map((tabData) => {
                                                const isShowTab = tabData.permissionCodes.split(",").some(element => {
                                                    return features.includes(element);
                                                }
                                                );
                                                return isShowTab && (
                                                    <Tab
                                                        key={tabData.label}
                                                        label={
                                                            <h4
                                                                style={{
                                                                    margin: "0px",
                                                                    color:
                                                                        this.state.activeTabIndex == tabData.tabIndex
                                                                            ? config.themeColor
                                                                            : "#6c7293",
                                                                    fontSize: "14px",
                                                                    border: "0px",
                                                                }}
                                                            >
                                                                Sales S&A
                                                            </h4>
                                                        }
                                                        value={tabData.tabIndex}
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            textTransform: "capitalize",
                                                            color: "#6c7293",
                                                        }}
                                                    />
                                                )
                                            })}
                                    </Tabs>
                                </div>
                                <TabPanel value={activeTabIndex} index={1}>
                                    <SalesContainer setDrawerVisible={this.setDrawerVisible} showDrawer={showDrawer} samplingType={SAMPLING_TYPES.SUPPLIER} />
                                </TabPanel>
                                <TabPanel value={activeTabIndex} index={4}>
                                    <DelayedSampleReport type={ContractTypesEnum.SALES_CONTRACT} />
                                </TabPanel>
                            </div>)}
                    </div>
                </div>
            </div>
        )
    }
}
