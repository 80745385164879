import React, { Component } from "react";
import CookieHandler from "../common/CookieHandler";
import api from "../../api/api";
import config from "../../config/config";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { pad } from "../common/common";
import Loader from "../common/Loader";

export default class ViewSalesQualityResult extends Component {
  constructor(props) {
    super(props);
    this.Cookie = new CookieHandler();
    this.state = {
      businessNoBargeID: "",
      quality_calorific_value_actual: "",
      quality_calorific_value2_actual: "",
      quality_calorific_value3_actual: "",
      quality_total_moisture_actual: "",
      quality_inherent_moisture_actual: "",
      quality_ash_actual: "",
      quality_volatile_matter_actual: "",
      quality_fixed_carbon_actual: "",
      quality_fuel_ratio_actual: "",
      quality_total_sulphur_actual: "",
      quality_hgi_actual: "",

      businessNoID: "",
      errorMsg: "",
      succesMsg: "",
      isLoading: true,
      sales_quality_result_id: "",
      features: [],
      files: [],
    };
  }

  async componentDidMount() {
    if (!this.Cookie.isLoggedIn()) {
      this.props.history.replace("/logout");
    } else {
      var features = this.Cookie.getCookie("features");
      this.setState({ features: features });
      if (!(features.includes("56")  || features.includes("97") )) {
        window.location.href = "/dashboard";
      }

      try {
        var comboID = this.props.comboID;
        var business_no_id = comboID.split("$#")[0];
        var business_allocation_id = comboID.split("$#")[1];
        var bargeID = comboID.split("$#")[2];
      } catch (e) {
        window.location.href = "/pagenotfound";
      }
      const idToken = this.Cookie.getIdTokenCookie();
      const loginUserID = this.Cookie.getCookie("loginUserId");

      const get_scq_specs = await api.getAllSalesSpecByBargeID(
        loginUserID,
        idToken,
        business_no_id,
        business_allocation_id,
        bargeID
      );
      if (get_scq_specs.status >= 200 && get_scq_specs.status < 300) {
        get_scq_specs.json().then((res) => {
          if (res.code === "200") {
            if (res.scq_typical_specs) {
              this.setState({
                quality_calorific_value_typical:
                  res.scq_typical_specs.quality_calorific_value_typical,
                quality_calorific_value_rejection:
                  res.scq_typical_specs.quality_calorific_value_rejection,
                quality_calorific_value_basis:
                  res.scq_typical_specs.quality_calorific_value_basis,
                quality_calorific_value2_typical:
                  res.scq_typical_specs.quality_calorific_value2_typical,
                quality_calorific_value2_rejection:
                  res.scq_typical_specs.quality_calorific_value2_rejection,
                quality_calorific_value2_basis:
                  res.scq_typical_specs.quality_calorific_value2_basis,
                quality_calorific_value3_typical:
                  res.scq_typical_specs.quality_calorific_value3_typical,
                quality_calorific_value3_rejection:
                  res.scq_typical_specs.quality_calorific_value3_rejection,
                quality_calorific_value3_basis:
                  res.scq_typical_specs.quality_calorific_value3_basis,
                quality_total_moisture_typical:
                  res.scq_typical_specs.quality_total_moisture_typical,
                quality_total_moisture_rejection:
                  res.scq_typical_specs.quality_total_moisture_rejection,
                quality_total_moisture_basis:
                  res.scq_typical_specs.quality_total_moisture_basis,
                quality_inherent_moisture_typical:
                  res.scq_typical_specs.quality_inherent_moisture_typical,
                quality_inherent_moisture_rejection:
                  res.scq_typical_specs.quality_inherent_moisture_rejection,
                quality_inherent_moisture_basis:
                  res.scq_typical_specs.quality_inherent_moisture_basis,
                quality_ash_typical: res.scq_typical_specs.quality_ash_typical,
                quality_ash_rejection:
                  res.scq_typical_specs.quality_ash_rejection,
                quality_ash_basis: res.scq_typical_specs.quality_ash_basis,
                quality_volatile_matter_typical:
                  res.scq_typical_specs.quality_volatile_matter_typical,
                quality_volatile_matter_rejection:
                  res.scq_typical_specs.quality_volatile_matter_rejection,
                quality_volatile_matter_basis:
                  res.scq_typical_specs.quality_volatile_matter_basis,
                quality_fixed_carbon_typical:
                  res.scq_typical_specs.quality_fixed_carbon_typical,
                quality_fixed_carbon_rejection:
                  res.scq_typical_specs.quality_fixed_carbon_rejection,
                quality_fixed_carbon_basis:
                  res.scq_typical_specs.quality_fixed_carbon_basis,
                quality_fuel_ratio_typical:
                  res.scq_typical_specs.quality_fuel_ratio_typical,
                quality_fuel_ratio_rejection:
                  res.scq_typical_specs.quality_fuel_ratio_rejection,
                quality_fuel_ratio_basis:
                  res.scq_typical_specs.quality_fuel_ratio_basis,
                quality_total_sulphur_typical:
                  res.scq_typical_specs.quality_total_sulphur_typical,
                quality_total_sulphur_rejection:
                  res.scq_typical_specs.quality_total_sulphur_rejection,
                quality_total_sulphur_basis:
                  res.scq_typical_specs.quality_total_sulphur_basis,
                quality_hgi_typical: res.scq_typical_specs.quality_hgi_typical,
                quality_hgi_rejection:
                  res.scq_typical_specs.quality_hgi_rejection,
              });
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
      // get Barge Purchase Quality Results By Barge ID
      const get_sc_quality_results =
        await api.get_barge_sales_fin_quality_results(
          loginUserID,
          idToken,
          business_no_id,
          business_allocation_id,
          bargeID
        );
      if (
        get_sc_quality_results.status >= 200 &&
        get_sc_quality_results.status < 300
      ) {
        get_sc_quality_results.json().then((res) => {
          if (res.code === "200") {
            if (res.barge_sales_qualities) {
              this.setState({
                barge_pur_quality_id: res.barge_sales_qualities.id,
                sales_quality_result_id: res.barge_sales_qualities.id,
                bargePurchaseQualityResultsAvailable: true,
                quality_calorific_value_actual:
                  res.barge_sales_qualities.quality_calorific_value_actual,
                quality_calorific_value2_actual:
                  res.barge_sales_qualities.quality_calorific_value2_actual,
                quality_calorific_value3_actual:
                  res.barge_sales_qualities.quality_calorific_value3_actual,
                quality_total_moisture_actual:
                  res.barge_sales_qualities.quality_total_moisture_actual,
                quality_inherent_moisture_actual:
                  res.barge_sales_qualities.quality_inherent_moisture_actual,
                quality_ash_actual:
                  res.barge_sales_qualities.quality_ash_actual,
                quality_volatile_matter_actual:
                  res.barge_sales_qualities.quality_volatile_matter_actual,
                quality_fixed_carbon_actual:
                  res.barge_sales_qualities.quality_fixed_carbon_actual,
                quality_fuel_ratio_actual:
                  res.barge_sales_qualities.quality_fuel_ratio_actual,
                quality_total_sulphur_actual:
                  res.barge_sales_qualities.quality_total_sulphur_actual,
                quality_hgi_actual:
                  res.barge_sales_qualities.quality_hgi_actual,
                files: res.barge_sales_qualities.files,
                isLoading: false,
              });
            } else {
              this.setState({
                bargePurchaseQualityResultsAvailable: false,
                isLoading: false,
              });
              //window.location.href = '/view-business/' + btoa(business_no_id);
            }
          } else if (res.code === "601") {
            this.setState({
              errorMsg: res.message,
              snackBarErrorOpen: true,
            });
          } else if (res.code === "607") {
            window.location.href = "/logout";
          }
        });
      }
    }
    document.title = config.documentTitle + " View Sales Quality Results";
  }

  render() {
    var comboID = this.props.comboID;
    var bargeID = comboID.split("$#")[2];
    return (
      <div>
        {this.state.isLoading && (
          <Loader />
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackBarErrorOpen}
          onClose={() => this.setState({ snackBarErrorOpen: false })}
          style={{ width: "450px" }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ snackBarErrorOpen: false })}
            severity="error"
            style={{ width: "100%" }}
          >
            {this.state.errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          style={{ width: "450px" }}
          open={this.state.snackBarSuccessOpen}
          autoHideDuration={100000}
          onClose={() => this.setState({ snackBarSuccessOpen: false })}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => this.setState({ snackBarSuccessOpen: false })}
            severity="success"
            style={{ width: "100%" }}
          >
            {this.state.successMsg}
          </Alert>
        </Snackbar>
        {!this.state.isLoading && (
          <div
            className="container bg-white p-0"
            style={{ minHeight: "100vh" }}
          >
            <div className="row">
              <div className="col-lg-12 p-0 border-bottom ">
                <h6 style={{ padding: "15px", marginBottom: 0 }}>
                  Barge Sales Quality Results - {pad(bargeID)}
                </h6>
              </div>
            </div>

            <div className="drawer-section">
              <div className="card p-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive">
                        <table className="table table-bordered mb-0">
                          <thead className="table-header">
                            <tr>
                              <th>Typical Analysis</th>
                              <th>Units</th>
                              <th>Basis</th>
                              <th>Typical</th>
                              <th>Rejection</th>
                              <th>Actual</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Gross Calorific Value
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                Kcal/kg
                              </td>
                              <td>
                                {this.state.quality_calorific_value_basis}
                              </td>
                              <td>
                                {this.state.quality_calorific_value_typical}
                              </td>
                              <td>
                                {this.state.quality_calorific_value_rejection}
                              </td>
                              <td>
                                {this.state.quality_calorific_value_actual}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  borderTop: "none",
                                }}
                              ></td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  borderTop: "none",
                                }}
                              >
                                Kcal/kg
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value2_basis}
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value2_typical}
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value2_rejection}
                              </td>
                              <td>
                                {this.state.quality_calorific_value2_actual}{" "}
                              </td>
                            </tr>

                            <tr>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  borderTop: "none",
                                }}
                              ></td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  borderTop: "none",
                                }}
                              >
                                Kcal/kg
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value3_basis}
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value3_typical}
                              </td>
                              <td style={{ borderTop: "none" }}>
                                {this.state.quality_calorific_value3_rejection}
                              </td>
                              <td>
                                {this.state.quality_calorific_value3_actual}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Total Moisture
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>{this.state.quality_total_moisture_basis}</td>
                              <td>
                                {this.state.quality_total_moisture_typical}
                              </td>
                              <td>
                                {this.state.quality_total_moisture_rejection}
                              </td>
                              <td>
                                {this.state.quality_total_moisture_actual}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Inherent Moisture
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>
                                {this.state.quality_inherent_moisture_basis}
                              </td>
                              <td>
                                {this.state.quality_inherent_moisture_typical}
                              </td>
                              <td>
                                {this.state.quality_inherent_moisture_rejection}
                              </td>
                              <td>
                                {this.state.quality_inherent_moisture_actual}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>Ash</td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>{this.state.quality_ash_basis}</td>
                              <td>{this.state.quality_ash_typical}</td>
                              <td>{this.state.quality_ash_rejection}</td>
                              <td>{this.state.quality_ash_actual}</td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Volatile Matter(VM)
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>
                                {this.state.quality_volatile_matter_basis}
                              </td>
                              <td>
                                {this.state.quality_volatile_matter_typical}
                              </td>
                              <td>
                                {this.state.quality_volatile_matter_rejection}
                              </td>
                              <td>
                                {this.state.quality_volatile_matter_actual}
                              </td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Fixed Carbon(FC)
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>{this.state.quality_fixed_carbon_basis}</td>
                              <td>{this.state.quality_fixed_carbon_typical}</td>
                              <td>
                                {this.state.quality_fixed_carbon_rejection}
                              </td>
                              <td>{this.state.quality_fixed_carbon_actual}</td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Fuel Ratio(FC/VM)
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>{this.state.quality_fuel_ratio_basis}</td>
                              <td>{this.state.quality_fuel_ratio_typical}</td>
                              <td>{this.state.quality_fuel_ratio_rejection}</td>
                              <td>{this.state.quality_fuel_ratio_actual}</td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>
                                Total Sulphur
                              </td>
                              <td style={{ verticalAlign: "middle" }}>%</td>
                              <td>{this.state.quality_total_sulphur_basis}</td>
                              <td>
                                {this.state.quality_total_sulphur_typical}
                              </td>
                              <td>
                                {this.state.quality_total_sulphur_rejection}
                              </td>
                              <td>{this.state.quality_total_sulphur_actual}</td>
                            </tr>

                            <tr>
                              <td style={{ verticalAlign: "middle" }}>HGI</td>
                              <td style={{ verticalAlign: "middle" }}></td>
                              <td></td>
                              <td>{this.state.quality_hgi_typical}</td>
                              <td>{this.state.quality_hgi_rejection}</td>
                              <td>{this.state.quality_hgi_actual}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* {(this.state.features.includes('323')  || this.state.features.includes('97') ) && */}

                  <div className="card p-0 border mb-3 mt-2">
                    <div className="card-header section_header">
                      <h5>Uploaded Files</h5>
                    </div>
                    {this.state.files.length > 0 ? (
                      <div className="card-body">
                        {this.state.files.map((e, index) => (
                          <div className="row mb-3" key={index}>
                            <div className="col-lg-4 p-0">
                              <div className="">
                                <a
                                  href={config.apiDomain + "/" + e.file_path}
                                  role="button"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: config.themeColor }}
                                >
                                  {e.file_description}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="card-body">
                        <p>No files to show</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <footer className="row bottom_buttons_section">
              <button
                type="button"
                className="btn previous_button btn-light"
                name="submit"
                onClick={() => this.props.returnCallbackDrawerClose(false)}
              >
                Close
              </button>
              <button
                type="button"
                className="drawer_text drawer_btn_success"
                name="Back"
                onClick={() => {
                  this.props.returnEditCallback("salesQualityResults");
                }}
              >
                EDIT
              </button>
            </footer>
          </div>
        )}
      </div>
    );
  }
}
