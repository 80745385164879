var config = {
  apiDomain: process.env.REACT_APP_SERVER_URL,
  cookieName: process.env.REACT_APP_COOKIE_NAME,
  documentTitle: process.env.REACT_APP_DOCUMENT_TITLE,
  sessionTime: process.env.REACT_APP_SESSION_TIME,
  company: process.env.REACT_APP_COMPANY,
  environment: process.env.REACT_APP_ENVIRONMENT,
  themeColor: process.env.REACT_APP_THEME_COLOR,
};

module.exports = config;
